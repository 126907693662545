import React from 'react';
import {
    AppointmentType, Appointment, AppointmentStatus, User,
} from '../store/api/apiTypes';
import { IconPhone, IconPlace, IconCamera } from '../components/icons';
import { formatAddress } from '.';
import genericMessages from '../locale/genericMessages';
import { DateTime } from 'luxon';

export const getAppointmentTypeIcon = (type: AppointmentType) => {
    switch (type) {
        case AppointmentType.phone: return <IconPhone />;
        case AppointmentType.real: return <IconPlace />;
        case AppointmentType.video: return <IconCamera />;
    }
};

export const getAppointmentTypeLabel = (type: AppointmentType) => {
    switch (type) {
        case AppointmentType.phone: return genericMessages.phone;
        case AppointmentType.real: return genericMessages.location;
        case AppointmentType.video: return genericMessages.url;
    }
};

export const getAppointmentDetails = (appointment: Appointment) => {
    switch (appointment.type) {
        case AppointmentType.phone: return appointment.phone;
        case AppointmentType.real: return formatAddress(appointment.address);
        case AppointmentType.video: return appointment.url;
    }
};

export const isAppointmentCancelled = (appointment?: Appointment) =>
    appointment?.status && appointment.status === AppointmentStatus.cancelled;

export const isAppointmentValidated = (appointment?: Appointment) =>
    appointment?.status && appointment.status === AppointmentStatus.validated;

export const getActiveSuspension = (user?: User) => {
    const lastSuspension = user?.selectedProgram?.suspensions[user?.selectedProgram?.suspensions.length - 1];

    if (lastSuspension?.toDate && DateTime.utc() <= DateTime.fromISO(lastSuspension?.toDate ?? '', { zone: 'UTC' })) {
        return lastSuspension;
    } else {
        return undefined;
    }
};
