import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M3.49348172,7.98703824 C3.17042572,7.98703824 2.90823534,7.72611795 2.90823534,7.40375891 C2.90823534,7.08139987 3.17042572,6.82047959 3.49348172,6.82047959 L7.86475591,6.82047959 C8.18781191,6.82047959 8.45000229,7.08139987 8.45000229,7.40375891 C8.45000229,7.72611795 8.18781191,7.98703824 7.86475591,7.98703824 L3.49348172,7.98703824 Z M4.66491201,4.01296176 C4.98796802,4.01296176 5.2501584,4.2742709 5.2501584,4.59624109 C5.2501584,4.91860013 4.98796802,5.17952041 4.66491201,5.17952041 L3.49348172,5.17952041 C3.17042572,5.17952041 2.90823534,4.91860013 2.90823534,4.59624109 C2.90823534,4.2742709 3.17042572,4.01296176 3.49348172,4.01296176 L4.66491201,4.01296176 Z M7.95009504,0.940635126 C7.34260929,0.335968892 6.53535945,0.00194426442 5.67699809,0 L2.38541524,0 C1.05807644,0 0,1.07634478 0,2.39922229 L0,9.60077771 C0,10.9236552 1.05807644,12 2.38541524,12 L9.59268655,12 C10.9200253,12 12,10.9236552 12,9.60077771 L12,6.30213869 C11.9984393,5.4536617 11.650803,4.62462735 11.0479992,4.02851588 L7.95009504,0.940635126 Z" />
    </svg>
);

const IconFile: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-file${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconFile;
