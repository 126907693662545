import { Card, Typography, Spin, Form, Input, Button, Dropdown, Menu, Space } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { FormProps } from 'antd/lib/form';

import '../../assets/styles/NetworkingActionComment.less';

import { Appointment, AppointmentStatus } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { update, getAppointmentsUpdateState, AppointmentsState } from '../../store/actions/appointments';

import { IconContacts, IconMore } from '../../components/icons';
import CommentCard from '../../components/CommentCard';
import genericMessages from '../../locale/genericMessages';
import formMessages from '../../locale/formMessages';

const messages = defineMessages({
    title: {
        id: 'networking_action_comment.title',
        defaultMessage: 'Compte rendu',
        description: 'Networking action comment block title',
    },
    commentLabel: {
        id: 'networking_action_comment.form.comment.label',
        defaultMessage: 'Notez les points clés de votre entretien pour mieux vous en souvenir',
        description: 'Networking action comment block form label',
    },
    commentPlaceholder: {
        id: 'networking_action_comment.form.comment.placeholder',
        defaultMessage: 'Saisir votre compte rendu',
        description: 'Networking action comment block form placeholder',
    },
    submit: {
        id: 'networking_action_comment.form.submit',
        defaultMessage: 'Enregistrer et valider',
        description: 'Networking action comment block form submit',
    },
});

interface FormValues {
    comment: string;
}

interface NetworkingActionCommentProps {
    appointment?: Appointment;
    appointmentUpdateState: AppointmentsState['update'];
    updateAppointment: typeof update.trigger;
}

const NetworkingActionComment: FC<NetworkingActionCommentProps> = ({
    appointment, appointmentUpdateState, updateAppointment,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const onSubmit: FormProps<FormValues>['onFinish'] = (val) => {
        updateAppointment({
            id: appointment?.id,
            appointment: {
                ...val,
                status: AppointmentStatus.validated,
            },
        });
        setIsEditing(false);
    };

    return (
        <Spin spinning={appointmentUpdateState.loading}>
            <Card className="networking-action-details-block-card networking-action-comment">
                <Typography.Title level={2}>
                    <IconContacts /> <FormattedMessage {...messages.title} />
                </Typography.Title>
                {
                    appointment?.status === AppointmentStatus.validated && !isEditing ?
                    (
                        <CommentCard
                            title={<FormattedDate value={appointment.validatedAt} />}
                            extra={(
                                <Dropdown
                                    overlay={(
                                        <Menu>
                                            <Menu.Item onClick={setIsEditing.bind(null, true)}>
                                                <FormattedMessage {...genericMessages.edit} />
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                    trigger={['click']}
                                    placement="bottomRight"
                                >
                                    <Button type="link">
                                        <IconMore />
                                    </Button>
                                </Dropdown>
                            )}
                        >
                            <p>{appointment.comment}</p>
                        </CommentCard>
                    ) :
                    (
                        <>
                            <Form<FormValues>
                                layout="vertical"
                                requiredMark={false}
                                form={form}
                                onFinish={onSubmit}
                                initialValues={isEditing ? {
                                    comment: appointment?.comment,
                                } : undefined}
                            >
                                <Form.Item
                                    label={formatMessage(messages.commentLabel)}
                                    name="comment"
                                    rules={[{ required: true, message: formatMessage(formMessages.requiredField) }]}
                                >
                                    <Input.TextArea
                                        placeholder={formatMessage(messages.commentPlaceholder)}
                                        rows={4}
                                    />
                                </Form.Item>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Space>
                                            {isEditing && (
                                                <Button
                                                    onClick={setIsEditing.bind(null, false)}
                                                    ghost
                                                >
                                                    <FormattedMessage {...genericMessages.cancel} />
                                                </Button>
                                            )}
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={appointmentUpdateState.loading}
                                                disabled={
                                                    form &&
                                                    (
                                                        !form.isFieldsTouched() ||
                                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                    )
                                                }
                                            >
                                                <FormattedMessage {...messages.submit} />
                                            </Button>
                                        </Space>
                                    )}
                                </Form.Item>
                            </Form>
                        </>
                    )
                }
            </Card>
        </Spin>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateAppointment: update.trigger,
    },
)(NetworkingActionComment);
