import api from './_client';
import { Notification } from './apiTypes';
import { SearchPaginationQuery } from '.';

export interface NotificationListPayload {
    poll: boolean;
    body?: SearchPaginationQuery;
}

export interface NotificationUpdatePayload {
    id: Notification['id'];
    notification: Partial<Notification>;
}

export const list = ({ poll, ...payload }: NotificationListPayload) => api.get('/me/notifications', { params: payload });

export const update = (payload: NotificationUpdatePayload) => api.put(`/me/notifications/${payload.id}`, payload.notification);
