import React, { FC, useEffect, useRef, useState, ReactNode } from 'react';
import { FormattedMessage, useIntl, MessageDescriptor } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Descriptions, Divider, Badge, Pagination, Spin } from 'antd';
import { User, businessTypeMessages, companySizeMessages } from '../../../store/api/apiTypes';
import Seo from '../../../components/Seo';
import { MainReducerState } from '../../../store/reducers';
import { getUser } from '../../../store/actions/auth';
import ClientOrganizationMessages from './ClientOrganizationMessages';
import AccountMessages from '../../account/AccountMessages';
import * as packsActions from '../../../store/actions/packs';
import BasicList from '../../../components/BasicList';
import PackCard from '../../../components/PackCard';
import { PaginationProps } from 'antd/lib/pagination';
import { scrollToElement } from '../../../helpers';

import '../../../assets/styles/ClientOrganization.less';
import EnumMessage from '../../../components/EnumMessage';

interface ClientOrganizationProps {
    user?: User;
    packsListState: packsActions.PacksState['list'];
    getPacksList: typeof packsActions.list.trigger;
}

interface DescriptionItem {
    label?: MessageDescriptor;
    value?: string | ReactNode;
}

const ClientOrganization: FC<ClientOrganizationProps> = ({
    user,
    packsListState,
    getPacksList,
}) => {
    const pageSize = 6;
    const { formatMessage } = useIntl();
    const listRef = useRef(null);
    const organization = user?.organization;
    const packs = packsListState.data?.items;
    const [ currentPage, setCurrentPage ] = useState<number>(0);

    // ---------------------------------------
    // On init, get packs list

    useEffect(() => {
        getPacksList({
            organization: organization?.id,
            pageSize,
            page: currentPage,
        });
    }, [currentPage, getPacksList, organization]);

    // ---------------------------------------
    // On pack page change

    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        setCurrentPage(page - 1);
    };

    // ---------------------------------------
    // Render

    return (
        <div id="organization">
            <Seo title={formatMessage(ClientOrganizationMessages.title)} />
            <Typography.Title level={1}>
                <FormattedMessage {...ClientOrganizationMessages.title} />
            </Typography.Title>

            <Descriptions column={1} bordered>
                {[
                    {label: AccountMessages.infoCompanyGroup, value: organization?.group},
                    {label: AccountMessages.infoCompany, value: organization?.name},
                    {label: AccountMessages.infoCompanyName, value: organization?.companyName},
                    {label: AccountMessages.infoCompanySerialNumber, value: organization?.serialNumber},
                    {label: AccountMessages.infoCompanyVAT, value: organization?.VATserialNumber},
                    {
                        label: AccountMessages.infoCompanySize,
                        value: <EnumMessage map={companySizeMessages} value={organization?.companySize} />,
                    },
                    {
                        label: AccountMessages.infoCompanyType,
                        value: <EnumMessage map={businessTypeMessages} value={organization?.businessType} />,
                    },
                ].map((item: DescriptionItem, index) => (
                    <Descriptions.Item key={'description' + index} label={<FormattedMessage {...item.label} />}>
                        {item.value || '-'}
                    </Descriptions.Item>
                ))}
                <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCompanyAddress} />}>
                    <Typography.Title level={2}>
                        <FormattedMessage {...AccountMessages.infoCompanySiteAddress} />
                    </Typography.Title>
                    {organization?.address?.street}
                    <Divider />
                    <Typography.Title level={2}>
                        <FormattedMessage {...AccountMessages.infoCompanyBillingAddress} />
                    </Typography.Title>
                    {organization?.billingAddress?.street}
                </Descriptions.Item>
            </Descriptions>

            {packs && (
                <div id="packs-list">
                    <Typography.Title level={2}>
                        <FormattedMessage {...ClientOrganizationMessages.titlePacks} />
                        &nbsp;
                        <Badge count={packsListState.data?.totalCount} overflowCount={10000} />
                    </Typography.Title>
                    <Spin spinning={packsListState.loading}>
                        <BasicList ref={listRef} grid>
                            {packs.map((pack) => (
                                <li key={pack.id}>
                                    <PackCard
                                        pack={pack}
                                    />
                                </li>
                            ))}
                        </BasicList>
                    </Spin>
                    <Pagination
                        current={currentPage + 1}
                        onChange={onPageSelect}
                        pageSize={pageSize}
                        showSizeChanger={false}
                        total={packsListState.data?.pageCount}
                        hideOnSinglePage={true}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    packsListState: state.packs.list,
});

export default connect(
    mapStateToProps,
    {
        getPacksList: packsActions.list.trigger,
    },
)(ClientOrganization);
