
import { takeLatest, call, put } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeSimpleAction } from '../helpers/EzeeSimpleAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    LoginPayload,
    login as loginApiCall,
    checkLoginStatus as checkLoginStatusApiCall,
    forgottenPassword as forgottenPasswordApiCall,
    resetPassword as resetPasswordApiCall,
    resendInvitation as resendInvitationApiCall,
    update as updateApiCall,
    ForgottenPasswordPayload,
    ResetPasswordPayload,
    UpdateMePayload,
    ResendInvitationPayload,
    exportInformations as exportInformationsApiCall,
} from '../api/auth';

// State

export interface AuthState {
    user?: User;
    forgottenPasswordError?: RequestState['error'];
    forgottenPasswordSuccess: boolean;
    resetPasswordError?: RequestState['error'];
    resetPasswordSuccess: boolean;
    resendInvitationError?: RequestState['error'];
    resendInvitationSuccess: boolean;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
    error?: any;
    acceptTermsError?: any;
    updateLoading: boolean;
    updateError?: any;
    exportState: RequestState<string>;
}

const initialState: AuthState = {
    forgottenPasswordSuccess: false,
    resetPasswordSuccess: false,
    resendInvitationSuccess: false,
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
    updateLoading: false,
    exportState: {
        data: undefined,
        loading: false,
        error: undefined,
    },
};

// Actions/Reducers

export const login = new EzeeAsyncAction<AuthState, LoginPayload>('auth/login', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        error: payload,
    }),
    reset: (state) => initialState,
});

export const checkLoginStatus = new EzeeAsyncAction<AuthState, any, User>('auth/checkLoginStatus', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state) => ({
        ...state,
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const forgottenPassword = new EzeeAsyncAction<AuthState, ForgottenPasswordPayload, User>('auth/forgottenPassword', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        forgottenPasswordSuccess: false,
        forgottenPasswordError: undefined,
    }),
    success: (state) => ({
        ...state,
        forgottenPasswordSuccess: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        forgottenPasswordSuccess: false,
        forgottenPasswordError: payload,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const resetPassword = new EzeeAsyncAction<AuthState, ResetPasswordPayload, User>('auth/resetPassword', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        resetPasswordSuccess: false,
        resetPasswordError: undefined,
    }),
    success: (state) => ({
        ...state,
        resetPasswordSuccess: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        resetPasswordSuccess: false,
        resetPasswordError: payload,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const resendInvitation = new EzeeAsyncAction<AuthState, ResendInvitationPayload, User>('auth/resendInvitation', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        resendInvitationSuccess: false,
        resendInvitationError: undefined,
    }),
    success: (state) => ({
        ...state,
        resendInvitationSuccess: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        resendInvitationSuccess: false,
        resendInvitationError: payload,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const acceptTerms = new EzeeAsyncAction<AuthState, UpdateMePayload, User>('auth/acceptTerms', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        acceptTermsError: payload,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const update = new EzeeAsyncAction<AuthState, UpdateMePayload, User>('auth/update', initialState, {
    trigger: (state) => ({
        ...state,
        updateLoading: true,
        updateError: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        user: payload,
        updateLoading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        updateLoading: false,
        updateError: payload,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const exportInformations = new EzeeAsyncAction<AuthState, User>('auth/export', initialState, {
    trigger: (state) => ({
        ...state,
        exportState: {
          ...state.exportState,
          loading: true,
          error: undefined,
 },
    }),
    success: (state, payload) => ({
        ...state,
        exportState: {
         data: payload,
         loading: false,
         error: undefined,
        },
    }),
    failure: (state, payload) => ({
        ...state,
        exportState: {
         data: undefined,
         loading: false,
         error: payload,
        },
    }),
    reset: () => ({
        ...initialState,
    }),
});

// Reducer

export const authReducer = EzeeSimpleAction.mergeActionReducers<AuthState>([
    acceptTerms,
    checkLoginStatus,
    forgottenPassword,
    login,
    resendInvitation,
    resetPassword,
    update,
    exportInformations,
]);

// Saga

export function* authSaga() {
    yield takeLatest(acceptTerms.type.trigger, simpleAsyncSaga(updateApiCall, acceptTerms));
    yield takeLatest(checkLoginStatus.type.trigger, simpleAsyncSaga(checkLoginStatusApiCall, checkLoginStatus));
    yield takeLatest(forgottenPassword.type.trigger, simpleAsyncSaga(forgottenPasswordApiCall, forgottenPassword));
    yield takeLatest(login.type.trigger, simpleAsyncSaga(loginApiCall, login));
    yield takeLatest(resendInvitation.type.trigger, simpleAsyncSaga(resendInvitationApiCall, resendInvitation));
    yield takeLatest(resetPassword.type.trigger, simpleAsyncSaga(resetPasswordApiCall, resetPassword));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(exportInformations.type.trigger, exportInformationsSaga);
}

function* exportInformationsSaga() {
    try {
        const response = yield call(exportInformationsApiCall);
        const filename = response.headers['content-disposition'].split('"')[1];
        const blob = new Blob([response.data], { type: 'blob'});
        const url = window.URL.createObjectURL(blob);

        window.setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 35000);

        return yield put(exportInformations.success({url, filename}));
    } catch (error) {
        return yield put(exportInformations.failure(error));
    }
}

// Store helpers

export const getAuthState = (state: MainReducerState) => state.auth;
export const getUser = (state: MainReducerState) => state.auth.user;
