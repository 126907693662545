import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Space, Typography } from 'antd';

import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';

import '../../assets/styles/Account.less';

import { getRoute, RoutePathName } from '../../routes';
import Seo from '../../components/Seo';
import AccountMessages from './AccountMessages';
import AccountInformations from './AccountInformations';
import AccountInformationsForm from './AccountInformationsForm';
import AccountPasswordForm from './AccountPasswordForm';
import AccountLanguageForm from './AccountLanguageForm';
import AccountSuspensionForm from './AccountSuspensionForm';
import AccountExportInformations from './AccountExportInformations';

interface AccountProps {
    user?: User;
}

const Account: FC<AccountProps> = ({ user }) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const isClient = location.pathname.startsWith(getRoute(RoutePathName.client));

    return (
        <div id="account">
            <Seo title={formatMessage(AccountMessages.title)} />
            <Typography.Title level={1}>
                <FormattedMessage {...AccountMessages.title} />
            </Typography.Title>
            <Space direction="vertical" size={32} className="account-sections-wrapper">
                {isClient && (
                    <AccountInformationsForm avatarOnly={true} />
                )}
                <AccountInformations />
                {!isClient && (
                    <AccountInformationsForm />
                )}
                <AccountPasswordForm />
                <AccountLanguageForm />
                {!isClient && (
                    <AccountExportInformations />
                )}
                <AccountSuspensionForm />
            </Space>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Account);
