import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input, Row, Col } from 'antd';
import { FormProps } from 'antd/lib/form';
import { User, Organization, rolesMessages, RoleSlug, JobLevel, jobStatusMessages } from '../../../store/api/apiTypes';
import formMessages from '../../../locale/formMessages';
import Select from '../../../components/Select';
import CreateUserMessages from './CreateUserMessages';
import genericMessages from '../../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { CreateUserStep } from './CreateUser';
import AccountMessages from '../../account/AccountMessages';
import GroupsFilterSelect from '../../../components/GroupsFilterSelect';
import UsersAutoComplete from '../../../components/UsersAutoComplete';
import EnumMessage from '../../../components/EnumMessage';

interface CreateUserJobFormProps {
    initialValues?: User;
    organization: Organization;
    onSubmit: (values: User) => void;
}

const CreateUserJobForm: FC<CreateUserJobFormProps> = ({ initialValues, organization, onSubmit }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const history = useHistory();
    const [, forceUpdate] = useState(false);
    const [formValues, setFormValues] = useState<User>();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    // ---------------------------------------
    // On init, use the organization values to set the company group/name

    useEffect(() => {
        form.setFieldsValue({
            company: {
                group: organization.group,
                name: organization.name,
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    // ---------------------------------------
    // Form changes and submit

    const onFormChange = () => {
        setFormValues(form.getFieldsValue());
    };

    const onFormValidSubmit: FormProps<User>['onFinish'] = (values) => {
        onSubmit(values);
        history.push(getRoute(RoutePathName.clientUserCreate, { step: CreateUserStep.job }));
    };

    // ---------------------------------------
    // Render

    return (
        <Form<User>
            layout="vertical"
            form={form}
            onFinish={onFormValidSubmit}
            initialValues={initialValues}
            scrollToFirstError
        >
            <Card className="form-card">
                {organization.group && (
                    <Form.Item
                        label={formatMessage(AccountMessages.infoCompanyGroup)}
                        name={['company', 'group']}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                )}
                <Form.Item
                    label={formatMessage(AccountMessages.infoCompanyFirm)}
                    name={['company', 'name']}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateUserMessages.profilLabel)}
                    name={['role', 'slug']}
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage(CreateUserMessages.profilPlaceholder)}
                        onChange={onFormChange}
                        showArrow
                    >
                        {[RoleSlug.user, RoleSlug.manager, RoleSlug.headHR].map((role) => (
                            <Select.Option value={role} key={role}>
                                <FormattedMessage {...rolesMessages.get(role)} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {(formValues?.role?.slug === RoleSlug.user) && (
                    <>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label={formatMessage(CreateUserMessages.jobPositionLabel)}
                                    name={['job', 'jobTitle']}
                                    rules={[requiredRule]}
                                >
                                    <Input
                                        placeholder={formatMessage(CreateUserMessages.jobPositionPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={formatMessage(CreateUserMessages.statusLabel)}
                                    name={['job', 'level']}
                                    rules={[requiredRule]}
                                >
                                    <Select
                                        placeholder={formatMessage(CreateUserMessages.statusPlaceholder)}
                                        showArrow
                                    >
                                        {Object.values(JobLevel).map((level) => (
                                            <Select.Option value={level} key={level}>
                                                <EnumMessage map={jobStatusMessages} value={level} />
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                {(formValues?.role?.slug) && (
                    <Form.Item
                        label={formatMessage(CreateUserMessages.groupsLabel)}
                        name="groups"
                        rules={[requiredRule]}
                    >
                        <GroupsFilterSelect
                            placeholder={formatMessage(CreateUserMessages.groupsPlaceholder)}
                            size="large"
                            multi={true}
                            filters={{
                                filters: [{
                                    name: 'organization',
                                    value: organization.id,
                                }],
                            }}
                        />
                    </Form.Item>
                )}

                {(formValues?.role?.slug && formValues?.role?.slug !== RoleSlug.user) && (
                    <Form.Item
                        label={formatMessage(CreateUserMessages.scopeGroupsLabel)}
                        name={['scope', 'groups']}
                        rules={[requiredRule]}
                    >
                        <GroupsFilterSelect
                            placeholder={formatMessage(CreateUserMessages.scopeGroupsPlaceholder)}
                            size="large"
                            multi={true}
                            filters={{
                                filters: [{
                                    name: 'organization',
                                    value: organization?.id,
                                }],
                            }}
                        />
                    </Form.Item>
                )}

                {(formValues?.role?.slug && [RoleSlug.user, RoleSlug.manager].indexOf(formValues?.role?.slug) !== -1) && (
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage(CreateUserMessages.managerLabel)}
                                name={['job', 'manager']}
                            >
                                <UsersAutoComplete
                                    placeholder={formatMessage(CreateUserMessages.managerPlaceholder)}
                                    size="middle"
                                    filters={{
                                        filters: [{
                                            name: 'organization',
                                            value: organization.id,
                                        }],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage(CreateUserMessages.headHRLabel)}
                                name={['job', 'hrRepresentative']}
                                rules={[requiredRule]}
                            >
                                <UsersAutoComplete
                                    placeholder={formatMessage(CreateUserMessages.managerPlaceholder)}
                                    size="middle"
                                    filters={{
                                        filters: [{
                                            name: 'organization',
                                            value: organization.id,
                                        }],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}

            </Card>
            <Form.Item className="mobile-fixed-submit">
                <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    <FormattedMessage {...genericMessages.continue} />
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateUserJobForm;
