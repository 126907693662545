import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M12.867 9.04C14.594 9.04 16 14.08 16 16H7.032c0-1.92 1.406-6.96 3.133-6.96zM6.835 5.597c.826 0 1.422.36 1.814 1.76-.5.96-1.815 1.47-2.722 5.61H0c0-2.954 2.406-7.37 4.133-7.37zm4.68-2.194c1.104 0 2 .954 2 2.126s-.896 2.126-2 2.126c-1.102 0-2-.954-2-2.127 0-1.173.898-2.127 2-2.127zM5.486 0c1.102 0 2 .954 2 2.127 0 1.172-.898 2.126-2 2.126-1.104 0-2-.954-2-2.126C3.484.954 4.38 0 5.484 0z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconContacts: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-contacts${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconContacts;
