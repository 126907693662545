import React, { FC, useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { Dayjs } from 'dayjs';

import '../../assets/styles/NetworkingActionCreate.less';

import { AppointmentType, NetworkingActionType, ProgramTaskValidation, Appointment, ContactType } from '../../store/api/apiTypes';
import * as appointmentsActions from '../../store/actions/appointments';
import { MainReducerState } from '../../store/reducers';

import Seo from '../../components/Seo';
import FullscreenLayout from '../../components/FullscreenLayout';
import { IconNetworkingActions } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import { classNames } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious, useQueryParams } from '../../hooks';
import NetworkingActionCreateMessages from './NetworkingActionCreateMessages';
import NetworkingActionCreateWhat from './NetworkingActionCreateWhat';
import NetworkingActionCreateWho from './NetworkingActionCreateWho';
import NetworkingActionCreateWhen from './NetworkingActionCreateWhen';

export interface NetworkingActionCreateValues {
    contact?: string;
    contactType?: ContactType;
    actionType?: NetworkingActionType;
    type?: AppointmentType;
    address?: string;
    phone?: string;
    url?: string;
    date?: Dayjs;
    time?: Dayjs;
    title?: string;
    parent?: Appointment['id'];
    parentTitle?: Appointment['title'];
    notes?: Appointment['notes'];
    noteText?: string;
}

export enum NetworkingActionCreateStep {
    what = 'what',
    who = 'who',
    when = 'when',
}

interface NetworkingActionCreatePageProps {
    createAction: typeof appointmentsActions.create.trigger;
    createActionState: appointmentsActions.AppointmentsState['create'];
}

const NetworkingActionCreatePage: FC<NetworkingActionCreatePageProps> = ({ createAction, createActionState }) => {
    const { formatMessage } = useIntl();
    const location = useLocation<{
        previous?: string,
        values?: NetworkingActionCreateValues,
        validationFields?: ProgramTaskValidation['fields'],
    } | undefined>();
    const queryParams = useQueryParams();
    const previous = usePrevious({ createActionState });
    const history = useHistory();
    const { step } = useParams<{ step: NetworkingActionCreateStep }>();
    const [previousPathname] = useState(location.state?.previous);
    const [validationToken] = useState(queryParams.get('validationToken'));
    const [mustCreateContact] = useState(!!location.state?.validationFields?.contact_type);
    const [values, setValues] = useState<NetworkingActionCreateValues | undefined>(
        location.state ?
            {
                ...location.state?.values,
                ...(location.state.validationFields ?
                        Object.entries(location.state.validationFields).reduce((acc, field) => {
                            if (field[0] === 'contact_type') {
                                return {
                                    ...acc,
                                    contactType: field[1].value,
                                };
                            } else {
                                return {
                                    ...acc,
                                    [field[0]]: field[1].value,
                                };
                            }
                        }, {}) :
                        {}
                ),
            } :
            undefined,
    );
    const onChangeValues = (val: NetworkingActionCreateValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            ...val,
        }));
    };
    const onSubmitWhen = (val: NetworkingActionCreateValues) => {
        onChangeValues(val);

        const payload = {
            ...values,
            ...val,
            ...(validationToken ? { taskValidationToken: validationToken } : {}),
        };

        delete payload.parentTitle;
        delete payload.noteText;

        createAction(payload);
    };
    const onQuit = useCallback(() => {
        history.replace(previousPathname || getRoute(RoutePathName.home));
    }, [history, previousPathname]);
    const onPrevious = () => {
        const steps = Object.values(NetworkingActionCreateStep);
        const previousStep = steps[steps.indexOf(step) - 1];
        history.replace(getRoute(RoutePathName.networkingActionCreate, { step: previousStep }));
    };

    useEffect(() => {
        if (previous?.createActionState.loading && !createActionState.loading) {
            if (createActionState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onQuit();
            }
        }
    }, [
        previous, createActionState.loading, createActionState.error, createActionState.data,
        formatMessage, onQuit,
    ]);

    let content = null;

    switch (step) {
        case NetworkingActionCreateStep.what:
            content = (
                <NetworkingActionCreateWhat
                    onSubmit={onChangeValues}
                    initialValues={values}
                />
            );
            break;

        case NetworkingActionCreateStep.who:
            if (!values?.actionType || !values?.title) {
                return <Redirect to={getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what })} />;
            }
            content = (
                <NetworkingActionCreateWho
                    onSubmit={onChangeValues}
                    initialValues={values}
                    mustCreateContact={mustCreateContact}
                />
            );
            break;

        case NetworkingActionCreateStep.when:
            if (!values?.contact) {
                return <Redirect to={getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what })} />;
            }
            content = (
                <NetworkingActionCreateWhen
                    onSubmit={onSubmitWhen}
                    initialValues={values}
                />
            );
            break;

        case undefined:
            return (
                <Redirect
                    to={{
                        pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
                        search: location.search,
                    }}
                />
            );

        default:
            return <Redirect to={getRoute(RoutePathName.notFound)} />;
    }

    return (
        <section id="create-action">
            <Seo title={formatMessage(NetworkingActionCreateMessages.title)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                onPrevious={onPrevious}
                title={<FormattedMessage {...NetworkingActionCreateMessages.title} />}
                icon={<IconNetworkingActions />}
                isFirstStep={step === NetworkingActionCreateStep.what}
            >
                <div id="create-action-steps">
                    <div id="create-action-steps-track-wrapper">
                        <div id="create-action-steps-track" />
                        <div
                            id="create-action-steps-progress"
                            style={{
                                width: step === NetworkingActionCreateStep.what ?
                                    '10%' :
                                    (step === NetworkingActionCreateStep.when ?
                                        '100%' : '50%'
                                    ),
                            }}
                        />
                    </div>
                    <div id="create-action-step-values">
                        <p className={classNames('step', step === NetworkingActionCreateStep.what && 'is-active')}>
                            <FormattedMessage {...NetworkingActionCreateMessages.what} />
                        </p>
                        <p className={classNames('step', step === NetworkingActionCreateStep.who && 'is-active')}>
                            <FormattedMessage {...NetworkingActionCreateMessages.who} />
                        </p>
                        <p className={classNames('step', step === NetworkingActionCreateStep.when && 'is-active')}>
                            <FormattedMessage {...NetworkingActionCreateMessages.when} />
                        </p>
                    </div>
                </div>
            </FullscreenLayout.Header>
            <FullscreenLayout.Content>
                <Spin spinning={createActionState.loading}>
                    {content}
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createActionState: appointmentsActions.getAppointmentsCreateState(state),
});

export default connect(
    mapStateToProps,
    {
        createAction: appointmentsActions.create.trigger,
    },
)(NetworkingActionCreatePage);
