import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M7.238 6.476c.21 0 .38.17.38.38v8.763c0 .21-.17.38-.38.38H.38a.38.38 0 0 1-.38-.38V6.856c0-.21.17-.38.38-.38zm8.38 3.81c.21 0 .382.17.382.38v4.953c0 .21-.17.38-.38.38H8.76a.38.38 0 0 1-.38-.38v-4.953c0-.21.17-.38.38-.38zM15.62 0c.21 0 .382.17.382.38v8.763c0 .21-.17.38-.38.38H8.76a.38.38 0 0 1-.38-.38V.38c0-.21.17-.38.38-.38zM7.24 0c.21 0 .38.17.38.38v4.953c0 .21-.17.38-.38.38H.38a.38.38 0 0 1-.38-.38V.38C0 .17.17 0 .38 0z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconHome: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-home${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconHome;
