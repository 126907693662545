import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Spin } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import {
    PacksState,
    search as searchPacks,
} from '../store/actions/packs';
import { SelectProps } from 'antd/lib/select';
import { Pack } from '../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PackListPayload } from '../store/api/packs';
import Select from './Select';

interface PackFilterSelectProps {
    value ?: Pack[];
    initialValue ?: Pack[];
    multi?: boolean;
    packs: PacksState;
    size?: SizeType;
    filters ?: PackListPayload;
    placeholder: string;
    onChange?: (value: any) => void;
    search: typeof searchPacks.trigger;
}

const PackFilterSelect: FC<PackFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, packs, search, multi, size, filters, placeholder} = props;
    const [ value, setValue ] = useState<Array<Pack['id']>>();
    const [ initialValue, setInitialValue ] = useState<Pack[]>([]);

    useEffect(() => {
        setValue([]);
        setInitialValue([]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value && props.value.length > 0 && typeof props.value[0] !== 'string') {
            setValue(props.value.map((a) => a.id));
            setInitialValue(props.value);
            if (onChange) { onChange(props.value.map((a) => a.id || a)); }
        }
    }, [props.value, props.initialValue, onChange]);

    const onFocus: SelectProps<Pack['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<Pack['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
        });
    };

    const onChangeSelect = (newValue: any) => {
        setValue(newValue);
        if (onChange) {onChange(newValue); }
    };

    let selectOptionsPacks: Pack[] = [...initialValue];
    if (packs.search?.data?.items) {
        selectOptionsPacks = [
            ...selectOptionsPacks,
            ...packs.search.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)),
        ];
    }

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder={placeholder || 'Recherchez un pack'}
            notFoundContent={packs.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
            showArrow
        >
            {
                selectOptionsPacks.map((pack) => (
                    <Select.Option value={pack.id} key={pack.id}>{pack.name}</Select.Option>
                ))
            }
        </Select>
    );

});

PackFilterSelect.defaultProps = {
    multi: false,
    size: 'large',
};

const mapStateToProps = (state: MainReducerState) => ({
    packs: state.packs,
});

export default connect(
    mapStateToProps,
    {
        search: searchPacks.trigger,
    },
    null,
    { forwardRef: true },
)(PackFilterSelect);
