import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 23">
        <path fill="currentColor" fillRule="nonzero" d="M16 0c11.26 0 15.74 10.657 15.925 11.112.1.25.1.527 0 .776C31.739 12.343 27.26 23 16 23 4.74 23 .26 12.343.075 11.888c-.1-.25-.1-.527 0-.776C.261 10.657 4.74 0 16 0Zm0 4.182c-4.124 0-7.467 3.276-7.467 7.318s3.343 7.318 7.467 7.318c4.122-.005 7.462-3.278 7.467-7.318 0-4.042-3.343-7.318-7.467-7.318Zm0 4.182c1.767 0 3.2 1.404 3.2 3.136 0 1.732-1.433 3.136-3.2 3.136-1.767 0-3.2-1.404-3.2-3.136 0-1.732 1.433-3.136 3.2-3.136Z"/>
    </svg>
);

const IconVisual: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-visual${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconVisual;
