import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Divider } from 'antd';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';

import '../../assets/styles/UserProgram.less';

import { MainReducerState } from '../../store/reducers';
import { TasksState, homeListMy, getHomeListMyTasksState } from '../../store/actions/tasks';
import { getMyProgramState, ProgramsState } from '../../store/actions/programs';
import { HomeListFilter } from '../../store/api/tasks';
import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';

import { useQueryParams } from '../../hooks';
import Filters from '../../components/Filters';
import SuspensionAlert from '../../components/SuspensionAlert';
import UserProgramTaskList from './UserProgramTaskList';
import HomeMessages from './HomeMessages';
import { getActiveSuspension } from '../../helpers/data';

interface UserProgramProps {
    programState: ProgramsState['my'];
    homeListMyTasks: typeof homeListMy.trigger;
    homeListMyTasksState: TasksState['homeListMy'];
    user?: User;
}

const UserProgram: FC<UserProgramProps> = ({ homeListMyTasks, homeListMyTasksState, programState, user }) => {
    const history = useHistory();
    const location = useLocation();
    const urlParams = useQueryParams();
    const filterParam = urlParams.get('filter');
    const currentFilter = filterParam ?
        Object.values(HomeListFilter).includes(filterParam as HomeListFilter) ?
            filterParam as HomeListFilter :
            HomeListFilter.all :
        HomeListFilter.all;
    const programStartDate = programState.data?.dates?.fromDate ?
        DateTime.fromISO(programState.data?.dates?.fromDate, { zone: 'UTC' }) :
        DateTime.utc();
    const isFirstWeek = DateTime.utc() < programStartDate.endOf('week') || DateTime.utc().equals(programStartDate.endOf('week'));
    const onPageSelect = (filter: HomeListFilter, page: number) => {
        homeListMyTasks({
            strictRange: true,
            currentFilter,
            programStartWeekNumber: programStartDate.weekNumber,
            [`${filter}Page`]: page - 1,
            ...(currentFilter === HomeListFilter.all ? { onlyRequestForThisFilter: filter } : {}),
        });
    };
    const onChangeFilter = (filter: string) => {
        urlParams.delete('page');
        urlParams.set('filter', filter);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const filterOptions = [
        { value: HomeListFilter.all, message: HomeMessages.userProgramFiltersAll },
        { value: HomeListFilter.currentWeek, message: HomeMessages.userProgramFiltersCurrentWeek },
        ...(!isFirstWeek ? [{ value: HomeListFilter.previousWeeks, message: HomeMessages.userProgramFiltersPreviousWeeks }] : []),
        { value: HomeListFilter.nextWeek, message: HomeMessages.userProgramFiltersNextWeek },
    ];

    useEffect(() => {
        if (!programState.loading && !programState.error && programState.data && programStartDate.weekNumber) {
            homeListMyTasks({
                strictRange: true,
                currentFilter,
                programStartWeekNumber: programStartDate.weekNumber,
            });
        }
    }, [
        homeListMyTasks, currentFilter, programState.loading, programState.error, programState.data,
        programStartDate.weekNumber,
    ]);

    return (
        <section id="home-user-program" className="block">
            <header>
                <Typography.Title level={2}>
                    <FormattedMessage {...HomeMessages.userProgramTitle} />
                </Typography.Title>
                <Filters
                    filterOptions={filterOptions}
                    onChangeFilter={onChangeFilter}
                    filterValue={currentFilter}
                />
            </header>
            {currentFilter === HomeListFilter.all ? (
                <>
                    <UserProgramTaskList
                        listState={homeListMyTasksState.currentWeek}
                        onPageSelect={onPageSelect.bind(null, HomeListFilter.currentWeek)}
                        programStartDate={programStartDate}
                        programLoading={programState.loading}
                        currentFilter={HomeListFilter.currentWeek}
                    />
                    {!isFirstWeek && (
                        <>
                            <Divider orientation="left" className="home-user-program-divider" plain>
                                <FormattedMessage {...HomeMessages.userProgramPreviousWeeksActions} />
                            </Divider>
                            <UserProgramTaskList
                                listState={homeListMyTasksState.previousWeeks}
                                onPageSelect={onPageSelect.bind(null, HomeListFilter.previousWeeks)}
                                programStartDate={programStartDate}
                                programLoading={programState.loading}
                                currentFilter={HomeListFilter.previousWeeks}
                            />
                        </>
                    )}
                    <Divider orientation="left" className="home-user-program-divider" plain>
                        <FormattedMessage {...HomeMessages.userProgramNextWeekActions} />
                    </Divider>
                    <SuspensionAlert margin />
                    {!getActiveSuspension(user) && (
                        <UserProgramTaskList
                            listState={homeListMyTasksState.nextWeek}
                            onPageSelect={onPageSelect.bind(null, HomeListFilter.nextWeek)}
                            programStartDate={programStartDate}
                            programLoading={programState.loading}
                            currentFilter={HomeListFilter.nextWeek}
                        />
                    )}
                </>
            ) : (
                <UserProgramTaskList
                    listState={homeListMyTasksState[currentFilter]}
                    onPageSelect={onPageSelect.bind(null, currentFilter)}
                    programStartDate={programStartDate}
                    programLoading={programState.loading}
                    currentFilter={currentFilter}
                />
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    homeListMyTasksState: getHomeListMyTasksState(state),
    programState: getMyProgramState(state),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        homeListMyTasks: homeListMy.trigger,
    },
)(UserProgram);
