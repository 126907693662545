import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getRawRoute, RoutePathName, getRoute } from '../../routes';
import ErrorPage from '../error';
import ClientDashboard from './dashboard/ClientDashboard';
import ClientLayout from '../../components/ClientLayout';
import ClientUsersList from './users/ClientUsersList';
import ClientUser from './users/ClientUser';
import ClientGroupsList from './groups/ClientGroupsList';
import FullscreenLayout from '../../components/FullscreenLayout';
import CreateGroup from './groups/CreateGroup';
import ClientOrganization from './organization/ClientOrganization';
import CreateUser from './users/CreateUser';
import Account from '../account';
import { connect } from 'react-redux';
import { getUser } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { User, RoleSlug } from '../../store/api/apiTypes';
import { hasRole } from '../../helpers/security';
import ProgramClosure from './users/ProgramClosure';

interface ClientProps {
    user?: User;
}

const Client: FC<ClientProps> = ({user}) => {

    if (!hasRole(user, [RoleSlug.superAdmin, RoleSlug.admin, RoleSlug.owner, RoleSlug.headHR, RoleSlug.manager])) {
        return <Redirect to={getRoute(RoutePathName.home)} />;
    }

    return (
        <Switch>
            <Route
                exact
                path={getRawRoute(RoutePathName.clientGroupsForm)}
            >
                <FullscreenLayout>
                    <CreateGroup />
                </FullscreenLayout>
            </Route>

            <Route
                exact
                path={getRawRoute(RoutePathName.clientUserCreate)}
            >
                <FullscreenLayout>
                    <CreateUser />
                </FullscreenLayout>
            </Route>

            <Route
                exact
                path={getRawRoute(RoutePathName.clientUserProgramClosure)}
            >
                <FullscreenLayout>
                    <ProgramClosure />
                </FullscreenLayout>
            </Route>

            <Route path="*">
                <ClientLayout>
                    <Switch>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientDashboard)}
                        >
                            <ClientDashboard />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientUsers)}
                        >
                            <ClientUsersList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientUser)}
                            component={ClientUser}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientGroups)}
                            component={ClientGroupsList}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientOrganization)}
                            component={ClientOrganization}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.clientAccount)}
                        >
                            <Account />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </ClientLayout>
            </Route>
        </Switch>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Client);
