import React from 'react';
import { Select as AntSelect, Spin } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';

import { classNames } from '../helpers';

class Select<ValueType extends SelectValue = SelectValue> extends AntSelect<ValueType> {
    public render() {
        return (
            <AntSelect
                {...this.props}
                className={classNames('custom-select', this.props.className)}
                suffixIcon={this.props.loading ?
                    <Spin /> :
                    <CaretDownFilled style={{ color: '#7881B2' }} />
                }
            >
                {this.props.children}
            </AntSelect>
        );
    }
}

export default Select;
