import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M11.31 6.443H9.97V2.73C9.97 1.222 8.736 0 7.213 0H4.787C3.264 0 2.029 1.222 2.029 2.73v3.713H.69A.685.685 0 000 7.125v8.192c0 .378.308.683.69.683h10.62c.382 0 .69-.305.69-.683V7.125a.685.685 0 00-.69-.682zM6.5 12.64c0 .094-.078.17-.172.17h-.862a.172.172 0 01-.172-.17v-1.13a1.019 1.019 0 01-.382-1.147c.138-.423.536-.71.985-.71.449 0 .847.287.984.71.138.424-.016.887-.381 1.146v1.13zm1.92-6.197H3.58V2.73c0-.66.54-1.195 1.207-1.195h2.426c.665 0 1.206.535 1.206 1.195v3.712z"/>
    </svg>
);

const IconLock: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-lock${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconLock;
