import { Layout } from 'antd';
import React, { FC, useContext } from 'react';

import { LayoutContextProvider, LayoutContext } from '../context/LayoutContext';
import Header from './Header';
import Footer from './Footer';
import ClientMenu from './ClientMenu';

const ClientLayout: FC = ({ children }) => {
    const { isMenuOpen } = useContext(LayoutContext);

    return (
        <LayoutContextProvider>
            <div className={isMenuOpen ? 'is-menu-open' : undefined}>
                <Layout id="main-layout">
                    <Header menu={<ClientMenu />} />
                    <div className="container" id="main-content-wrapper">
                        <Layout>
                            <Layout.Content id="client-content">
                                {children}
                            </Layout.Content>
                        </Layout>
                    </div>
                    <Footer />
                </Layout>
            </div>
        </LayoutContextProvider>
    );
};

export default ClientLayout;
