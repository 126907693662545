import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path d="M6 0C2.683 0 0 2.683 0 6s2.683 6 6 6 6-2.683 6-6-2.683-6-6-6zm0 2.468a.669.669 0 110 1.338.669.669 0 010-1.338zM5.52 8.484V5.339h-.48a.13.13 0 01-.096-.04.131.131 0 01-.04-.097v-.68c0-.074.062-.136.137-.136l1.3.002c.075 0 .136.061.136.136v3.96h.536c.075 0 .136.061.136.136v.684a.137.137 0 01-.136.136H4.986a.137.137 0 01-.136-.136V8.62c0-.075.061-.136.136-.136h.535z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconInformation: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-information${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconInformation;
