import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAction } from '../helpers/EzeeAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { MainReducerState } from '../reducers';

import {
    list as listApiCall,
    faq as faqApiCall,
} from '../api/staticPages';
import { StaticPage, StaticPageSlug, QuestionSection } from '../api/apiTypes';

// State

export interface StaticPagesState {
    loading: boolean;
    error?: any;
    data: {
        [key in StaticPageSlug]?: StaticPage;
    };
    faq: QuestionSection[];
}

const initialState: StaticPagesState = {
    loading: false,
    data: {},
    faq: [],
};

// Actions/Reducers

export const details = new EzeeAsyncAction<
    StaticPagesState, StaticPageSlug
>('staticPages/details', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: {
            ...state.data,
            [payload.slug]: payload,
        },
    }),
    failure: (state, payload) => ({
        ...state,
        data: {},
        loading: false,
        error: payload,
    }),
    reset: () => initialState,
});

export const faq = new EzeeAsyncAction<
    StaticPagesState
>('staticPages/faq', initialState, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        faq: payload.items,
    }),
    failure: (state, payload) => ({
        ...state,
        faq: [],
        loading: false,
        error: payload,
    }),
    reset: () => initialState,
});

// Reducer

export const staticPagesReducer = EzeeAction.mergeActionReducers<StaticPagesState>([
    details,
    faq,
]);

// Saga

export function* staticPagesSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(listApiCall, details));
    yield takeLatest(faq.type.trigger, simpleAsyncSaga(faqApiCall, faq));
}

// Store helpers

export const getStaticPagesListState = (state: MainReducerState) => state.staticPages;

export interface StaticPageState {
    loading: boolean;
    error?: any;
    data?: StaticPage;
    faq?: QuestionSection[];
}

type GetStaticPageStateBySlug = (state: MainReducerState, slug: StaticPageSlug) => StaticPageState;

export const getStaticPageStateBySlug: GetStaticPageStateBySlug = (state, slug) => ({
    ...state.staticPages,
    data: state.staticPages.data[slug],
});
