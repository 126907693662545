import React, { createContext, useState, FC, useMemo } from 'react';

interface DashboardContextTypes {
    dateRange: [number, number];
    setDateRange: React.Dispatch<React.SetStateAction<[number, number]>>;
}

export const DashboardContext = createContext<DashboardContextTypes>({
    dateRange: [(new Date().setDate(new Date().getDate() - 7)).valueOf(), new Date().valueOf()],
    setDateRange: () => undefined,
});

export const DashboardContextProvider: FC = ({ children }) => {
    const [dateRange, setDateRange] = useState<[number, number]>([(new Date().setDate(new Date().getDate() - 7)).valueOf(), new Date().valueOf()]);

    const dashboardContext = useMemo(() => ({
        dateRange,
        setDateRange,
    }), [dateRange]);

    return (
        <DashboardContext.Provider value={dashboardContext}>
            {children}
        </DashboardContext.Provider>
    );
};
