import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'create_contact.title',
        defaultMessage: 'Ajouter un contact',
        description: 'Create contact page title',
    },
    lastNameLabel: {
        id: 'create_contact.form.last_name.label',
        defaultMessage: 'Nom',
        description: 'Create contact form',
    },
    lastNamePlaceholder: {
        id: 'create_contact.form.last_name.placeholder',
        defaultMessage: 'Nom du contact',
        description: 'Create contact form',
    },
    firstNameLabel: {
        id: 'create_contact.form.first_name.label',
        defaultMessage: 'Prénom',
        description: 'Create contact form',
    },
    firstNamePlaceholder: {
        id: 'create_contact.form.first_name.placeholder',
        defaultMessage: 'Prénom du contact',
        description: 'Create contact form',
    },
    civilityLabel: {
        id: 'create_contact.form.civility.label',
        defaultMessage: 'Civilité',
        description: 'Create contact form',
    },
    civilityPlaceholder: {
        id: 'create_contact.form.civility.placeholder',
        defaultMessage: 'Sélectionner un titre',
        description: 'Create contact form',
    },
    typeLabel: {
        id: 'create_contact.form.type.label',
        defaultMessage: 'Type de contact',
        description: 'Create contact form',
    },
    typePlaceholder: {
        id: 'create_contact.form.type.placeholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Create contact form',
    },
    companyLabel: {
        id: 'create_contact.form.company.label',
        defaultMessage: 'Entreprise',
        description: 'Create contact form',
    },
    companyPlaceholder: {
        id: 'create_contact.form.company.placeholder',
        defaultMessage: 'Entreprise du contact',
        description: 'Create contact form',
    },
    jobOccupationLabel: {
        id: 'create_contact.form.job_occupation.label',
        defaultMessage: 'Fonction',
        description: 'Create contact form',
    },
    jobOccupationPlaceholder: {
        id: 'create_contact.form.job_occupation.placeholder',
        defaultMessage: 'Fonction du contact',
        description: 'Create contact form',
    },
    serviceLabel: {
        id: 'create_contact.form.service.label',
        defaultMessage: 'Service / département',
        description: 'Create contact form',
    },
    servicePlaceholder: {
        id: 'create_contact.form.service.placeholder',
        defaultMessage: 'Service / département de rattachement',
        description: 'Create contact form',
    },
    emailLabel: {
        id: 'create_contact.form.email.label',
        defaultMessage: 'Adresse e-mail',
        description: 'Create contact form',
    },
    emailPlaceholder: {
        id: 'create_contact.form.email.placeholder',
        defaultMessage: 'Adresse e-mail',
        description: 'Create contact form',
    },
    phoneLabel: {
        id: 'create_contact.form.phone.label',
        defaultMessage: 'Téléphone',
        description: 'Create contact form',
    },
    phonePlaceholder: {
        id: 'create_contact.form.phone.placeholder',
        defaultMessage: 'Numéro de téléphone',
        description: 'Create contact form',
    },
    mobilePhoneLabel: {
        id: 'create_contact.form.mobile_phone.label',
        defaultMessage: 'Portable',
        description: 'Create contact form',
    },
    mobilePhonePlaceholder: {
        id: 'create_contact.form.mobile_phone.placeholder',
        defaultMessage: 'Numéro de portable',
        description: 'Create contact form',
    },
    addressTitle: {
        id: 'create_contact.form.address',
        defaultMessage: 'Adresse(s)',
        description: 'Create contact form',
    },
    addressTypeLabel: {
        id: 'create_contact.form.address_type.label',
        defaultMessage: 'Type d\'adresse',
        description: 'Create contact form',
    },
    addressTypePlaceholder: {
        id: 'create_contact.form.address_type.placeholder',
        defaultMessage: 'Sélectionner une adresse',
        description: 'Create contact form',
    },
    addressStreetLabel: {
        id: 'create_contact.form.address_street.label',
        defaultMessage: 'Adresse',
        description: 'Create contact form',
    },
    addressStreetPlaceholder: {
        id: 'create_contact.form.address_street.placeholder',
        defaultMessage: 'Saisir une adresse',
        description: 'Create contact form',
    },
    referrerLabel: {
        id: 'create_contact.form.referrer.label',
        defaultMessage: 'Prescripteur',
        description: 'Create contact form',
    },
    referrerPlaceholder: {
        id: 'create_contact.form.referrer.placeholder',
        defaultMessage: 'Sélectionner un contact',
        description: 'Create contact form',
    },
    commentLabel: {
        id: 'create_contact.form.comment.label',
        defaultMessage: 'Notes',
        description: 'Create contact form',
    },
    commentPlaceholder: {
        id: 'create_contact.form.comment.placeholder',
        defaultMessage: 'Saisir des notes',
        description: 'Create contact form',
    },
    submit: {
        id: 'create_contact.form.submit',
        defaultMessage: 'Créer le contact',
        description: 'Create contact form',
    },
    successModalTitle: {
        id: 'create_contact.success_modal.title',
        defaultMessage: 'Le contact « {name} » a été créé avec succès',
        description: 'Create contact success modal title',
    },
    successModalSeeContact: {
        id: 'create_contact.success_modal.see_contact',
        defaultMessage: 'Accéder à la fiche contact',
        description: 'Create contact success modal see contact link',
    },
    successModalCreateActionLink: {
        id: 'create_contact.success_modal.create_action',
        defaultMessage: 'Créer une action de networking',
        description: 'Create contact success modal create action link',
    },
});
