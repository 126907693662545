import axios, { AxiosError } from 'axios';
import { RequestError, Filter } from './';
import { constants } from '../../helpers';

const client = axios.create({
    baseURL: constants.API.BASE_URL,
    withCredentials: true, // automatically send saved cookie
});

const interceptorErrorHandler = (error: AxiosError) => {
    const formattedError: RequestError = {
        status: 0,
        message: '',
    };
    if (error.response) {
        formattedError.status = error.response.status;
        formattedError.data = error.response.data;
        formattedError.headers = error.response.headers;

        if (error.response.status === 401) {
            const e = document.createEvent('CustomEvent');
            e.initCustomEvent('unauthorized.error', true, false, 401);
            window.dispatchEvent(e);
        }
    }

    throw formattedError;
};

client.interceptors.response.use(
    (response) => response.data,
    interceptorErrorHandler,
);

export const cleanPayload = (payload: any) => {
    if (!payload.sortBy) { delete payload.sortBy; }
    if (!payload.sort) { delete payload.sort; }
    if (!payload.order) { delete payload.order; }
    if (!payload.search) { delete payload.search; }

    if (payload.order === 'ascend') { payload.sortOrder = 'asc'; delete payload.order; }
    if (payload.order === 'descend') { payload.sortOrder = 'desc'; delete payload.order; }

    if (payload.filters !== undefined) {
        payload.filters.forEach((filter: Filter) => {
            payload[filter.name] = filter.value;
        });

        delete payload.filters;
    }

    return payload;
};

const clientWithFullResponse = axios.create({
    baseURL: '/api',
    withCredentials: true, // automatically send saved cookie
});

clientWithFullResponse.interceptors.response.use(
    (response) => response,
    interceptorErrorHandler,
);

export {clientWithFullResponse};

export default client;
