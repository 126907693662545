import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'program.title',
        defaultMessage: 'Votre programme',
        description: 'Program page title',
    },
    taskHistoryTitle: {
        id: 'program.task_history.title',
        defaultMessage: 'Historique de vos actions',
        description: 'Program task history title',
    },
    taskHistoryCurrentWeek: {
        id: 'program.task_history.current_week',
        defaultMessage: 'En cours',
        description: 'Program task history current week',
    },
});
