import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M15.9998445,7.98226167 C15.9955304,6.68258538 14.9783059,5.60591005 13.6668468,5.51308206 C13.0503447,4.16941213 11.9611718,3.09277615 10.6018554,2.4833703 C10.6018554,1.11184275 9.47706578,0 8.08956742,0 C6.70206906,0 5.5772794,1.11184275 5.5772794,2.4833703 C4.21441645,3.0912448 3.12405128,4.17158345 2.51228802,5.5201774 C1.12478966,5.5201774 0,6.63202016 0,8.0035477 C0,9.37507524 1.12478966,10.486918 2.51228802,10.486918 C3.12724094,11.8340937 4.22015966,12.9119082 5.58445737,13.5166298 C5.5844574,14.8881573 6.70924705,16 8.09674539,16 C9.48424373,16 10.6090334,14.8881573 10.6090334,13.5166298 C11.9718964,12.9087553 13.0622615,11.8284166 13.6740248,10.4798227 C14.9953657,10.3861012 16.0144704,9.29166984 15.9998445,7.98226167 L15.9998445,7.98226167 Z M10.2573131,12.0691796 C9.8114345,11.3109359 8.99117134,10.8443939 8.10392335,10.8443939 C7.21667537,10.8443939 6.39641221,11.3109359 5.95053362,12.0691796 C5.10845386,11.6272736 4.42058535,10.9448503 3.97659304,10.1108648 C4.74366622,9.67011848 5.21564082,8.85929697 5.21564082,7.98226167 C5.21564082,7.10522636 4.74366622,6.29440485 3.97659304,5.85365856 C4.41727199,5.01727025 5.10601108,4.33398322 5.95053362,3.89534369 C6.39458502,4.66713757 7.2236474,5.14384061 8.12186827,5.14384061 C9.02008913,5.14384061 9.84915151,4.66713757 10.2932029,3.89534369 C11.1251828,4.3410816 11.8027873,5.02324452 12.2384316,5.85365856 C11.4713585,6.29440485 10.9993839,7.10522636 10.9993839,7.98226167 C10.9993839,8.85929697 11.4713585,9.67011848 12.2384316,10.1108648 C11.7893146,10.9435265 11.0996708,11.6252322 10.2573131,12.0691796 Z" />
    </svg>
);

const IconGroups: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-groups${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconGroups;
