import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M14.4 16H1.6C.72 16 0 15.28 0 14.4V1.6C0 .72.72 0 1.6 0h12.8c.88 0 1.6.72 1.6 1.6v12.8c0 .88-.72 1.6-1.6 1.6zM12.92 4.66c-.38-.34-.98-.34-1.34 0l-5.14 4.8-2.02-1.88c-.38-.34-.98-.34-1.34 0-.38.34-.38.9 0 1.26l2.68 2.5c.38.34.98.34 1.34 0l5.82-5.44c.38-.34.38-.9 0-1.24z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconNetworkingActions: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-networking-actions${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconNetworkingActions;
