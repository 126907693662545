import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Skeleton, Divider } from 'antd';

import '../../../assets/styles/ClientDashboard.less';

import { User, RoleSlug } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import * as packsActions from '../../../store/actions/packs';
import { getUser } from '../../../store/actions/auth';

import Seo from '../../../components/Seo';
import clientMessages from '../../../locale/clientMessages';
import Can from '../../../components/Can';
import ClientDashboardMessages from './ClientDashboardMessages';
import Reporting from './Reporting';
import DashboardBuilder from '../../../components/reporting/DashboardBuilder';

interface HomeProps {
    user?: User;
    packsStatsState: packsActions.PacksState['stats'];
    getStats: typeof packsActions.stats.trigger;
}

const ClientDashboard: FC<HomeProps> = ({
    user,
    packsStatsState,
    getStats,
}) => {
    const { formatMessage } = useIntl();
    const stats = packsStatsState.data;

    useEffect(() => {
        getStats();
    }, [getStats]);

    return (
        <div id="client-dashboard">
            <Seo title={formatMessage(ClientDashboardMessages.title)} />
            <Typography.Title level={1}>
                <FormattedMessage
                    {...ClientDashboardMessages.mainTitle}
                    values={{
                        name: user?.firstName,
                    }}
                />
            </Typography.Title>
            {packsStatsState.loading ?
                <Skeleton paragraph={false} active title={{ width: '70%' }} /> :
                (
                    <>
                        <Can roles={[RoleSlug.admin, RoleSlug.superAdmin]}>
                            <Typography.Paragraph>
                                <FormattedMessage
                                    {...ClientDashboardMessages.summary}
                                    values={{
                                        packs: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage
                                                    {...clientMessages.packCount}
                                                    values={{ count: stats?.licensePacks.active }}
                                                />
                                            </strong>
                                        ),
                                        sieges: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage
                                                    {...clientMessages.availableSiegeCount}
                                                    values={{ count: stats?.licensePacks.availableLicenseCount }}
                                                />
                                            </strong>
                                        ),
                                    }}
                                    tagName="p"
                                />
                            </Typography.Paragraph>
                        </Can>

                        <Can roles={[RoleSlug.headHR]}>
                            <Typography.Paragraph>
                                <FormattedMessage
                                    {...ClientDashboardMessages.summaryRH}
                                    values={{
                                        users: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage
                                                    {...clientMessages.packCount}
                                                    values={{ count: stats?.licensePacks.licensesCount }}
                                                />
                                            </strong>
                                        ),
                                        groups: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage {...clientMessages.groupCount} values={{ count: stats?.groups.total }} />
                                            </strong>
                                        ),
                                        sieges: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage
                                                    {...clientMessages.availableSiegeCount}
                                                    values={{ count: stats?.licensePacks.availableLicenseCount }}
                                                />
                                            </strong>
                                        ),
                                    }}
                                    tagName="p"
                                />
                            </Typography.Paragraph>
                        </Can>

                        <Can roles={[RoleSlug.manager]}>
                            <Typography.Paragraph>
                                <FormattedMessage
                                    {...ClientDashboardMessages.summaryManager}
                                    values={{
                                        users: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage
                                                    {...clientMessages.packCount}
                                                    values={{ count: stats?.licensePacks.active }}
                                                />
                                            </strong>
                                        ),
                                        groups: (
                                            <strong className="text-primary text-lowercase">
                                                <FormattedMessage {...clientMessages.groupCount} values={{ count: stats?.groups.total }} />
                                            </strong>
                                        ),
                                    }}
                                    tagName="p"
                                />
                            </Typography.Paragraph>
                        </Can>
                    </>
                )
            }
            <Divider />
            <DashboardBuilder>
                <Reporting />
            </DashboardBuilder>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    packsStatsState: state.packs.stats,
});

export default connect(
    mapStateToProps,
    {
        getStats: packsActions.stats.trigger,
    },
)(ClientDashboard);
