import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Typography, message } from 'antd';
import { exportInformations as exportAction, getAuthState } from '../../store/actions/auth';
import AccountMessages from './AccountMessages';
import { MainReducerState, RequestState } from '../../store/reducers';
import { downloadFile } from './../../helpers';
import genericMessages from '../../locale/genericMessages';
import { usePrevious } from '../../hooks';

interface AccountExportInformationsProps {
    exportState: RequestState;
    download: typeof exportAction.trigger;
}

const AccountExportInformations: FC<AccountExportInformationsProps> = ({ exportState, download }) => {

    const { formatMessage } = useIntl();
    const previous = usePrevious({exportState});
    useEffect(() => {
        if (previous?.exportState.loading && ! exportState.loading) {
      if (exportState.error) {
            message.error(formatMessage(genericMessages.defaultError));
        } else if (exportState.data) {
            const { url, filename } = exportState.data;
            downloadFile(url, filename);
        }
        }
    }, [exportState.loading, exportState.error, exportState.data, formatMessage, previous]);

    const handleExportClick = () => {
        download();
    };

    return (
        <>
            <Typography.Title level={2} className="h1">
                <FormattedMessage {...AccountMessages.exportInformationsTitle} />
            </Typography.Title>
            <Card>
                <Typography.Title level={3} className="secondary-title">
                    <FormattedMessage
                        {...AccountMessages.exportInformationsFormTitle}
                    />
                </Typography.Title>
                <p style={{ marginBottom: '2rem' }}>
                    <FormattedMessage
                        {...AccountMessages.exportInformationsFormHelp}
                    />
                </p>
                <Button
                    onClick={handleExportClick}
                    type="primary"
                    size="large"
                    loading={exportState.loading}
                >
                    <FormattedMessage {...AccountMessages.exportInformationsFormSubmit} />
                </Button>
            </Card>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    exportState: getAuthState(state).exportState,
});

export default connect(
    mapStateToProps,
    { download: exportAction.trigger },
)(AccountExportInformations);
