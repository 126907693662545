import React, { FC, useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Badge, Table } from 'antd';
import { TableProps, ColumnProps } from 'antd/lib/table';

import { User, UserProgramSubTask, SubTaskType, SurveyAnswerType, SurveySubTaskType } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { FilterQuery } from '../../store/api';
import {
    surveysList as surveysListAcion, UsersState,
} from '../../store/actions/users';
import { UserSubTaskListPayload } from '../../store/api/users';

import ClientUsersMessages from '../client/users/ClientUsersMessages';
import { getRoute, RoutePathName } from '../../routes';
import genericMessages from '../../locale/genericMessages';
import BasicList from '../../components/BasicList';
import ButtonLink from '../../components/ButtonLink';
import DocumentsMessages from './DocumentsMessages';

const rowKey = (item: User) => `${item.id}`;

interface SurveysListProps {
    getList: typeof surveysListAcion.trigger;
    surveysList: UsersState['surveysList'];
}

const SurveysList: FC<SurveysListProps> = ({
    getList,
    surveysList,
}) => {
    const { formatMessage } = useIntl();
    const [ initialized, setInitialized ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState<Partial<UserSubTaskListPayload>>({});
    const [data, setData] = useState<UserProgramSubTask[]>();

    // ---------------------------------------
    // Initial search

    useEffect(() => {
        setLastSearchParams({
            type: [SubTaskType.survey],
            surveySubType: SurveySubTaskType.basic,
            page: 0,
            hasResponded: true,
            pageSize: 999,
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On search params change, reload the list

    const refresh = useCallback(() => {
        getList({...lastSearchParams});
    }, [getList, lastSearchParams]);

    useEffect(() => {
        if (lastSearchParams && initialized) { refresh(); }
    }, [lastSearchParams, initialized, refresh]);

    // ---------------------------------------
    // On search params change, reload the list

    useEffect(() => {
        setData(surveysList.data?.items.filter((s) => !s.subTask?.surveySubType || (s.subTask?.surveySubType === SurveySubTaskType.basic)));
    }, [surveysList]);

    // ---------------------------------------
    // Handle search, filters and sort changes

    const onTableChange: TableProps<UserProgramSubTask>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        const newSearchParams = {
            ...lastSearchParams,
            sort: (sorter.field) ? sorter.field[1] : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<UserProgramSubTask>> = [
        {
            dataIndex: ['task', 'name'],
            title: formatMessage(ClientUsersMessages.actionName),
        },
        {
            dataIndex: ['task', 'updatedAt'],
            title: formatMessage(ClientUsersMessages.answerDate),
            render: (updatedAt) => (
                <FormattedDate value={updatedAt} />
            ),
            sorter: true,
        },
    ];

    return (
        <div id="client-users-list">
            <Typography.Title level={2}>
                <FormattedMessage
                    {...DocumentsMessages.surveysTitle}
                />
                &nbsp;
                <Badge count={data?.length} overflowCount={10000} />
            </Typography.Title>
            <FormattedMessage {...DocumentsMessages.surveysDescription} tagName="p" />

            {data ? (
                <Table<UserProgramSubTask>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={surveysList.loading}
                    dataSource={data || []}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <>
                                <ul className="survey-answers">
                                    {record.subTask?.surveys?.map((survey) => (
                                        <li key={survey.id}>
                                            {survey.title && (
                                                <Typography.Title level={4}>
                                                    {survey.title}
                                                </Typography.Title>
                                            )}
                                            <BasicList>
                                                {survey.questions?.map((question) => (
                                                    <li key={question.id}>
                                                        <strong className="question">{question?.label}</strong>
                                                        {record.surveyResponses && (
                                                            <div>
                                                                {question?.type === SurveyAnswerType.boolean ? (
                                                                    <>
                                                                        {record.surveyResponses[question?.id]?.[0]?.values?.[0] === 'true' ? (
                                                                            <FormattedMessage {...genericMessages.yes} />
                                                                        ) : (
                                                                            <FormattedMessage {...genericMessages.no} />
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {record.surveyResponses[question?.id]?.[0]?.values?.join(',')}
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            </BasicList>
                                        </li>
                                    ))}
                                </ul>
                                <ButtonLink
                                    type="primary"
                                    to={getRoute(RoutePathName.programTask, { taskId: record.userTask?.id || '' })}
                                >
                                    <FormattedMessage {...DocumentsMessages.surveysEditAnswers} />
                                </ButtonLink>
                            </>
                        ),
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}
        </div>
    );
};

const mapStateToProps = (
    state: MainReducerState,
) => ({
    surveysList: state.users.surveysList,
});

export default connect(
    mapStateToProps,
    {
        getList: surveysListAcion.trigger,
    },
)(SurveysList);
