import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Card, Form, Input, Typography, message } from 'antd';
import { FormProps } from 'antd/lib/form';

import { MainReducerState } from '../../store/reducers';
import { update as updateAction, getAuthState, AuthState } from '../../store/actions/auth';

import PasswordInput from '../../components/PasswordInput';
import formMessages from '../../locale/formMessages';
import { stripUndefinedKeysFromObject } from '../../helpers';
import genericMessages from '../../locale/genericMessages';
import { usePrevious } from '../../hooks';
import AccountMessages from './AccountMessages';
import validatePasswordRules from '../../helpers/passwords';

interface AccountPasswordFormProps {
    authState: AuthState;
    update: typeof updateAction.trigger;
}

const AccountPasswordForm: FC<AccountPasswordFormProps> = ({ authState, update }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const previous = usePrevious({ authState });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const result = stripUndefinedKeysFromObject(values);
        setIsUpdating(true);
        update(result);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (previous?.authState.updateLoading && !authState.updateLoading && isUpdating) {
            if (authState.updateError) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                form.resetFields();
                message.success(formatMessage(AccountMessages.updateSuccess));
            }
            setIsUpdating(false);
        }
    }, [previous, authState.updateLoading, authState.updateError, formatMessage, isUpdating, form]);

    return (
        <>
            <Typography.Title level={2} className="h1">
                <FormattedMessage {...AccountMessages.passwordTitle} />
            </Typography.Title>
            <Card>
                <Form
                    onFinish={onFormValidSubmit}
                    layout="vertical"
                    requiredMark={false}
                    form={form}
                    style={{ maxWidth: 460 }}
                >
                    <Typography.Title level={3}>
                        <FormattedMessage {...AccountMessages.passwordFormTitle} />
                    </Typography.Title>
                    <Form.Item
                        name="oldPassword"
                        label={formatMessage(AccountMessages.currentPasswordLabel)}
                        rules={[requiredRule]}
                    >
                        <Input.Password
                            placeholder={formatMessage(AccountMessages.currentPasswordPlaceholder)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage(AccountMessages.newPasswordLabel)}
                        rules={[
                            requiredRule,
                            {
                                validator: (_, value) => {
                                    if (!validatePasswordRules(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(formatMessage(formMessages.invalidPassword));
                                },
                            },
                        ]}
                        name="password"
                        validateTrigger="onBlur"
                    >
                        <PasswordInput placeholder={formatMessage(AccountMessages.newPasswordPlaceholder)} />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={authState.updateLoading}
                                size="large"
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                }
                            >
                                <FormattedMessage {...AccountMessages.passwordSubmit} />
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        update: updateAction.trigger,
    },
)(AccountPasswordForm);
