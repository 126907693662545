import React, { FC, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, RouteComponentProps, useParams, Link, Route } from 'react-router-dom';
import { Space, Button, Modal, message, Form } from 'antd';

import { MainReducerState } from '../../store/reducers';
import {
    JobOffersState, JobOfferDetailsState, details, del, getJobOfferStateById,
    getJobOffersDeleteState, getJobOffersUpdateState, update,
} from '../../store/actions/jobOffers';
import { JobOffer } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import genericMessages from '../../locale/genericMessages';
import { IconTrash, IconArrowLeft, IconAddJobOffer } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import JobOfferDetailsMessages from './JobOfferDetailsMessages';
import { usePrevious } from '../../hooks';
import JobOfferDetailsCard from './JobOfferDetailsCard';
import CardSkeleton from '../../components/CardSkeleton';
import JobOffersMessages from '../jobOffers/JobOffersMessages';
import JobOfferForm from '../../components/JobOfferForm';
import { FormProps } from 'antd/lib/form';

interface MatchParams {
    jobOfferId: JobOffer['id'];
}

interface JobOfferDetailsProps extends RouteComponentProps<MatchParams> {
    jobOfferDetailsState: JobOfferDetailsState;
    jobOfferDelState: JobOffersState['del'];
    jobOfferUpdateState: JobOffersState['update'];
    deleteJobOffer: typeof del.trigger;
    fetchJobOffer: typeof details.trigger;
    updateJobOffer: typeof update.trigger;
}

const JobOfferDetails: FC<JobOfferDetailsProps> = ({
    jobOfferDetailsState, jobOfferDelState, jobOfferUpdateState, deleteJobOffer,
    fetchJobOffer, updateJobOffer,
}) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const { jobOfferId } = useParams<MatchParams>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const previous = usePrevious({ jobOfferDelState, jobOfferUpdateState });
    const onClickAddJobOfferCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.jobOfferCreate),
            state: {
                previous: getRoute(RoutePathName.jobOffers),
            },
        });
    };
    const onClickDelete = () => {
        setIsDeleteModalVisible(true);
        Modal.confirm({
            title: formatMessage(JobOffersMessages.deleteTitle),
            cancelText: formatMessage(genericMessages.cancel),
            okText: formatMessage(genericMessages.delete),
            onOk: () => {
                deleteJobOffer({ id: jobOfferId });
                setIsDeleteModalVisible(false);
            },
            icon: null,
            cancelButtonProps: {
                ghost: true,
            },
            okButtonProps: {
                danger: true,
                ghost: true,
            },
            visible: isDeleteModalVisible,
        });
    };
    const onFormValidSubmit: FormProps<Partial<JobOffer>>['onFinish'] = (val) => {
        updateJobOffer({ id: jobOfferId, jobOffer: val });
    };
    const onClickCancelEdit = () => {
        history.push(getRoute(RoutePathName.jobOffer, { jobOfferId }));
    };

    useEffect(() => {
        fetchJobOffer({ id: jobOfferId });
    }, [fetchJobOffer, jobOfferId]);

    useEffect(() => {
        if (previous?.jobOfferDelState.loading && !jobOfferDelState.loading) {
            if (jobOfferDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(JobOffersMessages.deleteSuccess));
                history.push(getRoute(RoutePathName.jobOffers));
            }
        }
    }, [
        previous, jobOfferDelState.loading, jobOfferDelState.error, formatMessage, history,
    ]);

    useEffect(() => {
        if (previous?.jobOfferUpdateState.loading && !jobOfferUpdateState.loading) {
            if (jobOfferUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                fetchJobOffer({ id: jobOfferId });
                history.push(getRoute(RoutePathName.jobOffer, { jobOfferId }));
            }
        }
    }, [
        previous, jobOfferUpdateState.loading, jobOfferUpdateState.error, formatMessage, history,
        jobOfferId, fetchJobOffer,
    ]);

    return (
        <section>
            <Seo title={formatMessage(JobOfferDetailsMessages.title)} />
            <HeaderCTA
                onClick={onClickAddJobOfferCTA}
                icon={<IconAddJobOffer />}
            >
                <FormattedMessage {...JobOffersMessages.addJobOfferCTA} />
            </HeaderCTA>
            <Space direction="vertical" size={24} style={{ width: '100%' }} className="block">
                <div className="flex flex-between">
                    <Link
                        to={getRoute(RoutePathName.jobOffers)}
                        className="back-link"
                        type="link"
                    >
                        <IconArrowLeft />
                        <FormattedMessage {...JobOfferDetailsMessages.backToList} />
                    </Link>
                    <Button
                        onClick={onClickDelete}
                        loading={jobOfferDelState.loading}
                        icon={<IconTrash />}
                        type="primary"
                        shape="circle"
                        size="small"
                        danger
                    />
                </div>
                <Route path={getRoute(RoutePathName.jobOffer, { jobOfferId })} exact>
                    {jobOfferDetailsState.loading && <CardSkeleton rows={12} />}
                    {!jobOfferDetailsState.loading && jobOfferDetailsState.data && (
                        <JobOfferDetailsCard jobOffer={jobOfferDetailsState.data} />
                    )}
                </Route>
                <Route path={getRoute(RoutePathName.jobOfferEdit, { jobOfferId })} exact>
                    <JobOfferForm
                        form={form}
                        onFinish={onFormValidSubmit}
                        jobOffer={jobOfferDetailsState.data}
                        submit={(
                            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                                {() => (
                                    <div className="flex flex-between">
                                        <Button
                                            type="ghost"
                                            onClick={onClickCancelEdit}
                                        >
                                            <FormattedMessage {...genericMessages.cancel} />
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={jobOfferUpdateState.loading}
                                            disabled={
                                                form &&
                                                (
                                                    !form.isFieldsTouched() ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                )
                                            }
                                        >
                                            <FormattedMessage {...JobOfferDetailsMessages.updateSubmit} />
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        )}
                        isEditing
                    />
                </Route>
            </Space>
        </section>
    );
};

const mapStateToProps = (
    state: MainReducerState,
    { match: { params: { jobOfferId } } }: JobOfferDetailsProps,
) => ({
    jobOfferDetailsState: getJobOfferStateById(state, jobOfferId),
    jobOfferDelState: getJobOffersDeleteState(state),
    jobOfferUpdateState: getJobOffersUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        fetchJobOffer: details.trigger,
        deleteJobOffer: del.trigger,
        updateJobOffer: update.trigger,
    },
)(JobOfferDetails);
