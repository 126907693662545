import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M11.4084507,3.48460708 C11.7307394,3.48460708 11.9928307,3.67841972 11.9998554,3.9195071 L12,3.92944103 L12,4.94620437 C12,7.27862083 9.61789455,9.21097735 6.59189253,9.4361735 L6.591,11.11 L8.70422535,11.1103321 C9.03092901,11.1103321 9.29577465,11.3341897 9.29577465,11.6103321 C9.29577465,11.8827428 9.03803863,12.1042723 8.71743571,12.1102099 L3.29577465,12.1103321 C2.96907099,12.1103321 2.70422535,11.8864745 2.70422535,11.6103321 C2.70422535,11.3379214 2.96196137,11.1163918 3.28256429,11.1104543 L5.408,11.11 L5.40780354,9.43615088 C2.41415334,9.21324609 0.0507800911,7.31948448 0.000807498592,5.02054346 L0,3.92944103 C0,3.68376602 0.264845641,3.48460708 0.591549296,3.48460708 C0.913838036,3.48460708 1.17592931,3.67841972 1.182954,3.9195071 L1.18309859,3.92944103 L1.18309859,4.94620437 C1.18309859,6.93740869 3.35205577,8.56842375 6,8.56842375 C8.62146479,8.56842375 10.7734744,6.96986589 10.8162524,5.00583117 L10.8169014,3.92944103 C10.8169014,3.68376602 11.081747,3.48460708 11.4084507,3.48460708 Z M6.04147707,0.000293099868 C7.40677806,0.0168047044 8.47058824,0.847671894 8.47058824,1.90506458 L8.47058824,1.90506458 L8.47058824,4.61602924 C8.47058824,5.67947848 7.35946144,6.54545455 6.00001282,6.54545455 C4.64830083,6.54545455 3.52941176,5.66602263 3.52941176,4.61602898 L3.52941176,4.61602898 L3.52941176,1.88730631 C3.53282741,1.3876609 3.79173207,0.909120002 4.25005791,0.555715799 C4.71450831,0.197589098 5.34538963,-0.00248055034 6.00222566,2.32201267e-05 L6.00222566,2.32201267e-05 Z" />
    </svg>
);

const IconPodcast: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-podcast${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconPodcast;
