import { ProgramTaskFamily, LibraryDocumentType, SurveyAnswerIntelligence } from '../../store/api/apiTypes';
import * as icons from './';

export { default as IconAddContact } from './IconAddContact';
export { default as IconAddJobOffer } from './IconAddJobOffer';
export { default as IconArrowLeft } from './IconArrowLeft';
export { default as IconArrowLeftCircle } from './IconArrowLeftCircle';
export { default as IconArrowRight } from './IconArrowRight';
export { default as IconArrowRightCircle } from './IconArrowRightCircle';
export { default as IconBadge } from './IconBadge';
export { default as IconBell } from './IconBell';
export { default as IconCalendar } from './IconCalendar';
export { default as IconCamera } from './IconCamera';
export { default as IconCheck } from './IconCheck';
export { default as IconCheckCircle } from './IconCheckCircle';
export { default as IconClose } from './IconClose';
export { default as IconCommunication } from './IconCommunication';
export { default as IconCompany } from './IconCompany';
export { default as IconContacts } from './IconContacts';
export { default as IconCrossCircle } from './IconCrossCircle';
export { default as IconCV } from './IconCV';
export { default as IconDashboard } from './IconDashboard';
export { default as IconDocuments } from './IconDocuments';
export { default as IconEvent } from './IconEvent';
export { default as IconFile } from './IconFile';
export { default as IconHiring } from './IconHiring';
export { default as IconHome } from './IconHome';
export { default as IconInformation } from './IconInformation';
export { default as IconInterpersonal } from './IconInterpersonal';
export { default as IconInterview } from './IconInterview';
export { default as IconLinkedin } from './IconLinkedin';
export { default as IconLock } from './IconLock';
export { default as IconMail } from './IconMail';
export { default as IconMathematical } from './IconMathematical';
export { default as IconMore } from './IconMore';
export { default as IconNetworking } from './IconNetworking';
export { default as IconNetworkingActions } from './IconNetworkingActions';
export { default as IconOffers } from './IconOffers';
export { default as IconOffice } from './IconOffice';
export { default as IconOther } from './IconOther';
export { default as IconPhone } from './IconPhone';
export { default as IconPlace } from './IconPlace';
export { default as IconPlus } from './IconPlus';
export { default as IconPodcast } from './IconPodcast';
export { default as IconPrivacy } from './IconPrivacy';
export { default as IconProfessionalProject } from './IconProfessionalProject';
export { default as IconProgram } from './IconProgram';
export { default as IconReferred } from './IconReferred';
export { default as IconReferrer } from './IconReferrer';
export { default as IconReporting } from './IconReporting';
export { default as IconRetirement } from './IconRetirement';
export { default as IconSearch } from './IconSearch';
export { default as IconTerms } from './IconTerms';
export { default as IconTrash } from './IconTrash';
export { default as IconTrophy } from './IconTrophy';
export { default as IconVideo } from './IconVideo';
export { default as IconVisual } from './IconVisual';
export { default as IconIntrapersonal } from './IconInterpersonal';
export { default as IconNaturalist } from './IconNaturalist';
export { default as IconMusical } from './IconMusical';
export { default as IconPhysical } from './IconPhysical';
export { default as IconVerbal } from './IconVerbal';

export const taskTypeIcons = new Map([
    [ProgramTaskFamily.cv, icons.IconCV],
    [ProgramTaskFamily.linkedin, icons.IconLinkedin],
    [ProgramTaskFamily.professionalProject, icons.IconProfessionalProject],
    [ProgramTaskFamily.offer, icons.IconOffers],
    [ProgramTaskFamily.networking, icons.IconNetworking],
    [ProgramTaskFamily.communication, icons.IconCommunication],
    [ProgramTaskFamily.hiring, icons.IconHiring],
    [ProgramTaskFamily.privateLife, icons.IconLock],
    [ProgramTaskFamily.retirement, icons.IconRetirement],
    [ProgramTaskFamily.businessCreation, icons.IconOffice],
    [ProgramTaskFamily.other, icons.IconOther],
]);

export const documentTypeIcons = new Map([
    [LibraryDocumentType.file, icons.IconFile],
    [LibraryDocumentType.video, icons.IconVideo],
    [LibraryDocumentType.podcast, icons.IconPodcast],
]);

export const IntelligenceSurveyIcons = new Map([
    [SurveyAnswerIntelligence.interpersonal, icons.IconInterpersonal],
    [SurveyAnswerIntelligence.intrapersonal, icons.IconIntrapersonal],
    [SurveyAnswerIntelligence.mathematical, icons.IconMathematical],
    [SurveyAnswerIntelligence.musical, icons.IconMusical],
    [SurveyAnswerIntelligence.naturalist, icons.IconNaturalist],
    [SurveyAnswerIntelligence.physical, icons.IconPhysical],
    [SurveyAnswerIntelligence.verbal, icons.IconVerbal],
    [SurveyAnswerIntelligence.visual, icons.IconVisual],
]);
