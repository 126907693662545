import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'home.title',
        defaultMessage: 'Accueil',
        description: 'Home page title',
    },
    mainTitle: {
        id: 'home.main_title',
        defaultMessage: 'Bonjour {name}',
        description: 'Home page title',
    },
    summaryTasks: {
        id: 'home.summary.tasks',
        defaultMessage: `
            {count, plural,
                =0 {Bravo, vous avez atteint l'objectif de la semaine.}
                one {Pour atteindre votre objectif de la semaine, il vous reste {actions} à réaliser.}
                other {Pour atteindre votre objectif de la semaine, il vous reste {actions} à réaliser.}
            }
        `,
        description: 'Home page title',
    },
    summaryMeetings: {
        id: 'home.summary.meetings',
        defaultMessage: `
            {count, plural,
                =0 {}
                one {{br}Vous avez par ailleurs {meetings} programmé pour cette semaine. N’hésitez pas à en prendre le maximum pour les semaines à venir !}
                other {{br}Vous avez par ailleurs {meetings} programmés pour cette semaine. N’hésitez pas à en prendre le maximum pour les semaines à venir !}
            }
        `,
        description: 'Home page title',
    },
    actionCount: {
        id: 'action_count',
        defaultMessage: `
            {count, plural,
                =0 {Aucune Action}
                one {1 Action}
                other {{count, number} Actions}
            }
        `,
        description: 'Action count',
    },
    meetingCount: {
        id: 'meeting_count',
        defaultMessage: `
            {count, plural,
                =0 {Aucun Rendez-vous}
                one {1 Rendez-vous}
                other {{count, number} Rendez-vous}
            }
        `,
        description: 'Meeting count',
    },
    editAccountModalTitle: {
        id: 'home.edit_account_modal.title',
        defaultMessage: 'Bienvenue dans Mon coach Mobilité',
        description: 'Home edit account modal title',
    },
    editAccountModalContent: {
        id: 'home.edit_account_modal.content',
        defaultMessage: 'Nous vous invitons à consulter votre profil et à compléter les informations manquantes',
        description: 'Home edit account modal content',
    },
    editAccountModalSubmit: {
        id: 'home.edit_account_modal.submit',
        defaultMessage: 'Accéder à votre profil',
        description: 'Home edit account modal submit',
    },
    gaugesTitle: {
        id: 'home.user_stats.gauges.title',
        defaultMessage: 'Votre baromètre',
        description: 'Home user stats gauges title',
    },
    gaugesActionHelp: {
        id: 'home.user_stats.gauges.action.help',
        defaultMessage: 'Votre baromètre « Actions » comptabilise chaque semaine les points gagnés en suivant votre programme. Il est remis à zéro chaque lundi.',
        description: 'Home user stats gauges title',
    },
    gaugesProgressionHelp: {
        id: 'home.user_stats.gauges.progression.help',
        defaultMessage: 'Votre baromètre de « Progression » est un pourcentage calculé sur la base de plusieurs facteurs : 25% représentent votre assiduité sur la semaine en cours, 25% représentent les actions validées sur les trois dernières semaines et 50% représentent votre Networking (nombre d’actions de Networking saisies et effectuées)',
        description: 'Home user stats gauges title',
    },
    interviewMetricLabel: {
        id: 'home.user_stats.metric.interview.label',
        defaultMessage: 'Nombre de rendez-vous',
        description: 'Home user stats metric label',
    },
    badgesTitle: {
        id: 'home.user_stats.badges.title',
        defaultMessage: 'Vos badges',
        description: 'Home user stats badges title',
    },
    weekMeetingsTitle: {
        id: 'home.week_meetings.title',
        defaultMessage: 'Vos rendez-vous de la semaine',
        description: 'Home week meetings block title',
    },
    weekMeetingsNoResult: {
        id: 'home.week_meetings.empty',
        defaultMessage: 'Aucun rendez-vous cette semaine',
        description: 'Home week meetings block title',
    },
    userProgramTitle: {
        id: 'home.user_program.title',
        defaultMessage: 'Vos actions à réaliser',
        description: 'Home user program block title',
    },
    userProgramFiltersAll: {
        id: 'home.user_program.filters.all',
        defaultMessage: 'Toutes les actions',
        description: 'Home user program filter',
    },
    userProgramFiltersCurrentWeek: {
        id: 'home.user_program.filters.current_week',
        defaultMessage: 'Cette semaine',
        description: 'Home user program filter',
    },
    userProgramFiltersPreviousWeeks: {
        id: 'home.user_program.filters.previous_weeks',
        defaultMessage: 'Semaines précédentes',
        description: 'Home user program filter',
    },
    userProgramFiltersNextWeek: {
        id: 'home.user_program.filters.next_week',
        defaultMessage: 'Semaine suivante',
        description: 'Home user program filter',
    },
    userProgramPreviousWeeksActions: {
        id: 'home.user_program.separator.previous_weeks',
        defaultMessage: 'Actions des semaines précédentes',
        description: 'Home user program section separator',
    },
    userProgramNextWeekActions: {
        id: 'home.user_program.separator.next_week',
        defaultMessage: 'Actions de la semaine prochaine',
        description: 'Home user program section separator',
    },
    userProgramEmptyListCurrent: {
        id: 'home.user_program.empty_list.current',
        defaultMessage: 'Aucunes actions à faire cette semaine pour le moment',
        description: 'Home user program empty list',
    },
    userProgramEmptyListNext: {
        id: 'home.user_program.empty_list.next',
        defaultMessage: 'Aucunes actions à faire la semaine prochaine pour le moment',
        description: 'Home user program empty list',
    },
    userProgramEmptyListPrevious: {
        id: 'home.user_program.empty_list.previous',
        defaultMessage: 'Aucunes actions à faire des semaines précédentes',
        description: 'Home user program empty list',
    },
});
