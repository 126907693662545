import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Button, Tag, Space, Upload, Divider, message, Alert } from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UploadProps, RcFile } from 'antd/lib/upload';

import '../assets/styles/SubTask.less';

import {
    ProgramSubTask, SubTaskType, UserProgramTask, ProgramValidationType, UserProgramSubTaskStatus,
    getSubTaskTypeMessage, DocumentSubTaskType, SurveySubTaskType, SubTaskSubType, SurveyResponses,
} from '../store/api/apiTypes';
import { MainReducerState } from '../store/reducers';
import {
    validate, getValidateStateByToken, TaskValidateState, getSubTaskUpdateStateBySubTaskId,
    updateSubTask as updateSubTaskAction, SubTaskUpdateState,
} from '../store/actions/tasks';
import { create, getLibraryCreateStateBySubTaskId, LibraryDocumentCreateState } from '../store/actions/library';

import ProgramTaskDetailsMessages from '../pages/programTaskDetails/ProgramTaskDetailsMessages';
import genericMessages from '../locale/genericMessages';
import formMessages from '../locale/formMessages';
import { useIsMobile, usePrevious } from '../hooks';
import { classNames, getDocumentTypeFromMimeType } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import { IconCheckCircle, IconTrophy } from './icons';
import ButtonLink from './ButtonLink';
import Survey from './surveys/Survey';
import TricamSurvey from './surveys/TricamSurvey';
import ColorsSurvey from './surveys/ColorsSurvey';
import IntelligenceSurvey from './surveys/IntelligenceSurvey';

interface SubTaskProps {
    data: ProgramSubTask;
    isTaskInTheFuture: boolean;
    onValidate: () => void;
    documentCreateState: LibraryDocumentCreateState;
    createDocument: typeof create.trigger;
    taskId: UserProgramTask['id'];
    taskFamily: UserProgramTask['family'];
    updateSubTask: typeof updateSubTaskAction.trigger;
    updateState: SubTaskUpdateState;
    validateState: TaskValidateState;
    validateSubTask: typeof validate.trigger;
}

const SubTask: FC<SubTaskProps> = ({
    data, documentCreateState, createDocument, isTaskInTheFuture, onValidate, taskId, taskFamily,
    updateSubTask, updateState, validateState, validateSubTask,
}) => {
    const [hasClickedLink, setHasClickedLink] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { formatMessage, locale } = useIntl();
    const [file, setFile] = useState<RcFile | undefined>();
    const previous = usePrevious({ validateState, updateState, documentCreateState });
    const isMobile = useIsMobile();
    const isValidationAutomatic = !!data.validation && data.validation?.type === ProgramValidationType.automatic;
    const isValidationManual = !!data.validation && data.validation?.type === ProgramValidationType.manual;
    const isValidated = data.userSubTask?.status === UserProgramSubTaskStatus.validated;
    const beforeUpload: UploadProps['beforeUpload'] = (value) => {
        setFile(value);

        return false;
    };
    const onSendValidate = useCallback((values?: any) => {
        validateSubTask({
            ...values,
            token: data.userSubTask?.validationToken,
        });
    }, [data.userSubTask, validateSubTask]);
    const onSubmitUpload = () => {
        createDocument({
            subTaskId: data.id,
            document: {
                document: file,
                name: file?.name,
                family: taskFamily,
                language: locale,
                type: getDocumentTypeFromMimeType(file?.type),
            },
        });
    };
    const onSendUpload = useCallback(() => {
        if (data.validation && !isValidated) {
            if (isValidationAutomatic) {
                onSendValidate({
                    document: documentCreateState.data?.id,
                });
            }
        } else {
            updateSubTask({
                taskId,
                subTaskId: data.id,
                values: {
                    document: documentCreateState.data?.id,
                },
            });
        }
    }, [
        data.id, data.validation, documentCreateState.data, isValidationAutomatic, onSendValidate,
        taskId, updateSubTask, isValidated,
    ]);
    const onSubmitSurvey = (values?: SurveyResponses) => {
        // @ts-expect-error
        if ([SurveySubTaskType.intelligence, SurveySubTaskType.colors, SurveySubTaskType.tricam].includes(data.surveySubType)) {
            onSendValidate({
                surveyResponses: values,
            });
        } else {
            if (data.validation && !isValidated) {
                onSendValidate({
                    surveyResponses: values,
                });
            } else {
                updateSubTask({
                    taskId,
                    subTaskId: data.id,
                    values: {
                        surveyResponses: values,
                    },
                });
            }
        }
    };
    const onLinkClick = () => {
        setHasClickedLink(true);

        if (isValidationAutomatic) {
            onSendValidate();
        }
    };
    let content = null;

    useEffect(() => {
        if (previous?.validateState.loading && !validateState.loading) {
            if (validateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onValidate();

                // scroll to top of subtask on validate success
                window.setTimeout(() => {
                    if (wrapperRef.current) {
                        console.log(wrapperRef.current?.offsetTop);
                        window.scrollTo(0, wrapperRef.current?.getBoundingClientRect()?.top + window.pageYOffset - 32);
                    }
                }, 200);
            }
        }
    }, [previous, validateState.loading, validateState.error, formatMessage, onValidate]);

    useEffect(() => {
        if (previous?.updateState.loading && !updateState.loading) {
            if (updateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success({
                    content: formatMessage(genericMessages.successfullySaved),
                    style: {
                        fontSize: '1rem',
                    },
                });
                onValidate();

                // scroll to top of subtask on update success
                window.setTimeout(() => {
                    if (wrapperRef.current) {
                        console.log(wrapperRef.current?.offsetTop);
                        window.scrollTo(0, wrapperRef.current?.getBoundingClientRect()?.top + window.pageYOffset - 32);
                    }
                }, 200);
            }
        }
    }, [previous, updateState.loading, updateState.error, formatMessage, onValidate]);

    useEffect(() => {
        if (previous?.documentCreateState.loading && !documentCreateState.loading) {
            if (documentCreateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onSendUpload();
            }
        }
    }, [previous, documentCreateState.loading, documentCreateState.error, formatMessage, onSendUpload]);

    switch (data.type) {
        case SubTaskType.action:
            let pathname;
            let buttonMessage;

            switch (data.subType) {
                case SubTaskSubType.contact:
                    pathname = getRoute(RoutePathName.contactCreate);
                    buttonMessage = ProgramTaskDetailsMessages.createContact;
                    break;

                case SubTaskSubType.hiring:
                    pathname = getRoute(RoutePathName.jobOfferCreate);
                    buttonMessage = ProgramTaskDetailsMessages.createJobOffer;
                    break;

                case SubTaskSubType.networking:
                    pathname = getRoute(RoutePathName.networkingActionCreate);
                    buttonMessage = ProgramTaskDetailsMessages.createNetworkingAction;
                    break;
            }

            content = (
                <>
                    <ButtonLink
                        type="primary"
                        to={{
                            pathname,
                            ...(isValidationAutomatic ? { search: `validationToken=${data.userSubTask?.validationToken || ''}` } : {}),
                            state: {
                                previous: getRoute(RoutePathName.programTask, { taskId }),
                                validationFields: data.validation?.fields,
                            },
                        }}
                        disabled={isTaskInTheFuture}
                    >
                        <FormattedMessage
                            {...(isTaskInTheFuture ?
                                ProgramTaskDetailsMessages.inTheFuture :
                                buttonMessage
                            )}
                        />
                    </ButtonLink>
                    {!isValidated && isValidationManual && (
                        <Button
                            type="primary"
                            onClick={onSendValidate.bind(null, undefined)}
                            loading={validateState.loading}
                            disabled={isTaskInTheFuture}
                        >
                            <FormattedMessage {...ProgramTaskDetailsMessages.validate} />
                        </Button>
                    )}
                </>
            );
            break;

        case SubTaskType.document:
            content = (
                data.libraryDocument?.id ? (
                    <>
                        <div className="sub-task-document flex-lg-between">
                            <p className="value">{data.libraryDocument?.name || 'Document'}</p>
                            <ButtonLink
                                type="primary"
                                onClick={!isValidationManual ? onSendValidate.bind(null, undefined) : undefined}
                                to={{
                                    pathname: getRoute(RoutePathName.libraryDocument, { documentId: data.libraryDocument?.id || '' }),
                                    state: {
                                        previous: getRoute(RoutePathName.programTask, { taskId }),
                                        download: data.documentSubType === DocumentSubTaskType.toDo,
                                    },
                                }}
                                disabled={isTaskInTheFuture}
                            >
                                <FormattedMessage
                                    {...(isTaskInTheFuture ?
                                        ProgramTaskDetailsMessages.inTheFuture :
                                        (
                                            data.documentSubType === DocumentSubTaskType.toRead ?
                                                ProgramTaskDetailsMessages.linkToDocumentToRead :
                                                ProgramTaskDetailsMessages.linkToDocument
                                        )
                                    )}
                                />
                            </ButtonLink>
                        </div>
                        {!isValidated && isValidationManual && (
                            <Button
                                type="primary"
                                onClick={onSendValidate.bind(null, undefined)}
                                loading={validateState.loading}
                                disabled={isTaskInTheFuture}
                            >
                                <FormattedMessage {...ProgramTaskDetailsMessages.validate} />
                            </Button>
                        )}
                    </>
                ) : (
                    <Alert type="error" message={<FormattedMessage {...ProgramTaskDetailsMessages.missingDocument} />} />
                )
            );
            break;

        case SubTaskType.text:
            content = (
                <>
                    {data.description && (
                        <div className="sub-task-description" dangerouslySetInnerHTML={{ __html: data.description }} />
                    )}
                    {!isValidated && isValidationManual && (
                        <Button
                            type="primary"
                            onClick={onSendValidate.bind(null, undefined)}
                            loading={validateState.loading}
                            disabled={isTaskInTheFuture}
                        >
                            <FormattedMessage
                                {...ProgramTaskDetailsMessages.validate}
                            />
                        </Button>
                    )}
                </>
            );
            break;

        case SubTaskType.link:
            content = (
                <div className="flex-between">
                    <ButtonLink
                        type="primary"
                        onClick={onLinkClick}
                        href={data.link}
                        target="_blank"
                        to=""
                        disabled={isTaskInTheFuture}
                    >
                        <FormattedMessage
                            {...(isTaskInTheFuture ?
                                ProgramTaskDetailsMessages.inTheFuture :
                                ProgramTaskDetailsMessages.linkSubmit
                            )}
                        />
                    </ButtonLink>
                    {!isValidated && isValidationManual && hasClickedLink && (
                        <Button
                            type="primary"
                            onClick={onSendValidate.bind(null, undefined)}
                            loading={validateState.loading}
                            disabled={isTaskInTheFuture}
                        >
                            <FormattedMessage {...ProgramTaskDetailsMessages.validate} />
                        </Button>
                    )}
                </div>
            );
            break;

        case SubTaskType.survey: {
            if (isTaskInTheFuture) {
                content = <Alert type="info" message={<FormattedMessage {...ProgramTaskDetailsMessages.inTheFuture} />} />;
            } else {
                switch (data.surveySubType) {
                    case SurveySubTaskType.tricam:
                        content = (
                            <TricamSurvey
                                data={data.surveys}
                                language={data.language}
                                responses={data.userSubTask?.surveyResponses}
                                onSubmit={onSubmitSurvey}
                                loading={validateState.loading || updateState.loading}
                                subTaskStatus={data.status}
                                partialResults={data.tricamWithPartialResults}
                            />
                        );
                        break;

                    case SurveySubTaskType.colors:
                        content = (
                            <ColorsSurvey
                                data={data.surveys?.[0]}
                                responses={data.userSubTask?.surveyResponses}
                                onSubmit={onSubmitSurvey}
                                loading={validateState.loading || updateState.loading}
                                subTaskStatus={data.status}
                            />
                        );
                        break;

                    case SurveySubTaskType.intelligence:
                        content = (
                            <IntelligenceSurvey
                                data={data.surveys?.[0]}
                                language={data.language}
                                responses={data.userSubTask?.surveyResponses}
                                onSubmit={onSubmitSurvey}
                                loading={validateState.loading || updateState.loading}
                                subTaskStatus={data.status}
                            />
                        );
                        break;

                    default:
                        content = (
                            <Survey
                                data={data.surveys?.[0]}
                                surveySubType={data.surveySubType}
                                initialValues={data.userSubTask?.surveyResponses}
                                onSubmit={onSubmitSurvey}
                                loading={validateState.loading || updateState.loading}
                                requiredCount={data.validation?.requiredCount}
                            />
                        );
                        break;
                }
            }
            break;
        }

        case SubTaskType.upload:
            content = (
                <Space direction="vertical" size="middle">
                    <Upload
                        beforeUpload={beforeUpload}
                        onRemove={setFile.bind(null, undefined)}
                        fileList={file ?
                            [file] :
                            (
                                data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1] ?
                                    [{
                                        uid: data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1]?.id || '',
                                        size: 0,
                                        name: data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1]?.name || '',
                                        type: '',
                                        url: data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1]?.id ?
                                            getRoute(RoutePathName.libraryDocument, {
                                                documentId: data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1]?.id,
                                            }) :
                                            data.userSubTask?.documents?.[data.userSubTask?.documents.length - 1]?.url,
                                    }] :
                                    undefined
                            )
                        }
                        disabled={isTaskInTheFuture}
                    >
                        <Button type="primary" disabled={isTaskInTheFuture} ghost>
                            <FormattedMessage {...formMessages.chooseFile} />
                        </Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={onSubmitUpload}
                        loading={validateState.loading || updateState.loading || documentCreateState.loading}
                        disabled={!file || isTaskInTheFuture}
                    >
                        <FormattedMessage
                            {...(isTaskInTheFuture ?
                                ProgramTaskDetailsMessages.inTheFuture :
                                ProgramTaskDetailsMessages.uploadSubmit
                            )}
                        />
                    </Button>
                </Space>
            );
            break;

        default:
            break;
    }

    return (
        <div
            className={classNames(
                'sub-task',
                isValidated && 'is-complete',
            )}
            id={data.id}
            ref={wrapperRef}
        >
            <div className="sub-task-content-wrapper">
                <header className="flex-between">
                    <Space size={isMobile ? 8 : 12}>
                        <Tag>
                            {!!getSubTaskTypeMessage(data) && (
                                <FormattedMessage
                                    {...getSubTaskTypeMessage(data)}
                                />
                            )}
                        </Tag>
                        {!!data.points && (
                            <Tag icon={<IconTrophy />} className="text-lowercase">
                                <FormattedMessage
                                    {...genericMessages.pointsShortPlural}
                                    values={{
                                        count: data.points,
                                        value: <FormattedNumber value={data.points} />,
                                    }}
                                />
                            </Tag>
                        )}
                    </Space>
                    {isValidated && (
                        <IconCheckCircle />
                    )}
                </header>
                <div className="sub-task-body">
                    <Divider />
                    {content}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (
    state: MainReducerState,
    { data }: Partial<SubTaskProps>,
) => ({
    validateState: getValidateStateByToken(state, data?.validationToken),
    updateState: getSubTaskUpdateStateBySubTaskId(state, data?.id),
    documentCreateState: getLibraryCreateStateBySubTaskId(state, data?.id),
});

export default connect(
    mapStateToProps,
    {
        validateSubTask: validate.trigger,
        updateSubTask: updateSubTaskAction.trigger,
        createDocument: create.trigger,
    },
)(SubTask);
