import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    PackDetailsPayload,
    PackListPayload,
    details as detailsApiCall,
    stats as statsApiCall,
    list as listApiCall,
} from '../api/packs';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, Pack, DashboardStats } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface PacksState {
    list: RequestState<ListResponse<Pack>>;
    search: RequestState<ListResponse<Pack>>;
    details: RequestState<Pack | undefined>;
    stats: RequestState<DashboardStats | undefined>;
}

const initialState: PacksState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    stats: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    PacksState['list'],
    PackListPayload,
    ListResponse<Pack>
>('packs/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    PacksState['search'],
    PackListPayload,
    ListResponse<Pack>
>('packs/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    PacksState['details'],
    PackDetailsPayload,
    Pack
>('packs/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const stats = new EzeeAsyncAction<
    PacksState['stats'],
    PackDetailsPayload,
    DashboardStats
>('packs/stats', initialState.stats, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.stats,
    }),
});

// Reducer

export const packsReducer = combineReducers<PacksState>({
    list: list.reducer,
    details: details.reducer,
    stats: stats.reducer,
    search: search.reducer,
});

// Saga

export function* packsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(stats.type.trigger, simpleAsyncSaga(statsApiCall, stats));
}

// Store helpers

export const getPacksState = (state: MainReducerState) => state.packs;
