import React, { FC, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { FormattedMessage } from 'react-intl';
import { Typography, Switch, Button, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { MainReducerState } from '../../store/reducers';
import { getCookiesState, cookies, CookiesState, CookieNames } from '../../store/actions/cookies';
import { StaticPageState, details, getStaticPageStateBySlug } from '../../store/actions/staticPages';
import { StaticPageSlug } from '../../store/api/apiTypes';

import { acceptAllCookies, refuseAllCookies, setCookiesOptions, removeGoogleTagManager, addGoogleTagManager } from '../../helpers/cookies';
import StaticPage from '../../components/StaticPage';
import PrivacyMessages from './PrivacyMessages';
import PublicLayout from '../../components/PublicLayout';

// tslint:disable: max-line-length

interface PrivacyProps {
    accept: typeof cookies.actions.acceptAll;
    cookiesState: CookiesState;
    pageState: StaticPageState;
    fetchPage: typeof details.trigger;
    refuse: typeof cookies.actions.refuse;
    toggle: typeof cookies.actions.toggle;
}

const Privacy: FC<PrivacyProps> = ({
    accept, cookiesState, fetchPage, pageState, refuse, toggle,
}) => {
    const [_, setCookie, removeCookie] = useCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [showSuccess, setShowSuccess] = useState(false);
    const onToggle = (cookie: string) => {
        toggle(cookie);

        if (cookie === CookieNames.GTAG) {
            if (cookiesState.gtag) {
                removeGoogleTagManager(removeCookie);
                setCookie(CookieNames.GTAG, 0, setCookiesOptions);
            } else {
                addGoogleTagManager();
                setCookie(CookieNames.GTAG, 1, setCookiesOptions);
            }
        }
    };
    const onClickAccept = useCallback(() => {
        acceptAllCookies(setCookie);
        accept();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [accept, setCookie]);
    const onClickRefuse = useCallback(() => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [refuse, removeCookie, setCookie]);

    useEffect(() => {
        if (!pageState.data) {
            fetchPage(StaticPageSlug.privacyPolicy);
        }
    }, [fetchPage, pageState.data]);

    return (
        <PublicLayout>
            <Typography.Title level={1}>{pageState.data?.title}</Typography.Title>
            <StaticPage
                pageState={pageState}
                small
            >
                <div style={{ marginTop: 40 }}>
                    <Typography.Title level={3} id="cookies">
                        <FormattedMessage {...PrivacyMessages.privacyCookiesTitle} />
                    </Typography.Title>

                    <Typography.Title level={3}>
                        <FormattedMessage {...PrivacyMessages.privacyCookiesOptionalTitle} />
                    </Typography.Title>
                    <ul>
                        <li className="flex">
                            <Switch checked={!!cookiesState.gtag} onChange={onToggle.bind(null, 'ga')} style={{marginRight: 24}} />
                            <p>
                                <FormattedMessage {...PrivacyMessages.privacyCookiesGoogleAnalytics} />{' '}
                                <code>ezee-ga, _gat, _gid, _ga</code>
                            </p>
                        </li>
                    </ul>
                    <Typography.Title level={3}>
                        <FormattedMessage {...PrivacyMessages.privacyCookiesMandatoryTitle} />
                    </Typography.Title>
                    <ul>
                        <li className="flex">
                            <Switch checked disabled style={{marginRight: 24}} />
                            <p>
                                <FormattedMessage {...PrivacyMessages.privacyCookiesChoiceCookie} />{' '}
                                <code>ezee-has_made_choice, refreshToken</code>
                            </p>
                        </li>
                        <li className="flex">
                            <Switch checked disabled style={{marginRight: 24}} />
                            <p>
                                <FormattedMessage {...PrivacyMessages.privacyCookiesSession} />{' '}
                                <code>refreshToken, accessToken</code>
                            </p>
                        </li>
                    </ul>
                    <div className="flex">
                        <Button
                            type="ghost"
                            onClick={onClickRefuse}
                        >
                            <FormattedMessage {...PrivacyMessages.rejectAll} />
                        </Button>
                        <Button
                            type="primary"
                            onClick={onClickAccept}
                        >
                            <FormattedMessage {...PrivacyMessages.acceptAll} />
                        </Button>
                        {showSuccess && (
                            <div>
                                <Tag
                                    icon={<CheckCircleOutlined />}
                                    color="success"
                                >
                                    Enregistré
                                </Tag>
                            </div>
                        )}
                    </div>
                </div>
            </StaticPage>
        </PublicLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    cookiesState: getCookiesState(state),
    pageState: getStaticPageStateBySlug(state, StaticPageSlug.privacyPolicy),
});

export default connect(
    mapStateToProps,
    {
        accept: cookies.actions.acceptAll,
        fetchPage: details.trigger,
        refuse: cookies.actions.refuse,
        toggle: cookies.actions.toggle,
    },
)(Privacy);
