import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
    LibraryDocumentDetailsPayload,
    LibraryDocumentCreatePayload,
    LibraryDocumentListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
} from '../api/library';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, LibraryDocument } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface LibraryState {
    readonly list: RequestState<ListResponse<LibraryDocument>>;
    readonly details: RequestState<LibraryDocument | undefined>;
    readonly create: {
        [token: string]: RequestState<LibraryDocument>;
    };
}

const initialState: LibraryState = {
    list: {
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {},
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    LibraryState['list'],
    LibraryDocumentListPayload,
    ListResponse<LibraryDocument>
>('library/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    LibraryState['details'],
    LibraryDocumentDetailsPayload,
    LibraryDocument
>('library/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    LibraryState['create'],
    LibraryDocumentCreatePayload
>('library/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        [payload.subTaskId || '-1']: {
            loading: true,
            error: undefined,
        },
    }),
    success: (state, { subTaskId, ...payload }) => ({
        ...state,
        [subTaskId || '-1']: {
            loading: false,
            data: payload,
        },
    }),
    failure: (state, { subTaskId, ...payload }) => ({
        ...state,
        [subTaskId || '-1']: {
            loading: false,
            error: payload,
        },
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

// Reducer

export const libraryReducer = combineReducers<LibraryState>({
    details: details.reducer,
    list: list.reducer,
    create: create.reducer,
});

// Saga

export function* librarySaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, createSaga);
}

function* createSaga(action: DataAction<LibraryDocumentCreatePayload>) {
    const { subTaskId } = action.payload;

    try {
        const response = yield call(createApiCall, action.payload);

        return yield put(create.success({
            subTaskId,
            ...response,
        }));
    } catch (error) {
        return yield put(create.failure({
            subTaskId,
            ...error,
        }));
    }
}

// Store helpers

export const getLibraryListState = (state: MainReducerState) => state.library.list;
export type LibraryDocumentCreateState = RequestState;

export const getLibraryCreateStateBySubTaskId = (state: MainReducerState, subTaskId: string = '-1') => ({
    loading: subTaskId && state.library.create[subTaskId] ? state.library.create[subTaskId].loading : false,
    error: subTaskId && state.library.create[subTaskId] ? state.library.create[subTaskId].error : undefined,
    data: subTaskId && state.library.create[subTaskId] ? state.library.create[subTaskId].data : undefined,
});
