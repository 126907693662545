import React, { FC, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { ProgramClosure, User } from '../../../store/api/apiTypes';
import * as usersActions from '../../../store/actions/users';
import { MainReducerState } from '../../../store/reducers';
import FullscreenLayout from '../../../components/FullscreenLayout';
import Seo from '../../../components/Seo';
import { usePrevious } from '../../../hooks';
import genericMessages from '../../../locale/genericMessages';
import { getUser } from '../../../store/actions/auth';
import { getRoute, RoutePathName } from '../../../routes';
import ProgramClosureMessages from './ProgramClosureMessages';
import { IconProgram } from '../../../components/icons';
import ProgramClosureForm from './ProgramClosureForm';

interface ProgramClosureProps {
    user?: User;
    updateActionState: usersActions.UsersState['update'];
    detailsActionState: usersActions.UsersState['details'];
    updateAction: typeof usersActions.update.trigger;
    detailsAction: typeof usersActions.details.trigger;
    resetDetailsAction: typeof usersActions.details.reset;
}

const ProgramClosurePage: FC<ProgramClosureProps> = ({
    user,
    updateActionState,
    detailsActionState,
    updateAction,
    detailsAction,
    resetDetailsAction,
}) => {
    const { formatMessage } = useIntl();
    const { userId } = useParams<{ userId: User['id'] }>();
    const previous = usePrevious({ updateActionState });
    const history = useHistory();

    // ---------------------------------------
    // On init, get details or reset state

    useEffect(() => {
        if (userId) {
            detailsAction({
                id: userId,
            });
        } else {
            resetDetailsAction();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On exit, back to groups list

    const onQuit = useCallback(() => {
        history.replace(getRoute(RoutePathName.clientUser, {userId}));
        resetDetailsAction();
    }, [history, resetDetailsAction, userId]);

    const onPrevious = () => {
        onQuit();
    };

    // ---------------------------------------
    // On submit, create or update

    const onChangeValues = (val: ProgramClosure) => {
        updateAction({
            closure: val,
            id: userId,
        });
    };

    useEffect(() => {
        if (
            previous?.updateActionState.loading && !updateActionState.loading
        ) {
            if (updateActionState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onQuit();
            }
        }
    }, [
        previous, formatMessage, onQuit,
        updateActionState.loading, updateActionState.error, updateActionState.data,
    ]);

    // ---------------------------------------
    // Render

    return (
        <section id="create-action">
            <Seo title={formatMessage(ProgramClosureMessages.title)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                onPrevious={onPrevious}
                title={<FormattedMessage {...ProgramClosureMessages.title} />}
                subtitle={<FormattedMessage {...ProgramClosureMessages.subtitle} />}
                icon={<IconProgram />}
                isFirstStep={true}
            />
            <FullscreenLayout.Content>
                <Spin spinning={updateActionState.loading}>
                    <ProgramClosureForm
                        onSubmit={onChangeValues}
                        organizationId={user?.organization?.id}
                        initialValues={detailsActionState.data}
                    />
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    updateActionState: state.users.update,
    detailsActionState: state.users.details,
});

export default connect(
    mapStateToProps,
    {
        updateAction: usersActions.update.trigger,
        detailsAction: usersActions.details.trigger,
        resetDetailsAction: usersActions.details.reset,
    },
)(ProgramClosurePage);
