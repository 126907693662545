import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M11.486 1.407H8.57V.78c0-.441-.411-.78-.925-.78H4.354C3.84 0 3.43.339 3.43.78v.627H.514A.502.502 0 000 1.915c0 .288.223.509.514.509h.635l.634 7.898A1.706 1.706 0 003.497 12h4.989c.943 0 1.697-.746 1.714-1.678l.651-7.881h.635c.291 0 .514-.22.514-.509a.517.517 0 00-.514-.525zm-7.029-.39h3.086v.39H4.457v-.39zm.206 8.254h-.017a.521.521 0 01-.515-.491l-.188-4.136a.492.492 0 01.497-.525h.017c.274 0 .497.22.514.491l.189 4.136a.524.524 0 01-.497.525zm3.206-.491a.521.521 0 01-.515.491h-.017a.524.524 0 01-.497-.525l.189-4.136a.521.521 0 01.514-.491h.017a.524.524 0 01.497.525L7.87 8.78z" />
    </svg>
);

const IconTrash: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-trash${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconTrash;
