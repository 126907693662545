import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M2.803.18a1.079 1.079 0 011.551.404l1.67 3.251c.253.455.118 1.028-.286 1.348L4.22 6.362C4.827 7.491 5.417 8.4 6.513 9.495c1.03 1.028 1.923 1.634 3.087 2.257l1.198-1.533c.32-.404.893-.522 1.349-.286l3.272 1.685c.557.286.759.993.405 1.533l-1.35 2.105c-.32.489-.877.775-1.467.741-.607-.05-1.35-.118-1.872-.252-2.328-.607-4.74-2.055-6.78-4.11C2.313 9.595.862 7.186.254 4.862.122 4.34.038 3.599.004 3.01A1.639 1.639 0 01.71 1.56zm6.089 2.847c1.096 0 2.125.42 2.9 1.196A4.06 4.06 0 0112.99 7.12a.755.755 0 01-.759.758.755.755 0 01-.759-.758c0-.69-.27-1.33-.759-1.82a2.553 2.553 0 00-1.821-.757.755.755 0 01-.76-.758c0-.421.338-.758.76-.758zm0-2.915c1.872 0 3.626.742 4.958 2.022a6.952 6.952 0 012.041 4.952.755.755 0 01-.759.758l-.103-.007a.755.755 0 01-.656-.75 5.47 5.47 0 00-1.602-3.858 5.493 5.493 0 00-3.88-1.6.755.755 0 01-.758-.758c0-.422.337-.759.759-.759z"/>
    </svg>
);

const IconPhone: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-phone${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconPhone;
