import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'client.users.title',
        defaultMessage: 'Utilisateurs',
        description: 'Client users page title',
    },
    addUser: {
        id: 'client.users.add_user',
        defaultMessage: 'Ajouter un utilisateur',
        description: 'Add user CTA',
    },
    viewOrEdit: {
        id: 'client.users.viewOrEdit',
        defaultMessage: 'Voir ou modifier',
        description: 'View or edit user',
    },
    sendWelcomeEmail: {
        id: 'client.users.sendWelcomeEmail',
        defaultMessage: 'Envoyer l\'email de bienvenue',
        description: 'Send welcome email',
    },
    closeProgram: {
        id: 'client.users.closeProgram',
        defaultMessage: 'Clôturer',
        description: 'Close program',
    },
    extendProgram: {
        id: 'client.users.extendProgram',
        defaultMessage: 'Prolonger',
        description: 'Extend program',
    },
    edit: {
        id: 'client.users.edit',
        defaultMessage: 'Modifier',
        description: 'Edit',
    },
    importUsers: {
        id: 'client.users.importUsers',
        defaultMessage: 'Importer',
        description: 'Import users',
    },
    exportUsers: {
        id: 'client.users.exportUsers',
        defaultMessage: 'Exporter',
        description: 'Export users',
    },
    confirmSendWelcomeEmail: {
        id: 'client.users.confirmSendWelcomeEmail',
        defaultMessage: 'Confirmez-vous l’envoi de l’email de bienvenue à {users}',
        description: 'Confirm send welcome email to users',
    },
    confirmSendEmail: {
        id: 'client.users.confirmSendEmail',
        defaultMessage: 'Confirmer l\'envoi',
        description: 'Confirm send welcome email',
    },
    userCount: {
        id: 'user_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun utilisateur}
                one {cet utilisateur}
                other {ces {count, number} utilisateurs}
            }
        `,
        description: 'User count',
    },
    backToUsers: {
        id: 'client.users.backToUsers',
        defaultMessage: 'Retour aux utilisateurs',
        description: 'Back to users list',
    },
    welcomeEmail: {
        id: 'client.users.welcomeEmail',
        defaultMessage: 'Email de bienvenue',
        description: 'Welcome email',
    },
    welcomeEmailSuccess: {
        id: 'client.users.welcomeEmailSuccess',
        defaultMessage: 'L\'email a bien été envoyé',
        description: 'Welcome email success',
    },
    importSuccess: {
        id: 'client.users.importSuccess',
        defaultMessage: 'L\'import a été effectué avec succès',
        description: 'Import success',
    },
    removeConfirm: {
        id: 'client.users.removeConfirm',
        defaultMessage: 'Confirmez-vous la suppression ?',
        description: 'Confirm remove user',
    },
    editUser: {
        id: 'client.users.editUser',
        defaultMessage: 'Modifier l\'utilisateur',
        description: 'Edit user',
    },
    deleteForever: {
        id: 'client.users.deleteForever',
        defaultMessage: 'Supprimer définitivement',
        description: 'Delete forever',
    },
    updateSuccess: {
        id: 'client.users.updateSuccess',
        defaultMessage: 'Mise a jour de l\'utilisateur',
        description: 'Update success',
    },
    deleteSuccess: {
        id: 'client.users.deleteSuccess',
        defaultMessage: 'L\'utilisateur a été supprimé',
        description: 'Delete success',
    },
    userProgram: {
        id: 'client.users.userProgram',
        defaultMessage: 'Son programme',
        description: 'User program',
    },
    selectProgramNow: {
        id: 'client.users.selectProgramNow',
        defaultMessage: 'Attribuer un programme maintenant',
        description: 'Select a program now',
    },
    selectProgram: {
        id: 'client.users.selectProgram',
        defaultMessage: 'Attribuer un programme',
        description: 'Select a program',
    },
    selectProgramPlaceholder: {
        id: 'client.users.selectProgramPlaceholder',
        defaultMessage: 'Sélectionnez un programme',
        description: 'Select a program placeholder',
    },
    validateProgram: {
        id: 'client.users.validateProgram',
        defaultMessage: 'Valider le programme',
        description: 'Validate program',
    },
    validateProgramLater: {
        id: 'client.users.validateProgramLater',
        defaultMessage: 'Attribuer plus tard',
        description: 'Validate program later',
    },
    changeProgram: {
        id: 'client.users.changeProgram',
        defaultMessage: 'Changer de programme',
        description: 'Change program',
    },
    extendNow: {
        id: 'client.users.extendNow',
        defaultMessage: 'Prolonger maintenant',
        description: 'Extend program now',
    },
    extendAgain: {
        id: 'client.users.extendAgain',
        defaultMessage: 'Prolonger à nouveau',
        description: 'Extend program again',
    },
    changeProgramModalTitle: {
        id: 'client.users.changeProgramModalTitle',
        defaultMessage: 'Changer de programme de rattachement',
        description: 'Change program modal title',
    },
    editProgram: {
        id: 'client.users.editProgram',
        defaultMessage: 'Modifier le programme',
        description: 'Edit program',
    },
    userCreatedSuccess: {
        id: 'client.users.userCreatedSuccess',
        defaultMessage: 'Utilisateur créé avec succès',
        description: 'User created modal title',
    },
    selectProgramBelow: {
        id: 'client.users.selectProgramBelow',
        defaultMessage: 'Sélectionner un programme ci-dessous pour lui attribuer',
        description: 'Select program below subtitle',
    },
    programExtension: {
        id: 'client.users.programExtension',
        defaultMessage: 'Programme de prolongation',
        description: 'Program extension modal title',
    },
    selectProgramExtension: {
        id: 'client.users.selectProgramExtension',
        defaultMessage: 'Veuillez sélectionner un programme pour valider la prolongation',
        description: 'Select a program extension',
    },
    validateProgramExtension: {
        id: 'client.users.validateProgramExtension',
        defaultMessage: 'Valider la prolongation',
        description: 'validate program extension',
    },
    programExtensionSuccess: {
        id: 'client.users.programExtensionSuccess',
        defaultMessage: 'La prolongation a bien été effectuée',
        description: 'program extension success',
    },
    closureInformations: {
        id: 'client.users.closureInformations',
        defaultMessage: 'Informations de fin du programme',
        description: 'Closure informations title',
    },
    files: {
        id: 'client.users.closureInformations',
        defaultMessage: 'Informations de fin du programme',
        description: 'Closure informations title',
    },
    sentFiles: {
        id: 'client.sentFiles',
        defaultMessage: 'Fichiers envoyés',
        description: 'Sent file title',
    },
    file: {
        id: 'client.users.file',
        defaultMessage: 'Fichier',
        description: 'File',
    },
    program: {
        id: 'client.users.program',
        defaultMessage: 'Programme',
        description: 'Program',
    },
    actionName: {
        id: 'client.users.actionName',
        defaultMessage: 'Nom de l\'action',
        description: 'Action name',
    },
    creationDate: {
        id: 'client.users.creationDate',
        defaultMessage: 'Date d\'ajout',
        description: 'Creation date',
    },
    surveys: {
        id: 'client.users.surveys',
        defaultMessage: 'Questionnaires',
        description: 'Surveys',
    },
    answerDate: {
        id: 'client.users.answerDate',
        defaultMessage: 'Date de réponse',
        description: 'Survey answer date',
    },
});
