import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M7.808 7.16c1.348 0 2.55 2.18 3.317 4.478l.158.494.144.493.066.245.122.483c.076.319.142.63.196.926l.074.434c.064.422.103.805.113 1.13L12 16H0c0-.363.042-.814.12-1.32l.077-.444c.042-.229.092-.465.147-.707l.119-.49.134-.5c.71-2.512 1.987-5.203 3.452-5.37l.143-.008h3.616zm4.412-4.384c0-.993 1.438-1.033 1.553-.12l.007.12V4.22h1.444c.955 0 1.029 1.328.22 1.533l-.11.02-.11.007h-1.445v1.444c0 .955-1.327 1.029-1.532.22l-.02-.11-.007-.11V5.779h-1.444c-.955 0-1.029-1.327-.22-1.532l.11-.02.11-.007h1.444V2.776zM6 0c1.476 0 2.676 1.212 2.676 2.7 0 1.49-1.2 2.702-2.676 2.702-1.476 0-2.676-1.212-2.676-2.701C3.324 1.21 4.524 0 6 0z" />
    </svg>
);

const IconAddContact: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-add-contact${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconAddContact;
