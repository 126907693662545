import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'job_offer_create.title',
        defaultMessage: 'Créer une annonce',
        description: 'Create job offer page title',
    },
    titleLabel: {
        id: 'job_offer_create.form.title.label',
        defaultMessage: 'Titre',
        description: 'Create job offer form',
    },
    titlePlaceholder: {
        id: 'job_offer_create.form.title.placeholder',
        defaultMessage: 'Saisir un titre',
        description: 'Create job offer form',
    },
    applyDateLabel: {
        id: 'job_offer_create.form.apply_date.label',
        defaultMessage: 'Date de candidature',
        description: 'Create job offer form',
    },
    applyDatePlaceholder: {
        id: 'job_offer_create.form.apply_date.placeholder',
        defaultMessage: 'Saisir une date',
        description: 'Create job offer form',
    },
    typeLabel: {
        id: 'job_offer_create.form.type.label',
        defaultMessage: 'Type',
        description: 'Create job offer form',
    },
    typePlaceholder: {
        id: 'job_offer_create.form.type.placeholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Create job offer form',
    },
    companyLabel: {
        id: 'job_offer_create.form.company.label',
        defaultMessage: 'Entreprise',
        description: 'Create job offer form',
    },
    companyPlaceholder: {
        id: 'job_offer_create.form.company.placeholder',
        defaultMessage: 'Entreprise',
        description: 'Create job offer form',
    },
    originLabel: {
        id: 'job_offer_create.form.origin.label',
        defaultMessage: 'Origine',
        description: 'Create job offer form',
    },
    originPlaceholder: {
        id: 'job_offer_create.form.origin.placeholder',
        defaultMessage: 'Saisir l\'origine de l\'annonce',
        description: 'Create job offer form',
    },
    referenceLabel: {
        id: 'job_offer_create.form.reference.label',
        defaultMessage: 'Référence',
        description: 'Create job offer form',
    },
    referencePlaceholder: {
        id: 'job_offer_create.form.reference.placeholder',
        defaultMessage: 'Saisir la référence',
        description: 'Create job offer form',
    },
    descriptionLabel: {
        id: 'job_offer_create.form.description.label',
        defaultMessage: 'Descriptif de l\'annonce',
        description: 'Create job offer form',
    },
    descriptionPlaceholder: {
        id: 'job_offer_create.form.description.placeholder',
        defaultMessage: 'Saisir le descriptif de l\'annonce',
        description: 'Create job offer form',
    },
    attachImage: {
        id: 'job_offer_create.form.image.title',
        defaultMessage: 'Attacher une image',
        description: 'Create job offer form',
    },
    imageButton: {
        id: 'job_offer_create.form.image.button',
        defaultMessage: 'Choisir une image',
        description: 'Create job offer form',
    },
    imageHelp: {
        id: 'job_offer_create.form.image.help',
        defaultMessage: 'La taille de fichier maximale autorisée est de 500 ko.',
        description: 'Create job offer form',
    },
    imageDescriptionLabel: {
        id: 'job_offer_create.form.image.description.label',
        defaultMessage: 'Description de l\'image',
        description: 'Create job offer form',
    },
    imageDescriptionPlaceholder: {
        id: 'job_offer_create.form.image.description.placeholder',
        defaultMessage: 'Saisir la description de l\'image',
        description: 'Create job offer form',
    },
    submit: {
        id: 'job_offer_create.form.submit',
        defaultMessage: 'Créer l\'annonce',
        description: 'Create job offer form',
    },
    successModalTitle: {
        id: 'job_offer_create.success_modal.title',
        defaultMessage: 'L\'annonce « {title} » a été créé avec succès',
        description: 'Create job offer success modal title',
    },
    successModalSeeJobOffer: {
        id: 'job_offer_create.success_modal.see_job_offer',
        defaultMessage: 'Voir l\'annonce',
        description: 'Create job offer success modal see job offer link',
    },
});
