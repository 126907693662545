import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, message } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { ModalProps } from 'antd/lib/modal';

import { MainReducerState } from '../store/reducers';
import { AuthState, checkLoginStatus as checkLoginStatusAction, getAuthState } from '../store/actions/auth';
import { updateMy, getMyProgramUpdateState, ProgramsState } from '../store/actions/programs';

import AccountMessages from '../pages/account/AccountMessages';
import Modal from './Modal';
import genericMessages from '../locale/genericMessages';
import { usePrevious } from '../hooks';
import { ui, getUiState } from '../store/actions/ui';

interface SuspensionReactivateModalProps extends ModalProps {
    authState: AuthState;
    myProgramUpdateState: ProgramsState['updateMy'];
    checkLoginStatus: typeof checkLoginStatusAction.trigger;
    updateMyProgram: typeof updateMy.trigger;
    setUi: typeof ui.actions.set;
}

const SuspensionReactivateModal: FC<SuspensionReactivateModalProps> = ({
    authState, updateMyProgram, myProgramUpdateState, checkLoginStatus, visible, setUi,
}) => {
    const { formatMessage } = useIntl();
    const [step, setStep] = useState<'form' | 'success'>('form');
    const previous = usePrevious({ myProgramUpdateState });
    const onSubmit = () => {
        updateMyProgram({ suspensions: 0 });
    };
    const onClose = () => {
        setUi({ isSuspensionReactivateModalVisible: false });
    };

    useEffect(() => {
        if (!visible) {
            setStep('form');
        }
    }, [visible]);

    useEffect(() => {
        if (visible && previous?.myProgramUpdateState.loading && !myProgramUpdateState.loading) {
            if (myProgramUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                setStep('success');
                checkLoginStatus();
            }
        }
    }, [visible, myProgramUpdateState.loading, myProgramUpdateState.error, formatMessage, previous, checkLoginStatus]);

    return (
        <Modal
            footer={null}
            onCancel={onClose}
            title={step === 'form' ?
                <FormattedMessage {...AccountMessages.suspensionReactivateFormModalTitle} /> :
                <FormattedMessage {...AccountMessages.suspensionReactivateFormModalTitleSuccess} />
            }
            visible={visible}
            zIndex={900}
            width={step === 'success' ? 550 : 444}
        >
            <div style={{ textAlign: 'center' }}>
                {step === 'form' && (
                    <>
                        <Typography.Paragraph className="text-light">
                            <FormattedMessage
                                {...AccountMessages.suspensionReactivateFormModalIntro}
                                values={{ date: dayjs().add(1, 'week').startOf('week').toDate() }}
                                tagName="p"
                            />
                        </Typography.Paragraph>
                        <div style={{ marginBottom: '2rem' }}>
                            <Button
                                onClick={onClose}
                            >
                                <FormattedMessage {...genericMessages.cancel} />
                            </Button>
                            <Button
                                type="primary"
                                onClick={onSubmit}
                                style={{ marginLeft: 8 }}
                                loading={myProgramUpdateState.loading}
                            >
                                <FormattedMessage {...AccountMessages.suspensionReactivateFormModalSubmit} />
                            </Button>
                        </div>
                    </>
                )}
                {step === 'success' && (
                    <Typography.Paragraph className="text-light" style={{ marginBottom: '2rem' }}>
                        <FormattedMessage
                            {...AccountMessages.suspensionReactivateFormModalSuccess}
                            values={{ count: authState.user?.selectedProgram?.remainingSuspensions ?? 0 }}
                        />
                    </Typography.Paragraph>
                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
    myProgramUpdateState: getMyProgramUpdateState(state),
    visible: getUiState(state).isSuspensionReactivateModalVisible,
});

export default connect(
    mapStateToProps,
    {
        updateMyProgram: updateMy.trigger,
        checkLoginStatus: checkLoginStatusAction.trigger,
        setUi: ui.actions.set,
    },
)(SuspensionReactivateModal);
