import React, { FC, useContext } from 'react';

import '../assets/styles/MainMenuButton.less';

import { LayoutContext } from '../context/LayoutContext';

const MainMenuButton: FC = () => {
    const { setIsMenuOpen, isMenuOpen } = useContext(LayoutContext);
    const onClick = () => {
        setIsMenuOpen((value) => !value);
    };

    return (
        <button
            type="button"
            id="main-menu-button"
            className={isMenuOpen ? 'is-open' : undefined}
            onClick={onClick}
        >
            <div />
        </button>
    );
};

export default MainMenuButton;
