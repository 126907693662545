import React, { FC, useMemo, useEffect } from 'react';
import { Spin, Result } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';
import { metrics } from '../../store/actions/reporting';

import { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';
import useQuery from '../../hooks/useQuery';
import { Panel, QueryResponseData, RangeVectors, InstantVector } from '../../types';
import { useQueryParams } from '../../hooks';
import EnumMessage from '../EnumMessage';
import { localizedDurationHumanizer } from '../../locale/i18n/humanizeDuration';
import { getParsedDescription, getMetricName, getQueryType } from '../../helpers/reporting';
import genericMessages from '../../locale/genericMessages';

type Data<T = any> = Array<QueryResponseData<T> | undefined> | undefined;

const formatData = (data: Data, panel: Panel, isLoading: boolean, error: boolean) => {
    if (data?.[0]?.resultType === 'vector') {
        return (data as Data<InstantVector[]>)?.[0]?.result[0]?.value[1];
    } else {
        if (!isLoading && !error && panel.transformations) {
            return transformData(data, panel.transformations);
        }

        return data;
    }
};

const transformData = (data: Data<RangeVectors[]>, transformations: Panel['transformations']) => {
    let transformedData: Array<[number, string]> | undefined = data?.[0]?.result[0]?.values;

    transformations?.forEach((transformation) => {
        if (transformation.id === 'reduce') {
            transformation.options.reducers.forEach((reducerMethod) => {

                if (reducerMethod === 'diff' && transformedData?.length) {
                    // @ts-ignore
                    transformedData = Math.abs(parseInt(transformedData[0][1], 10) - parseInt(transformedData[transformedData?.length - 1][1], 10));
                }

                if (reducerMethod === 'max' && transformedData?.length) {
                    // @ts-ignore
                    transformedData = Math.max(...transformedData.map((value) => parseInt(value[1], 10)));
                }

                if (reducerMethod === 'last' && transformedData?.length) {
                    // @ts-ignore
                    transformedData = parseInt(transformedData[transformedData?.length - 1][1], 10);
                }

                if (reducerMethod === 'lastNotNull' && transformedData?.length) {
                    const source = transformedData.reverse();
                    let match = null;
                    let findIndex = 0;
                    while (match === null && findIndex < source.length) {
                        if (source[findIndex][1] !== null) {
                            match = parseInt(source[findIndex][1], 10);
                        } else {
                            findIndex++;
                        }
                    }
                    // @ts-ignore
                    transformedData = match;
                }

                if (reducerMethod === 'mean' && transformedData?.length) {
                    let count = 0;
                    const sum = transformedData.reduce((acc, value) => {
                        const currentValue = parseInt(value[1], 10);

                        if (!isNaN(currentValue)) {
                            count++;
                        }

                        return acc += (currentValue || 0);
                    }, 0);

                    // @ts-ignore
                    transformedData = sum / count;
                }
            });
        }
    });

    return transformedData;
};

interface StatProps {
    panel: Panel;
    user?: User;
    params?: {
        [key: string]: string;
    };
    storeForExport: typeof metrics.actions.set;
    removeForExport: typeof metrics.actions.remove;
}

const Stat: FC<StatProps> = ({ panel, params, user, storeForExport, removeForExport }) => {
    const { formatMessage } = useIntl();
    const urlParams = useQueryParams();
    const dateParam = urlParams.get('dateRange');
    const userParam = urlParams.get('user');
    const groupParam = urlParams.get('group');
    const distribParam = urlParams.get('distrib');
    const parsedDescription = getParsedDescription(panel.description);
    const dateRange = useMemo(() =>
        dateParam ?
            [parseInt(dateParam.split('-')[0], 10), parseInt(dateParam.split('-')[1], 10)] :
            undefined
    , [dateParam]);
    const { data, isLoading, error } = useQuery(panel, useMemo(() => ({
        queryType: getQueryType(panel),
        params: {
            ...(dateRange ? { start: `${Math.floor(dateRange[0] / 1000)}` } : {}),
            ...(dateRange ? { end: `${Math.floor(dateRange[1] / 1000)}` } : {}),
            ...(userParam ? { $user: userParam } : {}),
            ...(groupParam ? { $group: groupParam } : {}),
            ...params,
        },
    }), [dateRange, userParam, groupParam, params, panel]), user);

    let finalData: any = formatData(data, panel, isLoading, error);

    if (!isLoading) {
        storeForExport({
            type: 'stat',
            translatedName: `${formatMessage(getMetricName(parsedDescription))}${
                panel.fieldConfig?.defaults.unit === 'ms' ? ' (ms)' : ''
                }${
                panel.fieldConfig?.defaults.unit === 's' ? ' (s)' : ''
                }`,
            name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription,
            value: finalData || 0,
            ...(userParam ? { user: urlParams.get('userName') } : {}),
            ...(groupParam ? { group: urlParams.get('groupName') } : {}),
            ...(distribParam ? { distrib: distribParam } : {}),
        });

        if (!error) {
            if (panel.fieldConfig?.defaults.unit === 'ms') {
                finalData = localizedDurationHumanizer(finalData, {
                    round: true,
                    units: ['d', 'h', 'm'],
                });
            } else if (panel.fieldConfig?.defaults.unit === 's') {
                finalData = localizedDurationHumanizer(finalData * 1000, {
                    round: true,
                    units: ['d', 'h', 'm'],
                });
            }
        }
    }

    useEffect(() => () => {
        removeForExport({ name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <EnumMessage
                map={metricsMessages}
                value={typeof parsedDescription === 'object' ? parsedDescription.name || '' : parsedDescription}
            />
            {isLoading && <Spin />}
            {!isLoading && !error && (
                <div className="dashboard-stat-wrapper">
                    {finalData || 0}
                </div>
            )}
            {!isLoading && error && (
                <Result
                    status="error"
                    subTitle={<FormattedMessage {...genericMessages.defaultError} />}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        storeForExport: metrics.actions.set,
        removeForExport: metrics.actions.remove,
    },
)(Stat);
