import React, { FC, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';

import '../assets/styles/MainMenu.less';

import { ProgramsState, getMyProgramState } from '../store/actions/programs';
import { MainReducerState } from '../store/reducers';

import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';
import genericMessages from '../locale/genericMessages';
import { IconArrowLeft, IconContacts, IconDocuments, IconHome, IconNetworkingActions, IconOffers, IconProgram, IconReporting } from './icons';
import ButtonLink from './ButtonLink';

const messages = defineMessages({
    home: {
        id: 'menu.home',
        defaultMessage: 'Accueil',
        description: 'Menu item',
    },
    program: {
        id: 'menu.program',
        defaultMessage: 'Votre programme',
        description: 'Menu item',
    },
    networkingActions: {
        id: 'menu.networking_actions',
        defaultMessage: 'Actions de Networking',
        description: 'Menu item',
    },
    calendar: {
        id: 'menu.calendar',
        defaultMessage: 'Calendrier des RDV',
        description: 'Menu item',
    },
    library: {
        id: 'menu.library',
        defaultMessage: 'Bibliothèque',
        description: 'Menu item',
    },
    contacts: {
        id: 'menu.contacts',
        defaultMessage: 'Contacts',
        description: 'Menu item',
    },
    jobOffers: {
        id: 'menu.jobOffers',
        defaultMessage: 'Annonces',
        description: 'Menu item',
    },
    dashboard: {
        id: 'menu.dashboard',
        defaultMessage: 'Tableau de bord',
        description: 'Menu item',
    },
});

interface SidebarProps {
    programState: ProgramsState['my'];
}

const Sidebar: FC<SidebarProps> = ({ programState }) => {
    const { isMenuOpen, setIsMenuOpen } = useContext(LayoutContext);
    const isJobOfferMenuVisible = Boolean(programState.data?.program?.shouldDisplayJobOffers);
    const location = useLocation();
    const isInnerPage = [
        getRoute(RoutePathName.account),
        getRoute(RoutePathName.faq),
        getRoute(RoutePathName.privacyPolicy),
        getRoute(RoutePathName.terms),
    ].includes(location.pathname);

    const menu = (
        <Menu
            defaultSelectedKeys={[location.pathname]}
            id="main-menu"
        >
            <Menu.Item key={getRoute(RoutePathName.home)}>
                <Link to={getRoute(RoutePathName.home)}>
                    <IconHome />
                    <FormattedMessage {...messages.home} tagName="span" />
                </Link>
            </Menu.Item>
            <Menu.Item key={getRoute(RoutePathName.program)}>
                <Link to={getRoute(RoutePathName.program)}>
                    <IconProgram />
                    <FormattedMessage {...messages.program} tagName="span" />
                </Link>
            </Menu.Item>
            <Menu.Item key={getRoute(RoutePathName.networkingActions)}>
                <Link to={getRoute(RoutePathName.networkingActions)}>
                    <IconNetworkingActions />
                    <FormattedMessage {...messages.networkingActions} tagName="span" />
                </Link>
            </Menu.Item>
            {/* <Menu.Item key={getRoute(RoutePathName.calendar)}>
                <Link to={getRoute(RoutePathName.calendar)}>
                    <IconEvent />
                    <FormattedMessage {...messages.calendar} tagName="span" />
                </Link>
            </Menu.Item> */}
            <Menu.Item key={getRoute(RoutePathName.library)}>
                <Link to={getRoute(RoutePathName.library)}>
                    <IconDocuments />
                    <FormattedMessage {...messages.library} tagName="span" />
                </Link>
            </Menu.Item>
            <Menu.Item key={getRoute(RoutePathName.contacts)}>
                <Link to={getRoute(RoutePathName.contacts)}>
                    <IconContacts />
                    <FormattedMessage {...messages.contacts} tagName="span" />
                </Link>
            </Menu.Item>
            {isJobOfferMenuVisible && (
                <Menu.Item key={getRoute(RoutePathName.jobOffers)}>
                    <Link to={getRoute(RoutePathName.jobOffers)}>
                        <IconOffers />
                        <FormattedMessage {...messages.jobOffers} tagName="span" />
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item key={getRoute(RoutePathName.dashboard)}>
                <Link to={getRoute(RoutePathName.dashboard)}>
                    <IconReporting />
                    <FormattedMessage {...messages.dashboard} tagName="span" />
                </Link>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname, setIsMenuOpen]);

    return (
        <>
            <Breakpoint lg down>
                <div id="sidebar-wrapper" className={isMenuOpen ? 'is-open' : undefined}>
                    {menu}
                </div>
            </Breakpoint>
            <Breakpoint xl up className={isInnerPage ? 'inner-page-menu' : ''}>
                { isInnerPage ? (
                    <ButtonLink
                        to={getRoute(RoutePathName.home)}
                        ghost
                    >
                        <IconArrowLeft />
                        <FormattedMessage {...genericMessages.backHomeButton} />
                    </ButtonLink>
                ) : (
                    <Layout.Sider
                        trigger={null}
                        width={203}
                    >
                        {menu}
                    </Layout.Sider>
                ) }
            </Breakpoint>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
)(Sidebar);
