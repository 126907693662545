import React, { FC, useState, useContext } from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';

import '../assets/styles/CheckboxButton.less';

import { classNames } from '../helpers';

const CheckboxButton: FC<CheckboxProps> = ({ checked, onChange, ...props }) => {
    const checkboxGroupContext = useContext(Checkbox.contextType);
    const [isChecked, setIsChecked] = useState(checked ?? checkboxGroupContext?.value?.includes(props.value) ?? false);
    const onChangePrivate: CheckboxProps['onChange'] = (e) => {
        setIsChecked(e.target.checked);
        onChange?.(e);
    };

    return (
        <div className={classNames('checkbox-button', isChecked && 'checkbox-button-checked')}>
            <Checkbox
                {...props}
                checked={isChecked}
                onChange={onChangePrivate}
            />
        </div>
    );
};

export default CheckboxButton;
