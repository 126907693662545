
import { EzeeAction } from '../helpers/EzeeAction';
import { MainReducerState } from '../reducers';

// State

export interface UiState {
    hasShownProfileModal: boolean;
    hasShownSuspensionModal: boolean;
    shouldShowTutorialModal: boolean;
    isSuspensionReactivateModalVisible: boolean;
}

const initialState: UiState = {
    hasShownProfileModal: false,
    hasShownSuspensionModal: false,
    shouldShowTutorialModal: false,
    isSuspensionReactivateModalVisible: false,
};

// Actions/Reducers

export const ui = new EzeeAction<UiState>('ui', initialState, {
    set: (state, payload) => ({
        ...state,
        ...payload,
    }),
});

// Reducer

export const uiReducer = ui.reducer;

// Store helpers

export const getUiState = (state: MainReducerState) => state.ui;
