import React, { FC, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { getStaticPageStateBySlug, faq, StaticPageState } from '../../store/actions/staticPages';
import { StaticPageSlug } from '../../store/api/apiTypes';
import { Typography, Card, Skeleton, Collapse, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import genericMessages from '../../locale/genericMessages';
import Seo from '../../components/Seo';

import '../../assets/styles/Faq.less';

const { Panel } = Collapse;

interface FaqProps extends RouteComponentProps {
    pageState: StaticPageState;
    fetchFaq: typeof faq.trigger;
}

const Faq: FC<FaqProps> = ({ pageState, fetchFaq }) => {
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!pageState.faq || pageState.faq.length === 0) {
            fetchFaq();
        }
    }, [pathname, fetchFaq, pageState.faq]);

    const sections = pageState.faq?.filter((section) => section.questions.length > 0);

    return (
        <>
            <Seo title={formatMessage(genericMessages.faqTitle)} />
            <Typography.Title level={1}>
                <FormattedMessage {...genericMessages.faqTitle} />
            </Typography.Title>
            <Card id="static-page">
                {pageState.loading ? (
                    <>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </>
                ) : (
                    <div id="faq-page">
                        {sections?.map((section, index) => (
                            <React.Fragment key={section.id}>
                                <Typography.Title level={2}>
                                    {section.title}
                                </Typography.Title>
                                <Collapse bordered={false}>
                                    {section.questions?.map((question) => (
                                        <Panel header={question.title} key={question.id}>
                                            {question.answer}
                                        </Panel>
                                    ))}
                                </Collapse>

                                {pageState.faq && (index !== sections.length - 1) && (<Divider />)}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </Card>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { location: { pathname } }: RouteComponentProps) => ({
    pageState: getStaticPageStateBySlug(state, pathname.substr(1) as StaticPageSlug),
});

export default connect(
    mapStateToProps,
    {
        fetchFaq: faq.trigger,
    },
)(Faq);
