import React, { FC, useRef, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationProps } from 'antd/lib/pagination';

import { MainReducerState } from '../../store/reducers';
import {
    getAppointmentsListState, list, AppointmentsState, listCountNetworkingActions,
    getNetworkingActionsListCountState, getAppointmentsDeleteState, getAppointmentsUpdateState,
} from '../../store/actions/appointments';

import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import Pagination from '../../components/Pagination';
import NetworkingActionCard from '../../components/NetworkingActionCard';
import ListTitle from '../../components/ListTitle';
import Filters from '../../components/Filters';
import { useQueryParams, usePrevious } from '../../hooks';
import { scrollToElement } from '../../helpers';
import constants from '../../config/constants';
import ListHeader from '../../components/ListHeader';
import genericMessages from '../../locale/genericMessages';
import { IconPlus } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import NetworkingActionsMessages from './NetworkingActionsMessages';
import { AppointmentStatus } from '../../store/api/apiTypes';
import { Empty, message } from 'antd';
import CardSkeleton from '../../components/CardSkeleton';
import NetworkingActionDetailsMessages from '../networkingActionDetails/NetworkingActionDetailsMessages';

interface NetworkingActionsProps {
    appointmentsListState: AppointmentsState['list'];
    appointmentsListCountState: AppointmentsState['listCountNetworkingActions'];
    appointmentDelState: AppointmentsState['del'];
    appointmentUpdateState: AppointmentsState['update'];
    listAppointments: typeof list.trigger;
    listAppointmentsCount: typeof listCountNetworkingActions.trigger;
}

const NetworkingActions: FC<NetworkingActionsProps> = ({
    appointmentsListState, appointmentsListCountState, appointmentDelState, appointmentUpdateState,
    listAppointments, listAppointmentsCount,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { formatMessage, formatNumber } = useIntl();
    const previous = usePrevious({ appointmentDelState, appointmentUpdateState });
    const listRef = useRef(null);
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const filterParam = urlParams.get('filter');
    const searchParam = urlParams.get('search');
    const sortParam = urlParams.get('sort');
    const currentPage = pageParam !== null ?
        (parseInt(pageParam, 10) || 1) :
        1;
    const currentFilter = filterParam || AppointmentStatus.pending;
    const currentSort = sortParam || 'createdAt';
    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('page', `${page || 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeFilter = (filter: string) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('filter', `${filter}`);
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSearch = (search?: string) => {
        scrollToElement(listRef.current, 100);
        if (search) {
            urlParams.set('search', search);
        } else {
            urlParams.delete('search');
        }
        urlParams.delete('page');
        urlParams.delete('filter');
        urlParams.delete('sort');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSort = (sort?: string) => {
        scrollToElement(listRef.current, 100);
        if (sort) {
            urlParams.set('sort', sort);
        } else {
            urlParams.delete('sort');
        }
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.networkingActions),
            },
        });
    };
    const filterOptions = [
        {
            value: AppointmentStatus.pending,
            message: NetworkingActionsMessages.filtersActive,
            messageValues: { count: formatNumber(appointmentsListCountState.data?.pending || 0) },
        },
        {
            value: AppointmentStatus.validated,
            message: NetworkingActionsMessages.filtersComplete,
            messageValues: { count: formatNumber(appointmentsListCountState.data?.validated || 0) },
        },
        {
            value: AppointmentStatus.cancelled,
            message: NetworkingActionsMessages.filtersCanceled,
            messageValues: { count: formatNumber(appointmentsListCountState.data?.cancelled || 0) },
        },
    ];
    const sortOptions = [
        {
            value: 'createdAt',
            message: genericMessages.sortByDate,
        },
        {
            value: 'title',
            message: genericMessages.sortAlphabetically,
        },
    ];
    const fetchAppointments = useCallback(() => {
        listAppointments({
            page: currentPage - 1,
            pageSize: constants.PAGE_SIZE,
            ...(searchParam !== null ?
                { search: searchParam } :
                {
                    ...(currentFilter !== undefined ? { status: currentFilter } : {}),
                    ...(currentSort !== undefined ? { sort: currentSort } : {}),
                }
            ),
        });
    }, [listAppointments, currentPage, currentFilter, currentSort, searchParam]);

    useEffect(() => {
        fetchAppointments();
    }, [fetchAppointments]);

    useEffect(() => {
        if (previous?.appointmentDelState.loading && !appointmentDelState.loading) {
            if (appointmentDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(NetworkingActionDetailsMessages.deleteSuccess));
                listAppointmentsCount();
                fetchAppointments();
            }
        }
    }, [
        previous, appointmentDelState.loading, appointmentDelState.error, formatMessage, history,
        fetchAppointments, listAppointmentsCount,
    ]);

    useEffect(() => {
        if (previous?.appointmentUpdateState.loading && !appointmentUpdateState.loading) {
            if (appointmentUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(NetworkingActionDetailsMessages.updateSuccess));
                listAppointmentsCount();
                fetchAppointments();
            }
        }
    }, [
        previous, appointmentUpdateState.loading, appointmentUpdateState.error, formatMessage, history,
        fetchAppointments, listAppointmentsCount,
    ]);

    useEffect(() => {
        listAppointmentsCount();
    }, [listAppointmentsCount]);

    return (
        <section id="networking-actions">
            <Seo title={formatMessage(NetworkingActionsMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <ListHeader>
                <ListTitle
                    count={
                        (appointmentsListCountState.data?.pending || 0) +
                        (appointmentsListCountState.data?.cancelled || 0) +
                        (appointmentsListCountState.data?.validated || 0)
                    }
                >
                    <FormattedMessage {...NetworkingActionsMessages.title} />
                </ListTitle>
                <Filters
                    filterOptions={filterOptions}
                    sortOptions={sortOptions}
                    onChangeFilter={onChangeFilter}
                    onChangeSearch={onChangeSearch}
                    onChangeSort={onChangeSort}
                    filterValue={currentFilter}
                    searchValue={searchParam || undefined}
                    sortValue={currentSort}
                />
            </ListHeader>
            {!appointmentsListState.loading && !!appointmentsListState.data?.items.length && (
                <>
                    <BasicList ref={listRef} grid>
                        {appointmentsListState.data?.items.map((appointment) => (
                            <li key={appointment.id}>
                                <NetworkingActionCard appointment={appointment} />
                            </li>
                        ))}
                    </BasicList>
                    {appointmentsListState.data?.pageCount > 1 && (
                        <Pagination
                            current={currentPage}
                            onChange={onPageSelect}
                            pageSize={constants.PAGE_SIZE}
                            showSizeChanger={false}
                            total={appointmentsListState.data?.totalCount}
                        />
                    )}
                </>
            )}
            {!appointmentsListState.loading && !appointmentsListState.data?.items.length && (
                <Empty />
            )}
            {appointmentsListState.loading && (
                <BasicList ref={listRef} grid>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                </BasicList>
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentsListState: getAppointmentsListState(state),
    appointmentsListCountState: getNetworkingActionsListCountState(state),
    appointmentDelState: getAppointmentsDeleteState(state),
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listAppointments: list.trigger,
        listAppointmentsCount: listCountNetworkingActions.trigger,
    },
)(NetworkingActions);
