import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'create_user.title',
        defaultMessage: 'Ajouter un utilisateur',
        description: 'Create user page title',
    },
    identity: {
        id: 'create_user.identity',
        defaultMessage: 'Identité',
        description: 'Identity',
    },
    actualJob: {
        id: 'create_user.actualJob',
        defaultMessage: 'Poste actuel',
        description: 'Actual job',
    },
    lastNameLabel: {
        id: 'create_user.lastNameLabel',
        defaultMessage: 'Nom',
        description: 'lastname form label',
    },
    lastNamePlaceholder: {
        id: 'create_user.lastNamePlaceholder',
        defaultMessage: 'Nom de l\'utilisateur',
        description: 'lastname form placeholder',
    },
    firstNameLabel: {
        id: 'create_user.firstNameLabel',
        defaultMessage: 'Prénom',
        description: 'firstname form label',
    },
    firstNamePlaceholder: {
        id: 'create_user.firstNamePlaceholder',
        defaultMessage: 'Prénom de l\'utilisateur',
        description: 'firstname form placeholder',
    },
    genderLabel: {
        id: 'create_user.genderLabel',
        defaultMessage: 'Genre',
        description: 'Gender form label',
    },
    genderPlaceholder: {
        id: 'create_user.genderPlaceholder',
        defaultMessage: 'Sélectionner le genre',
        description: 'Gender form placeholder',
    },
    civilityLabel: {
        id: 'create_user.civilityLabel',
        defaultMessage: 'Civilité',
        description: 'Civility form label',
    },
    civilityPlaceholder: {
        id: 'create_user.civilityPlaceholder',
        defaultMessage: 'Sélectionner la civilité',
        description: 'Civility form placeholder',
    },
    birthdateLabel: {
        id: 'create_user.birthdateLabel',
        defaultMessage: 'Date de naissance',
        description: 'birthdate form label',
    },
    birthdatePlaceholder: {
        id: 'create_user.birthdatePlaceholder',
        defaultMessage: 'Sélectionner une date',
        description: 'birthdate form placeholder',
    },
    emailLabel: {
        id: 'create_user.emailLabel',
        defaultMessage: 'Adresse e-mail',
        description: 'email form label',
    },
    emailPlaceholder: {
        id: 'create_user.emailPlaceholder',
        defaultMessage: 'Adresse e-mail de l\'utilisateur',
        description: 'email form placeholder',
    },
    profilLabel: {
        id: 'create_user.profilLabel',
        defaultMessage: 'Profil',
        description: 'profil form label',
    },
    profilPlaceholder: {
        id: 'create_user.profilPlaceholder',
        defaultMessage: 'Sélectionner un profil',
        description: 'profil form placeholder',
    },
    groupsLabel: {
        id: 'create_user.groupLabel',
        defaultMessage: 'Groupe(s)',
        description: 'group form label',
    },
    groupsPlaceholder: {
        id: 'create_user.groupPlaceholder',
        defaultMessage: 'Sélectionner un/des groupe(s)',
        description: 'group form placeholder',
    },
    scopeGroupsLabel: {
        id: 'create_user.scopeGoupLabel',
        defaultMessage: 'Groupe(s) géré(s)',
        description: 'scope group form label',
    },
    scopeGroupsPlaceholder: {
        id: 'create_user.scopeGroupPlaceholder',
        defaultMessage: 'Sélectionner un/des groupe(s) géré(s)',
        description: 'scope group form placeholder',
    },
    departmentLabel: {
        id: 'create_user.departmentLabel',
        defaultMessage: 'Département',
        description: 'department form label',
    },
    departmentPlaceholder: {
        id: 'create_user.departmentPlaceholder',
        defaultMessage: 'Saisir un département',
        description: 'department form placeholder',
    },
    statusLabel: {
        id: 'create_user.statusLabel',
        defaultMessage: 'Statut',
        description: 'status form label',
    },
    statusPlaceholder: {
        id: 'create_user.statusPlaceholder',
        defaultMessage: 'Sélectionner un statut',
        description: 'status form placeholder',
    },
    jobLabel: {
        id: 'create_user.jobLabel',
        defaultMessage: 'Emploi',
        description: 'job form label',
    },
    jobPlaceholder: {
        id: 'create_user.jobPlaceholder',
        defaultMessage: 'Saisir un emploi',
        description: 'job form placeholder',
    },
    jobPositionLabel: {
        id: 'create_user.jobPositionLabel',
        defaultMessage: 'Poste actuel / Dernier poste',
        description: 'jobPositionLabel form label',
    },
    jobPositionPlaceholder: {
        id: 'create_user.jobPositionPlaceholder',
        defaultMessage: 'Saisir un poste',
        description: 'jobPosition form placeholder',
    },
    annualSalaryLabel: {
        id: 'create_user.annualSalaryLabel',
        defaultMessage: 'Salaire souhaité',
        description: 'annual salary form label',
    },
    annualSalaryPlaceholder: {
        id: 'create_user.annualSalaryPlaceholder',
        defaultMessage: 'Saisir en €',
        description: 'annual salary form placeholder',
    },
    entryOnDutyDateLabel: {
        id: 'create_user.entryOnDutyDateLabel',
        defaultMessage: 'Date de prise du poste',
        description: 'entry on duty date form label',
    },
    entryOnDutyDatePlaceholder: {
        id: 'create_user.entryOnDutyDatePlaceholder',
        defaultMessage: 'Saisir une date',
        description: 'entry on duty date form placeholder',
    },
    managerLabel: {
        id: 'create_user.managerLabel',
        defaultMessage: 'Manager',
        description: 'manager form label',
    },
    managerPlaceholder: {
        id: 'create_user.managerPlaceholder',
        defaultMessage: 'Indiquez le manager',
        description: 'manager form placeholder',
    },
    headHRLabel: {
        id: 'create_user.headHRLabel',
        defaultMessage: 'RRH',
        description: 'head rh form label',
    },
    headHRPlaceholder: {
        id: 'create_user.headHRPlaceholder',
        defaultMessage: 'Indiquez le RRH',
        description: 'head rh form placeholder',
    },
});
