import { Layout } from 'antd';
import React, { FC } from 'react';

import { LayoutContextProvider } from '../context/LayoutContext';
import Header from './Header';
import Footer from './Footer';
import MainMenu from './MainMenu';
// import TutorialModal from './TutorialModal';
import SuspensionModal from './SuspensionModal';
import SuspensionReactivateModal from './SuspensionReactivateModal';

const MainLayout: FC = ({ children }) => (
    <LayoutContextProvider>
        <Layout id="main-layout">
            <Header />
            {/* <TutorialModal /> disabled until new tutorial content is provided */}
            <SuspensionModal />
            <SuspensionReactivateModal />
            <div className="container" id="main-content-wrapper">
                <Layout>
                    <MainMenu />
                    <Layout.Content id="main-content">
                        {children}
                    </Layout.Content>
                </Layout>
            </div>
            <Footer />
        </Layout>
    </LayoutContextProvider>
);

export default MainLayout;
