import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface PackDetailsPayload {
    id: string;
}

export type PackListPayload = SearchPaginationQuery;

export const details = (payload: PackDetailsPayload) => api.get(`/licensePacks/${payload.id}`);

export const list = (payload: PackListPayload = {}) => api.get(`/licensePacks`, {params: cleanPayload(payload)});

export const stats = () => api.get(`/licensePacks/stats`);
