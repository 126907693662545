import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="nonzero" d="M7.385 3c-.392.98-.158 2.198.664 2.889H2.862c-.197 0-.36.14-.398.324l-.008.082v1.499c0 .196.139.36.324.398l.082.008h7.204c.167 0 .31-.1.372-.244.424 1.133 1.526 1.63 2.563 1.532L13 14.05c0 .965-.79 1.864-1.804 1.944L11.05 16h-9.1c-.965 0-1.864-.79-1.944-1.804L0 14.05v-9.1c0-.965.855-1.864 1.813-1.944L1.95 3h5.435zm2.51 9.028H3.032a.578.578 0 00-.093 1.148l.093.007h6.861a.578.578 0 100-1.155zm0-2.24H3.032a.578.578 0 00-.093 1.149l.093.007h6.861a.578.578 0 100-1.155zM13.772.658l.007.12V2.22h1.444c.955 0 1.029 1.328.22 1.533l-.11.02-.11.007h-1.445v1.444c0 .955-1.327 1.029-1.532.22l-.02-.11-.007-.11V3.779h-1.444c-.955 0-1.029-1.327-.22-1.532l.11-.02.11-.007h1.444V.776c0-.993 1.438-1.033 1.553-.12z" />
    </svg>
);

const IconAddJobOffer: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-add-job-offer${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconAddJobOffer;
