import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'contacts.title',
        defaultMessage: 'Contacts',
        description: 'Contacts page title',
    },
    filtersAll: {
        id: 'contacts.filters.all',
        defaultMessage: 'Tous ({count})',
        description: 'Contacts page filter',
    },
    filtersReferrer: {
        id: 'contacts.filters.referrer',
        defaultMessage: 'Prescripteurs ({count})',
        description: 'Contacts page filter',
    },
    filtersReferred: {
        id: 'contacts.filters.referred',
        defaultMessage: 'Prescrits ({count})',
        description: 'Contacts page filter',
    },
    addContactCTA: {
        id: 'contacts.add_contact_cta',
        defaultMessage: 'Ajouter un contact',
        description: 'Contacts page header add contact button',
    },
    cardJobTitle: {
        id: 'contacts.card.job_title',
        defaultMessage: '{jobTitle} chez {company}',
        description: 'Contacts page card job title',
    },
    cardSeeOrEdit: {
        id: 'contacts.card.see_edit_cta',
        defaultMessage: 'Voir ou modifier',
        description: 'Contacts page card menu link',
    },
    createNetworkingActionTooltip: {
        id: 'contacts.card.create_netwoking_action.tooltip',
        defaultMessage: 'Créer une action de networking à partir de ce contact',
        description: 'Contacts page card create networking action button tooltip',
    },
    deleteTitle: {
        id: 'contacts.delete.title',
        defaultMessage: 'Êtes-vous sur de vouloir supprimer ce contact ?',
        description: 'Contacts page delete contact title',
    },
    deleteSuccess: {
        id: 'contacts.delete.success',
        defaultMessage: 'Contact supprimé avec succès',
        description: 'Contacts page delete contact success',
    },
});
