import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'account.title',
        defaultMessage: 'Votre profil',
        description: 'Account page title',
    },
    infoTitle: {
        id: 'account.infos.title',
        defaultMessage: 'Civilité',
        description: 'Account page user info',
    },
    infoLastName: {
        id: 'account.infos.lastname',
        defaultMessage: 'Nom',
        description: 'Account page user info',
    },
    infoFirstName: {
        id: 'account.infos.firstname',
        defaultMessage: 'Prénom',
        description: 'Account page user info',
    },
    infoGender: {
        id: 'account.infos.gender',
        defaultMessage: 'Genre',
        description: 'Account page user info',
    },
    infoBirthday: {
        id: 'account.infos.birthday',
        defaultMessage: 'Date de naissance',
        description: 'Account page user info',
    },
    infoUserGroups: {
        id: 'account.infos.user_groups',
        defaultMessage: 'Groupe(s)',
        description: 'Account page user info',
    },
    infoCompany: {
        id: 'account.infos.company',
        defaultMessage: 'Entreprise',
        description: 'Account page user info',
    },
    infoCompanyName: {
        id: 'account.infos.company.name',
        defaultMessage: 'Raison sociale',
        description: 'Account page user info',
    },
    infoCompanySerialNumber: {
        id: 'account.infos.company.serial',
        defaultMessage: 'SIRET',
        description: 'Account page user info',
    },
    infoCompanyVAT: {
        id: 'account.infos.company.vat',
        defaultMessage: 'Numéro de TVA',
        description: 'Account page user info',
    },
    infoCompanySize: {
        id: 'account.infos.company.size',
        defaultMessage: 'Taille de l\'entreprise',
        description: 'Account page user info',
    },
    infoCompanyType: {
        id: 'account.infos.company.type',
        defaultMessage: 'Activité principale',
        description: 'Account page user info',
    },
    infoCompanyAddress: {
        id: 'account.infos.company.address',
        defaultMessage: 'Adresse',
        description: 'Account page user info',
    },
    infoCompanySiteAddress: {
        id: 'account.infos.company.site.address',
        defaultMessage: 'Adresse du site',
        description: 'Account page user info',
    },
    infoCompanyBillingAddress: {
        id: 'account.infos.company.billing.address',
        defaultMessage: 'Adresse de facturation',
        description: 'Account page user info',
    },
    infoCompanyGroup: {
        id: 'account.infos.company.group',
        defaultMessage: 'Nom du groupe',
        description: 'Account page user info',
    },
    infoCompanyFirm: {
        id: 'account.infos.company.firm',
        defaultMessage: 'Société',
        description: 'Account page user info',
    },
    infoCompanyDepartment: {
        id: 'account.infos.company.department',
        defaultMessage: 'Département',
        description: 'Account page user info',
    },
    infoCurrentPost: {
        id: 'account.infos.current_post',
        defaultMessage: 'Poste actuel',
        description: 'Account page user info',
    },
    infoCurrentPostStatute: {
        id: 'account.infos.current_post.statute',
        defaultMessage: 'Statut',
        description: 'Account page user info',
    },
    infoCurrentPostGroup: {
        id: 'account.infos.current_post.group',
        defaultMessage: 'Groupe',
        description: 'Account page user info',
    },
    infoCurrentPostJob: {
        id: 'account.infos.current_post.job',
        defaultMessage: 'Emploi',
        description: 'Account page user info',
    },
    infoCurrentPostPost: {
        id: 'account.infos.current_post.post',
        defaultMessage: 'Poste',
        description: 'Account page user info',
    },
    infoCurrentPostSalary: {
        id: 'account.infos.current_post.salary',
        defaultMessage: 'Salaire souhaité',
        description: 'Account page user info',
    },
    infoCurrentPostSalaryPlaceholder: {
        id: 'account.infos.current_post.salary.placeholder',
        defaultMessage: 'Montant brut annuel',
        description: 'Account page user info',
    },
    infoCurrentPostManager: {
        id: 'account.infos.current_post.manager',
        defaultMessage: 'Manager',
        description: 'Account page user info',
    },
    infoCurrentPostHrRep: {
        id: 'account.infos.current_post.hr_rep',
        defaultMessage: 'Référent ressources humaines',
        description: 'Account page user info',
    },
    infoAddress: {
        id: 'account.infos.address',
        defaultMessage: 'Adresse personnelle',
        description: 'Account page user info',
    },
    infoEmail: {
        id: 'account.infos.email',
        defaultMessage: 'Adresse e-mail',
        description: 'Account page user info',
    },
    infoProgram: {
        id: 'account.infos.program',
        defaultMessage: 'Programme de rattachement',
        description: 'Account page user info',
    },
    phoneLabel: {
        id: 'account.form.phone.label',
        defaultMessage: 'Téléphone portable',
        description: 'Account page form',
    },
    phonePlaceholder: {
        id: 'account.form.phone.placeholder',
        defaultMessage: 'Saisir un numéro de téléphone portable',
        description: 'Account page form',
    },
    spokenLangLabel: {
        id: 'account.form.spokenLang.label',
        defaultMessage: 'Langues parlées',
        description: 'Account page form',
    },
    spokenLangPlaceholder: {
        id: 'account.form.spokenLang.placeholder',
        defaultMessage: 'Choisir une langue',
        description: 'Account page form',
    },
    spokenLangLevelPlaceholder: {
        id: 'account.form.spokenLang.level.placeholder',
        defaultMessage: 'Niveau',
        description: 'Account page form',
    },
    spokenLangAddLanguage: {
        id: 'account.form.spokenLang.add_language',
        defaultMessage: 'Ajouter une langue',
        description: 'Account page form',
    },
    spokenLangLengthError: {
        id: 'account.form.spokenLang.length.error',
        defaultMessage: 'Vous ne pouvez pas ajouter plus de 4 langues',
        description: 'Account page form',
    },
    degreesLabel: {
        id: 'account.form.degrees.label',
        defaultMessage: 'Diplômes',
        description: 'Account page form',
    },
    degreesPlaceholder: {
        id: 'account.form.degrees.placeholder',
        defaultMessage: 'Sélectionner un diplôme',
        description: 'Account page form',
    },
    jobOccupationLabel: {
        id: 'account.form.job_occupation.label',
        defaultMessage: 'Métier',
        description: 'Account page form',
    },
    jobOccupationPlaceholder: {
        id: 'account.form.job_occupation.placeholder',
        defaultMessage: 'Sélectionner un métier',
        description: 'Account page form',
    },
    maritalStatusLabel: {
        id: 'account.form.marital_status.label',
        defaultMessage: 'Situation familiale',
        description: 'Account page form',
    },
    maritalStatusPlaceholder: {
        id: 'account.form.marital_status.placeholder',
        defaultMessage: 'Sélectionner une situation familiale',
        description: 'Account page form',
    },
    formFirstSubmit: {
        id: 'account.form.first_submit',
        defaultMessage: 'Enregistrer les informations',
        description: 'Account page form submit button',
    },
    formSubmit: {
        id: 'account.form.submit',
        defaultMessage: 'Modifier les informations',
        description: 'Account page form submit button',
    },
    formAvatarLabel: {
        id: 'account.form.avatar.label',
        defaultMessage: 'Photo du profil',
        description: 'Account page form',
    },
    formAvatarButton: {
        id: 'account.form.avatar.button',
        defaultMessage: 'Choisir une photo',
        description: 'Account page form',
    },
    formAvatarHelp: {
        id: 'account.form.avatar.help',
        defaultMessage: 'La taille de fichier maximale autorisée est de 5 Mo.',
        description: 'Account page form',
    },
    formAvatarFileSizeError: {
        id: 'account.form.avatar.error.file_size',
        defaultMessage: 'La taille de fichier dépasse la limite maximale autorisée (5 Mo)',
        description: 'Account page form',
    },
    formAvatarFileTypeError: {
        id: 'account.form.avatar.error.file_type',
        defaultMessage: 'Le format de fichier n\'est pas respecté, veuillez choisir une image au format JPEG ou PNG',
        description: 'Account page form',
    },
    formConsent: {
        id: 'account.form.consent',
        defaultMessage: 'Je confirme mon consentement pour le traitement des données ci-dessus dans les conditions de la <link>politique de confidentialité</link>.',
        description: 'Account page form',
    },
    passwordTitle: {
        id: 'account.password.title',
        defaultMessage: 'Mot de passe',
        description: 'Account page password',
    },
    passwordFormTitle: {
        id: 'account.password.form_title',
        defaultMessage: 'Modifier le mot de passe associé à votre compte',
        description: 'Account page password',
    },
    passwordSubmit: {
        id: 'account.password.submit',
        defaultMessage: 'Modifier le mot de passe',
        description: 'Account page password',
    },
    currentPasswordLabel: {
        id: 'account.password.current_password.label',
        defaultMessage: 'Mot de passe actuel',
        description: 'Account page password',
    },
    currentPasswordPlaceholder: {
        id: 'account.password.current_password.placeholder',
        defaultMessage: 'Saisir le mot de passe',
        description: 'Account page password',
    },
    newPasswordLabel: {
        id: 'account.password.new_password.label',
        defaultMessage: 'Nouveau mot de passe',
        description: 'Account page password',
    },
    newPasswordPlaceholder: {
        id: 'account.password.new_password.placeholder',
        defaultMessage: 'Saisir le nouveau mot de passe',
        description: 'Account page password',
    },
    languageTitle: {
        id: 'account.language.title',
        defaultMessage: 'Langue préférée',
        description: 'Account page language',
    },
    languageFormTitle: {
        id: 'account.language.form_title',
        defaultMessage: 'Cette option définit la langue utilisée pour l’interface de l’application',
        description: 'Account page language',
    },
    languageFormHelp: {
        id: 'account.language.form_help',
        defaultMessage: 'Elle n’a pas d’impact sur la langue du programme qui vous a été attribué.',
        description: 'Account page language',
    },
    languageSubmit: {
        id: 'account.language.submit',
        defaultMessage: 'Modifier la langue',
        description: 'Account page language',
    },
    languagePlaceholder: {
        id: 'account.language.placeholder',
        defaultMessage: 'Sélectionner une langue',
        description: 'Account page language',
    },
    updateSuccess: {
        id: 'account.update.success',
        defaultMessage: 'Informations mises à jour avec succès',
        description: 'Account form update success',
    },
    degree0: {
        id: 'account.degree.0',
        defaultMessage: 'Sans diplôme',
        description: 'Account degree',
    },
    degree1: {
        id: 'account.degree.1',
        defaultMessage: 'CAP / CAPA / BEP',
        description: 'Account degree',
    },
    degree2: {
        id: 'account.degree.2',
        defaultMessage: 'Niveau BAC (avec ou sans diplôme) brevet professionnel, brevet de maîtrise',
        description: 'Account degree',
    },
    degree3: {
        id: 'account.degree.3',
        defaultMessage: 'BAC + 2 (DEUG, DUT, BTS, DEUST)',
        description: 'Account degree',
    },
    degree4: {
        id: 'account.degree.4',
        defaultMessage: 'BAC + 3 (Licence)',
        description: 'Account degree',
    },
    degree5: {
        id: 'account.degree.5',
        defaultMessage: 'BAC + 4 (Maîtrise)',
        description: 'Account degree',
    },
    degree6: {
        id: 'account.degree.6',
        defaultMessage: 'BAC + 5 et plus (Master, DEA, DESS, Doctorat, Ingénieur)',
        description: 'Account degree',
    },
    single: {
        id: 'account.marital_status.single',
        defaultMessage: 'Célibataire',
        description: 'Account marital status',
    },
    married: {
        id: 'account.marital_status.married',
        defaultMessage: 'Marié(e), pacsé(e) ou en union libre',
        description: 'Account marital status',
    },
    divorced: {
        id: 'account.marital_status.divorced',
        defaultMessage: 'Divorcé(e)',
        description: 'Account marital status',
    },
    widowed: {
        id: 'account.marital_status.widowed',
        defaultMessage: 'Veuf, veuve',
        description: 'Account marital status',
    },
    french: {
        id: 'account.spoken_language.french',
        defaultMessage: 'Français',
        description: 'Account spoken language',
    },
    german: {
        id: 'account.spoken_language.german',
        defaultMessage: 'Allemand',
        description: 'Account spoken language',
    },
    english: {
        id: 'account.spoken_language.english',
        defaultMessage: 'Anglais',
        description: 'Account spoken language',
    },
    arabic: {
        id: 'account.spoken_language.arabic',
        defaultMessage: 'Arabe',
        description: 'Account spoken language',
    },
    chinese: {
        id: 'account.spoken_language.chinese',
        defaultMessage: 'Chinois',
        description: 'Account spoken language',
    },
    spanish: {
        id: 'account.spoken_language.spanish',
        defaultMessage: 'Espagnol',
        description: 'Account spoken language',
    },
    italian: {
        id: 'account.spoken_language.italian',
        defaultMessage: 'Italien',
        description: 'Account spoken language',
    },
    japanese: {
        id: 'account.spoken_language.japanese',
        defaultMessage: 'Japonais',
        description: 'Account spoken language',
    },
    dutch: {
        id: 'account.spoken_language.dutch',
        defaultMessage: 'Néerlandais',
        description: 'Account spoken language',
    },
    russian: {
        id: 'account.spoken_language.russian',
        defaultMessage: 'Russe',
        description: 'Account spoken language',
    },
    otherEuropean: {
        id: 'account.spoken_language.other_european',
        defaultMessage: 'Autre langue européenne',
        description: 'Account spoken language',
    },
    otherAfrican: {
        id: 'account.spoken_language.other_african',
        defaultMessage: 'Autre langue africaine',
        description: 'Account spoken language',
    },
    otherAsian: {
        id: 'account.spoken_language.other_asian',
        defaultMessage: 'Autre langue asiatique',
        description: 'Account spoken language',
    },
    suspensionTitle: {
        id: 'account.suspensions.title',
        defaultMessage: 'Suspension des actions',
        description: 'Account page user suspension',
    },
    suspensionIntro: {
        id: 'account.suspensions.intro',
        defaultMessage: `
            {count, plural,
                one {Vous pouvez suspendre de manière temporaire le déroulement de votre programme pour un maximum d'une semaine.}
                other {Vous pouvez suspendre de manière temporaire le déroulement de votre programme pour un maximum de {count, number} semaines.}
            }
        `,
        description: 'Account page user suspension',
    },
    suspensionDescription: {
        id: 'account.suspensions.description',
        defaultMessage: 'Pendant cette suspension, vous pouvez vous connecter et continuer à travailler sur votre networking ou les actions des semaines passées, vous les retrouverez dans la rubrique <link>Programme</link>.',
        description: 'Account page user suspension',
    },
    suspensionFormModalTitle: {
        id: 'account.suspensions.modal.title',
        defaultMessage: 'Suspension des actions du programme',
        description: 'Account page user suspension',
    },
    suspensionFormModalTitleConfirm: {
        id: 'account.suspensions.modal.confirm.title',
        defaultMessage: 'Souhaitez-vous vraiment suspendre le suivi de votre programme ?',
        description: 'Account page user suspension',
    },
    suspensionCTA: {
        id: 'account.suspensions.cta',
        defaultMessage: 'Suspendre les actions temporairement',
        description: 'Account page user suspension',
    },
    suspensionFormLabel: {
        id: 'account.suspensions.form.label',
        defaultMessage: 'Veuillez indiquer le nombre de semaines de suspension :',
        description: 'Account page user suspension',
    },
    suspensionFormHelp: {
        id: 'account.suspensions.form.help',
        defaultMessage: `
            {count, plural,
                one {Vous avez encore <bold>1 semaine</bold> de suspension à votre disposition.}
                other {Vous avez encore <bold>{count, number} semaines</bold> de suspension à votre disposition.}
            }
        `,
        description: 'Account page user suspension',
    },
    suspensionFormConfirm: {
        id: 'account.suspensions.form.confirm',
        defaultMessage: 'Si vous le confirmez, les actions de la ou des semaines sélectionnées ne seront pas affichées durant cette période. Passé ce délai, les prochaines actions seront débloquées et affichées automatiquement. Vous avez par contre, toujours accès aux actions des semaines passées et à la bibliothèque.',
        description: 'Account page user suspension',
    },
    suspensionFormSuccess: {
        id: 'account.suspensions.form.success',
        defaultMessage: 'Vos actions sont désormais suspendues jusqu’au {date, date}.',
        description: 'Account page user suspension',
    },
    suspensionFormDurationError: {
        id: 'account.suspensions.form.duration_error',
        defaultMessage: 'Veuillez entrer un minimum de 1 et un maximum de {max}.',
        description: 'Account page user suspension',
    },
    suspensionFormSubmit: {
        id: 'account.suspensions.form.submit',
        defaultMessage: 'Valider le nombre de semaines',
        description: 'Account page user suspension',
    },
    suspensionFormConfirmSubmit: {
        id: 'account.suspensions.form.confirm.submit',
        defaultMessage: 'Valider la suspension',
        description: 'Account page user suspension',
    },
    suspensionActiveAlert: {
        id: 'account.suspensions.alert_active',
        defaultMessage: 'Vos actions sont suspendues jusqu’au {date, date}.',
        description: 'Account page user suspension',
    },
    suspensionRemaining: {
        id: 'account.suspensions.remaining',
        defaultMessage: `
            {count, plural,
                one {Il vous reste {count} semaine à disposition.}
                other {Il vous reste {count} semaines à disposition.}
            }
        `,
        description: 'Account page user suspension',
    },
    suspensionReactivateCta: {
        id: 'account.suspensions.reactivate.cta',
        defaultMessage: 'Réactiver',
        description: 'Account page user suspension',
    },
    suspensionReactivateFormModalTitle: {
        id: 'account.suspensions.reactivate.modal.title',
        defaultMessage: 'Souhaitez-vous réactiver votre programme ?',
        description: 'Account page user suspension',
    },
    suspensionReactivateFormModalTitleSuccess: {
        id: 'account.suspensions.reactivate.modal.title.success',
        defaultMessage: 'Votre programme est désormais réactivé et vos nouvelles actions seront disponibles lundi prochain.',
        description: 'Account page user suspension',
    },
    suspensionReactivateFormModalIntro: {
        id: 'account.suspensions.reactivate.modal.intro',
        defaultMessage: 'La réactivation prendra effet lundi {date, date}',
        description: 'Account page user suspension',
    },
    suspensionReactivateFormModalSubmit: {
        id: 'account.suspensions.reactivate.modal.submit',
        defaultMessage: 'Valider la réactivation',
        description: 'Account page user suspension',
    },
    suspensionReactivateFormModalSuccess: {
        id: 'account.suspensions.reactivate.modal.success',
        defaultMessage: `
            {count, plural,
                =0 {Vous avez utilisé toutes les semaines de suspension possibles.}
                one {Il vous reste {count} semaine de suspension possible.}
                other {Il vous reste {count} semaines de suspension possibles.}
                }
        `,
        description: 'Account page user suspension',
    },
    exportInformationsTitle: {
        id: 'account.exportInformations.title',
        defaultMessage: 'Export des données',
        description: 'account page export informations title',
    },
    exportInformationsFormTitle: {
        id: 'account.exportInformations.form.title',
        defaultMessage: 'Récupérez dans un fichier unique l’ensemble de vos données des rubriques Networking, Contacts et Annonces.',
        description: 'account page export informations form title',
    },
    exportInformationsFormHelp: {
        id: 'account.exportInformations.form.helper',
        defaultMessage: 'Cet export sera effectué dans un fichier Excel contenant plusieurs onglets.',
        description: 'account page export informations form helper',
    },
    exportInformationsFormSubmit: {
        id: 'account.exportInformations.form.submit',
        defaultMessage: 'Exporter les données',
        description: 'account page export informations submit button',
    },
});

export default messages;

export const degreesMessages = new Map([
    ['degree0', messages.degree0],
    ['degree1', messages.degree1],
    ['degree2', messages.degree2],
    ['degree3', messages.degree3],
    ['degree4', messages.degree4],
    ['degree5', messages.degree5],
    ['degree6', messages.degree6],
]);

export const maritalStatusMessages = new Map([
    ['single', messages.single],
    ['married', messages.married],
    ['divorced', messages.divorced],
    ['widowed', messages.widowed],
]);

export const spokenLanguagesMessages = new Map([
    ['french', messages.french],
    ['german', messages.german],
    ['english', messages.english],
    ['arabic', messages.arabic],
    ['chinese', messages.chinese],
    ['spanish', messages.spanish],
    ['italian', messages.italian],
    ['japanese', messages.japanese],
    ['dutch', messages.dutch],
    ['russian', messages.russian],
    ['otherEuropean', messages.otherEuropean],
    ['otherAfrican', messages.otherAfrican],
    ['otherAsian', messages.otherAsian],
]);
