import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { BadgeType, BadgeLevel } from '../../store/api/apiTypes';

const gradients: {[key: string]: JSX.Element} = {
    [BadgeLevel.lvl1]: (
        <defs>
            <linearGradient id={`badge-gradient-${BadgeLevel.lvl1}`} x1="31.197%" x2="80.909%" y1="1.381%" y2="86.591%">
                <stop offset="0%" stopColor="#A89DFF" />
                <stop offset="100%" stopColor="#5A47EB" />
            </linearGradient>
        </defs>
    ),
    [BadgeLevel.lvl2]: (
        <defs>
            <linearGradient id={`badge-gradient-${BadgeLevel.lvl2}`} x1="25.969%" x2="81.213%" y1="16.528%" y2="86.658%">
                <stop offset="0%" stopColor="#D8D8D8" />
                <stop offset="100%" stopColor="#A4A3A3" />
            </linearGradient>
        </defs>
    ),
    [BadgeLevel.lvl3]: (
        <defs>
            <linearGradient id={`badge-gradient-${BadgeLevel.lvl3}`} x1="29.178%" x2="72.709%" y1="9.54%" y2="87.689%">
                <stop offset="0%" stopColor="#F4E872" />
                <stop offset="100%" stopColor="#BDA524" />
            </linearGradient>
        </defs>
    ),
};

const shapes: (badgeLevel: BadgeLevel) => { [key: string]: {hexa: JSX.Element, rounded: JSX.Element } } = (badgeLevel) => ({
    cv: {
        hexa: (
            <>
                <path fill={`url(#badge-gradient-${badgeLevel})`} d="M39.25.846c-1.545 0-3.09.382-4.47 1.146L5.53 18.204C2.67 19.788.895 22.776.895 26.004v31.994c0 3.226 1.776 6.215 4.633 7.798L34.78 82.01a9.29 9.29 0 0 0 8.94 0l29.25-16.214c2.858-1.583 4.634-4.572 4.634-7.798V26.003c0-3.227-1.776-6.215-4.633-7.8L43.72 1.994A9.222 9.222 0 0 0 39.25.845" />
                <path fill="#FFF" d="M50.755 56.47c0 .552-.45 1.002-1 1.002h-20.01a1.002 1.002 0 0 1 0-2.007h20.01c.55 0 1 .45 1 1.004zm0-5.018c0 .554-.45 1.004-1 1.004h-20.01a1.002 1.002 0 0 1 0-2.007h20.01c.55 0 1 .448 1 1.002zm0 10.033c0 .554-.45 1.003-1 1.003h-20.01a1.002 1.002 0 0 1 0-2.007h20.01c.55 0 1 .45 1 1.005zM36.25 35.902c0-1.927 1.578-3.51 3.5-3.51s3.502 1.583 3.502 3.51c0 1.927-1.58 3.51-3.502 3.51-1.922 0-3.5-1.583-3.5-3.51zm-4.003 10.534c0-2.138 1.272-3.75 2.783-4.687 1.51-.94 3.27-1.333 4.72-1.333 1.45 0 3.21.394 4.72 1.332 1.51.936 2.783 2.548 2.783 4.686 0 .525-.477 1.003-1 1.003H33.247c-.523 0-1-.48-1-1.004zM66 53.27a6.32 6.32 0 0 1-3.203 5.495l-6.04 3.415V27.374c0-1.65-1.356-3.01-3-3.01H25.743c-1.645 0-3 1.36-3 3.01v35.088L16.2 58.765a6.32 6.32 0 0 1-3.2-5.494V30.73a6.318 6.318 0 0 1 3.2-5.495l20.21-11.423a6.276 6.276 0 0 1 3.088-.807c1.067 0 2.135.27 3.087.807l20.21 11.423A6.318 6.318 0 0 1 66 30.73v22.54zM42.587 70.19a6.31 6.31 0 0 1-6.176 0l-11.668-6.594v-36.22a.978.978 0 0 1 1-1.003h28.012c.57 0 1 .43 1 1.004V63.31L42.588 70.19zm21.192-46.7L43.57 12.063a8.314 8.314 0 0 0-8.14 0L15.22 23.487A8.33 8.33 0 0 0 11 30.73v22.54a8.328 8.328 0 0 0 4.22 7.243l20.21 11.423A8.276 8.276 0 0 0 39.5 73a8.277 8.277 0 0 0 4.07-1.064l20.21-11.423A8.328 8.328 0 0 0 68 53.27V30.73a8.33 8.33 0 0 0-4.22-7.243z" />
            </>
        ),
        rounded: (
            <>
                <circle cx="24" cy="24" r="24" fill={`url(#badge-gradient-${badgeLevel})`} />
                <path fill="#FFF" fillRule="nonzero" d="M28.74 30.067a.42.42 0 0 1-.422.42h-8.425a.42.42 0 0 1 0-.84h8.425a.42.42 0 0 1 .42.42zm0-2.103a.42.42 0 0 1-.422.42h-8.425a.42.42 0 0 1 0-.84h8.425a.42.42 0 0 1 .42.42zm0 4.207a.42.42 0 0 1-.422.422h-8.425a.42.42 0 0 1 0-.842h8.425a.42.42 0 0 1 .42.42zm-6.11-10.727a1.48 1.48 0 0 1 1.475-1.473c.81 0 1.475.665 1.475 1.473a1.48 1.48 0 0 1-1.475 1.472 1.48 1.48 0 0 1-1.474-1.472zm-1.684 4.417c0-.896.536-1.572 1.172-1.965a3.884 3.884 0 0 1 1.987-.56c.61 0 1.352.167 1.988.56.636.393 1.17 1.07 1.17 1.965 0 .22-.2.42-.42.42h-5.476c-.22 0-.42-.2-.42-.42zm14.212 2.867c0 .953-.517 1.836-1.35 2.304l-2.542 1.433V17.867a1.27 1.27 0 0 0-1.264-1.262H18.208a1.27 1.27 0 0 0-1.263 1.262V32.58l-2.755-1.55a2.648 2.648 0 0 1-1.348-2.303v-9.453c0-.954.517-1.837 1.348-2.305l8.51-4.79c.4-.226.85-.34 1.3-.34.45 0 .9.114 1.3.34l8.51 4.79a2.648 2.648 0 0 1 1.348 2.304v9.453zM25.3 35.82c-.802.452-1.798.452-2.6 0l-4.913-2.765V17.867c0-.24.18-.42.42-.42h11.795c.24 0 .422.18.422.42v15.07L25.3 35.82zm8.923-19.584l-8.51-4.79a3.513 3.513 0 0 0-3.427 0l-8.51 4.79A3.49 3.49 0 0 0 12 19.274v9.453c0 1.256.68 2.42 1.777 3.037l8.51 4.79c.528.297 1.12.446 1.713.446.592 0 1.185-.15 1.714-.446l8.51-4.79A3.49 3.49 0 0 0 36 28.727v-9.453a3.49 3.49 0 0 0-1.777-3.038z" />
            </>
        ),
    },
    linkedin: {
        hexa: (
            <>
                <path fill={`url(#badge-gradient-${badgeLevel})`} d="M39.25.846c-1.545 0-3.09.382-4.47 1.146L5.53 18.204C2.67 19.788.895 22.776.895 26.004v31.994c0 3.226 1.776 6.215 4.633 7.798L34.78 82.01a9.29 9.29 0 0 0 8.94 0l29.25-16.214c2.858-1.583 4.634-4.572 4.634-7.798V26.003c0-3.227-1.776-6.215-4.633-7.8L43.72 1.994A9.222 9.222 0 0 0 39.25.845" />
                <path fill="#FFF" d="M33.695 31.422a3.42 3.42 0 0 1-3.408 3.42c-1.878 0-3.36-1.537-3.36-3.42 0-1.882 1.482-3.468 3.36-3.468 1.877 0 3.408 1.586 3.408 3.468zm18.378 12.93v11.693H46.64V44.353c0-.545-.495-1.436-.94-1.586-2.174-1.238-4.446.99-4.446.99v12.288H35.72V36.872h5.534v.89c2.124-1.088 5.04-.89 7.114.2 2.223 1.24 3.705 3.914 3.705 6.39zM27.52 56.046h5.533V36.872H27.52v19.173zM66 53.27a6.32 6.32 0 0 1-3.203 5.495l-20.21 11.423a6.308 6.308 0 0 1-6.174 0l-20.21-11.423A6.32 6.32 0 0 1 13 53.27V30.73a6.32 6.32 0 0 1 3.202-5.496l20.21-11.422a6.275 6.275 0 0 1 3.088-.807c1.068 0 2.136.27 3.087.807l20.21 11.422A6.32 6.32 0 0 1 66 30.73v22.54zm-2.22-29.783L43.57 12.064a8.314 8.314 0 0 0-8.14 0L15.22 23.487A8.328 8.328 0 0 0 11 30.73v22.54a8.33 8.33 0 0 0 4.22 7.243l20.21 11.423A8.274 8.274 0 0 0 39.5 73a8.278 8.278 0 0 0 4.07-1.064l20.21-11.423A8.33 8.33 0 0 0 68 53.27V30.73a8.328 8.328 0 0 0-4.22-7.243z" />
            </>
        ),
        rounded: (
            <>
                <circle cx="24" cy="24" r="24" fill={`url(#badge-gradient-${badgeLevel})`} />
                <path fill="#FFF" d="M21.556 19.564c0 .79-.645 1.434-1.435 1.434a1.42 1.42 0 0 1-1.414-1.434c0-.79.624-1.454 1.415-1.454.79 0 1.436.665 1.436 1.454zm7.738 5.423v4.903h-2.288v-4.903c0-.23-.208-.603-.395-.665-.915-.52-1.87.415-1.87.415v5.153h-2.33v-8.04h2.33v.373c.893-.457 2.12-.373 2.994.084.936.52 1.56 1.64 1.56 2.68zM18.956 29.89h2.33v-8.04h-2.33v8.04zm16.202-1.163c0 .953-.517 1.836-1.35 2.304L25.3 35.82c-.802.452-1.798.452-2.6 0l-8.51-4.79a2.648 2.648 0 0 1-1.348-2.303v-9.454c0-.953.517-1.836 1.348-2.304l8.51-4.79c.4-.226.85-.34 1.3-.34.45 0 .9.114 1.3.34l8.51 4.79a2.648 2.648 0 0 1 1.348 2.303v9.454zm-.935-12.49l-8.51-4.79a3.513 3.513 0 0 0-3.427 0l-8.51 4.79A3.49 3.49 0 0 0 12 19.272v9.454c0 1.256.68 2.42 1.777 3.037l8.51 4.79c.528.297 1.12.446 1.713.446s1.185-.15 1.714-.446l8.51-4.79A3.49 3.49 0 0 0 36 28.727v-9.454a3.49 3.49 0 0 0-1.777-3.037z" />
            </>
        ),
    },
    professionalProject: {
        hexa: (
            <>
                <path fill={`url(#badge-gradient-${badgeLevel})`} d="M39.25.846c-1.545 0-3.09.382-4.47 1.146L5.53 18.204C2.67 19.788.895 22.776.895 26.004v31.994c0 3.226 1.776 6.215 4.633 7.798L34.78 82.01a9.29 9.29 0 0 0 8.94 0l29.25-16.214c2.858-1.583 4.634-4.572 4.634-7.798V26.003c0-3.227-1.776-6.215-4.633-7.8L43.72 1.994A9.222 9.222 0 0 0 39.25.845" />
                <path fill="#FFF" d="M43.57 12.064l20.21 11.423A8.33 8.33 0 0 1 68 30.73v22.54a8.33 8.33 0 0 1-4.22 7.243L43.57 71.936A8.277 8.277 0 0 1 39.5 73a8.274 8.274 0 0 1-4.07-1.064L15.22 60.513A8.328 8.328 0 0 1 11 53.27V30.73a8.33 8.33 0 0 1 4.22-7.243l20.21-11.423a8.314 8.314 0 0 1 8.14 0zm-4.07.94c-1.068 0-2.135.27-3.088.808l-20.21 11.423a6.318 6.318 0 0 0-3.2 5.494v22.54a6.32 6.32 0 0 0 3.2 5.495l20.21 11.423a6.308 6.308 0 0 0 6.176 0l20.21-11.423a6.318 6.318 0 0 0 3.2-5.494V30.73a6.317 6.317 0 0 0-3.2-5.495l-20.21-11.423a6.275 6.275 0 0 0-3.088-.807zm2.2 38.448v5.44a1.22 1.22 0 0 1-1.1 1.206l-.126.006h-2.067a1.223 1.223 0 0 1-1.22-1.09l-.006-.123v-5.438h4.52zm7.093-9.355c.634 0 1.157.478 1.22 1.09l.006.123v5.89a1.22 1.22 0 0 1-1.1 1.206l-.127.006H29.258c-.334 0-.652-.135-.88-.368l-.094-.106L26 46.993c-.308-.4-.333-.94-.077-1.364l.077-.113 2.284-2.945c.203-.26.504-.43.832-.467l.142-.008h19.535zm-7.093-3.53v2.49h-4.52v-2.49h4.52zm7.643-9.355c.334 0 .652.135.882.37l.092.104L52.6 32.63c.31.402.335.942.078 1.365l-.077.112-2.283 2.945c-.202.26-.504.43-.832.467l-.142.007H29.808a1.223 1.223 0 0 1-1.22-1.09l-.006-.123v-5.89a1.22 1.22 0 0 1 1.1-1.206l.126-.006h19.535zm-8.87-3.316c.635 0 1.158.478 1.22 1.09l.007.123v1.062h-4.52V27.11a1.22 1.22 0 0 1 1.102-1.208l.125-.006h2.067z" />
            </>
        ),
        rounded: (
            <>
                <circle cx="24" cy="24" r="24" fill={`url(#badge-gradient-${badgeLevel})`} />
                <path fill="#FFF" d="M25.714 11.446l8.51 4.79A3.49 3.49 0 0 1 36 19.274v9.453a3.49 3.49 0 0 1-1.777 3.037l-8.51 4.79c-.528.297-1.12.446-1.713.446s-1.185-.15-1.714-.446l-8.51-4.79A3.49 3.49 0 0 1 12 28.727v-9.453c0-1.257.68-2.42 1.777-3.038l8.51-4.79a3.513 3.513 0 0 1 3.427 0zM24 11.84c-.45 0-.9.114-1.3.34l-8.51 4.79a2.648 2.648 0 0 0-1.348 2.304v9.453c0 .953.517 1.836 1.348 2.304l8.51 4.79c.802.452 1.798.452 2.6 0l8.51-4.79a2.648 2.648 0 0 0 1.348-2.303v-9.453c0-.954-.517-1.837-1.348-2.305l-8.51-4.79a2.65 2.65 0 0 0-1.3-.34zm.926 16.124v2.28c0 .25-.183.458-.423.5l-.093.01h-.87a.515.515 0 0 1-.508-.418l-.008-.09v-2.282h1.902zm2.987-3.923c.253 0 .464.18.508.418l.01.09v2.47c0 .25-.184.458-.424.502l-.093.008h-8.225a.522.522 0 0 1-.346-.13l-.064-.07-.962-1.234a.504.504 0 0 1-.052-.537l.052-.082.962-1.235a.52.52 0 0 1 .315-.19l.095-.01h8.225zm-2.987-1.48v1.045h-1.902V22.56h1.902zm3.22-3.923c.127 0 .25.048.344.13l.065.07.96 1.234c.123.158.14.366.053.538l-.052.082-.96 1.235a.52.52 0 0 1-.316.19l-.095.01H19.92a.515.515 0 0 1-.51-.418l-.007-.09v-2.47c0-.25.183-.458.423-.502l.093-.008h8.225zm-3.736-1.39c.253 0 .464.18.508.417l.008.09v.447h-1.902v-.445c0-.25.183-.457.423-.5l.093-.008h.87z" />
            </>
        ),
    },
});

const svg = (badgeLevel: BadgeLevel = BadgeLevel.lvl1, badgeType: BadgeType, rounded?: boolean) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={rounded ? 48 : 78}
        height={rounded ? 48 : 84}
        viewBox={`0 0 ${rounded ? 48 : 78} ${rounded ? 48 : 84}`}
    >
        {gradients[badgeLevel]}
        {rounded ?
            shapes(badgeLevel)[badgeType].rounded :
            shapes(badgeLevel)[badgeType].hexa
        }
    </svg>
);

interface IconBadgeProps extends IconComponentProps {
    badgeType?: BadgeType;
    badgeLevel?: BadgeLevel;
    rounded?: boolean;
}

const IconBadge: FC<IconBadgeProps> = ({ badgeLevel, badgeType, rounded, ...props }) => {
    if (!badgeType) {
        return null;
    }

    return (
        // @ts-ignore
        <Icon
            component={svg.bind(null, badgeLevel, badgeType, rounded)}
            {...props}
            className={`icon-badge${props.className ? ` ${props.className}` : ''}`}
        />
    );
};

export default IconBadge;
