import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 18">
        <path fill="currentColor" d="M9.86 16.043C9.857 17.123 9.026 18 8 18c-1.024 0-1.855-.877-1.857-1.957zM5.57.51C7.99-.53 10.765.05 12.63 1.98c1.225 1.256 1.915 2.98 1.912 4.783v4.314a2.75 2.75 0 0 0 1.03 2.152c.368.288.517.784.375 1.235-.142.45-.542.755-.993.755H1.046c-.454 0-.856-.308-.995-.763-.14-.455.016-.953.384-1.233A2.744 2.744 0 0 0 1.5 11.046V6.763C1.547 4.01 3.147 1.55 5.57.51z" />
    </svg>
);

const IconBell: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-bell${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconBell;
