import { Form, Input, Button } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { MainReducerState } from '../../store/reducers';
import { update, getAppointmentsUpdateState } from '../../store/actions/appointments';

import genericMessages from '../../locale/genericMessages';
import formMessages from '../../locale/formMessages';

const messages = defineMessages({
    textPlaceholder: {
        id: 'networking_action_notes.form.text.placeholder',
        defaultMessage: 'Saisir une note',
        description: 'Networking action notes block form placeholder',
    },
    submit: {
        id: 'networking_action_notes.form.submit',
        defaultMessage: 'Enregistrer la note',
        description: 'Networking action notes block form submit',
    },
});

interface Note {
    text: string;
    date: string;
}

interface FormValues {
    text: string;
}

interface NoteFormProps {
    note?: Note;
    onSubmit: (value: FormValues) => void;
    onCancel: () => void;
    isLoading?: boolean;
}

const NoteForm: FC<NoteFormProps> = ({
    note, onSubmit, onCancel, isLoading,
}) => {
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    return (
        <Form<FormValues>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onSubmit}
            initialValues={note ? {
                text: note.text,
            } : undefined}
        >
            <Form.Item
                name="text"
                rules={[{ required: true, message: formatMessage(formMessages.requiredField) }]}
            >
                <Input.TextArea
                    placeholder={formatMessage(messages.textPlaceholder)}
                    rows={4}
                />
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <div className="flex flex-between">
                        <Button
                            onClick={onCancel}
                            ghost
                        >
                            <FormattedMessage {...genericMessages.cancel} />
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            disabled={
                                form &&
                                (
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                )
                            }
                        >
                            <FormattedMessage {...messages.submit} />
                        </Button>
                    </div>
                )}
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateAppointment: update.trigger,
    },
)(NoteForm);
