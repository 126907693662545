import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Spin, AutoComplete } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import {
    UsersState,
    search as searchUsers,
} from '../store/actions/users';
import { SelectProps } from 'antd/lib/select';
import { User } from '../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { UserListPayload } from '../store/api/users';
import { getFullName } from '../helpers';
import { AutoCompleteProps } from 'antd/lib/auto-complete';

interface Option {
    value: string;
}

interface UsersAutoCompleteProps {
    value ?: string;
    size?: SizeType;
    filters ?: UserListPayload;
    placeholder: string;
    users: UsersState;
    onChange?: (value: any) => void;
    search: typeof searchUsers.trigger;
}

const UsersAutoComplete: FC<UsersAutoCompleteProps> = forwardRef((props, ref: any) => {

    const {onChange, users, search, size, filters, placeholder} = props;
    const [ value, setValue ] = useState<string>();

    useEffect(() => {
        setValue('');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
            if (onChange) { onChange(props.value); }
        }
    }, [props.value, onChange]);

    const onFocus: SelectProps<User['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<User['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
        });
    };

    const onChangeSelect: AutoCompleteProps['onChange'] = (newValue: any, option: any) => {
        setValue(option.label);
        if (onChange) {onChange(option.label); }
    };

    const options: Option[] = (users.search?.data?.items) ?
        users.search.data.items.map((user) => ({
            value: user.id,
            label: getFullName(user.firstName, user.lastName),
        }))
    : [];

    return (
        <AutoComplete
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder={placeholder || 'Recherchez un utilisateur'}
            notFoundContent={users.search.loading ? <Spin size="small" /> : null}
            options={options}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
        />
    );

});

UsersAutoComplete.defaultProps = {
    size: 'large',
};

const mapStateToProps = (state: MainReducerState) => ({
    users: state.users,
});

export default connect(
    mapStateToProps,
    {
        search: searchUsers.trigger,
    },
    null,
    { forwardRef: true },
)(UsersAutoComplete);
