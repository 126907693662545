import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Button } from 'antd';

import { getUser, update as updateAction } from '../store/actions/auth';
import { User } from '../store/api/apiTypes';
import { ui, getUiState } from '../store/actions/ui';
import { MainReducerState } from '../store/reducers';

import Modal from './Modal';
import { getActiveSuspension } from '../helpers/data';

interface SuspensionModalProps {
    hasShownSuspensionModal: boolean;
    setUi: typeof ui.actions.set;
    user?: User;
}

const SuspensionModal: FC<SuspensionModalProps> = ({ hasShownSuspensionModal, setUi, user }) => {
    const suspension = getActiveSuspension(user);
    const [isSuspensionModalVisible, setIsSuspensionModalVisible] = useState(!!suspension && !hasShownSuspensionModal);
    const onClose = () => {
        setIsSuspensionModalVisible(false);
    };

    useEffect(() => {
        if (isSuspensionModalVisible) {
            setUi({ hasShownSuspensionModal: true });
        }
    }, [isSuspensionModalVisible, setUi]);

    return (
        <Modal
            footer={null}
            onCancel={onClose}
            visible={isSuspensionModalVisible}
            className="classic-modal"
            zIndex={900}
            width={444}
        >
            <Typography.Paragraph style={{ marginBottom: '1.5rem' }} strong>
                <FormattedMessage
                    id="suspension_modal.text"
                    defaultMessage="Pour rappel, vos actions sont suspendues jusqu'au {date, date}."
                    values={{ date: new Date(suspension?.toDate ?? '') }}
                    tagName="p"
                />
            </Typography.Paragraph>
            <Button type="primary" onClick={setIsSuspensionModalVisible.bind(null, false)}>
                <FormattedMessage
                    id="suspension_modal.cta"
                    defaultMessage="Accéder au site"
                />
            </Button>
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    hasShownSuspensionModal: getUiState(state).hasShownSuspensionModal,
});

export default connect(
    mapStateToProps,
    {
        setUi: ui.actions.set,
        updateUser: updateAction.trigger,
    },
)(SuspensionModal);
