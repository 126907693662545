import React, { FC, useEffect, useState, ReactText, useCallback } from 'react';
import { FormattedMessage, useIntl, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { Typography, Badge, Button, Table, Avatar, Dropdown, Menu, Modal, Upload, Tag, message } from 'antd';
import { UserOutlined, UploadOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { UploadProps } from 'antd/lib/upload';
import { DateTime } from 'luxon';

import '../../../assets/styles/ClientUsers.less';
import '../../../assets/styles/Table.less';

import { User, RoleSlug, rolesMessages, SelectedProgram } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import { getUser } from '../../../store/actions/auth';
import { FilterQuery } from '../../../store/api';
import {
    UsersState,
    list as usersList,
    roles as usersRoles,
    sendWelcomeEmail as usersSendWelcomeEmail,
    importUsers as usersImport,
    getUsersExportState,
    exportAll,
} from '../../../store/actions/users';

import Seo from '../../../components/Seo';
import useSessionStorage from '../../../hooks/sessionStorage';
import { getRoute, RoutePathName } from '../../../routes';
import HeaderCTA from '../../../components/HeaderCTA';
import { IconPlus, IconMore } from '../../../components/icons';
import clientMessages from '../../../locale/clientMessages';
import { usePrevious, useQueryParams } from '../../../hooks';
import genericMessages from '../../../locale/genericMessages';
import Can from '../../../components/Can';
import { downloadFile } from '../../../helpers';
import ClientUsersMessages from './ClientUsersMessages';
import { CreateUserStep } from './CreateUser';

const rowKey = (item: User) => `${item.id}`;

interface ClientUsersListProps {
    user?: User;
    users: UsersState;
    getList: typeof usersList.trigger;
    getRoles: typeof usersRoles.trigger;
    sendWelcomeEmail: typeof usersSendWelcomeEmail.trigger;
    importUsers: typeof usersImport.trigger;
    usersExportState: UsersState['exportAll'];
    exportUsers: typeof exportAll.trigger;
}

const ClientUsersList: FC<ClientUsersListProps> = ({
    user,
    users,
    getList,
    getRoles,
    sendWelcomeEmail,
    importUsers,
    usersExportState,
    exportUsers,
}) => {

    const itemsPerPage: number = 20;
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation<{ isBack?: boolean } | undefined>();
    const urlParams = useQueryParams();
    const groupId = urlParams.get('group');
    const [ modal, modalContextHolder ] = Modal.useModal();
    const [ initialized, setInitialized ] = useState(false);
    const [ selectedRowKeys, setSelectedRowKeys ] = useState<ReactText[]>([]);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('users_lastSearch', {});
    const previous = usePrevious({
        sendWelcomeEmail: users.sendWelcomeEmail,
        importUsers: users.importUsers,
        usersExportState,
    });
    const defaultRoles = [RoleSlug.manager, RoleSlug.headHR, RoleSlug.user];

    const isBack = () => {
        return history.action === 'POP' || location.state?.isBack;
    };

    // ---------------------------------------
    // Initial search

    useEffect(() => {
        getRoles();

        setLastSearchParams(!isBack() ? {
            organization: user?.organization?.id,
            page: 0,
            pageSize: itemsPerPage,
            role: defaultRoles,
            ...(groupId ? {
                group: groupId,
            } : {}),
        } : {
            ...lastSearchParams,
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On search params change, reload the list

    const refresh = useCallback(() => {
        getList({...lastSearchParams});
    }, [getList, lastSearchParams]);

    useEffect(() => {
        if (lastSearchParams && initialized) { refresh(); }
    }, [lastSearchParams, initialized, refresh]);

    // ---------------------------------------
    // Handle search, filters and sort changes

    const onTableChange: TableProps<User>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        if (tableFilters.role && tableFilters.role.length > 0) {
            queryFilters.push({
                name: 'role',
                value: tableFilters.role[0],
            });
        } else {
            queryFilters.push({
                name: 'role',
                value: defaultRoles,
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    // ---------------------------------------
    // Add user

    const onClickAddUser = () => {
        history.push({
            pathname: getRoute(RoutePathName.clientUserCreate, { step: CreateUserStep.identity }),
            state: {
                previous: getRoute(RoutePathName.clientUsers),
            },
        });
    };

    // ---------------------------------------
    // Export users

    const onClickExportUsers = () => {
        exportUsers();
    };

    useEffect(() => {
        if (previous?.usersExportState.loading && !usersExportState.loading) {
            if (usersExportState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else if (usersExportState.data) {
                downloadFile(usersExportState.data, `mcm-utilisateurs-${DateTime.local().toFormat('yyyy-dd-MM')}.csv`);
            }
        }
    }, [previous, usersExportState.loading, usersExportState.error, formatMessage, usersExportState.data]);

    // ---------------------------------------
    // Import users

    const onImportFileSelect: UploadProps['beforeUpload'] = (file) => {
        importUsers({file});
        return false;
    };

    useEffect(() => {
        if (previous?.importUsers.loading && !users.importUsers.loading) {
            if (users.importUsers.error) {
                if (users.importUsers.error?.data?.message) {
                    let errorMessage: string = users.importUsers.error?.data?.message;

                    if (users.importUsers.error?.data?.fields) {
                        for (const [, value] of Object.entries(users.importUsers.error?.data?.fields)) {
                            errorMessage += '<br>- ' + value;
                        }
                    }

                    message.error(<span dangerouslySetInnerHTML={{__html: errorMessage}} />);
                } else {
                    message.error(formatMessage(genericMessages.defaultError));
                }
            } else {
                message.success(formatMessage(ClientUsersMessages.importSuccess));
                refresh();
            }
        }
    }, [previous, users.importUsers.loading, users.importUsers.error, formatMessage, refresh]);

    // ---------------------------------------
    // Welcome email

    const openWelcomeEmailConfirm = () => {
        modal.confirm({
            icon: null,
            title: formatMessage(ClientUsersMessages.confirmSendWelcomeEmail, {
                users: formatMessage(ClientUsersMessages.userCount, {count: selectedRowKeys.length}),
            }),
            okText: formatMessage(ClientUsersMessages.confirmSendEmail),
            onOk: confirmSendWelcomeEmail,
        });
    };

    const sendWelcomeEmailTo = (u: User) => {
        sendWelcomeEmail({
            userIds: [u.id],
        });
    };

    const confirmSendWelcomeEmail = () => {
        sendWelcomeEmail({
            userIds: selectedRowKeys,
        });
    };

    useEffect(() => {
        if (previous?.sendWelcomeEmail.loading && !users.sendWelcomeEmail.loading) {
            if (users.sendWelcomeEmail.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ClientUsersMessages.welcomeEmailSuccess));
            }
        }
    }, [previous, users.sendWelcomeEmail.loading, users.sendWelcomeEmail.error, formatMessage]);

    // ---------------------------------------
    // Table columns

    const columnSortOrder = (columnIndex: string) => {
        return (lastSearchParams.sort === columnIndex) ? lastSearchParams.order : undefined;
    };

    const getFilterValue = (filterName: string, isYesNo: boolean = false) => {
        if (!lastSearchParams?.filters) { return false; }
        const filter = lastSearchParams.filters.find((f: any) => f.name === filterName);

        if (isYesNo && filter) {
            return (filter.value) ? 'yes' : 'no';
        }

        return (filter) ? filter.value : undefined;
    };

    const actionsMenu = (record: User) => (
        <Menu>
            <Menu.Item>
                <NavLink to={getRoute(RoutePathName.clientUser, { userId: record.id })}>
                    <FormattedMessage {...ClientUsersMessages.viewOrEdit} />
                </NavLink>
            </Menu.Item>
            <Menu.Item onClick={sendWelcomeEmailTo.bind(null, record)}>
                <FormattedMessage {...ClientUsersMessages.sendWelcomeEmail} />
            </Menu.Item>
            {record.selectedProgram && (
                <>
                    <Menu.Item>
                        <NavLink to={getRoute(RoutePathName.clientUserProgramClosure, { userId: record.id })}>
                            <FormattedMessage {...ClientUsersMessages.closeProgram} />
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                        <NavLink to={getRoute(RoutePathName.clientUser, { userId: record.id })}>
                            <FormattedMessage {...ClientUsersMessages.extendProgram} />
                        </NavLink>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );

    const columns: Array<ColumnProps<User>> = [
        {
            dataIndex: 'name',
            title: 'Nom',
            render: (name, record) => (
                <NavLink to={getRoute(RoutePathName.clientUser, { userId: record.id })} className="user-link">
                    <Avatar src={record?.avatarUrl} size={48}>
                        {!record?.avatarUrl && <UserOutlined />}
                    </Avatar>
                    {record.firstName} {record.lastName}
                </NavLink>
            ),
            sorter: true,
            defaultSortOrder: columnSortOrder('name'),
        },
        {
            dataIndex: 'role',
            title: 'Type',
            render: (role) => role ? <FormattedMessage {...rolesMessages.get(role.slug)} /> : '-',
            filters: [
                    RoleSlug.manager,
                    RoleSlug.user,
                    RoleSlug.headHR,
                ].map((slug) => (
                    {text: <FormattedMessage {...rolesMessages.get(slug)} />, value: slug}
                )),
            filterMultiple: false,
            defaultFilteredValue: getFilterValue('type', true),
        },
        {
            dataIndex: 'groups',
            title: 'Groupe(s)',
            render: (groups: User['groups'], record) => (
                <>
                    {groups && groups.map((group) => (
                        <Tag key={group.id}>{group.name}</Tag>
                    ))}
                    {record.scope?.groups?.map((group) => (
                        <Tag key={group.id} className="tag-primary">{group.name}&nbsp;<SettingOutlined /></Tag>
                    ))}
                </>
            ),
        },
        {
            dataIndex: 'selectedProgram',
            title: 'Programme',
            render: (selectedProgram) => (
                <>
                    {selectedProgram?.program?.name || '-'}
                </>
            ),
        },
        {
            dataIndex: 'selectedProgram',
            title: 'Dates du programme',
            width: 180,
            render: (selectedProgram: SelectedProgram) => (
                <>
                    {selectedProgram?.dates?.fromDate && (
                        <FormattedDate value={new Date(selectedProgram.dates?.fromDate)}/>
                    )}
                    &nbsp;-&nbsp;
                    {selectedProgram?.dates?.toDate && (
                        <FormattedDate value={new Date(selectedProgram.dates?.toDate)}/>
                    )}
                </>
            ),
        },
        {
            key:  'actions',
            width: 50,
            render: (text, record) => (
                <Dropdown
                    overlay={actionsMenu.bind(null, record)}
                    trigger={['click']}
                >
                    <Button
                        className="more-actions"
                        type="text"
                        icon={<IconMore />}
                        size="large"
                    />
                </Dropdown>
            ),
        },
    ];

    const rowSelection: TableRowSelection<User> = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    return (
        <div id="client-users-list">
            <Seo title={formatMessage(ClientUsersMessages.title)} />

            <Can roles={[RoleSlug.superAdmin, RoleSlug.owner, RoleSlug.admin, RoleSlug.headHR]}>
                <HeaderCTA
                    onClick={onClickAddUser}
                    icon={<IconPlus />}
                >
                    <FormattedMessage {...ClientUsersMessages.addUser} />
                </HeaderCTA>
            </Can>

            <Typography.Title level={1}>
                <FormattedMessage
                    {...clientMessages.users}
                    values={{
                        count: users.list.data?.totalCount,
                    }}
                />
                &nbsp;
                <Badge count={users.list.data?.totalCount} overflowCount={10000} />
            </Typography.Title>

            <div className="table-actions-row">
                <div className="group">
                    <Search
                        placeholder="Rechercher"
                        defaultValue={isBack() ? lastSearchParams.search : undefined}
                        loading={users.list.loading}
                        onSearch={onSearch}
                        allowClear
                        size="small"
                    />

                    <Button
                        type="ghost"
                        shape="round"
                        onClick={openWelcomeEmailConfirm}
                        disabled={selectedRowKeys.length === 0}
                    >
                        <FormattedMessage {...ClientUsersMessages.sendWelcomeEmail} />
                    </Button>
                </div>

                <div>
                    <Upload
                        name="file"
                        beforeUpload={onImportFileSelect}
                        showUploadList={false}
                    >
                        <Button
                            type="ghost"
                            shape="round"
                        >
                            <UploadOutlined /> <FormattedMessage {...ClientUsersMessages.importUsers} />
                        </Button>
                    </Upload>
                    <Button
                        type="ghost"
                        shape="round"
                        onClick={onClickExportUsers}
                        loading={usersExportState.loading}
                        style={{ marginLeft: 8 }}
                    >
                        <DownloadOutlined /> <FormattedMessage {...ClientUsersMessages.exportUsers} />
                    </Button>
                </div>
            </div>

            {users.list.data ? (
                <Table<User>
                    className="page-table"
                    rowKey={rowKey}
                    rowSelection={rowSelection}
                    columns={columns}
                    loading={users.list.loading}
                    dataSource={users.list.data.items}
                    pagination={{
                        total: users.list.data.totalCount,
                        current: users.list.data.page + 1,
                        pageSize: users.list.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    // tslint:disable-next-line: jsx-no-lambda
                    onRow={(record) => {
                        return {
                          onDoubleClick: () => { history.push(getRoute(RoutePathName.clientUser, { userId: record.id })); },
                        };
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}

            {modalContextHolder}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    users: state.users,
    usersExportState: getUsersExportState(state),
});

export default connect(
    mapStateToProps,
    {
        getList: usersList.trigger,
        getRoles: usersRoles.trigger,
        sendWelcomeEmail: usersSendWelcomeEmail.trigger,
        importUsers: usersImport.trigger,
        exportUsers: exportAll.trigger,
    },
)(ClientUsersList);
