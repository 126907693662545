import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, Space, Alert, Modal } from 'antd';

import { AuthState, getAuthState, acceptTerms } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { StaticPageSlug } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import { IconArrowRight, IconTerms, IconPrivacy } from '../../components/icons';
import LoginLayout from '../../components/LoginLayout';
import genericMessages from '../../locale/genericMessages';
import LoginTermsMessages from './LoginTermsMessages';
import Privacy from '../privacy';
import StaticPage from '../staticPage';

interface LoginTermsProps extends RouteComponentProps {
    onAcceptTerms: typeof acceptTerms.trigger;
    authState: AuthState;
}

const LoginTerms: FC<LoginTermsProps> = ({ authState, onAcceptTerms }) => {
    const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
    const [hasRefused, setHasRefused] = useState(false);
    const { formatMessage } = useIntl();
    const onClickAccept = () => {
        onAcceptTerms({ acceptedTerms: true });
    };

    if (authState.user?.acceptedTerms) {
        return <Redirect to="/" />;
    }

    const error = authState.acceptTermsError ?
        <FormattedMessage {...genericMessages.error} /> :
        null;

    return (
        <LoginLayout>
            <Seo title={formatMessage(LoginTermsMessages.title)} />
            <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="logo" />
            <Typography.Title level={1}>
                <FormattedMessage {...LoginTermsMessages.title} />
            </Typography.Title>
            {hasRefused ? (
                <>
                    <Typography.Paragraph>
                        <FormattedMessage
                            {...LoginTermsMessages.refusedDescription}
                            values={{ br: <><br /><br /></> }}
                            tagName="p"
                        />
                        <Button
                            onClick={setHasRefused.bind(null, false)}
                            size="large"
                            ghost
                        >
                            <FormattedMessage {...LoginTermsMessages.changeChoiceButton} />
                        </Button>
                    </Typography.Paragraph>
                </>
            ) : (
                <>
                    <Typography.Paragraph>
                        <FormattedMessage
                            {...LoginTermsMessages.description}
                            tagName="p"
                        />
                    </Typography.Paragraph>
                    <Space direction="vertical" size={24} className="terms-links">
                        <Button
                            onClick={setIsTermsModalVisible.bind(null, true)}
                            className="terms-link"
                            block
                        >
                            <IconTerms />
                            <FormattedMessage {...LoginTermsMessages.termsLink} />
                            <IconArrowRight />
                        </Button>
                        <Button
                            onClick={setIsPrivacyModalVisible.bind(null, true)}
                            className="terms-link"
                            target="_blank"
                            block
                        >
                            <IconPrivacy />
                            <FormattedMessage {...LoginTermsMessages.privacyLink} />
                            <IconArrowRight />
                        </Button>
                    </Space>
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>
                        <Button
                            onClick={onClickAccept}
                            size="large"
                            type="primary"
                            loading={authState.loading}
                        >
                            <FormattedMessage {...LoginTermsMessages.acceptButton} />
                        </Button>
                        <Button
                            onClick={setHasRefused.bind(null, true)}
                            size="large"
                            danger
                        >
                            <FormattedMessage {...LoginTermsMessages.refuseButton} />
                        </Button>
                    </Space>
                    {error ? (
                        <div className="login-error-message">
                            <Alert
                                type="error"
                                message={error}
                                showIcon
                            />
                        </div>
                    ) : null}
                    <Modal
                        footer={(
                            <Button
                                type="primary"
                                onClick={setIsPrivacyModalVisible.bind(null, false)}
                            >
                                <FormattedMessage {...genericMessages.close} />
                            </Button>
                        )}
                        onCancel={setIsPrivacyModalVisible.bind(null, false)}
                        visible={isPrivacyModalVisible}
                        width="80%"
                    >
                        <Privacy />
                    </Modal>
                    <Modal
                        footer={(
                            <Button
                                type="primary"
                                onClick={setIsTermsModalVisible.bind(null, false)}
                            >
                                <FormattedMessage {...genericMessages.close} />
                            </Button>
                        )}
                        onCancel={setIsTermsModalVisible.bind(null, false)}
                        visible={isTermsModalVisible}
                        width="80%"
                    >
                        <StaticPage pageSlug={StaticPageSlug.termsOfUse} />
                    </Modal>
                </>
            )}
        </LoginLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        onAcceptTerms: acceptTerms.trigger,
    },
)(LoginTerms);
