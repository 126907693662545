import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Button } from 'antd';
import { SelectProps, LabeledValue } from 'antd/lib/select';
import dayjs, { Dayjs } from 'dayjs';
import { DashOutlined, LinkOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';

import '../../../assets/styles/Reporting.less';

import { User } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import { UserGroupsState, list as userGroupsList, getUserGroupsListState } from '../../../store/actions/userGroups';
import { getUsersListState, list as usersList, UsersState } from '../../../store/actions/users';
import { getUser } from '../../../store/actions/auth';
import { reportingExport, getReportingExportState, ReportingState } from '../../../store/actions/reporting';

import clientJson from '../../../assets/data/client-dashboard.json';
import clientDistribJson from '../../../assets/data/client-distrib-dashboard.json';
import userJson from '../../../assets/data/user-dashboard.json';
import ReportingMessages from './ReportingMessages';
import ListTitle from '../../../components/ListTitle';
import ListHeader from '../../../components/ListHeader';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';
import DashboardBuilder from '../../../components/reporting/DashboardBuilder';
import { IconCalendar, IconSearch } from '../../../components/icons';
import { GrafanaDashboard } from '../../../types';
import { getFullName } from '../../../helpers';
import { useQueryParams } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { formatBadgeParameter } from '../../../helpers/reporting';

interface ReportingProps {
    user?: User;
    userGroups: UserGroupsState['list'];
    getGroups: typeof userGroupsList.trigger;
    usersListState: UsersState['list'];
    getUsers: typeof usersList.trigger;
    exportState: ReportingState['reportingExport'];
    exportMetrics: typeof reportingExport.trigger;
}

const Reporting: FC<ReportingProps> = ({
    user, userGroups, getGroups, usersListState, getUsers, exportState, exportMetrics,
}) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const urlParams = useQueryParams();
    const dateRangeParam = urlParams.get('dateRange');
    const dateRangeParams = dateRangeParam ?
        dateRangeParam.split('-') :
        undefined;
    const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>(
        [
            dateRangeParams?.[0] ? dayjs(parseInt(dateRangeParams[0], 10)) : dayjs().subtract(7, 'day').startOf('day'),
            dateRangeParams?.[1] ? dayjs(parseInt(dateRangeParams[1], 10)) : dayjs().endOf('day'),
        ],
    );
    const userParam = urlParams.get('user');
    const userNameParam = urlParams.get('userName');
    const groupParam = urlParams.get('group');
    const groupNameParam = urlParams.get('groupName');
    const distribParam = urlParams.get('distrib');
    const distribNameParam = urlParams.get('distribName');
    const [userValue, setUserValue] = useState<LabeledValue | undefined>(
        userParam ? { key: userParam, value: userParam, label: userNameParam } : undefined,
    );
    const [groupValue, setGroupValue] = useState<LabeledValue | undefined>(
        groupParam ? { key: groupParam, value: groupParam, label: groupNameParam } : undefined,
    );
    const [distribValue, setDistribValue] = useState<LabeledValue | undefined>(
        distribParam ? { key: distribParam, value: distribParam, label: distribNameParam } : undefined,
    );
    const onChangeGroup: SelectProps<LabeledValue>['onChange'] = (value) => {
        setGroupValue(value);

        if (value) {
            urlParams.set('group', `${value.value}`);
            urlParams.set('groupName', `${value.label}`);
        } else {
            urlParams.delete('group');
            urlParams.delete('groupName');
        }

        history.push({ search: urlParams.toString() });
    };
    const onChangeDistribution: SelectProps<LabeledValue>['onChange'] = (value) => {
        setDistribValue(value);

        if (value) {
            urlParams.set('distrib', `${value.value}`);
            urlParams.set('distribName', `${value.label}`);
        } else {
            urlParams.delete('distrib');
            urlParams.delete('distribName');
        }

        history.push({ search: urlParams.toString() });
    };
    const onChangeDates = (value: any, dateStrings: any, info: any) => {
        setDates(value);

        if (value?.[0] && value?.[1]) {
            urlParams.set('dateRange', `${value[0].startOf('day').valueOf()}-${value[1].endOf('day').valueOf()}`);
            history.push({ search: urlParams.toString() });
        }
    };
    const onChangeUser: SelectProps<LabeledValue>['onChange'] = (value) => {
        setUserValue(value);

        if (value) {
            urlParams.set('user', `${value.value}`);
            urlParams.set('userName', `${value.label}`);
        } else {
            urlParams.delete('user');
            urlParams.delete('userName');
        }

        setGroupValue(undefined);
        setDistribValue(undefined);
        urlParams.delete('distrib');
        urlParams.delete('distribName');
        urlParams.delete('group');
        urlParams.delete('groupName');

        history.push({ search: urlParams.toString() });
    };
    const onSearchUser: SelectProps<User['id']>['onSearch'] = (search) => {
        getUsers({
            pageSize: 5,
            search,
            throttling: 300,
        });
    };
    const onClickExport = () => {
        exportMetrics({
            dates,
        });
    };

    useEffect(() => {
        getGroups({
            organization: user?.organization?.id,
        });
        getUsers({
            pageSize: 5,
        });
    }, [getGroups, getUsers, user]);

    useEffect(() => {
        if (!dateRangeParam && dates[0] && dates[1]) {
            urlParams.set('dateRange', `${dates[0].valueOf()}-${dates[1].valueOf()}`);
            history.push({ search: urlParams.toString() });
        }
    }, [dateRangeParam, dates, urlParams, history]);

    useEffect(() => {
        if (userParam && userNameParam) {
            setUserValue({ key: userParam, value: userParam, label: userNameParam });
        }
    }, [userParam, userNameParam]);

    const selectedUser = usersListState.data?.items.find((userItem) => userItem.id === userParam);
    return (
        <section id="reporting">
            <ListHeader>
                <div className="flex-lg-between">
                    <ListTitle level={2}>
                        <FormattedMessage {...ReportingMessages.title} />
                    </ListTitle>
                    <Button
                        onClick={onClickExport}
                        loading={exportState.loading}
                        ghost
                    >
                        <FormattedMessage {...ReportingMessages.export} />
                    </Button>
                </div>
            </ListHeader>
            {userParam && userNameParam && (
                <p>
                    <Link to={getRoute(RoutePathName.clientUser, { userId: userParam })}>
                        <LinkOutlined /> {userNameParam}
                    </Link>
                </p>
            )}
            <div id="reporting-filters">
                <IconSearch />
                <Select
                    value={userValue}
                    placeholder={formatMessage(ReportingMessages.searchPlaceholder)}
                    filterOption={false}
                    loading={usersListState.loading}
                    onSearch={onSearchUser}
                    onChange={onChangeUser}
                    menuItemSelectedIcon={null}
                    bordered={false}
                    showSearch
                    allowClear
                    labelInValue
                >
                    {usersListState.data?.items.map((usr) => (
                        <Select.Option key={usr.id} value={`${usr.id}`}>
                            {getFullName(usr.firstName, usr.lastName)}
                        </Select.Option>
                    ))}
                </Select>
                <Divider type="vertical" />
                <Select
                    bordered={false}
                    onChange={onChangeGroup}
                    value={groupValue}
                    placeholder={<FormattedMessage {...ReportingMessages.groupSelect} />}
                    disabled={!!userParam}
                    allowClear
                    labelInValue
                >
                    {userGroups.data?.items?.map((userGroup) => (
                        <Select.Option key={userGroup.id} value={userGroup.id}>
                            {userGroup.name}
                        </Select.Option>
                    ))}
                </Select>
                <Divider type="vertical" />
                <DatePicker.RangePicker
                    suffixIcon={<IconCalendar />}
                    bordered={false}
                    onCalendarChange={onChangeDates}
                    value={dates}
                    allowClear={false}
                    separator={<DashOutlined />}
                    format="DD/MM/YYYY"
                />
                <Divider type="vertical" />
                <Select
                    bordered={false}
                    onChange={onChangeDistribution}
                    value={distribValue}
                    placeholder={<FormattedMessage {...ReportingMessages.repartitionSelect} />}
                    disabled={!!userParam}
                    allowClear
                    labelInValue
                >
                    <Select.Option value="gender">{formatMessage(ReportingMessages.repartitionSelectGender)}</Select.Option>
                    <Select.Option value="jobLevel">{formatMessage(ReportingMessages.repartitionSelectStatus)}</Select.Option>
                </Select>
            </div>
            <DashboardBuilder.Panels
                json={userParam ?
                    userJson as unknown as GrafanaDashboard :
                    (
                        distribParam ?
                            clientDistribJson as unknown as GrafanaDashboard :
                            clientJson as unknown as GrafanaDashboard
                    )
                }
                params={ selectedUser &&
                    {
                        $user: selectedUser.id,
                        $badgeType: formatBadgeParameter(selectedUser?.selectedProgram?.program?.badges),
                    }
                }
            />
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    usersListState: getUsersListState(state),
    userGroups: getUserGroupsListState(state),
    exportState: getReportingExportState(state),
});

export default connect(
    mapStateToProps,
    {
        getUsers: usersList.trigger,
        getGroups: userGroupsList.trigger,
        exportMetrics: reportingExport.trigger,
    },
)(Reporting);
