import React, { FC, ReactFragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Dropdown, Avatar, Menu, Space } from 'antd';
import { UserOutlined, CaretDownOutlined } from '@ant-design/icons';

import '../assets/styles/Header.less';

import { getUser } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { User, RoleSlug } from '../store/api/apiTypes';
import { ui } from '../store/actions/ui';

import genericMessages from '../locale/genericMessages';
import HomeMessages from '../pages/home/HomeMessages';
import { hasRole } from '../helpers/security';
import { getRoute, RoutePathName } from '../routes';
import NotificationsMenu from './NotificationsMenu';
import MainMenuButton from './MainMenuButton';
import Img from './Img';
import Logo from './Logo';

interface HeaderProps {
    user?: User;
    menu?: ReactFragment;
    setUi: typeof ui.actions.set;
}

const Header: FC<HeaderProps> = ({ user, menu, setUi }) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const isClient = location.pathname.startsWith(getRoute(RoutePathName.client));
    // const onClickTutorial = () => {
    //     setUi({ shouldShowTutorialModal: true });
    // };

    const banner = user?.organization?.theme.bannerUrl ? (
        <Img src={user?.organization?.theme.bannerUrl} alt="banner" />
    ) : (
        <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="1440" height="96" viewBox="0 0 1440 96" preserveAspectRatio="none">
            <defs>
                <path id="banner-a" d="M1440 0v96H0V0z" />
                <linearGradient id="banner-b" x1="37.052%" x2="98.061%" y1="49.579%" y2="50.035%">
                    <stop offset="0%" stopColor="#A493F7" />
                    <stop offset="100%" stopColor="#6B5AED" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="banner-c" fill="#fff">
                    <use xlinkHref="#banner-a" />
                </mask>
                <use fill="#EFF2FF" fillRule="nonzero" xlinkHref="#banner-a" />
                <path fill="url(#banner-b)" mask="url(#banner-c)" d="M0 0h863v96H0z" />
                <circle cx="-54" cy="-70" r="195" fill="#6B5AED" mask="url(#banner-c)" />
                <circle cx="1155.5" cy="722.5" r="779.5" fill="#16215C" mask="url(#banner-c)" />
            </g>
        </svg>
    );

    const userMenu = (
        <Menu>
            {hasRole(user, [RoleSlug.superAdmin, RoleSlug.admin, RoleSlug.owner, RoleSlug.headHR, RoleSlug.manager]) && (
                <>
                    <Menu.Item className="ant-dropdown-menu-item">
                        {isClient ? (
                            <Link to={getRoute(RoutePathName.home)}>
                                <FormattedMessage {...genericMessages.switchToUser} />
                            </Link>
                        ) : (
                            <Link to={getRoute(RoutePathName.clientDashboard)}>
                                <FormattedMessage {...genericMessages.switchToClient} />
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Divider />
                </>
            )}

            <Menu.Item>
                <Link to={getRoute(isClient ? RoutePathName.clientAccount : RoutePathName.account)}>
                    <FormattedMessage {...genericMessages.accountMenu} />
                </Link>
            </Menu.Item>

            <Menu.Item>
                <Link to={getRoute(RoutePathName.documents)}>
                    <FormattedMessage {...genericMessages.documentsMenu} />
                </Link>
            </Menu.Item>

            {/* {!isClient && (
                <Menu.Item onClick={onClickTutorial}>
                    <FormattedMessage {...genericMessages.tutorialMenu} />
                </Menu.Item>
            )} */}

            <Menu.Divider />

            <Menu.Item className="logout-menu-item">
                <a href="/api/auth/logout">
                    <FormattedMessage {...genericMessages.logout} />
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header id="main-header">
            <div className="container">
                <div className="flex">
                    <Breakpoint lg down>
                        <MainMenuButton />
                    </Breakpoint>
                    <Link
                        id="logo"
                        to={getRoute(isClient ? RoutePathName.clientDashboard : RoutePathName.home)}
                        title={formatMessage(HomeMessages.title)}
                    >
                        <Logo />
                    </Link>
                </div>
                {menu}
                <Space size={24}>
                    {!isClient && (
                        <NotificationsMenu />
                    )}
                    <Dropdown overlayClassName="user-card-dropdown" trigger={['click']} overlay={userMenu}>
                        <Link to="" className="user-card">
                            <Avatar src={user?.avatarUrl}>
                                {!user?.avatarUrl && <UserOutlined />}
                            </Avatar>
                            <Breakpoint xl up tagName="span">
                                {user?.firstName}
                                <CaretDownOutlined />
                            </Breakpoint>
                        </Link>
                    </Dropdown>
                </Space>
            </div>
            <div id="header-banner">
                {banner}
            </div>
        </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        setUi: ui.actions.set,
    },
)(Header);
