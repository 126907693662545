import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M22.457.024A1.485 1.485 0 0020.73 1.22a52.242 52.242 0 00-.798 11.62 15.213 15.213 0 00-8.654 2.863A36.559 36.559 0 00.475 26.083l-.184.273a1.74 1.74 0 102.9 1.928 31.8 31.8 0 016.336-7.006.362.362 0 01.499.52 35.272 35.272 0 00-5.204 7.68 1.742 1.742 0 003.114 1.559 31.798 31.798 0 017.453-9.666 7.603 7.603 0 015.03-1.577 52.225 52.225 0 002.36 11.177 1.485 1.485 0 102.82-.929 49.335 49.335 0 01-2.247-10.758l-.04-.439-.392-5.705-.02-.423a49.334 49.334 0 01.675-10.53l.077-.434A1.485 1.485 0 0022.457.024zm6.027 12.09a3.518 3.518 0 000 7.037A3.517 3.517 0 0032 15.63a3.517 3.517 0 00-3.516-3.518z"/>
    </svg>
);

const IconPhysical: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-physical${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconPhysical;
