import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="nonzero" d="M14.991 6.206C14.724 2.73 11.661 0 8 0 4.34 0 1.276 2.73 1.009 6.206a6.502 6.502 0 001.48 4.468c.02 0 .02 0 .02.019l4.216 4.754c.596.65 1.645.745 2.344.191a.943.943 0 00.206-.19l4.215-4.755c0-.02 0-.02.02-.02a6.502 6.502 0 001.481-4.467zM8.021 9.05H8C6.355 9.07 5.018 7.829 4.998 6.3c-.02-1.527 1.316-2.769 2.96-2.788 1.646-.019 2.983 1.222 3.003 2.75v.019c.02 1.527-1.295 2.75-2.94 2.769z" />
    </svg>
);

const IconPlace: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-place${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconPlace;
