import React, { FC } from 'react';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import IconArrowRightCircle from './IconArrowRightCircle';

const IconArrowLeftCircle: FC<IconComponentProps> = (props) => (
    <IconArrowRightCircle
        {...props}
        className={`icon-arrow-left-circle${props.className ? ` ${props.className}` : ''}`}
        style={{
            transform: 'rotate(180deg)',
        }}
    />
);

export default IconArrowLeftCircle;
