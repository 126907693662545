import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Form, Input, DatePicker, Select, InputNumber, Radio } from 'antd';
import { FormProps } from 'antd/lib/form';

import { Organization, ProgramClosure, User, ProgramClosureType, programClosureTypeMessages } from '../../../store/api/apiTypes';

import formMessages from '../../../locale/formMessages';
import genericMessages from '../../../locale/genericMessages';
import ProgramClosureMessages from './ProgramClosureMessages';
import { IconCalendar } from '../../../components/icons';
import TextArea from 'antd/lib/input/TextArea';
import CreateUserMessages from './CreateUserMessages';
import EnumMessage from '../../../components/EnumMessage';

interface ProgramClosureFormProps {
    onSubmit: (values: ProgramClosure) => void;
    organizationId?: Organization['id'];
    initialValues?: User;
}

const ProgramClosureForm: FC<ProgramClosureFormProps> = ({ onSubmit, initialValues, organizationId }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [formValues, setFormValues] = useState<ProgramClosure>();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    useEffect(() => {
        if (initialValues) {
            // form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    // ---------------------------------------
    // Form changes and submit

    const onFormChange = () => {
        setFormValues(form.getFieldsValue());
    };
    const onFormValidSubmit: FormProps<ProgramClosure>['onFinish'] = (values) => {
        onSubmit(values);
    };

    // ---------------------------------------
    // Render

    return (
        <Form<ProgramClosure>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFormValidSubmit}
        >
            <Card
                className="form-card"
            >
                <Form.Item
                    label={formatMessage(ProgramClosureMessages.typeLabel)}
                    name="type"
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage(ProgramClosureMessages.typePlaceholder)}
                        onChange={onFormChange}
                        showArrow
                    >
                        {Object.values(ProgramClosureType).map((type) => (
                            <Select.Option value={type} key={type}>
                                <EnumMessage map={programClosureTypeMessages} value={type} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {(formValues?.type === ProgramClosureType.internal) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.entryOnDutyDateLabel)}
                            name={['job', 'entryOnDutyDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.serviceLabel)}
                            name={['job', 'group']}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ProgramClosureMessages.servicePlaceholder)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.jobLabel)}
                            name={['job', 'jobTitle']}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ProgramClosureMessages.jobPlaceholder)}
                            />
                        </Form.Item>
                    </>
                )}

                {(formValues?.type === ProgramClosureType.external) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.leavingDateLabel)}
                            name={['leavingDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.entryOnDutyDateLabel)}
                            name={['job', 'entryOnDutyDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.companyLabel)}
                            name={['job', 'group']}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ProgramClosureMessages.companyPlaceholder)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.jobLabel)}
                            name={['job', 'jobTitle']}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ProgramClosureMessages.jobPlaceholder)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(CreateUserMessages.annualSalaryLabel)}
                            name={['job', 'annualSalaryWithoutTax']}
                            rules={[requiredRule]}
                        >
                            <InputNumber
                                placeholder={formatMessage(CreateUserMessages.annualSalaryPlaceholder)}
                                min={0}
                            />
                        </Form.Item>
                    </>
                )}

                {(formValues?.type === ProgramClosureType.businessCreation) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.leavingDateLabel)}
                            name={['leavingDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.sectorLabel)}
                            name={['job', 'jobTitle']}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ProgramClosureMessages.sectorPlaceholder)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.partnerLabel)}
                            name={['partnership']}
                            rules={[requiredRule]}
                        >
                            <Radio.Group>
                                <Radio value={true}><FormattedMessage {...genericMessages.yes} /></Radio>
                                <Radio value={false}><FormattedMessage {...genericMessages.no} /></Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                )}

                {(formValues?.type === ProgramClosureType.training) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.leavingDateLabel)}
                            name={['leavingDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.trainingFromDateLabel)}
                            name={['training', 'dates', 'fromDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.trainingFromDateLabel)}
                            name={['training', 'dates', 'toDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>
                    </>
                )}

                {(formValues?.type === ProgramClosureType.retired) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.retireDateLabel)}
                            name={['leavingDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>
                    </>
                )}

                {(formValues?.type === ProgramClosureType.other) && (
                    <>
                        <Form.Item
                            label={formatMessage(ProgramClosureMessages.leavingDateLabel)}
                            name={['leavingDate']}
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(ProgramClosureMessages.datePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>
                    </>
                )}

                {(formValues?.type) && (
                    <Form.Item
                        label={formatMessage(ProgramClosureMessages.noteLabel)}
                        name={['comment']}
                    >
                        <TextArea
                            placeholder={formatMessage(ProgramClosureMessages.notePlaceholder)}
                            rows={3}
                        />
                    </Form.Item>
                )}
            </Card>
            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={
                            !form.isFieldsTouched() ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                        <FormattedMessage {...ProgramClosureMessages.save} />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default ProgramClosureForm;
