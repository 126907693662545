import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Typography } from 'antd';

import { AuthState, getAuthState, forgottenPassword } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import GenericMessages from '../../locale/genericMessages';
import formMessages from '../../locale/formMessages';
import { getRoute, RoutePathName } from '../../routes';
import ForgottenPasswordMessages from './ForgottenPasswordMessages';
import LoginMessages from '../login/LoginMessages';
import LoginLayout from '../../components/LoginLayout';

interface ForgottenPasswordProps extends RouteComponentProps {
    resetForgottenPassword: typeof forgottenPassword.reset;
    sendForgottenPassword: typeof forgottenPassword.trigger;
    authState: AuthState;
}

const ForgottenPassword: FC<ForgottenPasswordProps> = ({ authState, resetForgottenPassword, sendForgottenPassword }) => {
    const [email, setEmail] = useState<string | undefined>();
    const { formatMessage } = useIntl();
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    let error = authState.forgottenPasswordError ? <FormattedMessage {...GenericMessages.error} /> : null;

    if (
        authState.forgottenPasswordError &&
        authState.forgottenPasswordError.status &&
        authState.forgottenPasswordError.status === 404
    ) {
        error = <FormattedMessage {...LoginMessages.emailNotFound} />;
    }

    useEffect(() => () => {
        setEmail(undefined);
        resetForgottenPassword();
    }, [setEmail, resetForgottenPassword]);

    return (
        <LoginLayout>
            <Seo title={formatMessage(ForgottenPasswordMessages.title)} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="logo" />
                <Typography.Title level={1}>
                    <FormattedMessage {...ForgottenPasswordMessages.title} />
                </Typography.Title>

                {authState.forgottenPasswordSuccess ? (
                    <Typography.Paragraph>
                        <FormattedMessage
                            {...ForgottenPasswordMessages.success}
                            values={{
                                email,
                                br: <><br /><br /></>,
                            }}
                            tagName="p"
                        />
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <FormattedMessage {...ForgottenPasswordMessages.description} tagName="p" />
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            label={formatMessage(LoginMessages.emailLabel)}
                            rules={[requiredRule, {
                                type: 'email',
                                message: formatMessage(formMessages.invalidEmail),
                            }]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder={formatMessage(LoginMessages.emailPlaceholder)}
                            />
                        </Form.Item>
                        <Form.Item>
                            {error ? (
                                <div className="login-error-message">
                                    <Alert
                                        type="error"
                                        message={error}
                                        showIcon
                                    />
                                </div>
                            ) : null}

                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={authState.loading}
                                block
                            >
                                <FormattedMessage {...ForgottenPasswordMessages.button} />
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>
                    <FormattedMessage {...ForgottenPasswordMessages.loginLink} />
                </Link>
            </Form>
        </LoginLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        sendForgottenPassword: forgottenPassword.trigger,
        resetForgottenPassword: forgottenPassword.reset,
    },
)(ForgottenPassword);
