import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useParams, useHistory, Link, useLocation } from 'react-router-dom';
import { Card, Spin, Typography, Divider, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player/lazy';

import '../../assets/styles/LibraryDocument.less';

import { MainReducerState } from '../../store/reducers';
import { details, LibraryState } from '../../store/actions/library';
import { LibraryDocument } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import HeaderCTA from '../../components/HeaderCTA';
import { IconPlus, IconArrowLeft } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import PDFViewer from '../../components/PDFViewer';
import LibraryMessages from './LibraryMessages';

enum FileType {
    pdf = 'pdf',
    image = 'image',
    video = 'video',
    audio = 'audio',
    link = 'link',
}

interface MatchParams {
    documentId: LibraryDocument['id'];
}

interface LibraryDocumentDetailsProps extends RouteComponentProps<MatchParams> {
    libraryDetailsState: LibraryState['details'];
    documentDetails: typeof details.trigger;
}

const LibraryDocumentDetails: FC<LibraryDocumentDetailsProps> = ({
    libraryDetailsState, documentDetails,
}) => {
    const location = useLocation<{ previous?: string, download?: boolean } | undefined>();
    const { documentId } = useParams<MatchParams>();
    const history = useHistory();

    useEffect(() => {
        documentDetails({id: documentId});
    }, [documentDetails, documentId]);

    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.networkingActions),
            },
        });
    };
    const getFileType = (ext?: string) => {
        switch (ext) {
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
                return FileType.image;
            case 'mp4':
            case 'mov':
                return FileType.video;
            case 'mp3':
                return FileType.audio;
            case 'pdf':
                return FileType.pdf;
            default: return FileType.link;
        }
    };

    const fileUrl = libraryDetailsState.data?.url;
    const fileExt = fileUrl?.split('.').pop()?.toLowerCase();
    const fileType = getFileType(fileExt);

    return (
        <>
            <Seo title={libraryDetailsState.data?.name} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            {!location.state?.previous && (
                <Link
                    to={getRoute(RoutePathName.library)}
                    className="back-link"
                    type="link"
                >
                    <IconArrowLeft />
                    <FormattedMessage {...LibraryMessages.backToList} />
                </Link>
            )}

            <Spin spinning={libraryDetailsState.loading}>
                <Card className="document-details">
                    <Typography.Title level={1}>
                        {libraryDetailsState.data?.name}
                    </Typography.Title>

                    <Divider />

                    <div className="media">
                        {location.state?.previous && (
                            <Link
                                to={location.state.previous}
                                className="back-link"
                                style={{ display: 'inline-block', marginBottom: '1.5rem' }}
                                type="link"
                            >
                                <IconArrowLeft />
                                <FormattedMessage {...genericMessages.backToTask} />
                            </Link>
                        )}
                        {fileUrl && (
                            <>
                                {(fileType === FileType.image) && (
                                    <img src={fileUrl} alt={libraryDetailsState.data?.name} className="document-image"/>
                                )}

                                {(fileType === FileType.video) && (
                                    <div className="player-wrapper">
                                        <ReactPlayer url={fileUrl} controls className="react-player" width="100%" height="100%" />
                                    </div>
                                )}

                                {(fileType === FileType.audio) && (
                                    <ReactPlayer url={fileUrl} controls className="react-audio-player" width="100%" height="50px" />
                                )}

                                {(fileType === FileType.pdf) && (
                                    <PDFViewer file={fileUrl} allowPrint={location.state?.download} />
                                )}

                                {(fileType === FileType.link) && (
                                    // https://gitlab.ezeeworld.com/ezee/MonCoachMobilite/mcm-front/-/issues/95#note_45859
                                    // <Button href={fileUrl} target="_blank" download type="primary">
                                    //     <DownloadOutlined />
                                    //     &nbsp;
                                    //     <FormattedMessage {...LibraryMessages.downloadFile} />
                                    // </Button>
                                    <Alert type="error" message={<FormattedMessage {...LibraryMessages.typeError} />} />
                                )}
                            </>
                        )}
                    </div>

                    <Typography.Paragraph className="description">
                        {libraryDetailsState.data?.description}
                    </Typography.Paragraph>
                    {location.state?.previous && (
                        <Link
                            to={location.state.previous}
                            className="back-link"
                            type="link"
                        >
                            <IconArrowLeft />
                            <FormattedMessage {...genericMessages.backToTask} />
                        </Link>
                    )}
                </Card>
            </Spin>
            {!location.state?.previous && (
                <Link
                    to={getRoute(RoutePathName.library)}
                    className="back-link"
                    type="link"
                >
                    <IconArrowLeft />
                    <FormattedMessage {...LibraryMessages.backToList} />
                </Link>
            )}
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    libraryDetailsState: state.library.details,
});

export default connect(
    mapStateToProps,
    {
        documentDetails: details.trigger,
    },
)(LibraryDocumentDetails);
