import React, { FC } from 'react';
import { Tooltip } from 'antd';

import { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';
import { getParsedDescription, getPanelName } from '../../helpers/reporting';
import { Panel } from '../../types';
import { IconInformation } from '../icons';
import EnumMessage from '../EnumMessage';
import ReportingUsersList from './ReportingUsersList';

interface GridPanelProps {
    panel: Panel;
    isFullWidth?: boolean;
}

const GridPanel: FC<GridPanelProps> = ({ children, panel, isFullWidth }) => {
    const parsedDescription = getParsedDescription(panel.description);
    const panelName = `${getPanelName(panel.description)}_description`;

    return (
        <div
            className="dashboard-grid-panel"
            style={{
                gridColumn: isFullWidth ? '1 / span 24' : `${panel.gridPos.x + 1} / span ${panel.gridPos.w}`,
            }}
        >
            {metricsMessages.get(panelName) && (
                <div className="dashboard-grid-panel-tooltip">
                    <Tooltip overlay={<EnumMessage map={metricsMessages} value={panelName} />}>
                        <IconInformation />
                    </Tooltip>
                </div>
            )}
            {children}
            {typeof parsedDescription === 'object' && parsedDescription.userDetails && (
                <footer>
                    <ReportingUsersList parsedDescription={parsedDescription} />
                </footer>
            )}
        </div>
    );
};

export default GridPanel;
