import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 26 24">
        <path d="M25.033 7.472A.985.985 0 0126 8.474v9.988a.985.985 0 01-.967 1.002h-2.516l-.468 4.28a.288.288 0 01-.48.18l-4.851-4.46h-6.426a.985.985 0 01-.967-1.002v-.273l2.532-2.327h7.507a2.29 2.29 0 002.26-2.315V7.472zM19.363 0c.668.009 1.201.562 1.193 1.235v12.312a1.214 1.214 0 01-1.192 1.235h-7.921L5.366 20.37a.288.288 0 01-.482-.18l-.59-5.408H1.191A1.214 1.214 0 010 13.547V1.235A1.214 1.214 0 011.192 0zm-6.682 10.235H2.893v1.167h9.788v-1.167zm4.981-3.427H2.893v1.167h14.77V6.808zm0-3.428H2.893v1.167h14.77V3.38z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconInterview: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-interview${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconInterview;
