import { Layout } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { FC, useContext } from 'react';
import { Breakpoint } from 'react-socks';
import { useIntl } from 'react-intl';

import '../assets/styles/Login.less';

import { LocaleContext } from '../context/LocaleContext';
import { Language, LanguageName } from '../types';
import { classNames } from '../helpers';
import Select from './Select';

const LoginLayout: FC = ({ children }) => {
    const { setLocale } = useContext(LocaleContext);
    const { locale } = useIntl();
    const onChangeLocale = (value: SelectValue) => {
        setLocale(value as Language);
    };

    return (
        <Layout id="login-layout">
            <div id="login-section">
                <div id="login-section-inner">
                    {children}
                    <Select
                        className="login-lang-select"
                        defaultValue={locale}
                        size="small"
                        dropdownClassName="login-lang-select-dropdown"
                        onChange={onChangeLocale}
                    >
                        {Object.values(Language).map((lang) => (
                            <Select.Option value={lang} key={lang}>
                                <span className={classNames('flag', lang)} /> {LanguageName.get(lang)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <Breakpoint md up className="login-background" />
        </Layout>
    );
};

export default LoginLayout;
