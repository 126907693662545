import React, { FC, ReactChild } from 'react';
import { connect } from 'react-redux';

import { Permission, User, PermissionRight, RoleSlug } from '../store/api/apiTypes';
import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/actions/auth';

import { debug } from '../helpers';

interface CanProps {
    children: ReactChild | null;
    read?: Permission;
    user?: User;
    write?: Permission;
    roles?: RoleSlug[];
}

const Can: FC<CanProps> = ({ children, read, user, write, roles }) => {
    let isAllowed = false;

    if (!read && !write && !roles) {
        debug.error('[Can] You must provide a `read`, `write` or `role` prop');
        return null;
    }

    if (user) {
        if (roles && user.role?.slug) {
            isAllowed = roles.indexOf(user.role.slug) !== -1;
        } else {
            if (write) {
                isAllowed = user.mergedPermissions?.[write] === PermissionRight.write;
            } else if (read) {
                isAllowed = user.mergedPermissions?.[read] === PermissionRight.read ||
                            user.mergedPermissions?.[read] === PermissionRight.write;
            } else {
                debug.warn(`[Can] Cannot read or write ${read || write}`);
            }
        }
    } else {
        debug.warn('[Can] No user');
    }

    if (isAllowed) {
        return <>{children}</>;
    } else {
        return null;
    }
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Can);
