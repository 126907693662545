import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';

import { NetworkingActionType, networkingActionTypeMessages, networkingActionTypeHelpMessages } from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import Select from '../../components/Select';
import NetworkingActionCreateMessages from './NetworkingActionCreateMessages';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { NetworkingActionCreateStep, NetworkingActionCreateValues } from './NetworkingActionCreate';

interface NetworkingActionCreateWhatProps {
    initialValues?: NetworkingActionCreateValues;
    onSubmit: (values: NetworkingActionCreateValues) => void;
}

const NetworkingActionCreateWhat: FC<NetworkingActionCreateWhatProps> = ({ initialValues, onSubmit }) => {
    const location = useLocation();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const history = useHistory();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps<NetworkingActionCreateValues>['onFinish'] = (values) => {
        onSubmit({
            ...values,
            notes: values.noteText ? [{
                text: values.noteText,
                date: new Date().toISOString(),
            }] : undefined,
        });
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.who }),
            search: location.search,
        });
    };
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <Form<NetworkingActionCreateValues>
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={initialValues}
            onFinish={onFormValidSubmit}
        >
            <Card
                title={formatMessage(NetworkingActionCreateMessages.whatTitle)}
                className="form-card"
            >
                <Form.Item label={formatMessage(NetworkingActionCreateMessages.whatTypeLabel)}>
                    <Form.Item
                        name="actionType"
                        rules={[requiredRule]}
                        noStyle
                    >
                        <Select
                            placeholder={formatMessage(NetworkingActionCreateMessages.whatTypePlaceholder)}
                            disabled={!!initialValues?.parentTitle}
                        >
                            {Object.values(NetworkingActionType).map((type) => (
                                <Select.Option value={type} key={type}>
                                    <FormattedMessage {...networkingActionTypeMessages.get(type)} />
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item dependencies={['actionType']} noStyle>
                        {({getFieldValue}) => (
                            <div className="ant-form-item-extra" style={{ marginTop: 12 }}>
                                {getFieldValue(['actionType']) && (
                                    <FormattedMessage
                                        // tslint:disable-next-line: max-line-length
                                        {...networkingActionTypeHelpMessages.get(getFieldValue(['actionType']))}
                                    />
                                )}
                            </div>
                        )}
                    </Form.Item>
                </Form.Item>
                {initialValues?.parentTitle && (
                    <Form.Item
                        label={formatMessage(NetworkingActionCreateMessages.whatParentLabel)}
                        name="parentTitle"
                    >
                        <Input readOnly />
                    </Form.Item>
                )}
                <Form.Item
                    label={formatMessage(NetworkingActionCreateMessages.whatNameLabel)}
                    name="title"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(NetworkingActionCreateMessages.whatNamePlaceholder)}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(NetworkingActionCreateMessages.whatNoteLabel)}
                    name="noteText"
                >
                    <Input.TextArea
                        placeholder={formatMessage(NetworkingActionCreateMessages.whatNotePlaceholder)}
                        rows={4}
                    />
                </Form.Item>
            </Card>
            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={
                            !Object.values(form.getFieldsValue()).every((v) => Boolean(v)) ?
                            false : (
                                !form.isFieldsTouched() ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            )
                        }
                    >
                        <FormattedMessage {...genericMessages.continue} />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default NetworkingActionCreateWhat;
