import React, { FC, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography } from 'antd';

import { MainReducerState } from '../../store/reducers';
import { getStaticPageStateBySlug, details, StaticPageState } from '../../store/actions/staticPages';
import { StaticPageSlug } from '../../store/api/apiTypes';

import StaticPage from '../../components/StaticPage';
import PublicLayout from '../../components/PublicLayout';

interface PageProps extends Partial<RouteComponentProps> {
    pageState: StaticPageState;
    pageSlug?: StaticPageSlug;
    fetchPage: typeof details.trigger;
}

const Page: FC<PageProps> = ({ pageSlug, pageState, fetchPage }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (!pageState.data) {
            fetchPage(pageSlug || pathname.substr(1) as StaticPageSlug);
        }
    }, [pathname, fetchPage, pageState.data, pageSlug]);

    return (
        <PublicLayout>
            <Typography.Title level={1}>{pageState.data?.title}</Typography.Title>
            <StaticPage
                pageState={pageState}
                small
            />
        </PublicLayout>
    );
};

interface OwnProps {
    pageSlug: PageProps['pageSlug'];
    location?: RouteComponentProps['location'];
}

const mapStateToProps = (state: MainReducerState, { pageSlug, location }: OwnProps) => ({
    pageState: getStaticPageStateBySlug(state, pageSlug || location?.pathname?.substr(1) as StaticPageSlug),
});

export default connect(
    mapStateToProps,
    {
        fetchPage: details.trigger,
    },
)(Page);
