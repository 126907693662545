import React, { FC, useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { User } from '../../../store/api/apiTypes';
import * as usersActions from '../../../store/actions/users';
import { MainReducerState } from '../../../store/reducers';
import Seo from '../../../components/Seo';
import FullscreenLayout from '../../../components/FullscreenLayout';
import { IconContacts } from '../../../components/icons';
import genericMessages from '../../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { usePrevious } from '../../../hooks';
import CreateUserMessages from './CreateUserMessages';
import { classNames } from '../../../helpers';
import CreateUserIdentityForm from './CreateUserIdentityForm';
import { getUser } from '../../../store/actions/auth';
import CreateUserJobForm from './CreateUserJobForm';

export enum CreateUserStep {
    identity = 'identity',
    job = 'job',
}

interface CreateUserPageProps {
    user?: User;
    createAction: typeof usersActions.create.trigger;
    createActionState: usersActions.UsersState['create'];
}

const CreateUserPage: FC<CreateUserPageProps> = ({ user, createAction, createActionState }) => {
    const { formatMessage } = useIntl();
    const location = useLocation<{ previous?: string } | undefined>();
    const previous = usePrevious({ createActionState });
    const history = useHistory();
    const { step } = useParams<{ step: CreateUserStep }>();
    const [values, setValues] = useState<User>();

    // ---------------------------------------
    // On exit, back to users list

    const onQuit = useCallback(() => {
        history.replace(location.state?.previous || getRoute(RoutePathName.clientUsers));
    }, [history, location.state]);

    // ---------------------------------------
    // Back to previous form step

    const onPrevious = () => {
        const steps = Object.values(CreateUserStep);
        const previousStep = steps[steps.indexOf(step) - 1];
        history.replace(getRoute(RoutePathName.clientUserCreate, { step: previousStep }));
    };

    // ---------------------------------------
    // On form change, update values or create

    const onChangeValues = (val: User) => {
        setValues((prevValues) => ({
            ...prevValues,
            ...val,
        }));
    };
    const onSubmit = (val: User) => {
        onChangeValues(val);
        createAction({
            ...values,
            ...val,
            role: val.role?.slug,
            organization: user?.organization?.id,
        });
    };

    useEffect(() => {
        if (previous?.createActionState.loading && !createActionState.loading) {
            if (createActionState.error) {
                if (createActionState.error?.data?.fields?.email) {
                    message.error(formatMessage(genericMessages.emailDuplicateError));
                } else {
                    message.error(formatMessage(genericMessages.defaultError));
                }
            } else {
                if (createActionState.data?.id) {
                    history.push(getRoute(RoutePathName.clientUser, {userId: createActionState.data?.id}), {
                        showProgramModal: true,
                    });
                } else {
                    onQuit();
                }
            }
        }
    }, [
        previous, createActionState.loading, createActionState.error, createActionState.data,
        formatMessage, onQuit, history,
    ]);

    // ---------------------------------------
    // Render

    let content = null;

    switch (step) {
        case CreateUserStep.identity:
            content = (
                <CreateUserIdentityForm
                    onSubmit={onChangeValues}
                    initialValues={values}
                />
            );
            break;

        case CreateUserStep.job:
            if (!values?.firstName) {
                return <Redirect to={getRoute(RoutePathName.clientUserCreate, { step: CreateUserStep.identity })} />;
            }
            if (user?.organization) {
                content = (
                    <CreateUserJobForm
                        organization={user.organization}
                        initialValues={values}
                        onSubmit={onSubmit}
                    />
                );
            }
            break;

        case undefined:
            return <Redirect to={getRoute(RoutePathName.clientUserCreate, { step: CreateUserStep.identity })} />;

        default:
            return <Redirect to={getRoute(RoutePathName.notFound)} />;
    }

    return (
        <section id="create-action">
            <Seo title={formatMessage(CreateUserMessages.title)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                onPrevious={onPrevious}
                title={<FormattedMessage {...CreateUserMessages.title} />}
                icon={<IconContacts />}
                isFirstStep={step === CreateUserStep.identity}
            >
                <div id="create-action-steps">
                    <div id="create-action-steps-track-wrapper">
                        <div id="create-action-steps-track" />
                        <div
                            id="create-action-steps-progress"
                            style={{
                                width: step === CreateUserStep.identity ? '10%' : '100%',
                            }}
                        />
                    </div>
                    <div id="create-action-step-values">
                        <p className={classNames('step', step === CreateUserStep.identity && 'is-active')}>
                            <FormattedMessage {...CreateUserMessages.identity} />
                        </p>
                        <p className={classNames('step', step === CreateUserStep.job && 'is-active')}>
                            <FormattedMessage {...CreateUserMessages.actualJob} />
                        </p>
                    </div>
                </div>
            </FullscreenLayout.Header>
            <FullscreenLayout.Content>
                <Spin spinning={createActionState.loading}>
                    {content}
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    createActionState: state.users.create,
});

export default connect(
    mapStateToProps,
    {
        createAction: usersActions.create.trigger,
    },
)(CreateUserPage);
