import React, { FC } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Descriptions, Divider } from 'antd';

import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/actions/auth';
import { User, genderMessages, jobStatusMessages, RoleSlug, SelectedProgram } from '../../store/api/apiTypes';

import AccountMessages from './AccountMessages';
import { getRoute, RoutePathName } from '../../routes';
import { Link } from 'react-router-dom';
import genericMessages from '../../locale/genericMessages';
import { IconArrowRight } from '../../components/icons';
import { formatAddress, formatTextData } from '../../helpers';
import { hasRole } from '../../helpers/security';
import { getMyProgramState } from '../../store/actions/programs';
import EnumMessage from '../../components/EnumMessage';

interface AccountInformationsProps {
    user?: User;
    selectedProgram?: SelectedProgram;
}

const AccountInformations: FC<AccountInformationsProps> = ({ selectedProgram, user }) => {
    return (
        <Descriptions column={1} bordered>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoLastName} />}>
                {formatTextData(user?.lastName)}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoFirstName} />}>
                {formatTextData(user?.firstName)}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoGender} />}>
                <EnumMessage map={genderMessages} value={user?.gender} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoBirthday} />}>
                <FormattedDate value={user?.birthday} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCompany} />}>
                <FormattedMessage {...AccountMessages.infoCompanyGroup} tagName="label" /><br />
                {formatTextData(user?.company?.group)}
                <Divider />
                <FormattedMessage {...AccountMessages.infoCompanyFirm} tagName="label" /><br />
                {formatTextData(user?.company?.name)}
            </Descriptions.Item>

            {hasRole(user, [RoleSlug.user]) && (
                <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCurrentPost} />}>
                    <FormattedMessage {...AccountMessages.infoCurrentPostStatute} tagName="label" /><br />
                    <EnumMessage map={jobStatusMessages} value={user?.job?.level} />
                    <Divider />
                    <FormattedMessage {...AccountMessages.infoCurrentPostJob} tagName="label" /><br />
                    {formatTextData(user?.job?.jobTitle)}
                    <Divider />
                    <FormattedMessage {...AccountMessages.infoCurrentPost} tagName="label" /><br />
                    {formatTextData(user?.job?.position)}
                    <Divider />
                    <FormattedMessage {...AccountMessages.infoCurrentPostManager} tagName="label" /><br />
                    {formatTextData(user?.job?.manager)}
                    <Divider />
                    <FormattedMessage {...AccountMessages.infoCurrentPostHrRep} tagName="label" /><br />
                    {formatTextData(user?.job?.hrRepresentative)}
                </Descriptions.Item>
            )}

            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoAddress} />}>
                {formatAddress(user?.address)}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoEmail} />}>
                {formatTextData(user?.email)}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoProgram} />}>
                {formatTextData(selectedProgram?.program?.name)}
                {selectedProgram && (
                    <>
                        <br />
                        <Link to={getRoute(RoutePathName.program)}>
                            <FormattedMessage {...genericMessages.detailsLink} /> <IconArrowRight />
                        </Link>
                    </>
                )}
            </Descriptions.Item>
        </Descriptions>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    selectedProgram: getMyProgramState(state).data,
});

export default connect(
    mapStateToProps,
)(AccountInformations);
