import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
        <path fill="currentColor" d="M8.733 10.586h7.145c.325 0 .59.262.59.586a.587.587 0 0 1-.59.586H8.733a.587.587 0 0 1-.59-.586c0-.324.264-.586.59-.586zm0-2.344h7.145c.325 0 .59.263.59.586a.587.587 0 0 1-.59.586H8.733a.587.587 0 0 1-.59-.586c0-.323.264-.586.59-.586zm0-2.344h7.145c.325 0 .59.263.59.586a.587.587 0 0 1-.59.586H8.733a.587.587 0 0 1-.59-.586c0-.323.264-.586.59-.586zM4.61 17.656h11.857v.586c0 .97.792 1.758 1.766 1.758S20 19.21 20 18.242V0H4.61v17.656zM1.666 20H15.88a2.912 2.912 0 0 1-.532-1.172H0A1.77 1.77 0 0 0 1.666 20z" />
    </svg>
);

const IconTerms: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-terms${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconTerms;
