// import { Civility, User } from './apiTypes';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

import api, {clientWithFullResponse as apiWithFullResponse} from './_client';
import { User } from './apiTypes';
import { UpdateNotificationPayload } from '../../helpers/native';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/auth', payload);

export interface ForgottenPasswordPayload {
    username: User['email'];
}

export const forgottenPassword = (payload: ForgottenPasswordPayload) => api.post('/auth/reset', payload);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}

export const resetPassword = (payload: ResetPasswordPayload) => api.post('/signedAction', payload);

export interface ResendInvitationPayload {
    token: string;
}

export const resendInvitation = (payload: ResendInvitationPayload) => api.post('/auth/reset', payload);

export interface UpdateMePayload extends Pick<User, 'spokenLanguages' | 'degrees' | 'phone' | 'maritalStatus'> {
    avatarFile: RcFile;
    device?: UpdateNotificationPayload;
}

export const update = (payload: UpdateMePayload) => {
    const formData = serialize(payload, { indices: true });

    return api.put('/me', formData);
};

export const checkLoginStatus = () => api.get('/me');

export const exportInformations =  () =>  apiWithFullResponse.get('/me/export', { headers: {'Content-Type': '\tapplication/vnd.ms-excel'},
                                                                                  responseType: 'blob'});
