import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import '../assets/styles/ReactPhoneInput2.less';
import { Language } from '../types';

interface LangImport {
    [lang: string]: any;
}

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id'];

const intlData = availableLangs.reduce<LangImport>((acc, lang) => ({
    ...acc,
    [lang]: () => import(`react-phone-input-2/lang/${lang}.json`),
}), {});

const PhoneInput: FC<PhoneInputProps> = (props) => {
    const { locale } = useIntl();
    const [localization, setLocalization] = useState();

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            (async () => {
                const localeData = await intlData[locale]?.();
                setLocalization(localeData);
            })();
        }
    }, [locale]);

    return localization || locale === Language.en ? (
        <ReactPhoneInput
            {...props}
            inputClass="ant-input"
            localization={localization}
            preferredCountries={['fr', 'be', 'ch', 'de', 'gb']}
            country={locale}
            specialLabel=""
        />
    ) : null;
};

export default PhoneInput;
