import React, { FC, ReactNode } from 'react';
import { Menu, Modal, Dropdown } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DropDownProps } from 'antd/lib/dropdown';

import { JobOffer } from '../store/api/apiTypes';
import { update, del } from '../store/actions/jobOffers';

import genericMessages from '../locale/genericMessages';
import { getRoute, RoutePathName } from '../routes';
import JobOffersMessages from '../pages/jobOffers/JobOffersMessages';

interface JobOfferMenuProps extends Partial<DropDownProps> {
    jobOffer: JobOffer;
    children?: ReactNode;
    deleteJobOffer: typeof del.trigger;
    updateJobOffer: typeof update.trigger;
}

const JobOfferMenu: FC<JobOfferMenuProps> = ({
    jobOffer, children, deleteJobOffer, updateJobOffer, ...props
}) => {
    const { formatMessage } = useIntl();
    const onClickDelete = () => {
        Modal.confirm({
            title: formatMessage(JobOffersMessages.deleteTitle),
            cancelText: formatMessage(genericMessages.cancel),
            okText: formatMessage(genericMessages.delete),
            onOk: () => {
                deleteJobOffer({ id: jobOffer.id });
            },
            icon: null,
            cancelButtonProps: {
                ghost: true,
            },
            okButtonProps: {
                danger: true,
                ghost: true,
            },
        });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={getRoute(RoutePathName.jobOfferEdit, { jobOfferId: jobOffer.id })}>
                    <FormattedMessage {...genericMessages.edit} />
                </Link>
            </Menu.Item>
            <Menu.Item onClick={onClickDelete} danger>
                <FormattedMessage {...genericMessages.delete} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            trigger={['click']}
            {...props}
            overlay={menu}
        >
            {children}
        </Dropdown>
    );
};

export default connect(
    undefined,
    {
        updateJobOffer: update.trigger,
        deleteJobOffer: del.trigger,
    },
)(JobOfferMenu);
