import { QueryType, Target, Panel, PanelParsedDescription, PanelType } from '../types';
import { metricsMessages } from '../pages/client/dashboard/ReportingMessages';
import genericMessages from '../locale/genericMessages';
import api from '../store/api/_client';
import { message } from 'antd';

let envPromise: Promise<{env: string}> | undefined;

export const getEnv: () => Promise<string> = async () => {
    if (!envPromise) {
        return envPromise = api.get('/').then((response) => {
            // @ts-expect-error
            if (!response.env) {
                message.error('Error could not get env from API');
            }
            // @ts-expect-error
            return response.env;
        });
    } else {
        return envPromise;
    }
};

export interface PrometheusPayload {
    target?: Target;
    queryType?: QueryType;
    params?: { [key: string]: string };
    distribParam: string | null;
    panel?: Panel;
}

function computeStep(target?: Target, params?: { [key: string]: string }, panel?: Panel, maxStep = (24 * 3600)) {
    const numberOfSteps = panel?.type === PanelType.graph ? 50 : 10000;
    const minStep = Math.max(15, Number(target?.interval || 0));
    const start = Number(params?.start || (Date.now() / 1000));
    const end = Math.min(Number(params?.end || (Date.now() / 1000)), (Date.now() / 1000));
    const timeDiff = end - start;
    const computedStep = Math.floor(timeDiff / numberOfSteps);
    const step = Math.max(Math.min(maxStep, computedStep), minStep);
    return String(step);
}

export const formatEndpoint = ({
    target,
    queryType = QueryType.queryRange,
    params,
    distribParam,
    panel,
}: PrometheusPayload) => {
    const urlParams = new URLSearchParams(params);
    urlParams.delete('$organization');
    let exprParams: IterableIterator<RegExpMatchArray> | string[] | undefined = target?.expr.matchAll(/(\$\w+)/g);

    if (exprParams) {
        exprParams = [...exprParams].map((param) => param[0]);
    }

    let query = target?.expr;

    if (exprParams?.length) {
        exprParams.forEach((param) => {
            query = query?.replaceAll(param, params?.[param] || 'all');
        });
    }

    urlParams.append('query', query || '');

    if (queryType === QueryType.queryRange && !urlParams.has('start') && !urlParams.has('end')) {
        throw new Error('You must provide a start and end when using query_range');
    }

    if (params?.end && (Date.now() / 1000) < Number(params.end)) {
        params.end = String(Date.now() / 1000);
        urlParams.set('end', params.end);
    }

    if (target?.instant) {
        urlParams.delete('step');
        urlParams.delete('start');
        urlParams.delete('end');
        urlParams.set('time', `${Math.min(Number(params?.end) ?? Date.now() / 1000, Date.now() / 1000)}`);
    } else {
        urlParams.set('step', computeStep(target, params, panel));
    }

    if (distribParam === 'jobLevel') {
        let newQuery = urlParams.get('query');

        if (newQuery) {
            newQuery = newQuery.replaceAll('(gender)', '(jobLevel)');
            newQuery = newQuery.replaceAll('gender!~"all|"', 'gender=~"all|"');
            newQuery = newQuery.replaceAll('jobLevel=~"all|"', 'jobLevel!~"all|"');
            urlParams.set('query', newQuery || '');
        }
    }

    return `/reporting/grafana/${queryType}?${urlParams.toString()}`;
};

export const getQueryType = (panel: Panel) =>
    panel.targets?.filter((target) => !target.hide).some((target) => target.instant) ?
        QueryType.query :
        QueryType.queryRange;

export const getParsedDescription = (description: Panel['description']) => {
    if (!description) {
        return '';
    }

    try {
        return JSON.parse(description) as PanelParsedDescription;
    } catch (error) {
        return description;
    }
};

export const getPanelName = (description: Panel['description']) => {
    const parsedDescription = getParsedDescription(description);

    return typeof parsedDescription === 'object' ? parsedDescription.name || '' : parsedDescription;
};

export const getLegendFormat = (panel: Panel, targetIndex: number) => (panel.targets[targetIndex].legendFormat || '').replace('{{', '').replace('}}', '');

export const getMetricName = (parsedDescription: PanelParsedDescription) => {
    const metricName = typeof parsedDescription === 'object' ? parsedDescription.name || '' : parsedDescription;

    if (!metricName) {
        return genericMessages.unknown;
    }

    return metricsMessages.get(metricName) ? metricsMessages.get(metricName)! : genericMessages.unknown;
};

export const formatBadgeParameter = (obj: Record<string, boolean> | undefined) => {
    if (typeof obj !== 'object' || obj === null) {
        return '';
    }

    let str = '(';
    for (const prop in obj) {
      if (obj[prop]) {
        str += prop + '|';
      }
    }
    if (str.endsWith('|')) {
      str = str.slice(0, -1);
    }
    str += ')';

    return str;
};
