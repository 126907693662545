import { combineReducers } from 'redux';
import { takeLatest, delay, call, put, all } from 'redux-saga/effects';

import {
    create as createApiCall,
    list as listApiCall,
    del as delApiCall,
    details as detailsApiCall,
    update as updateApiCall,
    JobOffersListPayload,
    CreateJobOfferPayload,
    DeleteJobOfferPayload,
    JobOfferDetailsPayload,
    JobOfferUpdatePayload,
} from '../api/jobOffers';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, JobOffer, InterviewStatus } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { DataAction } from '../helpers/EzeeAction';
import { SearchPaginationQuery } from '../api';

// State

export interface JobOffersState {
    readonly list: RequestState<ListResponse<JobOffer>>;
    readonly listCount: RequestState<{
        all?: number;
        pending?: number;
        refused?: number;
    }>;
    readonly create: RequestState<JobOffer>;
    readonly del: RequestState;
    readonly details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: JobOffer;
        };
    };
    readonly update: RequestState<JobOffer>;
}

const initialState: JobOffersState = {
    create: {
        loading: false,
    },
    list: {
        loading: false,
    },
    listCount: {
        loading: false,
    },
    del: {
        loading: false,
    },
    details: {
        loading: false,
        data: {},
    },
    update: {
        loading: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    JobOffersState['list'],
    JobOffersListPayload,
    ListResponse<JobOffer>
>('jobOffers/list', initialState.list, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const listCount = new EzeeAsyncAction<
    JobOffersState['listCount']
>('jobOffers/listCount', initialState.listCount, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listCount,
    }),
});

export const create = new EzeeAsyncAction<
    JobOffersState['create'], CreateJobOfferPayload, JobOffer
>('jobOffers/create', initialState.create, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.create,
    }),
});

export const del = new EzeeAsyncAction<
    JobOffersState['del'], DeleteJobOfferPayload
>('jobOffers/del', initialState.del, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.del,
    }),
});

export const details = new EzeeAsyncAction<
    JobOffersState['details'], JobOfferDetailsPayload, JobOffer
>('jobOffers/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: {
            ...state.data,
            [payload.id]: payload,
        },
    }),
    failure: (state, payload) => ({
        data: {},
        loading: false,
        error: payload,
    }),
    reset: () => initialState.details,
});

export const update = new EzeeAsyncAction<
    JobOffersState['update'], JobOfferUpdatePayload, JobOffer
>('jobOffers/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.update,
    }),
});

// Reducer

export const jobOffersReducer = combineReducers<JobOffersState>({
    create: create.reducer,
    list: list.reducer,
    listCount: listCount.reducer,
    del: del.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga

export function* jobOffersSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(listCount.type.trigger, listCountSaga);
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

function* listSaga(action: DataAction<SearchPaginationQuery>) {
    try {
        const { throttling, ...payload } = action.payload;
        yield delay(throttling || 0);

        const response = yield call(listApiCall, payload);

        return yield put(list.success(response));
    } catch (error) {
        return yield put(list.failure(error));
    }
}

function* listCountSaga() {
    try {
        const [allOffers, pending, refused]: Array<ListResponse<JobOffer>> = yield all([
            call(listApiCall, { pageSize: 1 }),
            call(listApiCall, { pageSize: 1, status: [InterviewStatus.pending, InterviewStatus.accepted] }),
            call(listApiCall, { pageSize: 1, status: InterviewStatus.refused }),
        ]);

        return yield put(listCount.success({
            all: allOffers.totalCount,
            pending: pending.totalCount,
            refused: refused.totalCount,
        }));
    } catch (error) {
        return yield put(listCount.failure(error));
    }
}

// Store helpers

export const getJobOffersCreateState = (state: MainReducerState) => state.jobOffers.create;
export const getJobOffersListState = (state: MainReducerState) => state.jobOffers.list;
export const getJobOffersListCountState = (state: MainReducerState) => state.jobOffers.listCount;
export const getJobOffersDeleteState = (state: MainReducerState) => state.jobOffers.del;
export const getJobOffersUpdateState = (state: MainReducerState) => state.jobOffers.update;

export type JobOfferDetailsState = RequestState<JobOffer>;

export const getJobOfferStateById = (state: MainReducerState, id: JobOffer['id']) => ({
    loading: state.jobOffers.details.loading,
    error: state.jobOffers.details.error,
    data: state.jobOffers.details.data[id],
});
