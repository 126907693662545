// tslint:disable: max-line-length
import React, { FC, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message, Form, Button } from 'antd';
import { FormProps } from 'antd/lib/form';

import { JobOffer, ProgramTaskValidation } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { create, JobOffersState, getJobOffersCreateState } from '../../store/actions/jobOffers';

import Seo from '../../components/Seo';
import FullscreenLayout from '../../components/FullscreenLayout';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious, useQueryParams } from '../../hooks';
import { IconAddJobOffer } from '../../components/icons';
import JobOfferCreateMessages from './JobOfferCreateMessages';
import JobOfferForm from '../../components/JobOfferForm';

interface JobOfferCreatePageProps {
    createJobOffer: typeof create.trigger;
    createJobOfferState: JobOffersState['create'];
}

const JobOfferCreatePage: FC<JobOfferCreatePageProps> = ({
    createJobOffer, createJobOfferState,
}) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const location = useLocation<{
        previous?: string,
        values?: Partial<JobOffer>,
        validationFields?: ProgramTaskValidation['fields'],
    } | undefined>();
    const queryParams = useQueryParams();
    const previous = usePrevious({ createJobOfferState });
    const history = useHistory();
    const onQuit = useCallback(() => {
        history.replace(location.state?.previous || getRoute(RoutePathName.home), {
            from: getRoute(RoutePathName.jobOfferCreate),
            initiator: location.state?.previous,
        });
    }, [history, location.state]);
    const onFormValidSubmit: FormProps<Partial<JobOffer>>['onFinish'] = (val) => {
        createJobOffer({
            ...val,
            ...(queryParams.get('validationToken') ? { taskValidationToken: queryParams.get('validationToken') } : {}),
        });
    };

    useEffect(() => {
        if (previous?.createJobOfferState.loading && !createJobOfferState.loading) {
            if (createJobOfferState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onQuit();
            }
        }
    }, [
        previous, createJobOfferState.loading, createJobOfferState.error, createJobOfferState.data,
        formatMessage, onQuit,
    ]);

    return (
        <section id="create-action">
            <Seo title={formatMessage(JobOfferCreateMessages.title)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                title={<FormattedMessage {...JobOfferCreateMessages.title} />}
                icon={<IconAddJobOffer />}
            />
            <FullscreenLayout.Content>
                <Spin spinning={createJobOfferState.loading}>
                    <JobOfferForm
                        form={form}
                        onFinish={onFormValidSubmit}
                        requiredResponseDate={location.state?.validationFields?.responseDate.required}
                        submit={(
                            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={createJobOfferState.loading}
                                        size="large"
                                        disabled={
                                            form &&
                                            (
                                                !form.isFieldsTouched() ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            )
                                        }
                                    >
                                        <FormattedMessage {...JobOfferCreateMessages.submit} />
                                    </Button>
                                )}
                            </Form.Item>
                        )}
                    />
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createJobOfferState: getJobOffersCreateState(state),
});

export default connect(
    mapStateToProps,
    {
        createJobOffer: create.trigger,
    },
)(JobOfferCreatePage);
