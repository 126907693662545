import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 12">
        <path fill="currentColor" d="M10.353 6.104l4.778 5.71c-.221.118-.472.186-.737.186H1.606c-.265 0-.516-.068-.737-.187l4.778-5.71 1.15.773c.337.227.77.34 1.203.34.433 0 .866-.113 1.203-.34l1.15-.772zM16 2.307v8.025c0 .273-.064.53-.176.758l-4.64-5.545L16 2.307zm-16 0l4.817 3.238-4.64 5.545A1.71 1.71 0 010 10.332V2.307zM14.394 0c.71 0 1.315.482 1.526 1.149L8.67 6.022c-.351.236-.99.236-1.342 0L.014 1.104C.114.473.777 0 1.606 0z"/>
    </svg>
);

const IconMail: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-mail${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconMail;
