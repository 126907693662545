import React, { FC } from 'react';
import { Tag, Typography } from 'antd';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { IconEvent } from './icons';
import { Pack } from '../store/api/apiTypes';

import '../assets/styles/PackCard.less';
import clientMessages from '../locale/clientMessages';

interface PackCardProps {
    pack: Pack;
}

const PackCard: FC<PackCardProps> = ({ pack }) => {
    return (
        <div className="pack-card">
            <header>
                <Tag className="tag-primary">
                    <FormattedMessage {...clientMessages.availableCount} values={{ count: pack.availableLicenseCount }} />
                </Tag>
                <Tag><FormattedMessage {...clientMessages.siegeCount} values={{ count: pack.maxLicenses }} /></Tag>
                <Tag icon={<IconEvent />}>
                    {pack.period?.fromDate && (<FormattedDate value={new Date(pack.period?.fromDate)}/>)}
                    &nbsp;-&nbsp;
                    {pack.period?.toDate && (<FormattedDate value={new Date(pack.period?.toDate)}/>)}
                </Tag>
            </header>
            <div className="pack-card-body">
                <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    <p className="pack-card-title">
                        {pack.name}
                    </p>
                </Typography.Paragraph>
                <p className="pack-card-description">
                    {pack.description}
                </p>
            </div>
        </div>
    );
};

export default PackCard;
