import React, { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import '../assets/styles/HeaderCTA.less';

import { useIsMobile } from '../hooks';

const HeaderCTA: FC<ButtonProps> = ({ icon, children, ...props }) => {
    const isMobile = useIsMobile();

    return (
        <div id="header-cta">
            <Button
                type="primary"
                size={isMobile ? 'small' : 'large'}
                shape={isMobile ? 'circle' : undefined}
                {...props}
            >
                {icon}{isMobile ? null : <span>{children}</span>}
            </Button>
        </div>
    );
};

export default HeaderCTA;
