import React, { FC } from 'react';
import { arc } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { yellow, orange } from '@ant-design/colors';
import tinycolor from 'tinycolor2';

import '../assets/styles/Gauge.less';

import genericMessages from '../locale/genericMessages';
import { Tooltip } from 'antd';
import HomeMessages from '../pages/home/HomeMessages';
import { InfoCircleOutlined } from '@ant-design/icons';

const arcSettings = {
    innerRadius: 0.84,
    outerRadius: 1,
    startAngle: 0.65,
    endAngle: 5.65,
};

interface GaugeProps {
    value: number;
    min?: number;
    max?: number;
    type: 'action' | 'progression';
}

const Gauge: FC<GaugeProps> = ({
    value = 50,
    min = 0,
    max = 100,
    type,
}) => {
    const backgroundArc = arc()
        .cornerRadius(1)
        (arcSettings) || undefined;
    const percentScale = scaleLinear()
        .domain([min, max])
        .range([0, 1]);
    const percent = percentScale(value);
    const angleScale = scaleLinear()
        .domain([0, 1])
        .range([0.65, 5.65])
        .clamp(true);
    const angle = angleScale(percent);
    const filledArc = arc()
        .cornerRadius(1)
        ({
            ...arcSettings,
            endAngle: angle,
        }) || undefined;
    const label = type === 'action' ? (
        <>
            <FormattedMessage {...genericMessages.action} values={{ count: 2 }} /> <InfoCircleOutlined />
        </>
    ) : (
        <>
            <FormattedMessage {...genericMessages.progression} /> <InfoCircleOutlined />
        </>
    );
    const circleColor = type === 'action' ?
        (window.__MCM__theme?.primary || '#6B5AED') :
        (
            value < 25 ? orange.primary : (
                value < 50 ? yellow.primary : '#4FCF04'
            )
        );

    return (
        <Tooltip overlay={<FormattedMessage {...(type === 'action' ? HomeMessages.gaugesActionHelp : HomeMessages.gaugesProgressionHelp)} />}>
            <div className="gauge">
                <svg
                    width="100%"
                    viewBox={[
                        -1, -1,
                        2, 2,
                    ].join(' ')}
                >
                    <path
                        d={backgroundArc}
                        fill={window.__MCM__theme?.primary ?
                            tinycolor({ ...tinycolor(window.__MCM__theme?.primary).toHsl(), s: 100, l: 90 }).toHexString() :
                            '#cdd7ff'
                        }
                    />
                    <path
                        d={filledArc}
                        fill={circleColor}
                    />
                </svg>
                <div className="gauge-value-wrapper">
                    <p>
                        <span className="gauge-value">
                            <FormattedNumber
                                value={Math.floor(value)}
                                maximumFractionDigits={0}
                            />
                        </span>
                        <span className="gauge-unit">
                            {
                                type === 'action' ?
                                    <FormattedMessage {...genericMessages.pointsShort} /> :
                                    '%'
                            }
                        </span>
                    </p>
                    <span className="gauge-total">{`/ ${max}`}</span>
                </div>
                <span className="gauge-label">{label}</span>
            </div>

        </Tooltip>
    );
};

export default Gauge;
