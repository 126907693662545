import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'documents.title',
        defaultMessage: 'Vos notes et documents',
        description: 'Documents page title',
    },
    filesTitle: {
        id: 'documents.files.title',
        defaultMessage: 'Vos documents téléchargés',
        description: 'Documents page title',
    },
    filesDescription: {
        id: 'documents.files.description',
        defaultMessage: 'Ces documents sont partagés avec votre service mobilité (RH, managers, coachs, conseillers de carrière,...). En cliquant sur le bouton "Télécharger", vous pouvez ajouter d\'autres documents.',
        description: 'Documents page description',
    },
    surveysTitle: {
        id: 'documents.surveys.title',
        defaultMessage: 'Votre bloc-notes',
        description: 'Documents page title',
    },
    surveysDescription: {
        id: 'documents.surveys.description',
        defaultMessage: 'Vous êtes seul(e) à pouvoir voir ces notes. Vous pouvez les modifier ou enrichir en cliquant pour chaque note sur le bouton "Modifier les réponses".',
        description: 'Documents page description',
    },
    surveysEditAnswers: {
        id: 'documents.surveys.edit_button',
        defaultMessage: 'Modifier les réponses',
        description: 'Documents page edit answers',
    },
    upload: {
        id: 'documents.files.upload',
        defaultMessage: 'Télécharger',
        description: 'Documents page upload button',
    },
    uploadSuccess: {
        id: 'documents.files.upload.success',
        defaultMessage: 'Fichier téléchargé avec succès',
        description: 'Documents page upload button',
    },
    uploadErrorDuplicateName: {
        id: 'documents.files.upload.error.duplicate_name',
        defaultMessage: 'Un fichier existe déjà avec ce nom.',
        description: 'Documents page upload button',
    },
});
