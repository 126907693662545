import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'networking_actions.title',
        defaultMessage: 'Actions de networking',
        description: 'Networking actions page title',
    },
    filtersActive: {
        id: 'networking_actions.filters.active',
        defaultMessage: 'Actives ({count})',
        description: 'Networking actions page filter',
    },
    filtersComplete: {
        id: 'networking_actions.filters.complete',
        defaultMessage: 'Réalisées ({count})',
        description: 'Networking actions page filter',
    },
    filtersCanceled: {
        id: 'networking_actions.filters.canceled',
        defaultMessage: 'Annulées ({count})',
        description: 'Networking actions page filter',
    },
});
