import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="nonzero" d="M15.5619597,2.4255911 C14.9779059,1.8581363 14.0557157,1.8581363 13.5024015,2.4255911 L5.60230548,10.4367177 L2.49759846,7.29902643 C1.91354467,6.73157163 0.991354467,6.73157163 0.438040346,7.29902643 C-0.146013449,7.86648122 -0.146013449,8.80111266 0.438040346,9.40194715 L4.55715658,13.5744089 C5.14121037,14.1418637 6.06340058,14.1418637 6.6167147,13.5744089 L15.5619597,4.49513213 C16.1460134,3.92767733 16.1460134,2.9930459 15.5619597,2.4255911 Z" />
    </svg>
);

const IconCheck: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-check${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCheck;
