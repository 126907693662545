import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Space, Typography, Divider, message, Empty } from 'antd';
import { DateTime } from 'luxon';

import '../../assets/styles/UserNetworkingActions.less';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';

import { MainReducerState } from '../../store/reducers';
import { getAppointmentsListState, list, AppointmentsState, getAppointmentsUpdateState } from '../../store/actions/appointments';

import NetworkingActionCard from '../../components/NetworkingActionCard';
import { IconArrowLeftCircle, IconArrowRightCircle } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import { usePrevious } from '../../hooks';
import HomeMessages from './HomeMessages';
import BasicList from '../../components/BasicList';
import CardSkeleton from '../../components/CardSkeleton';
import { AppointmentStatus } from '../../store/api/apiTypes';

SwiperCore.use([Navigation, Pagination, A11y]);

interface UserNetworkingActionsProps {
    appointmentsListState: AppointmentsState['list'];
    appointmentsUpdateState: AppointmentsState['update'];
    listAppointments: typeof list.trigger;
}

const UserNetworkingActions: FC<UserNetworkingActionsProps> = ({
    listAppointments, appointmentsListState, appointmentsUpdateState,
}) => {
    const previous = usePrevious({ appointmentsUpdateState });
    const { formatMessage } = useIntl();

    useEffect(() => {
        listAppointments({
            fromDate: DateTime.utc().startOf('week').toString(),
            toDate: DateTime.utc().endOf('week').toString(),
            status: AppointmentStatus.pending,
        });
    }, [listAppointments]);

    useEffect(() => {
        if (previous?.appointmentsUpdateState.loading && !appointmentsUpdateState.loading) {
            if (appointmentsUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                listAppointments({
                    fromDate: DateTime.utc().startOf('week').toString(),
                    toDate: DateTime.utc().endOf('week').toString(),
                });
            }
        }
    }, [
        previous, appointmentsUpdateState.loading, appointmentsUpdateState.error, formatMessage,
        listAppointments,
    ]);

    return (
        <section id="home-networking-actions" className="block">
            <header className="flex-between">
                <Typography.Title level={2}>
                    <FormattedMessage {...HomeMessages.weekMeetingsTitle} />
                </Typography.Title>
                <Breakpoint xl up>
                    <Space>
                        <Button
                            type="link"
                            className="home-networking-actions-swiper-button-prev"
                        >
                            <IconArrowLeftCircle />
                        </Button>
                        <Button
                            type="link"
                            className="home-networking-actions-swiper-button-next"
                        >
                            <IconArrowRightCircle />
                        </Button>
                    </Space>
                </Breakpoint>
            </header>
            {appointmentsListState.loading && (
                <BasicList grid>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                </BasicList>
            )}
            {!appointmentsListState.loading && !!appointmentsListState.data?.items.length && (
                <>
                    <Swiper
                        spaceBetween={16}
                        slidesPerView="auto"
                        a11y={{
                            enabled: true,
                            prevSlideMessage: '',
                            nextSlideMessage: '',
                            firstSlideMessage: '',
                            lastSlideMessage: '',
                            paginationBulletMessage: '',
                            notificationClass: '',
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 26,
                            },
                        }}
                        pagination={{
                            el: '.home-networking-actions-swiper-pagination',
                        }}
                        navigation={{
                            nextEl: '.home-networking-actions-swiper-button-next',
                            prevEl: '.home-networking-actions-swiper-button-prev',
                        }}
                    >
                        {appointmentsListState.data?.items.map((appointement) => (
                            <SwiperSlide key={appointement.id}>
                                <NetworkingActionCard appointment={appointement} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-pagination home-networking-actions-swiper-pagination" />
                </>
            )}
            {!appointmentsListState.loading && !appointmentsListState.data?.items.length && (
                <Empty description={<FormattedMessage {...HomeMessages.weekMeetingsNoResult} />} />
            )}
            <Divider />
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentsListState: getAppointmentsListState(state),
    appointmentsUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listAppointments: list.trigger,
    },
)(UserNetworkingActions);
