import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { UserProgramTask } from './apiTypes';

export interface LibraryDocumentDetailsPayload {
    id: string;
}
export interface LibraryDocumentCreatePayload {
    subTaskId: UserProgramTask['id'];
    document: {
        document: RcFile;
        name: string;
        family: UserProgramTask['family'];
        language: string;
    };
}

export type LibraryDocumentListPayload = SearchPaginationQuery;

export const details = (payload: LibraryDocumentDetailsPayload) => api.get(`/me/library/${payload.id}`);

export const list = (payload: LibraryDocumentListPayload = {}) =>
    api.get(`/me/library`, {params: cleanPayload(payload)});

export const create = (payload: LibraryDocumentCreatePayload) => {
    const formData = serialize(payload.document, { indices: true });

    return api.post('/me/library', formData);
};
