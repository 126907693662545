import React, { FC, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Button, Empty, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { PaginationProps } from 'antd/lib/pagination';
import { DateTime } from 'luxon';

import { MainReducerState } from '../../store/reducers';
import { TasksState, listMy, getMyTasksState } from '../../store/actions/tasks';
import { ProgramsState, getMyProgramState } from '../../store/actions/programs';

import { IconArrowLeftCircle, IconArrowRightCircle } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import Select from '../../components/Select';
import BasicList from '../../components/BasicList';
import ProgramTaskCard from '../../components/ProgramTaskCard';
import Pagination from '../../components/Pagination';
import { useQueryParams } from '../../hooks';
import { scrollToElement } from '../../helpers';
import constants from '../../config/constants';
import CardSkeleton from '../../components/CardSkeleton';
import ProgramMessages from './ProgramMessages';

interface TaskHistoryProps {
    programState: ProgramsState['my'];
    listMyTasks: typeof listMy.trigger;
    listMyTasksState: TasksState['listMy'];
}

const TaskHistory: FC<TaskHistoryProps> = ({ listMyTasks, listMyTasksState, programState }) => {
    const history = useHistory();
    const location = useLocation();
    const listRef = useRef(null);
    const todaysWeekNumber = DateTime.utc().weekNumber;
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const weekParam = urlParams.get('week');
    const weekParamParsed = weekParam ? (isNaN(parseInt(weekParam, 10)) ? 0 : parseInt(weekParam, 10)) : null;
    const currentPage = pageParam !== null ?
        (parseInt(pageParam, 10) || 1) :
        1;
    const currentWeekIndex = weekParamParsed !== null && programState.data?.weeks[weekParamParsed] ? weekParamParsed : 0;

    const onWeekChange = (value: SelectValue) => {
        urlParams.set('week', `${value}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('page', `${page || 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    useEffect(() => {
        listMyTasks({
            page: currentPage - 1,
            pageSize: constants.PAGE_SIZE,
            fromDate: currentWeekIndex !== 0 ?
                programState.data?.weeks[currentWeekIndex].fromDate :
                undefined,
            toDate: programState.data?.weeks[currentWeekIndex].toDate,
            strictRange: true,
        });
    }, [listMyTasks, currentPage, currentWeekIndex, programState.data]);

    return (
        <section id="task-history">
            <header>
                <Typography.Title level={2}>
                    <FormattedMessage {...ProgramMessages.taskHistoryTitle} />
                </Typography.Title>
                <div id="task-history-filters">
                    <Button
                        type="link"
                        id="task-history-filters-previous"
                        onClick={onWeekChange.bind(null, currentWeekIndex - 1)}
                        disabled={currentWeekIndex === 0}
                    >
                        <IconArrowLeftCircle />
                        <Breakpoint lg up tagName="span">
                            <FormattedMessage {...genericMessages.previousWeek} />
                        </Breakpoint>
                    </Button>
                    <Button
                        type="link"
                        id="task-history-filters-next"
                        onClick={onWeekChange.bind(null, currentWeekIndex + 1)}
                        disabled={currentWeekIndex === ((programState.data?.weeks?.length ?? 0) - 1)}
                    >
                        <Breakpoint lg up tagName="span">
                            <FormattedMessage {...genericMessages.nextWeek} />
                        </Breakpoint>
                        <IconArrowRightCircle />
                    </Button>
                    {!programState.loading ? (
                        <Select
                            onChange={onWeekChange}
                            bordered={false}
                            value={`${currentWeekIndex}`}
                            id="task-history-filters-select"
                        >
                            {programState.data?.weeks.map((week, w) => (
                                <Select.Option value={`${w}`} key={w}>
                                    <FormattedMessage
                                        {...genericMessages.weekNumber}
                                        values={{ number: w + 1 }}
                                    /> {
                                        week.fromDate && DateTime.fromISO(week.fromDate, { zone: 'UTC' }).weekNumber === todaysWeekNumber && (
                                            <small className="text-lowercase">
                                                {'('}
                                                <FormattedMessage {...ProgramMessages.taskHistoryCurrentWeek} />
                                                {')'}
                                            </small>
                                        )
                                    }
                                </Select.Option>
                            ))}
                        </Select>
                    ) : <Spin />}
                </div>
            </header>
            {!programState.loading && !listMyTasksState.loading && !!listMyTasksState.data?.items.length && (
                <>
                    <BasicList ref={listRef}>
                        {listMyTasksState.data?.items.map((userProgramTask) => (
                            <li key={userProgramTask.id}>
                                <ProgramTaskCard data={userProgramTask} />
                            </li>
                        ))}
                    </BasicList>
                    {listMyTasksState.data?.pageCount > 1 && (
                        <Pagination
                            current={currentPage}
                            onChange={onPageSelect}
                            pageSize={constants.PAGE_SIZE}
                            total={listMyTasksState.data?.totalCount}
                        />
                    )}
                </>
            )}
            {!programState.loading && !listMyTasksState.loading && !listMyTasksState.data?.items.length && (
                <Empty />
            )}
            {(programState.loading || listMyTasksState.loading) && (
                <BasicList ref={listRef}>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                </BasicList>
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listMyTasksState: getMyTasksState(state),
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
    {
        listMyTasks: listMy.trigger,
    },
)(TaskHistory);
