import React, { FC, useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Button, Space, Menu, Spin, Dropdown } from 'antd';
import { DashOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';

import '../../assets/styles/Dashboard.less';

import { reportingExport, getReportingExportState, ReportingState } from '../../store/actions/reporting';
import { User } from '../../store/api/apiTypes';
import { getUser } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { getMyProgramState, ProgramsState } from '../../store/actions/programs';

import userJson from '../../assets/data/user-dashboard.json';
import genericMessages from '../../locale/genericMessages';
import DashboardBuilder from '../../components/reporting/DashboardBuilder';
import HeaderCTA from '../../components/HeaderCTA';
import { IconPlus, IconCalendar, IconMore } from '../../components/icons';
import DatePicker from '../../components/DatePicker';
import Seo from '../../components/Seo';
import ListHeader from '../../components/ListHeader';
import { getRoute, RoutePathName } from '../../routes';
import { GrafanaDashboard } from '../../types';
import { useQueryParams } from '../../hooks';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import ReportingMessages from '../client/dashboard/ReportingMessages';
import DashboardMessages from './DashboardMessages';
import { formatBadgeParameter } from '../../helpers/reporting';

interface DashboardProps {
    user?: User;
    exportState: ReportingState['reportingExport'];
    exportMetrics: typeof reportingExport.trigger;
    programState: ProgramsState['my'];
}

const Dashboard: FC<DashboardProps> = ({ user, exportState, exportMetrics, programState }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const urlParams = useQueryParams();
    const dateRangeParam = urlParams.get('dateRange');
    const dateRangeParams = dateRangeParam ?
        dateRangeParam.split('-') :
        undefined;
    const isJobOfferPanelHidden = !Boolean(programState.data?.program?.shouldDisplayJobOffers);
    const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>(
        [
            dateRangeParams?.[0] ? dayjs(parseInt(dateRangeParams[0], 10)) : dayjs().subtract(7, 'day').startOf('day'),
            dateRangeParams?.[1] ? dayjs(parseInt(dateRangeParams[1], 10)) : dayjs().endOf('day'),
        ],
    );
    const onChangeDates = (value: any, dateStrings: any, info: any) => {
        setDates(value);

        if (value?.[0] && value?.[1]) {
            urlParams.set('dateRange', `${value[0].startOf('day').valueOf()}-${value[1].endOf('day').valueOf()}`);
            history.push({ search: urlParams.toString() });
        }
    };
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.program),
            },
        });
    };
    const onClickExport = () => {
        exportMetrics({
            dates,
        });
    };
    const menu = (
        <Menu>
            <Menu.Item onClick={onClickExport}>
                <FormattedMessage {...ReportingMessages.export} /> {exportState.loading && <Spin />}
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (!dateRangeParam && dates[0] && dates[1]) {
            urlParams.set('dateRange', `${dates[0].valueOf()}-${dates[1].valueOf()}`);
            history.push({ search: urlParams.toString() });
        }
    }, [dateRangeParam, dates, urlParams, history]);

    return (
        <section id="dashboard">
            <Seo title={formatMessage(DashboardMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <ListHeader>
                <div className="flex-lg-between" style={{ width: '100%' }}>
                    <Typography.Title level={1}>
                        <FormattedMessage {...DashboardMessages.title} />
                    </Typography.Title>
                    <Space>
                        <DatePicker.RangePicker
                            suffixIcon={<IconCalendar />}
                            onCalendarChange={onChangeDates}
                            value={dates}
                            allowClear={false}
                            separator={<DashOutlined />}
                            format="DD/MM/YYYY"
                        />
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                            <Button type="link">
                                <IconMore />
                            </Button>
                        </Dropdown>
                    </Space>
                </div>
            </ListHeader>
            {user && (
                <DashboardBuilder.Panels
                    json={userJson as unknown as GrafanaDashboard}
                    isJobOfferPanelHidden={isJobOfferPanelHidden}
                    params={{
                        $user: user.id,
                        $badgeType: formatBadgeParameter(user.selectedProgram?.program?.badges),
                    }}
                />
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    exportState: getReportingExportState(state),
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
    {
        exportMetrics: reportingExport.trigger,
    },
)(Dashboard);
