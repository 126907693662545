import React, { FC } from 'react';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import { FormattedMessage, defineMessages } from 'react-intl';

import genericMessages from '../locale/genericMessages';

const messages = defineMessages({
    deleteConfirmTitle: {
        id: 'delete_pop_confirm.title',
        defaultMessage: 'Confirmez-vous la suppression',
        description: 'Delete pop confirm title',
    },
    deleteConfirmOkButton: {
        id: 'delete_pop_confirm.ok_button',
        defaultMessage: 'Confirmer',
        description: 'Delete pop confirm button',
    },
    deleteConfirmCancelButton: {
        id: 'delete_pop_confirm.cancel_button',
        defaultMessage: 'Annuler',
        description: 'Delete pop confirm cancel button',
    },
});

interface DeletePopConfirmProps extends Omit<PopconfirmProps, 'title'> {
    title?: PopconfirmProps['title'];
}

const DeletePopConfirm: FC<DeletePopConfirmProps> = ({ children, ...props }) => (
    <Popconfirm
        title={<FormattedMessage {...messages.deleteConfirmTitle} />}
        okText={<FormattedMessage {...messages.deleteConfirmOkButton} />}
        cancelText={<FormattedMessage {...messages.deleteConfirmCancelButton} />}
        {...props}
    >
        {children || <FormattedMessage {...genericMessages.delete} />}
    </Popconfirm>
);

export default DeletePopConfirm;
