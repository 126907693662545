import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M13.6 0H2.4C1.156 0 0 1.156 0 2.4v11.2C0 14.933 1.156 16 2.4 16h11.2c1.333 0 2.4-1.156 2.4-2.4V2.4C16 1.156 14.844 0 13.6 0zm-1.422 9.778H3.733a.71.71 0 0 1 0-1.422h8.445a.71.71 0 0 1 0 1.422zm0 2.755H3.733a.71.71 0 0 1 0-1.422h8.445a.71.71 0 0 1 0 1.423zm.21-6.133H3.523a.5.5 0 0 1-.5-.5V4.056a.5.5 0 0 1 .5-.5h8.867a.5.5 0 0 1 .5.5V5.9a.5.5 0 0 1-.5.5z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconOffers: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-offers${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconOffers;
