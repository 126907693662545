import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'client.organization.title',
        defaultMessage: 'Entreprise',
        description: 'Client organization page title',
    },
    titlePacks: {
        id: 'client.organization.titlePacks',
        defaultMessage: 'Packs souscrits',
        description: 'Client organization packs list title',
    },
});
