import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 4 17">
        <path d="M4 1.962c0 1.083-.895 1.96-2 1.96s-2-.877-2-1.96C0 .878.895 0 2 0s2 .878 2 1.962zM4 8.5c0 1.083-.895 1.962-2 1.962S0 9.582 0 8.5c0-1.083.895-1.962 2-1.962s2 .88 2 1.962zm0 6.538C4 16.122 3.105 17 2 17s-2-.878-2-1.962c0-1.083.895-1.96 2-1.96s2 .877 2 1.96z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconMore: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-more${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconMore;
