import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M2.512 8.689c.153.054.315.088.482.1h1.672l-1.561 3.015c-.096.186-.309.25-.477.146l-1.052-.658c-.171-.107-.23-.35-.133-.538zm6.44-8.63c.235-.16.536.027.536.334v9.848c0 .304-.297.493-.531.337l-3.18-2.106V2.227zm1.62 6.998l.71.79a.424.424 0 010 .555.333.333 0 01-.25.115.333.333 0 01-.248-.115l-.71-.791a.424.424 0 010-.554.327.327 0 01.498 0zm-7.536-4.35h2.037v5.299H3.036c-.66-.045-1.195-.642-1.195-1.378V3.995c0-.736.535-1.333 1.195-1.288zm-1.857.848a2.34 2.34 0 00-.042.44v2.633c0 .15.015.298.042.44H.668C.299 7.068 0 6.735 0 6.324V4.3c0-.411.299-.745.668-.745zm10.47 1.43c.194 0 .351.175.351.392 0 .216-.157.392-.352.392h-1.003c-.194 0-.352-.176-.352-.392 0-.217.158-.392.352-.392h1.003zm-.368-2.633a.425.425 0 010 .555l-.71.79a.333.333 0 01-.248.115.333.333 0 01-.249-.115.424.424 0 010-.554l.71-.79a.327.327 0 01.497 0z" />
    </svg>
);

const IconCommunication: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-communication${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCommunication;
