import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'library.title',
        defaultMessage: 'Bibliothèque',
        description: 'Library page title',
    },
    categories: {
        id: 'library.categories',
        defaultMessage: 'Catégories',
        description: 'Library categories',
    },
    viewFile: {
        id: 'library.viewFile',
        defaultMessage: 'Accéder à la fiche',
        description: 'View more label',
    },
    viewVideo: {
        id: 'library.viewVideo',
        defaultMessage: 'Regarder la vidéo',
        description: 'View more label',
    },
    viewPodcast: {
        id: 'library.viewPodcast',
        defaultMessage: 'Ecouter le podcast',
        description: 'View more label',
    },
    backToList: {
        id: 'library.backToList',
        defaultMessage: 'Retour à la bibliothèque',
        description: 'Back to list',
    },
    downloadFile: {
        id: 'library.downloadFile',
        defaultMessage: 'Télécharger le fichier',
        description: 'Download file',
    },
    typeError: {
        id: 'library.file.type_error',
        defaultMessage: 'Fichier non supporté',
        description: 'file type error',
    },
});
