import React, { FC } from 'react';
import { Button, Divider, Tag, Typography } from 'antd';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';

import '../assets/styles/NetworkingActionCard.less';

import { Appointment, networkingActionTypeMessages } from '../store/api/apiTypes';

import { IconArrowRight, IconCheckCircle, IconContacts, IconEvent, IconMore, IconCrossCircle } from './icons';
import genericMessages from '../locale/genericMessages';
import { getFullName, classNames } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import NetworkingActionMenu from './NetworkingActionMenu';
import { isAppointmentValidated, isAppointmentCancelled } from '../helpers/data';

interface NetworkingActionCardProps {
    appointment: Appointment;
}

const NetworkingActionCard: FC<NetworkingActionCardProps> = ({ appointment }) => (
    <div
        className={classNames(
            'networking-action-card',
            isAppointmentValidated(appointment) && 'networking-action-card-with-status is-complete',
            isAppointmentCancelled(appointment) && 'networking-action-card-with-status is-cancelled',
        )}
    >
        <header className="flex-between">
            <Tag icon={<IconEvent />}>
                <FormattedMessage
                    {...genericMessages.dateAndTime}
                    values={{
                        date: (
                            <FormattedDate
                                value={appointment.date}
                                month="long"
                                day="numeric"
                            />
                        ),
                        time: <FormattedTime value={appointment.date} />,
                    }}
                />
            </Tag>
            <NetworkingActionMenu appointment={appointment} placement="bottomRight">
                <Button type="link" className="networking-action-card-menu-button">
                    <IconMore />
                </Button>
            </NetworkingActionMenu>
        </header>
        <div className="networking-action-card-body">
            <Typography.Paragraph ellipsis={{ rows: 2 }}>
                <p className="networking-action-card-title">
                    <Link to={getRoute(RoutePathName.networkingAction, { networkingActionId: appointment.id })}>
                        {appointment.title}
                    </Link>
                </p>
            </Typography.Paragraph>
            <p className="networking-action-card-type">
                <FormattedMessage {...networkingActionTypeMessages.get(appointment.actionType)} />
            </p>
            <Divider />
            {appointment.contact && (
                <div className="networking-action-card-contact">
                    <IconContacts />
                    <div>
                        <p className="networking-action-card-contact-name">
                            {getFullName(appointment.contact?.firstName, appointment.contact?.lastName)}
                        </p>
                        <p className="networking-action-card-contact-company">
                            {appointment.contact?.company?.name}
                        </p>
                    </div>
                </div>
            )}
        </div>
        <footer className="flex-between">
            <Link
                className="text-primary"
                to={getRoute(RoutePathName.networkingAction, {networkingActionId: appointment.id})}
            >
                <FormattedMessage {...genericMessages.detailsLink} /> <IconArrowRight />
            </Link>
            {isAppointmentValidated(appointment) && <IconCheckCircle />}
            {isAppointmentCancelled(appointment) && <IconCrossCircle />}
        </footer>
    </div>
);

export default NetworkingActionCard;
