import { combineReducers } from 'redux';
import { takeLatest, delay, call, put, all } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import {
    ContactCreatePayload,
    create as createApiCall,
    list as listApiCall,
    del as delApiCall,
    details as detailsApiCall,
    update as updateApiCall,
    exportAll as exportAllApiCall,
    ContactDeletePayload,
    ContactDetailsPayload,
    ContactUpdatePayload,
    ContactListPayload,
} from '../api/contacts';
import { MainReducerState, RequestState } from '../reducers';
import { Contact, ListResponse } from '../api/apiTypes';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface ContactsState {
    readonly create: RequestState<Contact>;
    readonly list: RequestState<ListResponse<Contact>>;
    readonly listCount: RequestState<{
        all?: number;
        referrer?: number;
        referred?: number;
    }>;
    readonly del: RequestState;
    readonly details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Contact;
        };
    };
    readonly update: RequestState<Contact>;
    readonly exportAll: RequestState<string>;
}

const initialState: ContactsState = {
    create: {
        loading: false,
    },
    list: {
        loading: false,
    },
    listCount: {
        loading: false,
    },
    del: {
        loading: false,
    },
    details: {
        loading: false,
        data: {},
    },
    update: {
        loading: false,
    },
    exportAll: {
        loading: false,
    },
};

// Actions/Reducers

export const create = new EzeeAsyncAction<
    ContactsState['create'], ContactCreatePayload, Contact
>('contacts/create', initialState.create, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.create,
    }),
});

export const list = new EzeeAsyncAction<
    ContactsState['list'], ContactListPayload, ListResponse<Contact>
>('contacts/list', initialState.list, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const listCount = new EzeeAsyncAction<
    ContactsState['listCount'], ContactListPayload
>('contacts/listCount', initialState.listCount, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listCount,
    }),
});

export const del = new EzeeAsyncAction<
    ContactsState['del'], ContactDeletePayload
>('contacts/del', initialState.del, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.del,
    }),
});

export const details = new EzeeAsyncAction<
    ContactsState['details'], ContactDetailsPayload, Contact
>('contacts/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: {
            ...state.data,
            [payload.id]: payload,
        },
    }),
    failure: (state, payload) => ({
        data: {},
        loading: false,
        error: payload,
    }),
    reset: () => initialState.details,
});

export const update = new EzeeAsyncAction<
    ContactsState['update'], ContactUpdatePayload, Contact
>('contacts/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.update,
    }),
});

export const exportAll = new EzeeAsyncAction<
    ContactsState['exportAll'], any, string
>('contacts/exportAll', initialState.exportAll, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.exportAll,
    }),
});

// Reducer

export const contactsReducer = combineReducers<ContactsState>({
    create: create.reducer,
    list: list.reducer,
    listCount: listCount.reducer,
    del: del.reducer,
    details: details.reducer,
    update: update.reducer,
    exportAll: exportAll.reducer,
});

// Saga

export function* contactsSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(listCount.type.trigger, listCountSaga);
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(exportAll.type.trigger, exportAllSaga);
}

function* listSaga(action: DataAction<ContactListPayload>) {
    try {
        const { throttling, ...payload } = action.payload;
        yield delay(throttling || 0);

        const response = yield call(listApiCall, payload);

        return yield put(list.success(response));
    } catch (error) {
        return yield put(list.failure(error));
    }
}

function* listCountSaga() {
    try {
        const [allContacts, referrer, referred]: Array<ListResponse<Contact>> = yield all([
            call(listApiCall, { pageSize: 1 }),
            call(listApiCall, { pageSize: 1, referrer: true }),
            call(listApiCall, { pageSize: 1, referred: true}),
        ]);

        return yield put(listCount.success({
            all: allContacts.totalCount,
            referrer: referrer.totalCount,
            referred: referred.totalCount,
        }));
    } catch (error) {
        return yield put(listCount.failure(error));
    }
}

function* exportAllSaga() {
    try {
        const response = yield call(exportAllApiCall);
        const blob = new Blob([response], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);

        window.setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 35000);

        return yield put(exportAll.success(url));
    } catch (error) {
        return yield put(exportAll.failure(error));
    }
}

// Store helpers

export const getContactsCreateState = (state: MainReducerState) => state.contacts.create;
export const getContactsListState = (state: MainReducerState) => state.contacts.list;
export const getContactsListCountState = (state: MainReducerState) => state.contacts.listCount;
export const getContactsDeleteState = (state: MainReducerState) => state.contacts.del;
export const getContactsUpdateState = (state: MainReducerState) => state.contacts.update;
export const getContactsExportState = (state: MainReducerState) => state.contacts.exportAll;

export type ContactDetailsState = RequestState<Contact>;

export const getContactStateById = (state: MainReducerState, id: Contact['id']) => ({
    loading: state.contacts.details.loading,
    error: state.contacts.details.error,
    data: state.contacts.details.data[id],
});
