import { combineReducers } from 'redux';
import { takeLatest, select, put } from 'redux-saga/effects';
import { Dayjs } from 'dayjs';
import Papa from 'papaparse';

import { EzeeAction, DataAction } from '../helpers/EzeeAction';
import { MainReducerState, RequestState } from '../reducers';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { downloadFile } from '../../helpers';
import { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';

// State

export interface ReportingState {
    metrics: {
        [name: string]: {
            translatedName: string;
            type: 'stat' | 'pieChart' | 'lineChart' | 'barChart';
            user?: string;
            group?: string;
            distrib?: 'gender' | 'jobLevel';
            value: any;
        };
    };
    reportingExport: RequestState;
}

const initialState: ReportingState = {
    metrics: {},
    reportingExport: {
        loading: false,
    },
};

// Actions/Reducers

export const metrics = new EzeeAction<ReportingState['metrics']>('metrics', initialState.metrics, {
    set: (state, {name, ...payload}) => ({
        ...state,
        [name]: {
            ...payload,
        },
    }),
    remove: (state, {name}) => ({
        ...state,
        [name]: undefined,
    }),
});

export const reportingExport = new EzeeAsyncAction<
    ReportingState['reportingExport']
>('reporting/reportingExport', initialState.reportingExport, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.reportingExport,
    }),
});

// Reducer

export const reportingReducer = combineReducers<ReportingState>({
    metrics: metrics.reducer,
    reportingExport: reportingExport.reducer,
});

// Saga

export function* reportingSaga() {
    yield takeLatest(reportingExport.type.trigger, exportSaga);
}

export interface ReportingExportPayload {
    dates: [Dayjs | null, Dayjs | null];
}

function* exportSaga(action: DataAction<ReportingExportPayload>) {
    try {
        const { dates } = action.payload;

        const data = yield formatExport();

        downloadFile(data, `mcm-reporting-${dates[0]?.format('YYYY-MM-DD')}-${dates[1]?.format('YYYY-MM-DD')}-${Date.now()}.csv`);

        return yield put(reportingExport.success(data));
    } catch (error) {
        console.error(error);
        return yield put(reportingExport.failure(error));
    }
}

function* formatExport() {
    const metricsState: ReportingState['metrics'] = yield select(getReportingMetricsState);
    const data = {
        fields: ['name', 'user', 'group', 'gender', 'jobLevel', 'label', 'time', 'value', 'lvl1', 'lvl2', 'lvl3'],
        data: [] as any,
    };

    Object.values(metricsState).forEach((metric) => {
        if (metric?.type === 'stat') {
            data.data.push([
                metric.translatedName,
                metric.user || 'all',
                metric.group || 'all',
                metric.distrib === 'gender' ? 'yes' : 'all',
                metric.distrib === 'jobLevel' ? 'yes' : 'all',
                '',
                '',
                metric.type === 'stat' ? metric.value : '',
            ]);
        }

        if (metric?.type === 'pieChart') {
            metric.value?.forEach((values: any[]) => {
                values?.forEach((value: any) => {
                    data.data.push([
                        metric.translatedName,
                        metric.user || 'all',
                        metric.group || 'all',
                        metric.distrib === 'gender' ? getMetricMessage(value.metric.gender) : 'all',
                        metric.distrib === 'jobLevel' ? getMetricMessage(value.metric.jobLevel) : 'all',
                        getMetricMessage(value.id),
                        '',
                        value.value,
                    ]);
                });
            });
        }

        if (metric?.type === 'lineChart') {
            metric.value?.forEach((value: any) => {
                value?.data?.forEach((datum: any) => {
                    data.data.push([
                        metric.translatedName,
                        metric.user || 'all',
                        metric.group || 'all',
                        metric.distrib === 'gender' ? getMetricMessage(value.metric.gender) : 'all',
                        metric.distrib === 'jobLevel' ? getMetricMessage(value.metric.jobLevel) : 'all',
                        getMetricMessage(value.id),
                        datum.x,
                        datum.y,
                    ]);
                });
            });
        }

        if (metric?.type === 'barChart') {
            metric.value?.forEach((value: any) => {
                data.data.push([
                    metric.translatedName,
                    metric.user || 'all',
                    metric.group || 'all',
                    metric.distrib === 'gender' ? getMetricMessage(value.metric.gender) : 'all',
                    metric.distrib === 'jobLevel' ? getMetricMessage(value.metric.jobLevel) : 'all',
                    getMetricMessage(value.badge),
                    '',
                    '',
                    value.lvl1,
                    value.lvl2,
                    value.lvl3,
                ]);
            });
        }
    });

    return `data:text/csv;charset=utf-8,\uFEFF${Papa.unparse(data)}`;
}

const getMetricMessage = (value: string) =>
    metricsMessages.get(value) ?
        window.reactIntl?.formatMessage(metricsMessages.get(value)!) :
        value;

// Store helpers

export const getReportingMetricsState = (state: MainReducerState) => state.reporting.metrics;
export const getReportingExportState = (state: MainReducerState) => state.reporting.reportingExport;
