import { combineReducers } from 'redux';

import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { ContactsState, contactsReducer } from '../actions/contacts';
import { AppointmentsState, appointmentsReducer } from '../actions/appointments';
import { UsersState, usersReducer } from '../actions/users';
import { UserGroupsState, userGroupsReducer } from '../actions/userGroups';
import { PacksState, packsReducer } from '../actions/packs';
import { ProgramsState, programsReducer } from '../actions/programs';
import { LibraryState, libraryReducer } from '../actions/library';
import { StaticPagesState, staticPagesReducer } from '../actions/staticPages';
import { TasksState, tasksReducer } from '../actions/tasks';
import { JobOffersState, jobOffersReducer } from '../actions/jobOffers';
import { StatsState, statsReducer } from '../actions/stats';
import { UiState, uiReducer } from '../actions/ui';
import { ReportingState, reportingReducer } from '../actions/reporting';
import { LogsState, logsReducer } from '../actions/logs';
import { NotificationsState, notificationsReducer } from '../actions/notifications';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly contacts: ContactsState;
    readonly cookies: CookiesState;
    readonly appointments: AppointmentsState;
    readonly users: UsersState;
    readonly userGroups: UserGroupsState;
    readonly packs: PacksState;
    readonly programs: ProgramsState;
    readonly library: LibraryState;
    readonly staticPages: StaticPagesState;
    readonly tasks: TasksState;
    readonly jobOffers: JobOffersState;
    readonly stats: StatsState;
    readonly ui: UiState;
    readonly logs: LogsState;
    readonly notifications: NotificationsState;
    readonly reporting: ReportingState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    contacts: contactsReducer,
    cookies: cookiesReducer,
    appointments: appointmentsReducer,
    users: usersReducer,
    userGroups: userGroupsReducer,
    packs: packsReducer,
    programs: programsReducer,
    library: libraryReducer,
    staticPages: staticPagesReducer,
    tasks: tasksReducer,
    jobOffers: jobOffersReducer,
    stats: statsReducer,
    ui: uiReducer,
    logs: logsReducer,
    notifications: notificationsReducer,
    reporting: reportingReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data?: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
