// tslint:disable: jsx-no-lambda
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Line, Serie } from '@nivo/line';

import { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';
import { nivoTheme, nivoColors } from '../../helpers/styles';
import { Panel } from '../../types';
import { DateTime } from 'luxon';

interface LineChartProps {
    data?: Serie[];
    panel: Panel;
    width: number;
    height: number;
    xScaleMinMax?: [Date, Date];
}

const LineChart: FC<LineChartProps> = ({ panel, data, width, height, xScaleMinMax }) => {
    const { formatNumber, formatMessage } = useIntl();
    const areYValuesPercentages = panel.yaxes?.[0]?.format === 'percent';

    return data && width && height ? (
        <Line
            margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
            width={width}
            height={height}
            data={data}
            theme={nivoTheme}
            colors={['#52C41A'].concat(nivoColors)}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                min: xScaleMinMax?.[0] || 'auto',
                max: xScaleMinMax?.[1] || 'auto',
            }}
            xFormat={(datum) => (datum as Date).toLocaleDateString('fr', {
                day: 'numeric',
                month: 'numeric',
            })}
            yFormat={(datum) => areYValuesPercentages ?
                formatNumber(datum as number / 100, { style: 'percent', maximumFractionDigits: 1 }) :
                `${datum}`
            }
            yScale={{
                type: 'linear',
                stacked: panel.stack,
                min: parseInt(panel.yaxes?.[0]?.min || 'NaN', 10) ? parseInt(panel.yaxes?.[0]?.min!, 10) : undefined,
                max: parseInt(panel.yaxes?.[0]?.max || 'NaN', 10) ? parseInt(panel.yaxes?.[0]?.max!, 10) : undefined,
            }}
            axisLeft={{
                legendOffset: 12,
                format: (datum) => areYValuesPercentages ?
                    formatNumber(datum as number / 100, {style: 'percent'}) :
                    `${datum}`,
                legend: metricsMessages.get(panel.yaxes?.[0]?.label || '') ?
                    formatMessage(metricsMessages.get(panel.yaxes?.[0]?.label!)!) :
                    panel.yaxes?.[0]?.label,
                tickValues: areYValuesPercentages ? [0, 20, 40, 60, 80, 100] : undefined,
            }}
            gridYValues={areYValuesPercentages ? [0, 20, 40, 60, 80, 100] : undefined}
            axisBottom={{
                format: (datum) => (datum as Date).toLocaleDateString('fr', {
                    day: 'numeric',
                    month: 'numeric',
                }),
                legendOffset: -12,
                tickValues: width < 350 ? 4 : undefined,
            }}
            lineWidth={2}
            curve="monotoneX"
            enablePoints={
                width < 350 ?
                    false :
                    (
                        xScaleMinMax ?
                            (DateTime.fromJSDate(xScaleMinMax?.[1]).diff(DateTime.fromJSDate(xScaleMinMax[0]), 'days').toObject().days || 11) < 10 :
                            undefined
                    )
            }
            // pointSize={14}
            // pointBorderWidth={2}
            // pointBorderColor="#52C41A"
            // pointColor="#fff"
            tooltipFormat={(datum) => areYValuesPercentages ?
                formatNumber(datum as number / 100, { style: 'percent' }) :
                `${datum}`}
            sliceTooltip={({ slice }) => (
                <div
                    style={{
                        background: 'white',
                        padding: '5px 9px',
                        borderRadius: '2px',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                    }}
                >
                    <div>{slice.points[0]?.data.x &&
                        (slice.points[0]?.data.x as Date).toLocaleDateString('fr', {
                            day: 'numeric',
                            month: 'numeric',
                            hour: 'numeric',
                        })
                    }</div>
                    {slice.points.map((point) => (
                        <div
                            key={point.id}
                            style={{
                                color: point.serieColor,
                                padding: '3px 0',
                            }}
                        >
                            <strong>
                                {metricsMessages.get(`${point.serieId}`) ?
                                    formatMessage(metricsMessages.get(`${point.serieId}`)!) :
                                    point.serieId
                                }
                            </strong> {point.data.yFormatted}
                        </div>
                    ))}
                </div>
            )}
            enableSlices="x"
        />
    ) : null;
};

export default LineChart;
