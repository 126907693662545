import { DeviceType } from '../store/api/apiTypes';

export interface UpdateNotificationPayload {
    deviceType: DeviceType;
    deviceToken: string;
}

declare global {
    interface Window {
        webkit: any;
        updateNotificationInfo: (data: UpdateNotificationPayload) => void;
        Android?: {
            fetchNotificationInfo?: () => void;
        };
    }
}

// called from NotificationsMenu.tsx
export const fetchNotificationInfo = () => {
    // iOS
    window.webkit?.messageHandlers?.webActions?.postMessage('fetchNotificationInfo');

    // android
    window.Android?.fetchNotificationInfo?.();
};

// called from native app
window.updateNotificationInfo = (data) => {
    const e = new CustomEvent('updateNotificationInfo', { detail: data });
    window.dispatchEvent(e);
};
