import React, { FC, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Empty } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { Breakpoint } from 'react-socks';

import { MainReducerState } from '../../store/reducers';
import { list, LibraryState, getLibraryListState } from '../../store/actions/library';
import { ProgramTaskFamily, taskTypeMessages } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import Pagination from '../../components/Pagination';
import ListTitle from '../../components/ListTitle';
import { useQueryParams } from '../../hooks';
import { scrollToElement } from '../../helpers';
import constants from '../../config/constants';
import ListHeader from '../../components/ListHeader';
import genericMessages from '../../locale/genericMessages';
import { IconPlus } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import CardSkeleton from '../../components/CardSkeleton';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import { getRoute, RoutePathName } from '../../routes';
import DocumentListCard from '../../components/DocumentListCard';
import Filters from '../../components/Filters';
import LibraryMessages from './LibraryMessages';

interface LibraryProps {
    libraryListState: LibraryState['list'];
    listDocuments: typeof list.trigger;
}

const Library: FC<LibraryProps> = ({
    libraryListState, listDocuments,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { formatMessage } = useIntl();
    const listRef = useRef(null);
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const filterParam = urlParams.get('filter');
    const searchParam = urlParams.get('search');
    const currentPage = pageParam !== null ?
        (parseInt(pageParam, 10) || 1) :
        1;
    const currentFilter = filterParam || '';
    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('page', `${page || 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeFilter = (filter: string) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('filter', `${filter}`);
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSearch = (search?: string) => {
        scrollToElement(listRef.current, 100);
        if (search) {
            urlParams.set('search', search);
        } else {
            urlParams.delete('search');
        }
        urlParams.delete('page');
        urlParams.delete('filter');
        urlParams.delete('sort');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.networkingActions),
            },
        });
    };
    const headerContent = (
        <>
            <ListTitle
                count={libraryListState.data?.totalCount}
            >
                <FormattedMessage {...LibraryMessages.title} />
            </ListTitle>
        </>
    );
    const filterOptions = [
        ...[{
            value: '',
            message: LibraryMessages.categories,
        }],
        ...Object.keys(ProgramTaskFamily).map((key: any) => ({
            value: key,
            message: taskTypeMessages.get(key),
        })),
    ];

    useEffect(() => {
        listDocuments({
            page: currentPage - 1,
            pageSize: constants.PAGE_SIZE,
            ...(currentFilter &&
                { family: currentFilter }
            ),
            ...(searchParam !== undefined ? { search: searchParam } : {}),
        });
    }, [listDocuments, currentPage, currentFilter, searchParam]);

    return (
        <section id="networking-actions">
            <Seo title={formatMessage(LibraryMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <ListHeader>
                <Breakpoint xl up>
                    <div className="flex flex-between">
                        {headerContent}
                    </div>
                </Breakpoint>
                <Breakpoint lg down>
                    {headerContent}
                </Breakpoint>

                <Filters
                    onChangeSearch={onChangeSearch}
                    onChangeFilter={onChangeFilter}
                    filterMultiple={true}
                    filterValue={currentFilter}
                    filterOptions={filterOptions}
                    searchValue={searchParam || undefined}
                />
            </ListHeader>
            {!libraryListState.loading && !!libraryListState.data?.items.length && (
                <>
                    <BasicList ref={listRef} className="document-list">
                        {libraryListState.data?.items.map((document) => (
                            <li key={document.id}>
                                <DocumentListCard document={document} />
                            </li>
                        ))}
                    </BasicList>
                    {libraryListState.data?.pageCount > 1 && (
                        <Pagination
                            current={currentPage}
                            onChange={onPageSelect}
                            pageSize={constants.PAGE_SIZE}
                            showSizeChanger={false}
                            total={libraryListState.data?.totalCount}
                        />
                    )}
                </>
            )}
            {!libraryListState.loading && !libraryListState.data?.items.length && (
                <Empty />
            )}
            {libraryListState.loading && (
                <BasicList ref={listRef}>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                </BasicList>
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    libraryListState: getLibraryListState(state),
});

export default connect(
    mapStateToProps,
    {
        listDocuments: list.trigger,
    },
)(Library);
