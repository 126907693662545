import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';

import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import genericMessages from '../../locale/genericMessages';

const messages = defineMessages({
    notFound: {
        id: 'error_page.not_found',
        defaultMessage: 'Nous sommes désolés, la page que vous cherchez n\'existe pas.',
        description: 'Error page > Not found',
    },
    invalidParameters: {
        id: 'error_page.invalid_parameters',
        defaultMessage: 'Nous sommes désolés, les informations envoyées ne sont pas valides.',
        description: 'Error page > Invalid parameters',
    },
    forbidden: {
        id: 'error_page.forbidden',
        defaultMessage: 'Nous sommes désolés, vous n\'êtes pas autorisé à accéder à cette ressource.',
        description: 'Error page > Forbidden',
    },
    unauthorized: {
        id: 'error_page.unauthorized',
        defaultMessage: 'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.',
        description: 'Error page > Unauthorized',
    },
    returnHome: {
        id: 'error_page.return_home',
        defaultMessage: 'Retourner à l\'accueil',
        description: 'Error page > Return home button',
    },
});

const ErrorPage: FC = () => {
    const { formatMessage } = useIntl();
    const { 0: param } = useParams();
    let statusCode = parseInt(param?.substring(1) || '', 10);
    let status: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = formatMessage(genericMessages.error);
    let subTitle: ResultProps['subTitle'] = formatMessage(genericMessages.defaultError);

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        status = '500';
        title = `${statusCode}`;
    } else if (statusCode === 403 || statusCode === 401) {
        status = '403';
        title = statusCode;
        subTitle = statusCode === 403 ?
            formatMessage(messages.forbidden) :
            formatMessage(messages.unauthorized);
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = formatMessage(messages.invalidParameters);
    } else {
        status = '404';
        title = 404;
        subTitle = formatMessage(messages.notFound);
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={(
                    <ButtonLink
                        to={getRoute(RoutePathName.home)}
                        type="primary"
                    >
                        <FormattedMessage {...messages.returnHome} />
                    </ButtonLink>
                )}
            />
        </>
    );
};

export default ErrorPage;
