import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M31.946 6.397l.005.064.001.068v4.612c.064.134.064.269 0 .336v13.189c0 1.884-1.832 3.297-4.107 3.297-2.276 0-4.108-1.48-4.108-3.297 0-1.884 1.832-3.298 4.108-3.298 1.137 0 2.085.337 2.844.875V12.217c-.146.04-.208.057-.223.06a.375.375 0 01.051-.016l.028-.009.075-.022.028-.009.058-.018c-.071.015-1.988.558-14.49 4.119v12.38c0 1.885-1.833 3.298-4.108 3.298C9.833 32 8 30.52 8 28.703c0-1.884 1.833-3.297 4.108-3.297 1.138 0 2.149.336 2.844.942.025-2.038.035-3.816.038-5.367v-1.369c-.008-3.81-.066-6.052-.071-7.389v-.324c.004-1.288.081-1.575.362-1.706l.046-.02.05-.017.08-.025c7.486-1.992 11.579-3.167 13.815-3.727l.275-.068c2.036-.497 2.356-.414 2.4.06zM6.697 0c3.525.648 5.992 3.168 7.261 7.488.212.576-.423 1.152-.987.864l-5.78-2.736v9.576c.07 3.744-7.191 3.744-7.191 0 0-2.52 3.595-3.6 5.851-2.232.005-.844.008-1.633.008-2.37V9.52c-.003-2.862-.03-4.86-.033-6.258v-.625c.008-2.083.106-2.51.532-2.611L6.42.015c.042-.007.088-.012.136-.015h.141z"/>
    </svg>
);

const IconMusical: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-musical${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconMusical;
