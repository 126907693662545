import React, { FC, useState, useEffect, ReactElement, Fragment } from 'react';
import { Input, Radio, Button, Checkbox } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Form, { FormProps } from 'antd/lib/form';

import {
    ProgramTaskSurveyQuestion, ProgramTaskSurvey, SurveyAnswerType, SurveyResponses, SurveySubTaskType,
} from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import Select from '../Select';
import CustomSwitch from '../CustomSwitch';
import { getRoute, RoutePathName } from '../../routes';
import ProgramTaskDetailsMessages from '../../pages/programTaskDetails/ProgramTaskDetailsMessages';
import { FieldData } from '../../types';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface SurveyProps {
    data?: ProgramTaskSurvey;
    surveySubType?: SurveySubTaskType;
    initialValues?: SurveyResponses;
    readOnly?: boolean;
    loading?: boolean;
    onSubmit: FormProps['onFinish'];
    requiredCount?: number;
}

const Survey: FC<SurveyProps> = ({ data, readOnly, initialValues, loading, onSubmit, requiredCount, surveySubType }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [isRequiredCountErrorVisible, setIsRequiredCountErrorVisible] = useState(false);
    const onSubmitForm: FormProps['onFinish'] = (values) => {
        if (requiredCount && Object.values(values).filter((v) => v !== undefined).length < requiredCount) {
            setIsRequiredCountErrorVisible(true);
        } else if (typeof onSubmit === 'function') {
            const { consent, ...restValues } = values;
            onSubmit(restValues);
        }
    };
    const renderQuestion = (question: ProgramTaskSurveyQuestion) => {
        switch (question.type) {
            case SurveyAnswerType.boolean:
                return (
                    <CustomSwitch disabled={readOnly} />
                );

            case SurveyAnswerType.list:
                return question.answers.length > 3 ? (
                    <Select
                        placeholder={formatMessage(formMessages.selectPlaceholder)}
                        disabled={readOnly}
                    >
                        {question.answers.map((answer, index) => (
                            <Select.Option value={answer.values?.join('') ?? index} key={answer.values?.join('') ?? index}>
                                {answer.label}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    <Radio.Group disabled={readOnly}>
                        {question.answers.map((answer, index) => (
                            <Radio style={radioStyle} value={answer.values?.join('') ?? index} key={answer.values?.join('') ?? index}>
                                {answer.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                );

            default:
                return (
                    <Input.TextArea rows={3} readOnly={readOnly} />
                );
        }
    };

    // set answer label to field value on field change
    const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
        changedFields.forEach((field) => {
            if (Array.isArray(field.name) && !field.name.includes('consent')) {
                form.setFields([{
                    name: [...field.name.slice(0, 2), 'label'],
                    value: field.value,
                }]);
            }
        });
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={
                data?.questions.reduce((acc, questionData) => ({
                    ...acc,
                    [questionData.id]: [{
                        values: initialValues?.[questionData.id]?.[0]?.values ?? [],
                        label: initialValues?.[questionData.id]?.[0]?.label ?? '',
                    }],
                }), {})
            }
            onFieldsChange={onFieldsChange}
            onChange={setIsRequiredCountErrorVisible.bind(null, false)}
            onFinish={onSubmitForm}
            scrollToFirstError
        >
            {data?.questions?.map((question) => (
                <Form.List
                    name={question.id}
                    key={question.id}
                >
                    {(questionFields) => questionFields.map((questionField) => (
                        <Fragment key={questionField.key}>
                            <Form.Item name={[questionField.name, 'label']} hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={question.label}
                                name={[questionField.name, 'values', 0]}
                                valuePropName={question.type === SurveyAnswerType.boolean ? 'checked' : 'value'}
                                normalize={question.type === SurveyAnswerType.boolean ?
                                    (value) => `${value}` :
                                    undefined
                                }
                            >
                                {renderQuestion(question)}
                            </Form.Item>
                        </Fragment>
                    ))}
                </Form.List>
            ))}
            {surveySubType !== SurveySubTaskType.validation && (
                <Form.Item
                    name="consent"
                    valuePropName="checked"
                    rules={[{
                        required: true,
                        message: formatMessage(formMessages.requiredField),
                        validator: (_, value) => !!value ? Promise.resolve() : Promise.reject(),
                    }]}
                >
                    <Checkbox>
                        <FormattedMessage
                            {...formMessages.dataConsent}
                            values={{
                                link: (...chunks: ReactElement[]) => (
                                    <Link to={getRoute(RoutePathName.privacyPolicy)} target="_blank">
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </Checkbox>
                </Form.Item>
            )}
            {!readOnly && (
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                (surveySubType !== SurveySubTaskType.validation && !form.getFieldValue('consent'))
                                ||
                                (
                                    form &&
                                    (
                                        !form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    )
                                )
                            }
                            loading={loading}
                        >
                            <FormattedMessage
                                {...(
                                    surveySubType === SurveySubTaskType.basic ?
                                        ProgramTaskDetailsMessages.surveyBasicSubmit :
                                        ProgramTaskDetailsMessages.surveyValidationSubmit
                                )}
                            />
                        </Button>
                    )}
                </Form.Item>
            )}
            {isRequiredCountErrorVisible && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">
                        <FormattedMessage {...formMessages.minRequiredCountError} values={{ count: requiredCount }} />
                    </div>
                </div>
            )}
        </Form>
    );
};

export default Survey;
