import React, { FC, useState, useEffect } from 'react';
import { useIntl, FormattedMessage, FormattedList } from 'react-intl';
import { Collapse, Typography } from 'antd';

import '../../assets/styles/IntelligenceSurvey.less';

import {
    SurveyResponses, IntelligenceSurveyMessage, SurveyAnswerIntelligence,
    surveyAnswerIntelligenceMessages, SupportedLanguage,
} from '../../store/api/apiTypes';

import SurveyMessages from './SurveyMessages';
import { IntelligenceSurveyIcons } from '../icons';

const intelligenceValues = Object.values(SurveyAnswerIntelligence);
const scoreInitialState = intelligenceValues.reduce((result, intelligence) => ({ ...result, [intelligence]: 0 }), {});

const getMessages = async (scores: Scores, language?: SupportedLanguage) => {
    if (!language) {
        return undefined;
    }

    try {
        return await Promise.all(scores.slice(0, 3).map((score) => {
            const data = import(`../../assets/data/intelligence/${language}/${score.intelligence}.json`);
            return data;
        }));
    } catch (error) {
        console.log('[IntelligenceSurveyResults.getMessages]', error);
        return undefined;
    }
};

type Scores = Array<{
    intelligence: SurveyAnswerIntelligence;
    score: number;
}>;

const getScore = (responses?: SurveyResponses<SurveyAnswerIntelligence>) => {
    if (!responses) {
        return undefined;
    }

    const scoreObject = Object.keys(responses).reduce<Record<SurveyAnswerIntelligence, number>>((result, questionId) => {
        const questionValues = responses[questionId][0]?.values?.filter((v) => intelligenceValues.includes(v));
        questionValues?.forEach((intelligence) => {
            result[intelligence] = result[intelligence] ?
                result[intelligence] + 1 :
                1;
        });

        return result;
    }, scoreInitialState as unknown as Record<SurveyAnswerIntelligence, number>);

    const scoreArray = (Object.keys(scoreObject) as SurveyAnswerIntelligence[]).reduce((result, intelligence) =>
        [...result, { intelligence, score: scoreObject[intelligence] }],
        [] as unknown as Array<{ intelligence: SurveyAnswerIntelligence; score: number }>);
    const sortedScoreArray = scoreArray.sort((a, b) => b.score - a.score);

    return sortedScoreArray;
};

interface IntelligenceSurveyResultsProps {
    responses?: SurveyResponses<SurveyAnswerIntelligence>;
    language?: SupportedLanguage;
}

const IntelligenceSurveyResults: FC<IntelligenceSurveyResultsProps> = ({ language, responses }) => {
    const [score, setScore] = useState<Scores>();
    const [messages, setMessages] = useState<IntelligenceSurveyMessage[]>();
    const { formatMessage } = useIntl();

    useEffect(() => {
        setScore(getScore(responses));
    }, [responses]);

    useEffect(() => {
        if (score) {
            (async () => {
                const msgs = await getMessages(score, language);
                setMessages(msgs);
            })();
        } else {
            setMessages(undefined);
        }
    }, [score, language]);

    return (
        <div id="intelligence-results">
            <Typography.Paragraph strong>{formatMessage(SurveyMessages.tricamResultsTitle)}</Typography.Paragraph>
            <div className="intelligence-scores mb-32">
                {(Object.keys(SurveyAnswerIntelligence) as Array<keyof typeof SurveyAnswerIntelligence>).slice(0, 4).map((key) => (
                    <div key={key} className="score">
                        <div className="score-title">
                            <FormattedMessage {...surveyAnswerIntelligenceMessages.get(SurveyAnswerIntelligence[key])} />
                        </div>
                        <div className="score-value">
                            {score?.find((scoreItem) =>
                                scoreItem.intelligence === SurveyAnswerIntelligence[key],
                            )?.score ?? 0}
                        </div>
                    </div>
                ))}
            </div>
            <div className="intelligence-scores mb-32">
                {(Object.keys(SurveyAnswerIntelligence) as Array<keyof typeof SurveyAnswerIntelligence>).slice(4).map((key) => (
                    <div key={key} className="score">
                        <div className="score-title">
                            <FormattedMessage {...surveyAnswerIntelligenceMessages.get(SurveyAnswerIntelligence[key])} />
                        </div>
                        <div className="score-value">
                            {score?.find((scoreItem) =>
                                scoreItem.intelligence === SurveyAnswerIntelligence[key],
                            )?.score ?? 0}
                        </div>
                    </div>
                ))}
            </div>
            <p id="intelligence-profile">
                {score && (
                    <FormattedMessage
                        {...SurveyMessages.intelligenceProfile}
                        values={{
                            results: (
                                <FormattedList
                                    type="conjunction"
                                    value={messages ? messages.map(({ type }) => type ? (
                                        <FormattedMessage
                                            key={type}
                                            {...surveyAnswerIntelligenceMessages.get(type)}
                                        />
                                    ) : '') : []}
                                />
                            ),
                        }}
                    />
                )}
            </p>
            <Collapse className="survey-collapse" ghost accordion>
                {messages?.map((message, index) => {
                    const Icon = message.type ? IntelligenceSurveyIcons.get(message.type) : null;
                    return (
                        <Collapse.Panel
                            header={(
                                <>
                                    {Icon && <Icon style={{ marginRight: '0.75rem', fontSize: '2rem' }} />}
                                    {message.title}
                                </>
                            )}
                            key={index}
                        >
                            <p dangerouslySetInnerHTML={{ __html: message.description || '' }} />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default IntelligenceSurveyResults;
