import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Form, Input, Row, Col, Divider, Popconfirm } from 'antd';
import { FormProps } from 'antd/lib/form';
import moment, { Moment } from 'moment';

import { User, Gender, genderMessages, JobLevel, jobStatusMessages, RoleSlug, rolesMessages, JobInfo } from '../../../store/api/apiTypes';

import formMessages from '../../../locale/formMessages';
import Select from '../../../components/Select';
import clientMessages from '../../../locale/clientMessages';
import AccountMessages from '../../account/AccountMessages';
import GroupsFilterSelect from '../../../components/GroupsFilterSelect';
import UsersAutoComplete from '../../../components/UsersAutoComplete';
import EnumMessage from '../../../components/EnumMessage';
import CreateUserMessages from './CreateUserMessages';
import ClientUsersMessages from './ClientUsersMessages';
import PhoneInput from '../../../components/PhoneInput';

interface JobInfoFormData extends Omit<JobInfo, 'entryOnDutyDate'> {
    entryOnDutyDate?: Moment;
}

interface UserFormData extends Omit<User, 'birthday' | 'job' | 'role'> {
    birthday?: Moment;
    job: JobInfoFormData;
    role?: RoleSlug;
}

interface ClientUserEditFormProps {
    user: User;
    onSubmit: (values: User) => void;
    onCancel: () => void;
    onRemove: () => void;
}

const ClientUserEditForm: FC<ClientUserEditFormProps> = ({ user, onSubmit, onCancel, onRemove }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    // ---------------------------------------
    // On init, set form initial values

    useEffect(() => {
        const userFormDatas: UserFormData = {
            ...user,
            birthday: user.birthday ? moment(user.birthday) : undefined,
            role: user.role?.slug ?? undefined,
            job: {
                ...user.job,
                entryOnDutyDate: user.job?.entryOnDutyDate ? moment(user.job?.entryOnDutyDate) : undefined,
            },
        };

        form.setFieldsValue(userFormDatas);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On form submit

    const onFormValidSubmit: FormProps<User>['onFinish'] = (values) => {
        onSubmit(values);
    };

    // ---------------------------------------
    // Render

    return (
        <Form<User>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFormValidSubmit}
        >
            <Card className="form-card">

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.lastNameLabel)}
                            name="lastName"
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(CreateUserMessages.lastNamePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.firstNameLabel)}
                            name="firstName"
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(CreateUserMessages.firstNamePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.genderLabel)}
                            name="gender"
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(CreateUserMessages.genderPlaceholder)}
                                showArrow
                            >
                                {Object.values(Gender).map((type) => (
                                    <Select.Option value={type} key={type}>
                                        <EnumMessage map={genderMessages} value={type} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(AccountMessages.phoneLabel)}
                            name="phone"
                        >
                            <PhoneInput
                                placeholder={formatMessage(AccountMessages.phonePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.emailLabel)}
                            name="email"
                            rules={[
                                requiredRule,
                                {type: 'email', message: formatMessage(formMessages.invalidEmail)},
                            ]}
                        >
                            <Input
                                type="email"
                                placeholder={formatMessage(CreateUserMessages.emailPlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.profilLabel)}
                            name={['role']}
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(CreateUserMessages.profilPlaceholder)}
                                showArrow
                            >
                                {[RoleSlug.user, RoleSlug.manager, RoleSlug.headHR].map((role) => (
                                    <Select.Option value={role} key={role}>
                                        <FormattedMessage {...rolesMessages.get(role)} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(CreateUserMessages.groupsLabel)}
                            name="groups"
                        >
                            <GroupsFilterSelect
                                placeholder={formatMessage(CreateUserMessages.groupsPlaceholder)}
                                size="large"
                                multi={true}
                                filters={{
                                    filters: [{
                                        name: 'organization',
                                        value: user.organization?.id,
                                    }],
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => getFieldValue(['role']) && getFieldValue(['role']) !== RoleSlug.user && (
                        <Form.Item
                            label={formatMessage(CreateUserMessages.scopeGroupsLabel)}
                            name={['scope', 'groups']}
                            rules={[requiredRule]}
                        >
                            <GroupsFilterSelect
                                placeholder={formatMessage(CreateUserMessages.scopeGroupsPlaceholder)}
                                size="large"
                                multi={true}
                                filters={{
                                    filters: [{
                                        name: 'organization',
                                        value: user.organization?.id,
                                    }],
                                }}
                            />
                        </Form.Item>
                    )}
                </Form.Item>

                <Divider plain orientation="left"><FormattedMessage {...AccountMessages.infoCompany} /></Divider>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(AccountMessages.infoCompanyGroup)}
                            name={['company', 'group']}
                            rules={[requiredRule]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={formatMessage(AccountMessages.infoCompanyFirm)}
                            name={['company', 'name']}
                            rules={[requiredRule]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item dependencies={[['role']]} noStyle>
                    {({ getFieldValue }) => getFieldValue(['role']) && getFieldValue(['role']) === RoleSlug.user && (
                        <>
                            <Divider plain orientation="left"><FormattedMessage {...AccountMessages.infoCurrentPostJob} /></Divider>

                            <Form.Item
                                label={formatMessage(CreateUserMessages.statusLabel)}
                                name={['job', 'level']}
                                rules={[requiredRule]}
                            >
                                <Select
                                    placeholder={formatMessage(CreateUserMessages.statusPlaceholder)}
                                    showArrow
                                >
                                    {Object.values(JobLevel).map((level) => (
                                        <Select.Option value={level} key={level}>
                                            <EnumMessage map={jobStatusMessages} value={level} />
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={formatMessage(CreateUserMessages.jobPositionLabel)}
                                name={['job', 'position']}
                                rules={[requiredRule]}
                            >
                                <Input
                                    placeholder={formatMessage(CreateUserMessages.jobPositionPlaceholder)}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form.Item>

                <Form.Item dependencies={[['role']]} noStyle>
                    {({ getFieldValue }) => getFieldValue(['role']) && [RoleSlug.user, RoleSlug.manager].includes(getFieldValue(['role'])) && (
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label={formatMessage(CreateUserMessages.managerLabel)}
                                    name={['job', 'manager']}
                                    rules={[requiredRule]}
                                >
                                    <UsersAutoComplete
                                        placeholder={formatMessage(CreateUserMessages.managerPlaceholder)}
                                        size="middle"
                                        filters={{
                                            filters: [{
                                                name: 'organization',
                                                value: user.organization?.id,
                                            }],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={formatMessage(CreateUserMessages.headHRLabel)}
                                    name={['job', 'hrRepresentative']}
                                    rules={[requiredRule]}
                                >
                                    <UsersAutoComplete
                                        placeholder={formatMessage(CreateUserMessages.managerPlaceholder)}
                                        size="middle"
                                        filters={{
                                            filters: [{
                                                name: 'organization',
                                                value: user.organization?.id,
                                            }],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Form.Item>

                <Divider />

                <div className="flex-between">
                    <div>
                        <Button
                            type="ghost"
                            onClick={onCancel}
                        >
                            <FormattedMessage {...clientMessages.cancel} />
                        </Button>
                    </div>
                    <div>
                        <Popconfirm
                            title={formatMessage(ClientUsersMessages.removeConfirm)}
                            onConfirm={onRemove}
                        >
                            <Button
                                type="ghost"
                                danger
                            >
                                <FormattedMessage {...ClientUsersMessages.deleteForever} />
                            </Button>
                        </Popconfirm>
                        &nbsp;&nbsp;
                        <Form.Item shouldUpdate={true} className="inline">
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    <FormattedMessage {...ClientUsersMessages.editUser} />
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Card>
        </Form>
    );
};

export default ClientUserEditForm;
