import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M30.4 0c-.96 0-1.6.64-1.6 1.6v3.2h-6.88c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6h-4.16c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6H3.2V1.6C3.2.64 2.56 0 1.6 0 .64 0 0 .64 0 1.6v28.8c0 .96.64 1.6 1.6 1.6.96 0 1.6-.64 1.6-1.6v-3.2h3.68c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h4.16c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h6.88v3.2c0 .96.64 1.6 1.6 1.6.96 0 1.6-.64 1.6-1.6V1.6c0-.96-.64-1.6-1.6-1.6Zm-1.6 24h-6.88c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6h-4.16c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6H3.2v-6.4h6.88c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h4.16c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h3.68V24Zm0-9.6h-3.68c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6h-4.16c-.48-.96-1.6-1.6-2.72-1.6s-2.24.64-2.72 1.6H3.2V8h3.68c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h4.16c.48.96 1.6 1.6 2.72 1.6s2.24-.64 2.72-1.6h6.88v6.4Z"/>
    </svg>
);

const IconMathematical: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-mathematical${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconMathematical;
