import { Layout } from 'antd';
import React, { FC } from 'react';

import { LayoutContextProvider } from '../context/LayoutContext';
import PublicHeader from './PublicHeader';
import Footer from './Footer';

const PublicLayout: FC = ({ children }) => (
    <LayoutContextProvider>
        <Layout id="main-layout">
            <PublicHeader />
            <div className="container" id="main-content-wrapper">
                <Layout.Content id="main-content">
                    {children}
                </Layout.Content>
            </div>
            <Footer />
        </Layout>
    </LayoutContextProvider>
);

export default PublicLayout;
