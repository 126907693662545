import React, { FC, useEffect, useState, ReactNode, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Card, Row, Col, Input, Divider, Spin, Upload, Space, Avatar, Button, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { RcFile, UploadProps } from 'antd/lib/upload';
import dayjs from 'dayjs';

import {
    JobOffer, JobOfferType, jobOfferTypeMessages,
} from '../store/api/apiTypes';

import JobOfferCreateMessages from '../pages/jobOfferCreate/JobOfferCreateMessages';
import formMessages from '../locale/formMessages';
import Select from './Select';
import DatePicker from './DatePicker';
import { IconCalendar } from './icons';
import { jobOccupationMessages } from '../locale/genericMessages';
import AccountMessages from '../pages/account/AccountMessages';

interface JobOfferFormProps extends FormProps {
    jobOffer?: JobOffer;
    requiredResponseDate?: boolean;
    submit: ReactNode;
    isEditing?: boolean;
}

const JobOfferForm: FC<JobOfferFormProps> = ({
    jobOffer, form, onFinish, submit, isEditing, requiredResponseDate, ...props
}) => {
    const { formatMessage } = useIntl();
    const [, forceUpdate] = useState(false);
    const [avatarFile, setAvatarFile] = useState<RcFile | undefined>();
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(isEditing ? jobOffer?.documents?.[0]?.url : undefined);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error(formatMessage(AccountMessages.formAvatarFileTypeError));
            return false;
        }

        if (file.size / 1024 > 500) {
            message.error(formatMessage(AccountMessages.formAvatarFileSizeError));
            return false;
        }

        setAvatarFile(file);
        setAvatarUrl(URL.createObjectURL(file));

        return false;
    };
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (typeof onFinish === 'function') {
            onFinish({
                ...values,
                fakeFileToTriggerFieldChange: undefined,
                responseDate: values.responseDate.toISOString(),
                documents: avatarFile ? [
                    {
                        file: avatarFile,
                        description: values.documents[0].description,
                    },
                ] : undefined,
            });
        }
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    if (isEditing && !jobOffer) {
        return <Spin />;
    }

    return (
        <Form<Partial<JobOffer>>
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={isEditing ? {
                ...jobOffer,
                responseDate: dayjs(jobOffer?.responseDate),
                documents: jobOffer?.documents[jobOffer?.documents.length - 1] ?
                    [{
                        url: jobOffer?.documents[jobOffer?.documents.length - 1].url,
                        description: jobOffer?.documents[jobOffer?.documents.length - 1].description,
                    }] : [{
                        url: '',
                        description: '',
                    }],
            } : {
                responseDate: dayjs(),
                documents: [{
                    url: '',
                    description: '',
                }],
            }}
            onFinish={onSubmit}
            scrollToFirstError
            {...props}
        >
            <Card className="form-card">
                <Form.Item
                    label={formatMessage(JobOfferCreateMessages.titleLabel)}
                    name="title"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(JobOfferCreateMessages.titlePlaceholder)}
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(JobOfferCreateMessages.companyLabel)}
                            name={['company', 'name']}
                        >
                            <Input
                                placeholder={formatMessage(JobOfferCreateMessages.companyPlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(JobOfferCreateMessages.applyDateLabel)}
                            name="responseDate"
                            rules={requiredResponseDate ? [requiredRule] : undefined}
                        >
                            <DatePicker
                                placeholder={formatMessage(JobOfferCreateMessages.applyDatePlaceholder)}
                                suffixIcon={<IconCalendar />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={formatMessage(formMessages.addressLabel)}
                    name={['company', 'address', 'street']}
                >
                    <Input placeholder={formatMessage(formMessages.addressPlaceholder)} />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} md={isEditing ? 12 : 24}>
                        <Form.Item
                            label={formatMessage(JobOfferCreateMessages.typeLabel)}
                            name="type"
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(JobOfferCreateMessages.typePlaceholder)}
                            >
                                {Object.values(JobOfferType).map((type) => ((
                                    <Select.Option value={type} key={type}>
                                        <FormattedMessage {...jobOfferTypeMessages.get(type)} />
                                    </Select.Option>
                                )))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={isEditing ? 12 : 24}>
                        <Form.Item
                            label={formatMessage(JobOfferCreateMessages.originLabel)}
                            name="origin"
                        >
                            <Input
                                placeholder={formatMessage(JobOfferCreateMessages.originPlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(JobOfferCreateMessages.referenceLabel)}
                            name="reference"
                        >
                            <Input
                                placeholder={formatMessage(JobOfferCreateMessages.referencePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(AccountMessages.jobOccupationLabel)}
                            name={['job', 'occupation']}
                        >
                            <Select
                                placeholder={formatMessage(AccountMessages.jobOccupationPlaceholder)}
                                dropdownClassName="option-multiline"
                            >
                                {[...jobOccupationMessages.entries()].map(([key, value]) => ((
                                    <Select.Option value={key} key={key}>
                                        <FormattedMessage {...value} />
                                    </Select.Option>
                                )))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={formatMessage(JobOfferCreateMessages.descriptionLabel)}
                    name="description"
                >
                    <Input.TextArea
                        placeholder={formatMessage(JobOfferCreateMessages.descriptionPlaceholder)}
                        rows={3}
                    />
                </Form.Item>
                <Divider orientation="left" plain>
                    <FormattedMessage {...JobOfferCreateMessages.attachImage} />
                </Divider>
                <Form.List name="documents">
                    {(fields, _, { errors }) => (
                        <>
                            {fields.map((field) => (
                                <Fragment key={field.key}>
                                    <Form.Item name="fakeFileToTriggerFieldChange">
                                        <Upload
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                        >
                                            <Space size={16}>
                                                <Space direction="vertical">
                                                    {avatarUrl && (
                                                        <Avatar src={avatarUrl} size={128} shape="square" />
                                                    )}
                                                    <Button id="avatar" ghost>
                                                        <FormattedMessage {...JobOfferCreateMessages.imageButton} />
                                                    </Button>
                                                    <FormattedMessage {...JobOfferCreateMessages.imageHelp} tagName="small" />
                                                </Space>
                                            </Space>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(JobOfferCreateMessages.imageDescriptionLabel)}
                                        name={[field.name, 'description']}
                                    >
                                        <Input.TextArea
                                            placeholder={formatMessage(JobOfferCreateMessages.imageDescriptionPlaceholder)}
                                            rows={3}
                                        />
                                    </Form.Item>
                                </Fragment>
                            ))}

                            <Form.ErrorList errors={errors} />
                        </>
                    )}
                </Form.List>
                {isEditing && (
                    submit
                )}
            </Card>
            {!isEditing && (
                submit
            )}
        </Form>
    );
};

export default JobOfferForm;
