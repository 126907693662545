import React, { FC, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { Menu } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';

import '../assets/styles/MainMenu.less';

import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';
import { IconContacts, IconHome } from './icons';
import IconGroups from './icons/IconGroups';
import IconCompany from './icons/IconCompany';
import { hasRole } from '../helpers/security';
import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/actions/auth';
import { connect } from 'react-redux';
import { User, RoleSlug } from '../store/api/apiTypes';

const messages = defineMessages({
    dashboard: {
        id: 'menu.client.dashboard',
        defaultMessage: 'Tableau de bord',
        description: 'Menu item',
    },
    users: {
        id: 'menu.client.users',
        defaultMessage: 'Utilisateurs',
        description: 'Menu item',
    },
    groups: {
        id: 'menu.client.groups',
        defaultMessage: 'Groupes',
        description: 'Menu item',
    },
    company: {
        id: 'menu.client.company',
        defaultMessage: 'Entreprise',
        description: 'Menu item',
    },
});

interface ClientMenuProps {
    user?: User;
}

const ClientMenu: FC<ClientMenuProps> = ({user}) => {
    const { isMenuOpen, setIsMenuOpen } = useContext(LayoutContext);
    const location = useLocation();

    const menu = (
        <>
            <Menu.Item key={getRoute(RoutePathName.clientDashboard)}>
                <Link to={getRoute(RoutePathName.clientDashboard)}>
                    <IconHome />
                    <FormattedMessage {...messages.dashboard} tagName="span" />
                </Link>
            </Menu.Item>
            <Menu.Item key={getRoute(RoutePathName.clientUsers)}>
                <Link to={getRoute(RoutePathName.clientUsers)}>
                    <IconContacts />
                    <FormattedMessage {...messages.users} tagName="span" />
                </Link>
            </Menu.Item>

            {hasRole(user, [
                RoleSlug.headHR,
                RoleSlug.owner,
                RoleSlug.admin,
                RoleSlug.superAdmin,
            ]) && (
                <Menu.Item key={getRoute(RoutePathName.clientGroups)}>
                    <Link to={getRoute(RoutePathName.clientGroups)}>
                        <IconGroups />
                        <FormattedMessage {...messages.groups} tagName="span" />
                    </Link>
                </Menu.Item>
            )}

             {hasRole(user, [
                RoleSlug.owner,
                RoleSlug.admin,
                RoleSlug.superAdmin,
            ]) && (
                <Menu.Item key={getRoute(RoutePathName.clientOrganization)}>
                    <Link to={getRoute(RoutePathName.clientOrganization)}>
                        <IconCompany />
                        <FormattedMessage {...messages.company} tagName="span" />
                    </Link>
                </Menu.Item>
            )}
        </>
    );

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname, setIsMenuOpen]);

    return (
        <>
            <Breakpoint lg down>
                <div id="sidebar-wrapper" className={isMenuOpen ? 'is-open' : undefined}>
                    <Menu
                        defaultSelectedKeys={[location.pathname]}
                        id="client-menu"
                    >
                        {menu}
                    </Menu>
                </div>
            </Breakpoint>
            <Breakpoint xl up className="menu-container">
                <Menu
                    defaultSelectedKeys={[location.pathname]}
                    id="header-menu"
                    mode="horizontal"
                >
                    {menu}
                </Menu>
            </Breakpoint>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(ClientMenu);
