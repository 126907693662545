import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'create_action.title',
        defaultMessage: 'Créer une nouvelle action de networking',
        description: 'Create action page title',
    },
    what: {
        id: 'create_action.step.what',
        defaultMessage: 'Quelle action ?',
        description: 'Create action step',
    },
    who: {
        id: 'create_action.step.who',
        defaultMessage: 'Avec qui ?',
        description: 'Create action step',
    },
    when: {
        id: 'create_action.step.when',
        defaultMessage: 'Où et quand ?',
        description: 'Create action step',
    },
    whatTitle: {
        id: 'create_action.step.what.title',
        defaultMessage: 'Définissez votre action',
        description: 'Create action form',
    },
    whatTypeLabel: {
        id: 'create_action.step.what.type.label',
        defaultMessage: 'Type d\'action',
        description: 'Create action form',
    },
    whatTypePlaceholder: {
        id: 'create_action.step.what.type.placeholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Create action form',
    },
    whatParentLabel: {
        id: 'create_action.step.what.parent.label',
        defaultMessage: 'Relier à une autre action',
        description: 'Create action form',
    },
    whatNameLabel: {
        id: 'create_action.step.what.name.label',
        defaultMessage: 'Titre du rendez-vous',
        description: 'Create action form',
    },
    whatNamePlaceholder: {
        id: 'create_action.step.what.name.placeholder',
        defaultMessage: 'Saisir un titre',
        description: 'Create action form',
    },
    whatNoteLabel: {
        id: 'create_action.step.what.note.label',
        defaultMessage: 'Note',
        description: 'Create action form',
    },
    whatNotePlaceholder: {
        id: 'create_action.step.what.note.placeholder',
        defaultMessage: 'Saisir une note',
        description: 'Create action form',
    },
    whoTitle: {
        id: 'create_action.step.who.title',
        defaultMessage: 'Avec qui souhaitez-vous effectuer l’action ?',
        description: 'Create action form',
    },
    whoContactLabel: {
        id: 'create_action.step.who.contact.label',
        defaultMessage: 'Contact',
        description: 'Create action form',
    },
    whoContactPlaceholder: {
        id: 'create_action.step.who.contact.placeholder',
        defaultMessage: 'Sélectionner un contact existant',
        description: 'Create action form',
    },
    whoCreateContact: {
        id: 'create_action.step.who.create_contact',
        defaultMessage: 'Créer un contact',
        description: 'Create action form',
    },
    whoSelectExisitingContact: {
        id: 'create_action.step.who.select_contact',
        defaultMessage: 'Sélectionner un contact existant',
        description: 'Create action form',
    },
    whoTypeLabel: {
        id: 'create_action.step.who.type.label',
        defaultMessage: 'Type de contact',
        description: 'Create action form',
    },
    whoTypePlaceholder: {
        id: 'create_action.step.who.type.placeholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Create action form',
    },
    whoLastNameLabel: {
        id: 'create_action.step.who.lastname.label',
        defaultMessage: 'Nom',
        description: 'Create action form',
    },
    whoLastNamePlaceholder: {
        id: 'create_action.step.who.lastname.placeholder',
        defaultMessage: 'Nom du contact',
        description: 'Create action form',
    },
    whoFirstNameLabel: {
        id: 'create_action.step.who.firstname.label',
        defaultMessage: 'Prénom',
        description: 'Create action form',
    },
    whoFirstNamePlaceholder: {
        id: 'create_action.step.who.firstname.placeholder',
        defaultMessage: 'Prénom du contact',
        description: 'Create action form',
    },
    whoCompanyLabel: {
        id: 'create_action.step.who.company.label',
        defaultMessage: 'Entreprise',
        description: 'Create action form',
    },
    whoCompanyPlaceholder: {
        id: 'create_action.step.who.company.placeholder',
        defaultMessage: 'Nom de l\'entreprise',
        description: 'Create action form',
    },
    whoDepartmentLabel: {
        id: 'create_action.step.who.department.label',
        defaultMessage: 'Service / Département',
        description: 'Create action form',
    },
    whoDepartmentPlaceholder: {
        id: 'create_action.step.who.department.placeholder',
        defaultMessage: 'Service / Département',
        description: 'Create action form',
    },
    whoCreateContactHelp: {
        id: 'create_action.step.who.create_contact.help',
        defaultMessage: 'Vous pourrez compléter les informations du contact ultérieurement.',
        description: 'Create action form',
    },
    whoCreateContactSubmit: {
        id: 'create_action.step.who.create_contact.submit',
        defaultMessage: 'Créer le contact et continuer',
        description: 'Create action form',
    },
    whenTitle: {
        id: 'create_action.step.when.title',
        defaultMessage: 'Où et quand aura lieu cette action ?',
        description: 'Create action form',
    },
    whenTypeLabel: {
        id: 'create_action.step.when.type.label',
        defaultMessage: 'Type',
        description: 'Create action form',
    },
    whenTypePlaceholder: {
        id: 'create_action.step.when.type.placeholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Create action form',
    },
    whenMeetingAddressLabel: {
        id: 'create_action.step.when.meeting_address.label',
        defaultMessage: 'Adresse du rendez-vous',
        description: 'Create action form',
    },
    whenMeetingAddressPlaceholder: {
        id: 'create_action.step.when.meeting_address.placeholder',
        defaultMessage: 'Saisir une adresse',
        description: 'Create action form',
    },
    whenMeetingPhoneLabel: {
        id: 'create_action.step.when.meeting_phone.label',
        defaultMessage: 'Numéro de téléphone',
        description: 'Create action form',
    },
    whenMeetingPhonePlaceholder: {
        id: 'create_action.step.when.meeting_phone.placeholder',
        defaultMessage: 'Saisir un numéro de téléphone',
        description: 'Create action form',
    },
    whenMeetingVideoUrlLabel: {
        id: 'create_action.step.when.meeting_video_url.label',
        defaultMessage: 'Adresse URL de visio-conférence',
        description: 'Create action form',
    },
    whenMeetingVideoUrlPlaceholder: {
        id: 'create_action.step.when.meeting_video_url.placeholder',
        defaultMessage: 'Saisir une URL',
        description: 'Create action form',
    },
    whenDateLabel: {
        id: 'create_action.step.when.date.label',
        defaultMessage: 'Date de l\'action',
        description: 'Create action form',
    },
    whenDatePlaceholder: {
        id: 'create_action.step.when.date.placeholder',
        defaultMessage: 'Sélectionner une date',
        description: 'Create action form',
    },
    whenTimeLabel: {
        id: 'create_action.step.when.time.label',
        defaultMessage: 'Heure de début de l\'action',
        description: 'Create action form',
    },
    whenTimePlaceholder: {
        id: 'create_action.step.when.time.placeholder',
        defaultMessage: 'Sélectionner une heure',
        description: 'Create action form',
    },
    whenSubmit: {
        id: 'create_action.step.when.submit',
        defaultMessage: 'Créer l\'action de Networking',
        description: 'Create action form',
    },
    successModalTitle: {
        id: 'create_action.success_modal.title',
        defaultMessage: 'L\'action de Networking « {title} » a été créée avec succès',
        description: 'Create action success modal title',
    },
    successModalActionLink: {
        id: 'create_action.success_modal.action_link',
        defaultMessage: 'Accéder à la fiche action',
        description: 'Create action success modal action link',
    },
    successModalCompleteContactLink: {
        id: 'create_action.success_modal.contact_link',
        defaultMessage: 'Compléter la fiche contact',
        description: 'Create action success modal add contact data link',
    },
    successModalCompleteDownloadCal: {
        id: 'create_action.success_modal.cal_link',
        defaultMessage: 'Enregistrer dans votre calendrier (.ics)',
        description: 'Create action success modal download calendar link',
    },
});
