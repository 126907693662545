import api from './_client';
import { SearchPaginationQuery } from '.';
import { SurveyResponses } from './apiTypes';

export type MyTasksListPayload = SearchPaginationQuery & {
    strictRange?: boolean;
    isPeriodValid?: boolean;
};

export enum HomeListFilter {
    all = 'all',
    currentWeek = 'currentWeek',
    previousWeeks = 'previousWeeks',
    nextWeek = 'nextWeek',
}

export interface HomeListMyPayload extends MyTasksListPayload {
    currentFilter: HomeListFilter;
    programStartWeekNumber: number;
    currentWeekPage?: number;
    previousWeeksPage?: number;
    nextWeekPage?: number;
    onlyRequestForThisFilter?: Exclude<HomeListFilter, HomeListFilter.all>;
}

export interface TaskDetailsPayload {
    id: string;
}
export interface SubTaskUpdatePayload {
    taskId: string;
    subTaskId: string;
    values: {
        surveyResponses: SurveyResponses,
    };
}

export interface TaskValidatePayload {
    token: string;
}

export const listMy = (payload: MyTasksListPayload) => api.get('/me/tasks', { params: payload });

export const details = (payload: TaskDetailsPayload) => api.get(`/me/tasks/${payload.id}`);

export const validate = (payload: TaskValidatePayload) => api.post('/signedAction', payload);

export const updateSubTask = (payload: SubTaskUpdatePayload) => {
    const { taskId, subTaskId, values } = payload;

    return api.put(`/me/tasks/${taskId}/subTask/${subTaskId}`, values);
};
