import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Typography, Spin } from 'antd';

import { AuthState, getAuthState, resetPassword, resendInvitation as resendInvitationAction } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import PasswordInput from '../../components/PasswordInput';
import LoginLayout from '../../components/LoginLayout';
import ButtonLink from '../../components/ButtonLink';
import genericMessages from '../../locale/genericMessages';
import formMessages from '../../locale/formMessages';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { useQueryParams } from '../../hooks';
import LoginMessages from '../login/LoginMessages';
import ResetPasswordMessages from './ResetPasswordMessages';
import validatePasswordRules from '../../helpers/passwords';

interface ResetPasswordProps extends RouteComponentProps {
    resetResetPassword: typeof resetPassword.reset;
    resendInvitation: typeof resendInvitationAction.trigger;
    sendResetPassword: typeof resetPassword.trigger;
    authState: AuthState;
}

const ResetPassword: FC<ResetPasswordProps> = ({ authState, resetResetPassword, resendInvitation, sendResetPassword }) => {
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const queryParams = useQueryParams();
    const { formatMessage } = useIntl();
    const isFirstSetup = queryParams.get('firstSetup');
    const token = queryParams.get('token');
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        sendResetPassword({
            ...values,
            token,
        });
    };
    const onClickSendNewInvitation = () => {
        resendInvitation({ token });
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    let error = (authState.resetPasswordError || authState.resendInvitationError) ?
        <FormattedMessage {...genericMessages.defaultError} /> :
        null;

    if (authState.resetPasswordError) {
        if (isFirstSetup) {
            error = <FormattedMessage {...genericMessages.defaultError} />;
        } else if (authState.resetPasswordError?.status === 404) {
            error = <FormattedMessage {...LoginMessages.emailNotFound} />;
        } else if (authState.resetPasswordError?.status === 400) {
            error = <FormattedMessage {...formMessages.invalidEmail} />;
        } else {
            error = <FormattedMessage {...genericMessages.defaultError} />;
        }
    }

    const formContent = authState.resetPasswordSuccess ? (
        <>
            <Typography.Paragraph>
                {isFirstSetup ?
                    <FormattedMessage {...ResetPasswordMessages.firstSetupSuccessDescription} tagName="p" /> :
                    <FormattedMessage {...ResetPasswordMessages.successDescription} tagName="p" />
                }
            </Typography.Paragraph>
            <ButtonLink
                to={
                    isFirstSetup ?
                        getRoute(RoutePathName.login) :
                        getRoute(RoutePathName.home)
                }
                type="primary"
            >
                {isFirstSetup ?
                    <FormattedMessage {...ResetPasswordMessages.firstSetupSuccessButton} /> :
                    <FormattedMessage {...ResetPasswordMessages.successButton} />
                }
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                <FormattedMessage {...ResetPasswordMessages.description} tagName="p" />
            </Typography.Paragraph>
            <Form.Item
                label={formatMessage(ResetPasswordMessages.passwordLabel)}
                rules={[
                    requiredRule,
                    {
                        validator: (_, value) => {
                            if (!validatePasswordRules(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(formatMessage(formMessages.invalidPassword));
                        },
                    },
                ]}
                name="password"
                validateTrigger="onBlur"
            >
                <PasswordInput placeholder={formatMessage(ResetPasswordMessages.passwordPlaceholder)} />
            </Form.Item>
            <Form.Item>
                {error ? (
                    <div className="login-error-message">
                        <Alert
                            type="error"
                            message={error}
                            showIcon
                        />
                    </div>
                ) : null}

                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={authState.loading}
                    block
                >
                    {isFirstSetup ?
                        <FormattedMessage {...ResetPasswordMessages.firstSetupButton} /> :
                        <FormattedMessage {...ResetPasswordMessages.button} />
                    }
                </Button>
            </Form.Item>
        </>
    );

    useEffect(() => {
        if (token) {
            setIsTokenExpired(checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    useEffect(() => () => {
        resetResetPassword();
    }, [resetResetPassword]);

    return (
        <LoginLayout>
            <Seo title={formatMessage(ResetPasswordMessages.title)} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="logo" />

                {isTokenExpired === null ? // checking token expiration
                    (
                        <Spin />
                    ) : (
                        isTokenExpired ? (
                            <>
                                {authState.resendInvitationSuccess ? (
                                    <Typography.Paragraph>
                                        <FormattedMessage {...ResetPasswordMessages.sendNewInvitationSuccess} tagName="p" />
                                    </Typography.Paragraph>
                                ) : (
                                    <>
                                        <div className="login-error-message" style={{ padding: '2rem 0' }}>
                                            <Alert
                                                type="error"
                                                message={error || <FormattedMessage {...ResetPasswordMessages.expiredOrInvalidToken} />}
                                            />
                                        </div>
                                        <Button
                                            onClick={onClickSendNewInvitation}
                                            type="primary"
                                            size="large"
                                        >
                                            <FormattedMessage {...ResetPasswordMessages.sendNewInvitation} />
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography.Title level={1}>
                                    {isFirstSetup ?
                                        <FormattedMessage {...ResetPasswordMessages.firstSetupTitle} /> :
                                        <FormattedMessage {...ResetPasswordMessages.title} />
                                    }
                                </Typography.Title>
                                {formContent}
                            </>
                        )
                    )
                }
            </Form>
        </LoginLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        sendResetPassword: resetPassword.trigger,
        resetResetPassword: resetPassword.reset,
        resendInvitation: resendInvitationAction.trigger,
        resetResendInvitation: resendInvitationAction.reset,
    },
)(ResetPassword);
