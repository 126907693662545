import React, { FC, useEffect, useState, ReactNode } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Card, Row, Col, Button, Input, Divider, Spin } from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { FormProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';

import {
    Contact, AddressType, addressTypeMessages, Civility, civilityMessages, ContactType,
    contactTypeMessages,
} from '../store/api/apiTypes';
import { MainReducerState } from '../store/reducers';
import { getContactsListState, list, ContactsState } from '../store/actions/contacts';

import ContactCreateMessages from '../pages/contactCreate/ContactCreateMessages';
import formMessages from '../locale/formMessages';
import { stripUndefinedKeysFromObject, getFullName } from '../helpers';
import Select from './Select';
import PhoneInput from './PhoneInput';

interface ContactFormProps extends FormProps {
    contact?: Contact;
    createInitialValues?: Partial<Contact>;
    initialReferrer?: Contact;
    isEditing?: boolean;
    listContact: typeof list.trigger;
    listContactState: ContactsState['list'];
    submit: ReactNode;
}

const ContactForm: FC<ContactFormProps> = ({
    contact, createInitialValues, form, initialReferrer, onFinish, submit, listContact,
    listContactState, isEditing, ...props
}) => {
    const { formatMessage } = useIntl();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onContactSearch: SelectProps<string>['onSearch'] = (search) => {
        listContact(stripUndefinedKeysFromObject({ limit: 20, search: search || undefined, throttling: 300 }));
    };

    useEffect(() => {
        listContact({ limit: 20 });
    }, [listContact]);

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    if (isEditing && !contact) {
        return <Spin />;
    }

    return (
        <Form<Partial<Contact>>
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={isEditing ? {
                ...contact,
                referrer: contact?.referrer?.id,
            } : createInitialValues}
            onFinish={onFinish}
            scrollToFirstError
            {...props}
        >
            <Card className="form-card">
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.lastNameLabel)}
                            name="lastName"
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage(ContactCreateMessages.lastNamePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.firstNameLabel)}
                            name="firstName"
                        >
                            <Input
                                placeholder={formatMessage(ContactCreateMessages.firstNamePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.civilityLabel)}
                            name="civility"
                        >
                            <Select
                                placeholder={formatMessage(ContactCreateMessages.civilityPlaceholder)}
                            >
                                {Object.values(Civility).map((civility) => ((
                                    <Select.Option value={civility} key={civility}>
                                        <FormattedMessage {...civilityMessages.get(civility)} />
                                    </Select.Option>
                                )))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.typeLabel)}
                            name="type"
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(ContactCreateMessages.typePlaceholder)}
                            >
                                {Object.values(ContactType).map((type) => (
                                    <Select.Option value={type} key={type}>
                                        <FormattedMessage {...contactTypeMessages.get(type)} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={formatMessage(ContactCreateMessages.companyLabel)}
                    name={['company', 'name']}
                >
                    <Input
                        placeholder={formatMessage(ContactCreateMessages.companyPlaceholder)}
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} md={isEditing ? 12 : 24}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.jobOccupationLabel)}
                            name={['job', 'occupation']}
                        >
                            <Input
                                placeholder={formatMessage(ContactCreateMessages.jobOccupationPlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={isEditing ? 12 : 24}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.serviceLabel)}
                            name={['company', 'service']}
                        >
                            <Input
                                placeholder={formatMessage(ContactCreateMessages.servicePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={formatMessage(ContactCreateMessages.emailLabel)}
                    name="email"
                    rules={[
                        { type: 'email', message: formatMessage(formMessages.invalidEmail) },
                    ]}
                >
                    <Input
                        placeholder={formatMessage(ContactCreateMessages.emailPlaceholder)}
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.phoneLabel)}
                            name="phone"
                        >
                            <PhoneInput
                                placeholder={formatMessage(ContactCreateMessages.phonePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={formatMessage(ContactCreateMessages.mobilePhoneLabel)}
                            name="mobilePhone"
                        >
                            <PhoneInput
                                placeholder={formatMessage(ContactCreateMessages.mobilePhonePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" plain>
                    <FormattedMessage {...ContactCreateMessages.addressTitle} />
                </Divider>
                <Form.List name="addresses">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item key={field.key}>
                                    <Row align="middle">
                                        <Col flex="auto">
                                            <Row gutter={24}>
                                                <Col xs={24} md={isEditing ? 7 : 24}>
                                                    <Form.Item
                                                        label={formatMessage(ContactCreateMessages.addressTypeLabel)}
                                                        name={[field.name, 'type']}
                                                    >
                                                        <Select
                                                            placeholder={formatMessage(ContactCreateMessages.addressTypePlaceholder)}
                                                        >
                                                            {Object.values(AddressType).map((type) => (
                                                                <Select.Option value={type} key={type}>
                                                                    <FormattedMessage {...addressTypeMessages.get(type)} />
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={isEditing ? 17 : 24}>
                                                    <Form.Item
                                                        label={formatMessage(ContactCreateMessages.addressStreetLabel)}
                                                        name={[field.name, 'street']}
                                                    >
                                                        <Input
                                                            placeholder={formatMessage(ContactCreateMessages.addressStreetPlaceholder)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ marginLeft: 16 }}>
                                            <Button
                                                className="remove-item"
                                                type="ghost"
                                                shape="circle"
                                                icon={<DeleteFilled />}
                                                onClick={remove.bind(null, index)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            ))}

                            <Form.ErrorList errors={errors} />

                            {form && (!form.getFieldValue(['addresses']) || form.getFieldValue(['addresses'])?.length < 4) && (
                                <Button
                                    type="ghost"
                                    // tslint:disable-next-line: jsx-no-lambda
                                    onClick={() => add()}
                                    shape="circle"
                                >
                                    <PlusOutlined />
                                </Button>
                            )}
                        </>
                    )}
                </Form.List>
                <Divider />
                <Form.Item
                    label={formatMessage(ContactCreateMessages.referrerLabel)}
                    name="referrer"
                >
                    <Select
                        filterOption={false}
                        loading={listContactState.loading}
                        onSearch={onContactSearch}
                        placeholder={formatMessage(ContactCreateMessages.referrerPlaceholder)}
                        showSearch
                        showArrow
                    >
                        {(initialReferrer ? [initialReferrer] : [])
                            .concat(listContactState.data?.items ?
                                listContactState.data.items.filter((item) => item.id !== initialReferrer?.id) :
                                [],
                            )
                            .map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {getFullName(item.firstName, item.lastName)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={formatMessage(ContactCreateMessages.commentLabel)}
                    name="comment"
                >
                    <Input.TextArea
                        placeholder={formatMessage(ContactCreateMessages.commentPlaceholder)}
                        rows={3}
                    />
                </Form.Item>
                {submit}
            </Card>
        </Form>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listContactState: getContactsListState(state),
});

export default connect(
    mapStateToProps,
    {
        listContact: list.trigger,
    },
)(ContactForm);
