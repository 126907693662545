import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'client.groups.title',
        defaultMessage: 'Groupes',
        description: 'Client groups page title',
    },
    groups: {
        id: 'client.groups',
        defaultMessage: `
            {count, plural,
                =0 {Groupe}
                one {Groupe}
                other {Groupes}
            }
        `,
        description: 'Groups',
    },
    addGroup: {
        id: 'client.groups.add_group',
        defaultMessage: 'Créer un groupe',
        description: 'Add group CTA',
    },

});
