import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Breakpoint } from 'react-socks';
import SwiperCore, { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Divider, Spin } from 'antd';

import 'swiper/swiper.less';
import 'swiper/components/pagination/pagination.less';

import '../../assets/styles/UserStats.less';

import { BadgeType, User, SelectedProgramStatus } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { getUserStatsState, my, StatsState } from '../../store/actions/stats';
import { getUser } from '../../store/actions/auth';
import { ProgramsState, getMyProgramState } from '../../store/actions/programs';

import Gauge from '../../components/Gauge';
import { IconBadge } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import HomeMessages from './HomeMessages';

SwiperCore.use([Pagination, A11y]);

interface UserStatsProps {
    statsState: StatsState['my'];
    fetchStats: typeof my.trigger;
    programState: ProgramsState['my'];
    user?: User;
}

const UserStats: FC<UserStatsProps> = ({ statsState, fetchStats, user, programState }) => {
    const badgesToShow = [BadgeType.professionalProject, BadgeType.cv, BadgeType.linkedin]
        .filter((badgeType) => user?.selectedProgram?.program?.badges[badgeType]);
    const badges = !!badgesToShow.length ? (
        <div className="user-stats-item-wrapper">
            <FormattedMessage {...HomeMessages.badgesTitle} tagName="p" />
            <div className="user-stats-item">
                {badgesToShow.map((badgeType) => (
                    <div className="user-stats-badge" key={badgeType}>
                        <IconBadge badgeType={badgeType} badgeLevel={statsState.data?.badges[badgeType]?.level} />
                        <FormattedMessage
                            {...genericMessages[badgeType === BadgeType.professionalProject ? 'professionalProjectShort' : badgeType]}
                            tagName="p"
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : null;
    const gauges = (
        <div className="user-stats-item-wrapper">
            <FormattedMessage {...HomeMessages.gaugesTitle} tagName="p" />
            <div className="user-stats-item" style={badges === null ? { justifyContent: 'space-evenly' } : undefined}>
                { programState?.data && programState.data.status !== SelectedProgramStatus.closed && (
                    <Gauge
                        value={statsState.data?.tasks.points || 0}
                        max={statsState.data?.tasks.total || 100}
                        type="action"
                    />
                )}
                <div className="user-stats-metric">
                    <p>{statsState.data?.appointments?.total ?? 0}</p>
                    <FormattedMessage
                        {...HomeMessages.interviewMetricLabel}
                        tagName="p"
                    />
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        if (!programState.loading && !programState.error && programState.data) {
            fetchStats();
        }
    }, [fetchStats, programState.loading, programState.error, programState.data]);

    return (
        <section id="user-stats" className="block">
            <Spin spinning={programState.loading || statsState.loading}>
                <Breakpoint xl down>
                    <div className="user-stats-mobile-wrapper">
                        <Swiper
                            spaceBetween={16}
                            slidesPerView="auto"
                            pagination={badges !== null ? {
                                el: '.user-stats-swiper-pagination',
                            } : false}
                            a11y={{
                                enabled: true,
                                prevSlideMessage: '',
                                nextSlideMessage: '',
                                firstSlideMessage: '',
                                lastSlideMessage: '',
                                paginationBulletMessage: '',
                                notificationClass: '',
                            }}
                        >
                            <SwiperSlide>
                                {gauges}
                            </SwiperSlide>
                            {!!badges && (
                                <SwiperSlide>
                                    {badges}
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className="swiper-pagination user-stats-swiper-pagination" />
                    </div>
                </Breakpoint>
                <Breakpoint xxl up>
                    <div className="user-stats-desktop-wrapper">
                        {badges && (
                            <>
                                {badges}
                                <Divider type="vertical" />
                            </>
                        )}
                        {gauges}
                    </div>
                </Breakpoint>
            </Spin>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    statsState: getUserStatsState(state),
    user: getUser(state),
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
    {
        fetchStats: my.trigger,
    },
)(UserStats);
