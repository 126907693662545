import api from './_client';
import { Organization } from './apiTypes';

export interface LogsPayload {
    streams: Array<{
        stream: {
            app: string;
            organization: Organization['id'];
        };
        values: Array<[string, string]>;
    }>;
}

export const logs = (payload: LogsPayload) => api.post('/debug/logs', payload, {
    headers: {
        'content-type': 'application/json',
    },
    baseURL: '/',
});
