import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'reset_password.title',
        defaultMessage: 'Réinitialisez votre mot de passe',
        description: 'Reset password title',
    },
    successDescription: {
        id: 'reset_password.success.description',
        defaultMessage: 'Votre mot de passe a été modifié avec succès',
        description: 'Reset password page success description',
    },
    description: {
        id: 'reset_password.description',
        defaultMessage: 'Merci de saisir un nouveau mot de passe ci-dessous',
        description: 'Reset password page description',
    },
    button: {
        id: 'reset_password.button',
        defaultMessage: 'Réinitialiser votre mot de passe',
        description: 'Reset password button',
    },
    successButton: {
        id: 'reset_password.success_button',
        defaultMessage: 'Continuer vers Mon coach Mobilité',
        description: 'Reset password success button',
    },
    passwordLabel: {
        id: 'reset_password.password.label',
        defaultMessage: 'Nouveau mot de passe',
        description: 'Reset password form label',
    },
    passwordPlaceholder: {
        id: 'reset_password.password.placeholder',
        defaultMessage: 'Saisissez votre nouveau mot de passe',
        description: 'Reset password form placeholder',
    },
    firstSetupTitle: {
        id: 'reset_password.first_setup.title',
        defaultMessage: 'Choisissez votre mot de passe',
        description: 'Reset password first setup title',
    },
    firstSetupButton: {
        id: 'reset_password.first_setup.button',
        defaultMessage: 'Enregistrer votre mot de passe',
        description: 'Reset password first setup button',
    },
    firstSetupSuccessDescription: {
        id: 'reset_password.first_setup.success.description',
        defaultMessage: 'Votre mot de passe a été enregistré avec succès',
        description: 'Reset password first setup success description',
    },
    firstSetupSuccessButton: {
        id: 'reset_password.first_setup.success.button',
        defaultMessage: 'Se connecter',
        description: 'Reset password first setup success button',
    },
    expiredOrInvalidToken: {
        id: 'reset_password.token_error',
        defaultMessage: 'L\'invitation a expiré.',
        description: 'Reset password expired or invalid token error',
    },
    sendNewInvitation: {
        id: 'reset_password.send_new_invitation',
        defaultMessage: 'Recevoir une nouvelle invitation par email',
        description: 'Reset password send new invitation button',
    },
    sendNewInvitationSuccess: {
        id: 'reset_password.send_new_invitation.success',
        defaultMessage: 'Vous allez recevoir une nouvelle invitation par email',
        description: 'Reset password send new invitation success',
    },
});
