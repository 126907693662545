import { useEffect, FC } from 'react';
import { connect } from 'react-redux';

import { my } from '../store/actions/programs';

interface GetProgramProps {
    fetchProgram: typeof my.trigger;
}

const GetProgram: FC<GetProgramProps> = ({ fetchProgram }) => {
    useEffect(() => {
        fetchProgram();
    }, [fetchProgram]);

    return null;
};

export default connect(
    undefined,
    {
        fetchProgram: my.trigger,
    },
)(GetProgram);
