import api from './_client';
import { SearchPaginationQuery } from '.';
import { Appointment, AppointmentStatus, Contact } from './apiTypes';

export type ListAppointmentPayload = SearchPaginationQuery | {
    status?: AppointmentStatus;
    contact?: Contact['id'];
};
export type CreateAppointmentPayload = Partial<Appointment>;
export type DeleteAppointmentPayload = Pick<Appointment, 'id'>;
export type AppointmentDetailsPayload = Pick<Appointment, 'id'>;
export interface AppointmentUpdatePayload {
    id: Appointment['id'];
    appointment: Appointment;
}

export const list = (payload: SearchPaginationQuery | { status?: AppointmentStatus }) => api.get('/me/agenda', { params: payload });

export const create = (payload: CreateAppointmentPayload) => api.post('/me/agenda', payload);

export const del = (payload: DeleteAppointmentPayload) => api.delete(`/me/agenda/${payload.id}`);

export const details = (payload: AppointmentDetailsPayload) => api.get(`/me/agenda/${payload.id}`);

export const update = (payload: AppointmentUpdatePayload) => api.put(`/me/agenda/${payload.id}`, payload.appointment);
