import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'forgotten_password.title',
        defaultMessage: 'Mot de passe oublié ?',
        description: 'Forgotten password title',
    },
    successDescription: {
        id: 'forgotten_password.success.description',
        defaultMessage: 'A new password has been sent to { address }.<break>|</break> Please check your inbox.',
        description: 'Forgotten password page success description',
    },
    description: {
        id: 'forgotten_password.description',
        defaultMessage: 'Saisissez l’adresse e-mail associé à votre compte, nous vous enverrons un mail avec un lien pour réinitialiser votre mot de passe',
        description: 'Forgotten password page description',
    },
    button: {
        id: 'forgotten_password.button',
        defaultMessage: 'Réinitialiser votre mot de passe',
        description: 'Forgotten password button',
    },
    loginLink: {
        id: 'forgotten_password.login_link',
        defaultMessage: 'Revenir à l’écran de connexion',
        description: 'Forgotten password link to login page',
    },
    success: {
        id: 'forgotten_password.success',
        defaultMessage: 'Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l’adresse {email}. {br}Merci de surveiller vos e-mails.',
        description: 'Forgotten password success message',
    },
});
