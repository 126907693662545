import { defineMessages } from 'react-intl';

export default defineMessages({
    backToHome: {
        id: 'program_task_details.back_to_home',
        defaultMessage: 'Retour à l\'accueil',
        description: 'Program task details page back to home link',
    },
    tasksTitle: {
        id: 'program_task_details.task_list.title',
        defaultMessage: 'Tâches de l\'action',
        description: 'Program task details page task list title',
    },
    requiredSubTask: {
        id: 'program_task_details.sub_task.required',
        defaultMessage: 'Tâche requise',
        description: 'Program task details page sub task required',
    },
    inTheFuture: {
        id: 'program_task_details.sub_task.future_action.button',
        defaultMessage: 'À venir',
        description: 'Program task details page sub task future action button',
    },
    createNetworkingAction: {
        id: 'program_task_details.sub_task.networking_action.button',
        defaultMessage: 'Prendre RDV',
        description: 'Program task details page sub task create action button',
    },
    createContact: {
        id: 'program_task_details.sub_task.contact.button',
        defaultMessage: 'Créer un contact',
        description: 'Program task details page sub task create action button',
    },
    createJobOffer: {
        id: 'program_task_details.sub_task.job_offer.button',
        defaultMessage: 'Créer une annonce',
        description: 'Program task details page sub task create action button',
    },
    textSubmit: {
        id: 'program_task_details.sub_task.text.button',
        defaultMessage: 'J\'ai lu',
        description: 'Program task details page sub task text button',
    },
    missingDocument: {
        id: 'program_task_details.sub_task.document.error',
        defaultMessage: 'Document manquant.',
        description: 'Program task details page sub task document missing error',
    },
    linkToDocument: {
        id: 'program_task_details.sub_task.document.button',
        defaultMessage: 'Voir',
        description: 'Program task details page sub task document button',
    },
    linkToDocumentToRead: {
        id: 'program_task_details.sub_task.document.to_read.button',
        defaultMessage: 'Consulter',
        description: 'Program task details page sub task document button',
    },
    linkToDocumentFull: {
        id: 'program_task_details.sub_task_card.document.button',
        defaultMessage: 'Voir le document',
        description: 'Program task details page sub task card document button',
    },
    linkSubmit: {
        id: 'program_task_details.sub_task.link.button',
        defaultMessage: 'Suivre le lien',
        description: 'Program task details page sub task link button',
    },
    uploadSubmit: {
        id: 'program_task_details.sub_task.upload.button',
        defaultMessage: 'Confirmer',
        description: 'Program task details page sub task upload link button',
    },
    surveyBasicSubmit: {
        id: 'program_task_details.sub_task.survey.submit.basic',
        defaultMessage: 'Enregistrer',
        description: 'Program task details page sub task survey submit button',
    },
    surveyTricamDefaultTitle: {
        id: 'program_task_details.sub_task.survey_tricam.default_title',
        defaultMessage: 'Questionnaire n°{index}',
        description: 'Program task details page sub task survey tricam default title',
    },
    surveyTricamValidationSubmit: {
        id: 'program_task_details.sub_task.survey_tricam.submit.validation',
        defaultMessage: 'Valider les réponses',
        description: 'Program task details page sub task survey tricam submit button',
    },
    surveyValidationSubmit: {
        id: 'program_task_details.sub_task.survey.submit.validation',
        defaultMessage: 'Valider',
        description: 'Program task details page sub task survey submit button',
    },
    validate: {
        id: 'program_task_details.sub_task.validate.button',
        defaultMessage: 'Valider',
        description: 'Program task details page sub task validate button',
    },
    validated: {
        id: 'program_task_details.validated',
        defaultMessage: 'Validée',
        description: 'Program task details page validated',
    },
});
