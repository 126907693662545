import React, { FC, useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, Table, Avatar, Dropdown, Button, Tag, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import { getUsersReportingListState, reportingList, UsersState } from '../../store/actions/users';
import { User, UserGroup, BadgeType, BadgeLevel, badgeLevelMessages } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';

import ClientUsersMessages from '../../pages/client/users/ClientUsersMessages';
import ReportingMessages from '../../pages/client/dashboard/ReportingMessages';
import { getRoute, RoutePathName } from '../../routes';
import { IconClose, IconMore, IconBadge } from '../icons';
import { useQueryParams, useIsMounted } from '../../hooks';
import { getFullName } from '../../helpers';
import { getUser } from '../../store/actions/auth';
import { PanelParsedDescription } from '../../types';
import dayjs from 'dayjs';
import { localizedDurationHumanizer } from '../../locale/i18n/humanizeDuration';
import genericMessages from '../../locale/genericMessages';
import { getEnv } from '../../helpers/reporting';

interface ReportingUsersListProps {
    usersState: UsersState['reportingList'];
    getUsers: typeof reportingList.trigger;
    user?: User;
    parsedDescription: PanelParsedDescription;
}

const ReportingUsersList: FC<ReportingUsersListProps> = ({ usersState, getUsers, user, parsedDescription }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isMounted = useIsMounted();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const urlParams = useQueryParams();
    const groupParam = urlParams.get('group');
    const dateRangeParam = urlParams.get('dateRange');
    const setUser = (u: User) => {
        urlParams.set('user', `${u.id}`);
        urlParams.set('userName', `${getFullName(u.firstName, u.lastName)}`);
        urlParams.delete('distrib');
        urlParams.delete('distribName');
        urlParams.delete('group');
        urlParams.delete('groupName');

        history.push({ search: urlParams.toString() });
        setIsModalOpen(false);
    };

    const actionsMenu = (record: User) => (
        <Menu>
            <Menu.Item>
                <NavLink to={getRoute(RoutePathName.clientUser, { userId: record.id })}>
                    <FormattedMessage {...ClientUsersMessages.viewOrEdit} />
                </NavLink>
            </Menu.Item>
            <Menu.Item onClick={setUser.bind(null, record)}>
                <FormattedMessage {...ReportingMessages.userReporting} />
            </Menu.Item>
        </Menu>
    );

    const columns: Array<ColumnProps<User>> = [
        {
            key: 'avatar',
            render: (name, record) => (
                <>
                    <Avatar src={record?.avatarUrl} size={48}>
                        {!record?.avatarUrl && <UserOutlined />}
                    </Avatar>
                </>
            ),
        },
        {
            dataIndex: 'lastName',
            title: 'Nom',
            render: (name, record) => getFullName(record.firstName, record.lastName),
            sorter: (a, b) => (a.lastName || '').localeCompare((b.lastName || '')),
        },
        {
            dataIndex: 'groups',
            title: 'Groupe(s)',
            render: (groups) => (
                <>
                    {groups && groups.map((group: UserGroup) => (
                        <Tag key={group.id}>{group.name}</Tag>
                    ))}
                </>
            ),
            filters: [...new Set(
                usersState.data?.items.reduce<string[]>((acc, u) => [...acc, ...(u.groups?.map((g) => g.name) || [])], []),
            )].map((i) => ({ text: i, value: i })),
            onFilter: (value, record) => record.groups?.some((g) => g.name === value) || false,
        },
        {
            dataIndex: 'selectedProgram',
            title: 'Programme',
            render: (selectedProgram) => (
                <>
                    {selectedProgram?.program?.name || '-'}
                </>
            ),
            filters: [...new Set(usersState.data?.items.map((i) => i.selectedProgram?.program?.name || ''))].map((i) => ({ text: i, value: i })),
            onFilter: (value, record) => record.selectedProgram?.program?.name === value || false,
        },
        {
            key: 'actions',
            width: 50,
            render: (text, record) => (
                <Dropdown
                    overlay={actionsMenu.bind(null, record)}
                    trigger={['click']}
                >
                    <Button
                        className="more-actions"
                        type="text"
                        icon={<IconMore />}
                        size="large"
                    />
                </Dropdown>
            ),
        },
    ];

    if (typeof parsedDescription === 'object') {
        if (parsedDescription.userDetailsType === 'sessions') {
            columns.splice(4, 0, {
                dataIndex: 'sessions',
                title: 'Connexions',
                render: (sessions) => sessions && (
                    <>
                        <FormattedNumber value={sessions.count} /> ({localizedDurationHumanizer(sessions.timeOnline * 1000, {
                            maxDecimalPoints: 1,
                            largest: 1,
                        })})
                    </>
                ),
            });
        } else if (parsedDescription.userDetailsType === 'badges') {
            columns.splice(2, 2, ...Object.values(BadgeType).map((badgeType) => ({
                key: badgeType,
                title: <FormattedMessage {...genericMessages[badgeType]} />,
                render: (record: User) => <IconBadge badgeType={badgeType} badgeLevel={record.stats?.badges[badgeType]?.level} />,
                filters: Object.values(BadgeLevel).map((i) => ({
                    text: badgeLevelMessages.get(i) ? formatMessage(badgeLevelMessages.get(i)!) : i,
                    value: i,
                })),
                onFilter: (value: any, record: User) => record.stats?.badges[badgeType]?.level === value || false,
                width: 60,
            })));
        }
    }

    useEffect(() => {
        if (isModalOpen && user) {
            (async () => {
                const env = await getEnv();

                if (isMounted()) {
                    getUsers({
                        organization: user.organization?.id,
                        returnUsers: true,
                        userDetailsType: typeof parsedDescription === 'object' ? parsedDescription.userDetailsType : undefined,
                        jobLevel: 'all',
                        gender: 'all',
                        env,
                        ...(groupParam ? { group: groupParam } : {}),
                        ...(dateRangeParam ? {
                            fromTime: parseInt(dateRangeParam.split('-')[0], 10) / 1000,
                            toTime: parseInt(dateRangeParam.split('-')[1], 10) / 1000,
                        } : {}),
                        ...(typeof parsedDescription === 'object' && parsedDescription.time === 'last' && dateRangeParam ? {
                            fromTime: dayjs(parseInt(dateRangeParam.split('-')[1], 10)).subtract(1, 'second').valueOf() / 1000,
                            toTime: parseInt(dateRangeParam.split('-')[1], 10) / 1000,
                        } : {}),
                    });
                }
            })();
        }
    }, [getUsers, isModalOpen, user, groupParam, dateRangeParam, parsedDescription, isMounted]);

    return (
        <>
            <Button
                type="link"
                onClick={setIsModalOpen.bind(null, true)}
            >
                Liste des salariés
            </Button>
            <Modal
                title={<FormattedMessage {...ReportingMessages.usersModalTitle} />}
                onCancel={setIsModalOpen.bind(null, false)}
                footer={null}
                visible={isModalOpen}
                closeIcon={<IconClose />}
                className="reporting-users-list"
                width={790}
                closable
            >
                <Table<User>
                    // tslint:disable-next-line: jsx-no-lambda
                    rowKey={(item: User) => `${item.id}`}
                    className="page-table"
                    columns={columns}
                    loading={usersState.loading}
                    dataSource={usersState.data?.items || []}
                />
            </Modal>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    usersState: getUsersReportingListState(state),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        getUsers: reportingList.trigger,
    },
)(ReportingUsersList);
