import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredField: {
        id: 'form.required_field',
        defaultMessage: 'Champ requis',
        description: 'Form required field',
    },
    invalidEmail: {
        id: 'form.invalid_email',
        defaultMessage: 'Veuillez entrer une adresse email valide',
        description: 'Form invalid email error',
    },
    invalidPassword: {
        id: 'form.invalid_password',
        defaultMessage: 'Veuillez entrer un mot de passe valide',
        description: 'Form invalid password error',
    },
    confirmEmailNotIdentical: {
        id: 'form.confirm_email.not_identical',
        defaultMessage: 'Les deux emails doivent être identiques',
        description: 'Form invalid email error',
    },
    passwordStrengthLength: {
        id: 'form.password.strength.length',
        defaultMessage: '8 caractères minimum',
        description: 'Form password strength',
    },
    passwordStrengthDigit: {
        id: 'form.password.strength.digit',
        defaultMessage: 'Au moins un chiffre',
        description: 'Form password strength',
    },
    passwordStrengthUppercase: {
        id: 'form.password.strength.uppercase',
        defaultMessage: 'Au moins une lettre en majuscule',
        description: 'Form password strength',
    },
    passwordStrengthLowercase: {
        id: 'form.password.strength.lowercase',
        defaultMessage: 'Au moins une lettre en minuscule',
        description: 'Form password strength',
    },
    passwordStrengthSymbol: {
        id: 'form.password.strength.symbol',
        defaultMessage: 'Au moins un caractère spécial',
        description: 'Form password strength',
    },
    addressLabel: {
        id: 'form.address.label',
        defaultMessage: 'Adresse',
        description: 'Form address',
    },
    addressPlaceholder: {
        id: 'form.address.placeholder',
        defaultMessage: 'Saisir une adresse',
        description: 'Form address',
    },
    selectPlaceholder: {
        id: 'form.select.placeholder',
        defaultMessage: 'Choisir une valeur',
        description: 'Form select placeholder',
    },
    submit: {
        id: 'form.submit',
        defaultMessage: 'Envoyer',
        description: 'Form submit button text',
    },
    chooseFile: {
        id: 'form.upload.choose_file',
        defaultMessage: 'Choisir un fichier',
        description: 'Form upload choose file button text',
    },
    minRequiredCountError: {
        id: 'form.error.minimum_required_fields_count',
        defaultMessage: `
            {count, plural,
                =0 {Vous devez remplir au moins {count} champ}
                one {Vous devez remplir au moins {count} champ}
                other {Vous devez remplir au moins {count} champs}
            }
        `,
        description: 'Form upload choose file button text',
    },
    dataConsent: {
        id: 'form.gdpr.data_consent',
        defaultMessage: 'J\'accepte que les informations saisies ci-dessus soient utilisées dans les conditions de la <link>politique de confidentialité</link>',
        description: 'Form GDPR consent checkbox to send data',
    },
});
