import genericMessages from '../../locale/genericMessages';
import clientMessages from '../../locale/clientMessages';
import LibraryMessages from '../../pages/library/LibraryMessages';
import SurveyMessages from '../../components/surveys/SurveyMessages';

// Types of the backend API
type ObjectId = string;

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    // es = 'es',
    // du = 'du',
    // it = 'it',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    superAdmin = 'superAdmin',
    admin = 'admin',
    logAs = 'logAs',
    users = 'users',
    roles = 'roles',
    organizations = 'organizations',
    staticPages = 'staticPages',
    questions = 'questions',

    // MCM specific permissions
    licensePacks = 'licensePacks',
    userGroups = 'userGroups',
    programs = 'programs',
    library = 'library',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
    groups = 'groups',
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}

export interface Role {
    id: ObjectId;
    name: string;
    slug: RoleSlug;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}

export enum RoleSlug {
    superAdmin = 'superAdmin',
    owner = 'owner',
    admin = 'admin',
    user = 'user',
    manager = 'manager',
    headHR = 'headHR',
}

export const rolesMessages = new Map([
    [RoleSlug.superAdmin, clientMessages.roleSuperAdmin],
    [RoleSlug.owner, clientMessages.roleOwner],
    [RoleSlug.admin, clientMessages.roleAdmin],
    [RoleSlug.user, clientMessages.roleUser],
    [RoleSlug.manager, clientMessages.roleManager],
    [RoleSlug.headHR, clientMessages.roleHeadHR],
]);

export interface Organization {
    id: ObjectId;
    name: string;
    slug: string;
    enabled?: boolean;
    comment?: string;
    group?: string;
    companyName?: string;
    serialNumber?: string;
    VATserialNumber?: string;
    companySize?: CompanySize;
    businessType?: BusinessType;
    address?: Address;
    billingAddress?: Address;
    licenseCount?: number;
    licensePackCount?: number;
    // licenses?: License[];
    licensePacks?: Pack[];
    owner?: User;
    theme: {
        primary?: string;
        secondary?: string;
        background?: string;
        bannerUrl?: string;
        logoUrl?: string;
    };
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}

export interface Address {
    id?: ObjectId;
    type?: AddressType;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export enum AddressType {
    personal = 'personal',
    professional = 'professional',
    other = 'other',
}

export const addressTypeMessages = new Map([
    [AddressType.personal, genericMessages.addressTypePersonal],
    [AddressType.professional, genericMessages.addressTypeProfessional],
    [AddressType.other, genericMessages.addressTypeOther],
]);

export enum Civility {
    mister = 'mr',
    missus = 'mrs',
    doctor = 'dr',
    lawyer = 'lawyer',
    professor = 'professor',
}

export const civilityMessages = new Map([
    [Civility.mister, genericMessages.civilityMister],
    [Civility.missus, genericMessages.civilityMissus],
    [Civility.doctor, genericMessages.civilityDoctor],
    [Civility.lawyer, genericMessages.civilityLawyer],
    [Civility.professor, genericMessages.civilityProfessor],
]);

export enum Gender {
    man = 'man',
    woman = 'woman',
}

export const genderMessages = new Map([
    [Gender.man, genericMessages.genderMan],
    [Gender.woman, genericMessages.genderWoman],
]);

export enum SpokenLanguageLevel {
    school = 'A1',
    notions = 'B1',
    fluent = 'C1',
    bilingual = 'C2',
}

export const spokenLanguageLevelMessages = new Map([
    [SpokenLanguageLevel.school, genericMessages.spokenLanguageLevelSchool],
    [SpokenLanguageLevel.notions, genericMessages.spokenLanguageLevelNotions],
    [SpokenLanguageLevel.fluent, genericMessages.spokenLanguageLevelFluent],
    [SpokenLanguageLevel.bilingual, genericMessages.spokenLanguageLevelBilingual],
]);

export const spokenLanguageLevelHelpMessages = new Map([
    [SpokenLanguageLevel.school, genericMessages.spokenLanguageLevelSchoolHelp],
    [SpokenLanguageLevel.notions, genericMessages.spokenLanguageLevelNotionsHelp],
    [SpokenLanguageLevel.fluent, genericMessages.spokenLanguageLevelFluentHelp],
    [SpokenLanguageLevel.bilingual, genericMessages.spokenLanguageLevelBilingualHelp],
]);

export enum JobLevel {
    worker = 'worker',
    etam = 'etam',
    executive = 'executive',
}

export const jobStatusMessages = new Map([
    [JobLevel.worker, genericMessages.jobStatusWorker],
    [JobLevel.etam, genericMessages.jobStatusEtam],
    [JobLevel.executive, genericMessages.jobStatusExecutive],
]);

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: ObjectId[] | Organization[];
    [RoleScope.users]?: ObjectId[] | User[];
    [RoleScope.groups]?: UserGroup[];
}

export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: SupportedLanguage;
    comment?: string;
    acceptedTerms?: boolean;
    acceptedTermsAt?: string;
    hasSetupAccount?: boolean;
    hasSetupAccountAt?: string;
    avatarUrl?: string;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: UserScope;
    organization?: Organization;
    gender?: Gender;
    phone?: string;
    spokenLanguages?: Array<{
        language: string;
        level: SpokenLanguageLevel;
    }>;
    degrees?: string;
    maritalStatus?: string;
    civility?: Civility;
    birthday?: string;
    company?: CompanyInfo;
    job?: JobInfo;
    address?: Address;
    selectedProgram?: SelectedProgram;
    groups?: UserGroup[];
    stats?: UserStats;
    shouldShowTutorial?: boolean;
}

export interface SelectedProgram {
    dates?: DateRange;
    status: SelectedProgramStatus;
    program?: Program;
    extensions?: ExtensionProgram[];
    closure?: ProgramClosure;
    remainingSuspensions?: number;
    suspensions: DateRange[];
    weeks: DateRange[];
}

export interface ExtensionProgram {
    dates?: DateRange;
    program?: Program;
}

export enum SelectedProgramStatus {
    pending = 'pending',
    started = 'started',
    closed = 'closed',
}

export const selectedProgramStatusMessages = new Map([
    [SelectedProgramStatus.pending, genericMessages.programStatusPending],
    [SelectedProgramStatus.started, genericMessages.programStatusStarted],
    [SelectedProgramStatus.closed, genericMessages.programStatusEnded],
]);

export interface CompanyInfo {
    name?: string;
    group?: string;
    department?: string;
    service?: string;
    website?: string;
    address?: Address;
}

export interface UserGroup {
    id: ObjectId;
    name: string;
    licensePacks?: Pack[];
}

export interface Pack {
    id: ObjectId;
    name: string;
    description?: string;
    enabled?: boolean;
    period?: DateRange;
    maxLicenses?: number;
    licensesCount?: number;
    availableLicenseCount?: number;
    programs?: Program[];
}

export interface DateRange {
    fromDate?: string;
    toDate?: string;
}

export interface JobInfo {
    level?: JobLevel;
    sector?: string;
    occupation?: string;
    jobTitle?: string;
    position?: string;
    annualSalaryWithoutTax?: number;
    entryOnDutyDate?: string;
    manager?: string;
    hrRepresentative?: string;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export enum BadgeLevel {
    lvl1 = 'lvl1',
    lvl2 = 'lvl2',
    lvl3 = 'lvl3',
}

export const badgeLevelMessages = new Map([
    [BadgeLevel.lvl1, genericMessages.badgeLevel1],
    [BadgeLevel.lvl2, genericMessages.badgeLevel2],
    [BadgeLevel.lvl3, genericMessages.badgeLevel3],
]);

export enum BadgeType {
    cv = 'cv',
    linkedin = 'linkedin',
    professionalProject = 'professionalProject',
}

export enum NotificationStatus {
    pending = 'pending', // not useful here
    sent = 'sent', // not useful here
    received = 'received',
    opened = 'opened',
    failed = 'failed', // not useful here
}

export interface Notification {
    id: ObjectId;
    type: 'text';
    channel: 'pull';
    payload: {
        text: string;
        context?: {
            [key: string]: any;
        };
    };
    reference: string;
    status: NotificationStatus;
    sendAt: string;
}

export interface ProgramWeek {
    id: ObjectId;
    index: number;
    points: number;
    program?: Program;
    tasks?: ProgramTask[];
}

export interface Program {
    id: ObjectId;
    isTemplate: boolean;
    type: ProgramType;
    code?: string;
    language: SupportedLanguage;
    name: string;
    description?: string;
    weekCount: number;
    organization?: Organization;
    weeks?: ProgramWeek[];
    points: number;
    maxSuspension: number;
    badges: {
        [BadgeType.cv]: boolean;
        [BadgeType.linkedin]: boolean;
        [BadgeType.professionalProject]: boolean;
    };
    shouldDisplayJobOffers: boolean;
}

export enum ProgramType {
    basic = 'basic',
    extension = 'extension',
}

export enum ProgramClosureType {
    businessCreation = 'businessCreation',
    training = 'training',
    internal = 'internal',
    external = 'external',
    retired = 'retired',
    other = 'other',
}

export const programClosureTypeMessages = new Map([
    [ProgramClosureType.businessCreation, genericMessages.closureTypeBusinessCreation],
    [ProgramClosureType.training, genericMessages.closureTypeTraining],
    [ProgramClosureType.internal, genericMessages.closureTypeInternal],
    [ProgramClosureType.external, genericMessages.closureTypeExternal],
    [ProgramClosureType.retired, genericMessages.closureTypeRetired],
    [ProgramClosureType.other, genericMessages.closureTypeOther],
]);

export interface ProgramClosure {
    type?: ProgramClosureType;
    signatureDate?: string;
    leavingDate?: string;
    partnership?: boolean;
    job?: JobInfo;
    training?: {
        dates: DateRange;
    };
    comment?: string;
}

export enum ProgramTaskFamily {
    cv = 'cv',
    linkedin = 'linkedin',
    professionalProject = 'professionalProject',
    offer = 'offer',
    networking = 'networking',
    communication = 'communication',
    hiring = 'hiring',
    privateLife = 'privateLife',
    retirement = 'retirement',
    businessCreation = 'businessCreation',
    other = 'other',
}

export const taskTypeMessages = new Map([
    [ProgramTaskFamily.cv, genericMessages.cv],
    [ProgramTaskFamily.linkedin, genericMessages.linkedin],
    [ProgramTaskFamily.professionalProject, genericMessages.professionalProject],
    [ProgramTaskFamily.offer, genericMessages.offer],
    [ProgramTaskFamily.networking, genericMessages.networking],
    [ProgramTaskFamily.communication, genericMessages.communication],
    [ProgramTaskFamily.hiring, genericMessages.hiring],
    [ProgramTaskFamily.privateLife, genericMessages.privateLife],
    [ProgramTaskFamily.retirement, genericMessages.retirement],
    [ProgramTaskFamily.businessCreation, genericMessages.businessCreation],
    [ProgramTaskFamily.other, genericMessages.otherProgramTaskFamily],
]);

export interface ProgramTask {
    id: ObjectId;
    isTemplate: boolean;
    index: number;
    code?: string;
    name: string;
    description?: string;
    points: number;
    language: SupportedLanguage;
    family?: ProgramTaskFamily;
    program?: Program;
    subTasks: ProgramSubTask[];
    weeksDuration?: number;
    badge?: BadgeLevel;
    updatedAt: string;
}

export enum UserProgramTaskStatus {
    pending = 'pending',
    started = 'started',
    validated = 'validated',
    cancelled = 'cancelled',
}

export interface TaskDateRange {
    fromDate?: string;
    toDate?: string;
    validUntil?: string;
}

export interface UserProgramTask {
    id: ObjectId;
    name: string;
    family?: ProgramTaskFamily;
    status: UserProgramTaskStatus;
    period: TaskDateRange;
    task?: ProgramTask;
    user?: User;
    organization?: Organization;
}

export enum ProgramValidationType {
    manual = 'manual',
    automatic = 'automatic',
}

export interface ProgramTaskValidation {
    type: ProgramValidationType;
    requiredCount: number;
    fields: {
        [field: string]: {
            required?: boolean;
            value?: string;
        };
    };

}

export enum SubTaskType {
    text = 'text',
    document = 'document',
    link = 'link',
    action = 'action',
    upload = 'upload',
    survey = 'survey',
}

export enum DocumentSubTaskType {
    toRead = 'toRead',
    toDo = 'toDo',
}

export enum SurveySubTaskType {
    basic = 'basic',
    validation = 'validation',
    tricam = 'tricam',
    colors = 'colors',
    intelligence = 'intelligence',
}

export const subTaskTypeMessages = new Map([
    [SubTaskType.text, genericMessages.subTaskTypeText],
    [SubTaskType.document, genericMessages.subTaskTypeDocument],
    [SubTaskType.link, genericMessages.subTaskTypeLink],
    [SubTaskType.action, genericMessages.subTaskTypeAction],
    [SubTaskType.upload, genericMessages.subTaskTypeUpload],
    [SubTaskType.survey, genericMessages.subTaskTypeSurvey],
]);

export const getSubTaskTypeMessage = (subTask: ProgramSubTask) => {
    switch (subTask.type) {
        case SubTaskType.document:
            if (subTask.documentSubType === DocumentSubTaskType.toDo) {
                return genericMessages.subTaskTypeDocumentToDo;
            } else if (subTask.documentSubType === DocumentSubTaskType.toRead) {
                return genericMessages.subTaskTypeDocumentToRead;
            }
            return subTaskTypeMessages.get(subTask.type);

        case SubTaskType.survey:
            switch (subTask.surveySubType) {
                case SurveySubTaskType.basic: return genericMessages.subTaskTypeSurveyBasic;
                case SurveySubTaskType.validation: return genericMessages.subTaskTypeSurveyValidation;
                case SurveySubTaskType.tricam: return genericMessages.subTaskTypeSurveyTricam;
                case SurveySubTaskType.colors: return genericMessages.subTaskTypeSurveyColors;
                case SurveySubTaskType.intelligence: return genericMessages.subTaskTypeSurveyIntelligence;

                default: return subTaskTypeMessages.get(subTask.type);
            }

        default:
            return subTaskTypeMessages.get(subTask.type);
    }
};

export enum SurveyAnswerIntelligence {
    verbal = 'verbal',
    mathematical = 'mathematical',
    musical = 'musical',
    visual = 'visual',
    physical = 'physical',
    interpersonal = 'interpersonal',
    intrapersonal = 'intrapersonal',
    naturalist = 'naturalist',
}

export const surveyAnswerIntelligenceMessages = new Map([
    [SurveyAnswerIntelligence.verbal, SurveyMessages.intelligenceAnswerVerbal],
    [SurveyAnswerIntelligence.mathematical, SurveyMessages.intelligenceAnswerMathematical],
    [SurveyAnswerIntelligence.musical, SurveyMessages.intelligenceAnswerMusical],
    [SurveyAnswerIntelligence.visual, SurveyMessages.intelligenceAnswerVisual],
    [SurveyAnswerIntelligence.physical, SurveyMessages.intelligenceAnswerPhysical],
    [SurveyAnswerIntelligence.interpersonal, SurveyMessages.intelligenceAnswerInterpersonal],
    [SurveyAnswerIntelligence.intrapersonal, SurveyMessages.intelligenceAnswerIntrapersonal],
    [SurveyAnswerIntelligence.naturalist, SurveyMessages.intelligenceAnswerNaturalist],
]);

export interface IntelligenceSurveyMessage {
    type?: SurveyAnswerIntelligence;
    title?: string;
    description?: string;
}

export enum SurveyAnswerTricamLetters {
    t = 't',
    r = 'r',
    i = 'i',
    c = 'c',
    a = 'a',
    m = 'm',
}

export interface TricamSurveyMessage {
    letter?: SurveyAnswerTricamLetters;
    name?: string;
    description?: string;
    skills?: string;
    communication?: string;
    strongPoints?: string[];
    weakPoints?: string;
    environments?: string;
    combinations?: Record<SurveyAnswerTricamLetters, string>;
    jobs?: Record<string, string[]>;
}

export enum SurveyAnswerTricam {
    technical = 'technical',
    reflection = 'reflection',
    imagination = 'imagination',
    cooperation = 'cooperation',
    action = 'action',
    method = 'method',
}

export const surveyAnswerTricamMessages = new Map([
    [SurveyAnswerTricam.technical, SurveyMessages.tricamCategoryTechnical],
    [SurveyAnswerTricam.reflection, SurveyMessages.tricamCategoryReflection],
    [SurveyAnswerTricam.imagination, SurveyMessages.tricamCategoryImagination],
    [SurveyAnswerTricam.cooperation, SurveyMessages.tricamCategoryCooperation],
    [SurveyAnswerTricam.action, SurveyMessages.tricamCategoryAction],
    [SurveyAnswerTricam.method, SurveyMessages.tricamCategoryMethod],
]);

export const surveyAnswerTricamLetterToType = new Map([
    [SurveyAnswerTricamLetters.t, SurveyAnswerTricam.technical],
    [SurveyAnswerTricamLetters.r, SurveyAnswerTricam.reflection],
    [SurveyAnswerTricamLetters.i, SurveyAnswerTricam.imagination],
    [SurveyAnswerTricamLetters.c, SurveyAnswerTricam.cooperation],
    [SurveyAnswerTricamLetters.a, SurveyAnswerTricam.action],
    [SurveyAnswerTricamLetters.m, SurveyAnswerTricam.method],
]);

export enum SurveyAnswerColors {
    red = 'red',
    green = 'green',
    blue = 'blue',
    yellow = 'yellow',
}

export const surveyAnswerColorsMessages = new Map([
    [SurveyAnswerColors.red, SurveyMessages.colorsAnswerRed],
    [SurveyAnswerColors.green, SurveyMessages.colorsAnswerGreen],
    [SurveyAnswerColors.blue, SurveyMessages.colorsAnswerBlue],
    [SurveyAnswerColors.yellow, SurveyMessages.colorsAnswerYellow],
]);

export const surveyAnswerColorsDescriptionMessages = new Map([
    [SurveyAnswerColors.red, SurveyMessages.colorsAnswerDescriptionRed],
    [SurveyAnswerColors.green, SurveyMessages.colorsAnswerDescriptionGreen],
    [SurveyAnswerColors.blue, SurveyMessages.colorsAnswerDescriptionBlue],
    [SurveyAnswerColors.yellow, SurveyMessages.colorsAnswerDescriptionYellow],
]);

export enum SubTaskSubType {
    networking = 'networking',
    contact = 'contact',
    hiring = 'hiring',
}

export enum SurveyAnswerType {
    text = 'text',
    list = 'list',
    boolean = 'boolean',
    multiValue = 'multiValue',
}

export interface ProgramTaskSurvey {
    id: ObjectId;
    title?: string;
    description?: string;
    questions: ProgramTaskSurveyQuestion[];
}

export interface ProgramTaskSurveyQuestion {
    id: ObjectId;
    label: string;
    type: SurveyAnswerType;
    answers: Answer[];
}

export interface ProgramSubTask {
    id: ObjectId;
    type: SubTaskType;
    subType?: SubTaskSubType;
    documentSubType?: DocumentSubTaskType;
    surveySubType?: SurveySubTaskType;
    tricamWithPartialResults?: boolean;
    language: SupportedLanguage;
    link?: string;
    description?: string;
    points: number;
    surveys?: ProgramTaskSurvey[];
    isRequired: boolean;
    validation?: ProgramTaskValidation;
    libraryDocument?: LibraryDocument;
    status: UserProgramTaskStatus;
    validationToken?: string;
    userSubTask?: UserProgramSubTask;
}

export enum UserProgramSubTaskStatus {
    pending = 'pending',
    started = 'started',
    validated = 'validated',
    cancelled = 'cancelled',
}

export interface Answer<T = string> {
    label: string;
    values?: T[];
}

export interface SurveyResponses<T = string> {
    [questionId: string]: Array<Answer<T>>;
}

export interface UserProgramSubTask {
    id: ObjectId;
    type?: SubTaskType;
    status: UserProgramSubTaskStatus;
    statusDate?: string;
    userTask?: UserProgramTask;
    task?: ProgramTask;
    subTask?: ProgramSubTask;
    user?: User;
    organization?: Organization;
    validationToken: string;
    surveyResponses?: SurveyResponses<any>;
    documents?: LibraryDocument[];
}

export enum NetworkingActionType {
    informationQuery = 'informationQuery',
    feedbackQuery = 'feedbackQuery',
    adviseQuery = 'adviseQuery',
    jobInterview = 'jobInterview',
    reminder = 'reminder',
}

export const networkingActionTypeMessages = new Map([
    [NetworkingActionType.informationQuery, genericMessages.actionTypeInformationQuery],
    [NetworkingActionType.feedbackQuery, genericMessages.actionTypeFeedbackQuery],
    [NetworkingActionType.adviseQuery, genericMessages.actionTypeAdviceQuery],
    [NetworkingActionType.reminder, genericMessages.actionTypeReminder],
    [NetworkingActionType.jobInterview, genericMessages.actionTypeJobInterview],
]);

export const networkingActionTypeHelpMessages = new Map([
    [NetworkingActionType.informationQuery, genericMessages.actionTypeHelpInformationQuery],
    [NetworkingActionType.feedbackQuery, genericMessages.actionTypeHelpFeedbackQuery],
    [NetworkingActionType.adviseQuery, genericMessages.actionTypeHelpAdviceQuery],
    [NetworkingActionType.reminder, genericMessages.actionTypeHelpReminder],
    [NetworkingActionType.jobInterview, genericMessages.actionTypeHelpJobInterview],
]);

export enum AppointmentType {
    real = 'real',
    phone = 'phone',
    video = 'video',
}

export const actionMeetingTypeMessages = new Map([
    [AppointmentType.real, genericMessages.actionMeetingTypeFaceToFace],
    [AppointmentType.phone, genericMessages.actionMeetingTypePhone],
    [AppointmentType.video, genericMessages.actionMeetingTypeVideo],
]);

export enum ContactType {
    manager = 'manager',
    hr = 'hr',
    networkAngel = 'networkAngel',
    internal = 'internal',
    external = 'external',
    headHunter = 'headHunter',
}

export const contactTypeMessages = new Map([
    [ContactType.manager, genericMessages.contactTypeManager],
    [ContactType.hr, genericMessages.contactTypeHr],
    [ContactType.networkAngel, genericMessages.contactTypeNetworkAngel],
    [ContactType.internal, genericMessages.contactTypeInternalNetworkContact],
    [ContactType.external, genericMessages.contactTypeExternalNetworkContact],
    [ContactType.headHunter, genericMessages.contactTypeHeadHunter],
]);

export interface Contact {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    type: ContactType;
    civility?: Civility;
    firstName?: string;
    lastName: string;
    email?: string;
    phone?: string;
    mobilePhone?: string;
    addresses: Address[];
    comment?: string;
    company?: CompanyInfo;
    job?: JobInfo;
    address?: Address;
    referrer?: Contact;
    referred?: Contact[];
}

export enum AppointmentStatus {
    pending = 'pending',
    cancelled = 'cancelled',
    validated = 'validated',
}

export enum InterviewStatus {
    pending = 'pending',
    accepted = 'accepted',
    refused = 'refused',
}

export interface Appointment {
    id: ObjectId;
    parent?: Appointment;
    firstAppointment?: Appointment;
    jobOffer?: JobOffer;
    actionType: NetworkingActionType;
    type: AppointmentType;
    status: AppointmentStatus;
    interviewStatus?: InterviewStatus;
    interviewStatusDate?: string;
    createdAt: string;
    updatedAt: string;
    validatedAt?: string;
    cancelledAt?: string;
    date: string;
    title: string;
    comment?: string;
    notes?: Array<{
        date: string;
        text: string;
    }>;
    phone?: string;
    url?: string;
    address?: Address;
    contact?: Contact;
    recommendedContacts?: Contact[];
    recommendedContactsCount: number;
}

export interface DashboardStats {
    licensePacks: {
        total: number;
        active: number;
        licensesCount: number;
        licensesTotal: number;
        availableLicenseCount: number;
    };
    groups: {
        total: number;
    };
}

export enum StaticPageSlug {
    termsOfUse = 'terms-of-use',
    privacyPolicy = 'privacy-policy',
    qa = 'qa',
}

export interface StaticPage {
    id: string;
    slug: string;
    title: string;
    content: string;
}

export interface QuestionSection {
    id: ObjectId;
    title: string;
    description?: string;
    questionCount?: number;
    questions: Question[];
}

export interface Question {
    id: ObjectId;
    section?: QuestionSection;
    title: string;
    description?: string;
    answer: string;
}

export enum LibraryDocumentType {
    file = 'file',
    video = 'video',
    podcast = 'podcast',
}

export const documentTypeMessages = new Map([
    [LibraryDocumentType.file, genericMessages.documentTypeFile],
    [LibraryDocumentType.video, genericMessages.documentTypeVideo],
    [LibraryDocumentType.podcast, genericMessages.documentTypePodcast],
]);

export const documentTypeViewMoreMessages = new Map([
    [LibraryDocumentType.file, LibraryMessages.viewFile],
    [LibraryDocumentType.video, LibraryMessages.viewVideo],
    [LibraryDocumentType.podcast, LibraryMessages.viewPodcast],
]);

export interface LibraryDocument {
    id: ObjectId;
    url: string;
    name: string;
    description: string;
    language: SupportedLanguage;
    file?: RemoteFile;
    enabled?: boolean;
    code?: string;
    type: LibraryDocumentType;
    family: ProgramTaskFamily;
    organization?: Organization;
    program?: Program;
    user?: User;
    createdAt: string;
    updatedAt: string;
}

export interface RemoteFile {
    id: ObjectId;
    url: string;
    createdAt: string;
    filename: string;
    originalName?: string;
    thumbnailUrl?: string;
}

export enum JobOfferType {
    internal = 'internal',
    external = 'external',
}

export const jobOfferTypeMessages = new Map([
    [JobOfferType.internal, genericMessages.jobOfferTypeInternal],
    [JobOfferType.external, genericMessages.jobOfferTypeExternal],
]);

export enum JobOfferStatus {
    pending = 'pending',
    refused = 'refused',
    accepted = 'accepted',
}

export interface JobOffer {
    id: ObjectId;
    type: JobOfferType;
    title: string;
    reference?: string;
    origin?: string;
    description?: string;
    documents: Array<{
        url: string;
        description?: string;
    }>;
    status?: InterviewStatus;
    statusDate?: string;
    createdAt: string;
    updatedAt: string;
    responseDate?: string;
    company?: CompanyInfo;
    appointments?: Appointment[];
    job?: JobInfo;
    user?: User;
}

export enum BusinessType {
    agricultureForestryFishing = 'agricultureForestryFishing',
    extractiveIndustries = 'extractiveIndustries',
    manufacturingIndustry = 'manufacturingIndustry',
    productionDistributionElectricityGas = 'productionDistributionElectricityGas',
    waterProductionDistribution = 'waterProductionDistribution',
    construction = 'construction',
    tradeRepairAutomobilesMotorcycles = 'tradeRepairAutomobilesMotorcycles',
    transportationWarehousing = 'transportationWarehousing',
    accommodationCatering = 'accommodationCatering',
    informationCommunication = 'informationCommunication',
    financialInsuranceActivities = 'financialInsuranceActivities',
    realEstateActivities = 'realEstateActivities',
    specializedScientificTechnicalActivities = 'specializedScientificTechnicalActivities',
    administrativeSupportServiceActivities = 'administrativeSupportServiceActivities',
    publicAdministration = 'publicAdministration',
    education = 'education',
    humanHealthSocialAction = 'humanHealthSocialAction',
    artsEntertainmentRecreationalActivities = 'artsEntertainmentRecreationalActivities',
    otherServiceActivities = 'otherServiceActivities',
    activitiesHouseholdsAsEmployers = 'activitiesHouseholdsAsEmployers',
    extraTerritorialActivities = 'extraTerritorialActivities',
}

export const businessTypeMessages = new Map([
    [BusinessType.agricultureForestryFishing, genericMessages.businessTypeAgricultureForestryFishing],
    [BusinessType.extractiveIndustries, genericMessages.businessTypeExtractiveIndustries],
    [BusinessType.manufacturingIndustry, genericMessages.businessTypeManufacturingIndustry],
    [BusinessType.productionDistributionElectricityGas, genericMessages.businessTypeProductionDistributionElectricityGas],
    [BusinessType.waterProductionDistribution, genericMessages.businessTypeWaterProductionDistribution],
    [BusinessType.construction, genericMessages.businessTypeConstruction],
    [BusinessType.tradeRepairAutomobilesMotorcycles, genericMessages.businessTypeTradeRepairAutomobilesMotorcycles],
    [BusinessType.transportationWarehousing, genericMessages.businessTypeTransportationWarehousing],
    [BusinessType.accommodationCatering, genericMessages.businessTypeAccommodationCatering],
    [BusinessType.informationCommunication, genericMessages.businessTypeInformationCommunication],
    [BusinessType.financialInsuranceActivities, genericMessages.businessTypeFinancialInsuranceActivities],
    [BusinessType.realEstateActivities, genericMessages.businessTypeRealEstateActivities],
    [BusinessType.specializedScientificTechnicalActivities, genericMessages.businessTypeSpecializedScientificTechnicalActivities],
    [BusinessType.administrativeSupportServiceActivities, genericMessages.businessTypeAdministrativeSupportServiceActivities],
    [BusinessType.publicAdministration, genericMessages.businessTypePublicAdministration],
    [BusinessType.education, genericMessages.businessTypeEducation],
    [BusinessType.humanHealthSocialAction, genericMessages.businessTypeHumanHealthSocialAction],
    [BusinessType.artsEntertainmentRecreationalActivities, genericMessages.businessTypeArtsEntertainmentRecreationalActivities],
    [BusinessType.otherServiceActivities, genericMessages.businessTypeOtherServiceActivities],
    [BusinessType.activitiesHouseholdsAsEmployers, genericMessages.businessTypeActivitiesHouseholdsAsEmployers],
    [BusinessType.extraTerritorialActivities, genericMessages.businessTypeExtraTerritorialActivities],
]);

export enum CompanySize {
    lessThan10 = 'lessThan10',
    from10To249 = 'from10To249',
    from250To999 = 'from250To999',
    from1000To5000 = 'from1000To5000',
    moreThan5000 = 'moreThan5000',
}

export const companySizeMessages = new Map([
    [CompanySize.lessThan10, genericMessages.companySizeLessThan10],
    [CompanySize.from10To249, genericMessages.companySizeFrom10To249],
    [CompanySize.from250To999, genericMessages.companySizeFrom250To999],
    [CompanySize.from1000To5000, genericMessages.companySizeFrom1000To5000],
    [CompanySize.moreThan5000, genericMessages.companySizeMoreThan5000],
]);

export interface UserStats {
    updatedAt: Date;
    badges: {
        [name in BadgeType]?: {
            level?: BadgeLevel; // Acquired
            points: number;
            total: number;
            percentage: number;
        };
    };
    tasks: {
        points: number;
        total: number;
        percentage: number;
    };
    activity: {
        points: number;
        total: number;
        percentage: number;
    };
    appointments: {
        total: number;
    };
}

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
}
