import React, { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { Breakpoint } from 'react-socks';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Input, Form, Divider, Radio } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { InputProps } from 'antd/lib/input';

import '../assets/styles/Filters.less';

import { classNames } from '../helpers';
import Select from './Select';
import BasicList from './BasicList';
import { IconSearch, IconClose } from './icons';
import { FormProps } from 'antd/lib/form';
import genericMessages from '../locale/genericMessages';
import Modal from './Modal';

const messages = defineMessages({
    filterSortModalButton: {
        id: 'filters.sort_modal.button',
        defaultMessage: 'Trier & filtrer',
        description: 'Filters sort & filter mobile modal button',
    },
    filterSortModalTitle: {
        id: 'filters.sort_modal.title',
        defaultMessage: 'Tri & filtres',
        description: 'Filters sort & filter mobile modal title',
    },
    sortBy: {
        id: 'filters.sort_by',
        defaultMessage: 'Trier par :',
        description: 'Filters sort by',
    },
});

interface FiltersProps {
    onChangeSort?: (selectedSort: string) => void;
    onChangeFilter?: (selectedFilter: string) => void;
    onChangeSearch?: (search?: string) => void;
    filterOptions: Array<{
        message: any;
        messageValues?: {[key: string]: any};
        value: string;
    }>;
    sortOptions?: Array<{
        message: any;
        messageValues?: { [key: string]: any };
        value: string;
    }>;
    filterValue: string;
    filterMultiple?: boolean;
    sortValue?: string;
    searchValue?: string;
}

let searchTimeout = 0;

const Filters: FC<FiltersProps> = ({
    onChangeFilter, onChangeSearch, onChangeSort, filterOptions, filterValue, filterMultiple = false,
    searchValue, sortOptions, sortValue,
}) => {
    const [isSearchExpanded, setIsSearchExpanded] = useState(!!searchValue);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState(searchValue || '');
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const inputRef = useRef<Input>(null);
    const hasSearch = typeof onChangeSearch === 'function';
    const hasFilter = typeof onChangeFilter === 'function' && filterOptions;
    const hasSort = typeof onChangeSort === 'function' && sortOptions;
    const onChangeFilterSelect = (val: SelectValue) => {
        onChangeFilter!(val as string);
    };
    const onChangeSortSelect = (val: SelectValue) => {
        onChangeSort!(val as string);
    };
    const onClickSearchButton = () => {
        if (search) {
            inputRef.current?.focus();
        } else {
            setIsSearchExpanded((value) => !value);
        }
    };
    const onClickCloseSearch = () => {
        if (search && onSearch) {
            onSearch({ target: { value: undefined } } as unknown as ChangeEvent<HTMLInputElement>);
        }
        setIsSearchExpanded(false);
    };
    const onSearch: InputProps['onChange'] = (e) => {
        const value = e.target.value;

        setSearch(value);

        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }

        searchTimeout = window.setTimeout(() => {
            if (hasSearch) {
                onChangeSearch!(value);
            }
        }, 400);
    };
    const onInputKeyDown: InputProps['onKeyDown'] = (e) => {
        if (e.key === 'Escape') {
            onClickCloseSearch();
        }
    };
    const onModalFormSubmit: FormProps['onFinish'] = (values) => {
        onChangeFilter!(values.filter);
        onChangeSort!(values.sort);
        setIsModalOpen(false);
    };
    const renderSortSelect = (bordered: boolean = false, inForm: boolean = false) => (
        <Select
            bordered={bordered}
            defaultValue={inForm ? undefined : sortValue}
            onChange={onChangeSortSelect}
        >
            {sortOptions!.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    <FormattedMessage {...option.message} values={option.messageValues} />
                </Select.Option>
            ))}
        </Select>
    );
    const renderFiltersSelect = (bordered: boolean = false, inForm: boolean = false) => (
        <Select
            bordered={bordered}
            defaultValue={inForm ? undefined : filterValue}
            onChange={onChangeFilterSelect}
            className="filters-select"
            dropdownMatchSelectWidth={false}
        >
            {filterOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    <FormattedMessage {...option.message} values={option.messageValues} />
                </Select.Option>
            ))}
        </Select>
    );
    const renderFiltersDesktop = () => (
        <Breakpoint xl up>
            {filterMultiple || filterOptions.length > 5 ? (
                renderFiltersSelect()
            ) : (
                <BasicList inline>
                    {filterOptions.map((option) => (
                        <li key={option.value}>
                            <Button
                                className={option.value === filterValue ? 'is-active' : undefined}
                                onClick={onChangeFilter!.bind(null, option.value)}
                                type="link"
                            >
                                <FormattedMessage {...option.message} values={option.messageValues} />
                            </Button>
                        </li>
                    ))}
                </BasicList>
            )}
        </Breakpoint>
    );

    useEffect(() => {
        if (isSearchExpanded) {
            inputRef.current?.focus();
        }
    }, [isSearchExpanded]);

    return (
        <div className="filters">
            <div className="filters-left">
                {hasSearch && (
                    <div className="filters-search-wrapper">
                        <Button
                            onClick={onClickSearchButton}
                            type="primary"
                        >
                            <IconSearch />
                        </Button>
                        <div
                            className={classNames(
                                'filters-search-input-wrapper',
                                isSearchExpanded && 'is-expanded',
                            )}
                        >
                            <Input
                                onChange={onSearch}
                                onKeyDown={onInputKeyDown}
                                placeholder={formatMessage(genericMessages.search)}
                                ref={inputRef}
                                value={search}
                                size="small"
                            />
                            {isSearchExpanded && (
                                <Button
                                    type="link"
                                    className="filters-search-close-button"
                                    onClick={onClickCloseSearch}
                                >
                                    <IconClose />
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                {hasFilter && renderFiltersDesktop()}
            </div>
            <div className="filters-right">
                <Breakpoint lg down>
                    {hasSort && hasFilter ? (
                        <>
                            <Modal
                                title={<FormattedMessage {...messages.filterSortModalTitle} />}
                                wrapClassName="modal-fullscreen filters-mobile-modal"
                                onCancel={setIsModalOpen.bind(null, false)}
                                footer={null}
                                visible={isModalOpen}
                                mask={false}
                                closable
                            >
                                <Form
                                    onFinish={onModalFormSubmit}
                                    layout="vertical"
                                    requiredMark={false}
                                    form={form}
                                    initialValues={{
                                        sort: sortValue,
                                        filter: filterValue,
                                    }}
                                >
                                    <Form.Item
                                        label={formatMessage(messages.sortBy)}
                                        name="sort"
                                    >
                                        {renderSortSelect(true, true)}
                                    </Form.Item>
                                    <Divider />
                                    <Form.Item
                                        label={formatMessage(messages.sortBy)}
                                        name="filter"
                                    >
                                        {filterMultiple || filterOptions.length > 4 ? (
                                            renderFiltersSelect(true, true)
                                        ) : (
                                            <Radio.Group className="ant-radio-group-vertical">
                                                {filterOptions.map((option) => (
                                                    <Radio value={option.value} key={option.value}>
                                                        <FormattedMessage {...option.message} values={option.messageValues} />
                                                    </Radio>
                                                ))}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <Form.Item shouldUpdate={true} id="filters-mobile-modal-submit">
                                        {() => (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                size="large"
                                                disabled={
                                                    !form.isFieldsTouched() ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                                block
                                            >
                                                <FormattedMessage {...genericMessages.validate} />
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Button
                                type="link"
                                className="filters-sort-modal-button"
                                size="small"
                                onClick={setIsModalOpen.bind(null, true)}
                            >
                                <FormattedMessage {...messages.filterSortModalButton} />
                            </Button>
                        </>
                    ) : null}
                    {!hasSort && hasFilter ? (
                        renderFiltersSelect()
                    ) : null}
                </Breakpoint>
                {hasSort && (
                    <Breakpoint xl up>
                        <div className="filters-select">
                            <FormattedMessage {...messages.sortBy} tagName="span" />
                            {renderSortSelect()}
                        </div>
                    </Breakpoint>
                )}
            </div>
        </div>
    );
};

export default Filters;
