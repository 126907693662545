import { Card, Typography, Spin, Button, Dropdown, Menu } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, defineMessages, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';

import '../../assets/styles/NetworkingActionNotes.less';

import { Appointment } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { update, getAppointmentsUpdateState, AppointmentsState } from '../../store/actions/appointments';

import { IconContacts, IconMore } from '../../components/icons';
import CommentCard from '../../components/CommentCard';
import genericMessages from '../../locale/genericMessages';
import BasicList from '../../components/BasicList';
import NoteForm from './NoteForm';
import { isAppointmentCancelled } from '../../helpers/data';

const messages = defineMessages({
    title: {
        id: 'networking_action_notes.title',
        defaultMessage: 'Notes',
        description: 'Networking action notes block title',
    },
    addNote: {
        id: 'networking_action_notes.form.add',
        defaultMessage: 'Ajouter une note',
        description: 'Networking action notes block form add note',
    },
});

interface FormValues {
    text: string;
}

interface NetworkingActionNotesProps {
    appointment?: Appointment;
    appointmentUpdateState: AppointmentsState['update'];
    updateAppointment: typeof update.trigger;
}

const NetworkingActionNotes: FC<NetworkingActionNotesProps> = ({
    appointment, appointmentUpdateState, updateAppointment,
}) => {
    const [noteIndexToEdit, setNoteIndexToEdit] = useState<number | undefined>();
    const [isAddNoteFormVisible, setIsAddNoteFormVisible] = useState(false);
    const onSubmit = (noteIndex: number, val: FormValues) => {
        const newNote = {
            ...val,
            date: new Date().toISOString(),
        };

        updateAppointment({
            id: appointment?.id,
            appointment: {
                notes: appointment?.notes?.length ?
                    (
                        noteIndex === appointment.notes.length ?
                            [...appointment.notes, newNote] :
                            (
                                appointment.notes.map((note, index) =>
                                    index === noteIndex ?
                                        newNote :
                                        note,
                                )
                            )
                    ) :
                    [newNote],
            },
        });
        setNoteIndexToEdit(undefined);
        setIsAddNoteFormVisible(false);
    };
    const onDeleteNote = (noteIndex: number) => {
        updateAppointment({
            id: appointment?.id,
            appointment: {
                notes: appointment?.notes?.filter((_, index) => index !== noteIndex),
            },
        });
    };

    return (
        <Spin spinning={appointmentUpdateState.loading}>
            <Card className="networking-action-details-block-card networking-action-notes">
                <Typography.Title level={2}>
                    <IconContacts /> <FormattedMessage {...messages.title} />
                </Typography.Title>
                <BasicList>
                    {appointment?.notes?.map((note, index) =>
                        note.text && noteIndexToEdit !== index ?
                        (
                            <li key={index}>
                                <CommentCard
                                    key={index}
                                    title={<FormattedDate value={appointment.validatedAt} />}
                                    extra={!isAppointmentCancelled(appointment) && (
                                        <Dropdown
                                            overlay={(
                                                <Menu>
                                                    <Menu.Item onClick={setNoteIndexToEdit.bind(null, index)}>
                                                        <FormattedMessage {...genericMessages.edit} />
                                                    </Menu.Item>
                                                    <Menu.Item onClick={onDeleteNote.bind(null, index)} danger>
                                                        <FormattedMessage {...genericMessages.delete} />
                                                    </Menu.Item>
                                                </Menu>
                                            )}
                                            trigger={['click']}
                                            placement="bottomRight"
                                        >
                                            <Button type="link">
                                                <IconMore />
                                            </Button>
                                        </Dropdown>
                                    )}
                                >
                                    <p>{note.text}</p>
                                </CommentCard>
                            </li>
                        ) :
                        (
                            <li>
                                <NoteForm
                                    onSubmit={onSubmit.bind(null, index)}
                                    onCancel={setNoteIndexToEdit.bind(null, undefined)}
                                    note={appointment.notes?.[index]}
                                />
                            </li>
                        ),
                    )}
                </BasicList>
                {isAddNoteFormVisible && (
                    <NoteForm
                        onSubmit={onSubmit.bind(null, appointment?.notes && appointment?.notes.length ? appointment?.notes.length : 0)}
                        onCancel={setIsAddNoteFormVisible.bind(null, false)}
                    />
                )}
                {!isAppointmentCancelled(appointment) && !isAddNoteFormVisible && (
                    <Button
                        type="primary"
                        onClick={setIsAddNoteFormVisible.bind(null, true)}
                        ghost
                    >
                        <FormattedMessage {...messages.addNote} />
                    </Button>
                )}
            </Card>
        </Spin>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateAppointment: update.trigger,
    },
)(NetworkingActionNotes);
