import { combineReducers } from 'redux';
import { takeLatest, call, put, take, race, delay } from 'redux-saga/effects';

import {
    list as listApiCall,
    update as updateApiCall,
    NotificationListPayload,
    NotificationUpdatePayload,
} from '../api/notifications';
import { MainReducerState, RequestState } from '../reducers';
import { Notification, ListResponse } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { DataAction } from '../helpers/EzeeAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface NotificationsState {
    readonly list: RequestState<ListResponse<Notification>>;
    readonly update: RequestState<Notification>;
}

const initialState: NotificationsState = {
    list: {
        loading: false,
    },
    update: {
        loading: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    NotificationsState['list'], NotificationListPayload, ListResponse<Notification>
>('notifications/list', initialState.list, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const update = new EzeeAsyncAction<
    NotificationsState['update'], NotificationUpdatePayload, Notification
>('notifications/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.update,
    }),
});

// Reducer

export const notificationsReducer = combineReducers<NotificationsState>({
    list: list.reducer,
    update: update.reducer,
});

// Saga

export function* notificationsSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

function* listSaga(action: DataAction<NotificationListPayload>) {
    if (action.payload.poll) {
        yield put({ type: 'NOTIFICATIONS/START_POLLING' });
        yield race([call(pollTask, action), take('NOTIFICATIONS/STOP_POLLING')]);
    } else {
        try {
            const response = yield call(listApiCall, action.payload);

            return yield put(list.success(response));
        } catch (error) {
            return yield put(list.failure(error));
        }
    }
}

/* Worker Function */
function* pollTask(action: DataAction<NotificationListPayload>) {
    // let errorCount = 0;

    while (true) {
        try {
            const response = yield call(listApiCall, action.payload);

            yield put(list.success(response));
            yield delay(5000);
        } catch (error) {
            // errorCount++;
            yield put(list.failure(error));

            // if (errorCount === 15) {
            //     yield put({ type: 'NOTIFICATIONS/STOP_POLLING', error });
            // } else {
            //     yield delay(5000);
            // }
            yield delay(5000);
        }
    }
}

// Store helpers

export const getNotificationsListState = (state: MainReducerState) => state.notifications.list;
export const getNotificationUpdateState = (state: MainReducerState) => state.notifications.update;
