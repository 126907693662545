import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 30">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0zm0 2C7.82 2 2 7.82 2 15s5.82 13 13 13 13-5.82 13-13S22.18 2 15 2z" />
            <path d="M14.83 9.326a1 1 0 0 0 .028 1.27l3.123 3.456H9.913c-.504 0-.912.425-.912.948l.004.09c.044.48.433.857.908.857h8.07l-3.125 3.456c-.33.365-.313.98.03 1.332a.89.89 0 0 0 1.3-.034l4.56-5.05c.18-.183.25-.387.253-.644 0-.23-.11-.5-.254-.657l-4.56-5.052a.89.89 0 0 0-1.287-.044l-.07.072z" />
        </g>
    </svg>
);

const IconArrowRightCircle: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-arrow-right-circle${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconArrowRightCircle;
