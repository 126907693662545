// tslint:disable: max-line-length
import React, { FC, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message, Form, Button } from 'antd';
import { FormProps } from 'antd/lib/form';

import { Contact, ProgramTaskValidation } from '../../store/api/apiTypes';
import * as contactActions from '../../store/actions/contacts';
import { MainReducerState } from '../../store/reducers';

import Seo from '../../components/Seo';
import FullscreenLayout from '../../components/FullscreenLayout';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious, useQueryParams } from '../../hooks';
import { IconAddContact } from '../../components/icons';
import ContactForm from '../../components/ContactForm';
import ContactCreateMessages from './ContactCreateMessages';

interface ContactCreatePageProps {
    createContact: typeof contactActions.create.trigger;
    createContactState: contactActions.ContactsState['create'];
}

const ContactCreatePage: FC<ContactCreatePageProps> = ({
    createContact, createContactState,
}) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const location = useLocation<{
        previous?: string,
        values?: Partial<Contact>,
        referrer?: Contact,
        validationFields?: ProgramTaskValidation['fields'],
    } | undefined>();
    const previous = usePrevious({ createContactState });
    const queryParams = useQueryParams();
    const history = useHistory();
    const onQuit = useCallback((contactId?: string) => {
        history.replace(location.state?.previous || getRoute(RoutePathName.home), {
            from: getRoute(RoutePathName.contactCreate),
            initiator: location.state?.previous,
            contactId,
        });
    }, [history, location.state]);
    const onFormValidSubmit: FormProps<Partial<Contact>>['onFinish'] = (val) => {
        createContact({
            ...val,
            ...(queryParams.get('validationToken') ? { taskValidationToken: queryParams.get('validationToken') } : {}),
        });
    };

    useEffect(() => {
        if (previous?.createContactState.loading && !createContactState.loading) {
            if (createContactState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onQuit(createContactState.data?.id);
            }
        }
    }, [
        previous, createContactState.loading, createContactState.error, createContactState.data,
        formatMessage, onQuit,
    ]);

    return (
        <section id="create-action">
            <Seo title={formatMessage(ContactCreateMessages.title)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                title={<FormattedMessage {...ContactCreateMessages.title} />}
                icon={<IconAddContact />}
            />
            <FullscreenLayout.Content>
                <Spin spinning={createContactState.loading}>
                    <ContactForm
                        form={form}
                        onFinish={onFormValidSubmit}
                        createInitialValues={
                            location.state ?
                                {
                                    ...location.state?.values,
                                    ...(location.state.validationFields ?
                                        Object.entries(location.state.validationFields).reduce((acc, field) => ({
                                            ...acc,
                                            [field[0]]: field[1].value,
                                        }), {}) :
                                        {}
                                    ),
                                } :
                                undefined
                        }
                        initialReferrer={location.state?.referrer}
                        submit={(
                            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={createContactState.loading}
                                        size="large"
                                        disabled={
                                            form &&
                                            (
                                                !form.isFieldsTouched() ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            )
                                        }
                                    >
                                        <FormattedMessage {...ContactCreateMessages.submit} />
                                    </Button>
                                )}
                            </Form.Item>
                        )}
                    />
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createContactState: contactActions.getContactsCreateState(state),
});

export default connect(
    mapStateToProps,
    {
        createContact: contactActions.create.trigger,
    },
)(ContactCreatePage);
