import React, { FC, useState } from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Breakpoint } from 'react-socks';

import '../assets/styles/ContactListCard.less';

import { Contact } from '../store/api/apiTypes';
import { del as deleteContactAction } from '../store/actions/contacts';

import ContactsMessages from '../pages/contacts/ContactsMessages';
import { getFullName } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import { IconMore, IconPlus, IconReferred, IconReferrer, IconMail, IconPhone } from './icons';
import BasicList from './BasicList';
import DeletePopConfirm from './DeletePopConfirm';
import genericMessages from '../locale/genericMessages';

interface ContactListCardProps {
    contact: Contact;
    deleteContact: typeof deleteContactAction.trigger;
}

const ContactListCard: FC<ContactListCardProps> = ({ contact, deleteContact }) => {
    const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
    const history = useHistory();
    const onClickCreateNetworkingAction = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), {
            values: { contact: contact?.id },
            previous: getRoute(RoutePathName.contacts),
        });
    };
    const onClickDelete = () => {
        setIsDeleteConfirmVisible(false);
        deleteContact({ id: contact.id });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={getRoute(RoutePathName.contact, {contactId: contact.id})}>
                    <FormattedMessage {...ContactsMessages.cardSeeOrEdit} />
                </Link>
            </Menu.Item>
            <Menu.Item onClick={setIsDeleteConfirmVisible.bind(null, true)}>
                <FormattedMessage {...genericMessages.delete} />
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="contact-list-card">
            <p>
                <Link to={getRoute(RoutePathName.contact, { contactId: contact.id })}>
                    <strong>
                        {getFullName(contact.firstName, contact.lastName)}
                        {!!contact.referred?.length && <IconReferrer />}
                        {contact.referrer && <IconReferred />}
                    </strong>
                </Link>
                <FormattedMessage
                    {...ContactsMessages.cardJobTitle}
                    values={{
                        jobTitle: contact.job?.jobTitle,
                        company: contact.company?.name,
                    }}
                />
            </p>
            <Breakpoint className="contact-list-card-email" xl up>
                <BasicList>
                    {contact.email && <li><a href={`mailto:${contact.email}`}><IconMail />{contact.email}</a></li>}
                    {contact.phone && <li><a href={`tel:${contact.phone}`}><IconPhone />{contact.phone.replace(/(.{2})/g, '$1 ')}</a></li>}
                </BasicList>
            </Breakpoint>
            <Tooltip overlay={<FormattedMessage {...ContactsMessages.createNetworkingActionTooltip} />}>
                <Button
                    onClick={onClickCreateNetworkingAction}
                    type="primary"
                    shape="circle"
                >
                    <IconPlus />
                </Button>
            </Tooltip>
            <DeletePopConfirm
                onConfirm={onClickDelete}
                onCancel={setIsDeleteConfirmVisible.bind(null, false)}
                visible={isDeleteConfirmVisible}
            >
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <Button type="link" className="contact-list-card-menu-button">
                        <IconMore />
                    </Button>
                </Dropdown>
            </DeletePopConfirm>
        </div>
    );
};

export default connect(
    undefined,
    {
        deleteContact: deleteContactAction.trigger,
    },
)(ContactListCard);
