import React, { FC, useState, useEffect, ReactElement } from 'react';
import { Button, Checkbox, Typography, Radio, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Form, { FormProps } from 'antd/lib/form';

import '../../assets/styles/IntelligenceSurvey.less';

import {
    ProgramTaskSurvey, SurveyResponses, UserProgramTaskStatus, SupportedLanguage, SurveyAnswerIntelligence,
} from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import { getRoute, RoutePathName } from '../../routes';
import ProgramTaskDetailsMessages from '../../pages/programTaskDetails/ProgramTaskDetailsMessages';
import IntelligenceSurveyResults from './IntelligenceSurveyResults';
import SurveyMessages from './SurveyMessages';
import { RadioChangeEvent } from 'antd/lib/radio';

interface IntelligenceSurveyProps {
    data?: ProgramTaskSurvey;
    language?: SupportedLanguage;
    responses?: SurveyResponses<SurveyAnswerIntelligence>;
    loading?: boolean;
    onSubmit: FormProps['onFinish'];
    subTaskStatus?: UserProgramTaskStatus;
}

const IntelligenceSurvey: FC<IntelligenceSurveyProps> = ({ data, language, responses, loading, onSubmit, subTaskStatus }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onSubmitForm: FormProps['onFinish'] = (values) => {
        const { consent, ...restValues } = values;
        const result = Object.keys(restValues).reduce((acc, questionKey) => ({
            ...acc,
            [questionKey]: [{
                ...restValues[questionKey]?.[0],
                values: restValues[questionKey]?.[0]?.values?.filter((val: string) => val !== ''),
            }],
        }), {});
        onSubmit?.(result);
    };
    const onChangeRadio = (fieldName: Array<string | number>, e: RadioChangeEvent) => {
        form.setFields([{
            name: fieldName,
            value: e.target.value !== undefined ?
                `${e.target.value?.split('-').length}` :
                '0',
        }]);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    if (subTaskStatus === UserProgramTaskStatus.validated) {
        return (
            <IntelligenceSurveyResults
                responses={responses}
                language={language}
            />
        );
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={
                data?.questions.reduce((acc, questionData) => ({
                    ...acc,
                    [questionData.id]: [{
                        values: undefined,
                        label: '0',
                    }],
                }), {})
            }
            onFinish={onSubmitForm}
            className="intelligence-form"
            scrollToFirstError
        >
            {data?.description && (
                <Typography.Paragraph style={{ lineHeight: '1.1875rem' }}>
                    {data.description}
                </Typography.Paragraph>
            )}
            {data?.questions.map((question, questionIndex) => (
                <Form.List
                    key={question.id}
                    name={question.id}
                >
                    {(questionFields) => questionFields.map((questionField) => (
                        <Form.Item label={`${questionIndex + 1}. ${question.label ?? ''}`} key={questionField.key}>
                            <Form.Item name={[questionField.name, 'label']} hidden>
                                <Input />
                            </Form.Item>
                            <div className="intelligence-value-label">
                                <FormattedMessage {...SurveyMessages.intelligenceLowestValue} />
                            </div>
                            <Form.Item
                                name={[questionField.name, 'values']}
                                // tslint:disable: jsx-no-lambda
                                normalize={(value) => value?.split('-')}
                                getValueProps={(value) => ({ value: value?.join('-') })}
                                rules={[requiredRule]}
                                noStyle
                            >
                                <Radio.Group onChange={onChangeRadio.bind(null, [question.id, questionField.name, 'label'])}>
                                    {[0, 1, 2, 3, 4].map((index) => (
                                        <Radio.Button
                                            key={index}
                                            value={`${
                                                Array.from(
                                                    { length: index },
                                                    () => question.answers?.[0].values?.[0],
                                                ).join('-')}`}
                                        >
                                            {index}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                            <div className="intelligence-value-label">
                                <FormattedMessage {...SurveyMessages.intelligenceHighestValue} />
                            </div>
                        </Form.Item>
                    ))}
                </Form.List>
            ))}
            <Form.Item
                name="consent"
                valuePropName="checked"
                rules={[{
                    required: true,
                    message: formatMessage(formMessages.requiredField),
                    validator: (_, value) => !!value ? Promise.resolve() : Promise.reject(),
                }]}
                style={{ marginTop: '1.5rem' }}
            >
                <Checkbox>
                    <FormattedMessage
                        {...formMessages.dataConsent}
                        values={{
                            link: (...chunks: ReactElement[]) => (
                                <Link to={getRoute(RoutePathName.privacyPolicy)} target="_blank">
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            !form.getFieldValue('consent')
                            ||
                            (
                                form &&
                                (
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                )
                            )
                        }
                        loading={loading}
                    >
                        <FormattedMessage
                            {...ProgramTaskDetailsMessages.surveyTricamValidationSubmit}
                        />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default IntelligenceSurvey;
