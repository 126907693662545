import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Layout } from 'antd';

import '../assets/styles/Header.less';

import { getUser } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { User } from '../store/api/apiTypes';

import HomeMessages from '../pages/home/HomeMessages';
import { getRoute, RoutePathName } from '../routes';
import Img from './Img';
import Logo from './Logo';
interface HeaderProps {
    user?: User;
}

const PublicHeader: FC<HeaderProps> = ({ user }) => {
    const { formatMessage } = useIntl();
    const banner = user?.organization?.theme.bannerUrl ? (
        <Img src={user?.organization?.theme.bannerUrl} alt="banner" />
    ) : (
            <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="1440" height="96" viewBox="0 0 1440 96" preserveAspectRatio="none">
                <defs>
                    <path id="a" d="M1440 0v96H0V0z" />
                    <linearGradient id="b" x1="37.052%" x2="98.061%" y1="49.579%" y2="50.035%">
                        <stop offset="0%" stopColor="#A493F7" />
                        <stop offset="100%" stopColor="#6B5AED" />
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="c" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <use fill="#EFF2FF" fillRule="nonzero" xlinkHref="#a" />
                    <path fill="url(#b)" mask="url(#c)" d="M0 0h863v96H0z" />
                    <circle cx="-54" cy="-70" r="195" fill="#6B5AED" mask="url(#c)" />
                    <circle cx="1155.5" cy="722.5" r="779.5" fill="#16215C" mask="url(#c)" />
                </g>
            </svg>
        );

    return (
        <Layout.Header id="main-header">
            <div className="container">
                <div className="flex">
                    <Link
                        id="logo"
                        to={getRoute(RoutePathName.home)}
                        title={formatMessage(HomeMessages.title)}
                    >
                        <Logo />
                    </Link>
                </div>
            </div>
            <div id="header-banner">
                {banner}
            </div>
        </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(PublicHeader);
