import { defineMessages } from 'react-intl';

const messages = defineMessages({
    yes: {
        id: 'yes',
        defaultMessage: 'Oui',
        description: 'yes',
    },
    no: {
        id: 'no',
        defaultMessage: 'Non',
        description: 'no',
    },
    validate: {
        id: 'validate',
        defaultMessage: 'Valider',
        description: 'Validate',
    },
    reset: {
        id: 'reset',
        defaultMessage: 'Reset',
        description: 'Reset',
    },
    noResults: {
        id: 'no_results',
        defaultMessage: 'Aucuns résultats',
        description: 'No results',
    },
    defaultError: {
        id: 'default_error',
        defaultMessage: 'Nous sommes désolés, une erreur inattendue est survenue, veuillez réessayer plus tard.',
        description: 'Default error message',
    },
    emailDuplicateError: {
        id: 'email_duplicate_error',
        defaultMessage: 'L\'email indiqué est déjà utilisé par un autre utilisateur.',
        description: 'Email error message',
    },
    error: {
        id: 'error',
        defaultMessage: 'Erreur',
        description: 'Error',
    },
    privacyPolicy: {
        id: 'privacy_policy',
        defaultMessage: 'Politique de confidentialité des données',
        description: 'Privacy policy link text',
    },
    faq: {
        id: 'faq',
        defaultMessage: 'FAQ',
        description: 'FAQ link text',
    },
    faqTitle: {
        id: 'faq_title',
        defaultMessage: 'Foire aux questions',
        description: 'FAQ page title',
    },
    termsOfUse: {
        id: 'terms_of_use',
        defaultMessage: 'Conditions générales d\'utilisation',
        description: 'Terms of use link text',
    },
    accountMenu: {
        id: 'account_menu',
        defaultMessage: 'Paramètres du compte',
        description: 'User menu account link',
    },
    documentsMenu: {
        id: 'documents_menu',
        defaultMessage: 'Vos notes et documents',
        description: 'User menu documents link',
    },
    tutorialMenu: {
        id: 'tutorial_menu',
        defaultMessage: 'L\'application en 2 mots',
        description: 'User menu tutorial link',
    },
    logout: {
        id: 'logout',
        defaultMessage: 'Déconnexion',
        description: 'User menu logout link',
    },
    civilityMister: {
        id: 'civility.mister',
        defaultMessage: 'M',
        description: 'Civility > Mr',
    },
    civilityMissus: {
        id: 'civility.missus',
        defaultMessage: 'Mme',
        description: 'Civility > Mrs',
    },
    civilityMiss: {
        id: 'civility.miss',
        defaultMessage: 'Mme',
        description: 'Civility > Ms',
    },
    civilityDoctor: {
        id: 'civility.doctor',
        defaultMessage: 'Docteur',
        description: 'Civility > Dr',
    },
    civilityLawyer: {
        id: 'civility.lawyer',
        defaultMessage: 'Maître',
        description: 'Civility > Lawyer',
    },
    civilityProfessor: {
        id: 'civility.professor',
        defaultMessage: 'Professeur',
        description: 'Civility > Professor',
    },
    civilityUnknown: {
        id: 'civility.unknown',
        defaultMessage: 'Inconnu',
        description: 'Civility > Unknown',
    },
    detailsLink: {
        id: 'details_link',
        defaultMessage: 'Voir le détail',
        description: 'Details link text',
    },
    action: {
        id: 'action',
        defaultMessage: `
            {count, plural,
                =0 {Action}
                one {Action}
                other {Actions}
            }
        `,
        description: 'Action',
    },
    progression: {
        id: 'progression',
        defaultMessage: 'Progression',
        description: 'Progression',
    },
    pointsShort: {
        id: 'points_short',
        defaultMessage: 'Pts',
        description: 'Short notation for `points`',
    },
    pointsShortPlural: {
        id: 'points_short.plural',
        defaultMessage: `
            {count, plural,
                =0 {{value} Pt}
                one {{value} Pt}
                other {{value} Pts}
            }
        `,
        description: 'Plural variation of short notation for `points` with count',
    },
    backHomeButton: {
        id: 'back_home_button',
        defaultMessage: 'Revenir à l\'accueil',
        description: 'Back to home button',
    },
    dateAndTime: {
        id: 'date_and_time',
        defaultMessage: '{date} à {time}',
        description: 'Date and time (used in meeting action cards)',
    },
    validateAction: {
        id: 'validate_action',
        defaultMessage: 'Valider l\'action',
        description: 'Validate action buttons',
    },
    cv: {
        id: 'cv',
        defaultMessage: 'Mon CV',
        description: 'Action type',
    },
    linkedin: {
        id: 'linkedin',
        defaultMessage: 'Mon LinkedIn',
        description: 'Action type',
    },
    professionalProject: {
        id: 'professional_project',
        defaultMessage: 'Mon projet',
        description: 'Action type',
    },
    professionalProjectShort: {
        id: 'professional_project.short',
        defaultMessage: 'Mon projet',
        description: 'Action type',
    },
    offer: {
        id: 'offer',
        defaultMessage: 'Mes candidatures',
        description: 'Action type',
    },
    networking: {
        id: 'networking',
        defaultMessage: 'Mon réseau',
        description: 'Action type',
    },
    communication: {
        id: 'communication',
        defaultMessage: 'Ma communication',
        description: 'Action type',
    },
    hiring: {
        id: 'hiring',
        defaultMessage: 'Recrutement',
        description: 'Action type',
    },
    privateLife: {
        id: 'private_life',
        defaultMessage: 'Vie personnelle',
        description: 'Action type',
    },
    retirement: {
        id: 'retirement',
        defaultMessage: 'Mon dossier administratif',
        description: 'Action type',
    },
    businessCreation: {
        id: 'business_creation',
        defaultMessage: 'Création d\'entreprise',
        description: 'Action type',
    },
    otherProgramTaskFamily: {
        id: 'program_task_family.other',
        defaultMessage: 'Informations générales',
        description: 'Action type',
    },
    other: {
        id: 'other',
        defaultMessage: 'Autre',
        description: 'Action type',
    },
    dateInterval: {
        id: 'date_interval',
        defaultMessage: 'Du {startDate} au {endDate}',
        description: 'Date interval',
    },
    previousWeek: {
        id: 'previous_week',
        defaultMessage: 'Semaine précédente',
        description: 'Previous week',
    },
    nextWeek: {
        id: 'next_week',
        defaultMessage: 'Semaine suivante',
        description: 'Next week',
    },
    weekNumber: {
        id: 'week_number',
        defaultMessage: 'Semaine {number}',
        description: 'Week number',
    },
    sortByDate: {
        id: 'sort_by_date',
        defaultMessage: 'Date d\'ajout',
        description: 'List sort item',
    },
    sortAlphabetically: {
        id: 'sort_alphabetically',
        defaultMessage: 'Ordre alphabétique',
        description: 'List sort item',
    },
    search: {
        id: 'search',
        defaultMessage: 'Rechercher',
        description: 'search',
    },
    switchToUser: {
        id: 'switch_to_user',
        defaultMessage: 'Site utilisateur',
        description: 'Switch to user section',
    },
    switchToClient: {
        id: 'switch_to_client',
        defaultMessage: 'Gestion des utilisateurs',
        description: 'Switch to client section',
    },
    continue: {
        id: 'continue',
        defaultMessage: 'Continuer',
        description: 'continue',
    },
    or: {
        id: 'or',
        defaultMessage: 'Ou',
        description: 'or',
    },
    documentTypeFile: {
        id: 'document.type.file',
        defaultMessage: 'Fiche',
        description: 'Document type',
    },
    documentTypeVideo: {
        id: 'document.type.video',
        defaultMessage: 'Vidéo',
        description: 'Document type',
    },
    documentTypePodcast: {
        id: 'document.type.podcast',
        defaultMessage: 'Podcast',
        description: 'Document type',
    },
    contactTypeManager: {
        id: 'contact.type.manager',
        defaultMessage: 'Mon manager',
        description: 'Contact type',
    },
    contactTypeHr: {
        id: 'contact.type.hr',
        defaultMessage: 'Mon RH',
        description: 'Contact type',
    },
    contactTypeNetworkAngel: {
        id: 'contact.type.network_angel',
        defaultMessage: 'MMon Coach',
        description: 'Contact type',
    },
    contactTypeInternalNetworkContact: {
        id: 'contact.type.internal_network_contact',
        defaultMessage: 'Contact interne',
        description: 'Contact type',
    },
    contactTypeExternalNetworkContact: {
        id: 'contact.type.external_network_contact',
        defaultMessage: 'Contact networking',
        description: 'Contact type',
    },
    contactTypeHeadHunter: {
        id: 'contact.type.head_hunter',
        defaultMessage: 'Recruteurs',
        description: 'Contact type',
    },
    actionTypeInformationQuery: {
        id: 'action.type.information_query',
        defaultMessage: 'Demande d\'information/conseil',
        description: 'Action type',
    },
    actionTypeFeedbackQuery: {
        id: 'action.type.feedback_query',
        defaultMessage: 'Demande de feedback',
        description: 'Action type',
    },
    actionTypeAdviceQuery: {
        id: 'action.type.advice_query',
        defaultMessage: 'Entretien coaching',
        description: 'Action type',
    },
    actionTypeReminder: {
        id: 'action.type.reminder',
        defaultMessage: 'Rappel à mon bon souvenir',
        description: 'Action type',
    },
    actionTypeJobInterview: {
        id: 'action.type.job_interview',
        defaultMessage: 'Entretien de recrutement',
        description: 'Action type',
    },
    actionTypeHelpInformationQuery: {
        id: 'action.type.help.information_query',
        defaultMessage: 'Recueillez des connaissances spécifiques de votre interlocuteur sur un secteur, une typologie d’entreprises, ou un métier.',
        description: 'Action type help',
    },
    actionTypeHelpFeedbackQuery: {
        id: 'action.type.help.feedback_query',
        defaultMessage: 'Recueillez l\'analyse de votre interlocuteur sur votre projet professionnel, votre CV, ou peut- être votre stratégie de recherche d’emploi.',
        description: 'Action type help',
    },
    actionTypeHelpAdviceQuery: {
        id: 'action.type.help.advice_query',
        defaultMessage: 'Recueillez l\'avis de votre interlocuteur sur des problématiques variées : comment aborder ce secteur d’activité, ce métier, ce processus de recrutement…',
        description: 'Action type help',
    },
    actionTypeHelpReminder: {
        id: 'action.type.help.reminder',
        defaultMessage: 'Contactez votre interlocuteur pour garder le lien, nourrir la relation, qu\'il se rappelle de vous et de votre projet.',
        description: 'Action type help',
    },
    actionTypeHelpJobInterview: {
        id: 'action.type.help.job_interview',
        defaultMessage: 'Vous avez été sélectionné pour un entretien avec un potentiel futur employeur.',
        description: 'Action type help',
    },
    actionMeetingTypeFaceToFace: {
        id: 'action.type.face_to_face',
        defaultMessage: 'En personne',
        description: 'Action meeting type',
    },
    actionMeetingTypePhone: {
        id: 'action.type.phone',
        defaultMessage: 'Par téléphone',
        description: 'Action meeting type',
    },
    actionMeetingTypeVideo: {
        id: 'action.type.video',
        defaultMessage: 'Par visio-conférence',
        description: 'Action meeting type',
    },
    networkingAction: {
        id: 'networking_action',
        defaultMessage: 'Action de Networking',
        description: 'Networking action',
    },
    genderMan: {
        id: 'gender.man',
        defaultMessage: 'Homme',
        description: 'Gender',
    },
    genderWoman: {
        id: 'gender.woman',
        defaultMessage: 'Femme',
        description: 'Gender',
    },
    spokenLanguageLevelSchool: {
        id: 'spoken_language.level.school',
        defaultMessage: 'Scolaire',
        description: 'Spoken language level',
    },
    spokenLanguageLevelNotions: {
        id: 'spoken_language.level.notions',
        defaultMessage: 'Notions',
        description: 'Spoken language level',
    },
    spokenLanguageLevelFluent: {
        id: 'spoken_language.level.fluent',
        defaultMessage: 'Courant',
        description: 'Spoken language level',
    },
    spokenLanguageLevelBilingual: {
        id: 'spoken_language.level.bilingual',
        defaultMessage: 'Bilingue',
        description: 'Spoken language level',
    },
    spokenLanguageLevelSchoolHelp: {
        id: 'spoken_language.level.school.help',
        defaultMessage: 'Ce niveau de langue élémentaire signifie que vous êtes en mesure de comprendre et d’employer des formulations très simples (se présenter).',
        description: 'Spoken language level help',
    },
    spokenLanguageLevelNotionsHelp: {
        id: 'spoken_language.level.notions.help',
        defaultMessage: 'Ce niveau intermédiaire signifie que vous êtes capable de comprendre des points-clés dans une conversation, lorsque le langage est clair et standard. Vous vous débrouillez assez facilement.',
        description: 'Spoken language level help',
    },
    spokenLanguageLevelFluentHelp: {
        id: 'spoken_language.level.fluent.help',
        defaultMessage: 'Ce niveau de langue indique que vos connaissances linguistiques dépassent les connaissances de base, et qu’il est possible pour vous de mener une conversation sans difficultés. Vous êtes à l’aise ; vous pouvez communiquer avec fluidité et aisance et vous êtes capable de vous exprimer avec facilité sans trop chercher vos mots.',
        description: 'Spoken language level help',
    },
    spokenLanguageLevelBilingualHelp: {
        id: 'spoken_language.level.bilingual.help',
        defaultMessage: 'Ce niveau expérimenté indique qu’il s’agit de votre langue maternelle (natif) ou d’une ou plusieurs langues que vous maîtrisez extrêmement bien sur n’importe quel sujet. Vous êtes capable de comprendre sans effort tout ce que vous lisez ou ce que vous entendez et de vous exprimer de façon spontanée. Ce niveau correspond souvent à votre langue maternelle.',
        description: 'Spoken language level help',
    },
    jobStatusWorker: {
        id: 'job_status.worker',
        defaultMessage: 'Ouvrier',
        description: 'Job status',
    },
    jobStatusEtam: {
        id: 'job_status.etam',
        defaultMessage: 'ETAM',
        description: 'Job status',
    },
    jobStatusExecutive: {
        id: 'job_status.executive',
        defaultMessage: 'Cadre',
        description: 'Job status',
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Supprimer',
        description: 'delete',
    },
    export: {
        id: 'export',
        defaultMessage: 'Exporter',
        description: 'export',
    },
    addressTypePersonal: {
        id: 'address.type.personal',
        defaultMessage: 'Personnelle',
        description: 'address type',
    },
    addressTypeProfessional: {
        id: 'address.type.professional',
        defaultMessage: 'Professionnelle',
        description: 'address type',
    },
    addressTypeOther: {
        id: 'address.type.other',
        defaultMessage: 'Autre',
        description: 'address type',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Annuler',
        description: 'cancel',
    },
    edit: {
        id: 'edit',
        defaultMessage: 'Modifier',
        description: 'edit',
    },
    programStatusPending: {
        id: 'program_status.pending',
        defaultMessage: 'En attente',
        description: 'Program status',
    },
    programStatusStarted: {
        id: 'program_status.started',
        defaultMessage: 'En cours',
        description: 'Program status',
    },
    programStatusEnded: {
        id: 'program_status.ended',
        defaultMessage: 'Terminé',
        description: 'Program status',
    },
    closureTypeBusinessCreation: {
        id: 'program.closure.businessCreation',
        defaultMessage: 'Création d\'entreprise',
        description: 'Program closure type',
    },
    closureTypeTraining: {
        id: 'program.closure.training',
        defaultMessage: 'Formation de reconversion professionnelle',
        description: 'Program closure type',
    },
    closureTypeInternal: {
        id: 'program.closure.internal',
        defaultMessage: 'Mobilité interne',
        description: 'Program closure type',
    },
    closureTypeExternal: {
        id: 'program.closure.external',
        defaultMessage: 'Mobilité externe',
        description: 'Program closure type',
    },
    closureTypeRetired: {
        id: 'program.closure.retired',
        defaultMessage: 'Retraite',
        description: 'Program closure type',
    },
    closureTypeOther: {
        id: 'program.closure.other',
        defaultMessage: 'Autre',
        description: 'Program closure type',
    },
    jobOccupationAccountant: {
        id: 'job.occupation.accountant',
        defaultMessage: 'Achats / Compta / Gestion',
        description: 'Job occupation',
    },
    jobOccupationArt: {
        id: 'job.occupation.art',
        defaultMessage: 'Arts / artisanat d\'art / Spectacle',
        description: 'Job occupation',
    },
    jobOccupationBank: {
        id: 'job.occupation.bank',
        defaultMessage: 'Banque',
        description: 'Job occupation',
    },
    jobOccupationInsurance: {
        id: 'job.occupation.insurance',
        defaultMessage: 'Assurance',
        description: 'Job occupation',
    },
    jobOccupationConstruction: {
        id: 'job.occupation.construction',
        defaultMessage: 'Bâtiment / Travaux Publics',
        description: 'Job occupation',
    },
    jobOccupationSales: {
        id: 'job.occupation.sales',
        defaultMessage: 'Commerce / Vente',
        description: 'Job occupation',
    },
    jobOccupationCommunications: {
        id: 'job.occupation.communications',
        defaultMessage: 'Communication / Multimédia',
        description: 'Job occupation',
    },
    jobOccupationConsulting: {
        id: 'job.occupation.consulting',
        defaultMessage: 'Conseil / Etude',
        description: 'Job occupation',
    },
    jobOccupationMilitary: {
        id: 'job.occupation.military',
        defaultMessage: 'Défense',
        description: 'Job occupation',
    },
    jobOccupationPublishing: {
        id: 'job.occupation.publishing',
        defaultMessage: 'Edition / Journalisme',
        description: 'Job occupation',
    },
    jobOccupationManagement: {
        id: 'job.occupation.management',
        defaultMessage: 'Direction d\'entreprise',
        description: 'Job occupation',
    },
    jobOccupationAgriculture: {
        id: 'job.occupation.agriculture',
        defaultMessage: 'Espaces verts et naturels / Agriculture / Pêche / Soins aux animaux',
        description: 'Job occupation',
    },
    jobOccupationPublicService: {
        id: 'job.occupation.public_service',
        defaultMessage: 'Fonction publique',
        description: 'Job occupation',
    },
    jobOccupationTourism: {
        id: 'job.occupation.tourism',
        defaultMessage: 'Hôtellerie - Restauration / Tourisme / Animation',
        description: 'Job occupation',
    },
    jobOccupationProperty: {
        id: 'job.occupation.property',
        defaultMessage: 'Immobilier',
        description: 'Job occupation',
    },
    jobOccupationIndustry: {
        id: 'job.occupation.industry',
        defaultMessage: 'Industrie',
        description: 'Job occupation',
    },
    jobOccupationIT: {
        id: 'job.occupation.it',
        defaultMessage: 'Informatique / Télécommunication',
        description: 'Job occupation',
    },
    jobOccupationEcommerce: {
        id: 'job.occupation.ecommerce',
        defaultMessage: 'Internet / E.commerce',
        description: 'Job occupation',
    },
    jobOccupationMaintenance: {
        id: 'job.occupation.maintenance',
        defaultMessage: 'Installation / Maintenance',
        description: 'Job occupation',
    },
    jobOccupationMarketing: {
        id: 'job.occupation.marketing',
        defaultMessage: 'Marketing / Stratégie commerciale',
        description: 'Job occupation',
    },
    jobOccupationHR: {
        id: 'job.occupation.hr',
        defaultMessage: 'Ressources Humaines / Juridique',
        description: 'Job occupation',
    },
    jobOccupationHealth: {
        id: 'job.occupation.health',
        defaultMessage: 'Santé',
        description: 'Job occupation',
    },
    jobOccupationSecretarial: {
        id: 'job.occupation.secretarial',
        defaultMessage: 'Secrétariat / Assistanat',
        description: 'Job occupation',
    },
    jobOccupationPersonalCare: {
        id: 'job.occupation.personal_care',
        defaultMessage: 'Services à la personne / à la collectivité',
        description: 'Job occupation',
    },
    jobOccupationSports: {
        id: 'job.occupation.sports',
        defaultMessage: 'Sport',
        description: 'Job occupation',
    },
    jobOccupationTransport: {
        id: 'job.occupation.transport',
        defaultMessage: 'Transport / Logistique',
        description: 'Job occupation',
    },
    phone: {
        id: 'phone',
        defaultMessage: 'Téléphone',
        description: 'phone',
    },
    url: {
        id: 'url',
        defaultMessage: 'URL',
        description: 'url',
    },
    location: {
        id: 'location',
        defaultMessage: 'Lieu',
        description: 'location',
    },
    createdOn: {
        id: 'created_on',
        defaultMessage: 'Créé le {date}',
        description: 'Created on date',
    },
    creationDate: {
        id: 'creation_date',
        defaultMessage: 'Date de création',
        description: 'Creation date',
    },
    jobOfferTypeInternal: {
        id: 'job_offer.type.internal',
        defaultMessage: 'Candidature interne',
        description: 'Job offer type',
    },
    jobOfferTypeExternal: {
        id: 'job_offer.type.external',
        defaultMessage: 'Candidature externe',
        description: 'Job offer type',
    },
    businessTypeAgricultureForestryFishing: {
        id: 'business.type.agricultureForestryFishing',
        defaultMessage: 'Agriculture, sylviculture et pêche',
        description: 'Business type',
    },
    businessTypeExtractiveIndustries: {
        id: 'business.type.extractiveIndustries',
        defaultMessage: 'Industries extractives',
        description: 'Business type',
    },
    businessTypeManufacturingIndustry: {
        id: 'business.type.manufacturingIndustry',
        defaultMessage: 'Industrie manufacturière',
        description: 'Business type',
    },
    businessTypeProductionDistributionElectricityGas: {
        id: 'business.type.productionDistributionElectricityGas',
        defaultMessage: 'Production et distribution d\'électricité, de gaz, de vapeur et d\'air conditionné',
        description: 'Business type',
    },
    businessTypeWaterProductionDistribution: {
        id: 'business.type.waterProductionDistribution',
        defaultMessage: 'Production et distribution d\'eau ; assainissement, gestion des déchets et dépollution',
        description: 'Business type',
    },
    businessTypeConstruction: {
        id: 'business.type.construction',
        defaultMessage: 'Construction',
        description: 'Business type',
    },
    businessTypeTradeRepairAutomobilesMotorcycles: {
        id: 'business.type.tradeRepairAutomobilesMotorcycles',
        defaultMessage: 'Commerce ; réparation d\'automobiles et de motocycles',
        description: 'Business type',
    },
    businessTypeTransportationWarehousing: {
        id: 'business.type.transportationWarehousing',
        defaultMessage: 'Transports et entreposage',
        description: 'Business type',
    },
    businessTypeAccommodationCatering: {
        id: 'business.type.accommodationCatering',
        defaultMessage: 'Hébergement et restauration',
        description: 'Business type',
    },
    businessTypeInformationCommunication: {
        id: 'business.type.informationCommunication',
        defaultMessage: 'Information et communication',
        description: 'Business type',
    },
    businessTypeFinancialInsuranceActivities: {
        id: 'business.type.financialInsuranceActivities',
        defaultMessage: 'Activités financières et d\'assurance',
        description: 'Business type',
    },
    businessTypeRealEstateActivities: {
        id: 'business.type.realEstateActivities',
        defaultMessage: 'Activités immobilières',
        description: 'Business type',
    },
    businessTypeSpecializedScientificTechnicalActivities: {
        id: 'business.type.specializedScientificTechnicalActivities',
        defaultMessage: 'Activités spécialisées, scientifiques et techniques',
        description: 'Business type',
    },
    businessTypeAdministrativeSupportServiceActivities: {
        id: 'business.type.administrativeSupportServiceActivities',
        defaultMessage: 'Activités de services administratifs et de soutien',
        description: 'Business type',
    },
    businessTypePublicAdministration: {
        id: 'business.type.publicAdministration',
        defaultMessage: 'Administration publique',
        description: 'Business type',
    },
    businessTypeEducation: {
        id: 'business.type.education',
        defaultMessage: 'Enseignement',
        description: 'Business type',
    },
    businessTypeHumanHealthSocialAction: {
        id: 'business.type.humanHealthSocialAction',
        defaultMessage: 'Santé humaine et action sociale',
        description: 'Business type',
    },
    businessTypeArtsEntertainmentRecreationalActivities: {
        id: 'business.type.artsEntertainmentRecreationalActivities',
        defaultMessage: 'Arts, spectacles et activités récréatives',
        description: 'Business type',
    },
    businessTypeOtherServiceActivities: {
        id: 'business.type.otherServiceActivities',
        defaultMessage: 'Autres activités de services',
        description: 'Business type',
    },
    businessTypeActivitiesHouseholdsAsEmployers: {
        id: 'business.type.activitiesHouseholdsAsEmployers',
        defaultMessage: 'Activités des ménages en tant qu\'employeurs ; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre',
        description: 'Business type',
    },
    businessTypeExtraTerritorialActivities: {
        id: 'business.type.extraTerritorialActivities',
        defaultMessage: 'Activités extra-territoriales',
        description: 'Business type',
    },
    companySizeLessThan10: {
        id: 'company.size.lessThan10',
        defaultMessage: 'Moins de 10 salariés',
        description: 'Company size',
    },
    companySizeFrom10To249: {
        id: 'company.size.from10To249',
        defaultMessage: 'De 10 à  249 salariés',
        description: 'Company size',
    },
    companySizeFrom250To999: {
        id: 'company.size.from250To999',
        defaultMessage: 'De 250 à 999 salariés',
        description: 'Company size',
    },
    companySizeFrom1000To5000: {
        id: 'company.size.from1000To5000',
        defaultMessage: 'De 1.000 à 5.000 salariés',
        description: 'Company size',
    },
    companySizeMoreThan5000: {
        id: 'company.size.moreThan5000',
        defaultMessage: 'Plus de 5.000 salariés',
        description: 'Company size',
    },
    subTaskTypeText: {
        id: 'sub_task.type.text',
        defaultMessage: 'À Faire',
        description: 'Sub task type',
    },
    subTaskTypeDocument: {
        id: 'sub_task.type.document',
        defaultMessage: 'Document',
        description: 'Sub task type',
    },
    subTaskTypeDocumentToDo: {
        id: 'sub_task.type.document.to_do',
        defaultMessage: 'Comment s\'y prendre',
        description: 'Sub task type',
    },
    subTaskTypeDocumentToRead: {
        id: 'sub_task.type.document.to_read',
        defaultMessage: 'À Consulter',
        description: 'Sub task type',
    },
    subTaskTypeLink: {
        id: 'sub_task.type.link',
        defaultMessage: 'Cliquer',
        description: 'Sub task type',
    },
    subTaskTypeAction: {
        id: 'sub_task.type.action',
        defaultMessage: 'Contacter',
        description: 'Sub task type',
    },
    subTaskTypeUpload: {
        id: 'sub_task.type.upload',
        defaultMessage: 'Télécharger',
        description: 'Sub task type',
    },
    subTaskTypeSurvey: {
        id: 'sub_task.type.survey',
        defaultMessage: 'Questionnaire',
        description: 'Sub task type',
    },
    subTaskTypeSurveyBasic: {
        id: 'sub_task.type.survey.basic',
        defaultMessage: 'Bloc Notes',
        description: 'Sub task type',
    },
    subTaskTypeSurveyValidation: {
        id: 'sub_task.type.survey.validation',
        defaultMessage: 'Je fais le point',
        description: 'Sub task type',
    },
    subTaskTypeSurveyTricam: {
        id: 'sub_task.type.survey.tricam',
        defaultMessage: 'Test - Tricam',
        description: 'Sub task type',
    },
    subTaskTypeSurveyColors: {
        id: 'sub_task.type.survey.colors',
        defaultMessage: 'Test - Couleurs',
        description: 'Sub task type',
    },
    subTaskTypeSurveyIntelligence: {
        id: 'sub_task.type.survey.intelligence',
        defaultMessage: 'Test - 8 formes d\'intelligence',
        description: 'Sub task type',
    },
    backToTask: {
        id: 'back_to_task_link',
        defaultMessage: 'Retourner à l\'action',
        description: 'Back to task link',
    },
    close: {
        id: 'close',
        defaultMessage: 'Fermer',
        description: 'Close',
    },
    successfullySaved: {
        id: 'successfully_saved',
        defaultMessage: 'Sauvegardé avec succès',
        description: 'successfully saved',
    },
    badgeLevel1: {
        id: 'badge.level.1',
        defaultMessage: 'Argent en cours',
        description: 'badge level',
    },
    badgeLevel2: {
        id: 'badge.level.2',
        defaultMessage: 'Argent',
        description: 'badge level',
    },
    badgeLevel3: {
        id: 'badge.level.3',
        defaultMessage: 'Or',
        description: 'badge level',
    },
    unknown: {
        id: 'unknown',
        defaultMessage: 'Inconnu',
        description: 'unknown',
    },
    tutorialModal1: {
        id: 'tutorial_modal.1',
        defaultMessage: 'Bonjour et Bienvenue dans votre programme <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic>, le coach digital qui vous accompagne jusqu’à votre prochain emploi.{br}{br}Depuis votre ordinateur, votre smartphone ou votre tablette, <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic> vous guide, vous conseille et vous forme tout au long de votre recherche d’emploi.',
        description: 'Home tutorial modal',
    },
    tutorialModal2: {
        id: 'tutorial_modal.2',
        defaultMessage: 'Le programme <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic> s’organise autour d’actions à réaliser chaque semaine pour retrouver le plus rapidement possible un emploi adapté à vos attentes et à vos compétences.{br}{br}Rendez-vous donc chaque lundi sur la page d’accueil <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic> pour découvrir les actions de la semaine à compléter à votre rythme pour gagner les points correspondants !',
        description: 'Home tutorial modal',
    },
    tutorialModal3: {
        id: 'tutorial_modal.3',
        defaultMessage: 'Dans la rubrique « <strongPrimary>Votre programme</strongPrimary> », découvrez <primary>les différents cycles thématiques</primary> qui constituent ce programme, et la manière dont ils s’organisent dans le temps.{br}{br}Découvrez également <primary>l’ensemble des actions</primary> que vous aurez à réaliser pour obtenir l’emploi que vous souhaitez.',
        description: 'Home tutorial modal',
    },
    tutorialModal4: {
        id: 'tutorial_modal.4',
        defaultMessage: 'Pour chaque action à réaliser, <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic> vous donne <primary>des conseils</primary> sous forme de fiche, de podcast ou de vidéo.{br}{br}Vous pouvez retrouver l’ensemble de ces contenus dans la rubrique « <strongPrimary>Bibliothèque</strongPrimary> ».',
        description: 'Home tutorial modal',
    },
    tutorialModal5: {
        id: 'tutorial_modal.5',
        defaultMessage: '<strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic> vous aide également <primary>à vous organiser</primary>.{br}Saisissez vos contacts, vos rendez-vous et leurs comptes-rendus ainsi que les annonces auxquelles vous postulez et retrouvez-les facilement à tout moment.{br}{br}Sachez ainsi qui vous avez vu, qui vous avait recommandé auprès de qui, ce que vous vous êtes dit, quand, ou à quelles annonces vous avez répondu, depuis votre candidature jusqu’à la réponse définitive !',
        description: 'Home tutorial modal',
    },
    tutorialModal6: {
        id: 'tutorial_modal.6',
        defaultMessage: 'Vous pouvez <primary>suspendre votre programme</primary> quand vous partez en vacances ou quand vous voulez faire une pause ou reprendre les actions des semaines passées.{br}{br}Il vous suffit pour cela de vous rendre dans votre profil, rubrique « <strongPrimary>paramètres du compte</strongPrimary> ». Pendant cette suspension, vous aurez toujours accès aux actions des semaines précédentes, à la bibliothèque, aux actions de networking.',
        description: 'Home tutorial modal',
    },
    tutorialModal7: {
        id: 'tutorial_modal.7',
        defaultMessage: 'Quelle que soit votre situation, nous savons qu’une transition professionnelle est toujours une période sensible de remise en cause. Cette remise en cause est riche aussi de possibles, comme votre recherche d’emploi l’est de nouvelles rencontres excitantes.{br}{br}Nous vous souhaitons <primary>beaucoup de succès et de plaisir</primary> dans votre recherche d’emploi avec <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic>.',
        description: 'Home tutorial modal',
    },
    editAnswers: {
        id: 'edit_answers',
        defaultMessage: 'Quelle que soit votre situation, nous savons qu’une transition professionnelle est toujours une période sensible de remise en cause. Cette remise en cause est riche aussi de possibles, comme votre recherche d’emploi l’est de nouvelles rencontres excitantes.{br}{br}Nous vous souhaitons <primary>beaucoup de succès et de plaisir</primary> dans votre recherche d’emploi avec <strongPrimaryItalic>Mon coach Mobilité</strongPrimaryItalic>.',
        description: 'Home tutorial modal',
    },
});

export default messages;

export const jobOccupationMessages = new Map([
    ['jobOccupationAccountant', messages.jobOccupationAccountant],
    ['jobOccupationArt', messages.jobOccupationArt],
    ['jobOccupationBank', messages.jobOccupationBank],
    ['jobOccupationInsurance', messages.jobOccupationInsurance],
    ['jobOccupationConstruction', messages.jobOccupationConstruction],
    ['jobOccupationSales', messages.jobOccupationSales],
    ['jobOccupationCommunications', messages.jobOccupationCommunications],
    ['jobOccupationConsulting', messages.jobOccupationConsulting],
    ['jobOccupationMilitary', messages.jobOccupationMilitary],
    ['jobOccupationPublishing', messages.jobOccupationPublishing],
    ['jobOccupationManagement', messages.jobOccupationManagement],
    ['jobOccupationAgriculture', messages.jobOccupationAgriculture],
    ['jobOccupationPublicService', messages.jobOccupationPublicService],
    ['jobOccupationTourism', messages.jobOccupationTourism],
    ['jobOccupationProperty', messages.jobOccupationProperty],
    ['jobOccupationIndustry', messages.jobOccupationIndustry],
    ['jobOccupationIT', messages.jobOccupationIT],
    ['jobOccupationEcommerce', messages.jobOccupationEcommerce],
    ['jobOccupationMaintenance', messages.jobOccupationMaintenance],
    ['jobOccupationMarketing', messages.jobOccupationMarketing],
    ['jobOccupationHR', messages.jobOccupationHR],
    ['jobOccupationHealth', messages.jobOccupationHealth],
    ['jobOccupationSecretarial', messages.jobOccupationSecretarial],
    ['jobOccupationPersonalCare', messages.jobOccupationPersonalCare],
    ['jobOccupationSports', messages.jobOccupationSports],
    ['jobOccupationTransport', messages.jobOccupationTransport],
]);
