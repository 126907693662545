import { combineReducers } from 'redux';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import sortBy from 'lodash.sortby';

import {
    MyTasksListPayload,
    listMy as listMyApiCall,
    details as detailsApiCall,
    validate as validateApiCall,
    updateSubTask as updateSubTaskApiCall,
    TaskDetailsPayload,
    TaskValidatePayload,
    SubTaskUpdatePayload,
    HomeListMyPayload,
    HomeListFilter,
} from '../api/tasks';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, UserProgramTask, UserProgramTaskStatus } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { DateTime } from 'luxon';
import { DataAction } from '../helpers/EzeeAction';

// State

export type TasksListResponse = ListResponse<UserProgramTask>;
export type TasksListResponseState = RequestState<TasksListResponse>;

export interface TasksState {
    readonly listMy: TasksListResponseState;
    readonly homeListMy: {
        currentWeek: TasksListResponseState;
        previousWeeks: TasksListResponseState;
        nextWeek: TasksListResponseState;
    };
    readonly homeListCount: TasksListResponseState;
    readonly details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: UserProgramTask;
        };
    };
    readonly validate: {
        [token: string]: RequestState;
    };
    readonly updateSubTask: {
        [token: string]: RequestState;
    };
}

const initialState: TasksState = {
    listMy: {
        loading: false,
    },
    homeListMy: {
        currentWeek: {
            loading: false,
        },
        previousWeeks: {
            loading: false,
        },
        nextWeek: {
            loading: false,
        },
    },
    homeListCount: {
        loading: false,
    },
    details: {
        loading: false,
        data: {},
    },
    validate: {},
    updateSubTask: {},
};

// Actions/Reducers

export const listMy = new EzeeAsyncAction<
    TasksState['listMy'],
    MyTasksListPayload,
    ListResponse<UserProgramTask>
>('tasks/listMy', initialState.listMy, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.listMy,
    }),
});

export const homeListMy = new EzeeAsyncAction<
    TasksState['homeListMy'], HomeListMyPayload, HomeListMyResponse
>('tasks/homeListMy', initialState.homeListMy, {
    trigger: (state, payload) => {
        if (payload.currentFilter === HomeListFilter.all) {
            if (payload.onlyRequestForThisFilter) {
                return {
                    ...state,
                    [payload.onlyRequestForThisFilter]: {
                        ...state[payload.onlyRequestForThisFilter],
                        loading: true,
                        success: undefined,
                        error: undefined,
                    },
                };
            } else {
                return {
                    currentWeek: {
                        ...state.currentWeek,
                        loading: true,
                        success: undefined,
                        error: undefined,
                    },
                    previousWeeks: {
                        ...state.previousWeeks,
                        loading: true,
                        success: undefined,
                        error: undefined,
                    },
                    nextWeek: {
                        ...state.nextWeek,
                        loading: true,
                        success: undefined,
                        error: undefined,
                    },
                };
            }
        } else {
            return {
                ...state,
                [payload.currentFilter]: {
                    ...state[payload.currentFilter],
                    loading: true,
                    success: undefined,
                    error: undefined,
                },
            };
        }
    },
    success: (state, payload) => {
        if (payload.currentFilter === HomeListFilter.all) {
            return {
                currentWeek: {
                    ...state.currentWeek,
                    data: payload.currentWeek,
                    loading: false,
                    success: true,
                },
                previousWeeks: {
                    ...state.previousWeeks,
                    data: payload.previousWeeks,
                    loading: false,
                    success: true,
                },
                nextWeek: {
                    ...state.nextWeek,
                    data: payload.nextWeek,
                    loading: false,
                    success: true,
                },
            };
        } else {
            return {
                ...state,
                [payload.currentFilter]: {
                    ...state[payload.currentFilter],
                    data: payload[payload.currentFilter],
                    loading: false,
                    success: true,
                },
            };
        }
    },
    failure: (state, payload) => {
        if (payload.currentFilter === HomeListFilter.all) {
            return {
                currentWeek: {
                    ...state.currentWeek,
                    loading: false,
                    error: payload.error,
                },
                previousWeeks: {
                    ...state.previousWeeks,
                    loading: false,
                    error: payload.error,
                },
                nextWeek: {
                    ...state.nextWeek,
                    loading: false,
                    error: payload.error,
                },
            };
        } else {
            return {
                ...state,
                [payload.currentFilter]: {
                    data: payload[payload.currentFilter],
                    loading: false,
                    error: payload.error,
                },
            };
        }
    },
    reset: (state) => ({
        ...initialState.homeListMy,
    }),
});

export const homeListCount = new EzeeAsyncAction<
    TasksState['homeListCount'],
    MyTasksListPayload,
    ListResponse<UserProgramTask>
>('tasks/homeListCount', initialState.homeListCount, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => initialState.homeListCount,
});

export const details = new EzeeAsyncAction<
    TasksState['details'], TaskDetailsPayload, UserProgramTask
>('tasks/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: {
            ...state.data,
            [payload.id]: payload,
        },
    }),
    failure: (state, payload) => ({
        data: {},
        loading: false,
        error: payload,
    }),
    reset: () => initialState.details,
});

export const validate = new EzeeAsyncAction<
    TasksState['validate'], TaskValidatePayload
>('tasks/validate', initialState.validate, {
    trigger: (state, payload) => ({
        ...state,
        [payload.token]: {
            loading: true,
            error: undefined,
        },
    }),
    success: (state, { token, ...payload }) => ({
        ...state,
        [token]: {
            loading: false,
            data: payload,
        },
    }),
    failure: (state, { token, ...payload }) => ({
        ...state,
        [token]: {
            loading: false,
            error: payload,
        },
    }),
    reset: () => initialState.validate,
});

export const updateSubTask = new EzeeAsyncAction<
    TasksState['updateSubTask'], SubTaskUpdatePayload
>('tasks/updateSubTask', initialState.updateSubTask, {
    trigger: (state, payload) => ({
        ...state,
        [payload.subTaskId]: {
            loading: true,
            error: undefined,
        },
    }),
    success: (state, { subTaskId, ...payload }) => ({
        ...state,
        [subTaskId]: {
            loading: false,
            data: payload,
        },
    }),
    failure: (state, { subTaskId, ...payload }) => ({
        ...state,
        [subTaskId]: {
            loading: false,
            error: payload,
        },
    }),
    reset: () => initialState.updateSubTask,
});

// Reducer

export const tasksReducer = combineReducers<TasksState>({
    listMy: listMy.reducer,
    homeListMy: homeListMy.reducer,
    homeListCount: homeListCount.reducer,
    details: details.reducer,
    validate: validate.reducer,
    updateSubTask: updateSubTask.reducer,
});

// Saga

export function* tasksSaga() {
    yield takeLatest(listMy.type.trigger, listMySaga);
    yield takeLatest(homeListMy.type.trigger, homeListMySaga);
    yield takeLatest(homeListCount.type.trigger, simpleAsyncSaga(listMyApiCall, homeListCount));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(validate.type.trigger, validateSaga);
    yield takeLatest(updateSubTask.type.trigger, updateSubTaskSaga);
}

function* listMySaga(action: DataAction<MyTasksListPayload>) {
    try {
        const response = yield call(listMyApiCall, action.payload);

        return yield put(listMy.success(sortTasksByDateAndIndex(response)));
    } catch (error) {
        return yield put(listMy.failure(error));
    }
}

interface HomeListMyResponse {
    currentFilter: HomeListFilter;
    currentWeek: TasksListResponse;
    previousWeeks: TasksListResponse;
    nextWeek: TasksListResponse;
}

const getHomeListPayloadFromFilter = (currentFilter: HomeListFilter, programStartWeekNumber: number) => {
    const currentWeekNumber = DateTime.utc().weekNumber;

    switch (currentFilter) {
        case 'currentWeek':
            return {
                fromDate: currentWeekNumber !== programStartWeekNumber ?
                    DateTime.utc().startOf('week').toString() :
                    undefined,
                toDate: DateTime.utc().endOf('week').toString(),
            };
        case 'previousWeeks':
            return {
                toDate: DateTime.utc().minus({ week: 1 }).endOf('week').toString(),
                status: [UserProgramTaskStatus.pending, UserProgramTaskStatus.started],
                isPeriodValid: true,
            };
        case 'nextWeek':
            return {
                fromDate: (currentWeekNumber + 1) !== programStartWeekNumber ?
                    DateTime.utc().plus({ week: 1 }).startOf('week').toString() :
                    undefined,
                toDate: DateTime.utc().plus({ week: 1 }).endOf('week').toString(),
            };
        default: return {};
    }
};

const sortTasksByDateAndIndex = (response: any) => {
    if (Array.isArray(response?.items)) {
        return {
            ...response,
            items: sortBy(response.items, ['period.fromDate', 'task.index']),
        };
    }

    return response;
};

function* homeListMySaga(action: DataAction<HomeListMyPayload>) {
    const {
        currentFilter, programStartWeekNumber, currentWeekPage, previousWeeksPage, nextWeekPage,
        onlyRequestForThisFilter, ...payloadRest
    } = action.payload;
    const pageParam = {
        [HomeListFilter.currentWeek]: currentWeekPage,
        [HomeListFilter.previousWeeks]: previousWeeksPage,
        [HomeListFilter.nextWeek]: nextWeekPage,
    };

    if (currentFilter === HomeListFilter.all) {
        if (onlyRequestForThisFilter) { // filter === all but we only changed the page param for a single section
            try {
                const response = yield call(listMyApiCall, {
                    page: pageParam[onlyRequestForThisFilter] || undefined,
                    ...getHomeListPayloadFromFilter(onlyRequestForThisFilter, programStartWeekNumber),
                    ...payloadRest,
                    pageSize: onlyRequestForThisFilter === HomeListFilter.currentWeek ? 1337 : 5,
                });

                return yield put(homeListMy.success({
                    currentFilter: onlyRequestForThisFilter,
                    [onlyRequestForThisFilter]: sortTasksByDateAndIndex(response),
                }));
            } catch (error) {
                return yield put(homeListMy.failure({
                    currentFilter: onlyRequestForThisFilter,
                    [onlyRequestForThisFilter]: error,
                }));
            }
        } else {// do all 3 requests
            try {
                const [currentWeek, previousWeeks, nextWeek]: Array<ListResponse<UserProgramTask>> = yield all([
                    call(listMyApiCall, {
                        page: currentWeekPage,
                        ...getHomeListPayloadFromFilter(HomeListFilter.currentWeek, programStartWeekNumber),
                        ...payloadRest,
                        pageSize: 1337,
                    }),
                    call(listMyApiCall, {
                        page: previousWeeksPage,
                        ...getHomeListPayloadFromFilter(HomeListFilter.previousWeeks, programStartWeekNumber),
                        ...payloadRest,
                        pageSize: 5,
                    }),
                    call(listMyApiCall, {
                        page: nextWeekPage,
                        ...getHomeListPayloadFromFilter(HomeListFilter.nextWeek, programStartWeekNumber),
                        ...payloadRest,
                        pageSize: 5,
                    }),
                ]);

                return yield put(homeListMy.success({
                    currentFilter,
                    currentWeek: sortTasksByDateAndIndex(currentWeek),
                    previousWeeks: sortTasksByDateAndIndex(previousWeeks),
                    nextWeek: sortTasksByDateAndIndex(nextWeek),
                }));
            } catch (error) {
                return yield put(homeListMy.failure({
                    currentFilter,
                    error,
                }));
            }
        }
    } else {
        try {
            const response = yield call(listMyApiCall, {
                page: pageParam[currentFilter] || undefined,
                ...getHomeListPayloadFromFilter(currentFilter, programStartWeekNumber),
                ...payloadRest,
                pageSize: 15,
            });

            return yield put(homeListMy.success({
                currentFilter,
                [currentFilter]: sortTasksByDateAndIndex(response),
            }));
        } catch (error) {
            return yield put(homeListMy.failure({
                currentFilter,
                [currentFilter]: error,
            }));
        }
    }
}

function* validateSaga(action: DataAction<TaskValidatePayload>) {
    const { token } = action.payload;

    try {
        const response = yield call(validateApiCall, action.payload);

        return yield put(validate.success({
            token,
            ...response,
        }));
    } catch (error) {
        return yield put(validate.failure({
            token,
            ...error,
        }));
    }
}

function* updateSubTaskSaga(action: DataAction<SubTaskUpdatePayload>) {
    const { subTaskId } = action.payload;

    try {
        const response = yield call(updateSubTaskApiCall, action.payload);

        return yield put(updateSubTask.success({
            subTaskId,
            ...response,
        }));
    } catch (error) {
        return yield put(updateSubTask.failure({
            subTaskId,
            ...error,
        }));
    }
}

// Store helpers

export const getMyTasksState = (state: MainReducerState) => state.tasks.listMy;
export const getHomeListMyTasksState = (state: MainReducerState) => state.tasks.homeListMy;
export const getIsHomePhraseLoading = (state: MainReducerState) => state.tasks.homeListCount.loading || state.programs.my.loading;
export const getHomePhraseTasksCount = (state: MainReducerState) => state.tasks.homeListCount.data?.totalCount || 0;

export type TaskDetailsState = RequestState<UserProgramTask>;

export const getTaskStateById = (state: MainReducerState, id: UserProgramTask['id']) => ({
    loading: state.tasks.details.loading,
    error: state.tasks.details.error,
    data: state.tasks.details.data[id],
});

export type TaskValidateState = RequestState;

export const getValidateStateByToken = (state: MainReducerState, token?: string) => ({
    loading: token && state.tasks.validate[token] ? state.tasks.validate[token].loading : false,
    error: token && state.tasks.validate[token] ? state.tasks.validate[token].error : undefined,
    data: token && state.tasks.validate[token] ? state.tasks.validate[token].data : undefined,
});

export type SubTaskUpdateState = RequestState;

export const getSubTaskUpdateStateBySubTaskId = (state: MainReducerState, subTaskId?: string) => ({
    loading: subTaskId && state.tasks.updateSubTask[subTaskId] ? state.tasks.updateSubTask[subTaskId].loading : false,
    error: subTaskId && state.tasks.updateSubTask[subTaskId] ? state.tasks.updateSubTask[subTaskId].error : undefined,
    data: subTaskId && state.tasks.updateSubTask[subTaskId] ? state.tasks.updateSubTask[subTaskId].data : undefined,
});
