import humanizeDuration from 'humanize-duration';

export const localizedDurationHumanizer = (ms: number, options?: humanizeDuration.Options) => humanizeDuration.humanizer({
    language: `short${window.__MCM__locale}`,
    spacer: '',
    languages: {
        shortfr: {
            y: () => 'a',
            mo: () => 'mo',
            w: () => 'sem',
            d: () => 'j',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        },
        shorten: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        },
    },
})(ms, options);
