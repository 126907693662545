import { Card, Typography, Empty } from 'antd';
import React, { FC, useEffect } from 'react';
import { FormattedMessage, defineMessages, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import '../../assets/styles/JobInterviews.less';

import { Appointment } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { list, AppointmentsState, getAppointmentsListState } from '../../store/actions/appointments';

import { getFullName } from '../../helpers';
import BasicList from '../../components/BasicList';
import { IconArrowRight, IconInterview } from '../../components/icons';
import { getRoute, RoutePathName } from '../../routes';
import CardSkeleton from '../../components/CardSkeleton';
import CommentCard from '../../components/CommentCard';

const messages = defineMessages({
    title: {
        id: 'job_interviews.title',
        defaultMessage: 'Entretiens précédents',
        description: 'Job interviews title',
    },
    link: {
        id: 'job_interviews.link',
        defaultMessage: 'Voir la fiche',
        description: 'Job interviews link to interview',
    },
});

interface JobInterviewsProps {
    appointment?: Appointment;
    appointmentsListState: AppointmentsState['list'];
    listAppointments: typeof list.trigger;
}

const JobInterviews: FC<JobInterviewsProps> = ({
    appointment, appointmentsListState, listAppointments,
}) => {

    useEffect(() => {
        if (appointment?.id) {
            listAppointments({
                firstAppointment: appointment?.firstAppointment?.id || appointment?.id,
                toDate: appointment?.date,
            });
        }
    }, [listAppointments, appointment]);

    return (
        <Card className="networking-action-details-block-card job-interviews">
            <Typography.Title level={2}>
                <IconInterview /> <FormattedMessage {...messages.title} />
            </Typography.Title>
            {!appointmentsListState.loading && !!appointmentsListState.data?.items.length && (
                <>
                    <BasicList>
                        {appointmentsListState.data?.items.map((appt) => (
                            <li key={appt.id}>
                                <Link to={getRoute(RoutePathName.networkingAction, {  networkingActionId: appt.id })}>
                                    <CommentCard
                                        title={<FormattedDate value={appt.date} />}
                                    >
                                        <p>{appt.title}</p>
                                        <footer>
                                            <p>
                                                <strong>{getFullName(appt.contact?.firstName, appt.contact?.lastName)}</strong><br />
                                                <small>{appt.contact?.company?.name}</small>
                                            </p>
                                            <p>
                                                <FormattedMessage {...messages.link} /> <IconArrowRight />
                                            </p>
                                        </footer>
                                    </CommentCard>
                                </Link>
                            </li>
                        ))}
                    </BasicList>
                </>
            )}
            {!appointmentsListState.loading && !appointmentsListState.data?.items.length && (
                <Empty />
            )}
            {appointmentsListState.loading && (
                <BasicList>
                    <li><CardSkeleton rows={2} /></li>
                </BasicList>
            )}
        </Card>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentsListState: getAppointmentsListState(state),
});

export default connect(
    mapStateToProps,
    {
        listAppointments: list.trigger,
    },
)(JobInterviews);
