import { User, Program, Pack, SubTaskType, UserProgramSubTaskStatus, SurveySubTaskType } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';
import { LibraryDocumentListPayload } from './library';

export interface UserDetailsPayload {
    id: string;
}

export interface UserSendWelcomeEmailPayload {
    ids: Array<User['id']>;
}

export interface UserSelectedProgramPayload {
    licensePack: Pack['id'];
    selectedProgram: Program['id'];
}

export interface UserImportPayload {
    file: RcFile;
}

export type UserListPayload = SearchPaginationQuery;

export type UserSubTaskListPayload = SearchPaginationQuery & {
    userId?: User['id'];
    type?: SubTaskType[];
    order?: string;
    status?: UserProgramSubTaskStatus | UserProgramSubTaskStatus[];
    hasResponded?: boolean;
    surveySubType?: SurveySubTaskType;
    isUser?: boolean;
};

export type UserFilePayload = LibraryDocumentListPayload & {
    userId?: User['id'];
    uploaded?: boolean;
    isUser?: boolean;
};

export const details = (payload: UserDetailsPayload) => api.get(`/users/${payload.id}`);

export const list = (payload: UserListPayload = {}) => api.get(`/users`, {params: cleanPayload(payload), headers: {accept: 'application/json'}});

export const exportAll = () => api.get('/users', { headers: { Accept: 'text/csv' } });

export const filesList = (payload: UserFilePayload) => api.get(
    payload.isUser ? '/me/library' : `/users/${payload.userId}/library`,
    {
        params: cleanPayload(payload),
        headers: {accept: 'application/json'},
    },
);

export const surveysList = (payload: UserSubTaskListPayload) => api.get(
    `/me/subTasks`,
    {
        params: cleanPayload(payload),
        headers: {accept: 'application/json'},
    },
);

export interface UserReportingListPayload {
    fromTime?: number;
    toTime?: number;
    returnUsers: boolean;
    organization: string;
    group?: string;
    userDetailsType: 'licenses' | 'sessions' | 'badges';
}

export const reportingList = ({ userDetailsType, ...payload }: UserReportingListPayload) => api.get(`/users/${userDetailsType}`, { params: payload });

export const create = (payload: User) => api.post(`/users`, payload);

export const update = (payload: User) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/users/${id}`, payload);
};

export const remove = (payload: User) => api.delete(`/users/${payload.id}`);

export const roles = (payload: UserListPayload = {}) => api.get(`/roles`, {params: cleanPayload(payload)});

export const sendWelcomeEmail = (payload: UserSendWelcomeEmailPayload) => api.post(`/users/sendWelcome`, payload);

export const importUsers = (payload: UserImportPayload) => {

    const formData = new FormData();
    formData.append('users', payload.file);

    return api.post(`/users`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
