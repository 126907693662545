import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M.557 19.746a.554.554 0 01.225.037c.632.236 1.106.841 1.17 1.422.037.347.006.645-.096 1.06l-.128.478c-.113.424-.181.79-.182 1l.004.08c-.009-.074-.032.146.074.263l.031.037a.129.129 0 00.014.013l.044.025.094.036.177.063c.394.14 1.11.019 2.102-.308l.662-.23c1.208-.426 1.888-.538 2.669-.312.286.083.606.423.677.697.021.08.03.133.03.193l-.033.48.305-.014a2.95 2.95 0 00.867-.142 10.6 10.6 0 001.176-.453l.284-.133a.565.565 0 01.611.009.589.589 0 01-.026.991l-.081.04c-.563.273-1.108.499-1.639.664-.817.253-1.304.322-1.845.197-.295-.068-.638-.389-.718-.658a.762.762 0 01-.04-.279l.041-.45-.266-.01c-.442-.018-1.046.1-1.791.34l-.34.114-.317.112c-1.238.435-1.915.535-2.695.258C.978 25.139.489 24.545.42 23.97c-.042-.35-.012-.648.09-1.068l.125-.467c.133-.498.208-.913.187-1.104.008.077.02-.106-.047-.228l-.065-.088-.01-.008-.04-.021-.268-.102a.586.586 0 01-.386-.64.576.576 0 01.46-.488l.091-.01zM31.542 0c.253 0 .458.221.458.494 0 2.106-.33 4.211-.982 6.257a.488.488 0 01-.206.268l-3.043 1.876h1.697c.16 0 .31.091.394.24.082.15.086.335.01.488-3.053 6.11-8.878 9.949-15.277 10.126a27.831 27.831 0 00-1.874 3.678.458.458 0 01-.418.292.428.428 0 01-.188-.044.511.511 0 01-.23-.653c1.518-3.652 3.609-6.592 6.534-9.97l.11-.128a113.585 113.585 0 011.693-1.905.522.522 0 00-.007-.7.433.433 0 00-.648.008c-2.366 2.604-4.262 4.869-5.81 7.223C14.568 7.728 22.229 0 31.543 0z"/>
    </svg>
);

const IconVerbal: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-verbal${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconVerbal;
