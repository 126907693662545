import { Permission, RoleSlug } from './../store/api/apiTypes';
import { User, PermissionRight } from '../store/api/apiTypes';

export const hasPermission = (user?: User, permission?: Permission, right: PermissionRight = PermissionRight.read) => {
    if (!permission) {
        return true;
    }
    switch (right) {
        case PermissionRight.read:
            return Boolean(user?.mergedPermissions?.[permission]);
        case PermissionRight.write:
            return user?.mergedPermissions?.[permission] === PermissionRight.write;
    }
    return false;
};

export const hasRole = (user?: User, roles?: RoleSlug[]) => {
    return (user?.role?.slug && roles) ? roles.includes(user.role.slug) : false;
};
