import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 16">
        <path fill="currentColor" d="M7.333 5.684a2.27 2.27 0 011.457.863l1.715-1.015c-.282-.82.036-1.756.797-2.206.86-.51 1.96-.208 2.457.674.496.882.202 2.009-.658 2.518a1.769 1.769 0 01-2.264-.395L9.123 7.137a2.39 2.39 0 010 1.726l1.714 1.014a1.768 1.768 0 012.263-.395c.86.51 1.155 1.636.659 2.518a1.774 1.774 0 01-2.456.675 1.866 1.866 0 01-.798-2.207L8.79 9.453a2.271 2.271 0 01-1.457.863v2.03c.834.16 1.465.91 1.465 1.81C8.798 15.176 7.993 16 7 16s-1.798-.825-1.798-1.843c0-.901.631-1.652 1.465-1.812v-2.029a2.27 2.27 0 01-1.457-.862l-1.715 1.014c.282.82-.036 1.756-.798 2.206-.86.51-1.96.208-2.456-.674S.04 9.99.9 9.482a1.769 1.769 0 012.264.395l1.713-1.014a2.391 2.391 0 010-1.726L3.164 6.123A1.768 1.768 0 01.9 6.518C.04 6.008-.255 4.882.24 4a1.774 1.774 0 012.456-.675c.762.451 1.08 1.387.798 2.207L5.21 6.547a2.271 2.271 0 011.457-.863v-2.03c-.834-.16-1.465-.91-1.465-1.811C5.202.825 6.007 0 7 0s1.798.825 1.798 1.843c0 .901-.631 1.652-1.465 1.812v2.029z"/>
    </svg>
);

const IconReferrer: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-referrer${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconReferrer;
