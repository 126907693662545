import { useState, useEffect, useMemo } from 'react';

import api from '../store/api/_client';
import { User } from '../store/api/apiTypes';

import { Panel, QueryResponseData } from '../types';
import { formatEndpoint, PrometheusPayload, getEnv } from '../helpers/reporting';
import { useQueryParams } from '.';

function fillWithZero(dataArray: any[], start?: string) {
    if (Array.isArray(dataArray)) {
        for (const data of dataArray) {
            if (Array.isArray(data.result)) {
                for (const r of data.result) {
                    const values: Array<[ number, string ]> = r.values ?? [];
                    if (values.length >= 2) {
                        const [ t0 ] = values[0];
                        const [ t1 ] = values[1];
                        const interval = t1 - t0;
                        if (start && t0 - Number(start) > interval) {
                            values.splice(0, 0, [ t0 - Math.floor((t0 - Number(start)) / interval) * interval, '0' ]);
                        }
                        for (let i = 0; i < values.length - 1; i++) {
                            const [ ti0 ] = values[i];
                            const [ ti1 ] = values[i + 1];
                            if (ti1 - ti0 > interval) {
                                values.splice(i + 1, 0, [ ti0 + interval, '0' ]);
                            } else if (interval !== ti1 - ti0) {
                                console.log('WRONG interval:', ti1, ti0, ti1 - ti0, (ti1 - ti0) / interval, (ti1 - ti0) % interval);
                            }
                        }
                        r.values = values;
                    }
                }
            }
        }
    }
    return dataArray;
}

const useQuery = <T = any>(panel: Panel, payload: Partial<PrometheusPayload>, user?: User) => {
    const [data, setData] = useState<Array<QueryResponseData<T> | undefined>>();
    const urlParams = useQueryParams();
    const distribParam = urlParams.get('distrib');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        (async () => {
            if (payload.params?.start && payload.params?.end) {
                setIsLoading(true);
                setError(undefined);
                let result: any = [];

                try {
                    const env = await getEnv();
                    result = await Promise.all(panel.targets.filter((target) => !target.hide).map((target) =>
                        api.get(formatEndpoint({
                            ...payload,
                            params: {
                                ...payload.params,
                                $organization: user?.organization?.id || '',
                                $env: env,
                            },
                            target,
                            distribParam,
                            panel,
                        })).then((response: any) => {
                            if (response.status === 'error') {
                                setError(response);
                            }
                            return response?.data;
                        }),
                    ));
                } catch (error) {
                    setError(error);
                }

                setIsLoading(false);
                setData(fillWithZero(result, payload.params?.start));
            }
        })();
    }, [panel, payload, user, distribParam]);

    return useMemo(() => ({ data, isLoading, error }), [data, isLoading, error]);
};

export default useQuery;
