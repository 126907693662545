import { defineMessages } from 'react-intl';

export default defineMessages({
    tricamResultsTitle: {
        id: 'surveys.tricam.results.title',
        defaultMessage: 'Vous avez obtenu les scores suivants :',
        description: 'TRICAM survey results',
    },
    tricamResultsStrongPoints: {
        id: 'surveys.tricam.results.strong_points',
        defaultMessage: 'Points forts :',
        description: 'TRICAM survey results',
    },
    tricamResultsWeakPoints: {
        id: 'surveys.tricam.results.weak_points',
        defaultMessage: 'Points faibles :',
        description: 'TRICAM survey results',
    },
    tricamResultsEnvironments: {
        id: 'surveys.tricam.results.environments',
        defaultMessage: 'Environnement conseillés :',
        description: 'TRICAM survey results',
    },
    tricamResultsCommunication: {
        id: 'surveys.tricam.results.communication',
        defaultMessage: 'Communication avec les autres :',
        description: 'TRICAM survey results',
    },
    tricamResultsSkills: {
        id: 'surveys.tricam.results.skills',
        defaultMessage: 'Principales compétences :',
        description: 'TRICAM survey results',
    },
    tricamResultsCombinations: {
        id: 'surveys.tricam.results.combinations',
        defaultMessage: 'Association {combination} :',
        description: 'TRICAM survey results',
    },
    tricamResultsJobs: {
        id: 'surveys.tricam.results.jobs',
        defaultMessage: 'Exemples de métiers pour les profils {combination} :',
        description: 'TRICAM survey results',
    },
    tricamCategoryTechnical: {
        id: 'surveys.tricam.category.technical',
        defaultMessage: 'Technicité',
        description: 'TRICAM survey category',
    },
    tricamCategoryReflection: {
        id: 'surveys.tricam.category.reflection',
        defaultMessage: 'Réflexion',
        description: 'TRICAM survey category',
    },
    tricamCategoryImagination: {
        id: 'surveys.tricam.category.imagination',
        defaultMessage: 'Imagination',
        description: 'TRICAM survey category',
    },
    tricamCategoryCooperation: {
        id: 'surveys.tricam.category.cooperation',
        defaultMessage: 'Coopération',
        description: 'TRICAM survey category',
    },
    tricamCategoryAction: {
        id: 'surveys.tricam.category.action',
        defaultMessage: 'Action',
        description: 'TRICAM survey category',
    },
    tricamCategoryMethod: {
        id: 'surveys.tricam.category.method',
        defaultMessage: 'Méthode',
        description: 'TRICAM survey category',
    },
    tricamProfile: {
        id: 'surveys.tricam.result.profile',
        defaultMessage: 'Votre profil est à dominante {results}.',
        description: 'TRICAM survey results profile',
    },
    colorsResultsProfile: {
        id: 'surveys.colors.result.profile',
        defaultMessage: 'Votre profil est à dominante : {first}{br}puis : {list}',
        description: 'Colors survey results profile',
    },
    colorsAnswerRed: {
        id: 'surveys.colors.answer.red',
        defaultMessage: 'Rouge',
        description: 'Colors survey answer',
    },
    colorsAnswerGreen: {
        id: 'surveys.colors.answer.green',
        defaultMessage: 'Vert',
        description: 'Colors survey answer',
    },
    colorsAnswerBlue: {
        id: 'surveys.colors.answer.blue',
        defaultMessage: 'Bleu',
        description: 'Colors survey answer',
    },
    colorsAnswerYellow: {
        id: 'surveys.colors.answer.yellow',
        defaultMessage: 'Jaune',
        description: 'Colors survey answer',
    },
    colorsWordCount: {
        id: 'surveys.colors.word_count',
        defaultMessage: `
            {count, plural,
                one {(1 mot)}
                other {({count, number} mots)}
            }
        `,
        description: 'Colors survey color word count',
    },
    colorsDescriptionTitle: {
        id: 'surveys.colors.description.title',
        defaultMessage: 'En tant que profil {color}',
        description: 'Colors survey color description title',
    },
    colorsAnswerDescriptionRed: {
        id: 'surveys.colors.answer.description.red',
        defaultMessage: '<p>Vous cherchez un résultat précis et tangible.</p><p> Vous aimez les objectifs ambitieux ; vous avez le sens du résultat. Vous êtes actif(ve). Votre environnement peut être hostile ; vous vous battez et vous restez compétitif(ve).</p><p>Vous résolvez les problèmes rapidement de manière indépendante et vous faites preuve d\'autorité.</p><p> Fonceur(se), vous aimez la vitesse, faire plusieurs choses en même temps.</p><p> Vous êtes motivé(e) par une forte détermination qui influence les personnes avec qui vous communiquez. </p><p> Vous communiquez avec les autres d\'une manière directe, et parfois agressive si votre interlocuteur n\'est pas assez rapide ou n\'a pas votre volonté. Quand vous exprimez votre point de vue franchement, vous pouvez être autoritaire. </p><p> <strong>Vous avez besoin dans votre vie professionnelle :</strong></p><ul><li>d\'objectifs ambitieux.</li><li>de défis et de pression.</li><li>d\'activités variées.</li><li>d\'options audacieuses et de prises de risques.</li><li>d\'une communication avec votre entourage directe</li></ul>{br}{br}<p>Vous pouvez par exemple vous orienter vers des métiers dans lesquels vous décidez rapidement et ou l\'action est importante.</p>',
        description: 'Colors survey answer',
    },
    colorsAnswerDescriptionGreen: {
        id: 'surveys.colors.answer.description.green',
        defaultMessage: '<p>Vous aimez être utile aux autres et construire des relations stables et durables.</p><p> Vous travaillez avec méthode, patience ; vous procédez pas à pas, avec une grande régularité. </p><p>Vous êtes considéré(e) comme un loyal(e) et pondéré(e). </p><p>Vous facilitez la vie du groupe, et vous cherchez souvent à aider. Vous acceptez de nombreux compromis. </p><p>Vous êtes à l\'écoute des autres et vos interlocuteurs se confient souvent à vous.</p><p>Vous avez besoin d\'anticiper, de travailler à votre rythme et vous n\'aimez pas travailler sous la pression de l\'urgence. </p><p>Parfois vous semblez lent(e) à vos interlocuteurs. Votre volonté de bien faire et de ne pas heurter les autres rend les décisions parfois difficiles à prendre. </p><p>Vous n\'aimez pas les conflits et préférez laisser les choses telles qu\'elles sont pour les éviter. </p><p>Vous n\'aimez pas particulièrement les changements et vous aurez besoin de comprendre la raison ou le sens de ceux qui vont troubler votre harmonie. Discret(e) par nature, vous préférez les relations individuelles plutôt qu\'en groupe. </p><p>Vous cachez vos émotions quitte à prendre sur vous et en subir les conséquences. Dire « non », peut s\'avérer être un vrai défi pour vous. </p><p> <strong>Vous avez besoin dans votre vie professionnelle :</strong></p><ul><li>d\'un environnement positif et stable.</li><li>d\'un travail vous permettant d\'anticiper votre organisation.</li><li>de temps pour progresser en procédant étape par étape.</li><li>de relations individualisées.</li></ul>{br}{br}<p>Vous pouvez par exemple vous orienter vers des métiers dans lesquels il y a peu de conflits mais des relations individuelles fortes.</p>',
        description: 'Colors survey answer',
    },
    colorsAnswerDescriptionBlue: {
        id: 'surveys.colors.answer.description.blue',
        defaultMessage: '<p>Vous souhaitez connaitre et comprendre le monde qui vous entoure. </p><p>Vous travaillez de manière précise et structurée en prenant un temps suffisant de réfléchir avant de passer à l\'action. </p><p>Vous aimez disposer de toutes les informations les plus exactes et complètes. Vous les analysez factuellement avant de vous décider. Vous prenez en compte à tous les aspects d\'une situation avant de vous lancer dans l\'action. </p><p>Vous suivez les règles et procédures et vous produisez un travail assez conventionnel. </p><p>Vous contrôlez la qualité de votre travail avec précision. Vous êtes calme et avez du recul ; vous nuancez vos idées. </p><p>Vous n\'appréciez pas toujours le débat d\'idées.Vous appuyez votre raisonnement sur des données factuelles et faites confiance à ce qui est écrit plutôt que dit. Vous conservez un point de vue détaché et objectif. </p><p>Vous n\'exprimez pas facilement vos sentiments et la distance que vous mettez dans vos relations peut parfois donner une impression de froideur ou de rigidité.</p><p>Vous pouvez parfois être indécis, froid, suspicieux et réservé.</p><p><strong>Vous avez besoin dans votre vie professionnelle :</strong></p><ul><li>d\'objectifs détaillés décomposés en étapes de progression.</li><li>d\'un cadre et un programme structurés.</li><li>de phases de travail technique solides.</li></ul>{br}{br}<p>Vous pouvez par exemple vous orienter vers des métiers de contrôle, de chiffres…</p>',
        description: 'Colors survey answer',
    },
    colorsAnswerDescriptionYellow: {
        id: 'surveys.colors.answer.description.yellow',
        defaultMessage: '<p>Vous rayonnez d\'enthousiasme et vous encouragez à la participation. </p><p>Vous appréciez et recherchez la compagnie d\'autrui. </p><p>Vous communiquez avec les autres d\'une manière engageante et accueillante. </p><p>Vous désirez être impliqué(e). Vous aimez être remarqué(e) et apprécié(e) pour vos contributions. </p><p>Vous avez parfois du mal à finir vos tâches. </p><p>Vous pouvez également être nerveux(se), indiscret(e), extravagant(e) et impulsif(ve).</p><p> <strong>Vous avez besoin dans votre vie professionnelle :</strong></p><ul><li>d\'une ambiance joyeuse et pleine de surprises.</li><li>de reconnaissance, de félicitations et de victoires fêtées.</li><li>de place pour l\'expression des sentiments.</li><li>de collaboration, d\'échanges et de rencontres.</li></ul>{br}{br}<p>Vous pouvez par exemple vous orienter vers des métiers dans lesquels il y a beaucoup de relation avec les autres.</p>',
        description: 'Colors survey answer',
    },
    intelligenceLowestValue: {
        id: 'surveys.intelligence.lowest_value',
        defaultMessage: 'Ne vous décrit pas du tout',
        description: 'Intelligence survey lowest value',
    },
    intelligenceHighestValue: {
        id: 'surveys.intelligence.highest_value',
        defaultMessage: 'Vous décrit entièrement',
        description: 'Intelligence survey highest value',
    },
    intelligenceProfile: {
        id: 'surveys.intelligence.result.profile',
        defaultMessage: 'Votre profil est à dominante {results}.',
        description: 'Intelligence survey results profile',
    },
    intelligenceAnswerVerbal: {
        id: 'surveys.intelligence.answer.verbal',
        defaultMessage: 'Verbale',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerMathematical: {
        id: 'surveys.intelligence.answer.mathematical',
        defaultMessage: 'Mathématique',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerMusical: {
        id: 'surveys.intelligence.answer.musical',
        defaultMessage: 'Musicale',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerVisual: {
        id: 'surveys.intelligence.answer.visual',
        defaultMessage: 'Visuelle',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerPhysical: {
        id: 'surveys.intelligence.answer.physical',
        defaultMessage: 'Corporelle',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerInterpersonal: {
        id: 'surveys.intelligence.answer.interpersonal',
        defaultMessage: 'Interpersonnelle',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerIntrapersonal: {
        id: 'surveys.intelligence.answer.intrapersonal',
        defaultMessage: 'Intrapersonnelle',
        description: 'Intelligence survey answer',
    },
    intelligenceAnswerNaturalist: {
        id: 'surveys.intelligence.answer.naturalist',
        defaultMessage: 'Naturaliste',
        description: 'Intelligence survey answer',
    },
});
