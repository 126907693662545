import { defineMessages } from 'react-intl';

import { JobOfferType, ContactType, JobLevel, Gender } from '../../../store/api/apiTypes';

import genericMessages from '../../../locale/genericMessages';

const messages = defineMessages({
    title: {
        id: 'client.dashboard.reporting.title',
        defaultMessage: 'Reporting de vos utilisateurs',
        description: 'Reporting title',
    },
    export: {
        id: 'client.dashboard.reporting.export',
        defaultMessage: 'Exporter les données',
        description: 'Reporting export',
    },
    searchPlaceholder: {
        id: 'client.dashboard.reporting.search.placeholder',
        defaultMessage: 'Rechercher un utilisateur',
        description: 'Reporting search placeholder',
    },
    datePlaceholder: {
        id: 'client.dashboard.reporting.date.placeholder',
        defaultMessage: 'Filtrer par date',
        description: 'Reporting datepicker placeholder',
    },
    groupSelect: {
        id: 'client.dashboard.reporting.group_select',
        defaultMessage: 'Tous les groupes',
        description: 'Reporting group select',
    },
    repartitionSelect: {
        id: 'client.dashboard.reporting.repartition_select',
        defaultMessage: 'Sélectionner répartition',
        description: 'Reporting repartition select',
    },
    repartitionSelectGender: {
        id: 'client.dashboard.reporting.repartition_select.gender',
        defaultMessage: 'Par sexe',
        description: 'Reporting repartition select',
    },
    repartitionSelectStatus: {
        id: 'client.dashboard.reporting.repartition_select.status',
        defaultMessage: 'Par statut',
        description: 'Reporting repartition select',
    },
    metricUserLicensesCountOnPeriod: {
        id: 'client.dashboard.reporting.metric.user_licenses_count_on_period',
        defaultMessage: 'Sièges attribués sur la période',
        description: 'Reporting metric',
    },
    metricUserLicensesCountMax: {
        id: 'client.dashboard.reporting.metric.user_licenses_count_max',
        defaultMessage: 'Salariés uniques qui ont eu un siège attribué',
        description: 'Reporting metric',
    },
    metricUserLicensesCountLast: {
        id: 'client.dashboard.reporting.metric.user_licenses_count_last',
        defaultMessage: 'Salariés uniques qui ont un siège attribué le dernier jour de la période',
        description: 'Reporting metric',
    },
    metricActiveUsersCount: {
        id: 'client.dashboard.reporting.metric.active_users_count',
        defaultMessage: 'Nombre d\'utilisateurs « actifs »',
        description: 'Reporting metric',
    },
    metricInactiveUsersCount: {
        id: 'client.dashboard.reporting.metric.inactive_users_count',
        defaultMessage: 'Nombre d\'utilisateurs « inactifs »',
        description: 'Reporting metric',
    },
    metricTotalSessionDuration: {
        id: 'client.dashboard.reporting.metric.total_session_duration',
        defaultMessage: 'Durée totale de connexion',
        description: 'Reporting metric',
    },
    metricAverageSessionCount: {
        id: 'client.dashboard.reporting.metric.average_session_count',
        defaultMessage: 'Moyenne de nombre de connexions',
        description: 'Reporting metric',
    },
    metricAverageSessionDuration: {
        id: 'client.dashboard.reporting.metric.average_session_duration',
        defaultMessage: 'Moyenne de durée de connexion',
        description: 'Reporting metric',
    },
    metricClientBadges: {
        id: 'client.dashboard.reporting.metric.badges',
        defaultMessage: 'Répartition des badges validés sur la période',
        description: 'Reporting metric',
    },
    metricAverageGoalSuccess: {
        id: 'client.dashboard.reporting.metric.average_goal_success',
        defaultMessage: 'Nombre de points validés',
        description: 'Reporting metric',
    },
    metricJobInterviewsDoneCount: {
        id: 'client.dashboard.reporting.metric.job_interview_done_count',
        defaultMessage: 'Nombre d\'entretiens organisés',
        description: 'Reporting metric',
    },
    metricSubmittedJobApplications: {
        id: 'client.dashboard.reporting.metric.submitted_job_applications',
        defaultMessage: 'Nombre de candidatures postées',
        description: 'Reporting metric',
    },
    metricAcceptedJobApplications: {
        id: 'client.dashboard.reporting.metric.accepted_job_applications',
        defaultMessage: 'Candidatures suivies d\'entretien',
        description: 'Reporting metric',
    },
    metricUserBadges: {
        id: 'client.dashboard.reporting.metric.user.badges',
        defaultMessage: 'Badges validés sur la période',
        description: 'Reporting metric',
    },
    metricUserSessionCount: {
        id: 'client.dashboard.reporting.metric.user.session_count',
        defaultMessage: 'Nombre de connexions',
        description: 'Reporting metric',
    },
    metricTotalSessionDurationDescription: {
        id: 'client.dashboard.reporting.metric.total_session_duration.description',
        defaultMessage: 'La durée totale de connexion correspond au temps passé sur l\'application depuis votre inscription. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    metricAverageSessionCountDescription: {
        id: 'client.dashboard.reporting.metric.average_session_count.description',
        defaultMessage: 'Le nombre de connexions correspond au nombre de fois où vous vous connectez et est comptabilisé après 6h d\'inactivité. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    metricUserSuspensionCount: {
        id: 'client.dashboard.reporting.metric.user.suspension_count',
        defaultMessage: 'Le nombre de suspension correspondent au nombre de semaines d\'interruption du programme utilisé. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    metricAverageSuspensionCountDescription: {
        id: 'client.dashboard.reporting.metric.user.suspension_count.description',
        defaultMessage: 'Semaines de suspension',
        description: 'Reporting metric',
    },
    metricAverageGoalSuccessDescription: {
        id: 'client.dashboard.reporting.metric.average_goal_success.description',
        defaultMessage: 'L\'application suit l\'évolution du nombre de points obtenus et le compare à l\'objectif. L\'indice mesure le nombre de points lié aux actions réalisées chaque jour. Il le compare aux points disponibles chaque semaine. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    metricJobInterviewsDoneCountDescription: {
        id: 'client.dashboard.reporting.metric.job_interview_done_count.description',
        defaultMessage: 'L\'application suit le nombre d\'entretiens organisés par catégorie d\'interlocuteurs. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information ',
        description: 'Reporting metric',
    },
    metricSubmittedJobApplicationsDescription: {
        id: 'client.dashboard.reporting.metric.submitted_job_applications.description',
        defaultMessage: 'L\'application suit le nombre des annonces auxquelles vous avez postulé. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    metricUserBadgesDescription: {
        id: 'client.dashboard.reporting.metric.user.badges.description',
        defaultMessage: 'Vous gagnez des badges d\'argent puis des badges d\'or dans chaque cycle : CV, LinkedIn, et Projet Professionnel. Votre service mobilité (RH, Managers, Conseillers de carrières, coach...) a accès à cette information',
        description: 'Reporting metric',
    },
    percentage: {
        id: 'client.dashboard.reporting.percentage',
        defaultMessage: 'Pourcentage',
        description: 'Reporting metric',
    },
    value: {
        id: 'reporting.value',
        defaultMessage: 'Valeur {index}',
        description: 'Reporting value',
    },
    nullValue: {
        id: 'reporting.null_value',
        defaultMessage: 'N/A',
        description: 'Reporting null value',
    },
    usersModalTitle: {
        id: 'client.dashboard.reporting.users_modal.title',
        defaultMessage: 'Détail des utilisateurs',
        description: 'Reporting users modal',
    },
    userReporting: {
        id: 'client.dashboard.reporting.users_modal.user_reporting',
        defaultMessage: 'Accès au reporting',
        description: 'Reporting users modal',
    },
});

export default messages;

export const metricsMessages = new Map([
    ['user_licenses_count_on_period', messages.metricUserLicensesCountOnPeriod],
    ['user_licenses_count_max', messages.metricUserLicensesCountMax],
    ['user_licenses_count_last', messages.metricUserLicensesCountLast],
    ['active_users_count', messages.metricActiveUsersCount],
    ['inactive_users_count', messages.metricInactiveUsersCount],
    ['total_session_duration', messages.metricTotalSessionDuration],
    ['average_session_count', messages.metricAverageSessionCount],
    ['average_session_duration', messages.metricAverageSessionDuration],
    ['client_badges', messages.metricClientBadges],
    ['average_goal_success', messages.metricAverageGoalSuccess],
    ['job_interview_done_count', messages.metricJobInterviewsDoneCount],
    ['submitted_job_applications', messages.metricSubmittedJobApplications],
    ['accepted_job_applications', messages.metricAcceptedJobApplications],
    ['user_badges', messages.metricUserBadges],
    ['session_count', messages.metricUserSessionCount],
    ['total_session_duration_description', messages.metricTotalSessionDurationDescription],
    ['session_count_description', messages.metricAverageSessionCountDescription],
    ['suspension_count', messages.metricUserSuspensionCount],
    ['suspension_count_description', messages.metricAverageSuspensionCountDescription],
    ['average_goal_success_description', messages.metricAverageGoalSuccessDescription],
    ['job_interview_done_count_description', messages.metricJobInterviewsDoneCountDescription],
    ['submitted_job_applications_description', messages.metricSubmittedJobApplicationsDescription],
    ['user_badges_description', messages.metricUserBadgesDescription],
    ['Percentage', messages.percentage],
    [`contactType${ContactType.internal}`, genericMessages.contactTypeInternalNetworkContact],
    [`contactType${ContactType.external}`, genericMessages.contactTypeExternalNetworkContact],
    [`jobOfferType${JobOfferType.internal}`, genericMessages.jobOfferTypeInternal],
    [`jobOfferType${JobOfferType.external}`, genericMessages.jobOfferTypeExternal],
    [ContactType.manager, genericMessages.contactTypeManager],
    [ContactType.hr, genericMessages.contactTypeHr],
    [ContactType.networkAngel, genericMessages.contactTypeNetworkAngel],
    [ContactType.headHunter, genericMessages.contactTypeHeadHunter],
    [JobLevel.worker, genericMessages.jobStatusWorker],
    [JobLevel.etam, genericMessages.jobStatusEtam],
    [JobLevel.executive, genericMessages.jobStatusExecutive],
    [Gender.man, genericMessages.genderMan],
    [Gender.woman, genericMessages.genderWoman],
    ['null', messages.nullValue],
]);
