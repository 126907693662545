import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { classNames } from '../../helpers';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M9.389 1.378V6.61h5.233c1.837 0 1.837 2.778 0 2.778H9.39v5.233c0 1.837-2.778 1.837-2.778 0V9.39H1.378c-1.837 0-1.837-2.778 0-2.778H6.61V1.378c0-1.837 2.778-1.837 2.778 0z" />
    </svg>
);

const IconPlus: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={classNames('icon-plus', props.className)}
    />
);

export default IconPlus;
