import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fillRule="nonzero" fill="none">
            <path d="M12 .5c3.176 0 6.05 1.287 8.132 3.368A11.464 11.464 0 0123.5 12c0 3.176-1.287 6.05-3.368 8.132A11.464 11.464 0 0112 23.5c-3.176 0-6.05-1.287-8.132-3.368A11.464 11.464 0 01.5 12c0-3.176 1.287-6.05 3.368-8.132A11.464 11.464 0 0112 .5z" stroke="currentColor" />
            <path d="M16.243 15.238l-7.5-7.5a.698.698 0 00-.986.02l-.046.05a.695.695 0 00.028.936l7.499 7.498a.71.71 0 001.005-1.004zm-7.48 1.005l7.498-7.5a.698.698 0 00-.018-.986l-.051-.046a.695.695 0 00-.936.028l-7.498 7.499a.71.71 0 001.004 1.005z" fill="currentColor" />
        </g>
    </svg>
);

const IconCrossCircle: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-cross-circle${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCrossCircle;
