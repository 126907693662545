import React, { FC, useMemo, ReactNode, memo, useEffect } from 'react';
import { IntlFormatters, useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useResizeAware from 'react-resize-aware';
import { Spin, Empty, Result } from 'antd';

import { getUser } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { User } from '../../store/api/apiTypes';

import useQuery from '../../hooks/useQuery';
import ReportingMessages, { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';
import { getQueryType, getParsedDescription, getLegendFormat, getMetricName } from '../../helpers/reporting';
import { Panel, QueryResponseData, PanelType, PanelParsedDescription } from '../../types';
import genericMessages from '../../locale/genericMessages';
import { useQueryParams } from '../../hooks';
import EnumMessage from '../EnumMessage';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { metrics } from '../../store/actions/reporting';

const formatGraphResponse = (
    response: QueryResponseData | undefined,
    panel: Panel,
    parsedDescription: PanelParsedDescription,
    distribParam: string | null,
    targetIndex: number,
    formatMessage: IntlFormatters['formatMessage'],
) => {
    if (!response) {
        return [];
    }

    const result = distribParam ?
        response.result.filter(({ metric }: any) => metric.badge ? true : metric[distribParam]) :
        response.result;

    if (panel.lines) {
        return result.map(({ metric, values }: any, i: number) => {
            let id = `${metric.__name__}-${i}`;

            if (distribParam === 'jobLevel' && getLegendFormat(panel, targetIndex) === 'gender') {
                id = metric.jobLevel || formatMessage(ReportingMessages.value, { index: i + 1 });
            } else if (panel.targets[targetIndex]?.legendFormat) {
                id = metric[getLegendFormat(panel, targetIndex)] ||
                     panel.targets[targetIndex]?.legendFormat ||
                     formatMessage(ReportingMessages.value, { index: i + 1 });
            }

            return {
                id,
                metric,
                data: values?.map(([x, y]: any) => ({
                    x: new Date(x * 1000),
                    y: parseFloat(y) || 0,
                })) || [],
            };
        });
    }

    if (panel.type === PanelType.barChart) {
        return result.reduce((acc: any, { metric, value }: any) => {
            const matchIndex = acc.findIndex((item: any) => item.badge === metric.badge);
            const currentBadgeLevel = metric.badge_level === 'null' ? 'lvl1' : metric.badge_level;

            if (matchIndex > -1) {
                acc.splice(matchIndex, 1, {
                    ...acc[matchIndex],
                    [currentBadgeLevel]: (acc[matchIndex][currentBadgeLevel] || 0) + (parseInt(value?.[1], 10) || 0),
                });
            } else {
                acc.push({
                    badge: metric.badge,
                    [currentBadgeLevel]: parseInt(value?.[1], 10) || 0,
                });
            }

            return acc;
        }, []);
    }

    return response;
};

interface GraphProps {
    panel: Panel;
    user?: User;
    params?: {
        [key: string]: string;
    };
    storeForExport: typeof metrics.actions.set;
    removeForExport: typeof metrics.actions.remove;
}

const Graph: FC<GraphProps> = memo(({ panel, params, user, storeForExport, removeForExport }) => {
    const { formatMessage } = useIntl();
    const urlParams = useQueryParams();
    const dateParam = urlParams.get('dateRange');
    const userParam = urlParams.get('user');
    const groupParam = urlParams.get('group');
    const distribParam = urlParams.get('distrib');
    const parsedDescription = getParsedDescription(panel.description);
    const dateRange = useMemo(() =>
        dateParam ?
            [parseInt(dateParam.split('-')[0], 10), parseInt(dateParam.split('-')[1], 10)] :
            undefined
    , [dateParam]);
    const [resizeListener, sizes] = useResizeAware();
    const { data, isLoading, error } = useQuery(panel, useMemo(() => ({
        queryType: getQueryType(panel),
        params: {
            ...(dateRange ? {start: `${Math.floor(dateRange[0] / 1000)}`} : {}),
            ...(dateRange ? {end: `${Math.floor(dateRange[1] / 1000)}`} : {}),
            ...(userParam ? { $user: userParam } : {}),
            ...(groupParam ? { $group: groupParam } : {}),
            ...params,
        },
    }), [dateRange, userParam, groupParam, panel, params]), user);

    let formattedData;
    let component: ReactNode = null;

    if (!error) {
        formattedData = data?.flatMap((d, index) => formatGraphResponse(d, panel, parsedDescription, distribParam, index, formatMessage));
    }

    if (!isLoading) {
        storeForExport({
            type: panel.lines ? 'lineChart' : 'barChart',
            translatedName: `${formatMessage(getMetricName(parsedDescription))}${
                panel.fieldConfig?.defaults.unit === 'ms' ? ' (ms)' : ''
                }${
                panel.fieldConfig?.defaults.unit === 's' ? ' (s)' : ''
                }${
                panel.yaxes?.[0]?.format === 'percent' ? ' (%)' : ''
                }`,
            name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription,
            value: formattedData,
            ...(userParam ? { user: urlParams.get('userName') } : {}),
            ...(groupParam ? { group: urlParams.get('groupName') } : {}),
            ...(distribParam ? { distrib: distribParam } : {}),
        });
    }

    if (panel.lines) {
        component = (
            <LineChart
                data={formattedData}
                panel={panel}
                width={sizes.width}
                height={sizes.height}
                xScaleMinMax={dateRange ? [new Date(dateRange[0]), new Date(Math.min(Date.now(), dateRange[1]))] : undefined}
            />
        );
    }

    if (panel.type === PanelType.barChart) {
        component = (
            <BarChart
                data={formattedData}
                panel={panel}
                width={sizes.width || 0}
                height={sizes.height || 0}
            />
        );
    }

    useEffect(() => () => {
        removeForExport({ name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <h2>
                <EnumMessage
                    map={metricsMessages}
                    value={typeof parsedDescription === 'object' ? parsedDescription.name || '' : parsedDescription}
                />
            </h2>
            {isLoading && <Spin />}
            {!isLoading && !error && (
                <>
                    {!formattedData || formattedData.every((d) => !d) ? (
                        <Empty />
                    ) : (
                        <div className="dashboard-graph-wrapper">
                            {resizeListener}
                            {component}
                        </div>
                    )}
                </>
            )}
            {!isLoading && error && (
                <Result
                    status="error"
                    subTitle={<FormattedMessage {...genericMessages.defaultError} />}
                />
            )}
        </div>
    );
});

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        storeForExport: metrics.actions.set,
        removeForExport: metrics.actions.remove,
    },
)(Graph);
