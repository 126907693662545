import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';
import { Program } from './apiTypes';

export interface ProgramDetailsPayload {
    id: string;
}

export type ProgramUpdatePayload = Program;

export type ProgramListPayload = SearchPaginationQuery;

export const details = (payload: ProgramDetailsPayload) => api.get(`/programs/${payload.id}`);

export const list = (payload: ProgramListPayload = {}) => api.get(`/programs`, {params: cleanPayload(payload)});

export const my = () => api.get('/me/program');

export const updateMy = (payload: ProgramUpdatePayload) => api.put('/me/program', payload);
