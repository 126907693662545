import React, { FC, memo } from 'react';

import { GrafanaDashboard, PanelType } from '../../types';
import Graph from './Graph';

import { DashboardContextProvider } from '../../context/DashboardContext';
import GridPanel from './GridPanel';
import Stat from './Stat';
import PieChart from './PieChart';
import { getPanelName } from '../../helpers/reporting';

interface DashboardBuilderComposition {
    Panels: FC<{
        json: GrafanaDashboard;
        isJobOfferPanelHidden?: boolean;
        params?: {
            [key: string]: string;
        }
    }>;
}

const DashboardBuilder: FC & DashboardBuilderComposition = ({ children }) => (
    <DashboardContextProvider>
        {children}
    </DashboardContextProvider>
);

export default DashboardBuilder;

const Panels: DashboardBuilderComposition['Panels'] = memo(({ json, isJobOfferPanelHidden, params }) => (
    <div className="dashboard-builder">
        <div className="dashboard-grid">
            {json.panels.filter((panel) => {
                const panelName = getPanelName(panel.description);

                if (isJobOfferPanelHidden && panelName === 'submitted_job_applications') {
                    return false;
                }

                return true;
            }).map((panel, index) => {
                const panelName = getPanelName(panel.description);
                const isFullWidth = isJobOfferPanelHidden && panelName === 'job_interview_done_count';
                let chart = null;

                switch (panel.type) {
                    case PanelType.barChart:
                    case PanelType.graph:
                        chart = <Graph panel={panel} params={params} />;
                        break;

                    case PanelType.pieChart:
                        chart = <PieChart panel={panel} params={params} />;
                        break;

                    case PanelType.stat:
                        chart = <Stat panel={panel} params={params} />;
                        break;
                }

                return (
                    <GridPanel key={index} panel={panel} isFullWidth={isFullWidth}>
                        {chart}
                    </GridPanel>
                );
            })}
        </div>
    </div>
));

DashboardBuilder.Panels = Panels;
