import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18">
        <path fill="currentColor" d="M18 14.708v1.243c0 1.128-.934 2.05-2.075 2.05h-1.712v-3.292H18zm-4.824 0V18H9.52v-3.292h3.656zm-9.39 0V18h-1.71C.933 18 0 17.078 0 15.95V14.71h3.787zm4.695 0V18H4.825v-3.292H8.48zM18 10.475v3.292h-3.787v-3.292H18zm-9.52 0v3.292H4.825v-3.292H8.48zm4.696 0v3.292H9.52v-3.292h3.656zm-9.39 0v3.292H0v-3.292h3.787zM18 6.28v3.293h-3.787V6.28H18zm-4.824 0v3.293H9.52V6.28h3.656zm-4.695 0v3.293H4.825V6.28H8.48zm-4.693 0v3.293H0V6.28h3.787zM2.75 1.69v.128c0 .87.674 1.562 1.555 1.562.882 0 1.583-.717 1.583-1.562V1.69h1.556v.128c0 .87.674 1.562 1.556 1.562s1.582-.717 1.582-1.562V1.69h1.556v.128c0 .87.7 1.562 1.557 1.562.855 0 1.556-.717 1.556-1.562v-.102h.675c1.14 0 2.075.922 2.075 2.048v1.468H0V3.738C0 2.61.934 1.69 2.075 1.69h.674zM4.28 0c.31 0 .544.256.544.538v1.28c0 .308-.26.538-.544.538-.286 0-.52-.256-.52-.538V.538C3.76.23 3.995 0 4.28 0zM9 0c.31 0 .545.256.545.538v1.28c0 .308-.26.538-.545.538-.285 0-.52-.256-.52-.538V.538C8.48.23 8.716 0 9 0zm4.695 0c.285 0 .518.256.518.538v1.28a.523.523 0 0 1-.518.538c-.286 0-.52-.256-.52-.538V.538c0-.308.234-.538.52-.538z" />
    </svg>
);

const IconEvent: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-event${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconEvent;
