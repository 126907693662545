import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M15.734 14.486l-3.924-3.93a6.549 6.549 0 00-.592-8.625A6.517 6.517 0 006.571 0a6.517 6.517 0 00-4.647 1.931 6.59 6.59 0 000 9.306A6.517 6.517 0 006.57 13.17a6.46 6.46 0 003.968-1.34l3.946 3.908a.877.877 0 00.635.263c.22 0 .46-.088.636-.263.329-.33.329-.9-.022-1.251zM6.593 11.39c-1.293 0-2.477-.505-3.398-1.405-1.863-1.865-1.863-4.916 0-6.804a4.771 4.771 0 013.398-1.404c1.293 0 2.477.505 3.398 1.404.92.9 1.403 2.107 1.403 3.402s-.505 2.48-1.403 3.402c-.9.922-2.127 1.405-3.398 1.405z" />
    </svg>
);

const IconSearch: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-search${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconSearch;
