import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'login_terms.title',
        defaultMessage: 'Nos conditions générales d’utilisation et notre politique de confidentialité',
        description: 'Login terms title',
    },
    refusedDescription: {
        id: 'login_terms.refused.description',
        defaultMessage: 'Vous avez refusé nos conditions d’utilisation et/ou notre politique de confidentialité.{br} Malheureusement, vous ne pouvez donc pas accéder à votre compte et utiliser nos services.',
        description: 'Login terms refused description',
    },
    changeChoiceButton: {
        id: 'login_terms.refused.button',
        defaultMessage: 'Modifier votre choix',
        description: 'Login terms refused button label to change choice',
    },
    description: {
        id: 'login_terms.description',
        defaultMessage: 'Veuillez lire attentivement les deux documents ci-dessous et faites un choix afin d’accéder à votre compte.',
        description: 'Login terms description',
    },
    termsLink: {
        id: 'login_terms.terms_link',
        defaultMessage: 'Lire les conditions générales d’utilisation',
        description: 'Login terms link',
    },
    privacyLink: {
        id: 'login_terms.privacy_link',
        defaultMessage: 'Lire notre politique de confidentialité',
        description: 'Login terms privacy link',
    },
    acceptButton: {
        id: 'login_terms.accept_button',
        defaultMessage: 'Vous avez lu et vous acceptez',
        description: 'Login terms accept button',
    },
    refuseButton: {
        id: 'login_terms.refuse_button',
        defaultMessage: 'Vous refusez',
        description: 'Login terms refuse button',
    },
});
