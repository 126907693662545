import { defineMessages } from 'react-intl';

export default defineMessages({
    loginTitle: {
        id: 'login.title',
        defaultMessage: 'Accédez à votre espace',
        description: 'Login title',
    },
    emailLabel: {
        id: 'login.email.label',
        defaultMessage: 'Votre e-mail',
        description: 'Login email field label',
    },
    emailPlaceholder: {
        id: 'login.email.placeholder',
        defaultMessage: 'Saisissez votre e-mail professionnel',
        description: 'Login email field placeholder',
    },
    invalidCredentials: {
        id: 'login.error.invalid_credentials',
        defaultMessage: 'Informations de connexion non valides',
        description: 'Login error message when credentials provided aren\'t valid',
    },
    passwordLabel: {
        id: 'login.password.label',
        defaultMessage: 'Mot de passe',
        description: 'Login password field label',
    },
    passwordPlaceholder: {
        id: 'login.password.placeholder',
        defaultMessage: 'Saisissez votre mot de passe',
        description: 'Login password field placeholder',
    },
    loginButton: {
        id: 'login.submit',
        defaultMessage: 'Se connecter',
        description: 'Login submit button text',
    },
    forgotPassword: {
        id: 'login.forgotten_password',
        defaultMessage: 'Mot de passe oublié ?',
        description: 'Login forgot password link',
    },
    emailNotFound: {
        id: 'forgotten_password.email_not_found',
        defaultMessage: 'Êtes-vous sûr d\'avoir saisi la bonne adresse e-mail ?',
        description: 'Forgotten password not found error',
    },
});
