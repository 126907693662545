import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M.498 0A.5.5 0 000 .498V6.7a.5.5 0 00.498.498h6.197a.5.5 0 00.502-.498V.5A.5.5 0 006.695 0H.498zm8.804 0a.5.5 0 00-.5.498V6.7a.5.5 0 00.5.498h6.196A.5.5 0 0016 6.7V.5A.5.5 0 0015.498 0H9.302zM.498 8.803A.5.5 0 000 9.3v6.197A.5.5 0 00.498 16h6.197a.5.5 0 00.502-.502V9.301a.5.5 0 00-.502-.498H.498zm8.804 0a.5.5 0 00-.5.498v6.197a.5.5 0 00.5.502h6.196a.5.5 0 00.502-.502V9.301a.5.5 0 00-.502-.498H9.302z" />
    </svg>
);

const IconOther: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-other${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconOther;
