import React, { FC, ReactNode } from 'react';
import { Menu, Modal, Dropdown } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { DropDownProps } from 'antd/lib/dropdown';

import { Appointment, AppointmentStatus, NetworkingActionType } from '../store/api/apiTypes';
import { update, del } from '../store/actions/appointments';

import genericMessages from '../locale/genericMessages';
import { getRoute, RoutePathName } from '../routes';
import NetworkingActionDetailsMessages from '../pages/networkingActionDetails/NetworkingActionDetailsMessages';
import { isAppointmentValidated, isAppointmentCancelled } from '../helpers/data';

interface NetworkingActionMenuProps extends Partial<DropDownProps> {
    appointment: Appointment;
    children?: ReactNode;
    deleteAppointment: typeof del.trigger;
    updateAppointment: typeof update.trigger;
}

const NetworkingActionMenu: FC<NetworkingActionMenuProps> = ({
    appointment, children, deleteAppointment, updateAppointment, ...props
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const onClickCreateInterview = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), {
            values: {
                firstAppointment: appointment?.firstAppointment?.id || appointment?.id,
                parent: appointment?.id,
                parentTitle: appointment?.title,
                actionType: NetworkingActionType.jobInterview,
            },
        });
    };
    const onClickCancel = () => {
        updateAppointment({
            id: appointment.id,
            appointment: {
                status: AppointmentStatus.cancelled,
            },
        });
    };
    const onClickDelete = () => {
        Modal.confirm({
            title: formatMessage(NetworkingActionDetailsMessages.deleteTitle),
            cancelText: formatMessage(genericMessages.cancel),
            okText: formatMessage(genericMessages.delete),
            onOk: () => {
                deleteAppointment({ id: appointment.id });
            },
            icon: null,
            cancelButtonProps: {
                ghost: true,
            },
            okButtonProps: {
                danger: true,
                ghost: true,
            },
        });
    };

    const menu = (
        <Menu>
            {appointment.actionType === NetworkingActionType.jobInterview &&
                isAppointmentValidated(appointment) &&
                !appointment.interviewStatus && (
                <Menu.Item onClick={onClickCreateInterview}>
                    <FormattedMessage {...NetworkingActionDetailsMessages.createInterview} />
                </Menu.Item>
            )}
            {!isAppointmentValidated(appointment) && !isAppointmentCancelled(appointment) && (
                <Menu.Item onClick={onClickCancel}>
                    <FormattedMessage {...genericMessages.cancel} />
                </Menu.Item>
            )}
            {!isAppointmentValidated(appointment) && !isAppointmentCancelled(appointment) && (
                <Menu.Item>
                    <Link to={getRoute(RoutePathName.networkingActionEdit, { networkingActionId: appointment.id })}>
                        <FormattedMessage {...genericMessages.edit} />
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item onClick={onClickDelete} danger>
                <FormattedMessage {...genericMessages.delete} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            trigger={['click']}
            {...props}
            overlay={menu}
        >
            {children}
        </Dropdown>
    );
};

export default connect(
    undefined,
    {
        updateAppointment: update.trigger,
        deleteAppointment: del.trigger,
    },
)(NetworkingActionMenu);
