import React, { FC, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, FormattedRelativeTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import { Dropdown, Menu, Badge, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { LoginOutlined, UserOutlined } from '@ant-design/icons';
import { ActionCreator } from 'redux';
import { usePageVisibility } from 'react-page-visibility';

import '../assets/styles/NotificationsMenu.less';

import { MainReducerState } from '../store/reducers';
import { NotificationStatus, Notification } from '../store/api/apiTypes';
import { getNotificationsListState, getNotificationUpdateState, list, update as updateNotificationAction, NotificationsState } from '../store/actions/notifications';
import { update as updateMeAction } from '../store/actions/auth';

import { IconBell, IconEvent, IconContacts } from './icons';
import IconBadge from './icons/IconBadge';
import { usePrevious } from '../hooks';
import { fetchNotificationInfo, UpdateNotificationPayload } from '../helpers/native';

const messages = defineMessages({
    markAllAsRead: {
        id: 'notifications.mark_all_as_read',
        defaultMessage: 'Tout marquer comme lu',
        description: 'Notification mark all as read button',
    },
    empty: {
        id: 'notifications.empty',
        defaultMessage: 'Vous n\'avez pas de notifications !',
        description: 'Notification empty state',
    },
});

const getNotificationIcon = (payloadContext?: {
    [key: string]: any;
}) => {
    switch (payloadContext?.type) {
        case 'badge':
            return (
                <IconBadge
                    rounded
                    badgeType={payloadContext.badgeType}
                    badgeLevel={payloadContext.badgeLevel}
                />
            );

        case 'appointment':
            return <Avatar size={48} icon={<IconEvent />} />;

        case 'contact':
            return <Avatar size={48} icon={<IconContacts />} />;

        case 'login':
            return <Avatar size={48} icon={<LoginOutlined />} />;

        case 'user':
            return <Avatar size={48} icon={<UserOutlined />} />;

        default:
            return <Avatar size={48} icon={<IconBell />} />;
    }
};

interface NotificationsMenuProps {
    notificationsListState: NotificationsState['list'];
    notificationUpdateState: NotificationsState['update'];
    listNotifications: typeof list.trigger;
    updateNotification: typeof updateNotificationAction.trigger;
    stopPolling: ActionCreator<{}>;
    updateMe: typeof updateMeAction.trigger;
}

const NotificationsMenu: FC<NotificationsMenuProps> = ({
    notificationsListState, listNotifications, updateNotification, notificationUpdateState, stopPolling,
    updateMe,
}) => {
    const isVisible = usePageVisibility();
    const unreadCount = notificationsListState.data?.items.reduce((acc, item) => acc += (item.status === NotificationStatus.received ? 1 : 0), 0);
    const previous = usePrevious({ notificationUpdateState });
    const onClickNotificationItem = (id: Notification['id']) => {
        updateNotification({
            id,
            notification: {
                status: NotificationStatus.opened,
            },
        });
    };
    const onNativeUpdateNotificationInfo = useCallback((data: CustomEventInit<UpdateNotificationPayload>) => {
        if (data.detail) {
            updateMe({
                device: data.detail,
            });
        }
    }, [updateMe]);

    const menu = (
        <Menu id="notifications-menu">
            {!notificationsListState.data?.items.length && (
                <Menu.Item disabled>
                    <FormattedMessage {...messages.empty} />
                </Menu.Item>
            )}
            {notificationsListState.data?.items.map((notification) => {
                const { value, unit } = selectUnit(Date.now() - (Date.now() - (new Date(notification.sendAt).getTime())));

                return (
                    <Menu.Item key={notification.id} onClick={onClickNotificationItem.bind(null, notification.id)}>
                        <Space size={12}>
                            <div className="notification-icon">
                                {getNotificationIcon(notification.payload.context)}
                            </div>
                            <div className="notification-content">
                                <span className="notification-content-text">
                                    {notification.payload.text}
                                </span>
                                <span className="notification-content-date">
                                    <FormattedRelativeTime
                                        value={value}
                                        unit={unit}
                                        numeric="auto"
                                        style="short" // eslint-disable-line react/style-prop-object
                                    />
                                </span>
                            </div>
                            <div className="notification-status">
                                {notification.status === NotificationStatus.received &&
                                    <Badge color={window.__MCM__theme?.primary || '#6B5AED'} dot />
                                }
                            </div>
                        </Space>
                    </Menu.Item>
                );
            })}
            {/* <Menu.Item key="markAllAsRead" className="mark-as-read">
                <FormattedMessage {...messages.markAllAsRead} />
            </Menu.Item> */}
        </Menu>
    );

    useEffect(() => {
        if (isVisible) {
            listNotifications({
                poll: true,
            });
        } else {
            stopPolling();
        }
    }, [listNotifications, isVisible, stopPolling]);

    useEffect(() => () => { stopPolling(); }, [stopPolling]);

    useEffect(() => {
        if (previous?.notificationUpdateState.loading && !notificationUpdateState.loading && !notificationUpdateState.error) {
            listNotifications({
                poll: false,
            });
        }
    }, [listNotifications, previous, notificationUpdateState]);

    // native
    useEffect(() => {
        fetchNotificationInfo();
    }, []);

    useEffect(() => {
        // dispatched from helpers/native.ts
        window.addEventListener('updateNotificationInfo', onNativeUpdateNotificationInfo);

        return () => window.removeEventListener('updateNotificationInfo', onNativeUpdateNotificationInfo);
    }, [onNativeUpdateNotificationInfo]);

    return (
        <span id="notifications-menu-wrapper">
            <Dropdown placement="topRight" trigger={['click']} overlay={menu}>
                <Link to="" className="user-card">
                    <Badge count={unreadCount} offset={[4, -2]}>
                        <IconBell />
                    </Badge>
                </Link>
            </Dropdown>
        </span>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    notificationsListState: getNotificationsListState(state),
    notificationUpdateState: getNotificationUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listNotifications: list.trigger,
        updateNotification: updateNotificationAction.trigger,
        stopPolling: () => ({ type: 'NOTIFICATIONS/STOP_POLLING' }),
        updateMe: updateMeAction.trigger,
    },
)(NotificationsMenu);
