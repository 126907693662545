import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M4.658 10.65a.78.78 0 0 1-.78-.778c0-.43.35-.778.78-.778h5.828a.78.78 0 0 1 0 1.555H4.658zm1.562-5.3a.78.78 0 0 1 0 1.555H4.658a.78.78 0 0 1-.78-.777c0-.43.35-.777.78-.777H6.22zm4.38-4.096A4.283 4.283 0 0 0 7.57 0H3.18A3.183 3.183 0 0 0 0 3.2v9.6C0 14.566 1.41 16 3.18 16h9.61c1.77 0 3.21-1.435 3.21-3.2V8.404a4.304 4.304 0 0 0-1.27-3.032L10.6 1.255z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconDocuments: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-documents${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconDocuments;
