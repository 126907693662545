import React, { FC, useEffect } from 'react';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, RouteComponentProps, useParams, Link } from 'react-router-dom';
import { Space, Tag, Typography, Divider } from 'antd';
import { DateTime } from 'luxon';

import { MainReducerState } from '../../store/reducers';
import { UserProgramTask, taskTypeMessages, UserProgramTaskStatus } from '../../store/api/apiTypes';
import { TaskDetailsState, details, getTaskStateById } from '../../store/actions/tasks';

import '../../assets/styles/ProgramTaskDetails.less';

import Seo from '../../components/Seo';
import { IconArrowLeft, taskTypeIcons, IconTrophy, IconEvent, IconCheckCircle, IconPlus } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import ProgramTaskDetailsMessages from './ProgramTaskDetailsMessages';
import { useIsMobile } from '../../hooks';
import SubTask from '../../components/SubTask';
import { classNames } from '../../helpers';

interface MatchParams {
    taskId: UserProgramTask['id'];
}

interface ProgramTaskDetailsProps extends RouteComponentProps<MatchParams> {
    taskDetailsState: TaskDetailsState;
    fetchTask: typeof details.trigger;
}

const ProgramTaskDetails: FC<ProgramTaskDetailsProps> = ({
    taskDetailsState, fetchTask,
}) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const { taskId } = useParams<MatchParams>();
    const taskFromDate = taskDetailsState.data?.period.fromDate ?
        DateTime.fromISO(taskDetailsState.data?.period.fromDate, { zone: 'UTC' }) :
        null;
    const isTaskInTheFuture = !!taskFromDate && taskFromDate > DateTime.utc();
    const isDateBefore = taskDetailsState.data?.period.validUntil ?
        DateTime.fromISO(taskDetailsState.data.period.validUntil, { zone: 'UTC' }) < DateTime.utc() :
        false;
    const onClickNetworkingActionCTA = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), {
            previous: getRoute(RoutePathName.programTask, { taskId: taskDetailsState.data?.id || '' }),
        });
    };
    const ActionTypeIcon = taskDetailsState.data?.task?.family ?
        taskTypeIcons.get(taskDetailsState.data?.task?.family) :
        undefined;

    useEffect(() => {
        fetchTask({ id: taskId });
    }, [fetchTask, taskId]);

    return (
        <section id="program-task-details">
            <Seo title={taskDetailsState.data?.task?.name} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <Space direction="vertical" size={24} style={{ width: '100%' }} className="block">
                <Link
                    to={getRoute(RoutePathName.home)}
                    className="back-link"
                    type="link"
                >
                    <IconArrowLeft />
                    <FormattedMessage {...ProgramTaskDetailsMessages.backToHome} />
                </Link>
                <div
                    className={classNames(
                        'program-task-details-card',
                        taskDetailsState.data?.status === UserProgramTaskStatus.validated && 'is-complete',
                    )}
                >
                    {taskDetailsState.data?.status === UserProgramTaskStatus.validated && (
                        <div className="program-task-details-card-top is-complete">
                            <div className="flex flex-align-center">
                                <IconCheckCircle /> <FormattedMessage {...ProgramTaskDetailsMessages.validated} />
                            </div>
                        </div>
                    )}
                    <header id="program-task-details-header">
                        <Space size={isMobile ? 8 : 12}>
                            <Tag icon={ActionTypeIcon && <ActionTypeIcon />} className="tag-primary">
                                {taskDetailsState.data?.task?.family && (
                                    <FormattedMessage
                                        {...taskTypeMessages.get(taskDetailsState.data?.task?.family)}
                                    />
                                )}
                            </Tag>
                            <Tag icon={<IconTrophy />} className="text-lowercase">
                                <FormattedMessage
                                    {...genericMessages.pointsShortPlural}
                                    values={{
                                        count: taskDetailsState.data?.task?.points,
                                        value: <FormattedNumber value={taskDetailsState.data?.task?.points || 0} />,
                                    }}
                                />
                            </Tag>
                            <Tag icon={<IconEvent />} color={isDateBefore ? 'error' : 'default'}>
                                <FormattedMessage
                                    {...genericMessages.dateInterval}
                                    values={{
                                        startDate: (
                                            <FormattedDate
                                                value={taskDetailsState.data?.period.fromDate}
                                                month={
                                                    taskDetailsState.data?.period.fromDate && taskDetailsState.data?.period.validUntil ? (
                                                        // tslint:disable-next-line: max-line-length
                                                        DateTime.fromISO(taskDetailsState.data?.period.fromDate, { zone: 'UTC' }).month !== DateTime.fromISO(taskDetailsState.data?.period.validUntil, { zone: 'UTC' }).month ?
                                                            'long' :
                                                            undefined
                                                    ) :
                                                        undefined
                                                }
                                                timeZone="UTC"
                                                day="numeric"
                                            />
                                        ),
                                        endDate: (
                                            <FormattedDate
                                                value={taskDetailsState.data?.period.validUntil}
                                                month="long"
                                                day="numeric"
                                                timeZone="UTC"
                                            />
                                        ),
                                    }}
                                />
                            </Tag>
                        </Space>
                        <Typography.Title level={1}>
                            {taskDetailsState.data?.task?.name}
                        </Typography.Title>
                        <Divider />
                    </header>
                    <div
                        id="program-task-details-description"
                        dangerouslySetInnerHTML={{ __html: taskDetailsState.data?.task?.description || '' }}
                    />
                    <Divider dashed className="sub-task-separator" />
                    {taskDetailsState.data?.task?.subTasks.map((subTask, index) => (
                        <SubTask
                            key={subTask.id}
                            data={subTask}
                            isTaskInTheFuture={isTaskInTheFuture}
                            onValidate={fetchTask.bind(null, { id: taskId })}
                            taskId={taskId}
                            taskFamily={taskDetailsState.data?.task?.family}
                        />
                    ))}
                </div>
                <Link
                    to={getRoute(RoutePathName.home)}
                    className="back-link"
                    type="link"
                >
                    <IconArrowLeft />
                    <FormattedMessage {...ProgramTaskDetailsMessages.backToHome} />
                </Link>
            </Space>
        </section>
    );
};

const mapStateToProps = (
    state: MainReducerState,
    { match: { params: { taskId } } }: ProgramTaskDetailsProps,
) => ({
    taskDetailsState: getTaskStateById(state, taskId),
});

export default connect(
    mapStateToProps,
    {
        fetchTask: details.trigger,
    },
)(ProgramTaskDetails);
