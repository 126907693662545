import React, { FC } from 'react';
import { connect } from 'react-redux';

import { getUser } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { User } from '../store/api/apiTypes';

import Img from './Img';

interface LogoProps {
    user?: User;
}

const Logo: FC<LogoProps> = ({ user }) => (
    <Img
        src={user?.organization?.theme?.logoUrl || `${process.env.PUBLIC_URL}/images/logo.svg`}
        className={user?.organization?.theme?.logoUrl ? 'white-label' : undefined}
        alt="logo"
    />
);

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Logo);
