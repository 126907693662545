import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/fonts.less';
import './assets/styles/App.less';
import './assets/styles/Typography.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import FullscreenLayout from './components/FullscreenLayout';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import SuccessModals from './components/SuccessModals';
import GetProgram from './components/GetProgram';
import Login from './pages/login';
import Home from './pages/home';
import Privacy from './pages/privacy';
import { getRawRoute, RoutePathName } from './routes';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import LoginTerms from './pages/loginTerms';
import Account from './pages/account';
import Program from './pages/program';
import NetworkingActions from './pages/networkingActions';
import Client from './pages/client/Client';
import NetworkingActionCreate from './pages/networkingActionCreate';
import Contacts from './pages/contacts';
import ContactCreate from './pages/contactCreate';
import ContactDetails from './pages/contactDetails';
import StaticPage from './pages/staticPage/StaticPage';
import Faq from './pages/staticPage/Faq';
import NetworkingActionDetails from './pages/networkingActionDetails';
import Library from './pages/library';
import LibraryDocument from './pages/library/LibraryDocument';
import JobOffers from './pages/jobOffers';
import JobOfferDetails from './pages/jobOfferDetails';
import JobOfferCreate from './pages/jobOfferCreate';
import ProgramTaskDetails from './pages/programTaskDetails';
import Dashboard from './pages/dashboard';
import Documents from './pages/documents';

const App: FC = () => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.loginTerms)} component={LoginTerms} />
        <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
        <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
        <Route exact path={getRawRoute(RoutePathName.privacyPolicy)} component={Privacy} />
        <Route exact path={getRawRoute(RoutePathName.terms)} component={StaticPage} />

        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <SuccessModals />
            <ErrorBoundary>
                <Switch>

                    <Route // client
                        path={getRawRoute(RoutePathName.client)}
                        component={Client}
                    />

                    <Route
                        path="*" // user
                    >
                        <GetProgram />
                        <Switch>
                            <Route
                                exact
                                path={getRawRoute(RoutePathName.networkingActionCreate)}
                            >
                                <FullscreenLayout>
                                    <NetworkingActionCreate />
                                </FullscreenLayout>
                            </Route>

                            <Route
                                exact
                                path={getRawRoute(RoutePathName.contactCreate)}
                            >
                                <FullscreenLayout>
                                    <ContactCreate />
                                </FullscreenLayout>
                            </Route>

                            <Route
                                exact
                                path={getRawRoute(RoutePathName.jobOfferCreate)}
                            >
                                <FullscreenLayout>
                                    <JobOfferCreate />
                                </FullscreenLayout>
                            </Route>

                            <Route path="*">
                                <MainLayout>
                                    <Switch>

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.home)}
                                        >
                                            <Home />
                                        </Route>

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.account)}
                                        >
                                            <Account />
                                        </Route>

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.program)}
                                        >
                                            <Program />
                                        </Route>

                                        <Route
                                            path={getRawRoute(RoutePathName.programTask)}
                                            component={ProgramTaskDetails}
                                        />

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.networkingActions)}
                                        >
                                            <NetworkingActions />
                                        </Route>

                                        <Route
                                            path={getRawRoute(RoutePathName.networkingAction)}
                                            component={NetworkingActionDetails}
                                        />

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.jobOffers)}
                                        >
                                            <JobOffers />
                                        </Route>

                                        <Route
                                            path={getRawRoute(RoutePathName.jobOffer)}
                                            component={JobOfferDetails}
                                        />

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.contacts)}
                                        >
                                            <Contacts />
                                        </Route>

                                        <Route
                                            path={getRawRoute(RoutePathName.contact)}
                                            component={ContactDetails}
                                        />

                                        {/* <Route
                                            exact
                                            path={getRawRoute(RoutePathName.calendar)}
                                        >
                                            <Calendar />
                                        </Route> */}

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.library)}
                                        >
                                            <Library />
                                        </Route>

                                        <Route
                                            exact
                                            path={getRawRoute(RoutePathName.documents)}
                                        >
                                            <Documents />
                                        </Route>

                                        <Route
                                            path={getRawRoute(RoutePathName.libraryDocument)}
                                            component={LibraryDocument}
                                        />

                                        <Route
                                            path={getRawRoute(RoutePathName.faq)}
                                            component={Faq}
                                        />

                                        <Route
                                            path={getRawRoute(RoutePathName.dashboard)}
                                            exact
                                        >
                                            <Dashboard />
                                        </Route>

                                        <Route path="*">
                                            <ErrorPage />
                                        </Route>
                                    </Switch>
                                </MainLayout>
                            </Route>
                        </Switch>
                    </Route>
                </Switch>
            </ErrorBoundary>
        </ProtectedRoute>
    </Switch>
);

export default App;
