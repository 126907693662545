import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M16 0a3.64 3.64 0 0 0-3.636 3.636A3.64 3.64 0 0 0 16 7.273a3.64 3.64 0 0 0 3.636-3.637A3.64 3.64 0 0 0 16 0Zm0 8c-2.008 0-3.813.816-4.974 1.5-.97.572-1.572 1.682-1.572 2.897v.467c0 .927.7 1.682 1.56 1.682h9.973c.86 0 1.559-.755 1.559-1.682v-.467c0-1.215-.602-2.325-1.572-2.897C19.814 8.816 18.008 8 16 8Zm0 8a.727.727 0 0 0-.727.727v4.014l-3.364 2.691a.728.728 0 0 0 .91 1.136L16 22.022l3.182 2.546a.73.73 0 0 0 1.023-.114.728.728 0 0 0-.114-1.022l-3.364-2.69v-4.015A.727.727 0 0 0 16 16Zm-9.454 1.454a3.64 3.64 0 0 0-3.637 3.637 3.64 3.64 0 0 0 3.637 3.636 3.64 3.64 0 0 0 3.636-3.636 3.64 3.64 0 0 0-3.636-3.637Zm18.908 0a3.64 3.64 0 0 0-3.636 3.637 3.64 3.64 0 0 0 3.636 3.636 3.64 3.64 0 0 0 3.637-3.636 3.64 3.64 0 0 0-3.637-3.637Zm-18.908 8c-2.008 0-3.814.816-4.975 1.5C.601 27.528 0 28.638 0 29.853v.466C0 31.246.699 32 1.558 32h9.975c.859 0 1.558-.754 1.558-1.682v-.466c0-1.215-.601-2.325-1.571-2.897-1.161-.685-2.966-1.5-4.974-1.5Zm18.908 0c-2.008 0-3.813.816-4.974 1.5-.97.573-1.57 1.683-1.57 2.898v.466c0 .928.698 1.682 1.558 1.682h9.974c.86 0 1.558-.754 1.558-1.682v-.466c0-1.215-.602-2.325-1.571-2.897-1.161-.685-2.967-1.5-4.975-1.5Z"/>
    </svg>
);

const IconInterpersonal: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-interpersonal${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconInterpersonal;
