import React, { FC, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { AuthState, checkLoginStatus as checkLoginStatusAction, getAuthState } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { RoleSlug, Organization } from '../store/api/apiTypes';

import { getRoute, RoutePathName } from '../routes';
import { usePrevious } from '../hooks';
import { hasRole } from '../helpers/security';

declare global {
    interface Window { __MCM__theme?: Organization['theme']; }
}

interface ProtectedRouteProps {
    auth: AuthState;
    checkLoginStatus: typeof checkLoginStatusAction.trigger;
    children: ReactNode;
    loginPath: string;
    logout: typeof checkLoginStatusAction.failure;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    auth, checkLoginStatus, children, loginPath, logout,
}) => {
    const location = useLocation();
    const previousLocation = usePrevious(location.pathname);
    const previousHasCheckedLoginStatus = usePrevious(auth.hasCheckedLoginStatus);
    const isInitialHomeRoute = (
        location.pathname === getRoute(RoutePathName.home) && (previousLocation === undefined || !previousHasCheckedLoginStatus)
    );
    const canSeeClientSection = hasRole(auth.user, [
        RoleSlug.superAdmin, RoleSlug.admin, RoleSlug.owner, RoleSlug.headHR, RoleSlug.manager,
    ]);

    useEffect(() => {
        // dispatched from axios interceptor in api/_client.ts
        window.addEventListener('unauthorized.error', logout);

        if (!auth.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', logout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (auth.hasCheckedLoginStatus && !auth.isConnected) {
        return <Redirect to={loginPath} />;
    } else if (auth.hasCheckedLoginStatus && auth.isConnected) {
        window.__MCM__theme = auth.user?.organization?.theme;
        if (auth.user?.acceptedTerms) {
            // redirect to "client" section on first page load of homepage if user has permissions
            if (isInitialHomeRoute && canSeeClientSection) {
                return <Redirect to={getRoute(RoutePathName.clientDashboard)} />;
            } else {
                return <>{children}</>;
            }
        } else {
            return <Redirect to={getRoute(RoutePathName.loginTerms)} />;
        }
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    }
};

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        checkLoginStatus: checkLoginStatusAction.trigger,
        logout: checkLoginStatusAction.failure,
    },
)(ProtectedRoute);
