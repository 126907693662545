// import { Civility, User } from './apiTypes';
import api from './_client';
import { SearchPaginationQuery } from '.';
import { Contact } from './apiTypes';

export type ContactListPayload = SearchPaginationQuery & {
    referrer?: boolean;
    referred?: boolean;
};
export type ContactCreatePayload = Partial<Contact>;
export type ContactDeletePayload = Pick<Contact, 'id'>;
export type ContactDetailsPayload = Pick<Contact, 'id'>;
export interface ContactUpdatePayload {
    id: Contact['id'];
    contact: Contact;
}

export const list = (payload: ContactListPayload) => api.get('/me/contacts', { params: payload });

export const exportAll = () => api.get('/me/contacts', { headers: { Accept: 'text/vcard' } });

export const create = (payload: ContactCreatePayload) => api.post('/me/contacts', payload);

export const del = (payload: ContactDeletePayload) => api.delete(`/me/contacts/${payload.id}`);

export const details = (payload: ContactDetailsPayload) => api.get(`/me/contacts/${payload.id}`);

export const update = (payload: ContactUpdatePayload) => api.put(`/me/contacts/${payload.id}`, payload.contact);
