import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="nonzero" d="M7.095.025v8.898l7.71 3.278A7.984 7.984 0 018 16l-.25-.004C3.448 15.864 0 12.33 0 7.987A8.009 8.009 0 017.095.025zM8.908 0A8.01 8.01 0 0116 7.962c0 .907-.15 1.78-.428 2.592l-.051-.022h-.002l-.006-.003-6.605-2.808z" />
    </svg>
);

const IconReporting: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-reporting${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconReporting;
