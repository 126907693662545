import React, { createContext, useState, FC } from 'react';

interface LayoutContextTypes {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextTypes>({
    isMenuOpen: false,
    setIsMenuOpen: () => undefined,
});

export const LayoutContextProvider: FC = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const layoutContext = {
        isMenuOpen,
        setIsMenuOpen,
    };

    return (
        <LayoutContext.Provider value={layoutContext}>
            {children}
        </LayoutContext.Provider>
    );
};
