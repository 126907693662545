// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/eina04-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/eina04-regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/eina04-semibold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/eina04-semibold.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/eina04-semibolditalic-subset.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/eina04-semibolditalic-subset.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/eina04-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/eina04-bold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/eina04-regularitalic.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/eina04-regularitalic.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/eina04-bolditalic.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../fonts/eina04-bolditalic.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "body {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n  font-weight: normal;\n  font-style: normal;\n  font-display: fallback;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-SemiBold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n  font-weight: 600;\n  font-style: normal;\n  font-display: fallback;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-SemiBoldItalic'), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n  font-weight: 600;\n  font-style: italic;\n  font-display: fallback;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n  font-weight: bold;\n  font-style: normal;\n  font-display: fallback;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-RegularItalic'), url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff');\n  font-weight: normal;\n  font-style: italic;\n  font-display: fallback;\n}\n@font-face {\n  font-family: 'Eina04';\n  src: local('Eina04-BoldItalic'), url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff');\n  font-weight: bold;\n  font-style: italic;\n  font-display: fallback;\n}\n", ""]);
// Exports
module.exports = exports;
