import { Switch } from 'antd';
import React, { FC, useState } from 'react';
import { SwitchProps } from 'antd/lib/switch';

interface CustomSwitchProps {
    checked?: string;
    onChange?: (checked: string) => void;
    disabled?: boolean;
}

const CustomSwitch: FC<CustomSwitchProps> = ({ checked, disabled, onChange, ...props }) => {
    const [localValue, setLocalValue] = useState(`${checked}`);
    const onChangeSwitch: SwitchProps['onChange'] = (value) => {
        if (typeof onChange === 'function') {
            setLocalValue(`${value}`);
            onChange(`${value}`);
        }
    };

    return (
        <Switch
            {...props}
            checked={localValue === 'true'}
            onChange={onChangeSwitch}
            disabled={disabled}
        />
    );
};

export default CustomSwitch;
