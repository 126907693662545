import React, { FC, useRef, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationProps } from 'antd/lib/pagination';
import { message, Empty, Button } from 'antd';

import { MainReducerState } from '../../store/reducers';
import {
    getContactsListState, list as listContactsAction, ContactsState, getContactsDeleteState,
    getContactsExportState, exportAll, listCount, getContactsListCountState,
} from '../../store/actions/contacts';

import '../../assets/styles/Contacts.less';

import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import Pagination from '../../components/Pagination';
import ContactListCard from '../../components/ContactListCard';
import ListTitle from '../../components/ListTitle';
import Filters from '../../components/Filters';
import { useQueryParams, usePrevious } from '../../hooks';
import { scrollToElement, downloadFile } from '../../helpers';
import constants from '../../config/constants';
import ContactsMessages from './ContactsMessages';
import ListHeader from '../../components/ListHeader';
import genericMessages from '../../locale/genericMessages';
import { IconPlus } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import CardSkeleton from '../../components/CardSkeleton';
import { DateTime } from 'luxon';

interface ContactsProps {
    contactsState: ContactsState['list'];
    contactsCountState: ContactsState['listCount'];
    contactDelState: ContactsState['del'];
    listContacts: typeof listContactsAction.trigger;
    listContactsCount: typeof listCount.trigger;
    contactsExportState: ContactsState['exportAll'];
    exportContacts: typeof exportAll.trigger;
}

const Contacts: FC<ContactsProps> = ({
    contactsState, contactDelState, contactsExportState, exportContacts, listContacts,
    listContactsCount, contactsCountState,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { formatMessage, formatNumber } = useIntl();
    const previous = usePrevious({ contactDelState, contactsExportState });
    const listRef = useRef(null);
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const filterParam = urlParams.get('filter');
    const searchParam = urlParams.get('search');
    const sortParam = urlParams.get('sort');
    const currentPage = pageParam !== null ?
        (parseInt(pageParam, 10) || 1) :
        1;
    const currentFilter = filterParam || 'all';
    const currentSort = sortParam || 'createdAt';
    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('page', `${page || 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeFilter = (filter: string) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('filter', `${filter}`);
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSearch = (search?: string) => {
        scrollToElement(listRef.current, 100);
        if (search) {
            urlParams.set('search', search);
        } else {
            urlParams.delete('search');
        }
        urlParams.delete('page');
        urlParams.delete('filter');
        urlParams.delete('sort');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSort = (sort?: string) => {
        scrollToElement(listRef.current, 100);
        if (sort) {
            urlParams.set('sort', sort);
        } else {
            urlParams.delete('sort');
        }
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onClickAddContactCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.contactCreate),
            state: {
                previous: getRoute(RoutePathName.contacts),
            },
        });
    };
    const onClickExport = () => {
        exportContacts();
    };
    const filterOptions = [
        {
            value: 'all',
            message: ContactsMessages.filtersAll,
            messageValues: { count: formatNumber(contactsCountState.data?.all || 0) },
        },
        {
            value: 'referrer',
            message: ContactsMessages.filtersReferrer,
            messageValues: { count: formatNumber(contactsCountState.data?.referrer || 0) },
        },
        {
            value: 'referred',
            message: ContactsMessages.filtersReferred,
            messageValues: { count: formatNumber(contactsCountState.data?.referred || 0) },
        },
    ];
    const sortOptions = [
        {
            value: 'createdAt',
            message: genericMessages.sortByDate,
        },
        {
            value: 'fullName',
            message: genericMessages.sortAlphabetically,
        },
    ];
    const fetchContacts = useCallback(() => {
        listContacts({
            page: currentPage - 1,
            pageSize: constants.PAGE_SIZE,
            ...(currentFilter === 'referrer' ? { referrer: true } : {}),
            ...(currentFilter === 'referred' ? { referred: true } : {}),
            ...(currentSort !== undefined ? { sort: currentSort } : {}),
            ...(searchParam !== undefined ? { search: searchParam } : {}),
        });
    }, [listContacts, currentPage, currentFilter, currentSort, searchParam]);

    useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);

    useEffect(() => {
        if (previous?.contactDelState.loading && !contactDelState.loading) {
            if (contactDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ContactsMessages.deleteSuccess));
                fetchContacts();
                listContactsCount();
            }
        }
    }, [
        previous, contactDelState.loading, contactDelState.error, fetchContacts, formatMessage,
        listContactsCount,
    ]);

    useEffect(() => {
        if (previous?.contactsExportState.loading && !contactsExportState.loading) {
            if (contactsExportState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else if (contactsExportState.data) {
                downloadFile(contactsExportState.data, `${DateTime.local().toFormat('yyyy-dd-MM')}-contacts.vcard`);
            }
        }
    }, [previous, contactsExportState.loading, contactsExportState.error, formatMessage, contactsExportState.data ]);

    useEffect(() => {
        listContactsCount();
    }, [listContactsCount]);

    return (
        <section id="contacts">
            <Seo title={formatMessage(ContactsMessages.title)} />
            <HeaderCTA
                onClick={onClickAddContactCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...ContactsMessages.addContactCTA} />
            </HeaderCTA>
            <ListHeader>
                <div className="flex flex-align-center">
                    <ListTitle count={contactsState.data?.totalCount || 0}>
                        <FormattedMessage {...ContactsMessages.title} />
                    </ListTitle>
                    {!!contactsState.data?.totalCount && (
                        <Button
                            onClick={onClickExport}
                            loading={contactsExportState.loading}
                        >
                            <FormattedMessage {...genericMessages.export} />
                        </Button>
                    )}
                </div>
                <Filters
                    filterOptions={filterOptions}
                    sortOptions={sortOptions}
                    onChangeFilter={onChangeFilter}
                    onChangeSearch={onChangeSearch}
                    onChangeSort={onChangeSort}
                    filterValue={currentFilter}
                    searchValue={searchParam || undefined}
                    sortValue={currentSort}
                />
            </ListHeader>
            {!contactsState.loading && !!contactsState.data?.items.length && (
                <>
                    <BasicList ref={listRef}>
                        {contactsState.data?.items.map((contact) => (
                            <li key={contact.id}>
                                <ContactListCard contact={contact} />
                            </li>
                        ))}
                    </BasicList>
                    {contactsState.data?.pageCount > 1 && (
                        <Pagination
                            current={currentPage}
                            onChange={onPageSelect}
                            pageSize={constants.PAGE_SIZE}
                            showSizeChanger={false}
                            total={contactsState.data?.totalCount}
                        />
                    )}
                </>
            )}
            {!contactsState.loading && !contactsState.data?.items.length && (
                <Empty />
            )}
            {contactsState.loading && (
                <BasicList ref={listRef}>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                    <li><CardSkeleton rows={1} /></li>
                </BasicList>
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    contactsState: getContactsListState(state),
    contactsCountState: getContactsListCountState(state),
    contactDelState: getContactsDeleteState(state),
    contactsExportState: getContactsExportState(state),
});

export default connect(
    mapStateToProps,
    {
        listContacts: listContactsAction.trigger,
        listContactsCount: listCount.trigger,
        exportContacts: exportAll.trigger,
    },
)(Contacts);
