import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Spin } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import {
    UserGroupsState,
    search as searchUserGroups,
} from '../store/actions/userGroups';
import { SelectProps } from 'antd/lib/select';
import { UserGroup } from '../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { UserGroupListPayload } from '../store/api/userGroups';
import Select from './Select';

interface UserGroupFilterSelectProps {
    value ?: UserGroup[];
    initialValue ?: UserGroup[];
    multi?: boolean;
    userGroups: UserGroupsState;
    size?: SizeType;
    filters ?: UserGroupListPayload;
    placeholder: string;
    onChange?: (value: any) => void;
    search: typeof searchUserGroups.trigger;
}

const UserGroupFilterSelect: FC<UserGroupFilterSelectProps> = forwardRef((props, ref: any) => {

    const {onChange, userGroups, search, multi, size, filters, placeholder} = props;
    const [ value, setValue ] = useState<Array<UserGroup['id']>>();
    const [ initialValue, setInitialValue ] = useState<UserGroup[]>([]);

    useEffect(() => {
        setValue([]);
        setInitialValue([]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.value && props.value.length > 0 && typeof props.value[0] !== 'string') {
            setValue(props.value.map((a) => a.id));
            setInitialValue(props.value);
            if (onChange) { onChange(props.value.map((a) => a.id || a)); }
        }
    }, [props.value, props.initialValue, onChange]);

    const onFocus: SelectProps<UserGroup['id']>['onFocus'] = () => {
        search({
            search: '',
            pageSize: 15,
            ...filters,
        });
    };

    const onSearch: SelectProps<UserGroup['id']>['onSearch'] = (newValue) => {
        search({
            search: newValue,
            pageSize: 15,
            ...filters,
        });
    };

    const onChangeSelect = (newValue: any) => {
        setValue(newValue);
        if (onChange) {onChange(newValue); }
    };

    let selectOptionsUserGroups: UserGroup[] = [...initialValue];
    if (userGroups.search?.data?.items) {
        selectOptionsUserGroups = [
            ...selectOptionsUserGroups,
            ...userGroups.search.data.items.filter((a) => !initialValue.map((b) => b.id).includes(a.id)),
        ];
    }

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value}
            placeholder={placeholder || 'Recherchez un groupe'}
            notFoundContent={userGroups.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={onChangeSelect}
            onFocus={onFocus}
            allowClear
            style={{ width: '100%' }}
            showArrow
        >
            {
                selectOptionsUserGroups.map((userGroup) => (
                    <Select.Option value={userGroup.id} key={userGroup.id}>{userGroup.name}</Select.Option>
                ))
            }
        </Select>
    );

});

UserGroupFilterSelect.defaultProps = {
    multi: false,
    size: 'large',
};

const mapStateToProps = (state: MainReducerState) => ({
    userGroups: state.userGroups,
});

export default connect(
    mapStateToProps,
    {
        search: searchUserGroups.trigger,
    },
    null,
    { forwardRef: true },
)(UserGroupFilterSelect);
