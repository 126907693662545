import React, { FC, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, RouteComponentProps, useParams, Link, Route } from 'react-router-dom';
import { Button, message, Modal, Space, Typography, Empty, Form } from 'antd';
import { FormProps } from 'antd/lib/form';

import { MainReducerState } from '../../store/reducers';
import {
    getContactStateById, details, ContactDetailsState, ContactsState, del, getContactsDeleteState, update, getContactsUpdateState,
} from '../../store/actions/contacts';
import { Contact } from '../../store/api/apiTypes';
import { getAppointmentsListState, list, AppointmentsState, getAppointmentsUpdateState } from '../../store/actions/appointments';

import '../../assets/styles/Contacts.less';

import Seo from '../../components/Seo';
import genericMessages from '../../locale/genericMessages';
import { IconArrowLeft, IconTrash, IconAddContact } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious } from '../../hooks';
import ContactsMessages from '../contacts/ContactsMessages';
import BasicList from '../../components/BasicList';
import CardSkeleton from '../../components/CardSkeleton';
import NetworkingActionCard from '../../components/NetworkingActionCard';
import ContactDescriptions from '../../components/ContactDescriptions';
import ContactForm from '../../components/ContactForm';
import ContactDetailsMessages from './ContactDetailsMessages';

interface MatchParams {
    contactId: Contact['id'];
}

interface ContactDetailsProps extends RouteComponentProps<MatchParams> {
    appointmentsListState: AppointmentsState['list'];
    appointmentsUpdateState: AppointmentsState['update'];
    listAppointments: typeof list.trigger;
    contactDetailsState: ContactDetailsState;
    contactDelState: ContactsState['del'];
    contactUpdateState: ContactsState['update'];
    fetchContact: typeof details.trigger;
    deleteContact: typeof del.trigger;
    updateContact: typeof update.trigger;
}

const ContactDetails: FC<ContactDetailsProps> = ({
    appointmentsListState, appointmentsUpdateState, contactDetailsState, contactDelState,
    deleteContact, fetchContact, listAppointments, contactUpdateState, updateContact,
}) => {
    const history = useHistory();
    const { contactId } = useParams<MatchParams>();
    const [form] = Form.useForm();
    const previous = usePrevious({ appointmentsUpdateState, contactDelState, contactUpdateState });
    const { formatMessage } = useIntl();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const onClickAddContactCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.contactCreate),
            state: {
                previous: getRoute(RoutePathName.contact, { contactId }),
            },
        });
    };
    const onClickDelete = () => {
        setIsDeleteModalVisible(true);
        Modal.confirm({
            title: formatMessage(ContactsMessages.deleteTitle),
            cancelText: formatMessage(genericMessages.cancel),
            okText: formatMessage(genericMessages.delete),
            onOk: () => {
                deleteContact({ id: contactId });
                setIsDeleteModalVisible(false);
            },
            icon: null,
            cancelButtonProps: {
                ghost: true,
            },
            okButtonProps: {
                danger: true,
                ghost: true,
            },
            visible: isDeleteModalVisible,
        });
    };
    const onFormValidSubmit: FormProps<Partial<Contact>>['onFinish'] = (val) => {
        updateContact({id: contactId, contact: val});
    };
    const onClickCancelEdit = () => {
        history.push(getRoute(RoutePathName.contact, { contactId }));
    };

    useEffect(() => {
        fetchContact({ id: contactId });
    }, [fetchContact, contactId]);

    useEffect(() => {
        listAppointments({ contact: contactId });
    }, [listAppointments, contactId]);

    useEffect(() => {
        if (previous?.contactDelState.loading && !contactDelState.loading) {
            if (contactDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ContactsMessages.deleteSuccess));
                history.push(getRoute(RoutePathName.contacts));
            }
        }
    }, [
        previous, contactDelState.loading, contactDelState.error, formatMessage, history,
    ]);

    useEffect(() => {
        if (previous?.contactUpdateState.loading && !contactUpdateState.loading) {
            if (contactUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ContactDetailsMessages.updateSuccess));
                history.push(getRoute(RoutePathName.contact, { contactId }));
                fetchContact({ id: contactId });
            }
        }
    }, [
        previous, contactUpdateState.loading, contactUpdateState.error, formatMessage, history,
        contactId, fetchContact,
    ]);

    useEffect(() => {
        if (previous?.appointmentsUpdateState.loading && !appointmentsUpdateState.loading) {
            if (appointmentsUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                listAppointments({ contact: contactId });
            }
        }
    }, [
        previous, appointmentsUpdateState.loading, appointmentsUpdateState.error, formatMessage,
        listAppointments, contactId,
    ]);

    return (
        <section id="contact-details">
            <Seo title={formatMessage(ContactDetailsMessages.title)} />
            <HeaderCTA
                onClick={onClickAddContactCTA}
                icon={<IconAddContact />}
            >
                <FormattedMessage {...ContactsMessages.addContactCTA} />
            </HeaderCTA>
            <Space direction="vertical" size={24} style={{ width: '100%' }} className="block">
                <div className="flex flex-between">
                    <Link
                        to={getRoute(RoutePathName.contacts)}
                        className="back-link"
                        type="link"
                    >
                        <IconArrowLeft />
                        <FormattedMessage {...ContactDetailsMessages.backToList} />
                    </Link>
                    <Button
                        onClick={onClickDelete}
                        loading={contactDelState.loading}
                        icon={<IconTrash />}
                        type="primary"
                        shape="circle"
                        size="small"
                        danger
                    />
                </div>
                <Route path={getRoute(RoutePathName.contact, { contactId })} exact>
                    <ContactDescriptions contact={contactDetailsState.data} />
                </Route>
                <Route path={getRoute(RoutePathName.contactEdit, { contactId })} exact>
                    <ContactForm
                        form={form}
                        onFinish={onFormValidSubmit}
                        contact={contactDetailsState.data}
                        submit={(
                            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                                {() => (
                                    <div className="flex flex-between">
                                        <Button
                                            type="ghost"
                                            onClick={onClickCancelEdit}
                                        >
                                            <FormattedMessage {...genericMessages.cancel} />
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={contactUpdateState.loading}
                                            disabled={
                                                form &&
                                                (
                                                    !form.isFieldsTouched() ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                )
                                            }
                                        >
                                            <FormattedMessage {...ContactDetailsMessages.updateSubmit} />
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        )}
                        isEditing
                    />
                </Route>
            </Space>
            <section className="block">
                <Typography.Title level={2}>
                    <FormattedMessage {...ContactDetailsMessages.contactActions} />
                </Typography.Title>
                {!appointmentsListState.loading && !!appointmentsListState.data?.items.length && (
                    <BasicList grid>
                        {appointmentsListState.data?.items.map((appointment) => (
                            <li key={appointment.id}>
                                <NetworkingActionCard appointment={appointment} />
                            </li>
                        ))}
                    </BasicList>
                )}
                {!appointmentsListState.loading && !appointmentsListState.data?.items.length && (
                    <Empty />
                )}
                {appointmentsListState.loading && (
                    <BasicList grid>
                        <li><CardSkeleton /></li>
                        <li><CardSkeleton /></li>
                    </BasicList>
                )}
            </section>
        </section>
    );
};

const mapStateToProps = (
    state: MainReducerState,
    { match: { params: { contactId } } }: ContactDetailsProps,
) => ({
    appointmentsListState: getAppointmentsListState(state),
    appointmentsUpdateState: getAppointmentsUpdateState(state),
    contactDetailsState: getContactStateById(state, contactId),
    contactDelState: getContactsDeleteState(state),
    contactUpdateState: getContactsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        fetchContact: details.trigger,
        deleteContact: del.trigger,
        listAppointments: list.trigger,
        updateContact: update.trigger,
    },
)(ContactDetails);
