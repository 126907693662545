import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M6.63959391,2.25 C7.11167513,2.25 7.49238579,2.64893617 7.49238579,3.14361702 L7.49238579,8.87234043 C7.49238579,9.35106383 7.11167513,9.75 6.63959391,9.75 L0.852791878,9.75 C0.38071066,9.75 0,9.35106383 0,8.85638298 L0,3.14361702 C0,2.64893617 0.38071066,2.25 0.852791878,2.25 L6.63959391,2.25 Z M12,3.20744681 L12,8.79255319 C12,8.90425532 11.893401,8.96808511 11.8020305,8.93617021 L8.31472081,7.56382979 C8.26903553,7.53191489 8.23857868,7.48404255 8.23857868,7.43617021 L8.23857868,4.56382979 C8.23857868,4.51595745 8.26903553,4.46808511 8.31472081,4.43617021 L11.8020305,3.06382979 C11.893401,3.03191489 12,3.09574468 12,3.20744681 Z" />
    </svg>
);

const IconVideo: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-video${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconVideo;
