import React, { FC } from 'react';
import { Tag, Typography, Space } from 'antd';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import '../assets/styles/ProgramTaskCard.less';

import { taskTypeMessages, UserProgramTask, UserProgramTaskStatus, SubTaskType, ProgramValidationType, SubTaskSubType, DocumentSubTaskType } from '../store/api/apiTypes';
import { validate } from '../store/actions/tasks';

import ProgramTaskDetailsMessages from '../pages/programTaskDetails/ProgramTaskDetailsMessages';
import genericMessages from '../locale/genericMessages';
import { getRoute, RoutePathName } from '../routes';
import { IconArrowRight, IconCheckCircle, IconEvent, IconTrophy, taskTypeIcons } from './icons';
import { useIsMobile } from '../hooks';
import { classNames } from '../helpers';
import ButtonLink from './ButtonLink';

interface ProgramTaskCardProps {
    data: UserProgramTask;
    validateSubTask: typeof validate.trigger;
}

const ProgramTaskCard: FC<ProgramTaskCardProps> = ({ data, validateSubTask }) => {
    const location = useLocation();
    const currentLocation = `${location.pathname}${location.search}`;
    const isMobile = useIsMobile();
    const firstSubTask = data.task?.subTasks[0];
    const taskFromDate = data?.period.fromDate ? DateTime.fromISO(data?.period.fromDate, { zone: 'UTC' }) : null;
    const isDateBefore = data?.period.validUntil ?
        DateTime.fromISO(data.period.validUntil, { zone: 'UTC' }) < DateTime.utc() :
        false;
    const onValidate = () => {
        const isValidationAutomatic = !!firstSubTask?.validation && firstSubTask?.validation?.type === ProgramValidationType.automatic;

        if (isValidationAutomatic) {
            validateSubTask({
                token: firstSubTask?.validationToken,
            });
        }
    };
    const needsDetailsPage = (data.task?.subTasks.length || 0) > 1 || (
        firstSubTask &&
        (
            data?.status === UserProgramTaskStatus.validated ||
            [SubTaskType.survey, SubTaskType.text, SubTaskType.upload].includes(firstSubTask.type) ||
            (firstSubTask.validation && firstSubTask.validation?.type === ProgramValidationType.manual) ||
            !firstSubTask.validation
        )
    );
    const ActionTypeIcon = data?.task?.family ? taskTypeIcons.get(data?.task?.family) : undefined;

    let unvalidatedContent = null;

    if (!needsDetailsPage && data.task?.subTasks.length === 1 && firstSubTask) {
        const isValidationAutomatic = !!firstSubTask.validation && firstSubTask.validation?.type === ProgramValidationType.automatic;
        const isTaskInTheFuture = !!taskFromDate && taskFromDate > DateTime.utc();

        switch (firstSubTask.type) {
            case SubTaskType.action:
                const queryParams = isValidationAutomatic ?
                    `validationToken=${firstSubTask.validationToken || ''}` :
                    '';
                let pathname;
                let message;

                switch (firstSubTask.subType) {
                    case SubTaskSubType.contact:
                        pathname = getRoute(RoutePathName.contactCreate);
                        message = ProgramTaskDetailsMessages.createContact;
                        break;

                    case SubTaskSubType.hiring:
                        pathname = getRoute(RoutePathName.jobOfferCreate);
                        message = ProgramTaskDetailsMessages.createJobOffer;
                        break;

                    case SubTaskSubType.networking:
                        pathname = getRoute(RoutePathName.networkingActionCreate);
                        message = ProgramTaskDetailsMessages.createNetworkingAction;
                        break;
                }

                if (isTaskInTheFuture) {
                    message = ProgramTaskDetailsMessages.inTheFuture;
                }

                unvalidatedContent = (
                    <ButtonLink
                        type="primary"
                        size={isMobile ? 'small' : 'large'}
                        to={{
                            pathname,
                            search: queryParams || '',
                            state: {
                                previous: currentLocation,
                                validationFields: firstSubTask.validation?.fields,
                            },
                        }}
                        disabled={isTaskInTheFuture}
                    >
                        <FormattedMessage {...message} />
                    </ButtonLink>
                );
                break;

            case SubTaskType.document:
                unvalidatedContent = (
                    <ButtonLink
                        type="primary"
                        size={isMobile ? 'small' : 'large'}
                        onClick={onValidate}
                        to={{
                            pathname: getRoute(RoutePathName.libraryDocument, { documentId: firstSubTask.libraryDocument?.id || '' }),
                            state: {
                                previous: currentLocation,
                                download: firstSubTask.documentSubType === DocumentSubTaskType.toDo,
                            },
                        }}
                        disabled={isTaskInTheFuture}
                    >
                        <FormattedMessage
                            {...(isTaskInTheFuture ?
                                ProgramTaskDetailsMessages.inTheFuture :
                                ProgramTaskDetailsMessages.linkToDocumentFull
                            )}
                        />
                    </ButtonLink>
                );
                break;

            case SubTaskType.link:
                unvalidatedContent = (
                    <ButtonLink
                        type="primary"
                        size={isMobile ? 'small' : 'large'}
                        onClick={onValidate}
                        href={firstSubTask.link}
                        target="_blank"
                        to=""
                        disabled={isTaskInTheFuture}
                    >
                        <FormattedMessage
                            {...(isTaskInTheFuture ?
                                ProgramTaskDetailsMessages.inTheFuture :
                                ProgramTaskDetailsMessages.linkSubmit
                            )}
                        />
                    </ButtonLink>
                );
                break;
        }
    }

    return (
        <div
            className={classNames(
                'program-task-card',
                data?.status === UserProgramTaskStatus.validated && 'is-complete',
            )}
        >
            <div className="program-task-card-content-wrapper">
                <header className="flex-between">
                    <Space size={isMobile ? 8 : 12}>
                        <Tag icon={ActionTypeIcon && <ActionTypeIcon />} className="tag-primary">
                            {data?.task?.family && (
                                <FormattedMessage
                                    {...taskTypeMessages.get(data?.task?.family)}
                                />
                            )}
                        </Tag>
                        <Tag icon={<IconTrophy />} className="text-lowercase">
                            <FormattedMessage
                                {...genericMessages.pointsShortPlural}
                                values={{
                                    count: data?.task?.points,
                                    value: <FormattedNumber value={data?.task?.points || 0} />,
                                }}
                            />
                        </Tag>
                        <Tag icon={<IconEvent />} color={isDateBefore ? 'error' : 'default'}>
                            <FormattedMessage
                                {...genericMessages.dateInterval}
                                values={{
                                    startDate: (
                                        <FormattedDate
                                            value={data?.period.fromDate}
                                            timeZone="UTC"
                                            month={
                                                data?.period.fromDate && data?.period.validUntil ? (
                                                    // tslint:disable-next-line: max-line-length
                                                    DateTime.fromISO(data?.period.fromDate, { zone: 'UTC' }).month !== DateTime.fromISO(data?.period.validUntil, { zone: 'UTC' }).month ?
                                                        'long' :
                                                        undefined
                                                ) :
                                                undefined
                                            }
                                            day="numeric"
                                        />
                                    ),
                                    endDate: (
                                        <FormattedDate
                                            value={data?.period.validUntil}
                                            timeZone="UTC"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        </Tag>
                    </Space>
                </header>
                <div className="program-task-card-body">
                    <Typography.Paragraph ellipsis={{ rows: 2 }}>
                        <p className="program-task-card-title">
                            {needsDetailsPage ? (
                                <Link
                                    to={getRoute(RoutePathName.programTask, { taskId: data?.id || '' })}
                                >
                                    {data?.task?.name}
                                </Link>
                            ) : data?.task?.name}
                        </p>
                    </Typography.Paragraph>
                    <Typography.Paragraph ellipsis={{ rows: 3 }}>
                        <p className="program-task-card-description">
                            {data?.task?.description}
                        </p>
                    </Typography.Paragraph>
                </div>
                <footer className="flex-between">
                    {needsDetailsPage ? (
                        <Link
                            className="text-primary"
                            to={getRoute(RoutePathName.programTask, { taskId: data?.id || '' })}
                        >
                            <FormattedMessage {...genericMessages.detailsLink} /> <IconArrowRight />
                        </Link>
                    ) : <span />}
                    <Breakpoint sm down>
                        {
                            data?.status === UserProgramTaskStatus.validated ?
                                (
                                    <IconCheckCircle />
                                ) :
                                unvalidatedContent
                        }
                    </Breakpoint>
                </footer>
            </div>
            <Breakpoint md up className="program-task-card-right-side">
                {
                    data?.status === UserProgramTaskStatus.validated ?
                        (
                            <IconCheckCircle />
                        ) :
                        unvalidatedContent
                }
            </Breakpoint>
        </div>
    );
};

export default connect(
    undefined,
    {
        validateSubTask: validate.trigger,
    },
)(ProgramTaskCard);
