import React, { FC, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { UserGroup, User } from '../../../store/api/apiTypes';
import * as userGroupsActions from '../../../store/actions/userGroups';
import { MainReducerState } from '../../../store/reducers';
import FullscreenLayout from '../../../components/FullscreenLayout';
import Seo from '../../../components/Seo';
import IconGroups from '../../../components/icons/IconGroups';
import { usePrevious } from '../../../hooks';
import genericMessages from '../../../locale/genericMessages';
import CreateGroupMessages from './CreateGroupMessages';
import CreateGroupForm from './CreateGroupForm';
import { getUser } from '../../../store/actions/auth';
import { getRoute, RoutePathName } from '../../../routes';

interface CreateActionPageProps {
    user?: User;
    createActionState: userGroupsActions.UserGroupsState['create'];
    updateActionState: userGroupsActions.UserGroupsState['update'];
    detailsActionState: userGroupsActions.UserGroupsState['details'];
    createAction: typeof userGroupsActions.create.trigger;
    updateAction: typeof userGroupsActions.update.trigger;
    detailsAction: typeof userGroupsActions.details.trigger;
    resetDetailsAction: typeof userGroupsActions.details.reset;
}

const CreateActionPage: FC<CreateActionPageProps> = ({
    user,
    createActionState,
    updateActionState,
    detailsActionState,
    createAction,
    updateAction,
    detailsAction,
    resetDetailsAction,
}) => {
    const { formatMessage } = useIntl();
    const { groupId } = useParams<{ groupId: UserGroup['id'] }>();
    const previous = usePrevious({ createActionState, updateActionState });
    const history = useHistory();

    // ---------------------------------------
    // On init, get details or reset state

    useEffect(() => {
        if (groupId) {
            detailsAction({
                id: groupId,
            });
        } else {
            resetDetailsAction();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On exit, back to groups list

    const onQuit = useCallback(() => {
        history.replace(getRoute(RoutePathName.clientGroups));
        resetDetailsAction();
    }, [history, resetDetailsAction]);

    const onPrevious = () => {
        onQuit();
    };

    // ---------------------------------------
    // On submit, create or update

    const onChangeValues = (val: UserGroup) => {
        if (groupId) {
            updateAction({
                ...val,
                id: groupId,
            });
        } else {
            createAction({
                organization: user?.organization?.id,
                ...val,
            });
        }
    };

    useEffect(() => {
        if (
            (previous?.createActionState.loading && !createActionState.loading)
            || (previous?.updateActionState.loading && !updateActionState.loading)
        ) {
            if (createActionState.error || updateActionState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                onQuit();
            }
        }
    }, [
        previous, formatMessage, onQuit,
        createActionState.loading, createActionState.error, createActionState.data,
        updateActionState.loading, updateActionState.error, updateActionState.data,
    ]);

    // ---------------------------------------
    // Render

    return (
        <section id="create-action">
            <Seo title={formatMessage(!groupId ? CreateGroupMessages.createTitle : CreateGroupMessages.editTitle)} />
            <FullscreenLayout.Header
                onQuit={onQuit}
                onPrevious={onPrevious}
                title={<FormattedMessage {...(!groupId ? CreateGroupMessages.createTitle : CreateGroupMessages.editTitle)} />}
                icon={<IconGroups />}
                isFirstStep={true}
            />
            <FullscreenLayout.Content>
                <Spin spinning={createActionState.loading}>
                    <CreateGroupForm
                        onSubmit={onChangeValues}
                        organizationId={user?.organization?.id}
                        initialValues={detailsActionState.data}
                    />
                </Spin>
            </FullscreenLayout.Content>
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    createActionState: state.userGroups.create,
    updateActionState: state.userGroups.update,
    detailsActionState: state.userGroups.details,
});

export default connect(
    mapStateToProps,
    {
        createAction: userGroupsActions.create.trigger,
        updateAction: userGroupsActions.update.trigger,
        detailsAction: userGroupsActions.details.trigger,
        resetDetailsAction: userGroupsActions.details.reset,
    },
)(CreateActionPage);
