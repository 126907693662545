import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Button } from 'antd';

import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';
import { ui, getUiState } from '../../store/actions/ui';
import { MainReducerState } from '../../store/reducers';

import { getRoute, RoutePathName } from '../../routes';
import Modal from '../../components/Modal';
import HomeMessages from './HomeMessages';
import { usePrevious } from '../../hooks';

interface EditAccountModalProps {
    hasShownProfileModal: boolean;
    setUi: typeof ui.actions.set;
    user?: User;
}

const EditAccountModal: FC<EditAccountModalProps> = ({ hasShownProfileModal, setUi, user }) => {
    const history = useHistory();
    const previousUser = usePrevious(user || {} as any as User);
    const [isEditAccountModalVisible, setIsAccountModalVisible] = useState(
        !user?.hasSetupAccount && !hasShownProfileModal && !user?.shouldShowTutorial,
    );
    const onClickEditAccount = () => {
        setIsAccountModalVisible(false);
        history.push(getRoute(RoutePathName.account));
    };

    useEffect(() => {
        if (isEditAccountModalVisible) {
            setUi({ hasShownProfileModal: true });
        }
    }, [isEditAccountModalVisible, setUi]);

    // display account modal when closing tutorial modal if applicable
    useEffect(() => {
        if (previousUser?.shouldShowTutorial && !user?.shouldShowTutorial && !user?.hasSetupAccount && !hasShownProfileModal) {
            setIsAccountModalVisible(true);
        }
    }, [previousUser, user, hasShownProfileModal]);

    return (
        <Modal
            footer={[(
                <Button
                    key="submit"
                    onClick={onClickEditAccount}
                    size="small"
                    autoFocus
                    ghost
                >
                    <FormattedMessage {...HomeMessages.editAccountModalSubmit} />
                </Button>
            )]}
            onCancel={setIsAccountModalVisible.bind(null, false)}
            title={<FormattedMessage {...HomeMessages.editAccountModalTitle} />}
            visible={isEditAccountModalVisible}
            zIndex={900}
        >
            <Typography.Paragraph>
                <FormattedMessage {...HomeMessages.editAccountModalContent} tagName="p" />
            </Typography.Paragraph>
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    hasShownProfileModal: getUiState(state).hasShownProfileModal,
});

export default connect(
    mapStateToProps,
    {
        setUi: ui.actions.set,
    },
)(EditAccountModal);
