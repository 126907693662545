import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Bar, BarDatum, BarTooltipDatum } from '@nivo/bar';
import { useTheme } from '@nivo/core';

import { badgeLevelMessages } from '../../store/api/apiTypes';

import { Panel } from '../../types';
import genericMessages from '../../locale/genericMessages';
import { nivoTheme } from '../../helpers/styles';
import EnumMessage from '../EnumMessage';

const Tooltip: FC<BarTooltipDatum> = (props) => (
    <>
        <EnumMessage map={badgeLevelMessages} value={`${props.id}`} /> {props.value === 1 ? null : `: ${props.value}`}
    </>
);

const Tick = ({ value, x, y, opacity, rotate, format, lineX, lineY, onClick, textX, textY, textBaseline, textAnchor }: any) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const gStyle: any = { opacity };

    if (format !== undefined) {
        value = format(value);
    }

    if (onClick) {
        gStyle.cursor = 'pointer';
    }

    return (
        <g
            transform={`translate(${x},${y})`}
            {...(onClick ? { onClick: (e) => onClick(e, value) } : {})}
            style={gStyle}
        >
            <line x1={0} x2={lineX} y1={0} y2={lineY} style={theme.axis.ticks.line} />
            {value === 'cv' && (
                <g transform={`translate(-63,-28)`} width="53" height="56"><path fill="#2E384A" fillRule="evenodd" d="M36.407 41.068c0 .5-.41.906-.916.906H17.167a.912.912 0 01-.916-.906c0-.5.41-.906.916-.906h18.324c.505 0 .916.406.916.906zm0-4.53c0 .5-.41.906-.916.906H17.167a.912.912 0 01-.916-.906c0-.5.41-.907.916-.907h18.324c.505 0 .916.406.916.907zm0 9.061c0 .5-.41.906-.916.906H17.167a.912.912 0 01-.916-.906c0-.5.41-.906.916-.906h18.324c.505 0 .916.406.916.906zM23.122 22.492c0-1.74 1.447-3.171 3.207-3.171 1.76 0 3.207 1.43 3.207 3.171 0 1.74-1.447 3.172-3.207 3.172-1.76 0-3.207-1.431-3.207-3.172zm-3.664 9.515c0-1.931 1.165-3.387 2.548-4.234s2.995-1.203 4.323-1.203c1.328 0 2.94.356 4.323 1.203 1.383.847 2.548 2.303 2.548 4.234 0 .474-.436.906-.916.906h-11.91c-.48 0-.916-.432-.916-.906zm30.91 6.173a5.694 5.694 0 01-2.932 4.963l-5.532 3.084V14.79c0-1.49-1.242-2.719-2.749-2.719H13.503c-1.507 0-2.749 1.228-2.749 2.719v31.692l-5.99-3.339a5.694 5.694 0 01-2.932-4.963V17.82a5.693 5.693 0 012.932-4.963L23.272 2.54a5.81 5.81 0 012.828-.729c.978 0 1.956.243 2.827.73l18.509 10.316a5.694 5.694 0 012.932 4.963v20.36zm-21.44 15.28a5.85 5.85 0 01-5.656 0l-10.685-5.956V14.79a.89.89 0 01.916-.906h25.652a.89.89 0 01.916.906v32.458L28.928 53.46zm19.408-42.182L29.826.961a7.706 7.706 0 00-7.453 0L3.863 11.278A7.506 7.506 0 000 17.82v20.36a7.505 7.505 0 003.864 6.542l18.509 10.317c1.15.64 2.438.961 3.727.961 1.289 0 2.578-.32 3.727-.961l18.509-10.317A7.505 7.505 0 0052.2 38.18V17.82a7.506 7.506 0 00-3.864-6.542z" /></g>
            )}
            {value === 'professionalProject' && (
                <g transform={`translate(-62,-28)`} width="52" height="56"><path fill="#2E384A" fillRule="evenodd" d="M29.713.961L48.15 11.278A7.51 7.51 0 0152 17.82v20.36a7.51 7.51 0 01-3.85 6.542L29.713 55.039A7.61 7.61 0 0126 56a7.608 7.608 0 01-3.713-.961L3.849 44.722A7.51 7.51 0 010 38.18V17.82a7.51 7.51 0 013.85-6.542L22.286.961a7.65 7.65 0 017.426 0zM26 1.811a5.77 5.77 0 00-2.817.73L4.745 12.856a5.697 5.697 0 00-2.92 4.963v20.36a5.698 5.698 0 002.92 4.963L23.183 53.46a5.805 5.805 0 005.634 0l18.437-10.317a5.697 5.697 0 002.92-4.963V17.82a5.696 5.696 0 00-2.92-4.963L28.816 2.54A5.769 5.769 0 0026 1.811zm2.007 34.726v4.913c0 .564-.437 1.03-.997 1.089l-.121.006h-1.886a1.112 1.112 0 01-1.112-.976l-.006-.119v-4.913h4.122zm6.471-8.45c.576 0 1.051.429 1.112.977l.007.119v5.32c0 .564-.437 1.03-.997 1.089l-.122.006H16.657c-.305 0-.595-.122-.804-.333l-.085-.096-2.083-2.66a1.08 1.08 0 01-.076-1.222l.076-.11 2.083-2.66c.185-.236.46-.388.759-.422l.13-.007h17.821zm-6.47-3.188v2.25h-4.123v-2.25h4.122zm6.972-8.45c.305 0 .595.123.804.333l.084.096 2.084 2.66c.279.359.304.841.076 1.222l-.076.111-2.084 2.66c-.184.236-.46.387-.759.422l-.13.007H17.16a1.112 1.112 0 01-1.113-.976l-.006-.12v-5.32c0-.563.437-1.029.997-1.088l.121-.007H34.98zm-8.091-2.994c.575 0 1.05.428 1.112.976l.006.119v.96h-4.122v-.96c0-.564.437-1.03.997-1.089l.121-.006h1.886z" /></g>
            )}
            {value === 'linkedin' && (
                <g transform={`translate(-62,-28)`} width="52" height="56"><path fill="#2E384A" fillRule="evenodd" d="M20.705 18.446c0 1.7-1.398 3.087-3.11 3.087a3.067 3.067 0 01-3.065-3.087c0-1.7 1.352-3.132 3.065-3.132 1.712 0 3.11 1.432 3.11 3.132zm16.765 11.68v10.56h-4.958v-10.56c0-.493-.45-1.298-.856-1.433-1.983-1.118-4.056.895-4.056.895v11.098h-5.048V23.368H27.6v.805c1.938-.984 4.597-.805 6.49.18 2.028 1.119 3.38 3.535 3.38 5.772zm-22.4 10.56h5.049V23.368H15.07v17.318zm35.105-2.506a5.698 5.698 0 01-2.921 4.963L28.817 53.46a5.805 5.805 0 01-5.634 0L4.746 43.143a5.698 5.698 0 01-2.92-4.963V17.82a5.698 5.698 0 012.92-4.963L23.183 2.54A5.77 5.77 0 0126 1.81c.974 0 1.948.244 2.817.73l18.437 10.317a5.698 5.698 0 012.92 4.962V38.18zM48.15 11.278L29.713.961a7.65 7.65 0 00-7.426 0L3.85 11.278A7.51 7.51 0 000 17.82V38.18a7.51 7.51 0 003.85 6.542l18.437 10.317A7.607 7.607 0 0026 56a7.61 7.61 0 003.713-.961L48.15 44.722A7.51 7.51 0 0052 38.18V17.82a7.51 7.51 0 00-3.85-6.542z" /></g>
            )}
            <text
                dominantBaseline={textBaseline}
                textAnchor={textAnchor}
                transform={`translate(${textX - 66},${textY}) rotate(${rotate})`}
            >
                {// @ts-ignore
                    genericMessages[value] ? // @ts-ignore
                    formatMessage(genericMessages[value]) :
                    null
                }
            </text>
        </g>
    );
};

const colors: {[key: string]: string} = {
    lvl1: '#CDD7FF',
    lvl2: '#BDBCBC',
    lvl3: '#C6B030',
};

interface BarChartProps {
    data?: object[];
    panel: Panel;
    width: number;
    height: number;
}

const BarChart: FC<BarChartProps> = ({ panel, data, width, height }) =>
    data && width && height ? (
        <Bar
            margin={{ top: 20, right: 20, left: 210 }}
            width={width}
            height={height}
            indexBy="badge"
            keys={['lvl1', 'lvl2', 'lvl3']}
            data={data}
            theme={nivoTheme}
            axisLeft={{
                legendOffset: 12,
                tickSize: 0,
                tickPadding: 12,
                renderTick: Tick,
            }}
            axisBottom={null}
            // tslint:disable-next-line: jsx-no-lambda
            colors={(d: BarDatum) => colors[d.id] || '#6B5AED'}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.7]] }}
            // tslint:disable-next-line: jsx-no-lambda
            label={(d) => d.value === 1 ? '' : `${d.value}`}
            layout="horizontal"
            groupMode="stacked"
            enableGridY={false}
            tooltip={Tooltip}
        />
    ) : null;

export default BarChart;
