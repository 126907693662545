import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';
import { User, Gender, genderMessages } from '../../../store/api/apiTypes';
import formMessages from '../../../locale/formMessages';
import Select from '../../../components/Select';
import CreateUserMessages from './CreateUserMessages';
import genericMessages from '../../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { CreateUserStep } from './CreateUser';
import EnumMessage from '../../../components/EnumMessage';
import PhoneInput from '../../../components/PhoneInput';
import AccountMessages from '../../account/AccountMessages';

interface CreateUserIdentityFormProps {
    initialValues?: User;
    onSubmit: (values: User) => void;
}

const CreateUserIdentityForm: FC<CreateUserIdentityFormProps> = ({ initialValues, onSubmit }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const history = useHistory();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps<User>['onFinish'] = (values) => {
        onSubmit(values);
        history.push(getRoute(RoutePathName.clientUserCreate, { step: CreateUserStep.job }));
    };
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <Form<User>
            layout="vertical"
            form={form}
            onFinish={onFormValidSubmit}
            initialValues={initialValues}
            scrollToFirstError
        >
            <Card className="form-card">
                <Form.Item
                    label={formatMessage(CreateUserMessages.lastNameLabel)}
                    name="lastName"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(CreateUserMessages.lastNamePlaceholder)}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateUserMessages.firstNameLabel)}
                    name="firstName"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(CreateUserMessages.firstNamePlaceholder)}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(AccountMessages.phoneLabel)}
                    name="phone"
                >
                    <PhoneInput
                        placeholder={formatMessage(AccountMessages.phonePlaceholder)}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateUserMessages.genderLabel)}
                    name="gender"
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage(CreateUserMessages.genderPlaceholder)}
                        showArrow
                    >
                        {Object.values(Gender).map((type) => (
                            <Select.Option value={type} key={type}>
                                <EnumMessage map={genderMessages} value={type} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateUserMessages.emailLabel)}
                    name="email"
                    rules={[
                        requiredRule,
                        {type: 'email', message: formatMessage(formMessages.invalidEmail)},
                    ]}
                >
                    <Input
                        type="email"
                        placeholder={formatMessage(CreateUserMessages.emailPlaceholder)}
                    />
                </Form.Item>
            </Card>
            <Form.Item className="mobile-fixed-submit">
                <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    <FormattedMessage {...genericMessages.continue} />
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateUserIdentityForm;
