import React, { FC, useState, useLayoutEffect, useRef, ReactChild } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import useResizeAware from 'react-resize-aware';

import '../assets/styles/TruncatedText.less';

import { classNames } from '../helpers';
import { useIsMounted } from '../hooks';

const messages = defineMessages({
    defaultExpandLabel: {
        id: 'truncated_text.default_expand_label',
        defaultMessage: 'Lire la suite',
        description: 'Truncated text > default label for expanding the text',
    },
    defaultRetractLabel: {
        id: 'truncated_text.default_retract_label',
        defaultMessage: 'Voir moins',
        description: 'Truncated text > default label for retracting the text',
    },
});

interface TruncatedTextProps {
    className?: string;
    expandText?: string | ReactChild;
    expandedText?: string | ReactChild;
    maxHeight: number;
    withFade?: boolean;
}

const TruncatedText: FC<TruncatedTextProps> = ({
    className, expandText, expandedText, children, maxHeight, withFade,
}) => {
    const isMounted = useIsMounted();
    const [resizeListener, sizes] = useResizeAware();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const onToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useLayoutEffect(() => {
        if (wrapperRef.current) {
            setShowExpandButton(wrapperRef.current.scrollHeight > maxHeight);

            // firefox :D
            window.setTimeout(() => {
                if (wrapperRef.current && isMounted) {
                    setShowExpandButton(wrapperRef.current.scrollHeight > maxHeight);
                }
            }, 500);
        }
    }, [maxHeight, sizes.height, isMounted]);

    return (
        <div
            className={classNames(
                'truncated-text',
                isExpanded && 'is-expanded',
                className,
            )}
        >
            <div
                className="truncated-text-inner"
                ref={wrapperRef}
                style={wrapperRef.current ? { maxHeight: isExpanded ? wrapperRef.current.scrollHeight : maxHeight } : undefined}
            >
                {resizeListener}
                {children}
            </div>
            {showExpandButton && (
                <>
                    {withFade && <div className="truncated-text-fade" />}
                    <Button type="link" onClick={onToggle}>
                        {isExpanded && (
                            <>{expandedText || <FormattedMessage {...messages.defaultRetractLabel} />}</>
                        )}
                        {!isExpanded && (
                            <>{expandText || <FormattedMessage {...messages.defaultExpandLabel} />}</>
                        )}
                    </Button>
                </>
            )}
        </div>
    );
};

export default TruncatedText;
