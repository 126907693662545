import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M13.311 1.399c.022-.458.022-.732.022-.732 0-.367-.3-.667-.666-.667H3.333c-.366 0-.666.3-.666.667 0 0 0 .274.022.732A3.333 3.333 0 003.334 8c.214 0 .423-.025.626-.063.69 1.665 1.75 3.033 3.373 3.33V14c0 .367-.3.667-.666.667h-2c-.367 0-.667.3-.667.666 0 .367.3.667.667.667h6.666c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666h-2A.669.669 0 018.667 14v-2.733c1.622-.297 2.682-1.665 3.373-3.33.203.038.412.063.627.063a3.333 3.333 0 00.644-6.601zM3.333 6.667c-1.102 0-2-.898-2-2 0-.913.619-1.676 1.456-1.916.11 1.116.322 2.534.723 3.898-.06.005-.117.018-.179.018zm9.334 0c-.062 0-.119-.013-.179-.018.401-1.364.613-2.782.723-3.898a1.998 1.998 0 011.456 1.916c0 1.102-.898 2-2 2z" />
    </svg>
);

const IconTrophy: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-trophy${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconTrophy;
