import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'client.dashboard.title',
        defaultMessage: 'Tableau de bord',
        description: 'Client dashboard page title',
    },
    mainTitle: {
        id: 'client.dashboard.main_title',
        defaultMessage: 'Bonjour {name}',
        description: 'Client dashboard title',
    },
    summary: {
        id: 'client.dashboard.summary',
        defaultMessage: 'Vous avez {packs} pour un total de {sieges}.',
        description: 'Client dashboard summary',
    },
    summaryRH: {
        id: 'client.dashboard.summary_rh',
        defaultMessage: 'Il y a actuellement {users} dans {groups} utilisant la plateforme . Vous avez {sieges}.',
        description: 'Client dashboard summary',
    },
    summaryManager: {
        id: 'client.dashboard.summary_manager',
        defaultMessage: 'Vous avez {users} dans vos {groups}.',
        description: 'Client dashboard summary',
    },
});
