import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'client.program.closure.title',
        defaultMessage: 'Clôturer l’utilisateur',
        description: 'Program closure title',
    },
    subtitle: {
        id: 'client.program.closure.subtitle',
        defaultMessage: 'Saisissez les informations de fin de programme',
        description: 'Program closure subtitle',
    },
    typeLabel: {
        id: 'client.program.closure.typeLabel',
        defaultMessage: 'Type de sortie de programme',
        description: 'Type label',
    },
    typePlaceholder: {
        id: 'client.program.closure.typePlaceholder',
        defaultMessage: 'Sélectionner un type',
        description: 'Type placeholder',
    },
    datePlaceholder: {
        id: 'client.program.closure.datePlaceholder',
        defaultMessage: 'Saisir une date',
        description: 'Date placeholder',
    },
    entryOnDutyDateLabel: {
        id: 'client.program.closure.entryOnDutyDateLabel',
        defaultMessage: 'Date de prise de poste',
        description: 'entryOnDutyDate label',
    },
    serviceLabel: {
        id: 'client.program.closure.serviceLabel',
        defaultMessage: 'Service',
        description: 'service label',
    },
    servicePlaceholder: {
        id: 'client.program.closure.servicePlaceholder',
        defaultMessage: 'Saisir un nom de service',
        description: 'Service placeholder',
    },
    jobLabel: {
        id: 'client.program.closure.jobLabel',
        defaultMessage: 'Poste',
        description: 'Job label',
    },
    jobPlaceholder: {
        id: 'client.program.closure.jobPlaceholder',
        defaultMessage: 'Saisir un poste',
        description: 'Job placeholder',
    },
    sectorLabel: {
        id: 'client.program.closure.sectorLabel',
        defaultMessage: 'Secteur d\'activité',
        description: 'Sector label',
    },
    sectorPlaceholder: {
        id: 'client.program.closure.sectorPlaceholder',
        defaultMessage: 'Saisir un secteur d\'activité',
        description: 'Sector placeholder',
    },
    partnerLabel: {
        id: 'client.program.closure.partnerLabel',
        defaultMessage: 'Entreprise partenaire ?',
        description: 'partner label',
    },
    noteLabel: {
        id: 'client.program.closure.noteLabel',
        defaultMessage: 'Notes',
        description: 'Note label',
    },
    notePlaceholder: {
        id: 'client.program.closure.notePlaceholder',
        defaultMessage: 'Vous pouvez saisir des notes complémentaires',
        description: 'Note placeholder',
    },
    companyLabel: {
        id: 'client.program.closure.companyLabel',
        defaultMessage: 'Entreprise',
        description: 'Company label',
    },
    companyPlaceholder: {
        id: 'client.program.closure.companyPlaceholder',
        defaultMessage: 'Saisir l\'entreprise',
        description: 'Company placeholder',
    },
    leavingDateLabel: {
        id: 'client.program.closure.leavingDateLabel',
        defaultMessage: 'Date de fin du poste actuel',
        description: 'Leaving date label',
    },
    trainingFromDateLabel: {
        id: 'client.program.closure.trainingFromDateLabel',
        defaultMessage: 'Date de début de formation',
        description: 'Training from date label',
    },
    trainingToDateLabel: {
        id: 'client.program.closure.trainingToDateLabel',
        defaultMessage: 'Date de fin de formation',
        description: 'Training to date label',
    },
    retireDateLabel: {
        id: 'client.program.closure.retireDateLabel',
        defaultMessage: 'Date de départ à la retraire',
        description: 'Retire date label',
    },
    save: {
        id: 'client.program.closure.save',
        defaultMessage: 'Enregistrer les informations',
        description: 'Form submit',
    },
});
