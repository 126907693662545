import React, { FC, useState, useEffect, memo } from 'react';
import { useIntl, FormattedMessage, FormattedList } from 'react-intl';
import { Typography } from 'antd';

import {
    SurveyResponses, SurveyAnswerTricam, surveyAnswerTricamMessages, SurveyAnswerTricamLetters,
    SupportedLanguage, TricamSurveyMessage, surveyAnswerTricamLetterToType, ProgramSubTask,
} from '../../store/api/apiTypes';

import SurveyMessages from './SurveyMessages';

const letterValues = Object.values(SurveyAnswerTricamLetters);
const scoreInitialState = letterValues.reduce((result, letter) => ({ ...result, [letter]: 0 }), {});

const getMessages = async (score: Scores, language?: SupportedLanguage) => {
    if (!language) {
        return undefined;
    }

    try {
        const data: TricamSurveyMessage = await import(`../../assets/data/tricam/${language}/${score[0].letter}.json`);
        return data;
    } catch (error) {
        console.log('[TricamSurveyResults.getMessages]', error);
        return undefined;
    }
};

type Scores = Array<{
    letter: SurveyAnswerTricamLetters;
    score: number;
}>;

const getScore = (responses?: SurveyResponses<SurveyAnswerTricamLetters>) => {
    if (!responses) {
        return undefined;
    }

    const scoreObject = Object.keys(responses).reduce<Record<SurveyAnswerTricamLetters, number>>((result, questionId) => {
        const questionValues = responses[questionId][0]?.values?.filter((v) => letterValues.includes(v));
        questionValues?.forEach((letter) => {
            result[letter] = result[letter] ?
                result[letter] + 1 :
                1;
        });

        return result;
    }, scoreInitialState as unknown as Record<SurveyAnswerTricamLetters, number>);

    const scoreArray = (Object.keys(scoreObject) as SurveyAnswerTricamLetters[]).reduce((result, letter) =>
        [...result, { letter, score: scoreObject[letter] }],
    [] as unknown as Array<{ letter: SurveyAnswerTricamLetters; score: number }>);
    const sortedScoreArray = scoreArray.sort((a, b) => b.score - a.score);

    return sortedScoreArray;
};

interface TricamSurveyResultsProps {
    responses?: SurveyResponses<SurveyAnswerTricamLetters>;
    language?: SupportedLanguage;
    partialResults?: ProgramSubTask['tricamWithPartialResults'];
}

const TricamSurveyResults: FC<TricamSurveyResultsProps> = memo(({ language, responses, partialResults }) => {
    const [score, setScore] = useState<Scores>();
    const [messages, setMessages] = useState<TricamSurveyMessage>();
    const { formatMessage } = useIntl();

    useEffect(() => {
        setScore(getScore(responses));
    }, [responses]);

    useEffect(() => {
        if (score) {
            (async () => {
                const msgs = await getMessages(score, language);
                setMessages(msgs);
            })();
        } else {
            setMessages(undefined);
        }
    }, [score, language]);

    return (
        <div id="tricam-results">
            <Typography.Paragraph strong>{formatMessage(SurveyMessages.tricamResultsTitle)}</Typography.Paragraph>
            <div id="tricam-scores" className="mb-32">
                {(Object.keys(SurveyAnswerTricam) as Array<keyof typeof SurveyAnswerTricam>).map((key) => (
                    <div key={key} className="score">
                        <div className="score-title">
                            <FormattedMessage {...surveyAnswerTricamMessages.get(SurveyAnswerTricam[key])} />
                        </div>
                        <div className="score-value">
                            {score?.find((scoreItem) =>
                                surveyAnswerTricamLetterToType.get(scoreItem.letter) === SurveyAnswerTricam[key],
                            )?.score ?? 0}
                        </div>
                    </div>
                ))}
            </div>
            <p id="tricam-profile">
                {score && (
                    <FormattedMessage
                        {...SurveyMessages.tricamProfile}
                        values={{ results: (
                            <FormattedList
                                type="conjunction"
                                value={score.slice(0, partialResults ? 2 : 3).map(({ letter }) => (
                                    <FormattedMessage
                                        key={letter}
                                        {...surveyAnswerTricamMessages.get(surveyAnswerTricamLetterToType.get(letter)!)}
                                    />
                                ))}
                            />
                        ) }}
                    />
                )}
            </p>
            {!!messages?.description && (
                <p className="mb-32" dangerouslySetInnerHTML={{__html: messages?.description}} />
            )}
            {!!messages?.skills && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage {...SurveyMessages.tricamResultsSkills} />
                    </Typography.Paragraph>
                    <p className="backdrop mb-32" dangerouslySetInnerHTML={{ __html: messages?.skills }} />
                </>
            )}
            {!!messages?.communication && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage {...SurveyMessages.tricamResultsCommunication} />
                    </Typography.Paragraph>
                    <p className="backdrop mb-32" dangerouslySetInnerHTML={{ __html: messages?.communication }} />
                </>
            )}
            {!!messages?.strongPoints?.length && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage {...SurveyMessages.tricamResultsStrongPoints} />
                    </Typography.Paragraph>
                    <ul className="mb-32">
                        {messages?.strongPoints.map((message, i) => <li key={i}>{message}</li>)}
                    </ul>
                </>
            )}
            {!!messages?.weakPoints && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage {...SurveyMessages.tricamResultsWeakPoints} />
                    </Typography.Paragraph>
                    <div className="backdrop mb-32" dangerouslySetInnerHTML={{ __html: messages?.weakPoints }} />
                </>
            )}
            {!!messages?.environments && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage {...SurveyMessages.tricamResultsEnvironments} />
                    </Typography.Paragraph>
                    <p className="backdrop mb-32" dangerouslySetInnerHTML={{ __html: messages?.environments }} />
                </>
            )}
            {!!score && !!messages?.combinations?.[score[1].letter] && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage
                            {...SurveyMessages.tricamResultsCombinations}
                            values={{
                                combination: (
                                    <FormattedList
                                        type="conjunction"
                                        value={score.slice(0, 2).map(({ letter }) => (
                                            <FormattedMessage
                                                key={letter}
                                                {...surveyAnswerTricamMessages.get(surveyAnswerTricamLetterToType.get(letter)!)}
                                            />
                                        ))}
                                    />
                                ) }}
                        />
                    </Typography.Paragraph>
                    <p
                        className={`backdrop${!partialResults ? ' mb-32' : ' mb-0'}`}
                        dangerouslySetInnerHTML={{ __html: messages?.combinations[score[1].letter] }}
                    />
                </>
            )}
            {!!score && !partialResults && !!messages?.jobs?.[`${score[0].letter}${score[1].letter}${score[2].letter}`] && (
                <>
                    <Typography.Paragraph className="text-bold">
                        <FormattedMessage
                            {...SurveyMessages.tricamResultsJobs}
                            values={{
                                combination: (
                                    <FormattedList
                                        type="conjunction"
                                        value={score.slice(0, 3).map(({ letter }) => (
                                            <FormattedMessage
                                                key={letter}
                                                {...surveyAnswerTricamMessages.get(surveyAnswerTricamLetterToType.get(letter)!)}
                                            />
                                        ))}
                                    />
                                ) }}
                        />
                    </Typography.Paragraph>
                    <ul>
                        {messages?.jobs[`${score[0].letter}${score[1].letter}${score[2].letter}`].map((message, i) => <li key={i}>{message}</li>)}
                    </ul>
                </>
            )}
        </div>
    );
});

export default TricamSurveyResults;
