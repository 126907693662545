import React, { FC, useEffect, useState, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Typography, InputNumber, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { ModalProps } from 'antd/lib/modal';

import { MainReducerState } from '../../store/reducers';
import { AuthState, checkLoginStatus as checkLoginStatusAction, getAuthState } from '../../store/actions/auth';
import { updateMy, getMyProgramUpdateState, ProgramsState } from '../../store/actions/programs';

import formMessages from '../../locale/formMessages';
import AccountMessages from './AccountMessages';
import Modal from '../../components/Modal';
import genericMessages from '../../locale/genericMessages';
import { usePrevious } from '../../hooks';

interface AccountSuspensionModalProps extends ModalProps {
    authState: AuthState;
    myProgramUpdateState: ProgramsState['updateMy'];
    checkLoginStatus: typeof checkLoginStatusAction.trigger;
    updateMyProgram: typeof updateMy.trigger;
}

const AccountSuspensionModal: FC<AccountSuspensionModalProps> = ({
    authState, updateMyProgram, myProgramUpdateState, checkLoginStatus, visible, onCancel,
}) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [step, setStep] = useState<'form' | 'confirm' | 'success'>('form');
    const previous = usePrevious({ myProgramUpdateState });
    const getTitle = () => {
        switch (step) {
            case 'confirm': return <FormattedMessage {...AccountMessages.suspensionFormModalTitleConfirm} />;
            case 'form': return <FormattedMessage {...AccountMessages.suspensionFormModalTitle} />;
            default: return undefined;
        }
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onClickSubmit = () => {
        form.validateFields().then(() => {
            setStep('confirm');
        });
    };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        updateMyProgram(values);
    };

    useEffect(() => {
        if (!visible) {
            setStep('form');
        }
    }, [visible]);

    useEffect(() => {
        if (visible && previous?.myProgramUpdateState.loading && !myProgramUpdateState.loading) {
            if (myProgramUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                setStep('success');
                checkLoginStatus();
            }
        }
    }, [visible, myProgramUpdateState.loading, myProgramUpdateState.error, formatMessage, previous, checkLoginStatus]);

    return (
        <Modal
            footer={null}
            onCancel={onCancel}
            title={getTitle()}
            visible={visible}
            zIndex={900}
            width={step === 'form' ? 550 : 444}
        >
            <Form
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                form={form}
                initialValues={{
                    suspensions: authState.user?.selectedProgram?.remainingSuspensions ?? 0,
                }}
                style={{ maxWidth: 534 }}
            >
                <div style={{ display: step === 'confirm' || step === 'success' ? 'none' : 'block' }}>
                    <Form.Item
                        name="suspensions"
                        label={<p className="text-md"><FormattedMessage {...AccountMessages.suspensionFormLabel} /></p>}
                        rules={[
                            requiredRule,
                            {
                                type: 'number',
                                max: authState.user?.selectedProgram?.remainingSuspensions,
                                message: formatMessage(AccountMessages.suspensionFormDurationError, {
                                    max: authState.user?.selectedProgram?.remainingSuspensions,
                                }),
                            },
                        ]}
                        style={{ marginBottom: 24 }}
                        extra={(
                            <FormattedMessage
                                {...AccountMessages.suspensionFormHelp}
                                values={{
                                    bold: (...chunks: ReactElement[]) => (
                                        <strong>
                                            {chunks}
                                        </strong>
                                    ),
                                    count: authState.user?.selectedProgram?.remainingSuspensions ?? 0,
                                }}
                            />
                        )}
                    >
                        <InputNumber min={1} max={authState.user?.selectedProgram?.remainingSuspensions} style={{ width: 80 }} />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        <Button
                            type="primary"
                            size="large"
                            onClick={onClickSubmit}
                        >
                            <FormattedMessage {...AccountMessages.suspensionFormSubmit} />
                        </Button>
                    </Form.Item>
                </div>
                <div style={{ display: step === 'confirm' ? 'block' : 'none' }}>
                    <Typography.Paragraph className="text-light" style={{ margin: '0.5rem 0 1.5rem' }}>
                        <FormattedMessage
                            {...AccountMessages.suspensionFormConfirm}
                        />
                    </Typography.Paragraph>
                    <Form.Item>
                        <div className="flex-center">
                            <Button
                                size="large"
                                onClick={setStep.bind(null, 'form')}
                            >
                                <FormattedMessage {...genericMessages.cancel} />
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{ marginLeft: 8 }}
                                loading={myProgramUpdateState.loading}
                            >
                                <FormattedMessage {...AccountMessages.suspensionFormConfirmSubmit} />
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
            {step === 'success' && (
                <Typography.Paragraph strong>
                    <p style={{ margin: '48px 0' }}>
                        <FormattedMessage
                            {...AccountMessages.suspensionFormSuccess}
                            values={{
                                date: dayjs().add((form.getFieldValue('suspensions') ?? 0) + 1, 'week').startOf('week').subtract(1, 'day').toDate(),
                            }}
                        />
                    </p>
                </Typography.Paragraph>
            )}
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
    myProgramUpdateState: getMyProgramUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateMyProgram: updateMy.trigger,
        checkLoginStatus: checkLoginStatusAction.trigger,
    },
)(AccountSuspensionModal);
