import React, { FC } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Divider, Row, Col, Spin, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import '../../assets/styles/JobOfferDetailsCard.less';

import { JobOffer, InterviewStatus, jobOfferTypeMessages, NetworkingActionType } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { getJobOffersUpdateState, update, JobOffersState } from '../../store/actions/jobOffers';

import genericMessages, { jobOccupationMessages } from '../../locale/genericMessages';
import { IconCheckCircle, IconCrossCircle, IconCompany, IconPlace } from '../../components/icons';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { classNames, formatTextData, formatAddress } from '../../helpers';
import JobOfferDetailsMessages from './JobOfferDetailsMessages';
import AccountMessages from '../account/AccountMessages';
import JobOfferCreateMessages from '../jobOfferCreate/JobOfferCreateMessages';

interface JobOfferDetailsCardProps {
    jobOffer: JobOffer;
    jobOfferUpdateState: JobOffersState['update'];
    updateJobOffer: typeof update.trigger;
}

const JobOfferDetailsCard: FC<JobOfferDetailsCardProps> = ({
    jobOffer, jobOfferUpdateState, updateJobOffer,
}) => {
    const history = useHistory();
    const onClickInterview = (status: InterviewStatus) => {
        updateJobOffer({
            id: jobOffer.id,
            jobOffer: {
                status,
            },
        });

        if (status === InterviewStatus.accepted) {
            history.push(getRoute(RoutePathName.networkingActionCreate), {
                values: {
                    jobOffer: jobOffer.id,
                    actionType: NetworkingActionType.jobInterview,
                },
                previous: getRoute(RoutePathName.jobOffer, { jobOfferId: jobOffer.id }),
            });
        }
    };

    return (
        <div
            className={classNames(
                'job-offer-details-card',
            )}
        >
            {jobOffer.status === InterviewStatus.accepted && (
                <div className="job-offer-details-card-top is-accepted">
                    <div className="flex flex-align-center">
                        <IconCheckCircle /> <FormattedMessage {...JobOfferDetailsMessages.interviewScheduled} />
                    </div>
                    <FormattedDate value={jobOffer.appointments?.[0].date} />
                </div>
            )}
            {jobOffer.status === InterviewStatus.refused && (
                <div className="job-offer-details-card-top is-refused">
                    <div className="flex flex-align-center">
                        <IconCrossCircle /> <FormattedMessage {...JobOfferDetailsMessages.interviewRejected} />
                    </div>
                    <FormattedDate value={jobOffer.statusDate} />
                </div>
            )}
            <header className="flex-between">
                <Typography.Title level={1} style={{marginBottom: 0}}>
                    {jobOffer.title}
                </Typography.Title>
                <ButtonLink
                    to={getRoute(RoutePathName.jobOfferEdit, { jobOfferId: jobOffer.id })}
                    ghost
                >
                    <FormattedMessage {...genericMessages.edit} />
                </ButtonLink>
            </header>
            <div className="job-offer-details-card-infos">
                <p className="value">
                    <IconCompany />
                    {jobOffer.company?.name}
                </p>
                <p className="value">
                    <IconPlace />
                    {formatAddress(jobOffer.company?.address)}
                </p>
            </div>
            {jobOffer.status === InterviewStatus.pending && (
                <Spin spinning={jobOfferUpdateState.loading}>
                    <Divider />
                    <div className="flex-lg-between">
                        <Button
                            onClick={onClickInterview.bind(null, InterviewStatus.refused)}
                            danger
                            ghost
                        >
                            <FormattedMessage {...JobOfferDetailsMessages.interviewRejected} />
                        </Button>
                        <Button
                            onClick={onClickInterview.bind(null, InterviewStatus.accepted)}
                            type="primary"
                            className="btn-success"
                        >
                            <FormattedMessage {...JobOfferDetailsMessages.interviewScheduled} />
                        </Button>
                    </div>
                </Spin>
            )}
            <Divider />
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <p className="label"><FormattedMessage {...genericMessages.creationDate} /></p>
                    <p className="value"><FormattedDate value={jobOffer.createdAt} /></p>
                </Col>
                <Col xs={24} lg={12}>
                    <p className="label"><FormattedMessage {...JobOfferCreateMessages.applyDateLabel} /></p>
                    <p className="value"><FormattedDate value={jobOffer.createdAt} /></p>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <p className="label"><FormattedMessage {...jobOfferTypeMessages.get(jobOffer.type)} /></p>
                    <p className="value">{formatTextData(jobOffer.origin)}</p>
                </Col>
                <Col xs={24} lg={12}>
                    <p className="label"><FormattedMessage {...JobOfferCreateMessages.referenceLabel} /></p>
                    <p className="value">{formatTextData(jobOffer.reference)}</p>
                </Col>
            </Row>
            <p className="label"><FormattedMessage {...AccountMessages.jobOccupationLabel} /></p>
            <p className="value">
                {jobOffer.job?.occupation &&
                    <FormattedMessage {...jobOccupationMessages.get(jobOffer.job.occupation)} />
                }
            </p>
            <Divider />
            <p className="label"><FormattedMessage {...JobOfferCreateMessages.descriptionLabel} /></p>
            <p style={{ whiteSpace: 'pre-line' }}>{formatTextData(jobOffer.description)}</p>
            {!!jobOffer.documents.length && (
                <>
                    <Divider />
                    <p className="label"><FormattedMessage {...JobOfferDetailsMessages.attachment} /></p>
                    <p className="job-offer-attachment">
                        <a href={jobOffer.documents[0].url} target="_blank" rel="noopener noreferrer">
                            <img src={jobOffer.documents[0].url} alt={jobOffer.documents[0].description} />
                        </a>
                        {jobOffer.documents[0].description}
                    </p>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    jobOfferUpdateState: getJobOffersUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateJobOffer: update.trigger,
    },
)(JobOfferDetailsCard);
