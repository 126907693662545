import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Space, Typography } from 'antd';

import '../../assets/styles/Program.less';

import Seo from '../../components/Seo';
import ProgramCard from '../../components/ProgramCard';
import ProgramMessages from './ProgramMessages';
import TaskHistory from './TaskHistory';
import HeaderCTA from '../../components/HeaderCTA';
import { IconPlus } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';

const ProgramPage: FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.program),
            },
        });
    };

    return (
        <div id="program">
            <Seo title={formatMessage(ProgramMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <Typography.Title level={1}>
                <FormattedMessage {...ProgramMessages.title} />
            </Typography.Title>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <section>
                    <ProgramCard />
                </section>
                <TaskHistory />
            </Space>
        </div>
    );
};

export default ProgramPage;
