import React, { createContext, useState, FC } from 'react';

import { Language } from '../types';

const availableLangs = Object.values(Language);
export const defaultLanguage = Language.fr;

const initialLanguage = availableLangs.includes(localStorage.getItem('locale') as Language) ?
    localStorage.getItem('locale') as Language :
    defaultLanguage;

interface LocaleContextTypes {
    locale: Language;
    setLocale: React.Dispatch<React.SetStateAction<Language>>;
}

export const LocaleContext = createContext<LocaleContextTypes>({
    locale: initialLanguage,
    setLocale: () => undefined,
});

export const LocaleContextProvider: FC = ({ children }) => {
    const [locale, setLocale] = useState(initialLanguage);

    const layoutContext = {
        locale,
        setLocale,
    };

    return (
        <LocaleContext.Provider value={layoutContext}>
            {children}
        </LocaleContext.Provider>
    );
};
