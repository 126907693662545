import { defineMessages } from 'react-intl';

export default defineMessages({
    packCount: {
        id: 'pack_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun pack actif}
                one {1 pack actif}
                other {{count, number} packs actifs}
            }
        `,
        description: 'Pack count',
    },
    siegeCount: {
        id: 'siege_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun siège}
                one {1 siège}
                other {{count, number} sièges}
            }
        `,
        description: 'Siege count',
    },
    availableSiegeCount: {
        id: 'available_siege_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun siège disponible}
                one {1 siège disponible}
                other {{count, number} sièges disponibles}
            }
        `,
        description: 'Siege count',
    },
    availableCount: {
        id: 'available_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun disponible}
                one {1 disponible}
                other {{count, number} disponibles}
            }
        `,
        description: 'Siege count',
    },
    groupCount: {
        id: 'group_count',
        defaultMessage: `
            {count, plural,
                =0 {aucun groupe}
                one {1 groupe}
                other {{count, number} groupes}
            }
        `,
        description: 'Group count',
    },
    roleSuperAdmin: {
        id: 'client.role_superAdmin',
        defaultMessage: 'Super Admin',
        description: 'Role',
    },
    roleOwner: {
        id: 'client.role_owner',
        defaultMessage: 'Administrateur principal',
        description: 'Role',
    },
    roleAdmin: {
        id: 'client.role_admin',
        defaultMessage: 'Administrateur',
        description: 'Role',
    },
    roleManager: {
        id: 'client.role_manager',
        defaultMessage: 'Manager',
        description: 'Role',
    },
    roleUser: {
        id: 'client.role_user',
        defaultMessage: 'Utilisateur',
        description: 'Role',
    },
    roleHeadHR: {
        id: 'client.role_headhr',
        defaultMessage: 'RRH',
        description: 'Role',
    },
    name: {
        id: 'client.name',
        defaultMessage: 'Nom',
        description: 'Name',
    },
    description: {
        id: 'client.description',
        defaultMessage: 'Description',
        description: 'description',
    },
    sieges: {
        id: 'client.sieges',
        defaultMessage: 'Sièges',
        description: 'Sieges',
    },
    users: {
        id: 'client.users',
        defaultMessage: 'Utilisateurs',
        description: 'Users',
    },
    groupPacks: {
        id: 'client.groupPacks',
        defaultMessage: 'Packs de rattachement',
        description: 'Group packs',
    },
    edit: {
        id: 'client.edit',
        defaultMessage: 'Modifier',
        description: 'Edit',
    },
    delete: {
        id: 'client.delete',
        defaultMessage: 'Supprimer',
        description: 'Delete',
    },
    sortBy: {
        id: 'client.sortBy',
        defaultMessage: 'Trier par :',
        description: 'Sort by',
    },
    deleteConfirmTitle: {
        id: 'client.delete.confirm',
        defaultMessage: 'Confirmez-vous la suppression ?',
        description: 'Delete item confirm title',
    },
    confirm: {
        id: 'client.confirm',
        defaultMessage: 'Confirmer',
        description: 'Confirm',
    },
    cancel: {
        id: 'client.cancel',
        defaultMessage: 'Annuler',
        description: 'Cancel',
    },
    select: {
        id: 'client.select',
        defaultMessage: 'Sélectionner',
        description: 'Select',
    },
});
