import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import {
    LogsPayload,
    logs as logsApiCall,
} from '../api/logs';
import { RequestState } from '../reducers';

// State

export type LogsState = RequestState;

const initialState: LogsState = {
    loading: false,
};

// Actions/Reducers

export const logs = new EzeeAsyncAction<
    LogsState, LogsPayload
>('logs', initialState, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState,
    }),
});

// Reducer

export const logsReducer = logs.reducer;

// Saga

export function* logsSaga() {
    yield takeLatest(logs.type.trigger, simpleAsyncSaga(logsApiCall, logs));
}
