import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Badge, Button, Table, Dropdown, Menu, Popconfirm, Tag, message } from 'antd';
import { UserGroup, User, Pack, RoleSlug, Organization } from '../../../store/api/apiTypes';
import Seo from '../../../components/Seo';
import { MainReducerState } from '../../../store/reducers';
import { getUser } from '../../../store/actions/auth';
import useSessionStorage from '../../../hooks/sessionStorage';
import { useHistory, NavLink } from 'react-router-dom';
import { FilterQuery } from '../../../store/api';
import { getRoute, RoutePathName } from '../../../routes';
import HeaderCTA from '../../../components/HeaderCTA';
import { IconPlus, IconMore } from '../../../components/icons';
import { ColumnProps, TableProps } from 'antd/lib/table';
import ClientGroupsMessages from './ClientGroupsMessages';
import clientMessages from '../../../locale/clientMessages';
import { usePrevious } from '../../../hooks';
import genericMessages from '../../../locale/genericMessages';
import {
    UserGroupsState,
    list as userGroupsList,
    remove as userGroupsRemove,
} from '../../../store/actions/userGroups';

import '../../../assets/styles/ClientUserGroups.less';
import '../../../assets/styles/Table.less';
import Select from '../../../components/Select';
import Can from '../../../components/Can';

const rowKey = (item: UserGroup) => `${item.id}`;

interface ClientGroupsListProps {
    user?: User;
    userGroups: UserGroupsState;
    getList: typeof userGroupsList.trigger;
    remove: typeof userGroupsRemove.trigger;
}

const ClientGroupsList: FC<ClientGroupsListProps> = ({
    user,
    userGroups,
    getList,
    remove,
}) => {

    const itemsPerPage: number = 20;
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [ sortBy, setSortBy ] = useState('createdAt');
    const [ initialized, setInitialized ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useSessionStorage('userGroups_lastSearch', {});
    const previous = usePrevious({ remove: userGroups.remove });

    const isBack = () => {
        return history.action === 'POP';
    };

    // ---------------------------------------
    // Initial search

    useEffect(() => {
        setLastSearchParams(!isBack() ? {
            page: 0,
            pageSize: itemsPerPage,
            sort: sortBy,
            order: 'asc',
            organization: (user?.organization as Organization).id,
        } : {
            ...lastSearchParams,
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // On search params change, reload the list

    useEffect(() => {
        if (lastSearchParams && initialized) { getList({...lastSearchParams}); }
    }, [lastSearchParams, initialized, getList]);

    // ---------------------------------------
    // Handle search, filters and sort changes

    const onTableChange: TableProps<UserGroup>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const queryFilters: FilterQuery['filters'] = [];

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    const sortOptions = [
        {
            value: 'createdAt',
            message: genericMessages.sortByDate,
        },
        {
            value: 'name',
            message: genericMessages.sortAlphabetically,
        },
    ];

    const onChangeSortSelect = (val: any) => {
        setSortBy(val);
        setLastSearchParams({
            ...lastSearchParams,
            sortBy: val,
        });
    };

    // ---------------------------------------
    // Add group

    const onClickAddUserGroup = () => {
        history.push({
            pathname: getRoute(RoutePathName.clientGroupsForm),
            state: {
                previous: getRoute(RoutePathName.clientGroups),
            },
        });
    };

    // ---------------------------------------
    // Remove group

    const removeItem = (group: UserGroup) => {
        remove({id: group.id});
    };

    useEffect(() => {
        if (previous?.remove.loading && !userGroups.remove.loading) {
            if (userGroups.remove.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                getList({...lastSearchParams});
            }
        }
    }, [
        previous, userGroups.remove.loading, userGroups.remove.error, formatMessage, getList, lastSearchParams,
    ]);

    // ---------------------------------------
    // Table columns

    const preventClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    };

    const actionsMenu = (record: UserGroup) => (
        <Menu>
            <Menu.Item>
                <NavLink to={getRoute(RoutePathName.clientUsers, undefined, { group: record.id })}>
                    <FormattedMessage {...clientMessages.users} />
                </NavLink>
            </Menu.Item>
            <Menu.Item>
                <NavLink to={getRoute(RoutePathName.clientGroupsForm, {groupId: record.id})}>
                    <FormattedMessage {...clientMessages.edit} />
                </NavLink>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title={formatMessage(clientMessages.deleteConfirmTitle)}
                    onConfirm={removeItem.bind(null, record)}
                    okText={formatMessage(clientMessages.confirm)}
                    cancelText={formatMessage(clientMessages.cancel)}
                    placement="topRight"
                >
                    <div onClick={preventClick}><FormattedMessage {...clientMessages.delete} /></div>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    const columns: Array<ColumnProps<UserGroup>> = [
        {
            dataIndex: 'name',
            title: formatMessage(clientMessages.name),
            render: (name) => (
                <strong>{name}</strong>
            ),
        },
        {
            dataIndex: 'description',
            title: formatMessage(clientMessages.description),
        },
        {
            dataIndex: 'licensePacks',
            title: formatMessage(clientMessages.groupPacks),
            render: (packs) => (
                <>
                    {packs ? packs.map((pack: Pack) => (
                        <Tag key={pack.id}>{pack.name}</Tag>
                    )) : '-'}
                </>
            ),
        },
        {
            dataIndex: 'licensePacks',
            title: formatMessage(clientMessages.sieges),
            render: (licensePacks) =>
                licensePacks
                    ? licensePacks.map((pack: Pack) => pack.maxLicenses).reduce((a: number, b: number) => a + b, 0)
                    : '-',
        },
        {
            key:  'actions',
            width: 50,
            render: (text, record) => (
                <Dropdown
                    overlay={actionsMenu.bind(null, record)}
                    trigger={['click']}
                >
                    <Button
                        className="more-actions"
                        type="text"
                        icon={<IconMore />}
                        size="large"
                    />
                </Dropdown>
            ),
        },
    ];

    return (
        <div id="client-groups-list">
            <Seo title={formatMessage(ClientGroupsMessages.title)} />

            <Can roles={[RoleSlug.superAdmin, RoleSlug.owner, RoleSlug.admin]}>
                <HeaderCTA
                    onClick={onClickAddUserGroup}
                    icon={<IconPlus />}
                >
                    <FormattedMessage {...ClientGroupsMessages.addGroup} />
                </HeaderCTA>
            </Can>

            <div className="table-title-row flex-between">
                <Typography.Title level={1}>
                    <FormattedMessage
                        {...ClientGroupsMessages.groups}
                        values={{
                            count: userGroups.list.data?.totalCount,
                        }}
                    />
                    <Badge count={userGroups.list.data?.totalCount} overflowCount={10000} />
                </Typography.Title>

                <div className="filters-select">
                    <FormattedMessage {...clientMessages.sortBy} tagName="span" />
                    <Select
                        bordered={false}
                        value={sortBy}
                        onChange={onChangeSortSelect}
                    >
                        {sortOptions!.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                <FormattedMessage {...option.message} values={option.message} />
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>

            {userGroups.list.data ? (
                <Table<UserGroup>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={userGroups.list.loading}
                    dataSource={userGroups.list.data.items}
                    pagination={{
                        total: userGroups.list.data.totalCount,
                        current: userGroups.list.data.page + 1,
                        pageSize: userGroups.list.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    userGroups: state.userGroups,
});

export default connect(
    mapStateToProps,
    {
        getList: userGroupsList.trigger,
        remove: userGroupsRemove.trigger,
    },
)(ClientGroupsList);
