import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';

import { UserGroup, Organization } from '../../../store/api/apiTypes';

import formMessages from '../../../locale/formMessages';
import genericMessages from '../../../locale/genericMessages';
import CreateGroupMessages from './CreateGroupMessages';
import PacksFilterSelect from '../../../components/PacksFilterSelect';

interface CreateGroupFormProps {
    onSubmit: (values: UserGroup) => void;
    organizationId?: Organization['id'];
    initialValues?: UserGroup;
}

const CreateGroupForm: FC<CreateGroupFormProps> = ({ onSubmit, initialValues, organizationId }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    const onFormValidSubmit: FormProps<UserGroup>['onFinish'] = (values) => {
        onSubmit(values);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <Form<UserGroup>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFormValidSubmit}
        >
            <Card
                className="form-card"
            >
                <Form.Item
                    label={formatMessage(CreateGroupMessages.nameLabel)}
                    name="name"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(CreateGroupMessages.namePlaceholder)}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateGroupMessages.descriptionLabel)}
                    name="description"
                    rules={[requiredRule]}
                >
                    <Input.TextArea
                        placeholder={formatMessage(CreateGroupMessages.descriptionPlaceholder)}
                        rows={3}
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage(CreateGroupMessages.packsLabel)}
                    name="licensePacks"
                    rules={[requiredRule]}
                >
                    <PacksFilterSelect
                        placeholder={formatMessage(CreateGroupMessages.packsPlaceholder)}
                        size="large"
                        multi={true}
                        filters={{
                            filters: [{
                                name: 'organization',
                                value: organizationId,
                            }],
                        }}
                    />
                </Form.Item>
            </Card>
            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={
                            !form.isFieldsTouched() ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                        <FormattedMessage {...genericMessages.continue} />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default CreateGroupForm;
