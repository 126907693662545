import { hasOwnProp, debug } from './helpers';
import { StaticPageSlug } from './store/api/apiTypes';

export enum RoutePathName {
    account = 'account',
    calendar = 'calendar',
    client = 'client',
    clientAccount = 'clientAccount',
    clientDashboard = 'clientDashboard',
    clientGroups = 'clientGroups',
    clientGroupsForm = 'clientGroupsForm',
    clientOrganization = 'clientOrganization',
    clientUser = 'clientUser',
    clientUserCreate = 'clientUserCreate',
    clientUserProgramClosure = 'clientUserProgramClosure',
    clientUsers = 'clientUsers',
    contact = 'contact',
    contactCreate = 'contactCreate',
    contactEdit = 'contactEdit',
    contacts = 'contacts',
    dashboard = 'dashboard',
    documents = 'documents',
    faq = 'faq',
    forgottenPassword = 'forgottenPassword',
    home = 'home',
    library = 'library',
    libraryDocument = 'libraryDocument',
    login = 'login',
    loginTerms = 'loginTerms',
    networkingAction = 'networkingAction',
    networkingActionEdit = 'networkingActionEdit',
    networkingActions = 'networkingActions',
    networkingActionCreate = 'networkingActionCreate',
    notFound = 'notFound',
    jobOffers = 'jobOffers',
    jobOffer = 'jobOffer',
    jobOfferCreate = 'jobOfferCreate',
    jobOfferEdit = 'jobOfferEdit',
    privacyPolicy = 'privacyPolicy',
    program = 'program',
    programTask = 'programTask',
    resetPassword = 'resetPassword',
    terms = 'terms',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.account]: '/account',
    [RoutePathName.calendar]: '/calendar',
    [RoutePathName.client]: '/client',
    [RoutePathName.clientDashboard]: '/client/dashboard',
    [RoutePathName.clientGroups]: '/client/groups',
    [RoutePathName.clientGroupsForm]: '/client/groups/form/:groupId?',
    [RoutePathName.clientOrganization]: '/client/organization',
    [RoutePathName.clientUser]: '/client/user/:userId',
    [RoutePathName.clientUserCreate]: '/client/user/create/:step?',
    [RoutePathName.clientUserProgramClosure]: '/client/user/:userId/closure',
    [RoutePathName.clientUsers]: '/client/users',
    [RoutePathName.clientAccount]: '/client/account',
    [RoutePathName.contactCreate]: '/contacts/create',
    [RoutePathName.contact]: '/contacts/:contactId',
    [RoutePathName.contactEdit]: '/contacts/:contactId/edit',
    [RoutePathName.contacts]: '/contacts',
    [RoutePathName.dashboard]: '/dashboard',
    [RoutePathName.documents]: '/documents',
    [RoutePathName.faq]: '/faq',
    [RoutePathName.forgottenPassword]: '/forgotten-password',
    [RoutePathName.home]: '/',
    [RoutePathName.library]: '/library',
    [RoutePathName.libraryDocument]: '/library/:documentId',
    [RoutePathName.login]: '/login',
    [RoutePathName.loginTerms]: '/login-terms',
    [RoutePathName.networkingAction]: '/networking-actions/:networkingActionId',
    [RoutePathName.networkingActionEdit]: '/networking-actions/:networkingActionId/edit',
    [RoutePathName.networkingActions]: '/networking-actions',
    [RoutePathName.networkingActionCreate]: '/networking-actions/create/:step?',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.jobOffers]: '/job-offers',
    [RoutePathName.jobOffer]: '/job-offers/:jobOfferId',
    [RoutePathName.jobOfferEdit]: '/job-offers/:jobOfferId/edit',
    [RoutePathName.jobOfferCreate]: '/job-offers/create',
    [RoutePathName.privacyPolicy]: `/${StaticPageSlug.privacyPolicy}`,
    [RoutePathName.program]: '/program',
    [RoutePathName.programTask]: '/program/task/:taskId',
    [RoutePathName.resetPassword]: '/reset-password',
    [RoutePathName.terms]: `/${StaticPageSlug.termsOfUse}`,
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams,
    anchor?: string,
) => {
    let route = getRawRoute(path);
    const rawParams = route.split('/').filter((str) => str.startsWith(':'));
    const providedParamNames = params ? Object.keys(params) : [];

    rawParams.forEach((rawParam) => {
        const isOptionnal = rawParam.endsWith('?');
        const rawParamStripped = isOptionnal ?
            rawParam.substring(1, rawParam.length - 1) :
            rawParam.substring(1);

        if (params && providedParamNames.includes(rawParamStripped)) {
            route = route.replace(rawParam, `${params[rawParamStripped]}`);
        } else if (isOptionnal) {
            debug.info(`[getRoute] Optionnal param "${rawParamStripped}" not provided in route "${route}", skipping param.`);
            route = route.replace(`/${rawParam}`, '');
        } else {
            throw new Error(`[getRoute] Param "${rawParamStripped}" not provided in route "${route}".`);
        }
    });

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
