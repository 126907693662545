import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 16">
        <path fill="currentColor" d="M10.002 16h-4c-.744 0-1.348-.426-2-1.028v.528c0 .274-.226.5-.5.5H1c-.548 0-1-.45-1-1V8c0-.554.452-1 1-1a5.002 5.002 0 005.002-5 2 2 0 114 0v2c0 .73-.212 1.412-.556 2h2.556C13.108 6 14 6.896 14 8v3.998A4 4 0 0110.002 16z"/>
    </svg>
);

const IconReferred: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-referred${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconReferred;
