import React, { FC, DetailedHTMLProps } from 'react';

import '../assets/styles/ListHeader.less';

const ListHeader: FC<DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = ({ children, ...props }) => (
    <header className="list-header" {...props}>
        {children}
    </header>
);

export default ListHeader;
