import { Theme } from '@nivo/core';
import tinycolor from 'tinycolor2';

export const nivoTheme: Theme = {
    background: '#ffffff',
    textColor: '#2e384a',
    fontSize: 14,
    fontFamily: 'Eina04, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    axis: {
        domain: {
            line: {
                stroke: '#eff2ff',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#7881b2',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#eff2ff',
            strokeWidth: 1,
        },
    },
};

export const nivoColors = ['#131454', '#4D42C7', '#C1B3FF', '#E3DBFF'];
export const getThemeNivoColors = (color?: string) => {
    if (!color) {
        return nivoColors;
    }

    const colorL = tinycolor(color).toHsl().l;

    return [
        tinycolor({ ...tinycolor(color).toHsl(), l: colorL - (colorL * 20 / 100) }).toHexString(),
        color,
        tinycolor({ ...tinycolor(color).toHsl(), l: colorL + (colorL * 40 / 100) }).toHexString(),
        tinycolor({ ...tinycolor(color).toHsl(), l: colorL + (colorL * 50 / 100) }).toHexString(),
    ];
};
