import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'contact_details.title',
        defaultMessage: 'Contacts',
        description: 'Contact details page title',
    },
    backToList: {
        id: 'contact_details.back_to_list',
        defaultMessage: 'Retour aux contacts',
        description: 'Contact details page back to contact list link',
    },
    referred: {
        id: 'contact_details.referred',
        defaultMessage: 'Personnes prescrites',
        description: 'Contact details page referred title',
    },
    contactActions: {
        id: 'contact_details.actions',
        defaultMessage: 'Actions de Networking avec ce contact',
        description: 'Contact details page actions section title',
    },
    updateSubmit: {
        id: 'contact_details.edit.submit',
        defaultMessage: 'Modifier le contact',
        description: 'Contact details page edit form submit',
    },
    updateSuccess: {
        id: 'contact_details.edit.success',
        defaultMessage: 'Le contact a été modifié avec succès',
        description: 'Contact details page edit success',
    },
});
