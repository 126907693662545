import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, Form, Input, Divider, Row, Col, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';

import * as contactActions from '../../store/actions/contacts';
import { MainReducerState } from '../../store/reducers';
import { ContactType, contactTypeMessages } from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import Select from '../../components/Select';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { stripUndefinedKeysFromObject, getFullName } from '../../helpers';
import { usePrevious } from '../../hooks';
import NetworkingActionCreateMessages from './NetworkingActionCreateMessages';
import { NetworkingActionCreateStep, NetworkingActionCreateValues } from './NetworkingActionCreate';

interface NetworkingActionCreateWhoProps {
    createContact: typeof contactActions.create.trigger;
    createContactState: contactActions.ContactsState['create'];
    initialValues?: NetworkingActionCreateValues;
    listContact: typeof contactActions.list.trigger;
    listContactState: contactActions.ContactsState['list'];
    onSubmit: (values: NetworkingActionCreateValues) => void;
    mustCreateContact: boolean;
}

const NetworkingActionCreateWho: FC<NetworkingActionCreateWhoProps> = ({
    createContact, createContactState, initialValues, listContact, listContactState, onSubmit,
    mustCreateContact,
}) => {
    const location = useLocation();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const history = useHistory();
    const previous = usePrevious({ createContactState });
    const [isContactCreation, setIsContactCreation] = useState(mustCreateContact);
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onContactSearch: SelectProps<string>['onSearch'] = (search) => {
        listContact(stripUndefinedKeysFromObject({ limit: 20, search: search || undefined, throttling: 300 }));
    };
    const onFormValidSubmit: FormProps<NetworkingActionCreateValues>['onFinish'] = (values) => {
        if (isContactCreation) {
            const result = stripUndefinedKeysFromObject({
                ...values,
                type: values.contactType,
            });
            createContact(result);
        } else {
            onSubmit(values);
            history.push({
                pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.when }),
                search: location.search,
            });
        }
    };
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (!isContactCreation) {
            listContact({ limit: 20 });
        }
    }, [isContactCreation, listContact]);

    useEffect(() => {
        if (previous?.createContactState.loading && !createContactState.loading) {
            if (createContactState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                form.resetFields();
                onSubmit({ contact: createContactState.data?.id });
                history.push(getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.when }));
            }
        }
    }, [
        previous, createContactState.loading, createContactState.error, formatMessage, form,
        createContactState.data, history, onSubmit,
    ]);

    return (
        <Form<NetworkingActionCreateValues>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFormValidSubmit}
            initialValues={initialValues}
        >
            <Card
                title={formatMessage(NetworkingActionCreateMessages.whoTitle)}
                className="form-card"
            >
                {isContactCreation ? (
                    <>
                        <Divider orientation="left" plain>
                            <FormattedMessage {...NetworkingActionCreateMessages.whoCreateContact} />
                        </Divider>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whoTypeLabel)}
                            name="contactType"
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(NetworkingActionCreateMessages.whoTypePlaceholder)}
                                showArrow
                            >
                                {Object.values(ContactType).map((type) => (
                                    <Select.Option value={type} key={type}>
                                        <FormattedMessage {...contactTypeMessages.get(type)} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Row gutter={24}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whoLastNameLabel)}
                                    name="lastName"
                                    rules={[requiredRule]}
                                >
                                    <Input
                                        placeholder={formatMessage(NetworkingActionCreateMessages.whoLastNamePlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whoFirstNameLabel)}
                                    name="firstName"
                                >
                                    <Input
                                        placeholder={formatMessage(NetworkingActionCreateMessages.whoFirstNamePlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whoCompanyLabel)}
                                    name={['company', 'name']}
                                >
                                    <Input
                                        placeholder={formatMessage(NetworkingActionCreateMessages.whoCompanyPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whoDepartmentLabel)}
                                    name={['company', 'service']}
                                >
                                    <Input
                                        placeholder={formatMessage(NetworkingActionCreateMessages.whoDepartmentPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <FormattedMessage {...NetworkingActionCreateMessages.whoCreateContactHelp} tagName="p" />
                        {!mustCreateContact && (
                            <Form.Item className="text-center">
                                <Button
                                    onClick={setIsContactCreation.bind(null, false)}
                                    ghost
                                >
                                    <FormattedMessage {...NetworkingActionCreateMessages.whoSelectExisitingContact} />
                                </Button>
                            </Form.Item>
                        )}
                    </>
                ) : (
                    <>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whoContactLabel)}
                            name="contact"
                        >
                            <Select
                                filterOption={false}
                                loading={listContactState.loading}
                                onSearch={onContactSearch}
                                placeholder={formatMessage(NetworkingActionCreateMessages.whoContactPlaceholder)}
                                showSearch
                                showArrow
                            >
                                {listContactState.data?.items.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {getFullName(item.firstName, item.lastName)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item className="text-center">
                            <p className="text-uppercase text-light text-semibold">
                                <FormattedMessage {...genericMessages.or} />
                            </p>
                            <Button
                                onClick={setIsContactCreation.bind(null, true)}
                                ghost
                            >
                                <FormattedMessage {...NetworkingActionCreateMessages.whoCreateContact} />
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Card>
            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={createContactState.loading}
                        size="large"
                        disabled={
                            form.getFieldValue('contact') ?
                                false : (
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                )
                        }
                    >
                        {isContactCreation ?
                            <FormattedMessage {...NetworkingActionCreateMessages.whoCreateContactSubmit} /> :
                            <FormattedMessage {...genericMessages.continue} />
                        }
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createContactState: contactActions.getContactsCreateState(state),
    listContactState: contactActions.getContactsListState(state),
});

export default connect(
    mapStateToProps,
    {
        createContact: contactActions.create.trigger,
        listContact: contactActions.list.trigger,
    },
)(NetworkingActionCreateWho);
