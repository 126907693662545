import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Form, Typography, Space } from 'antd';

import * as usersActions from '../../../store/actions/users';

import { MainReducerState } from '../../../store/reducers';
import { FormProps } from 'antd/lib/form';
import formMessages from '../../../locale/formMessages';
import ClientUsersMessages from './ClientUsersMessages';
import { User } from '../../../store/api/apiTypes';
import UserProgramFilterSelect from '../../../components/UserProgramFilterSelect';

interface ClientUserProgramModalProps {
    user: User;
    isVisible: boolean;
    isAfterCreate: boolean;
    isExtension: boolean;
    onClose: () => void;
    onSuccess?: (payload: any) => void;

    updateActionState: usersActions.UsersState['update'];
    update: typeof usersActions.update.trigger;
}

const ClientUserProgramModal: FC<ClientUserProgramModalProps> = ({
    user,
    isVisible,
    isAfterCreate,
    isExtension,
    onClose,
    onSuccess,
    updateActionState,
    update,
}) => {
    const { formatMessage } = useIntl();

    const [form] = Form.useForm();
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    useEffect(() => {
        if (isVisible) {
            form.resetFields();
        }
    }, [isVisible, form]);

    const onFormFinish: FormProps['onFinish'] = (values) => {
        form.validateFields().then(() => {
            if (onSuccess) { onSuccess(values); }

            if (isExtension) {
                update({
                    extension: values.program.selectedProgram,
                    id: user.id,
                });
            } else {
                update({
                    ...values.program,
                    id: user.id,
                });
            }
        });
    };

    const onModalClose = () => {
        onClose();
        form.resetFields();
    };

    return (
        <Modal
            wrapClassName="program-modal"
            footer={null}
            visible={isVisible}
            onCancel={onModalClose}
            width={550}
            maskClosable
        >
            {isExtension ? (
                <>
                    <Typography.Title level={4}>
                        <FormattedMessage {...ClientUsersMessages.programExtension} />
                    </Typography.Title>
                    <p className="intro">
                        <FormattedMessage {...ClientUsersMessages.selectProgramExtension} />
                    </p>
                </>
            ) : (
                <>
                    {isAfterCreate ? (
                        <>
                            <Typography.Title level={4}>
                                <FormattedMessage {...ClientUsersMessages.userCreatedSuccess} />
                            </Typography.Title>
                            <p className="intro">
                                <FormattedMessage {...ClientUsersMessages.selectProgramBelow} />
                            </p>
                        </>
                    ) : (
                        <Typography.Title level={4}>
                            <FormattedMessage
                                {
                                    ...(user.selectedProgram?.program) ?
                                    ClientUsersMessages.changeProgramModalTitle :
                                    ClientUsersMessages.selectProgram
                                }
                            />
                        </Typography.Title>
                    )}
                </>
            )}

            <Form
                form={form}
                onFinish={onFormFinish}
                layout="vertical"
                hideRequiredMark
            >
                <Form.Item
                    name="program"
                    rules={[requiredRule]}
                >
                    <UserProgramFilterSelect
                        user={user}
                        size="middle"
                        isExtension={isExtension}
                    />
                </Form.Item>

                <div className="flex-center">
                    <Space>
                        <Form.Item>
                            {(isAfterCreate) && (
                                <Button
                                    type="ghost"
                                    onClick={onModalClose}
                                >
                                    <FormattedMessage {...ClientUsersMessages.validateProgramLater} />
                                </Button>
                            )}
                        </Form.Item>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    <FormattedMessage
                                        {
                                            ...(
                                                isExtension ? ClientUsersMessages.validateProgramExtension : (
                                                    (user.selectedProgram?.program) ?
                                                    ClientUsersMessages.editProgram :
                                                    ClientUsersMessages.validateProgram
                                                )
                                            )
                                        }
                                    />
                                </Button>
                            )}
                        </Form.Item>
                    </Space>
                </div>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    updateActionState: state.users.update,
});

export default connect(
    mapStateToProps,
    {
        update: usersActions.update.trigger,
    },
)(ClientUserProgramModal);
