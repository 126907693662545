import { UserGroup } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api, { cleanPayload } from './_client';

export interface UserGroupDetailsPayload {
    id: string;
}

export type UserGroupListPayload = SearchPaginationQuery;

export const details = (payload: UserGroupDetailsPayload) => api.get(`/users/groups/${payload.id}`);

export const list = (payload: UserGroupListPayload = {}) => api.get(`/users/groups`, {params: cleanPayload(payload)});

export const create = (payload: UserGroup) => api.post(`/users/groups`, payload);

export const update = (payload: UserGroup) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/users/groups/${id}`, payload);
};

export const remove = (payload: UserGroup) => api.delete(`/users/groups/${payload.id}`);
