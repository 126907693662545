import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'job_offers.title',
        defaultMessage: 'Annonces',
        description: 'Job offers page title',
    },
    filtersAll: {
        id: 'job_offers.filters.all',
        defaultMessage: 'Toutes ({count})',
        description: 'Job offers page filter',
    },
    filtersPending: {
        id: 'job_offers.filters.pending',
        defaultMessage: 'En cours ({count})',
        description: 'Job offers page filter',
    },
    filtersRefused: {
        id: 'job_offers.filters.refused',
        defaultMessage: 'Négatives ({count})',
        description: 'Job offers page filter',
    },
    addJobOfferCTA: {
        id: 'job_offers.add_contact_cta',
        defaultMessage: 'Ajouter une annonce',
        description: 'Job offers page header add job offer button',
    },
    deleteTitle: {
        id: 'job_offers.delete.title',
        defaultMessage: 'Êtes-vous sur de vouloir supprimer cette annonce ?',
        description: 'Job offers page delete contact title',
    },
    deleteSuccess: {
        id: 'job_offers.delete.success',
        defaultMessage: 'Annonce supprimée avec succès',
        description: 'Job offers page delete contact success',
    },
    interviewDate: {
        id: 'job_offers.card.interview_date',
        defaultMessage: 'Entretien prévu le {date}',
        description: 'Job offers page job offer card interview date',
    },
    interviewRefusedDate: {
        id: 'job_offers.card.interview_refused_date',
        defaultMessage: 'Refus reçu le {date}',
        description: 'Job offers page job offer card interview refused date',
    },
});
