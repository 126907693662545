import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 34 40">
        <path fill="currentColor" d="M20.01 31.738v6.756c0 .83-.69 1.506-1.538 1.506H15.88c-.848 0-1.538-.676-1.538-1.506v-6.756h5.668zm8.897-11.618c.848 0 1.538.676 1.538 1.507v7.314c0 .831-.69 1.507-1.538 1.507H4.403c-.48 0-.932-.22-1.222-.59L.316 26.201a1.486 1.486 0 010-1.833l2.865-3.658c.29-.37.743-.59 1.222-.59zm-8.897-4.384v3.094h-5.668v-3.094h5.668zm9.587-11.618c.48 0 .932.22 1.222.59l2.865 3.657c.421.542.421 1.29 0 1.833l-2.865 3.657c-.29.37-.743.59-1.222.59H5.093c-.848 0-1.538-.676-1.538-1.506V5.624c0-.83.69-1.506 1.538-1.506zM18.472 0c.848 0 1.538.676 1.538 1.506v1.321h-5.668V1.506c0-.83.69-1.506 1.538-1.506z" />
    </svg>
);

const IconProfessionalProject: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-professional-project${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconProfessionalProject;
