import React, { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Form, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';

import formMessages from '../../locale/formMessages';
import AccountMessages from './AccountMessages';
import Select from '../../components/Select';
import { Language, LanguageName } from '../../types';
import { LocaleContext } from '../../context/LocaleContext';

const AccountLanguageForm: FC = () => {
    const { setLocale } = useContext(LocaleContext);
    const { formatMessage, locale } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);

    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = ({ lang }) => {
        setLocale(lang);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <>
            <Typography.Title level={2} className="h1">
                <FormattedMessage {...AccountMessages.languageTitle} />
            </Typography.Title>
            <Card>
                <Form
                    onFinish={onFormValidSubmit}
                    layout="vertical"
                    requiredMark={false}
                    form={form}
                    initialValues={{
                        lang: locale,
                    }}
                    style={{ maxWidth: 534}}
                >
                    <Typography.Title level={3}>
                        <FormattedMessage {...AccountMessages.languageFormTitle} />
                    </Typography.Title>
                    <p>
                        <FormattedMessage {...AccountMessages.languageFormHelp} tagName="small" />
                    </p>
                    <Form.Item
                        name="lang"
                        rules={[requiredRule]}
                    >
                        <Select
                            placeholder={formatMessage(AccountMessages.languagePlaceholder)}
                        >
                            {Object.values(Language).map((lang) => (
                                <Select.Option value={lang} key={lang}>
                                    {LanguageName.get(lang)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                }
                            >
                                <FormattedMessage {...AccountMessages.languageSubmit} />
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default AccountLanguageForm;
