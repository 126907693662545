import React, { FC, useEffect, useState, useCallback } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Badge, Table, Button, Upload, message } from 'antd';
import { TableProps, ColumnProps } from 'antd/lib/table';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload';

import { User, LibraryDocument } from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import { FilterQuery } from '../../../store/api';
import {
    filesList as filesListAcion, UsersState,
} from '../../../store/actions/users';
import { create, getLibraryCreateStateBySubTaskId, LibraryDocumentCreateState } from '../../../store/actions/library';
import DocumentsMessages from '../../documents/DocumentsMessages';
import genericMessages from '../../../locale/genericMessages';
import { downloadAs, getDocumentTypeFromMimeType, classNames } from '../../../helpers';
import ClientUsersMessages from './ClientUsersMessages';
import { usePrevious } from '../../../hooks';
import { UserFilePayload } from '../../../store/api/users';

const rowKey = (item: User) => `${item.id}`;

interface ClientUserFilesListProps {
    user?: User;
    getList: typeof filesListAcion.trigger;
    createDocument: typeof create.trigger;
    documentCreateState: LibraryDocumentCreateState;
    filesList: UsersState['filesList'];
    isUser?: boolean;
}

const ClientUserFilesList: FC<ClientUserFilesListProps> = ({
    user,
    getList,
    createDocument,
    documentCreateState,
    filesList,
    isUser,
}) => {

    const itemsPerPage: number = 20;
    const { formatMessage, locale } = useIntl();
    const [ initialized, setInitialized ] = useState(false);
    const [ lastSearchParams, setLastSearchParams ] = useState<UserFilePayload>({});
    const previous = usePrevious({ documentCreateState });
    const onUpload: UploadProps['beforeUpload'] = (value) => {
        createDocument({
            document: {
                document: value,
                name: value?.name,
                language: locale,
                type: getDocumentTypeFromMimeType(value?.type),
            },
        });

        return false;
    };

    // ---------------------------------------
    // On search params change, reload the list

    const refresh = useCallback(() => {
        getList({ ...lastSearchParams });
    }, [getList, lastSearchParams]);

    useEffect(() => {
        if (lastSearchParams && initialized) { refresh(); }
    }, [lastSearchParams, initialized, refresh]);

    useEffect(() => {
        if (previous?.documentCreateState.loading && !documentCreateState.loading) {
            if (documentCreateState.error) {
                if (documentCreateState.error.status === 409) {
                    message.error(formatMessage(DocumentsMessages.uploadErrorDuplicateName));
                } else {
                    message.error(formatMessage(genericMessages.defaultError));
                }
            } else {
                message.success(formatMessage(DocumentsMessages.uploadSuccess));
                refresh();
            }
        }
    }, [previous, documentCreateState.loading, documentCreateState.error, formatMessage, refresh]);

    // ---------------------------------------
    // Initial search

    useEffect(() => {
        setLastSearchParams({
            userId: user?.id,
            isUser,
            uploaded: true,
            page: 0,
            pageSize: itemsPerPage,
            sort: 'updatedAt',
            sortOrder: 'desc',
        });

        setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Handle search, filters and sort changes

    const onTableChange: TableProps<LibraryDocument>['onChange'] = (pagination, tableFilters, sorter: any) => {
        const queryFilters: FilterQuery['filters'] = [];
        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            // we take the last element
            sort: sorter?.field?.[(sorter?.field?.length || 1) - 1],
            order: (sorter.order) ? sorter.order : undefined,
            filters: queryFilters,
        };

        setLastSearchParams(newSearchParams);
    };

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<LibraryDocument>> = [
        {
            title: formatMessage(ClientUsersMessages.file),
            dataIndex: 'name',
        },
        {
            dataIndex: ['userSubTask', 'task'],
            title: formatMessage(ClientUsersMessages.actionName),
            render: (task) => (
                <>{task ? task.name : '-'}</>
            ),
        },
        {
            dataIndex:  ['updatedAt'],
            title: formatMessage(ClientUsersMessages.creationDate),
            render: (date) => (
                <>{date ? <FormattedDate value={date} /> : '-'}</>
            ),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            key: 'action',
            // title: formatMessage(ClientUsersMessages.action),
            render: (file) => (
                <Button
                    type="ghost"
                    shape="circle"
                    icon={<DownloadOutlined />}
                    onClick={downloadAs.bind(null, file.url, file.name)}
                />
            ),
        },
    ];

    return (
        <div id="client-users-list">
            <div className={classNames('flex flex-between', isUser && 'mb-16')}>
                <Typography.Title level={2}>
                    <FormattedMessage
                        {...(isUser ? DocumentsMessages.filesTitle : ClientUsersMessages.sentFiles)}
                    />
                    &nbsp;
                    <Badge count={filesList.data?.totalCount} overflowCount={10000} />
                </Typography.Title>
                {isUser && (
                    <Upload
                        beforeUpload={onUpload}
                        showUploadList={false}
                    >
                        <Button
                            type="ghost"
                            shape="round"
                            loading={documentCreateState.loading}
                        >
                            <UploadOutlined /> <FormattedMessage {...DocumentsMessages.upload} />
                        </Button>
                    </Upload>
                )}
            </div>
            {isUser && (
                <FormattedMessage {...DocumentsMessages.filesDescription} tagName="p" />
            )}

            {filesList.data ? (
                <Table<LibraryDocument>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={filesList.loading}
                    dataSource={filesList.data.items}
                    pagination={{
                        total: filesList.data.totalCount,
                        current: filesList.data.page + 1,
                        pageSize: filesList.data.pageSize,
                        hideOnSinglePage: true,
                    }}
                    onChange={onTableChange}
                />
            ) : undefined}
        </div>
    );
};

const mapStateToProps = (
    state: MainReducerState,
) => ({
    filesList: state.users.filesList,
    documentCreateState: getLibraryCreateStateBySubTaskId(state),
});

export default connect(
    mapStateToProps,
    {
        getList: filesListAcion.trigger,
        createDocument: create.trigger,
    },
)(ClientUserFilesList);
