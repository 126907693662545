import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface EnumMessagedProps {
    map: Map<string, MessageDescriptor>;
    value?: string;
}

const EnumMessage: FC<EnumMessagedProps> = ({ map, value }) => {
    return value ? (map.get(value) ? <FormattedMessage {...map.get(value)} /> : <>{value}</>) : <>-</>;
};

export default EnumMessage;
