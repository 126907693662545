export enum PasswordIssueReason {
    minimumLength = 'minimumLength',
    maximumLength = 'maximumLength',
    requireCapital = 'requireCapital',
    requireLower = 'requireLower',
    requireNumber = 'requireNumber',
    requireSpecial = 'requireSpecial',
}
interface PasswordRules {
    minimumLength: number;
    maximumLength: number;
    requireCapital: boolean;
    requireLower: boolean;
    requireNumber: boolean;
    requireSpecial: boolean;
    specialCharacters?: string;
}
interface PasswordRuleError {
    reason: PasswordIssueReason;
    message: string;
    part: string;
}
function errorSentence(reasons: PasswordRuleError[]) {
    const start = 'Password must ';
    if (reasons.length === 1) {
        return `${start}${reasons[0].part}.`;
    }
    if (reasons.length === 2) {
        return `${start}${reasons[0].part} and ${reasons[1].part}.`;
    }
    if (reasons.length > 2) {
        const last = reasons[reasons.length - 1].part;
        const parts = reasons.slice(0, -1).map((r) => r.part).join(', ');
        return `${start}, ${parts}, and ${last}.`;
    }
    return undefined;
}
export function validatePasswordRules(password: string, options?: Partial<PasswordRules>) {
    const issues: PasswordRuleError[] = [];
    const rules = {
        minimumLength: 8,
        maximumLength: 100, // Infinity,
        requireCapital: true,
        requireLower: true,
        requireNumber: true,
        requireSpecial: true,
        specialCharacters: `!@#$%^&*{}[\\]()\\-_\\/'"\`~,;:.<>`,
        ...options,
    };
    if (password.length < rules.minimumLength) {
        issues.push({
            reason: PasswordIssueReason.minimumLength,
            message: 'Password must be at least ' + rules.minimumLength + ' letters long',
            part: 'be at least ' + rules.minimumLength + ' letters long',
        });
    }
    if (password.length > rules.maximumLength) {
        issues.push({
            reason: PasswordIssueReason.maximumLength,
            message: 'Password must be less than ' + rules.maximumLength + ' letters long',
            part: 'be less than ' + rules.maximumLength + ' letters long',
        });
    }
    if (rules.requireCapital && !password.match(/[A-Z]/g)) {
        issues.push({
            reason: PasswordIssueReason.requireCapital,
            message: 'Password must contain a capital letter',
            part: 'contain a capital letter',
        });
    }
    if (rules.requireLower && !password.match(/[a-z]/g)) {
        issues.push({
            reason: PasswordIssueReason.requireLower,
            message: 'Password must contain a lowercase letter',
            part: 'contain a lowercase letter',
        });
    }
    if (rules.requireNumber && !password.match(/\d/g)) {
        issues.push({
            reason: PasswordIssueReason.requireNumber,
            message: 'Password must contain a number',
            part: 'contain a number',
        });
    }
    if (rules.requireSpecial) {
        if (rules.specialCharacters) {
            if (!password.match(new RegExp(`[${rules.specialCharacters}]+`, 'g'))) {
                issues.push({
                    reason: PasswordIssueReason.requireSpecial,
                    message: 'Password must contain a special character',
                    part: 'contain a special character',
                });
            }
        } else if (!password.match(/\W+/g)) {
            issues.push({
                reason: PasswordIssueReason.requireSpecial,
                message: 'Password must contain a special character',
                part: 'contain a special character',
            });
        }
    }
    return issues.length ? {
        sentence: errorSentence(issues),
        issues,
    } : undefined;
}
export default validatePasswordRules;
