import { combineReducers } from 'redux';
import { takeLatest, delay, call, put, all } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import {
    CreateAppointmentPayload,
    create as createApiCall,
    list as listApiCall,
    del as delApiCall,
    details as detailsApiCall,
    update as updateApiCall,
    DeleteAppointmentPayload,
    AppointmentDetailsPayload,
    AppointmentUpdatePayload,
    ListAppointmentPayload,
} from '../api/appointments';
import { MainReducerState, RequestState } from '../reducers';
import { ListResponse, Appointment, AppointmentStatus } from '../api/apiTypes';
import { SearchPaginationQuery } from '../api';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface AppointmentsState {
    readonly create: RequestState<Appointment>;
    readonly list: RequestState<ListResponse<Appointment>>;
    readonly listCountNetworkingActions: RequestState<{
        [AppointmentStatus.cancelled]?: number;
        [AppointmentStatus.pending]?: number;
        [AppointmentStatus.validated]?: number;
    }>;
    readonly listCountCalendar: RequestState<{
        upcoming?: number;
        past?: number;
    }>;
    readonly del: RequestState;
    readonly details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Appointment;
        };
    };
    readonly update: RequestState<Appointment>;
}

const initialState: AppointmentsState = {
    create: {
        loading: false,
    },
    list: {
        loading: false,
    },
    listCountNetworkingActions: {
        loading: false,
    },
    listCountCalendar: {
        loading: false,
    },
    del: {
        loading: false,
    },
    details: {
        loading: false,
        data: {},
    },
    update: {
        loading: false,
    },
};

// Actions/Reducers

export const create = new EzeeAsyncAction<
    AppointmentsState['create'], CreateAppointmentPayload, Appointment
>('appointments/create', initialState.create, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.create,
    }),
});

export const list = new EzeeAsyncAction<
    AppointmentsState['list'], ListAppointmentPayload, ListResponse<Appointment>
>('appointments/list', initialState.list, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const listCountNetworkingActions = new EzeeAsyncAction<
    AppointmentsState['listCountNetworkingActions']
>('appointments/listCountNetworkingActions', initialState.listCountNetworkingActions, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listCountNetworkingActions,
    }),
});

export const listCountCalendar = new EzeeAsyncAction<
    AppointmentsState['listCountCalendar']
>('appointments/listCountCalendar', initialState.listCountCalendar, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listCountCalendar,
    }),
});

export const del = new EzeeAsyncAction<
    AppointmentsState['del'], DeleteAppointmentPayload
>('appointments/del', initialState.del, {
    trigger: (state) => ({
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.del,
    }),
});

export const details = new EzeeAsyncAction<
    AppointmentsState['details'], AppointmentDetailsPayload, Appointment
>('appointments/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: {
            ...state.data,
            [payload.id]: payload,
        },
    }),
    failure: (state, payload) => ({
        data: {},
        loading: false,
        error: payload,
    }),
    reset: () => initialState.details,
});

export const update = new EzeeAsyncAction<
    AppointmentsState['update'], AppointmentUpdatePayload, Appointment
>('appointments/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.update,
    }),
});

// Reducer

export const appointmentsReducer = combineReducers<AppointmentsState>({
    create: create.reducer,
    list: list.reducer,
    listCountNetworkingActions: listCountNetworkingActions.reducer,
    listCountCalendar: listCountCalendar.reducer,
    del: del.reducer,
    details: details.reducer,
    update: update.reducer,
});

// Saga

export function* appointmentsSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(listCountNetworkingActions.type.trigger, listCountNetworkingActionsSaga);
    yield takeLatest(listCountCalendar.type.trigger, listCountCalendarSaga);
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

function* listSaga(action: DataAction<SearchPaginationQuery>) {
    try {
        const { throttling, ...payload } = action.payload;
        yield delay(throttling || 0);

        const response = yield call(listApiCall, payload);

        return yield put(list.success(response));
    } catch (error) {
        return yield put(list.failure(error));
    }
}

function* listCountNetworkingActionsSaga() {
    try {
        const [cancelled, pending, validated]: Array<ListResponse<Appointment>> = yield all([
            call(listApiCall, { pageSize: 1, status: AppointmentStatus.cancelled }),
            call(listApiCall, { pageSize: 1, status: AppointmentStatus.pending }),
            call(listApiCall, { pageSize: 1, status: AppointmentStatus.validated }),
        ]);

        return yield put(listCountNetworkingActions.success({
            cancelled: cancelled.totalCount,
            pending: pending.totalCount,
            validated: validated.totalCount,
        }));
    } catch (error) {
        return yield put(listCountNetworkingActions.failure(error));
    }
}

function* listCountCalendarSaga() {
    try {
        const [upcoming, past]: Array<ListResponse<Appointment>> = yield all([
            call(listApiCall, { fromDate: new Date().toISOString() }),
            call(listApiCall, { toDate: new Date().toISOString() }),
        ]);

        return yield put(listCountCalendar.success({
            upcoming: upcoming.totalCount,
            past: past.totalCount,
        }));
    } catch (error) {
        return yield put(listCountCalendar.failure(error));
    }
}

// Store helpers

export const getAppointmentsCreateState = (state: MainReducerState) => state.appointments.create;
export const getAppointmentsListState = (state: MainReducerState) => state.appointments.list;
export const getNetworkingActionsListCountState = (state: MainReducerState) => state.appointments.listCountNetworkingActions;
export const getCalendarListCountState = (state: MainReducerState) => state.appointments.listCountCalendar;
export const getAppointmentsDeleteState = (state: MainReducerState) => state.appointments.del;
export const getAppointmentsUpdateState = (state: MainReducerState) => state.appointments.update;

export type AppointmentDetailsState = RequestState<Appointment>;

export const getAppointmentStateById = (state: MainReducerState, id: Appointment['id']) => ({
    loading: state.appointments.details.loading,
    error: state.appointments.details.error,
    data: state.appointments.details.data[id],
});
