import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M3 7.716c-.011.63.233 1.269.735 1.757l3.49 3.345c.991.91 2.529.91 3.473-.006l.613-.589c.41 1.37.66 2.72.687 3.62L12 16H0c0-2 1.268-6.675 3-8.284zM15.7 4.284c.37.349.399.907.085 1.288l-.085.091-6.15 6.053c-.351.35-.918.376-1.32.08l-.096-.08-2.832-2.781c-.401-.4-.401-1.024 0-1.402.351-.35.919-.376 1.32-.08l.096.08 2.135 2.091 5.43-5.34c.381-.379 1.015-.379 1.417 0zM5.999 0c1.477 0 2.677 1.212 2.677 2.7 0 1.49-1.2 2.702-2.676 2.702-1.476 0-2.676-1.212-2.676-2.701C3.324 1.21 4.524 0 6 0z" />
    </svg>
);

const IconHiring: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-hiring${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconHiring;
