import React, { Component } from 'react';
import { Typography, Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { logs } from '../store/actions/logs';

import genericMessages from '../locale/genericMessages';
import constants from '../config/constants';
import { AuthState, getUser } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

interface ErrorBoundaryProps {
    sendLogs: typeof logs.trigger;
    user?: AuthState['user'];
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {

    public state: ErrorBoundaryState = {
        error: null,
        errorInfo: null,
    };

    public componentDidCatch(error: any, errorInfo: any) {
        const now = `${+(new Date())}000000`;
        const stack = (errorInfo.componentStack || '').split('\n').map((trace: string) => [now, trace]);

        if (constants.ENABLE_DEBUG_LOGS) {
            this.props.sendLogs({
                streams: [
                    {
                        stream: {
                            app: constants.DEBUG_LOGS_APP,
                            organization: this.props.user?.organization?.id || null,
                        },
                        values: [
                            [now, `${error}`],
                            ...stack,
                        ],
                    },
                ],
            });
        }

        this.setState({ error, errorInfo });
    }

    public render() {
        if (this.state.error) {
            return (
                <div className="container">
                    <Typography.Title level={1}>
                        <FormattedMessage {...genericMessages.defaultError} />
                    </Typography.Title>
                    <Card>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>
                    </Card>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        sendLogs: logs.trigger,
    },
)(ErrorBoundary);
