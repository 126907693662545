import React, { FC, useState, useEffect } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import '../assets/styles/Img.less';
import { ApiOutlined } from '@ant-design/icons';
import { classNames } from '../helpers';

interface ImgProps {
    alt?: string;
    className?: string;
    src?: string;
    onClick?: () => void;
    onKeyPress?: () => void;
    role?: string;
    width?: number;
    height?: number;
}

const Img: FC<ImgProps> = ({
    alt, className, height, src, width, ...props
}) => {
    const [isInViewport, targetRef] = useIsInViewport();
    const [image, setImage] = useState<string | undefined>();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const hasSize = height !== undefined && width !== undefined;

    useEffect(() => {
        if (src && isInViewport && !isLoaded) {
            const loadingTimeout = window.setTimeout(() => {
                setIsLoading(true);
            }, 100);
            const img = new Image();
            const onLoad = async () => {
                if (img.decode !== undefined) {
                    try {
                        await img.decode();
                    } catch (e) {
                        setHasError(true);
                    }
                }
                setImage(img.src);
                setIsLoading(false);
                setIsLoaded(true);
                window.clearTimeout(loadingTimeout);
            };

            img.onload = onLoad;
            img.onerror = () => {
                setHasError(true);
                setIsLoading(false);
                window.clearTimeout(loadingTimeout);
            };
            img.src = src;

            return () => {
                img.removeEventListener('load', onLoad);
                window.clearTimeout(loadingTimeout);
            };
        }

        return () => undefined;
    }, [src, isInViewport, isLoaded, setIsLoaded]);

    return (
        <div
            {...props}
            className={classNames(
                'img-wrapper',
                image && 'img-loaded',
                isLoading && 'img-loading',
                hasError && 'img-error',
                !hasSize && 'img-fill',
                className,
            )}
            ref={targetRef}
            style={hasSize ? {
                height: height || 'auto',
                width: width || 'auto',
            } : undefined}
        >
            {hasError ? (
                !isLoading && <ApiOutlined />
            ) : (
                <img src={image} alt={alt} />
            )}
        </div>
    );
};

export default Img;
