import { Card, Button, Typography, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SelectProps } from 'antd/lib/select';

import '../../assets/styles/RecommendedContacts.less';

import { Appointment, Contact } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { getContactsListState, list, ContactsState } from '../../store/actions/contacts';
import { update, getAppointmentsUpdateState, AppointmentsState } from '../../store/actions/appointments';

import ContactCreateMessages from '../contactCreate/ContactCreateMessages';
import genericMessages from '../../locale/genericMessages';
import BasicList from '../../components/BasicList';
import Select from '../../components/Select';
import { IconTrash, IconContacts } from '../../components/icons';
import { isAppointmentCancelled } from '../../helpers/data';
import { getFullName, stripUndefinedKeysFromObject } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';

const messages = defineMessages({
    title: {
        id: 'recommended_contacts.title',
        defaultMessage: 'Contact(s) recommandé(s)',
        description: 'Recommended contacts title',
    },
    createContact: {
        id: 'recommended_contacts.create',
        defaultMessage: 'Créer un nouveau contact',
        description: 'Recommended contacts create contact button',
    },
});

interface RecommendedContactsProps {
    appointment?: Appointment;
    onClickCreate: () => void;
    listContact: typeof list.trigger;
    listContactState: ContactsState['list'];
    appointmentUpdateState: AppointmentsState['update'];
    updateAppointment: typeof update.trigger;
}

const RecommendedContacts: FC<RecommendedContactsProps> = ({
    appointment, appointmentUpdateState, onClickCreate, listContact, listContactState, updateAppointment,
}) => {
    const [selectedContact, setSelectedContact] = useState();
    const [hasUpdatedAfterCreate, setHasUpdatedAfterCreate] = useState(false);
    const location = useLocation<{ from?: string; contactId?: string } | undefined>();
    const from = location.state?.from;
    const { formatMessage } = useIntl();
    const onChangeContact = (value: any) => {
        if (value) {
            updateAppointment({
                id: appointment?.id,
                appointment: {
                    recommendedContacts: [
                        ...appointment?.recommendedContacts?.map((contact) => contact.id),
                        value,
                    ],
                },
            });
            setSelectedContact(undefined);
        } else {
            setSelectedContact(value);
        }
    };
    const onClickDeleteContact = (contactId: Contact['id']) => {
        updateAppointment({
            id: appointment?.id,
            appointment: {
                recommendedContacts: appointment?.recommendedContacts?.
                    filter((contact) => contact.id !== contactId).
                    map((contact) => contact.id),
            },
        });
    };
    const onContactSearch: SelectProps<Contact['id']>['onSearch'] = (search) => {
        listContact(stripUndefinedKeysFromObject({ limit: 20, search: search || undefined, throttling: 300 }));
    };

    useEffect(() => {
        listContact({ limit: 20 });
    }, [listContact]);

    useEffect(() => {
        if (appointment?.id && from === getRoute(RoutePathName.contactCreate) && !hasUpdatedAfterCreate && location.state?.contactId) {
            updateAppointment({
                id: appointment?.id,
                appointment: {
                    recommendedContacts: [
                        ...appointment?.recommendedContacts?.
                            filter((contact) => contact.id !== location.state?.contactId).
                            map((contact) => contact.id),
                        location.state.contactId,
                    ],
                },
            });
            setHasUpdatedAfterCreate(true);
        }
    }, [appointment, from, updateAppointment, hasUpdatedAfterCreate, location.state]);

    return (
        <Spin spinning={appointmentUpdateState.loading}>
            <Card className="networking-action-details-block-card recommended-contacts">
                <Typography.Title level={2}>
                    <IconContacts /> <FormattedMessage {...messages.title} />
                </Typography.Title>
                {!!appointment?.recommendedContacts?.length && (
                    <BasicList>
                        {appointment?.recommendedContacts.map((contact) => (
                            <li key={contact.id} className="flex flex-between">
                                <span>
                                    {getFullName(contact.firstName, contact.lastName)}
                                    {contact.company?.name ? ` - ${contact.company.name}` : null}
                                </span>
                                {!isAppointmentCancelled(appointment) && (
                                    <Button
                                        type="link"
                                        onClick={onClickDeleteContact.bind(null, contact.id)}
                                    >
                                        <IconTrash />
                                    </Button>
                                )}
                            </li>
                        ))}
                    </BasicList>
                )}
                {!isAppointmentCancelled(appointment) && (
                    <div>
                        <Button
                            onClick={onClickCreate}
                            type="primary"
                        >
                            <FormattedMessage {...messages.createContact} />
                        </Button>
                        <FormattedMessage {...genericMessages.or} tagName="p" />
                        <Select
                            filterOption={false}
                            loading={listContactState.loading}
                            onSearch={onContactSearch}
                            onChange={onChangeContact}
                            placeholder={formatMessage(ContactCreateMessages.referrerPlaceholder)}
                            size="small"
                            value={selectedContact}
                            showSearch
                            showArrow
                        >
                            {listContactState.data?.items.map((item) => (
                                <Select.Option value={item.id} key={item.id}>
                                    {getFullName(item.firstName, item.lastName)}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            </Card>
        </Spin>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listContactState: getContactsListState(state),
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listContact: list.trigger,
        updateAppointment: update.trigger,
    },
)(RecommendedContacts);
