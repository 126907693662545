import { serialize } from 'object-to-formdata';

import api from './_client';
import { SearchPaginationQuery } from '.';
import { JobOffer, InterviewStatus } from './apiTypes';
import { RcFile } from 'antd/lib/upload';

export type JobOffersListPayload = SearchPaginationQuery & { status?: InterviewStatus | InterviewStatus[] };
export type CreateJobOfferPayload = Partial<JobOffer> & {
    documents: Array<{
        url?: string;
        description?: string;
        file?: RcFile,
    }>;
};
export type DeleteJobOfferPayload = Pick<JobOffer, 'id'>;
export type JobOfferDetailsPayload = Pick<JobOffer, 'id'>;
export interface JobOfferUpdatePayload {
    id: JobOffer['id'];
    jobOffer: JobOffer & {
        documents: Array<{
            url?: string;
            description?: string;
            file?: RcFile,
        }>;
    };
}

export const list = (payload: JobOffersListPayload) => api.get('/me/jobOffers', { params: payload });

export const create = (payload: CreateJobOfferPayload) => {
    const formData = serialize(payload, { indices: true });
    if (payload.documents?.[0].file) {
        formData.delete('documents[0][file]');
        formData.append('documents[0]', payload.documents?.[0].file, payload.documents?.[0].file.name);
    }

    return api.post('/me/jobOffers', formData);
};

export const del = (payload: DeleteJobOfferPayload) => api.delete(`/me/jobOffers/${payload.id}`);

export const details = (payload: JobOfferDetailsPayload) => api.get(`/me/jobOffers/${payload.id}`);

export const update = (payload: JobOfferUpdatePayload) => {
    const formData = serialize(payload.jobOffer, { indices: true });
    if (payload.jobOffer.documents?.[0].file) {
        formData.delete('documents[0][file]');
        formData.append('documents[0]', payload.jobOffer.documents?.[0].file, payload.jobOffer.documents?.[0].file.name);
    }

    return api.put(`/me/jobOffers/${payload.id}`, formData);
};
