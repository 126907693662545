import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'networking_action_details.title',
        defaultMessage: 'Actions de networking',
        description: 'Networking action details page title',
    },
    backToList: {
        id: 'networking_action_details.back_to_list',
        defaultMessage: 'Retour aux actions',
        description: 'Networking action details page back to actions list link',
    },
    deleteTitle: {
        id: 'networking_action_details.delete_modal.title',
        defaultMessage: 'Êtes-vous sur de vouloir supprimer cette action ?',
        description: 'Networking action details page delete modal title',
    },
    deleteSuccess: {
        id: 'networking_action_details.delete_success',
        defaultMessage: 'L\'action a été supprimée avec succès',
        description: 'Networking action details page delete success',
    },
    updateSuccess: {
        id: 'networking_action_details.update_success',
        defaultMessage: 'L\'action a été mise à jour avec succès',
        description: 'Networking action details page update success',
    },
    date: {
        id: 'networking_action_details.infos.date',
        defaultMessage: 'Date',
        description: 'Networking action details infos card date',
    },
    type: {
        id: 'networking_action_details.infos.type',
        defaultMessage: 'Type',
        description: 'Networking action details infos card type',
    },
    validated: {
        id: 'networking_action_details.complete.validated',
        defaultMessage: 'Action validée',
        description: 'Networking action details header validated title',
    },
    cancelled: {
        id: 'networking_action_details.complete.cancelled',
        defaultMessage: 'Action annulée',
        description: 'Networking action details header cancelled title',
    },
    interviewRejected: {
        id: 'networking_action_details.interview.reject_button',
        defaultMessage: 'Candidature refusée',
        description: 'Networking action details header refuse interview button',
    },
    interviewAccepted: {
        id: 'networking_action_details.interview.accept_button',
        defaultMessage: 'Candidature acceptée',
        description: 'Networking action details header accept interview button',
    },
    createInterview: {
        id: 'networking_action_details.create_interview',
        defaultMessage: 'Nouvel entretien prévu',
        description: 'Networking action details create interview button',
    },
    updateSubmit: {
        id: 'networking_action_details.edit.update_submit',
        defaultMessage: 'Modifier l\'action',
        description: 'Networking action details edit form submit',
    },
});
