import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { contactsSaga } from '../actions/contacts';
import { usersSaga } from '../actions/users';
import { userGroupsSaga } from '../actions/userGroups';
import { appointmentsSaga } from '../actions/appointments';
import { packsSaga } from '../actions/packs';
import { programsSaga } from '../actions/programs';
import { librarySaga } from '../actions/library';
import { staticPagesSaga } from '../actions/staticPages';
import { tasksSaga } from '../actions/tasks';
import { jobOffersSaga } from '../actions/jobOffers';
import { statsSaga } from '../actions/stats';
import { logsSaga } from '../actions/logs';
import { notificationsSaga } from '../actions/notifications';
import { reportingSaga } from '../actions/reporting';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(contactsSaga);
    yield fork(usersSaga);
    yield fork(userGroupsSaga);
    yield fork(appointmentsSaga);
    yield fork(packsSaga);
    yield fork(programsSaga);
    yield fork(librarySaga);
    yield fork(staticPagesSaga);
    yield fork(tasksSaga);
    yield fork(jobOffersSaga);
    yield fork(statsSaga);
    yield fork(logsSaga);
    yield fork(notificationsSaga);
    yield fork(reportingSaga);
}
