import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Divider, Descriptions, Space } from 'antd';

import {
    Contact, addressTypeMessages, civilityMessages, contactTypeMessages,
} from '../store/api/apiTypes';

import ContactCreateMessages from '../pages/contactCreate/ContactCreateMessages';
import ContactDetailsMessages from '../pages/contactDetails/ContactDetailsMessages';
import AccountMessages from '../pages/account/AccountMessages';
import genericMessages from '../locale/genericMessages';
import { getFullName, formatAddress, formatTextData } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import { IconReferrer, IconReferred, IconArrowRight, IconPlus, IconPhone, IconMail } from './icons';
import DescriptionHeader from './DescriptionHeader';
import ButtonLink from './ButtonLink';

interface ContactDescriptionsProps {
    contact?: Contact;
}

const ContactDescriptions: FC<ContactDescriptionsProps> = ({ contact }) => {
    const history = useHistory();
    const onClickCreateActionFromContact = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), {
            values: { contact: contact?.id },
            previous: getRoute(RoutePathName.contact, { contactId: contact?.id || '' }),
        });
    };

    return (
        <div>
            <DescriptionHeader
                heading={(
                    <>
                        {getFullName(contact?.firstName, contact?.lastName)}
                        {!!contact?.referred?.length && <IconReferrer />}
                        {contact?.referrer && <IconReferred />}
                    </>
                )}
                headerRight={(
                    <ButtonLink
                        to={getRoute(RoutePathName.contactEdit, { contactId: contact?.id || '' })}
                        ghost
                    >
                        <FormattedMessage {...genericMessages.edit} />
                    </ButtonLink>
                )}
            >
                <Row className="description-infos">
                    <Col xs={24} lg={12}>
                        {contact?.email && <a href={`mailto:${contact?.email}`}><IconMail />{contact?.email}</a>}
                    </Col>
                    <Col xs={24} lg={12}>
                        {(contact?.phone || contact?.mobilePhone) && (
                            <span>
                                <IconPhone />
                                <Space size={16}>
                                    {contact?.phone && (
                                        <a href={`tel:${contact?.phone}`}>
                                            {contact?.phone.replace(/(.{2})/g, '$1 ')}
                                        </a>
                                    )}
                                    {contact?.phone && contact?.mobilePhone && '/'}
                                    {contact?.mobilePhone && (
                                        <a href={`tel:${contact?.mobilePhone}`}>
                                            {contact?.mobilePhone.replace(/(.{2})/g, '$1 ')}
                                        </a>
                                    )}
                                </Space>
                            </span>
                        )}
                    </Col>
                </Row>
                <Button
                    onClick={onClickCreateActionFromContact}
                    type="primary"
                >
                    <IconPlus /> <FormattedMessage {...ContactCreateMessages.successModalCreateActionLink} />
                </Button>
            </DescriptionHeader>
            <Descriptions column={1} bordered>
                <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoTitle} />}>
                    {contact?.civility &&
                        <FormattedMessage {...civilityMessages.get(contact?.civility)} />
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...ContactCreateMessages.typeLabel} />}>
                    {contact?.type &&
                        <FormattedMessage {...contactTypeMessages.get(contact?.type)} />
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCompany} />}>
                    <FormattedMessage {...AccountMessages.infoCompanyFirm} tagName="label" /><br />
                    {formatTextData(contact?.company?.name)}
                    <Divider />
                    <FormattedMessage {...ContactCreateMessages.jobOccupationLabel} tagName="label" /><br />
                    {formatTextData(contact?.job?.occupation)}
                    <Divider />
                    <FormattedMessage {...ContactCreateMessages.serviceLabel} tagName="label" /><br />
                    {formatTextData(contact?.company?.service)}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...ContactCreateMessages.addressTitle} />}>
                    {contact?.addresses?.length ?
                        contact.addresses.map((address, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Divider />}
                                {address.type && (
                                    <>
                                        <FormattedMessage {...addressTypeMessages.get(address.type)} tagName="label" />
                                        <br />
                                    </>
                                )}
                                {formatAddress(address)}
                            </Fragment>
                        )) : (
                            '——'
                        )
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...ContactCreateMessages.referrerLabel} />}>
                    {contact?.referrer ? (
                        <Link to={getRoute(RoutePathName.contact, { contactId: contact.referrer.id || '' })}>
                            <div className="flex flex-between flex-align-center">
                                <p className="descriptions-link">
                                    <strong>
                                        {getFullName(contact.referrer.firstName, contact.referrer.lastName)}
                                    </strong><br />
                                    <span>
                                        {contact.referrer.job?.occupation} - {contact.referrer.company?.name}
                                    </span>
                                </p>
                                <IconArrowRight />
                            </div>
                        </Link>
                    ) : '——'}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...ContactDetailsMessages.referred} />}>
                    {contact?.referred?.length ?
                        contact?.referred?.map((referred, index) => (
                            <Fragment key={referred.id}>
                                {index > 0 && <Divider />}
                                <Link to={getRoute(RoutePathName.contact, { contactId: referred?.id || '' })}>
                                    <div className="flex flex-between flex-align-center">
                                        <p className="descriptions-link">
                                            <strong>
                                                {getFullName(referred?.firstName, referred?.lastName)}
                                            </strong><br />
                                            <span>
                                                {referred?.job?.occupation} - {referred?.company?.name}
                                            </span>
                                        </p>
                                        <IconArrowRight />
                                    </div>
                                </Link>
                            </Fragment>
                        )) : (
                            '——'
                        )
                    }
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage {...ContactCreateMessages.commentLabel} />}>
                    {formatTextData(contact?.comment)}
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};

export default ContactDescriptions;
