import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M14.758 0h-2.393v2.257c0 .46-.344.837-.768.837H4.403c-.424 0-.768-.376-.768-.837V0H1.24C.558 0 0 .5 0 1.112v13.776C0 15.5.557 16 1.24 16H14.76c.685 0 1.242-.5 1.242-1.112V1.112C16 .5 15.443 0 14.758 0zM12.91 12.5H3.086c-.515 0-.93-.335-.93-.75 0-.414.415-.75.93-.75h9.828c.513 0 .93.336.93.75 0 .415-.417.75-.932.75zm.002-6c.514 0 .93.336.93.75 0 .415-.416.75-.93.75H3.084c-.515 0-.93-.335-.93-.75 0-.414.415-.75.93-.75h9.827zM11.576 0v2.233H4.424V0h7.152z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconProgram: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-program${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconProgram;
