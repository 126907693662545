import React, { FC } from 'react';
import { Typography, Tag } from 'antd';
import { LibraryDocument, taskTypeMessages, documentTypeMessages, documentTypeViewMoreMessages } from '../store/api/apiTypes';
import { taskTypeIcons, documentTypeIcons, IconArrowRight } from './icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { RoutePathName, getRoute } from '../routes';

import '../assets/styles/DocumentListCard.less';

interface DocumentListCardProps {
    document: LibraryDocument;
}

const DocumentListCard: FC<DocumentListCardProps> = ({ document }) => {
    const DocumentFamilyIcon = taskTypeIcons.get(document.family);
    const DocumentTypeIcon = documentTypeIcons.get(document.type);

    return (
        <div className="document-list-card">
            <header>
                {document.family && (
                    <Tag icon={DocumentFamilyIcon && <DocumentFamilyIcon />} className="tag-primary">
                        <FormattedMessage {...taskTypeMessages.get(document.family)} />
                    </Tag>
                )}
                {document.type && (
                    <Tag icon={DocumentTypeIcon && <DocumentTypeIcon />}>
                        <FormattedMessage {...documentTypeMessages.get(document.type)} />
                    </Tag>
                )}
            </header>
            <Typography.Title level={2} className="document-card-title">
                <Link to={getRoute(RoutePathName.libraryDocument, { documentId: document.id })}>
                    {document.name}
                </Link>
            </Typography.Title>
            {document.description && (
                <Typography.Paragraph ellipsis={{ rows: 3 }} className="document-card-description">
                    {document.description}
                </Typography.Paragraph>
            )}
            <footer>
                <Link
                    className="text-primary"
                    to={getRoute(RoutePathName.libraryDocument, {documentId: document.id})}
                >
                    <FormattedMessage {...documentTypeViewMoreMessages.get(document.type)} /> <IconArrowRight />
                </Link>
            </footer>
        </div>
    );
};

export default DocumentListCard;
