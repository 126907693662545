import React, { DetailedHTMLProps, HTMLAttributes, FC, ReactNode } from 'react';
import { Typography } from 'antd';

import '../assets/styles/DescriptionHeader.less';

import { classNames } from '../helpers';
import { TitleProps } from 'antd/lib/typography/Title';

interface DescriptionHeaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    heading: string | number | ReactNode;
    headingLevel?: TitleProps['level'];
    headerRight?: ReactNode;
}

const DescriptionHeader: FC<DescriptionHeaderProps> = ({
    children, className, heading, headingLevel = 1, headerRight,
}) => (
    <header
        className={classNames(
            'description-header',
            className,
        )}
    >
        <div className="heading-wrapper flex flex-between">
            <Typography.Title level={headingLevel}>
                {heading}
            </Typography.Title>
            {headerRight}
        </div>
        {children}
    </header>
);

export default DescriptionHeader;
