import { defineMessages } from 'react-intl';

export default defineMessages({
    createTitle: {
        id: 'client.create.group.title',
        defaultMessage: 'Créer un groupe',
        description: 'Create group title',
    },
    editTitle: {
        id: 'client.edit.group.title',
        defaultMessage: 'Éditer un groupe',
        description: 'Edit group title',
    },
    nameLabel: {
        id: 'client.group.form.name.label',
        defaultMessage: 'Nom du groupe',
        description: 'Group name',
    },
    namePlaceholder: {
        id: 'client.group.form.label.name',
        defaultMessage: 'Saisir un nom pour le groupe',
        description: 'Group name placeholder',
    },
    descriptionLabel: {
        id: 'client.group.form.description.label',
        defaultMessage: 'Description',
        description: 'Group description',
    },
    descriptionPlaceholder: {
        id: 'client.group.formr.description.placeholde',
        defaultMessage: 'Saisir une description',
        description: 'Group description placeholder',
    },
    packsLabel: {
        id: 'client.group.form.packs.label',
        defaultMessage: 'Packs de rattachement',
        description: 'Group packs',
    },
    packsPlaceholder: {
        id: 'client.group.form.packs.placeholder',
        defaultMessage: 'Sélectionner des packs',
        description: 'Group packs placeholder',
    },
});
