import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    my as myApiCall,
} from '../api/stats';
import { RequestState, MainReducerState } from '../reducers';
import { UserStats } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface StatsState {
    my: RequestState<UserStats>;
}

const initialState: StatsState = {
    my: {
        loading: false,
    },
};

// Actions/Reducers

export const my = new EzeeAsyncAction<
    StatsState['my'],
    any,
    UserStats
>('stats/my', initialState.my, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.my,
    }),
});

// Reducer

export const statsReducer = combineReducers<StatsState>({
    my: my.reducer,
});

// Saga

export function* statsSaga() {
    yield takeLatest(my.type.trigger, simpleAsyncSaga(myApiCall, my));
}

// Store helpers

export const getUserStatsState = (state: MainReducerState) => state.stats.my;
