import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Layout, Typography } from 'antd';

import '../assets/styles/Footer.less';

import genericMessages from '../locale/genericMessages';
import { getRoute, RoutePathName } from '../routes';
import { useIsMobile } from '../hooks';
import BasicList from './BasicList';
import constants from '../config/constants';

const Footer: FC = () => {
    const isMobile = useIsMobile();

    return (
        <Layout.Footer id="main-footer">
            <div className="container">
                <div>
                    <p>
                        &copy; Mon coach mobilité {(new Date()).getFullYear()}
                        <Typography.Text type="secondary"> v{constants.VERSION}</Typography.Text>
                    </p>
                    <nav>
                        <BasicList inline={!isMobile}>
                            <li>
                                <Link to={getRoute(RoutePathName.faq)}>
                                    <FormattedMessage {...genericMessages.faq} />
                                </Link>
                            </li>
                            <li>
                                <Link to={getRoute(RoutePathName.terms)}>
                                    <FormattedMessage {...genericMessages.termsOfUse} />
                                </Link>
                            </li>
                            <li>
                                <Link to={getRoute(RoutePathName.privacyPolicy)}>
                                    <FormattedMessage {...genericMessages.privacyPolicy} />
                                </Link>
                            </li>
                        </BasicList>
                    </nav>
                </div>
            </div>
        </Layout.Footer>
    );
};

export default Footer;
