import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="nonzero" d="M8.853 3c.63 0 1.137.532 1.137 1.191v7.639c0 .638-.508 1.17-1.137 1.17H1.137C.507 13 0 12.468 0 11.809V4.19C0 3.531.508 3 1.137 3h7.716zM16 4.277v7.446a.199.199 0 01-.264.192l-4.65-1.83c-.06-.042-.101-.106-.101-.17v-3.83c0-.064.04-.128.101-.17l4.65-1.83a.199.199 0 01.264.192z" />
    </svg>
);

const IconCamera: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-camera${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCamera;
