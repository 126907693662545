import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography, Alert } from 'antd';
import { connect } from 'react-redux';
import { ModalProps } from 'antd/lib/modal';

import { MainReducerState } from '../store/reducers';
import { getUser } from '../store/actions/auth';

import AccountMessages from '../pages/account/AccountMessages';
import { ui } from '../store/actions/ui';
import { User } from '../store/api/apiTypes';
import { getActiveSuspension } from '../helpers/data';
import { DateTime } from 'luxon';

interface SuspensionAlertProps extends ModalProps {
    user?: User;
    setUi: typeof ui.actions.set;
    margin?: boolean;
}

const SuspensionAlert: FC<SuspensionAlertProps> = ({
    user, setUi, margin,
}) => {
    const suspension = getActiveSuspension(user);
    const doesSuspensionEndNextWeek = suspension?.toDate ?
        DateTime.fromISO(suspension.toDate, { zone: 'UTC' }).weekNumber === DateTime.utc().weekNumber :
        false;

    const onClickReactivate = () => {
        setUi({ isSuspensionReactivateModalVisible: true });
    };

    return !!suspension ? (
        <Alert
            message={(
                <div className="flex flex-between">
                    <Typography.Text className="text-primary text-md" strong>
                        {doesSuspensionEndNextWeek ?
                            <FormattedMessage {...AccountMessages.suspensionReactivateFormModalTitleSuccess} /> : (
                                <FormattedMessage
                                    {...AccountMessages.suspensionActiveAlert}
                                    values={{ date: new Date(suspension.toDate ?? '') }}
                                />
                            )
                        }
                    </Typography.Text>
                    {!doesSuspensionEndNextWeek && (
                        <Button
                            type="primary"
                            size="large"
                            onClick={onClickReactivate}
                        >
                            <FormattedMessage {...AccountMessages.suspensionReactivateCta} />
                        </Button>
                    )}
                </div>
            )}
            style={margin ? { marginBottom: '1.5rem' } : undefined}
        />
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        setUi: ui.actions.set,
    },
)(SuspensionAlert);
