import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 16">
        <path fill="currentColor" d="M6.707 3.692v.387c1.04-.473 2.465-.387 3.48.086C11.275 4.7 12 5.86 12 6.934V12H9.341V6.934c0-.236-.241-.623-.459-.687-1.063-.537-2.175.43-2.175.43V12H4V3.692h2.707zM3 3.692V12H0V3.692h3zM1.49 0C2.32 0 3 .638 3 1.394c0 .758-.679 1.375-1.51 1.375C.656 2.77 0 2.152 0 1.394 0 .638.657 0 1.49 0z" />
    </svg>
);

const IconLinkedin: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-linkedin${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconLinkedin;
