import React, { FC, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, RouteComponentProps, useParams, Link, Route } from 'react-router-dom';
import { Space, Button, Modal, message } from 'antd';

import { MainReducerState } from '../../store/reducers';
import {
    AppointmentsState, AppointmentDetailsState, details, del, getAppointmentStateById,
    getAppointmentsDeleteState,
    getAppointmentsUpdateState,
} from '../../store/actions/appointments';
import { Appointment, NetworkingActionType } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import genericMessages from '../../locale/genericMessages';
import { IconPlus, IconTrash, IconArrowLeft } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import NetworkingActionsMessages from './NetworkingActionDetailsMessages';
import { usePrevious } from '../../hooks';
import NetworkingActionDetailsCard from './NetworkingActionDetailsCard';
import CardSkeleton from '../../components/CardSkeleton';
import RecommendedContacts from './RecommendedContacts';
import NetworkingActionComment from './NetworkingActionComment';
import NetworkingActionNotes from './NetworkingActionNotes';
import JobInterviews from './JobInterviews';
import { isAppointmentCancelled } from '../../helpers/data';
import NetworkingActionEditForm from './NetworkingActionEditForm';

interface MatchParams {
    networkingActionId: Appointment['id'];
}

interface NetworkingActionDetailsProps extends RouteComponentProps<MatchParams> {
    appointmentDetailsState: AppointmentDetailsState;
    appointmentDelState: AppointmentsState['del'];
    appointmentUpdateState: AppointmentsState['update'];
    deleteAppointment: typeof del.trigger;
    fetchAppointment: typeof details.trigger;
}

const NetworkingActionDetails: FC<NetworkingActionDetailsProps> = ({
    appointmentDetailsState, appointmentDelState, appointmentUpdateState, deleteAppointment,
    fetchAppointment,
}) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { networkingActionId } = useParams<MatchParams>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const previous = usePrevious({ appointmentDelState, appointmentUpdateState });
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.networkingActions),
            },
        });
    };
    const onClickCreateContact = () => {
        history.push({
            pathname: getRoute(RoutePathName.contactCreate),
            state: {
                previous: getRoute(RoutePathName.networkingAction, { networkingActionId }),
                referrer: appointmentDetailsState.data?.contact,
                values: {
                    referrer: appointmentDetailsState.data?.contact?.id,
                },
            },
        });
    };
    const onClickDelete = () => {
        setIsDeleteModalVisible(true);
        Modal.confirm({
            title: formatMessage(NetworkingActionsMessages.deleteTitle),
            cancelText: formatMessage(genericMessages.cancel),
            okText: formatMessage(genericMessages.delete),
            onOk: () => {
                deleteAppointment({ id: networkingActionId });
                setIsDeleteModalVisible(false);
            },
            icon: null,
            cancelButtonProps: {
                ghost: true,
            },
            okButtonProps: {
                danger: true,
                ghost: true,
            },
            visible: isDeleteModalVisible,
        });
    };

    useEffect(() => {
        fetchAppointment({ id: networkingActionId });
    }, [fetchAppointment, networkingActionId]);

    useEffect(() => {
        if (previous?.appointmentDelState.loading && !appointmentDelState.loading) {
            if (appointmentDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(NetworkingActionsMessages.deleteSuccess));
                history.push(getRoute(RoutePathName.networkingActions));
            }
        }
    }, [
        previous, appointmentDelState.loading, appointmentDelState.error, formatMessage, history,
    ]);

    useEffect(() => {
        if (previous?.appointmentUpdateState.loading && !appointmentUpdateState.loading) {
            if (appointmentUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                fetchAppointment({ id: networkingActionId });
            }
        }
    }, [
        previous, appointmentUpdateState.loading, appointmentUpdateState.error, formatMessage, history,
        networkingActionId, fetchAppointment,
    ]);

    return (
        <section id="networking-action">
            <Seo title={formatMessage(NetworkingActionsMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <Space direction="vertical" size={24} style={{ width: '100%' }} className="block">
                <div className="flex flex-between">
                    <Link
                        to={getRoute(RoutePathName.networkingActions)}
                        className="back-link"
                        type="link"
                    >
                        <IconArrowLeft />
                        <FormattedMessage {...NetworkingActionsMessages.backToList} />
                    </Link>
                    <Button
                        onClick={onClickDelete}
                        loading={appointmentDelState.loading}
                        icon={<IconTrash />}
                        type="primary"
                        shape="circle"
                        size="small"
                        danger
                    />
                </div>
                <Route path={getRoute(RoutePathName.networkingAction, { networkingActionId })} exact>
                    {appointmentDetailsState.loading && <CardSkeleton rows={12} />}
                    {!appointmentDetailsState.loading && appointmentDetailsState.data && (
                        <NetworkingActionDetailsCard appointment={appointmentDetailsState.data} />
                    )}
                </Route>
                <Route path={getRoute(RoutePathName.networkingActionEdit, { networkingActionId })} exact>
                    <NetworkingActionEditForm appointment={appointmentDetailsState.data} />
                </Route>
                {appointmentDetailsState.data?.actionType !== NetworkingActionType.jobInterview && (
                    <RecommendedContacts
                        appointment={appointmentDetailsState.data}
                        onClickCreate={onClickCreateContact}
                    />
                )}
                {!isAppointmentCancelled(appointmentDetailsState.data) && (
                    <NetworkingActionComment
                        appointment={appointmentDetailsState.data}
                    />
                )}
                {appointmentDetailsState.data?.actionType === NetworkingActionType.jobInterview && (
                    <JobInterviews
                        appointment={appointmentDetailsState.data}
                    />
                )}
                <NetworkingActionNotes
                    appointment={appointmentDetailsState.data}
                />
            </Space>
        </section>
    );
};

const mapStateToProps = (
    state: MainReducerState,
    { match: { params: { networkingActionId } } }: NetworkingActionDetailsProps,
) => ({
    appointmentDetailsState: getAppointmentStateById(state, networkingActionId),
    appointmentDelState: getAppointmentsDeleteState(state),
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        fetchAppointment: details.trigger,
        deleteAppointment: del.trigger,
    },
)(NetworkingActionDetails);
