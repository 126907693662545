import jwt_decode from 'jwt-decode';
import dayjs, { Dayjs } from 'dayjs';
import Axios from 'axios';
import { RcFile } from 'antd/es/upload/interface';

import { Address, LibraryDocumentType } from '../store/api/apiTypes';

export { default as constants } from '../config/constants';

interface LoggerType {
    isAllowed: boolean;
    log(messages?: any, ...optionalParams: any[]): void;
    warn(messages?: any, ...optionalParams: any[]): void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }

    public count(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.count(messages);
        }
    }
}

export const debug = new Logger();

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const formatAddress = (address?: Address) =>
    [
        (address?.street || '').trim(),
        (address?.zipCode || '').trim(),
        (address?.street || address?.zipCode) && address?.city && ',',
        (address?.city || '').trim(),
    ].filter(Boolean).join(' ');

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProp = (obj: object, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict ?
                !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
                value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export const disableBeforeTodayIncluded = (current: Dayjs) => {
    return current && current < dayjs().endOf('day');
};

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return ((decoded.exp * 1000) - Date.now()) < 0;
    } catch (error) {
        return true;
    }
};

export const stripUndefinedKeysFromObject = (object: {[key: string]: any}) =>
    Object.keys(object).reduce<{[key: string]: any}>((acc, key) => {
        if (object[key] !== undefined) {
            acc[key] = object[key];
        }
        return acc;
    }, {});

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...arguments].filter(Boolean).join(' ');
}

export const scrollToElement = (element: HTMLElement | null, offset?: number) => {
    if (element) {
        const elementTop = element.getBoundingClientRect().top +
            (window.pageYOffset || document.documentElement.scrollTop);

        window.scrollTo({
            top: elementTop - (offset || 0),
            behavior: 'smooth',
        });
    }
};

export const formatTextData = (data?: any) => data || '——';

export const downloadFile = (url: string, filename?: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename || 'download');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export const downloadAs = (url: string, filename: string) => {
    Axios.get(url, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    })
        .then((response) => {
            const localURL = window.URL.createObjectURL(response.data);
            downloadFile(localURL, filename);
        })
        .catch((err) => {
            console.log('error', err);
        });
};

export const getDocumentTypeFromMimeType = (type?: RcFile['type']) => {
    if (type?.toLowerCase().includes('audio')) {
        return LibraryDocumentType.podcast;
    } else if (type?.toLowerCase().includes('video')) {
        return LibraryDocumentType.video;
    } else {
        return LibraryDocumentType.file;
    }
};
