import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="nonzero" d="M2.31 20.223a.626.626 0 01.942 0l7.527 8.221c.26.285.26.745 0 1.037l-2.11 2.307a.672.672 0 01-.474.212.66.66 0 01-.475-.212L.2 23.56a.765.765 0 01-.2-.519c0-.19.073-.38.2-.518zm25.116-7.053c.647-.563 1.462-.804 2.257-.672A2.66 2.66 0 0131.5 13.71c.394.606.56 1.35.48 2.095v.015a3.1 3.1 0 01-1.035 1.993l-9.243 8.623a3.55 3.55 0 01-2.317.884c-.147 0-.294-.008-.448-.03l-7.42-.97-.514.372-5.977-6.528 3.773-3.212c1.129-.971 2.551-1.41 4-1.227l6.125.803a2.52 2.52 0 011.042.38c.848.526 1.222 1.745.694 2.65l-.013.015c-.287.49-.828.81-1.436.876-.027 0-.06.007-.074.007h-.073l-5.122-.116c-.388.014-.675.314-.682.715-.006.402.287.738.655.745l5.102.117h.067c.053 0 .134-.008.247-.015a3.21 3.21 0 002.438-1.533c.327-.548.594-1.322.527-2.337zM30.58.073a.628.628 0 01.601.007c.187.11.314.307.348.54.48 3.454-.02 5.908-1.49 7.288-.995.941-2.237 1.182-3.272 1.182-.695 0-1.29-.11-1.657-.19a2.47 2.47 0 00-.207.643c-.013.745-.233 5.09-4.154 6.834a.611.611 0 01-.247.059.677.677 0 01-.62-.453c-.141-.38.026-.803.366-.956 2.802-1.247 3.24-4.148 3.307-5.169-.387-.705-.69-.995-.849-1.11-.534.372-1.79 1.153-3.326 1.518-1.189.285-2.544.321-3.867-.27C12.984 8.864 11.3 5.79 10.512.854a.76.76 0 01.174-.628.639.639 0 01.567-.219c.02 0 2.077.285 4.702.161 2.865-.131 5.483 1.424 6.832 4.067.007.007.007.015.007.022l.013.014c.007.015.04.088.054.103.46.949.634 2.037.5 3.11.195.123.415.314.65.592.028-.05.056-.104.085-.147a4.046 4.046 0 01-.253-2.124l.013-.11c.367-2.11 1.803-3.804 3.753-4.417A20.228 20.228 0 0030.581.073z"/>
    </svg>
);

const IconNaturalist: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-naturalist${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconNaturalist;
