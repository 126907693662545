import React, { FC, useRef, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationProps } from 'antd/lib/pagination';
import { message, Empty } from 'antd';

import { MainReducerState } from '../../store/reducers';
import {
    getJobOffersListState, list, listCount, JobOffersState, getJobOffersDeleteState, getJobOffersListCountState,
} from '../../store/actions/jobOffers';

import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import Pagination from '../../components/Pagination';
import ListTitle from '../../components/ListTitle';
import Filters from '../../components/Filters';
import { useQueryParams, usePrevious } from '../../hooks';
import { scrollToElement } from '../../helpers';
import constants from '../../config/constants';
import JobOffersMessages from './JobOffersMessages';
import ListHeader from '../../components/ListHeader';
import genericMessages from '../../locale/genericMessages';
import { IconAddJobOffer } from '../../components/icons';
import HeaderCTA from '../../components/HeaderCTA';
import { getRoute, RoutePathName } from '../../routes';
import CardSkeleton from '../../components/CardSkeleton';
import JobOfferCard from '../../components/JobOfferCard';
import { InterviewStatus } from '../../store/api/apiTypes';

interface JobOffersProps {
    jobOffersState: JobOffersState['list'];
    jobOfferDelState: JobOffersState['del'];
    jobOffersCountState: JobOffersState['listCount'];
    listJobOffers: typeof list.trigger;
    listJobOffersCount: typeof listCount.trigger;
}

const JobOffers: FC<JobOffersProps> = ({
    jobOffersState, jobOfferDelState, listJobOffers, listJobOffersCount, jobOffersCountState,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { formatMessage, formatNumber } = useIntl();
    const previous = usePrevious({ jobOfferDelState });
    const listRef = useRef(null);
    const urlParams = useQueryParams();
    const pageParam = urlParams.get('page');
    const filterParam = urlParams.get('filter');
    const searchParam = urlParams.get('search');
    const sortParam = urlParams.get('sort');
    const currentPage = pageParam !== null ?
        (parseInt(pageParam, 10) || 1) :
        1;
    const currentFilter = filterParam || 'all';
    const currentSort = sortParam || 'createdAt';
    const onPageSelect: PaginationProps['onChange'] = (page) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('page', `${page || 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeFilter = (filter: string) => {
        scrollToElement(listRef.current, 100);
        urlParams.set('filter', `${filter}`);
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSearch = (search?: string) => {
        scrollToElement(listRef.current, 100);
        if (search) {
            urlParams.set('search', search);
        } else {
            urlParams.delete('search');
        }
        urlParams.delete('page');
        urlParams.delete('filter');
        urlParams.delete('sort');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onChangeSort = (sort?: string) => {
        scrollToElement(listRef.current, 100);
        if (sort) {
            urlParams.set('sort', sort);
        } else {
            urlParams.delete('sort');
        }
        urlParams.delete('page');

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };
    const onClickAddJobOfferCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.jobOfferCreate),
            state: {
                previous: getRoute(RoutePathName.jobOffers),
            },
        });
    };
    const filterOptions = [
        {
            value: 'all',
            message: JobOffersMessages.filtersAll,
            messageValues: { count: formatNumber(jobOffersCountState.data?.all || 0) },
        },
        {
            value: 'pending',
            message: JobOffersMessages.filtersPending,
            messageValues: { count: formatNumber(jobOffersCountState.data?.pending || 0) },
        },
        {
            value: 'refused',
            message: JobOffersMessages.filtersRefused,
            messageValues: { count: formatNumber(jobOffersCountState.data?.refused || 0) },
        },
    ];
    const sortOptions = [
        {
            value: 'createdAt',
            message: genericMessages.sortByDate,
        },
        {
            value: 'title',
            message: genericMessages.sortAlphabetically,
        },
    ];
    const fetchJobOffers = useCallback(() => {
        listJobOffers({
            page: currentPage - 1,
            pageSize: constants.PAGE_SIZE,
            ...(currentFilter !== undefined && currentFilter !== 'all' ? {
                status: currentFilter === 'pending' ?
                    [InterviewStatus.pending, InterviewStatus.accepted] :
                    InterviewStatus.refused,
            } : {}),
            ...(currentSort !== undefined ? { sort: currentSort } : {}),
            ...(searchParam !== undefined ? { search: searchParam } : {}),
        });
    }, [listJobOffers, currentPage, currentFilter, currentSort, searchParam]);

    useEffect(() => {
        fetchJobOffers();
    }, [fetchJobOffers]);

    useEffect(() => {
        if (previous?.jobOfferDelState.loading && !jobOfferDelState.loading) {
            if (jobOfferDelState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(JobOffersMessages.deleteSuccess));
                fetchJobOffers();
                listJobOffersCount();
            }
        }
    }, [
        previous, jobOfferDelState.loading, jobOfferDelState.error, fetchJobOffers, formatMessage,
        listJobOffersCount,
    ]);

    useEffect(() => {
        listJobOffersCount();
    }, [listJobOffersCount]);

    return (
        <section id="job-offers">
            <Seo title={formatMessage(JobOffersMessages.title)} />
            <HeaderCTA
                onClick={onClickAddJobOfferCTA}
                icon={<IconAddJobOffer />}
            >
                <FormattedMessage {...JobOffersMessages.addJobOfferCTA} />
            </HeaderCTA>
            <ListHeader>
                <div className="flex flex-align-center">
                    <ListTitle count={jobOffersState.data?.totalCount || 0}>
                        <FormattedMessage {...JobOffersMessages.title} />
                    </ListTitle>
                </div>
                <Filters
                    filterOptions={filterOptions}
                    sortOptions={sortOptions}
                    onChangeFilter={onChangeFilter}
                    onChangeSearch={onChangeSearch}
                    onChangeSort={onChangeSort}
                    filterValue={currentFilter}
                    searchValue={searchParam || undefined}
                    sortValue={currentSort}
                />
            </ListHeader>
            {!jobOffersState.loading && !!jobOffersState.data?.items.length && (
                <>
                    <BasicList ref={listRef} grid>
                        {jobOffersState.data?.items.map((jobOffer) => (
                            <li key={jobOffer.id}>
                                <JobOfferCard jobOffer={jobOffer} />
                            </li>
                        ))}
                    </BasicList>
                    {jobOffersState.data?.pageCount > 1 && (
                        <Pagination
                            current={currentPage}
                            onChange={onPageSelect}
                            pageSize={constants.PAGE_SIZE}
                            showSizeChanger={false}
                            total={jobOffersState.data?.totalCount}
                        />
                    )}
                </>
            )}
            {!jobOffersState.loading && !jobOffersState.data?.items.length && (
                <Empty />
            )}
            {jobOffersState.loading && (
                <BasicList ref={listRef} grid>
                    <li><CardSkeleton rows={6} /></li>
                    <li><CardSkeleton rows={6} /></li>
                    <li><CardSkeleton rows={6} /></li>
                    <li><CardSkeleton rows={6} /></li>
                </BasicList>
            )}
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    jobOffersState: getJobOffersListState(state),
    jobOfferDelState: getJobOffersDeleteState(state),
    jobOffersCountState: getJobOffersListCountState(state),
});

export default connect(
    mapStateToProps,
    {
        listJobOffers: list.trigger,
        listJobOffersCount: listCount.trigger,
    },
)(JobOffers);
