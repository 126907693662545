import React, { FC, useState, useEffect, ReactElement, useRef } from 'react';
import { Button, Checkbox, Typography } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Form, { FormProps } from 'antd/lib/form';

import '../../assets/styles/ColorsSurvey.less';

import {
    ProgramTaskSurvey, SurveyResponses, UserProgramTaskStatus,
} from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import { getRoute, RoutePathName } from '../../routes';
import ProgramTaskDetailsMessages from '../../pages/programTaskDetails/ProgramTaskDetailsMessages';
import ColorsSurveyResults from './ColorsSurveyResults';
import BasicList from '../BasicList';
import CheckboxButton from '../CheckboxButton';
import constants from '../../config/constants';

const formatInitialValues = (responses?: SurveyResponses) => {
    const responsesKeys = Object.keys(responses || {});

    if (responses && responsesKeys.length) {
        return {
            [responsesKeys[0]]:
                responses[responsesKeys[0]]
                    .map((response) =>
                        `${
                            response.label
                        }${
                            constants.SURVEY_LABEL_ANSWER_SEPARATOR
                        }${
                            response.values?.join(constants.SURVEY_LABEL_ANSWER_SEPARATOR)
                        }`,
                    ),
        };
    } else {
        return undefined;
    }
};

interface ColorsSurveyProps {
    data?: ProgramTaskSurvey;
    responses?: SurveyResponses;
    loading?: boolean;
    onSubmit: FormProps['onFinish'];
    subTaskStatus?: UserProgramTaskStatus;
}

const ColorsSurvey: FC<ColorsSurveyProps> = ({ data, responses, loading, onSubmit, subTaskStatus }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const onSubmitForm: FormProps['onFinish'] = (values) => {
        const { consent, ...restValues } = values;
        const result = {
            ...restValues,
            [Object.keys(restValues)[0]]: restValues[Object.keys(restValues)[0]]?.map((answer: string) => ({
                label: answer.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)[0],
                values: [answer.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)[1]],
            })),
        };

        onSubmit?.(result);
    };
    const positions = useRef(
        data?.questions?.[0]?.answers?.map((_, index) => ({
            top: Math.floor(Math.random() * 24) * (index % (Math.floor(Math.random() * 2)) === 0 ? -1 : 1),
            left: Math.floor(Math.random() * 12) * (index % (Math.floor(Math.random() * 2)) === 0 ? -1 : 1),
        }),
    ));

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    if (subTaskStatus === UserProgramTaskStatus.validated) {
        return (
            <ColorsSurveyResults
                survey={data}
                responses={responses}
            />
        );
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={formatInitialValues(responses)}
            onFinish={onSubmitForm}
            className="colors-form"
            scrollToFirstError
        >
            {data?.description && (
                <Typography.Paragraph style={{ lineHeight: '1.1875rem' }}>
                    {data.description}
                </Typography.Paragraph>
            )}
            <div id="colors-wrapper">
                <Form.Item
                    name={data?.questions?.[0]?.id}
                    noStyle
                >
                    <Checkbox.Group>
                        <BasicList>
                            {data?.questions?.[0]?.answers?.map((answer, answerIndex) => (
                                <li
                                    key={answerIndex}
                                    style={positions ? positions.current?.[answerIndex] : undefined}
                                >
                                    <CheckboxButton value={`${answer.label}${constants.SURVEY_LABEL_ANSWER_SEPARATOR}${answer.values?.join('')}`}>
                                        {answer.label}
                                    </CheckboxButton>
                                </li>
                            ))}
                        </BasicList>
                    </Checkbox.Group>
                </Form.Item>
            </div>
            <Form.Item
                name="consent"
                valuePropName="checked"
                rules={[{
                    required: true,
                    message: formatMessage(formMessages.requiredField),
                    validator: (_, value) => !!value ? Promise.resolve() : Promise.reject(),
                }]}
                style={{ marginTop: '1.5rem' }}
            >
                <Checkbox>
                    <FormattedMessage
                        {...formMessages.dataConsent}
                        values={{
                            link: (...chunks: ReactElement[]) => (
                                <Link to={getRoute(RoutePathName.privacyPolicy)} target="_blank">
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            !form.getFieldValue('consent')
                            ||
                            (
                                form &&
                                (
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                )
                            )
                        }
                        loading={loading}
                    >
                        <FormattedMessage
                            {...ProgramTaskDetailsMessages.surveyTricamValidationSubmit}
                        />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default ColorsSurvey;
