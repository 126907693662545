import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Typography } from 'antd';

import { AuthState, login, getAuthState } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';
import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import GenericMessages from '../../locale/genericMessages';
import formMessages from '../../locale/formMessages';
import { getRoute, RoutePathName } from '../../routes';
import LoginMessages from './LoginMessages';
import LoginLayout from '../../components/LoginLayout';

interface LoginProps extends RouteComponentProps {
    onLogin: typeof login.trigger;
    authState: AuthState;
}

const Login: FC<LoginProps> = ({ authState, onLogin }) => {
    const { formatMessage } = useIntl();
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        onLogin(values);
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };

    if (authState.isConnected) {
        if (authState.user && authState.user.acceptedTerms) {
            return <Redirect to="/" />;
        } else {
            return <Redirect to="/login-terms" />;
        }
    }

    let error = authState.error ? <FormattedMessage {...GenericMessages.error} /> : null;

    if (authState?.error?.status) {
        switch (authState.error.status) {
            case 401:
            case 403:
                error = <FormattedMessage {...LoginMessages.invalidCredentials} />;
                break;

            case 404:
                error = <FormattedMessage {...LoginMessages.emailNotFound} />;
                break;

            default:
                error = <FormattedMessage {...GenericMessages.error} />;
                break;
        }
    }

    return (
        <LoginLayout>
            <Seo title={formatMessage(LoginMessages.loginTitle)} />
            <Form
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="logo" />
                <Typography.Title level={1}>
                    <FormattedMessage {...LoginMessages.loginTitle} />
                </Typography.Title>

                <Form.Item
                    name="username"
                    label={formatMessage(LoginMessages.emailLabel)}
                    rules={[requiredRule, { type: 'email', message: formatMessage(formMessages.invalidEmail)}]}
                >
                    <Input
                        placeholder={formatMessage(LoginMessages.emailPlaceholder)}
                    />
                </Form.Item>

                <Form.Item
                    className="password-row"
                    name="password"
                    label={formatMessage(LoginMessages.passwordLabel)}
                    rules={[requiredRule]}
                >
                    <Input.Password
                        placeholder={formatMessage(LoginMessages.passwordPlaceholder)}
                    />
                </Form.Item>

                <Form.Item>
                    {error ? (
                        <div className="login-error-message">
                            <Alert
                                type="error"
                                message={error}
                                showIcon
                            />
                        </div>
                    ) : null}

                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={authState.loading}
                        block
                    >
                        <FormattedMessage {...LoginMessages.loginButton} />
                    </Button>
                </Form.Item>
                <Link to={getRoute(RoutePathName.forgottenPassword)}>
                    <FormattedMessage {...LoginMessages.forgotPassword} />
                </Link>
            </Form>
        </LoginLayout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        onLogin: login.trigger,
    },
)(Login);
