import React, { useState, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Input from 'antd/lib/input';
import { PasswordProps } from 'antd/lib/input/Password';
import validatePasswordRules, { PasswordIssueReason } from '../helpers/passwords';

import formMessages from '../locale/formMessages';
import { IconCheck } from './icons';
import BasicList from './BasicList';
import { classNames } from '../helpers';

interface PasswordInputProps extends PasswordProps {
    value?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({
    value, onChange, ...props
}) => {
    const [val, setVal] = useState(value || '');
    const [errors, setErrors] = useState<ReturnType<typeof validatePasswordRules>>();
    const onChangeInput: PasswordProps['onChange'] = (e) => {
        const inputValue = e.target.value;
        setVal(inputValue);
        setErrors(validatePasswordRules(inputValue));
        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <>
            <Input.Password
                {...props}
                onChange={onChangeInput}
            />
            <BasicList className="password-input-instructions">
                <li>
                    <IconCheck
                        className={classNames(!!val && !errors?.issues.find((issue) =>
                            issue.reason === PasswordIssueReason.minimumLength ||
                            issue.reason === PasswordIssueReason.maximumLength,
                            ) && 'is-valid')}
                    />
                    <FormattedMessage {...formMessages.passwordStrengthLength} />
                </li>
                <li>
                    <IconCheck
                        className={classNames(!!val && !errors?.issues.find((issue) =>
                            issue.reason === PasswordIssueReason.requireNumber,
                        ) && 'is-valid')}
                    />
                    <FormattedMessage {...formMessages.passwordStrengthDigit} />
                </li>
                <li>
                    <IconCheck
                        className={classNames(!!val && !errors?.issues.find((issue) =>
                            issue.reason === PasswordIssueReason.requireCapital,
                        ) && 'is-valid')}
                    />
                    <FormattedMessage {...formMessages.passwordStrengthUppercase} />
                </li>
                <li>
                    <IconCheck
                        className={classNames(!!val && !errors?.issues.find((issue) =>
                            issue.reason === PasswordIssueReason.requireLower,
                        ) && 'is-valid')}
                    />
                    <FormattedMessage {...formMessages.passwordStrengthLowercase} />
                </li>

                <li>
                    <IconCheck
                        className={classNames(!!val && !errors?.issues.find((issue) =>
                            issue.reason === PasswordIssueReason.requireSpecial,
                        ) && 'is-valid')}
                    />
                    <FormattedMessage {...formMessages.passwordStrengthSymbol} />
                </li>
            </BasicList>
        </>
    );
};

export default PasswordInput;
