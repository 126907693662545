import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 16">
        <path fill="currentColor" d="M10.941 0C11.521 0 12 .471 12 1.043v13.914c0 .572-.478 1.043-1.059 1.043H1.06C.479 16 0 15.529 0 14.957V1.043C0 .471.478 0 1.059 0zM9.53 12.522H2.471a.35.35 0 00-.353.348.35.35 0 00.353.347h7.058a.35.35 0 00.353-.347.35.35 0 00-.353-.348zm0-1.74H2.471a.35.35 0 00-.353.348.35.35 0 00.353.348h7.058a.35.35 0 00.353-.348.35.35 0 00-.353-.347zm0-1.739H2.471a.35.35 0 00-.353.348.35.35 0 00.353.348h7.058a.35.35 0 00.353-.348.35.35 0 00-.353-.348zM6 5.565c-.512 0-1.133.137-1.665.462-.533.325-.982.884-.982 1.625 0 .182.168.348.353.348h4.588a.364.364 0 00.353-.348c0-.741-.449-1.3-.982-1.625A3.288 3.288 0 006 5.565zm0-2.782c-.678 0-1.235.549-1.235 1.217S5.322 5.217 6 5.217 7.235 4.668 7.235 4 6.678 2.783 6 2.783z" />
    </svg>
);

const IconCV: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-cv${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCV;
