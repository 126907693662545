import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useResizeObserver from 'use-resize-observer';
// tslint:disable-next-line: no-submodule-imports
import { Props } from 'react-pdf/dist/Document';
import { Button, Input } from 'antd';
import { InputProps } from 'antd/lib/input';

// tslint:disable-next-line: no-submodule-imports
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../assets/styles/PDFViewer.less';

import { IconArrowLeft, IconArrowRight } from './icons';
import { scrollToElement } from '../helpers';
import { PrinterOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps extends Props {
    allowPrint?: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ allowPrint, file }) => {
    const {ref, width} = useResizeObserver<HTMLDivElement>();
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfOptions, setPdfOptions] = useState({ numPages: 0 });
    const onDocumentLoadSuccess: Props['onLoadSuccess'] = (pdfData) => {
        setPdfOptions(pdfData);
    };
    const onPreviousPage = () => {
        setPageNumber(pageNumber - 1 < 1 ? 1 : pageNumber - 1);
        scrollToElement(ref.current);
    };
    const onNextPage = () => {
        setPageNumber(pageNumber + 1 === pdfOptions.numPages ? pdfOptions.numPages : pageNumber + 1);
        scrollToElement(ref.current);
    };
    const onChangePage: InputProps['onChange'] = (e) => {
        const page = parseInt(e.target.value, 10);
        setPageNumber(page <= pdfOptions.numPages && page > 0 ? page : pageNumber);
        scrollToElement(ref.current);
    };
    const onPrint = () => {
        window.open(file);
    };
    const toolbar = (
        <div className="pdf-viewer-toolbar">
            <div className="pdf-viewer-pagination">
                <Button
                    onClick={onPreviousPage}
                    shape="circle"
                    icon={<IconArrowLeft />}
                    disabled={pageNumber === 1}
                />
                <Input
                    value={pageNumber}
                    onChange={onChangePage}
                    size="small"
                />
                <span>{` / ${pdfOptions.numPages}`}</span>
                <Button
                    onClick={onNextPage}
                    shape="circle"
                    icon={<IconArrowRight />}
                    disabled={pageNumber === pdfOptions.numPages}
                />
            </div>
            {allowPrint && (
                <Button
                    onClick={onPrint}
                    shape="circle"
                    icon={<PrinterOutlined />}
                />
            )}
        </div>
    );

    return (
        <section className="pdf-viewer" ref={ref}>
            {toolbar}
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={pageNumber}
                    width={width}
                    renderAnnotationLayer
                />
            </Document>
            {toolbar}
        </section>
    );
};
export default PDFViewer;
