import React, { FC, ReactNode } from 'react';
import { Layout, Button, Typography } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';

import '../assets/styles/FullscreenLayout.less';

import { IconClose, IconArrowLeft } from './icons';

const messages = defineMessages({
    quit: {
        id: 'fullscreen_layout.quit',
        defaultMessage: 'Quitter',
        description: 'Fullscreen layout quit button',
    },
    back: {
        id: 'fullscreen_layout.back',
        defaultMessage: 'Retour',
        description: 'Fullscreen layout back button',
    },
});

interface FullscreenLayoutComposition {
    Header: FC<{
        onQuit: () => void;
        onPrevious?: () => void;
        title: string | ReactNode;
        subtitle?: string | ReactNode;
        icon: ReactNode;
        isFirstStep?: boolean;
    }>;
    Content: FC;
}

const FullscreenLayout: FC & FullscreenLayoutComposition = ({ children }) => (
    <Layout id="fullscreen-layout">
        {children}
    </Layout>
);

export default FullscreenLayout;

const Header: FullscreenLayoutComposition['Header'] = ({
    children, icon, onQuit, onPrevious, isFirstStep, title, subtitle,
}) => {
    // created a handler to prevent passing click event to onQuit which led to some weird error
    const onClickClose = () => {
        onQuit();
    };

    return (
        <Layout.Header id="fullscreen-layout-header">
            {typeof onPrevious === 'function' && !isFirstStep && (
                <Button
                    id="fullscreen-layout-previous"
                    type="link"
                    onClick={onPrevious}
                >
                    <IconArrowLeft />
                    <FormattedMessage {...messages.back} />
                </Button>
            )}
            <Button
                id="fullscreen-layout-quit"
                type="link"
                onClick={onClickClose}
            >
                <IconClose />
                <FormattedMessage {...messages.quit} />
            </Button>
            <div id="fullscreen-layout-header-inner">
                <div id="fullscreen-layout-icon">
                    {icon}
                </div>
                <Typography.Title level={1}>
                    {title}
                </Typography.Title>
                {subtitle && (
                    <div className="subtitle">
                        {subtitle}
                    </div>
                )}
            </div>
            {children}
        </Layout.Header>
    );
};

const Content: FullscreenLayoutComposition['Content'] = ({ children }) => (
    <Layout.Content id="fullscreen-layout-content">
        {children}
    </Layout.Content>
);

FullscreenLayout.Content = Content;
FullscreenLayout.Header = Header;
