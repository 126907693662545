import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Skeleton } from 'antd';
import { DateTime } from 'luxon';

import '../../assets/styles/Home.less';

import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/actions/auth';
import { User, UserProgramTaskStatus } from '../../store/api/apiTypes';
import { getHomePhraseTasksCount, getIsHomePhraseLoading, homeListCount } from '../../store/actions/tasks';
import { getAppointmentsListState } from '../../store/actions/appointments';
import { ProgramsState, getMyProgramState } from '../../store/actions/programs';

import Seo from '../../components/Seo';
import { getRoute, RoutePathName } from '../../routes';
import HeaderCTA from '../../components/HeaderCTA';
import { IconPlus } from '../../components/icons';
import genericMessages from '../../locale/genericMessages';
import { NetworkingActionCreateStep } from '../networkingActionCreate/NetworkingActionCreate';
import EditAccountModal from './EditAccountModal';
import HomeMessages from './HomeMessages';
import UserStats from './UserStats';
import UserNetworkingActions from './UserNetworkingActions';
import UserProgram from './UserProgram';

interface HomeProps {
    user?: User;
    appointmentCount: number;
    getTasksCount: typeof homeListCount.trigger;
    programState: ProgramsState['my'];
    tasksCount: number;
    tasksLoading: boolean;
}

const Home: FC<HomeProps> = ({ appointmentCount, getTasksCount, programState, tasksCount, tasksLoading, user }) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const currentWeekNumber = DateTime.utc().weekNumber;
    const programStartDate = programState.data?.dates?.fromDate ?
        DateTime.fromISO(programState.data?.dates?.fromDate, { zone: 'UTC' }) :
        DateTime.utc();
    const onClickNetworkingActionCTA = () => {
        history.push({
            pathname: getRoute(RoutePathName.networkingActionCreate, { step: NetworkingActionCreateStep.what }),
            state: {
                previous: getRoute(RoutePathName.home),
            },
        });
    };

    useEffect(() => {
        if (!programState.loading && !programState.error && programState.data && programStartDate.weekNumber) {
            getTasksCount({
                fromDate: currentWeekNumber !== programStartDate.weekNumber ?
                    DateTime.utc().startOf('week').toString() :
                    undefined,
                toDate: DateTime.utc().endOf('week').toString(),
                status: [UserProgramTaskStatus.pending, UserProgramTaskStatus.started],
                pageSize: 1,
                strictRange: true,
            });
        }
    }, [
        getTasksCount, programState.loading, programState.error, programState.data, currentWeekNumber,
        programStartDate.weekNumber,
    ]);

    return (
        <div id="home">
            <Seo title={formatMessage(HomeMessages.title)} />
            <HeaderCTA
                onClick={onClickNetworkingActionCTA}
                icon={<IconPlus />}
            >
                <FormattedMessage {...genericMessages.networkingAction} />
            </HeaderCTA>
            <Typography.Title level={1}>
                <FormattedMessage
                    {...HomeMessages.mainTitle}
                    values={{
                        name: user?.firstName,
                    }}
                />
            </Typography.Title>
            <Typography.Paragraph>
                {tasksLoading ?
                    <Skeleton paragraph={false} active title={{ width: '70%' }} /> :
                    (
                        <p>
                            <FormattedMessage
                                {...HomeMessages.summaryTasks}
                                values={{
                                    count: tasksCount,
                                    actions: (
                                        <strong className="text-primary text-lowercase">
                                            <FormattedMessage {...HomeMessages.actionCount} values={{ count: tasksCount }} />
                                        </strong>
                                    ),
                                }}
                            />
                            <FormattedMessage
                                {...HomeMessages.summaryMeetings}
                                values={{
                                    count: appointmentCount,
                                    meetings: (
                                        <strong className="text-primary text-lowercase">
                                            <FormattedMessage
                                                {...HomeMessages.meetingCount}
                                                // request made from UserNetworkingActions
                                                values={{ count: appointmentCount }}
                                            />
                                        </strong>
                                    ),
                                    br: <><br /><br /></>,
                                }}
                            />

                        </p>
                    )
                }
            </Typography.Paragraph>
            <EditAccountModal />
            <UserStats />
            <UserNetworkingActions />
            <UserProgram />
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
    tasksCount: getHomePhraseTasksCount(state),
    tasksLoading: getIsHomePhraseLoading(state),
    appointmentCount: getAppointmentsListState(state).data?.totalCount || 0,
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
    {
        getTasksCount: homeListCount.trigger,
    },
)(Home);
