import React, { FC } from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

import '../assets/styles/CommentCard.less';

import { classNames } from '../helpers';

const CommentCard: FC<CardProps> = ({ children, ...props }) => (
    <Card
        {...props}
        className={classNames('comment-card', props.className)}
    >
        {children}
    </Card>
);

export default CommentCard;
