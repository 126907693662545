import React, { FC } from 'react';
import { Card, Typography } from 'antd';

import '../assets/styles/ProgramCard.less';

import TruncatedText from './TruncatedText';
import { useIsMobile } from '../hooks';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';
import { getMyProgramState, ProgramsState } from '../store/actions/programs';
import CardSkeleton from './CardSkeleton';

interface ProgramCardProps {
    programState: ProgramsState['my'];
}

const ProgramCard: FC<ProgramCardProps> = ({ programState }) => {
    const isMobile = useIsMobile();
    return programState.loading ?
        <CardSkeleton rows={6} /> : (
        <Card className="program-card">
            <Typography.Title level={2}>
                {programState.data?.program?.name}
            </Typography.Title>
            {!!programState.data?.program?.description && (
                <TruncatedText
                    maxHeight={isMobile ? 168 : 175}
                    withFade
                >
                    <p className="program-card-description" dangerouslySetInnerHTML={{ __html: programState.data?.program?.description }} />
                </TruncatedText>
            )}
        </Card>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    programState: getMyProgramState(state),
});

export default connect(
    mapStateToProps,
)(ProgramCard);
