import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import ICalendarLink from 'react-icalendar-link';
import { Modal, Space, Button, Row, Col } from 'antd';

import { MainReducerState } from '../store/reducers';
import * as appointmentsActions from '../store/actions/appointments';
import * as contactsActions from '../store/actions/contacts';
import * as jobOffersActions from '../store/actions/jobOffers';

import ButtonLink from './ButtonLink';
import { getRoute, RoutePathName } from '../routes';
import CreateActionMessages from '../pages/networkingActionCreate/NetworkingActionCreateMessages';
import { formatAddress, getFullName } from '../helpers';
import { usePrevious } from '../hooks';
import ContactCreateMessages from '../pages/contactCreate/ContactCreateMessages';
import { useHistory, useLocation } from 'react-router-dom';
import { IconPlus } from './icons';
import JobOfferCreateMessages from '../pages/jobOfferCreate/JobOfferCreateMessages';

interface SuccessModalsProps {
    createActionState: appointmentsActions.AppointmentsState['create'];
    createContactState: contactsActions.ContactsState['create'];
    createJobOfferState: jobOffersActions.JobOffersState['create'];
}

const SuccessModals: FC<SuccessModalsProps> = ({ createActionState, createContactState, createJobOfferState }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation<{ initiator?: string } | undefined>();
    const locationInitiator = location?.state?.initiator;
    const [isCreateActionModalVisible, setIsCreateActionModalVisible] = useState(false);
    const [isCreateContactModalVisible, setIsCreateContactModalVisible] = useState(false);
    const [isCreateJobOfferModalVisible, setIsCreateJobOfferModalVisible] = useState(false);
    const previous = usePrevious({ createActionState, createContactState, createJobOfferState });
    const onClickCreateActionFromContact = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), { values: { contact: createContactState.data?.id } });
        setIsCreateContactModalVisible.bind(null, false);
    };

    useEffect(() => {
        if (previous?.createActionState.loading && !createActionState.loading && !createActionState.error) {
            setIsCreateActionModalVisible(true);
        }
    }, [ previous, createActionState.loading, createActionState.error ]);

    useEffect(() => {
        if (
            previous?.createContactState.loading && !createContactState.loading && !createContactState.error &&
            locationInitiator?.startsWith(getRoute(RoutePathName.networkingAction))
        ) {
            setIsCreateContactModalVisible(true);
        }
    }, [ previous, createContactState.loading, createContactState.error, locationInitiator ]);

    useEffect(() => {
        if (previous?.createJobOfferState.loading && !createJobOfferState.loading && !createJobOfferState.error) {
            setIsCreateJobOfferModalVisible(true);
        }
    }, [previous, createJobOfferState.loading, createJobOfferState.error]);

    return (
        <>
            <Modal
                wrapClassName="success-modal"
                title={formatMessage(CreateActionMessages.successModalTitle, { title: createActionState.data?.title })}
                footer={null}
                visible={isCreateActionModalVisible}
                onCancel={setIsCreateActionModalVisible.bind(null, false)}
                width={550}
                maskClosable
            >
                <Space direction="vertical">
                    <Row gutter={[24, 16]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <ButtonLink
                                to={getRoute(RoutePathName.networkingAction, { networkingActionId: createActionState.data?.id || '' })}
                                onClick={setIsCreateActionModalVisible.bind(null, false)}
                                ghost
                            >
                                <FormattedMessage {...CreateActionMessages.successModalActionLink} />
                            </ButtonLink>
                        </Col>
                        <Col xs={{ order: 0, span: 24 }} lg={{ span: 12 }}>
                            <ButtonLink
                                to={getRoute(RoutePathName.contactEdit, { contactId: createActionState.data?.contact?.id || '' })}
                                onClick={setIsCreateActionModalVisible.bind(null, false)}
                                type="primary"
                            >
                                <FormattedMessage {...CreateActionMessages.successModalCompleteContactLink} />
                            </ButtonLink>
                        </Col>
                    </Row>
                    <small>
                        <ICalendarLink
                            event={{
                                title: createActionState.data?.title || '',
                                description: createActionState.data?.title || '',
                                startTime: createActionState.data?.date || '',
                                endTime: createActionState.data?.date || '',
                                location: formatAddress(createActionState.data?.address),
                            }}
                        >
                            <FormattedMessage {...CreateActionMessages.successModalCompleteDownloadCal} />
                        </ICalendarLink>
                    </small>
                </Space>
            </Modal>
            <Modal
                wrapClassName="success-modal"
                title={
                    formatMessage(ContactCreateMessages.successModalTitle, {
                        name: getFullName(createContactState.data?.firstName, createContactState.data?.lastName),
                    })
                }
                footer={null}
                visible={isCreateContactModalVisible}
                onCancel={setIsCreateContactModalVisible.bind(null, false)}
                width={550}
                maskClosable
            >
                <Row gutter={[24, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <ButtonLink
                            to={getRoute(RoutePathName.contact, { contactId: createContactState.data?.id || '' })}
                            onClick={setIsCreateContactModalVisible.bind(null, false)}
                            ghost
                        >
                            <FormattedMessage {...ContactCreateMessages.successModalSeeContact} />
                        </ButtonLink>
                    </Col>
                    <Col xs={{ order: 0, span: 24 }} lg={{ span: 12 }}>
                        <Button
                            onClick={onClickCreateActionFromContact}
                            type="primary"
                        >
                            <IconPlus /> <FormattedMessage {...ContactCreateMessages.successModalCreateActionLink} />
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                wrapClassName="success-modal"
                title={
                    formatMessage(JobOfferCreateMessages.successModalTitle, {
                        title: <strong>{createJobOfferState.data?.title}</strong>,
                    })
                }
                footer={null}
                visible={isCreateJobOfferModalVisible}
                onCancel={setIsCreateJobOfferModalVisible.bind(null, false)}
                width={550}
                maskClosable
            >
                <Space direction="vertical">
                    <ButtonLink
                        to={getRoute(RoutePathName.jobOffer, { jobOfferId: createJobOfferState.data?.id || '' })}
                        onClick={setIsCreateJobOfferModalVisible.bind(null, false)}
                        ghost
                    >
                        <FormattedMessage {...JobOfferCreateMessages.successModalSeeJobOffer} />
                    </ButtonLink>
                </Space>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createActionState: appointmentsActions.getAppointmentsCreateState(state),
    createContactState: contactsActions.getContactsCreateState(state),
    createJobOfferState: jobOffersActions.getJobOffersCreateState(state),
});

export default connect(
    mapStateToProps,
)(SuccessModals);
