import { Pagination as AntPagination } from 'antd';
import React, { FC } from 'react';
import { PaginationProps } from 'antd/lib/pagination';

import '../assets/styles/Login.less';

import { IconArrowLeft, IconArrowRight } from './icons';

const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
    if (type === 'prev') {
        return (
            <button className="ant-pagination-item-link" type="button" tabIndex={-1}>
                <IconArrowLeft />
            </button>
        );
    } else if (type === 'next') {
        return (
            <button className="ant-pagination-item-link" type="button" tabIndex={-1}>
                <IconArrowRight />
            </button>
        );
    } else {
        return originalElement;
    }
};

const Pagination: FC<PaginationProps> = (props) => (
    <AntPagination
        {...props}
        itemRender={itemRender}
    />
);

export default Pagination;
