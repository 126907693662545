import React, { FC, useState, forwardRef, useEffect } from 'react';
import { Spin } from 'antd';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import {
    ProgramsState,
} from '../store/actions/programs';
import { Program, User, Pack, UserGroup, ProgramType } from '../store/api/apiTypes';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Select from './Select';
import { FormattedMessage } from 'react-intl';
import ClientUsersMessages from '../pages/client/users/ClientUsersMessages';
import { UserSelectedProgramPayload } from '../store/api/users';

interface Option {
    pack: Pack;
    program: Program;
}

interface UserProgramFilterSelectProps {
    user: User;
    value ?: UserSelectedProgramPayload;
    multi?: boolean;
    programs: ProgramsState;
    size?: SizeType;
    placeholder ?: string;
    isExtension ?: boolean;
    onChange?: (value: any) => void;
}

const UserProgramFilterSelect: FC<UserProgramFilterSelectProps> = forwardRef((props, ref: any) => {

    const {user, onChange, programs, multi, size, placeholder, isExtension} = props;
    const [ value, setValue ] = useState<UserSelectedProgramPayload>();

    useEffect(() => {
        setValue(undefined);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSelect = (newValue: any) => {
        let val;
        if (newValue) {
            const [programId, packId] = newValue.split('-');

            val = {
                selectedProgram: programId,
                licensePack: packId,
            };

            setValue(val);
        } else {
            setValue(undefined);
        }

        if (onChange) {onChange(val); }
    };

    const selectOptionsPrograms: Option[] = [];

    user.groups?.forEach((group: UserGroup) => {
        if (group.licensePacks) {
            group.licensePacks.forEach((pack: Pack) => {
                pack.programs?.forEach((program) => {
                    if (
                        (
                            (!isExtension && program.type === ProgramType.basic)
                            || (isExtension && program.type === ProgramType.extension)
                        )
                        && !selectOptionsPrograms.find((option) => (option.pack.id === pack.id && option.program.id === program.id))
                    ) {
                        selectOptionsPrograms.push({
                            program,
                            pack,
                        });
                    }
                });
            });
        }
    });

    return (
        <Select
            mode={(multi) ? 'multiple' : undefined}
            size={size}
            ref={ref}
            showSearch
            value={value ? (value?.selectedProgram + '-' + value?.licensePack) : undefined}
            placeholder={placeholder || <FormattedMessage {...ClientUsersMessages.selectProgramPlaceholder} />}
            notFoundContent={programs.search.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onChange={onChangeSelect}
            allowClear
            style={{ width: '100%' }}
            showArrow
        >
            {
                selectOptionsPrograms.map((option) => (
                    <Select.Option
                        value={option.program.id + '-' + option.pack.id}
                        key={option.program.id + '-' + option.pack.id}
                        disabled={!(option.pack.availableLicenseCount && option.pack.availableLicenseCount > 0)}
                    >
                        {option.program.name} ({option.pack.name})
                    </Select.Option>
                ))
            }
        </Select>
    );

});

UserProgramFilterSelect.defaultProps = {
    multi: false,
    size: 'large',
};

const mapStateToProps = (state: MainReducerState) => ({
    programs: state.programs,
});

export default connect(
    mapStateToProps,
    {
    },
    null,
    { forwardRef: true },
)(UserProgramFilterSelect);
