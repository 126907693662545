import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Card, Row, Col, Button, Input, Spin, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import { SelectProps } from 'antd/lib/select';
import dayjs from 'dayjs';

import {
    Appointment, NetworkingActionType, networkingActionTypeMessages, AppointmentType,
    actionMeetingTypeMessages,
} from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { getContactsListState, list, ContactsState } from '../../store/actions/contacts';
import { AppointmentsState, update, getAppointmentsUpdateState } from '../../store/actions/appointments';

import formMessages from '../../locale/formMessages';
import { stripUndefinedKeysFromObject, getFullName } from '../../helpers';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import TimePicker from '../../components/TimePicker';
import PhoneInput from '../../components/PhoneInput';
import NetworkingActionCreateMessages from '../networkingActionCreate/NetworkingActionCreateMessages';
import { NetworkingActionCreateValues } from '../networkingActionCreate/NetworkingActionCreate';
import NetworkingActionDetailsMessages from './NetworkingActionDetailsMessages';
import { usePrevious } from '../../hooks';

interface NetworkingActionEditFormProps {
    appointment?: Appointment;
    listContact: typeof list.trigger;
    listContactState: ContactsState['list'];
    appointmentUpdateState: AppointmentsState['update'];
    updateAppointment: typeof update.trigger;
}

const NetworkingActionEditForm: FC<NetworkingActionEditFormProps> = ({
    appointment, appointmentUpdateState, listContact, listContactState, updateAppointment,
}) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const previous = usePrevious({ appointmentUpdateState });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps<NetworkingActionCreateValues>['onFinish'] = (val) => {
        const { date, time, ...rest } = val;

        updateAppointment({
            id: appointment?.id,
            appointment: {
                ...rest,
                date: date?.
                    set('hour', time?.get('hour') || 0).
                    set('minute', time?.get('minute') || 0).
                    set('second', 0).
                    set('millisecond', 0),
            },
        });
    };
    const onClickCancelEdit = () => {
        history.push(getRoute(RoutePathName.networkingAction, { networkingActionId: appointment?.id || '' }));
    };
    const onContactSearch: SelectProps<string>['onSearch'] = (search) => {
        listContact(stripUndefinedKeysFromObject({ limit: 20, search: search || undefined, throttling: 300 }));
    };

    useEffect(() => {
        listContact({ limit: 20 });
    }, [listContact]);

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (previous?.appointmentUpdateState.loading && !appointmentUpdateState.loading) {
            if (appointmentUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(NetworkingActionDetailsMessages.updateSuccess));
                history.push(getRoute(RoutePathName.networkingAction, { networkingActionId: appointment?.id || '' }));
            }
        }
    }, [
        previous, appointmentUpdateState.loading, appointmentUpdateState.error, formatMessage, history,
        appointment,
    ]);

    if (!appointment) {
        return <Spin />;
    }

    return (
        <Form<NetworkingActionCreateValues>
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={{
                ...appointment,
                contact: appointment.contact?.id,
                date: dayjs(appointment.date),
                time: dayjs(appointment.date),
            }}
            onFinish={onFormValidSubmit}
            scrollToFirstError
        >
            <Card className="form-card">
                <Form.Item
                    label={formatMessage(NetworkingActionCreateMessages.whatNameLabel)}
                    name="title"
                    rules={[requiredRule]}
                >
                    <Input
                        placeholder={formatMessage(NetworkingActionCreateMessages.whatNamePlaceholder)}
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whatTypeLabel)}
                            name="actionType"
                            rules={[requiredRule]}
                        >
                            <Select
                                placeholder={formatMessage(NetworkingActionCreateMessages.whatTypePlaceholder)}
                            >
                                {Object.values(NetworkingActionType).map((type) => (
                                    <Select.Option value={type} key={type}>
                                        <FormattedMessage {...networkingActionTypeMessages.get(type)} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whoContactLabel)}
                            name="contact"
                            rules={[requiredRule]}
                        >
                            <Select
                                filterOption={false}
                                loading={listContactState.loading}
                                onSearch={onContactSearch}
                                placeholder={formatMessage(NetworkingActionCreateMessages.whoContactPlaceholder)}
                                showSearch
                                showArrow
                            >
                                {(appointment.contact ?
                                    // add existing contact to initial list since we only load the first 20, it might not be in it
                                    [
                                        appointment.contact,
                                        ...(listContactState.data?.items.filter((item) => item.id !== appointment.contact?.id) || []),
                                    ] :
                                    listContactState.data?.items
                                )?.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {getFullName(item.firstName, item.lastName)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whenDateLabel)}
                            name="date"
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(NetworkingActionCreateMessages.whenDatePlaceholder)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whenTimeLabel)}
                            name="time"
                            rules={[requiredRule]}
                        >
                            <TimePicker
                                placeholder={formatMessage(NetworkingActionCreateMessages.whenTimePlaceholder)}
                                showSecond={false}
                                minuteStep={5}
                                // tslint:disable-next-line: jsx-no-lambda
                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 23]}
                                hideDisabledOptions
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={formatMessage(NetworkingActionCreateMessages.whenTypeLabel)}
                    name="type"
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage(NetworkingActionCreateMessages.whenTypePlaceholder)}
                    >
                        {Object.values(AppointmentType).map((type) => (
                            <Select.Option value={type} key={type}>
                                <FormattedMessage {...actionMeetingTypeMessages.get(type)} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item dependencies={['type']} noStyle>
                    {({ getFieldValue }) => (
                        <>
                            {getFieldValue('type') === AppointmentType.real && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingAddressLabel)}
                                    name={['address', 'street']}
                                >
                                    <Input placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingAddressPlaceholder)} />
                                </Form.Item>
                            )}
                            {getFieldValue('type') === AppointmentType.phone && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingPhoneLabel)}
                                    name="phone"
                                >
                                    <PhoneInput placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingPhonePlaceholder)} />
                                </Form.Item>
                            )}
                            {getFieldValue('type') === AppointmentType.video && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingVideoUrlLabel)}
                                    name="url"
                                >
                                    <Input placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingVideoUrlPlaceholder)} />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <div className="flex flex-between">
                            <Button
                                type="ghost"
                                onClick={onClickCancelEdit}
                            >
                                <FormattedMessage {...genericMessages.cancel} />
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={appointmentUpdateState.loading}
                                disabled={
                                    form &&
                                    (
                                        !form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    )
                                }
                            >
                                <FormattedMessage {...NetworkingActionDetailsMessages.updateSubmit} />
                            </Button>
                        </div>
                    )}
                </Form.Item>
            </Card>
        </Form>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listContactState: getContactsListState(state),
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listContact: list.trigger,
        updateAppointment: update.trigger,
    },
)(NetworkingActionEditForm);
