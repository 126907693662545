import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4 2.857V4h1.714V2.857H4zm6.286 0V4H12V2.857h-1.714zm0 0V4H12V2.857h-1.714zM4 2.857V4h1.714V2.857H4zM13.714 0H2.286A2.286 2.286 0 000 2.286v11.428A2.286 2.286 0 002.286 16h2.857v-4c0-.947.767-1.714 1.714-1.714h2.286c.947 0 1.714.767 1.714 1.714v4h2.857A2.286 2.286 0 0016 13.714V2.286A2.286 2.286 0 0013.714 0zM6.857 8.571c0 .632-.512 1.143-1.143 1.143H4a1.143 1.143 0 01-1.143-1.143V7.43c0-.632.512-1.143 1.143-1.143h1.714c.631 0 1.143.511 1.143 1.143V8.57zm0-4.571c0 .631-.512 1.143-1.143 1.143H4A1.143 1.143 0 012.857 4V2.857c0-.631.512-1.143 1.143-1.143h1.714c.631 0 1.143.512 1.143 1.143V4zm6.286 4.571c0 .632-.512 1.143-1.143 1.143h-1.714a1.143 1.143 0 01-1.143-1.143V7.43c0-.632.512-1.143 1.143-1.143H12c.631 0 1.143.511 1.143 1.143V8.57zm0-4.571c0 .631-.512 1.143-1.143 1.143h-1.714A1.143 1.143 0 019.143 4V2.857c0-.631.512-1.143 1.143-1.143H12c.631 0 1.143.512 1.143 1.143V4zm-2.857 4.571H12V7.43h-1.714V8.57zm0-4.571H12V2.857h-1.714V4zM4 4h1.714V2.857H4V4zm0-1.143V4h1.714V2.857H4zm5.143 8.572c.315 0 .571.255.571.571v4H6.286v-4c0-.316.256-.571.571-.571h2.286zm-3.429-4V8.57H4V7.43h1.714zm0-4.572V4H4V2.857h1.714z" />
    </svg>
);

const IconOffice: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-office${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconOffice;
