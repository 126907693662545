import React, { FC } from 'react';
import { Skeleton, Empty, Card } from 'antd';

import { StaticPageState } from '../store/actions/staticPages';

import Seo from './Seo';
import ErrorPage from '../pages/error';

interface StaticPageProps {
    pageState: StaticPageState;
    small?: boolean;
}

const StaticPage: FC<StaticPageProps> = ({ children, pageState, small }) => (
    <Card id="static-page" className={`page-container${small ? '-small' : ''}`}>
        <Seo title={pageState.data?.title} />
        {pageState.loading ? (
            <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </>
        ) : (
            <>
                {pageState.error ? (
                    <ErrorPage />
                ) : (
                    !pageState.data ? (
                        <Empty />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: pageState.data.content }} />
                    )
                )}
            </>
        )}
        {children}
    </Card>
);

export default StaticPage;
