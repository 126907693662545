import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path fill="currentColor" d="M5.83.326a1 1 0 0 0 .028 1.27L8.98 5.053H.913C.408 5.052 0 5.477 0 6l.004.09c.044.48.433.857.908.857h8.07l-3.125 3.456c-.33.365-.313.98.03 1.332a.89.89 0 0 0 1.3-.034l4.56-5.05c.18-.183.25-.387.253-.644 0-.23-.11-.5-.254-.657L7.186.297A.89.89 0 0 0 5.9.254l-.07.072z" />
    </svg>
);

const IconArrowRight: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-arrow-right${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconArrowRight;
