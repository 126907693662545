import React, { FC, ReactElement, Fragment } from 'react';
import { useIntl, FormattedMessage, FormattedList } from 'react-intl';

import '../../assets/styles/ColorsSurvey.less';

import {
    ProgramTaskSurvey, SurveyResponses, SurveyAnswerColors, surveyAnswerColorsMessages,
    surveyAnswerColorsDescriptionMessages,
} from '../../store/api/apiTypes';

import SurveyMessages from './SurveyMessages';
import { Collapse } from 'antd';

const colors = new Map([
    [SurveyAnswerColors.red, '#ff0000'],
    [SurveyAnswerColors.green, '#5DA92A'],
    [SurveyAnswerColors.blue, '#1F89E9'],
    [SurveyAnswerColors.yellow, '#FFBC00'],
]);

interface ColorsSurveyResultsProps {
    survey?: ProgramTaskSurvey;
    responses?: SurveyResponses;
}

const ColorsSurveyResults: FC<ColorsSurveyResultsProps> = ({ responses }) => {
    const { formatMessage } = useIntl();
    const values = responses?.[Object.keys(responses || {})?.[0]];
    const top = [
        { color: SurveyAnswerColors.red, count: (values?.filter((val) => val.values?.includes(SurveyAnswerColors.red)) || []).length },
        { color: SurveyAnswerColors.green, count: (values?.filter((val) => val.values?.includes(SurveyAnswerColors.green)) || []).length },
        { color: SurveyAnswerColors.blue, count: (values?.filter((val) => val.values?.includes(SurveyAnswerColors.blue)) || []).length },
        { color: SurveyAnswerColors.yellow, count: (values?.filter((val) => val.values?.includes(SurveyAnswerColors.yellow)) || []).length },
    ].sort((a, b) => b.count - a.count);

    return (
        <div id="colors-results">
            <p id="colors-result-profile">
                <FormattedMessage
                    {...SurveyMessages.colorsResultsProfile}
                    values={{
                        br: <br />,
                        first: (
                            <>
                                <span className="color-first" style={{ color: colors.get(top[0].color) }}>
                                    <FormattedMessage {...surveyAnswerColorsMessages.get(top[0].color)} />
                                </span>
                                <span className="color-word-count">
                                    <FormattedMessage {...SurveyMessages.colorsWordCount} values={{ count: top[0].count }} />
                                </span>
                            </>
                        ),
                        list: top.slice(1).filter((color) => color.count > 0).length ? (
                            <FormattedList
                                type="conjunction"
                                value={top.slice(1).filter((color) => color.count > 0).map((item, index) => (
                                    <Fragment key={index}>
                                        <span style={{ color: colors.get(item.color) }}>
                                            <FormattedMessage {...surveyAnswerColorsMessages.get(item.color)} />
                                        </span>
                                        <span className="color-word-count">
                                            <FormattedMessage {...SurveyMessages.colorsWordCount} values={{ count: item.count }} />
                                        </span>
                                    </Fragment>
                                ))}
                            />
                        ) : undefined,
                    }}
                />
            </p>
            <Collapse className="survey-collapse" ghost accordion>
                {top.filter((color) => color.count > 0)?.map((item, index) => (
                    <Collapse.Panel
                        header={
                            formatMessage(SurveyMessages.colorsDescriptionTitle,
                                { color: formatMessage(surveyAnswerColorsMessages.get(item.color)!) },
                            )
                        }
                        key={index}
                    >
                        <FormattedMessage
                            {...surveyAnswerColorsDescriptionMessages.get(item.color)}
                            values={{
                                br: <br />,
                                p: (chunks: ReactElement) => <p>{chunks}</p>,
                                strong: (chunks: ReactElement) => <strong>{chunks}</strong>,
                                ul: (chunks: ReactElement) => <ul>{chunks}</ul>,
                                li: (chunks: ReactElement) => <li>{chunks}</li>,
                            }}
                        />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default ColorsSurveyResults;
