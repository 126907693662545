import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'job_offer_details.title',
        defaultMessage: 'Actions de networking',
        description: 'Job offer details page title',
    },
    backToList: {
        id: 'job_offer_details.back_to_list',
        defaultMessage: 'Retour aux annonces',
        description: 'Job offer details page back to job offers list link',
    },
    updateSuccess: {
        id: 'job_offer_details.update_success',
        defaultMessage: 'L\'annonce a été mise à jour avec succès',
        description: 'Job offer details page update success',
    },
    interviewRejected: {
        id: 'job_offer_details.interview.reject_button',
        defaultMessage: 'Réponse négative',
        description: 'Job offer details header refuse interview button',
    },
    updateSubmit: {
        id: 'job_offer_details.edit.update_submit',
        defaultMessage: 'Modifier l\'annonce',
        description: 'Job offer details edit form submit',
    },
    attachment: {
        id: 'job_offer_details.attachment',
        defaultMessage: 'Pièce jointe',
        description: 'Job offer details infos',
    },
    interviewScheduled: {
        id: 'job_offer_details.interview_scheduled',
        defaultMessage: 'Entretien proposé',
        description: 'Job offer details infos',
    },
});
