import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    ProgramDetailsPayload,
    ProgramListPayload,
    ProgramUpdatePayload,
    my as myApiCall,
    details as detailsApiCall,
    list as listApiCall,
    updateMy as updateMyApiCall,
} from '../api/programs';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, Program, SelectedProgram } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface ProgramsState {
    search: RequestState<ListResponse<Program>>;
    details: RequestState<Program>;
    my: RequestState<SelectedProgram>;
    updateMy: RequestState;
}

const initialState: ProgramsState = {
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    my: {
        loading: false,
    },
    updateMy: {
        loading: false,
    },
};

// Actions/Reducers

export const search = new EzeeAsyncAction<
    ProgramsState['search'],
    ProgramListPayload,
    ListResponse<Program>
>('programs/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    ProgramsState['details'],
    ProgramDetailsPayload,
    Program
>('programs/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const my = new EzeeAsyncAction<
    ProgramsState['my'],
    ProgramDetailsPayload,
    SelectedProgram
>('programs/my', initialState.my, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.my,
    }),
});

export const updateMy = new EzeeAsyncAction<
    ProgramsState['updateMy'],
    ProgramUpdatePayload
>('programs/updateMy', initialState.updateMy, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.updateMy,
    }),
});

// Reducer

export const programsReducer = combineReducers<ProgramsState>({
    search: search.reducer,
    details: details.reducer,
    my: my.reducer,
    updateMy: updateMy.reducer,
});

// Saga

export function* programsSaga() {
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(my.type.trigger, simpleAsyncSaga(myApiCall, my));
    yield takeLatest(updateMy.type.trigger, simpleAsyncSaga(updateMyApiCall, updateMy));
}

// Store helpers

export const getProgramsState = (state: MainReducerState) => state.programs;
export const getProgramDetailsState = (state: MainReducerState) => state.programs.details;
export const getMyProgramState = (state: MainReducerState) => {
    // set user's last extensions programs as his main program
    if (state.programs.my.data?.extensions &&  state.programs.my.data?.extensions.length > 0) {
        state.programs.my.data.program = state.programs.my.data.extensions[state.programs.my.data?.extensions.length - 1]?.program;
        state.programs.my.data.dates = state.programs.my.data.extensions[state.programs.my.data?.extensions.length - 1]?.dates;
    }
    return state.programs.my;
};
export const getMyProgramUpdateState = (state: MainReducerState) => state.programs.updateMy;
