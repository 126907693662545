import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { Empty, Pagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import '../../assets/styles/UserProgram.less';

import { TasksListResponseState } from '../../store/actions/tasks';
import { HomeListFilter } from '../../store/api/tasks';

import BasicList from '../../components/BasicList';
import ProgramTaskCard from '../../components/ProgramTaskCard';
import CardSkeleton from '../../components/CardSkeleton';
import { scrollToElement } from '../../helpers';
import HomeMessages from './HomeMessages';

const getEmptyListMessage = (currentFilter: HomeListFilter) => {
    switch (currentFilter) {
        case HomeListFilter.currentWeek: return HomeMessages.userProgramEmptyListCurrent;
        case HomeListFilter.nextWeek: return HomeMessages.userProgramEmptyListNext;
        case HomeListFilter.previousWeeks: return HomeMessages.userProgramEmptyListPrevious;

        default: return undefined;
    }
};

interface UserProgramTaskListProps {
    listState: TasksListResponseState;
    onPageSelect: PaginationProps['onChange'];
    programStartDate: DateTime;
    programLoading: boolean;
    currentFilter: HomeListFilter;
}

const UserProgramTaskList: FC<UserProgramTaskListProps> = ({ listState, onPageSelect, programStartDate, programLoading, currentFilter }) => {
    const listRef = useRef(null);
    const onChangePage: PaginationProps['onChange'] = (...args) => {
        scrollToElement(listRef.current, 100);

        if (typeof onPageSelect === 'function') {
            onPageSelect(...args);
        }
    };
    return (
        <>
            {!programLoading && !listState.loading && !!listState.data?.totalCount && (
                <>
                    <BasicList ref={listRef}>
                        {listState.data?.items.map((userProgramTask) => (
                            <li key={userProgramTask.id}>
                                <ProgramTaskCard data={userProgramTask} />
                            </li>
                        ))}
                    </BasicList>
                    <Pagination
                        current={(listState.data?.page || 0) + 1}
                        onChange={onChangePage}
                        pageSize={listState.data?.pageSize}
                        total={listState.data?.totalCount}
                        showSizeChanger={false}
                        hideOnSinglePage
                    />
                </>
            )}
            {!programLoading && !listState.loading && !listState.data?.totalCount && (
                <Empty
                    description={getEmptyListMessage(currentFilter) ?
                        <FormattedMessage {...getEmptyListMessage(currentFilter)} /> :
                        undefined
                    }
                />
            )}
            {(programLoading || listState.loading) && (
                <BasicList>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                    <li><CardSkeleton rows={3} /></li>
                </BasicList>
            )}
        </>
    );
};

export default UserProgramTaskList;
