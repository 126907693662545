import { NamePath } from 'antd/lib/form/interface';

export enum Language {
    fr = 'fr',
    en = 'en',
}

export const LanguageName = new Map([
    [Language.fr, 'Français'],
    [Language.en, 'English'],
]);

export interface FieldData {
    name: NamePath;
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

export enum PanelType {
    graph = 'graph',
    stat = 'stat',
    gauge = 'gauge',
    bargauge = 'bargauge',
    table = 'table',
    singlestat = 'singlestat',
    text = 'text',
    heatmap = 'heatmap',
    alertlist = 'alertlist',
    dashlist = 'dashlist',
    news = 'news',
    barChart = 'grafana-groupedbarchart-panel',
    pieChart = 'grafana-piechart-panel',
}

export interface Panel {
    title: string;
    description?: string;
    type: PanelType;
    targets: Target[];
    bars: boolean;
    dashes: boolean;
    lines: boolean;
    points: boolean;
    stack?: boolean;
    yaxes?: Array<{
        decimals: string | null;
        format: 'percent' | null;
        label: string | null;
        logBase: number;
        max: string | null;
        min: string | null;
        show: boolean;
    }>;
    pointradius: number;
    gridPos: {
        h: number;
        w: number;
        x: number;
        y: number;
    };
    transformations?: Array<{
        id: 'reduce';
        options: {
            reducers: Array<'diff' | 'mean' | 'max' | 'min' | 'last' | 'lastNotNull'>;
        };
    }>;
    fieldConfig: {
        defaults: {
            unit?: 'ms' | 's';
        };
    };
}

export type PanelParsedDescription = string | {
    name?: string;
    diff?: boolean;
    diffMin?: number;
    repeat?: boolean;
    userDetails?: boolean;
    userDetailsType?: 'licenses' | 'sessions' | 'badges';
    time?: string;
    i18nPrefix?: string;
};

export interface Target {
    expr: string;
    instant: boolean;
    refId: string;
    legendFormat?: string;
    interval?: string;
    hide?: boolean;
}

export interface GrafanaDashboard {
    panels: Panel[];
    title: string;
}

export enum QueryType {
    query = 'query',
    queryRange = 'query_range',
}

export interface RangeVectors {
    metric: { [key: string]: string };
    values: Array<[number, string]>;
}

export interface InstantVector {
    metric: { [key: string]: string };
    value: [number, string];
}

export type ScalarOrString = [number, string];

export interface QueryResponseData<T = any> {
    result: T;
    resultType: 'matrix' | 'vector' | 'scalar' | 'string';
}

export interface QueryResponse<T = any> {
    data: QueryResponseData<T>;
    status: 'success' | 'error';
    errorType?: string;
    error?: string;
    warnings?: string[];
}
