import React, { FC, useState, useEffect, ReactElement, useRef } from 'react';
import { Radio, Button, Checkbox, Collapse, Typography } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Form, { FormProps } from 'antd/lib/form';

import '../../assets/styles/TricamSurvey.less';

import {
    ProgramTaskSurveyQuestion, ProgramTaskSurvey, SurveyAnswerType, SurveyResponses,
    UserProgramTaskStatus, SurveyAnswerTricamLetters, SupportedLanguage, ProgramSubTask, Answer,
} from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import ProgramTaskDetailsMessages from '../../pages/programTaskDetails/ProgramTaskDetailsMessages';
import TricamSurveyResults from './TricamSurveyResults';
import constants from '../../config/constants';
import { stripUndefinedKeysFromObject } from '../../helpers';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface TricamSurveyProps {
    data?: ProgramTaskSurvey[];
    language?: SupportedLanguage;
    responses?: SurveyResponses<SurveyAnswerTricamLetters>;
    loading?: boolean;
    onSubmit: FormProps['onFinish'];
    subTaskStatus?: UserProgramTaskStatus;
    partialResults?: ProgramSubTask['tricamWithPartialResults'];
}

const TricamSurvey: FC<TricamSurveyProps> = ({ data, responses, language, loading, onSubmit, subTaskStatus, partialResults }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const panelRef = useRef<HTMLDivElement>(null);
    const [, forceUpdate] = useState(false);
    const onSubmitForm: FormProps['onFinish'] = (values) => {
        const { consent, ...restValues } = values;
        const filteredValues = stripUndefinedKeysFromObject(restValues);
        const result = Object.keys(filteredValues).reduce<Record<string, Answer | Answer[]>>((acc, questionKey) => ({
            ...acc,
            [questionKey]: Array.isArray(filteredValues[questionKey]) ?
                filteredValues[questionKey].map((val: string) => ({
                    label: val.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)?.[0],
                    values: val.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)?.slice(1),
                })) :
                [{
                    label: filteredValues[questionKey]?.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)?.[0],
                    values: filteredValues[questionKey]?.split(constants.SURVEY_LABEL_ANSWER_SEPARATOR)?.slice(1),
                }],
        }), {});

        onSubmit?.(result);
    };
    const renderSurveyItem = (question: ProgramTaskSurveyQuestion) => {
        switch (question.type) {
            case SurveyAnswerType.boolean:
                return (
                    <Radio.Group>
                        <Radio
                            style={radioStyle}
                            value={`true${constants.SURVEY_LABEL_ANSWER_SEPARATOR}${question.answers?.[0]?.values?.join(constants.SURVEY_LABEL_ANSWER_SEPARATOR)}`}
                        >
                            <FormattedMessage {...genericMessages.yes} />
                        </Radio>
                        <Radio
                            style={radioStyle}
                            value={`false${constants.SURVEY_LABEL_ANSWER_SEPARATOR}false`}
                        >
                            <FormattedMessage {...genericMessages.no} />
                        </Radio>
                    </Radio.Group>
                );

            case SurveyAnswerType.list:
                return (
                    <Radio.Group>
                        {question.answers.map((answer, index) => (
                            <Radio
                                style={radioStyle}
                                value={`${answer.label}${constants.SURVEY_LABEL_ANSWER_SEPARATOR}${(answer.values ?? []).join(constants.SURVEY_LABEL_ANSWER_SEPARATOR)}`}
                                key={answer.values?.join('') ?? index}
                            >
                                {answer.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                );

            case SurveyAnswerType.multiValue:
                return (
                    <Checkbox.Group className="checkbox-group-vertical">
                        {question.answers.map((answer, index) => (
                            <Checkbox
                                style={radioStyle}
                                value={`${answer.label}${constants.SURVEY_LABEL_ANSWER_SEPARATOR}${(answer.values ?? []).join(constants.SURVEY_LABEL_ANSWER_SEPARATOR)}`}
                                key={answer.values?.join('') ?? index}
                            >
                                {answer.label}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                );

            default:
                return null;
        }
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    if (subTaskStatus === UserProgramTaskStatus.validated) {
        return (
            <TricamSurveyResults
                responses={responses}
                language={language}
                partialResults={partialResults}
            />
        );
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={responses}
            onFinish={onSubmitForm}
            className="tricam-form"
            scrollToFirstError
        >
            <Collapse
                className="survey-collapse"
                // tslint:disable-next-line: jsx-no-lambda
                onChange={() => {
                    window.setTimeout(() => {
                        if (panelRef?.current) {
                            window.scrollTo({
                                behavior: 'smooth',
                                top: (panelRef?.current?.getBoundingClientRect().top ?? 0) + window.pageYOffset - 50,
                            });
                        }
                    }, 300);
                }}
                ghost
                accordion
            >
                {data?.map((survey, surveyIndex) => (
                    <Collapse.Panel
                        header={survey.title || formatMessage(ProgramTaskDetailsMessages.surveyTricamDefaultTitle, { index: surveyIndex + 1 })}
                        key={surveyIndex}
                    >
                        <div ref={panelRef}>
                            {survey.description && (
                                <Typography.Paragraph style={{ lineHeight: '1.1875rem' }}>{survey.description}</Typography.Paragraph>
                            )}
                            {survey?.questions?.map((surveyQuestion, questionIndex) => (
                                <Form.Item
                                    label={surveyIndex !== 3 &&
                                        <strong style={{ fontSize: '1rem' }}>{`${questionIndex + 1}. ${surveyQuestion.label}`}</strong>
                                    }
                                    name={surveyQuestion.id}
                                    key={surveyQuestion.id}
                                >
                                    {renderSurveyItem(surveyQuestion)}
                                </Form.Item>
                            ))}
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
            <Form.Item
                name="consent"
                valuePropName="checked"
                rules={[{
                    required: true,
                    message: formatMessage(formMessages.requiredField),
                    validator: (_, value) => !!value ? Promise.resolve() : Promise.reject(),
                }]}
                style={{ marginTop: '1.5rem' }}
            >
                <Checkbox>
                    <FormattedMessage
                        {...formMessages.dataConsent}
                        values={{
                            link: (...chunks: ReactElement[]) => (
                                <Link to={getRoute(RoutePathName.privacyPolicy)} target="_blank">
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            !form.getFieldValue('consent')
                            ||
                            (
                                form &&
                                (
                                    !form.isFieldsTouched() ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                )
                            )
                        }
                        loading={loading}
                    >
                        <FormattedMessage
                            {...ProgramTaskDetailsMessages.surveyTricamValidationSubmit}
                        />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default TricamSurvey;
