import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Form, Row, Col, Input } from 'antd';
import { FormProps } from 'antd/lib/form';

import { AppointmentType, actionMeetingTypeMessages } from '../../store/api/apiTypes';

import formMessages from '../../locale/formMessages';
import Select from '../../components/Select';
import NetworkingActionCreateMessages from './NetworkingActionCreateMessages';
import { NetworkingActionCreateValues } from './NetworkingActionCreate';
import PhoneInput from '../../components/PhoneInput';
import DatePicker from '../../components/DatePicker';
import TimePicker from '../../components/TimePicker';

interface NetworkingActionCreateWhenProps {
    initialValues?: NetworkingActionCreateValues;
    onSubmit: (values: NetworkingActionCreateValues) => void;
}

const NetworkingActionCreateWhen: FC<NetworkingActionCreateWhenProps> = ({ initialValues, onSubmit }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps<NetworkingActionCreateValues>['onFinish'] = (values) => {
        const { date, time, ...rest } = values;

        onSubmit({
            ...rest,
            date: date?.
                set('hour', time?.get('hour') || 0).
                set('minute', time?.get('minute') || 0).
                set('second', 0).
                set('millisecond', 0),
        });
    };
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return (
        <Form<NetworkingActionCreateValues>
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFormValidSubmit}
            initialValues={initialValues}
        >
            <Card
                title={formatMessage(NetworkingActionCreateMessages.whenTitle)}
                className="form-card"
            >
                <Form.Item
                    label={formatMessage(NetworkingActionCreateMessages.whenTypeLabel)}
                    name="type"
                    rules={[requiredRule]}
                >
                    <Select
                        placeholder={formatMessage(NetworkingActionCreateMessages.whenTypePlaceholder)}
                    >
                        {Object.values(AppointmentType).map((type) => (
                            <Select.Option value={type} key={type}>
                                <FormattedMessage {...actionMeetingTypeMessages.get(type)} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item dependencies={['type']} noStyle>
                    {({ getFieldValue }) => (
                        <>
                            {getFieldValue('type') === AppointmentType.real && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingAddressLabel)}
                                    name={['address', 'street']}
                                >
                                    <Input placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingAddressPlaceholder)} />
                                </Form.Item>
                            )}
                            {getFieldValue('type') === AppointmentType.phone && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingPhoneLabel)}
                                    name="phone"
                                >
                                    <PhoneInput placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingPhonePlaceholder)} />
                                </Form.Item>
                            )}
                            {getFieldValue('type') === AppointmentType.video && (
                                <Form.Item
                                    label={formatMessage(NetworkingActionCreateMessages.whenMeetingVideoUrlLabel)}
                                    name="url"
                                >
                                    <Input placeholder={formatMessage(NetworkingActionCreateMessages.whenMeetingVideoUrlPlaceholder)} />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whenDateLabel)}
                            name="date"
                            rules={[requiredRule]}
                        >
                            <DatePicker
                                placeholder={formatMessage(NetworkingActionCreateMessages.whenDatePlaceholder)}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={formatMessage(NetworkingActionCreateMessages.whenTimeLabel)}
                            name="time"
                            rules={[requiredRule]}
                        >
                            <TimePicker
                                placeholder={formatMessage(NetworkingActionCreateMessages.whenTimePlaceholder)}
                                showSecond={false}
                                minuteStep={5}
                                // tslint:disable-next-line: jsx-no-lambda
                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 23]}
                                format="HH:mm"
                                hideDisabledOptions
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <Form.Item shouldUpdate={true} className="mobile-fixed-submit">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={
                            !form.isFieldsTouched() ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                        <FormattedMessage {...NetworkingActionCreateMessages.whenSubmit} />
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default NetworkingActionCreateWhen;
