export default Object.freeze({
    PAGE_SIZE: 25,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '/api',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Mon Coach Mobilité',
        DEFAULT_TITLE: 'Mon Coach Mobilité',
        DEFAULT_DESCRIPTION: 'Mon coach Mobilité est un cabinet de conseil RH spécialisé dans la mobilité. Que ce soit dans le cadre de plans sociaux (PSE - RCC - PVD), de mobilité interne ou d\'out/inplacements. Nous combinons un accompagnement digital permanent et un coaching humain périodique.',
    }),
    ENABLE_DEBUG_LOGS: process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: 'moncoachmobilite_front', // <app>_<service>
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
    VERSION: '1.4.0',
    SURVEY_LABEL_ANSWER_SEPARATOR: '-$-',
});
