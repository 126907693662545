import { defineMessages } from 'react-intl';

export default defineMessages({
    privacyCookiesTitle: {
        id: 'privacy.cookies.title',
        defaultMessage: 'Choix des cookies',
        description: 'Privacy > Cookies > title',
    },
    privacyCookiesOptionalTitle: {
        id: 'privacy.cookies.optional.title',
        defaultMessage: 'Optionnels',
        description: 'Privacy > Cookies > optional cookies title',
    },
    privacyCookiesGoogleAnalytics: {
        id: 'privacy.cookies.google_analytics.description',
        defaultMessage: 'Cookies de Google Analytics destinés à réaliser des statistiques de navigation et de fréquentation',
        description: 'Privacy > Cookies > Optional cookies > Google Analytics cookies description',
    },
    privacyCookiesMandatoryTitle: {
        id: 'privacy.cookies.mandatory.title',
        defaultMessage: 'Obligatoires',
        description: 'Privacy > Cookies > mandatory cookies title',
    },
    privacyCookiesChoiceCookie: {
        id: 'privacy.cookies.choice_cookie.description',
        defaultMessage: 'Cookie permettant de déterminer si l\'utilisateur a accepté ou refusé un ou plusieurs cookies',
        description: 'Privacy > Cookies > Mandatory cookies > Cookie that stores the choice',
    },
    privacyCookiesSession: {
        id: 'privacy.cookies.session_cookie.description',
        defaultMessage: 'Cookie de session permettant l\'utilisation du service',
        description: 'Privacy > Cookies > Mandatory cookies > Cookie that stores the session',
    },
    acceptAll: {
        id: 'cookies.accept_all',
        defaultMessage: 'Tout accepter',
        description: 'Privacy > Cookies > accept all cookies button',
    },
    rejectAll: {
        id: 'cookies.reject_all',
        defaultMessage: 'Tout refuser',
        description: 'Privacy > Cookies > reject all cookies button',
    },
});
