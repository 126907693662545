import React, { FC } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { Typography, Divider, Row, Col, Button, Spin, Space, Alert } from 'antd';
import ICalendarLink from 'react-icalendar-link';
import { useHistory } from 'react-router-dom';

import '../../assets/styles/NetworkingActionDetailsCard.less';

import { Appointment, AppointmentStatus, networkingActionTypeMessages, NetworkingActionType, InterviewStatus, AppointmentType } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { getAppointmentsUpdateState, update, AppointmentsState } from '../../store/actions/appointments';

import CreateActionMessages from '../networkingActionCreate/NetworkingActionCreateMessages';
import genericMessages from '../../locale/genericMessages';
import { IconNetworkingActions, IconCalendar, IconContacts, IconCheckCircle, IconCrossCircle, IconMore } from '../../components/icons';
import ButtonLink from '../../components/ButtonLink';
import BasicList from '../../components/BasicList';
import { getRoute, RoutePathName } from '../../routes';
import { getAppointmentTypeIcon, getAppointmentTypeLabel, isAppointmentCancelled, isAppointmentValidated } from '../../helpers/data';
import { classNames, getFullName, formatAddress } from '../../helpers';
import { useIsMobile } from '../../hooks';
import NetworkingActionDetailsMessages from './NetworkingActionDetailsMessages';
import NetworkingActionMenu from '../../components/NetworkingActionMenu';

interface NetworkingActionDetailsCardProps {
    appointment: Appointment;
    appointmentUpdateState: AppointmentsState['update'];
    updateAppointment: typeof update.trigger;
}

const NetworkingActionDetailsCard: FC<NetworkingActionDetailsCardProps> = ({
    appointment, appointmentUpdateState, updateAppointment,
}) => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const onClickInterview = (status: InterviewStatus) => {
        updateAppointment({
            id: appointment.id,
            appointment: {
                interviewStatus: status,
            },
        });
    };
    const onClickCreateInterview = () => {
        history.push(getRoute(RoutePathName.networkingActionCreate), {
            values: {
                firstAppointment: appointment.firstAppointment?.id || appointment.id,
                parent: appointment.id,
                parentTitle: appointment.title,
                actionType: NetworkingActionType.jobInterview,
            },
            previous: getRoute(RoutePathName.networkingAction, { networkingActionId: appointment.id }),
        });
    };

    return (
        <div
            className={classNames(
                'networking-action-details-card',
                isAppointmentValidated(appointment) && 'is-complete',
            )}
        >
            {isAppointmentValidated(appointment) && (
                <div className="networking-action-details-card-top is-complete">
                    <div className="flex flex-align-center">
                        <IconCheckCircle /> <FormattedMessage {...NetworkingActionDetailsMessages.validated} />
                    </div>
                    <FormattedDate value={appointment.validatedAt} />
                </div>
            )}
            {isAppointmentCancelled(appointment) && (
                <div className="networking-action-details-card-top is-cancelled">
                    <div className="flex flex-align-center">
                        <IconCrossCircle /> <FormattedMessage {...NetworkingActionDetailsMessages.cancelled} />
                    </div>
                    <FormattedDate value={appointment.cancelledAt} />
                </div>
            )}
            <header className="flex-between">
                <Typography.Title level={1} style={{marginBottom: 0}}>
                    {appointment.title}
                </Typography.Title>
                <Space size="middle">
                    {!isAppointmentCancelled(appointment) && !isAppointmentValidated(appointment) && (
                        <ButtonLink
                            to={getRoute(RoutePathName.networkingActionEdit, { networkingActionId: appointment.id })}
                            ghost
                        >
                            <FormattedMessage {...genericMessages.edit} />
                        </ButtonLink>
                    )}
                    <NetworkingActionMenu appointment={appointment}>
                        <Button
                            icon={<IconMore />}
                            shape="circle"
                            ghost
                        />
                    </NetworkingActionMenu>
                </Space>
            </header>
            <Divider />
            <BasicList className="networking-action-details-card-infos" grid={!isMobile}>
                <li>
                    <Row gutter={16}>
                        <Col xs={8}>
                            <IconNetworkingActions />
                            <FormattedMessage {...NetworkingActionDetailsMessages.type} />
                        </Col>
                        <Col xs={16}>
                            <FormattedMessage {...networkingActionTypeMessages.get(appointment.actionType)} />
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row gutter={16}>
                        <Col xs={8}>
                            <IconCalendar />
                            <FormattedMessage {...NetworkingActionDetailsMessages.date} />
                        </Col>
                        <Col xs={16}>
                            <p>
                                <FormattedMessage
                                    {...genericMessages.dateAndTime}
                                    values={{
                                        date: (
                                            <FormattedDate
                                                value={appointment.date}
                                                month="2-digit"
                                                day="numeric"
                                                year="numeric"
                                            />
                                        ),
                                        time: <FormattedTime value={appointment.date} />,
                                    }}
                                /><br />
                                <span className="calendar-link">
                                    <ICalendarLink
                                        event={{
                                            title: appointment.title || '',
                                            description: appointment.title || '',
                                            startTime: appointment.date || '',
                                            endTime: appointment.date || '',
                                            location: formatAddress(appointment.address),
                                        }}
                                    >
                                        <FormattedMessage {...CreateActionMessages.successModalCompleteDownloadCal} />
                                    </ICalendarLink>
                                </span>
                            </p>
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row gutter={16}>
                        <Col xs={8}>
                            <IconContacts />
                            <FormattedMessage {...CreateActionMessages.whoContactLabel} />
                        </Col>
                        <Col xs={16}>
                            <p>
                                {getFullName(appointment.contact?.firstName, appointment.contact?.lastName)}<br />
                                <small>{appointment.contact?.job?.jobTitle}</small>
                            </p>
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row gutter={16}>
                        <Col xs={8}>
                            {getAppointmentTypeIcon(appointment.type)}
                            <FormattedMessage {...getAppointmentTypeLabel(appointment.type)} />
                        </Col>
                        <Col xs={16}>
                            {appointment.type === AppointmentType.phone && (
                                <a href={`tel:${appointment.phone}`}>{appointment.phone}</a>
                            )}
                            {appointment.type === AppointmentType.real && (
                                <Typography.Paragraph ellipsis={{ rows: 1 }}>
                                    {formatAddress(appointment.address)}
                                </Typography.Paragraph>
                            )}
                            {appointment.type === AppointmentType.video && (
                                <Typography.Paragraph ellipsis={{ rows: 1 }}>
                                    <a
                                        href={appointment.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {appointment.url}
                                    </a>
                                </Typography.Paragraph>
                            )}
                        </Col>
                    </Row>
                </li>
            </BasicList>
            {appointment.status === AppointmentStatus.validated &&
            appointment.actionType === NetworkingActionType.jobInterview &&
            !appointment.interviewStatus && (
                <Spin spinning={appointmentUpdateState.loading}>
                    <Divider />
                    <div className="flex-lg-between">
                        <Button
                            onClick={onClickInterview.bind(null, InterviewStatus.refused)}
                            danger
                            ghost
                        >
                            <FormattedMessage {...NetworkingActionDetailsMessages.interviewRejected} />
                        </Button>
                        <Space size="middle">
                            <Button
                                onClick={onClickCreateInterview}
                                type="primary"
                                ghost
                            >
                                <FormattedMessage {...NetworkingActionDetailsMessages.createInterview} />
                            </Button>
                            <Button
                                onClick={onClickInterview.bind(null, InterviewStatus.accepted)}
                                type="primary"
                                className="btn-success"
                            >
                                <FormattedMessage {...NetworkingActionDetailsMessages.interviewAccepted} />
                            </Button>
                        </Space>
                    </div>
                </Spin>
            )}
            {appointment.interviewStatus && (
                <>
                    <Divider />
                    <Alert
                        type={appointment.interviewStatus === InterviewStatus.accepted ? 'success' : 'error'}
                        message={(
                            <div className="flex-lg-between">
                                <div className="flex flex-align-center">
                                    {appointment.interviewStatus === InterviewStatus.accepted ? (
                                        <>
                                            <IconCheckCircle /> <FormattedMessage {...NetworkingActionDetailsMessages.interviewAccepted} />
                                        </>
                                    ) : (
                                        <>
                                            <IconCrossCircle /> <FormattedMessage {...NetworkingActionDetailsMessages.interviewRejected} />
                                        </>
                                    )}
                                </div>
                                <FormattedDate value={appointment?.interviewStatusDate} />
                            </div>
                        )}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    appointmentUpdateState: getAppointmentsUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        updateAppointment: update.trigger,
    },
)(NetworkingActionDetailsCard);
