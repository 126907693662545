import React, { FC } from 'react';
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';

import { getUser } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { User } from '../store/api/apiTypes';

interface ThemeProps {
    user?: User;
}

const Theme: FC<ThemeProps> = ({ user }) => {
    const theme = user?.organization?.theme;

    return theme ? (
        <style>{`
            ${theme.primary ?
                `html {
                    --antd-wave-shadow-color: ${theme.primary};
                }
                .ant-badge-count,
                ::selection {
                    background: ${theme.primary};
                }

                .networking-action-card,
                .program-task-card {
                    box-shadow: 0 2px 6px 0 ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 8 }).setAlpha(0.16).toString()};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }

                .networking-action-card.is-complete,
                .program-task-card.is-complete {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 98 }).toHexString()};
                    border-color: ${theme.primary};
                }

                .sub-task {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 98 }).toHexString()};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 100, l: 90 }).toHexString()};
                }

                .sub-task.is-complete {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 69, l: 95 }).toHexString()};
                    border-color: ${theme.primary};
                }

                .sub-task.is-complete .ant-tag, .sub-task.is-complete .ant-tag.tag-primary,
                .program-task-card.is-complete .ant-tag, .program-task-card.is-complete .ant-tag.tag-primary {
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 84 }).toHexString()};
                }

                .label,
                #reporting-filters .icon-search,
                body .ant-picker-suffix,
                .job-offer-details-card-infos .anticon,
                .job-offer-card .job-offer-card-company .anticon,
                body .ant-pagination .ant-pagination-item a,
                .filters .filters-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
                .filters .filters-right .filters-select > span,
                .networking-action-card .networking-action-card-contact .icon-contacts,
                .networking-action-card .networking-action-card-contact .networking-action-card-contact-company,
                .networking-action-card .networking-action-card-type,
                #main-footer, #main-footer a,
                .back-link,
                .gauge .gauge-unit,
                #client-user-profil .description-header .user-card .icon-row .anticon,
                #client-user-profil .cta-back,
                body .ant-descriptions-bordered .ant-descriptions-item-content label,
                #organization .ant-descriptions-item-content h2,
                .filters .basic-list .ant-btn,
                .ant-tag, .ant-tag a, .ant-tag a:hover {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                .page-table .ant-table-thead > tr > th {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 95 }).toHexString()};
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                .page-table .ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 93 }).toHexString()};
                }

                .page-table .ant-table-filter-trigger,
                .page-table .ant-table-column-sorter {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 83 }).toHexString()};
                }

                #user-stats .user-stats-item-wrapper .anticon-info-circle {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 83 }).toHexString()};
                }

                body .ant-pagination .ant-pagination-item-active a {
                    color: #fff;
                }

                #main-footer a:hover,
                #main-footer a:focus {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 45 }).toHexString()};
                }

                .ant-typography.ant-typography-secondary {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 83 }).toHexString()};
                }

                .job-offer-card .ant-divider,
                .networking-action-card .ant-divider {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }

                .ant-layout-footer {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 95 }).toHexString()};
                }

                body .ant-select-multiple .ant-select-selection-item {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 95 }).toHexString()};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                body .ant-tag.ant-tag-error {
                    color: #E45A61;
                }

                .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 97 }).toHexString()};
                }

                #home-user-program .home-user-program-divider {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }

                .ant-input-affix-wrapper,
                .table-actions-row .ant-input-search .ant-input-group-addon,
                .ant-select-multiple .ant-select-selector,
                .ant-input,
                body .ant-tag {
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }

                .ant-input:focus, .ant-input-focused {
                    box-shadow: 0 0 0 2px ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 64 }).setAlpha(0.2).toString()};
                }

                .ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 83 }).toHexString()};
                }

                .ant-table-tbody > tr.ant-table-row-selected > td {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 97 }).toHexString()};
                }

                .ant-table-tbody > tr.ant-table-row-selected:hover > td {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 95 }).toHexString()};
                }

                .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 97 }).toHexString()};
                }

                .ant-pagination-item-active,
                .program-task-card.is-complete::before,
                .program-task-details-card > .program-task-details-card-top.is-complete,
                .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
                #create-action-steps-progress,
                .page-table .ant-avatar,
                .ant-checkbox-indeterminate .ant-checkbox-inner::after,
                #client-user-profil .description-header .user-card .ant-avatar,
                .ant-picker-range .ant-picker-active-bar,
                #main-menu-button::after, #main-menu-button::before, #main-menu-button div,
                .underline::after {
                    background-color: ${theme.primary};
                }

                .ant-input-affix-wrapper,
                .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
                .ant-input-affix-wrapper:hover,
                .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
                .ant-checkbox-checked::after {
                    border-color: ${theme.primary};
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                    border-color: ${theme.primary};
                    background-color: ${theme.primary};
                }

                .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                    border-color: ${theme.primary};
                }

                a,
                body .ant-pagination .ant-pagination-prev .ant-pagination-item-link, body .ant-pagination .ant-pagination-next .ant-pagination-item-link,
                .ant-picker-today-btn,
                .ant-picker-header-view button:hover,
                .ant-card.form-card .ant-card-head-title,
                #create-action-step-values p.step.is-active,
                .description-header .heading-wrapper .ant-typography .icon-referred,
                #fullscreen-layout-icon,
                .job-offer-card .job-offer-card-title a:focus, .job-offer-card .job-offer-card-title a:hover,
                .contact-list-card .icon-referred,
                .contact-list-card a:focus, .contact-list-card a:hover,
                .document-list-card .document-card-title a:focus, .document-list-card .document-card-title a:hover,
                .filters .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
                .ant-btn-link,
                .truncated-text .ant-btn-link,
                .program-card h2.ant-typography, .program-card .ant-typography h2,
                .program-task-card .program-task-card-title a:focus, .program-task-card .program-task-card-title a:hover,
                .networking-action-card .networking-action-card-title a:focus, .networking-action-card .networking-action-card-title a:hover,
                a.ant-typography, .ant-typography a,
                .filters .basic-list .ant-btn.is-active,
                .filters .basic-list .ant-btn:hover, .filters .basic-list .ant-btn:focus,
                .ant-menu-item a:hover,
                .dashboard-stat-wrapper,
                #header-menu li.ant-menu-item-selected > a .anticon,
                #client-menu .ant-menu-item.ant-menu-item-active .anticon, #main-menu .ant-menu-item.ant-menu-item-active .anticon, #client-menu .ant-menu-submenu-title.ant-menu-item-active .anticon, #main-menu .ant-menu-submenu-title.ant-menu-item-active .anticon, #client-menu .ant-menu-item.ant-menu-item-selected .anticon, #main-menu .ant-menu-item.ant-menu-item-selected .anticon, #client-menu .ant-menu-submenu-title.ant-menu-item-selected .anticon, #main-menu .ant-menu-submenu-title.ant-menu-item-selected .anticon,
                .ant-menu-item-selected a, .ant-menu-item-selected a:hover,
                #header-menu li.ant-menu-item-selected > a,
                .sub-task.is-complete .icon-check-circle,
                .filters .ant-select-arrow,
                .gauge .gauge-total,
                .text-primary {
                    color: ${theme.primary};
                }

                .ant-select-arrow .anticon {
                    color: ${theme.primary}!important;
                }

                #header-menu li > a .anticon,
                #main-menu .ant-menu-item .anticon, #main-menu .ant-menu-submenu-title .anticon,
                #client-menu .ant-menu-item .anticon, #client-menu .ant-menu-submenu-title .anticon {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                .filters .filters-search-wrapper > .ant-btn {
                    background-color: ${tinycolor(theme.primary).toHexString()};
                    border-color: ${tinycolor(theme.primary).toHexString()};
                }

                .ant-btn.ant-btn-primary {
                    background-color: ${theme.primary};
                    border-color: ${theme.primary};
                }
                .ant-btn.ant-btn-primary:focus,
                .ant-btn.ant-btn-primary:hover {
                    background-color: ${tinycolor(theme.primary).lighten(10).toHexString()};
                    border-color: ${tinycolor(theme.primary).lighten(10).toHexString()};
                    color: #fff;
                }

                body .ant-btn-primary[disabled], body .ant-btn-primary[disabled]:hover, body .ant-btn-primary[disabled]:focus, body .ant-btn-primary[disabled]:active {
                    background-color: ${tinycolor(theme.primary).lighten(20).desaturate().toHexString()};
                    border-color: ${tinycolor(theme.primary).lighten(20).desaturate().toHexString()};
                }

                .ant-btn-background-ghost.ant-btn-primary {
                    color: ${theme.primary};
                    border-color: ${theme.primary};
                }
                .ant-btn-background-ghost.ant-btn-primary:focus,
                .ant-btn-background-ghost.ant-btn-primary:hover {
                    background-color: ${tinycolor(theme.primary).lighten(10).toHexString()};
                    border-color: ${tinycolor(theme.primary).lighten(10).toHexString()};
                }

                .ant-btn:hover, .ant-btn:focus {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 75 }).toHexString()};
                }

                .ant-select-multiple .ant-select-selector,
                .ant-picker {
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }

                .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 70 }).toHexString()};
                }

                .ant-picker:hover, .ant-picker-focused {
                    border-color: ${theme.primary};
                }

                .ant-btn-background-ghost {
                    color: ${theme.primary};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 90 }).toHexString()};
                }
                .ant-btn-background-ghost:focus,
                .ant-btn-background-ghost:hover {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 75 }).toHexString()};
                    border-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), l: 75 }).toHexString()};
                }

                .ant-input {
                    border-color: ${tinycolor(theme.primary).lighten(20).toHexString()};
                }

                .ant-input:focus,
                .ant-input:hover {
                    border-color: ${theme.primary};
                }
                a.ant-typography:hover,
                .ant-typography a:focus,
                a.ant-typography:focus,
                .ant-typography a:hover,
                a:hover,
                a:focus,
                a.text-primary:hover,
                a.text-primary:focus {
                    color: ${tinycolor(theme.primary).darken(20).toHexString()}
                }
                body .ant-descriptions-bordered .ant-descriptions-item-label,
                a,
                a.ant-typography:active,
                .ant-typography a:active,
                a.text-primary:active {
                    color: ${tinycolor(theme.primary).darken(20).toHexString()}
                }

                .calendar-list-card .calendar-list-card-date {
                    background-color: ${tinycolor(theme.primary).darken(20).toHexString()};
                }

                .dashboard-grid-panel-tooltip {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 100, l: 83 }).toHexString()};
                }

                .ant-modal-close {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 100, l: 83 }).toHexString()};
                }

                .tutorial-modal .ant-carousel .slick-dots li button {
                    background: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 100, l: 95 }).toHexString()};
                }

                .tutorial-modal .ant-carousel .slick-dots li.slick-active button {
                    background: ${tinycolor(theme.primary)};
                }

                .tutorial-modal .ant-carousel .slick-prev,
                .tutorial-modal .ant-carousel .slick-next {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                #notifications-menu .notification-content-date {
                    color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }

                #notifications-menu .ant-avatar {
                    background-color: ${tinycolor({ ...tinycolor(theme.primary).toHsl(), s: 27, l: 58 }).toHexString()};
                }` : ''}

            ${theme.primary && theme.background ?
                `body .ant-tag.tag-primary {
                    color: ${theme.primary};
                    background-color: ${theme.background};
                    border-color: ${theme.background};
                }` : ''}

            ${theme.secondary ?
                `.networking-action-details-block-card h2.ant-typography, .networking-action-details-block-card .ant-typography h2 {
                    color: ${theme.secondary};
                }
                .ant-form-item-explain {
                    color: ${theme.secondary};
                }` : ''}

            ${theme.background ?
                `#fullscreen-layout {
                    background-color: ${theme.background};
                }
                body .ant-descriptions-bordered .ant-descriptions-item-label,
                .filters .basic-list .ant-btn.is-active::after,
                #user-stats .user-stats-desktop-wrapper {
                    background-color: ${tinycolor({ ...tinycolor(theme.background).toHsl(), s: 100, l: 98 }).toHexString()};
                }
                #user-stats .user-stats-desktop-wrapper {
                    border-color: ${tinycolor({ ...tinycolor(theme.background).toHsl(), s: 54, l: 93 }).toHexString()};
                }` : ''}
        `}</style>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Theme);
