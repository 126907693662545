import React, { FC, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Button } from 'antd';

import '../assets/styles/CookieBanner.less';

import { MainReducerState } from '../store/reducers';
import { cookies, getCookiesState, CookiesState } from '../store/actions/cookies';

import { getRoute, RoutePathName } from '../routes';
import { acceptAllCookies, refuseAllCookies, analytics, addGoogleTagManager } from '../helpers/cookies';
import { hasOwnProp } from '../helpers';

interface CookieBannerProps {
    cookiesState: CookiesState;
    accept: typeof cookies.actions.acceptAll;
    refuse: typeof cookies.actions.refuse;
}

const CookieBanner: FC<CookieBannerProps> = ({ cookiesState, accept, refuse }) => {
    const [_, setCookie, removeCookie] = useCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const location = useLocation();
    const onClickAccept = () => {
        acceptAllCookies(setCookie);
        accept();
    };
    const onClickRefuse = () => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
    };

    useEffect(() => {
        if (cookiesState.gtag) {
            if (hasOwnProp(window, 'dataLayer')) {
                analytics.pageView(location.pathname);
            } else {
                addGoogleTagManager();
            }
        }
    }, [location.pathname, cookiesState.gtag]);

    return !cookiesState.hasMadeChoice ? (
        <div id="cookie-banner">
            <p>
                {'En poursuivant votre navigation ou en cliquant sur "Accepter" vous acceptez l\'utilisation de cookies destinés à réaliser des statistiques de navigation et de fréquentation. Pour en savoir plus ou modifier les paramètres des cookies vous pouvez consulter à tout moment notre '}
                <Link to={getRoute(RoutePathName.privacyPolicy)}>
                    Politique de confidentialité et Protection de la vie privée
                </Link>
                {'.'}
            </p>
            <div>
                <Button
                    type="ghost"
                    onClick={onClickRefuse}
                >
                    Refuser
                </Button>
                <Button
                    type="primary"
                    onClick={onClickAccept}
                >
                    Accepter
                </Button>
            </div>
        </div>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    cookiesState: getCookiesState(state),
});

export default connect(
    mapStateToProps,
    {
        accept: cookies.actions.acceptAll,
        refuse: cookies.actions.refuse,
    },
)(CookieBanner);
