import React, { FC } from 'react';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import IconArrowRight from './IconArrowRight';

const IconArrowLeft: FC<IconComponentProps> = (props) => (
    <IconArrowRight
        {...props}
        className={`icon-arrow-left${props.className ? ` ${props.className}` : ''}`}
        style={{
            transform: 'rotate(180deg)',
        }}
    />
);

export default IconArrowLeft;
