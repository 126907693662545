import React, { FC, useState, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Typography } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { MainReducerState } from '../../store/reducers';
import { AuthState, getAuthState } from '../../store/actions/auth';

import AccountMessages from './AccountMessages';
import { getRoute, RoutePathName } from '../../routes';
import AccountSuspensionModal from './AccountSuspensionModal';
import SuspensionAlert from '../../components/SuspensionAlert';
import { getActiveSuspension } from '../../helpers/data';

interface AccountSuspensionFormProps {
    authState: AuthState;
}

const AccountSuspensionForm: FC<AccountSuspensionFormProps> = ({ authState }) => {
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);

    return !!authState.user?.selectedProgram?.program?.maxSuspension ? (
        <>
            <Typography.Title level={2} className="h1">
                <FormattedMessage {...AccountMessages.suspensionTitle} />
            </Typography.Title>
            <Card>
                <Typography.Title level={3} className="secondary-title">
                    <FormattedMessage
                        {...AccountMessages.suspensionIntro}
                        values={{ count: authState.user?.selectedProgram?.program?.maxSuspension ?? 0 }}
                    />
                </Typography.Title>
                <p style={{ marginBottom: '2rem' }}>
                    <FormattedMessage
                        {...AccountMessages.suspensionDescription}
                        values={{
                            link: (...chunks: ReactElement[]) => (
                                <Link to={getRoute(RoutePathName.program)}>
                                    <strong>{chunks}</strong>
                                </Link>
                            ),
                        }}
                    />
                </p>
                <SuspensionAlert />
                {!getActiveSuspension(authState.user) && !!authState.user?.selectedProgram?.remainingSuspensions && (
                    <>
                        <Button
                            type="primary"
                            size="large"
                            onClick={setIsFormModalVisible.bind(null, true)}
                        >
                            <FormattedMessage {...AccountMessages.suspensionCTA} />
                        </Button>
                        {authState.user?.selectedProgram?.remainingSuspensions! < authState.user?.selectedProgram?.program?.maxSuspension && (
                            <Typography.Text style={{ marginLeft: '1rem' }} strong>
                                <FormattedMessage
                                    {...AccountMessages.suspensionRemaining}
                                    values={{ count: authState.user?.selectedProgram?.remainingSuspensions ?? 0 }}
                                />
                            </Typography.Text>
                        )}
                    </>
                )}
            </Card>
            <AccountSuspensionModal
                onCancel={setIsFormModalVisible.bind(null, false)}
                visible={isFormModalVisible}
            />
        </>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
)(AccountSuspensionForm);
