import { combineReducers } from 'redux';
import { takeLatest, delay, call, put } from 'redux-saga/effects';

import {
    UserDetailsPayload,
    UserListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    roles as rolesApiCall,
    filesList as filesListApiCall,
    surveysList as surveysListApiCall,
    reportingList as reportingListApiCall,
    sendWelcomeEmail as sendWelcomeEmailApiCall,
    exportAll as exportAllApiCall,
    importUsers as importUsersApiCall,
    UserSendWelcomeEmailPayload,
    UserImportPayload,
    UserSubTaskListPayload,
    UserReportingListPayload,
    UserFilePayload,
} from '../api/users';
import { RequestState, MainReducerState } from '../reducers';
import { ListResponse, User, Role, UserProgramSubTask, LibraryDocument } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface UsersState {
    list: RequestState<ListResponse<User>>;
    search: RequestState<ListResponse<User>>;
    details: RequestState<User | undefined>;
    create: RequestState<User | undefined>;
    update: RequestState<User | undefined>;
    remove: RequestState<User | undefined>;
    roles: RequestState<ListResponse<Role>>;
    sendWelcomeEmail: RequestState;
    importUsers: RequestState;
    filesList: RequestState<ListResponse<LibraryDocument>>;
    surveysList: RequestState<ListResponse<UserProgramSubTask>>;
    reportingList: RequestState<ListResponse<User>>;
    readonly exportAll: RequestState<string>;
}

const initialState: UsersState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
    roles: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    sendWelcomeEmail: {
        loading: false,
        success: false,
    },
    importUsers: {
        loading: false,
        success: false,
    },
    filesList: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    surveysList: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    reportingList: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    exportAll: {
        loading: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    UsersState['list'],
    UserListPayload,
    ListResponse<User>
>('users/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    UsersState['search'],
    UserListPayload,
    ListResponse<User>
>('users/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    UsersState['details'],
    UserDetailsPayload,
    User
>('users/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    UsersState['create'],
    User,
    User
>('users/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    UsersState['update'],
    User,
    User
>('users/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    UsersState['remove'],
    User,
    User
>('users/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const roles = new EzeeAsyncAction<
    UsersState['roles'],
    UserListPayload,
    ListResponse<Role>
>('users/roles', initialState.roles, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.roles,
    }),
});

export const sendWelcomeEmail = new EzeeAsyncAction<
    UsersState['sendWelcomeEmail'],
    UserSendWelcomeEmailPayload,
    User
>('users/sendWelcomeEmail', initialState.sendWelcomeEmail, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.sendWelcomeEmail,
    }),
});

export const importUsers = new EzeeAsyncAction<
    UsersState['importUsers'],
    UserImportPayload,
    User
>('users/importUsers', initialState.importUsers, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.importUsers,
    }),
});

export const filesList = new EzeeAsyncAction<
    UsersState['filesList'],
    UserFilePayload,
    ListResponse<LibraryDocument>
>('users/filesList', initialState.filesList, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.filesList,
    }),
});

export const surveysList = new EzeeAsyncAction<
    UsersState['surveysList'],
    UserSubTaskListPayload,
    ListResponse<UserProgramSubTask>
>('users/surveysList', initialState.surveysList, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.surveysList,
    }),
});

export const reportingList = new EzeeAsyncAction<
    UsersState['reportingList'],
    UserReportingListPayload,
    ListResponse<User>
>('users/reportingList', initialState.reportingList, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.reportingList,
    }),
});

export const exportAll = new EzeeAsyncAction<
    UsersState['exportAll'], any, string
>('users/exportAll', initialState.exportAll, {
    trigger: (state) => ({
        ...state,
        loading: true,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        loading: false,
        data: payload,
    }),
    failure: (state, payload) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.exportAll,
    }),
});

// Reducer

export const usersReducer = combineReducers<UsersState>({
    details: details.reducer,
    list: list.reducer,
    search: search.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    roles: roles.reducer,
    sendWelcomeEmail: sendWelcomeEmail.reducer,
    importUsers: importUsers.reducer,
    filesList: filesList.reducer,
    surveysList: surveysList.reducer,
    reportingList: reportingList.reducer,
    exportAll: exportAll.reducer,
});

// Saga

export function* usersSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(roles.type.trigger, simpleAsyncSaga(rolesApiCall, roles));
    yield takeLatest(sendWelcomeEmail.type.trigger, simpleAsyncSaga(sendWelcomeEmailApiCall, sendWelcomeEmail));
    yield takeLatest(importUsers.type.trigger, simpleAsyncSaga(importUsersApiCall, importUsers));
    yield takeLatest(filesList.type.trigger, simpleAsyncSaga(filesListApiCall, filesList));
    yield takeLatest(surveysList.type.trigger, simpleAsyncSaga(surveysListApiCall, surveysList));
    yield takeLatest(reportingList.type.trigger, simpleAsyncSaga(reportingListApiCall, reportingList));
    yield takeLatest(exportAll.type.trigger, exportAllSaga);
}

function* listSaga(action: DataAction<UserListPayload>) {
    try {
        const { throttling, ...payload } = action.payload;
        yield delay(throttling || 0);

        const response = yield call(listApiCall, payload);

        return yield put(list.success(response));
    } catch (error) {
        return yield put(list.failure(error));
    }
}

function* exportAllSaga() {
    try {
        const response = yield call(exportAllApiCall);
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        window.setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 35000);

        return yield put(exportAll.success(url));
    } catch (error) {
        return yield put(exportAll.failure(error));
    }
}

// Store helpers

export const getUsersState = (state: MainReducerState) => state.users;
export const getUsersListState = (state: MainReducerState) => state.users.list;
export const getUsersReportingListState = (state: MainReducerState) => state.users.reportingList;
export const getUsersExportState = (state: MainReducerState) => state.users.exportAll;
