import React, { FC } from 'react';
import { Button, Divider, Tag, Typography } from 'antd';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import '../assets/styles/JobOfferCard.less';

import { JobOffer, InterviewStatus } from '../store/api/apiTypes';

import genericMessages from '../locale/genericMessages';
import { classNames, formatAddress } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import { IconArrowRight, IconCompany, IconEvent, IconMore, IconPlace, IconCheck, IconClose } from './icons';
import JobOfferMenu from './JobOfferMenu';
import JobOffersMessages from '../pages/jobOffers/JobOffersMessages';

const renderTag = (jobOffer: JobOffer) => {
    switch (jobOffer.status) {
        case InterviewStatus.accepted:
            return jobOffer.appointments?.length ? (
                <Tag icon={<IconCheck />} color="success">
                    <FormattedMessage
                        {...JobOffersMessages.interviewDate}
                        values={{
                            date: (
                                <FormattedDate
                                    value={jobOffer.appointments[0].date}
                                    month="long"
                                    day="numeric"
                                />
                            ),
                        }}
                    />
                </Tag>
            ) : null;

        case InterviewStatus.refused:
            return (
                <Tag icon={<IconClose />} color="error">
                    <FormattedMessage
                        {...JobOffersMessages.interviewRefusedDate}
                        values={{
                            date: (
                                <FormattedDate
                                    value={jobOffer.statusDate}
                                    month="long"
                                    day="numeric"
                                />
                            ),
                        }}
                    />
                </Tag>
            );

        default:
            return (
                <Tag icon={<IconEvent />}>
                    <FormattedMessage
                        {...genericMessages.createdOn}
                        values={{
                            date: (
                                <FormattedDate
                                    value={jobOffer.createdAt}
                                    month="long"
                                    day="numeric"
                                />
                            ),
                        }}
                    />
                </Tag>
            );
    }
};

interface JobOfferCardProps {
    jobOffer: JobOffer;
}

const JobOfferCard: FC<JobOfferCardProps> = ({
    jobOffer,
}) => {

    return (
        <div
            className={classNames(
                'job-offer-card',
            )}
        >
            <header className="flex-between">
                {renderTag(jobOffer)}
                <JobOfferMenu jobOffer={jobOffer} placement="bottomRight">
                    <Button type="link" className="job-offer-card-menu-button">
                        <IconMore />
                    </Button>
                </JobOfferMenu>
            </header>
            <div className="job-offer-card-body">
                <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    <p className="job-offer-card-title">
                        <Link to={getRoute(RoutePathName.jobOffer, { jobOfferId: jobOffer.id })}>
                            {jobOffer.title}
                        </Link>
                    </p>
                </Typography.Paragraph>
                <Divider />
                {jobOffer.company && (
                    <div className="job-offer-card-company">
                        <p className="job-offer-card-company-info">
                            <IconCompany />
                            {jobOffer.company?.name}
                        </p>
                        <Typography.Paragraph
                            ellipsis={{ rows: 1 }}
                            className="job-offer-card-company-info"
                        >
                            <IconPlace />
                            {formatAddress(jobOffer.company?.address)}
                        </Typography.Paragraph>
                    </div>
                )}
            </div>
            <footer className="flex-between">
                <Link
                    className="text-primary"
                    to={getRoute(RoutePathName.jobOffer, {jobOfferId: jobOffer.id})}
                >
                    <FormattedMessage {...genericMessages.detailsLink} /> <IconArrowRight />
                </Link>
            </footer>
        </div>
    );
};

export default JobOfferCard;
