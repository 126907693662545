import React, { FC, useMemo, memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl, IntlFormatters, FormattedMessage } from 'react-intl';
import { ResponsivePie, PieLayer, DefaultRawDatum } from '@nivo/pie';
import { Spin, Empty, Typography, Result } from 'antd';

import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import { metrics } from '../../store/actions/reporting';

import ReportingMessages, { metricsMessages } from '../../pages/client/dashboard/ReportingMessages';
import useQuery from '../../hooks/useQuery';
import { nivoTheme, nivoColors, getThemeNivoColors } from '../../helpers/styles';
import { Panel, QueryResponseData, PanelParsedDescription } from '../../types';
import { useQueryParams } from '../../hooks';
import EnumMessage from '../EnumMessage';
import { getQueryType, getParsedDescription, getLegendFormat, getMetricName } from '../../helpers/reporting';
import { classNames } from '../../helpers';
import { localizedDurationHumanizer } from '../../locale/i18n/humanizeDuration';
import genericMessages from '../../locale/genericMessages';

const formatPieChartResponse = (
    response: QueryResponseData | undefined,
    panel: Panel,
    parsedDescription: PanelParsedDescription,
    distribParam: string | null,
    targetIndex: number,
    formatMessage: IntlFormatters['formatMessage'],
) => {
    if (!response) {
        return [];
    }

    const result = distribParam ?
        response.result.filter(({ metric }: any) => metric[distribParam]) :
        response.result;

    if (response.resultType === 'vector') {
        return result.map(({ metric, value }: any, i: number) => {
            let id = `${metric.__name__}-${i}`;

            if (distribParam === 'jobLevel' && getLegendFormat(panel, targetIndex) === 'gender') {
                id = metric.jobLevel || formatMessage(ReportingMessages.value, { index: i + 1 });
            } else if (panel.targets[targetIndex]?.legendFormat) {
                id = metric[getLegendFormat(panel, targetIndex)] || formatMessage(ReportingMessages.value, { index: i + 1 });
            }

            return {
                id,
                metric,
                value: value?.[1] || 0,
            };
        });
    } else {
        return result.map(({ metric, values }: any, i: number) => {
            let id = `${metric.__name__}-${i}`;
            let value = parseInt(values?.[0]?.[1] || NaN, 10) || 0;

            if (distribParam === 'jobLevel' && getLegendFormat(panel, targetIndex) === 'gender') {
                id = metric.jobLevel || formatMessage(ReportingMessages.value, { index: i + 1 });
            } else if (panel.targets[targetIndex]?.legendFormat) {
                id = metric[getLegendFormat(panel, targetIndex)] || formatMessage(ReportingMessages.value, { index: i + 1 });
            }

            if (typeof parsedDescription === 'object' && parsedDescription.diff) {
                value = (parseInt(values?.[values.length - 1]?.[1] || NaN, 10) || 0) - (parseInt(values?.[0]?.[1] || NaN, 10) || 0);
            }
            if (typeof parsedDescription === 'object' && parsedDescription.diffMin != null) {
                value = Math.max(value, parsedDescription.diffMin);
            }

            return {
                id,
                metric,
                value,
            };
        });
    }
};

interface PieChartProps {
    panel: Panel;
    user?: User;
    params?: {
        [key: string]: string;
    };
    storeForExport: typeof metrics.actions.set;
    removeForExport: typeof metrics.actions.remove;
}

const PieChart: FC<PieChartProps> = memo(({ panel, params, user, storeForExport, removeForExport }) => {
    const { formatMessage } = useIntl();
    const urlParams = useQueryParams();
    const dateParam = urlParams.get('dateRange');
    const userParam = urlParams.get('user');
    const groupParam = urlParams.get('group');
    const distribParam = urlParams.get('distrib');
    const [formattedData, setFormattedData] = useState<Array<any[] | undefined>>();
    const parsedDescription = useMemo(() => getParsedDescription(panel.description), [panel.description]);
    const dateRange = useMemo(() =>
        dateParam ?
            [parseInt(dateParam.split('-')[0], 10), parseInt(dateParam.split('-')[1], 10)] :
            undefined
    , [dateParam]);
    const { data, isLoading, error } = useQuery(panel, useMemo(() => ({
        queryType: getQueryType(panel),
        params: {
            ...(dateRange ? {start: `${Math.floor(dateRange[0] / 1000)}`} : {}),
            ...(dateRange ? {end: `${Math.floor(dateRange[1] / 1000)}`} : {}),
            ...(userParam ? { $user: userParam } : {}),
            ...(groupParam ? { $group: groupParam } : {}),
            ...params,
        },
    }), [dateRange, userParam, groupParam, panel, params]), user);

    const formatValue = (value: number) => {
        if (panel.fieldConfig?.defaults.unit === 'ms') {
            return localizedDurationHumanizer(value, {
                maxDecimalPoints: 1,
                largest: 1,
            });
        } else if (panel.fieldConfig?.defaults.unit === 's') {
            return localizedDurationHumanizer(value * 1000, {
                maxDecimalPoints: 1,
                largest: 1,
            });
        }

        return value;
    };
    const CenteredMetric: PieLayer<DefaultRawDatum> = ({ dataWithArc, centerX, centerY, innerRadius }) => {
        const total = formatValue(dataWithArc.reduce((acc, datum: any) =>
            acc += parseInt(datum.value, 10) || 0,
        0));

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: innerRadius,
                    fontWeight: 600,
                    fill: window.__MCM__theme?.primary || '#6B5AED',
                }}
            >
                {total}
            </text>
        );
    };

    useEffect(() => {

        if (!isLoading) {
            if (!error && data) {
                let splitData = [data];

                // beware of string.repeat(); since parsedDescription can be either object or string
                if (parsedDescription.repeat === true && distribParam && data?.[0]) {
                    const dataSource = data[0];
                    const keys = [...new Set(dataSource?.result.map((d: any) => d.metric[distribParam]))];

                    splitData = keys.map((key) => [{
                        resultType: dataSource.resultType,
                        result: dataSource.result.filter((d: any) => d.metric[distribParam] === key),
                    }]);
                }

                const value = splitData.map((splitDatum) => splitDatum?.flatMap((d, index) =>
                    formatPieChartResponse(d, panel, parsedDescription, distribParam, index, formatMessage),
                ));

                setFormattedData(value);

                storeForExport({
                    type: 'pieChart',
                    translatedName: `${formatMessage(getMetricName(parsedDescription))}${
                        panel.fieldConfig?.defaults.unit === 'ms' ? ' (ms)' : ''
                    }${
                        panel.fieldConfig?.defaults.unit === 's' ? ' (s)' : ''
                    }`,
                    name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription,
                    value,
                    ...(userParam ? { user: urlParams.get('userName') } : {}),
                    ...(groupParam ? { group: urlParams.get('groupName') } : {}),
                    ...(distribParam ? { distrib: distribParam } : {}),
                });
            } else {
                setFormattedData(undefined);
            }
        }
    }, [
        isLoading, data, error, distribParam, formatMessage, groupParam, panel, parsedDescription,
        storeForExport, urlParams, userParam,
    ]);

    useEffect(() => () => {
        removeForExport({ name: typeof parsedDescription === 'object' ? parsedDescription.name || '-' : parsedDescription });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <h2>
                <EnumMessage
                    map={metricsMessages}
                    value={typeof parsedDescription === 'object' ? parsedDescription.name || '' : parsedDescription}
                />
            </h2>
            {isLoading && <Spin />}
            {!isLoading && !error && (
                <div className={classNames('dashboard-graph-wrapper-list', panel.gridPos.w === 24 && (formattedData?.length || 0) > 1 && 'two-col')}>
                    {formattedData?.map((formattedDatum, dataIndex) => {

                        const filteredDatum = formattedDatum?.filter((d) => d.value !== 0);
                        if (!filteredDatum) {
                            return <Empty key={dataIndex} />;
                        }

                        if (filteredDatum.every((d) => !d.value)) {
                            return (
                                <div className="dashboard-stat-wrapper" key={dataIndex}>
                                    {0}
                                </div>
                            );
                        }

                        return (
                            <div className="dashboard-graph-wrapper pie-chart" key={dataIndex}>
                                {parsedDescription.repeat === true && !!distribParam && (
                                    <Typography.Title level={3}>
                                        {metricsMessages.get(`${filteredDatum[0].metric[distribParam]}`) ?
                                            formatMessage(metricsMessages.get(`${filteredDatum[0].metric[distribParam]}`)!) :
                                            filteredDatum[0].metric[distribParam]
                                        }
                                    </Typography.Title>
                                )}
                                <ResponsivePie
                                    data={filteredDatum}
                                    theme={nivoTheme}
                                    colors={user?.organization?.theme?.primary ? getThemeNivoColors(user?.organization?.theme?.primary) : nivoColors}
                                    margin={{ top: 32, bottom: 32, left: 100, right: 100 }}
                                    innerRadius={0.5}
                                    padAngle={2.83}
                                    cornerRadius={5}
                                    radialLabelsLinkColor={{
                                        from: 'color',
                                    }}
                                    radialLabelsSkipAngle={4}
                                    radialLabelsLinkStrokeWidth={2}
                                    radialLabelsTextColor="#2E384A"
                                    // tslint:disable-next-line: jsx-no-lambda
                                    sliceLabelsTextColor={(d) => ['#131454', '#4D42C7'].includes(d.color) ? '#fafafa' : '#2E384A'}
                                    // tslint:disable-next-line: jsx-no-lambda
                                    radialLabel={(d) => {
                                        let label: string;
                                        if (typeof parsedDescription === 'object' && parsedDescription.i18nPrefix && metricsMessages.get(`${parsedDescription.i18nPrefix}${d.id}`)) {
                                            label = formatMessage(metricsMessages.get(`${parsedDescription.i18nPrefix}${d.id}`)!);
                                        } else if (metricsMessages.get(`${d.id}`)) {
                                            label = formatMessage(metricsMessages.get(`${d.id}`)!);
                                        } else {
                                            label = `${d.id}`;
                                        }

                                        return (
                                            <>
                                                {label.split(' ').map((word, index) => (
                                                    <tspan x="0" dy={`${index}em`} key={index}>{word}</tspan>
                                                ))}
                                            </>
                                        ) as unknown as any;
                                    }}
                                    layers={['slices', 'sliceLabels', CenteredMetric, 'radialLabels', 'legends']}
                                    valueFormat={formatValue}
                                    fit
                                />
                            </div>
                        );
                    })}
                    {!formattedData?.length && (
                        <Empty />
                    )}
                </div>
            )}
            {!isLoading && error && (
                <Result
                    status="error"
                    subTitle={<FormattedMessage {...genericMessages.defaultError} />}
                />
            )}
        </div>
    );
});

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        storeForExport: metrics.actions.set,
        removeForExport: metrics.actions.remove,
    },
)(PieChart);
