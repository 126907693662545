import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 20">
        <path fill="currentColor" d="M8 0C4.82.004 2.243 2.6 2.24 5.8v3.84h-.746A.998.998 0 0 0 .5 10.643v6.043c0 4.42 15 4.42 15 0v-6.043c0-.553-.445-1-.994-1h-.746V5.8C13.757 2.598 11.18.003 8 0zM4.888 9.64V5.8c0-1.12.593-2.153 1.556-2.713.963-.56 2.15-.56 3.112 0A3.138 3.138 0 0 1 11.112 5.8v3.84H4.888z" />
    </svg>
);

const IconPrivacy: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-privacy${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconPrivacy;
