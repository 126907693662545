import React, { FC, useEffect, useState, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Avatar, Button, Card, Col, Divider, Form, message, Row, Space, Upload, Typography, Checkbox, InputNumber } from 'antd';
import { CaretDownFilled, UserOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { FormProps } from 'antd/lib/form';
import dayjs, { Dayjs } from 'dayjs';

import { MainReducerState } from '../../store/reducers';
import { update as updateAction, AuthState, getAuthState } from '../../store/actions/auth';

import formMessages from '../../locale/formMessages';
import Select from '../../components/Select';
import genericMessages, { jobOccupationMessages } from '../../locale/genericMessages';
import { usePrevious } from '../../hooks';
import { stripUndefinedKeysFromObject } from '../../helpers';
import AccountMessages, { maritalStatusMessages, degreesMessages, spokenLanguagesMessages } from './AccountMessages';
import { SpokenLanguageLevel, spokenLanguageLevelMessages, spokenLanguageLevelHelpMessages } from '../../store/api/apiTypes';
import { Link } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import CreateUserMessages from '../client/users/CreateUserMessages';
import DatePicker from '../../components/DatePicker';
import { IconCalendar } from '../../components/icons';

interface AccountInformationsFormProps {
    authState: AuthState;
    avatarOnly?: boolean;
    update: typeof updateAction.trigger;
}

const AccountInformationsForm: FC<AccountInformationsFormProps> = ({ authState, avatarOnly, update }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const previous = usePrevious({ authState });
    const [, forceUpdate] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [avatarFile, setAvatarFile] = useState<RcFile | undefined>();
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(authState.user?.avatarUrl);
    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error(formatMessage(AccountMessages.formAvatarFileTypeError));
            return false;
        }

        if (file.size / 1024 > 5000) {
            message.error(formatMessage(AccountMessages.formAvatarFileSizeError));
            return false;
        }

        setAvatarFile(file);
        setAvatarUrl(URL.createObjectURL(file));

        return false;
    };
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        let result = {};
        if (avatarOnly) {
            result = stripUndefinedKeysFromObject({
                avatar: avatarFile,
            });
        } else {
            result = stripUndefinedKeysFromObject({
                ...values,
                phone: values.phone || null,
                maritalStatus: values.maritalStatus || null,
                birthday: values.birthday.toISOString() || null,
                avatar: avatarFile,
                hasSetupAccount: true,
                degrees: values.degrees || null,
                job: {
                    ...values.job,
                    occupation: values.job.occupation || null,
                },
            });
        }

        setIsUpdating(true);
        update(result);
    };
    const disabledBirthdayDate = (current: Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (previous?.authState.updateLoading && !authState.updateLoading && isUpdating) {
            if (authState.updateError) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(AccountMessages.updateSuccess));
            }
            setIsUpdating(false);
        }
    }, [previous, authState.updateLoading, authState.updateError, formatMessage, isUpdating]);

    return (
        <Card>
            <Form
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                form={form}
                initialValues={{
                    ...authState.user,
                    birthday: dayjs(authState.user?.birthday),
                }}
            >
                <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept="image/png,image/jpeg"
                >
                    <Space size={16}>
                        <Avatar src={avatarUrl} size={128} className={avatarUrl ? 'has-value' : undefined}>
                            {!avatarUrl && <UserOutlined />}
                        </Avatar>
                        <Space direction="vertical">
                            <label htmlFor="avatar"><FormattedMessage {...AccountMessages.formAvatarLabel} /></label>
                            <Button id="avatar" ghost><FormattedMessage {...AccountMessages.formAvatarButton} /></Button>
                            <FormattedMessage {...AccountMessages.formAvatarHelp} tagName="small" />
                        </Space>
                    </Space>
                </Upload>
                {avatarOnly && (
                    <Form.Item style={{ marginTop: '2rem' }} shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={authState.updateLoading}
                                size="large"
                                disabled={
                                    !avatarFile && (
                                        !form.isFieldsTouched() ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    )
                                }
                            >
                                <FormattedMessage
                                    {...AccountMessages[
                                    authState.user?.hasSetupAccount ?
                                        'formSubmit' :
                                        'formFirstSubmit'
                                    ]}
                                />
                            </Button>
                        )}
                    </Form.Item>
                )}
                {!avatarOnly && (
                    <>
                        <Divider />
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={formatMessage(CreateUserMessages.birthdateLabel)}
                                    name="birthday"
                                    rules={[requiredRule]}
                                >
                                    <DatePicker
                                        placeholder={formatMessage(CreateUserMessages.birthdatePlaceholder)}
                                        suffixIcon={<IconCalendar />}
                                        format="DD/MM/YYYY"
                                        disabledDate={disabledBirthdayDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={formatMessage(AccountMessages.maritalStatusLabel)}
                                    name="maritalStatus"
                                >
                                    <Select
                                        placeholder={formatMessage(AccountMessages.maritalStatusPlaceholder)}
                                        suffixIcon={<CaretDownFilled />}
                                        allowClear
                                    >
                                        {[...maritalStatusMessages.entries()].map(([key, value]) => ((
                                            <Select.Option value={key} key={key}>
                                                <FormattedMessage {...value} />
                                            </Select.Option>
                                        )))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label={formatMessage(AccountMessages.degreesLabel)}
                            name="degrees"
                        >
                            <Select
                                placeholder={formatMessage(AccountMessages.degreesPlaceholder)}
                                allowClear
                            >
                                {[...degreesMessages.entries()].map(([key, value], index) => ((
                                    <Select.Option value={`${index}`} key={key}>
                                        <FormattedMessage {...value} />
                                    </Select.Option>
                                )))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage(AccountMessages.jobOccupationLabel)}
                            name={['job', 'occupation']}
                        >
                            <Select
                                placeholder={formatMessage(AccountMessages.jobOccupationPlaceholder)}
                                allowClear
                            >
                                {[...jobOccupationMessages.entries()].map(([key, value]) => ((
                                    <Select.Option value={key} key={key}>
                                        <FormattedMessage {...value} />
                                    </Select.Option>
                                )))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage(AccountMessages.infoCurrentPostSalary)}
                            name={['job', 'annualSalaryWithoutTax']}
                        >
                            <InputNumber
                                placeholder={formatMessage(AccountMessages.infoCurrentPostSalaryPlaceholder)}
                            />
                        </Form.Item>
                        <Divider />
                        <Form.List name="spokenLanguages">
                            {(fields, { add, remove }, { errors }) => (
                                <Form.Item>
                                    <Typography.Title level={3}>
                                        <FormattedMessage {...AccountMessages.spokenLangLabel} />
                                    </Typography.Title>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            shouldUpdate
                                            key={field.key}
                                        >
                                            {({ getFieldValue }) => (
                                                <>
                                                    <Row gutter={24}>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item
                                                                name={[field.name, 'language']}
                                                                noStyle
                                                            >
                                                                <Select
                                                                    placeholder={formatMessage(AccountMessages.spokenLangPlaceholder)}
                                                                >
                                                                    {[...spokenLanguagesMessages.entries()].filter(([lang]) =>
                                                                        lang === getFieldValue(['spokenLanguages', field.name, 'language']) ||
                                                                        !getFieldValue(['spokenLanguages'])
                                                                            .some((selected: { language?: string }) => selected.language === lang),
                                                                    ).map(([key, value]) => ((
                                                                        <Select.Option
                                                                            value={key}
                                                                            key={key}
                                                                        >
                                                                            <FormattedMessage {...value} />
                                                                        </Select.Option>
                                                                    )))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Row align="middle">
                                                                <Col flex="auto">
                                                                    <Form.Item
                                                                        name={[field.name, 'level']}
                                                                        noStyle
                                                                    >
                                                                        <Select
                                                                            placeholder={formatMessage(AccountMessages.spokenLangLevelPlaceholder)}
                                                                        >
                                                                            {Object.values(SpokenLanguageLevel).map((spokenLangLevelMessage) => ((
                                                                                <Select.Option
                                                                                    value={spokenLangLevelMessage}
                                                                                    key={spokenLangLevelMessage}
                                                                                >
                                                                                    <FormattedMessage
                                                                                        {...spokenLanguageLevelMessages.get(spokenLangLevelMessage)}
                                                                                    />
                                                                                </Select.Option>
                                                                            )))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col style={{ marginLeft: 16 }}>
                                                                    <Button
                                                                        className="remove-item"
                                                                        type="ghost"
                                                                        shape="circle"
                                                                        icon={<DeleteFilled />}
                                                                        onClick={remove.bind(null, index)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <div className="ant-form-item-extra" style={{marginTop: 12}}>
                                                        {getFieldValue(['spokenLanguages', field.name, 'level']) && (
                                                            <FormattedMessage
                                                                // tslint:disable-next-line: max-line-length
                                                                {...spokenLanguageLevelHelpMessages.get(getFieldValue(['spokenLanguages', field.name, 'level']))}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form.Item>
                                    ))}

                                    <Form.ErrorList errors={errors} />

                                    {(!form.getFieldValue(['spokenLanguages']) || form.getFieldValue(['spokenLanguages'])?.length < 4) && (
                                        <Button
                                            type="text"
                                            className="add-new"
                                            onClick={add.bind(null, { level: SpokenLanguageLevel.school }, undefined)}
                                        >
                                            <PlusOutlined />
                                            <FormattedMessage {...AccountMessages.spokenLangAddLanguage} />
                                        </Button>
                                    )}
                                </Form.Item>
                            )}
                        </Form.List>
                        <Divider />
                        <Form.Item
                            name="consent"
                            valuePropName="checked"
                            rules={[{
                                ...requiredRule,
                                validator: (_, value) => !!value ? Promise.resolve() : Promise.reject(),
                            }]}
                        >
                            <Checkbox>
                                <FormattedMessage
                                    {...AccountMessages.formConsent}
                                    values={{
                                        link: (...chunks: ReactElement[]) => (
                                            <Link to={getRoute(RoutePathName.privacyPolicy)}>
                                                {chunks}
                                            </Link>
                                        ),
                                    }}
                                />
                            </Checkbox>
                        </Form.Item>
                        <Divider />
                        <Form.Item style={{ textAlign: 'center' }} shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={authState.updateLoading}
                                    size="large"
                                    disabled={
                                        !form.getFieldValue('consent') || (
                                            !avatarFile &&
                                            (
                                                !form.isFieldsTouched() ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            )
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        {...AccountMessages[
                                            authState.user?.hasSetupAccount ?
                                                'formSubmit' :
                                                'formFirstSubmit'
                                        ]}
                                    />
                                </Button>
                            )}
                        </Form.Item>
                    </>
                )}
            </Form>
        </Card>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        update: updateAction.trigger,
    },
)(AccountInformationsForm);
