import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/actions/auth';
import { User } from '../../store/api/apiTypes';

import '../../assets/styles/Contacts.less';

import Seo from '../../components/Seo';
import DocumentsMessages from './DocumentsMessages';
import ClientUserFilesList from '../client/users/ClientUserFilesList';
import SurveysList from './SurveysList';

interface DocumentsProps {
    user?: User;
}

const Documents: FC<DocumentsProps> = ({ user }) => {
    const { formatMessage } = useIntl();

    return (
        <section id="contacts">
            <Seo title={formatMessage(DocumentsMessages.title)} />
            <ClientUserFilesList isUser />
            <SurveysList />
        </section>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Documents);
