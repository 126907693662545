import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import { RouteChildrenProps, useHistory, useLocation } from 'react-router-dom';
import { Descriptions, Button, Avatar, Typography, Divider, message, Tag, Card, Space } from 'antd';
import { UserOutlined, SettingOutlined } from '@ant-design/icons';

import {
    jobStatusMessages, genderMessages, User, rolesMessages, RoleSlug,
    selectedProgramStatusMessages, programClosureTypeMessages, ProgramClosureType,
} from '../../../store/api/apiTypes';
import { MainReducerState } from '../../../store/reducers';
import * as usersActions from '../../../store/actions/users';

import AccountMessages from '../../account/AccountMessages';
import { IconArrowLeft, IconMail, IconPhone, IconEvent } from '../../../components/icons';
import { formatTextData, formatAddress, getFullName, scrollToElement } from '../../../helpers';
import { usePrevious } from '../../../hooks';
import genericMessages from '../../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { hasRole } from '../../../helpers/security';
import TruncatedText from '../../../components/TruncatedText';
import ButtonLink from '../../../components/ButtonLink';
import EnumMessage from '../../../components/EnumMessage';
import ClientUsersMessages from './ClientUsersMessages';
import ClientUserEditForm from './ClientUserEditForm';
import ClientUserProgramModal from './ClientUserProgramModal';
import ProgramClosureMessages from './ProgramClosureMessages';
import CreateUserMessages from './CreateUserMessages';
import ClientUserFilesList from './ClientUserFilesList';

interface MatchParams {
    userId: string;
}

interface ClientUserProps extends RouteChildrenProps<MatchParams> {
    usersDetailsState: usersActions.UsersState['details'];
    usersUpdateState: usersActions.UsersState['update'];
    usersSendWelcomeState: usersActions.UsersState['sendWelcomeEmail'];
    usersRemoveState: usersActions.UsersState['remove'];

    getDetails: typeof usersActions.details.trigger;
    resetDetails: typeof usersActions.details.reset;
    update: typeof usersActions.update.trigger;
    sendWelcomeEmail: typeof usersActions.sendWelcomeEmail.trigger;
    remove: typeof usersActions.remove.trigger;
}

const ClientUser: FC<ClientUserProps> = ({
    match,

    usersDetailsState,
    usersUpdateState,
    usersSendWelcomeState,
    usersRemoveState,

    getDetails,
    resetDetails,
    update,
    sendWelcomeEmail,
    remove,
}) => {

    const user = usersDetailsState.data;
    const [ isEdit, setIsEdit ] = useState(false);
    const [ isProgramModalVisible, setIsProgramModalVisible ] = useState(false);
    const [ isProgramModalAfterCreate, setIsProgramModalAfterCreate ] = useState(false);
    const [ isProgramExtend, setIsProgramExtend ] = useState(false);
    const previous = usePrevious({ usersSendWelcomeState, usersUpdateState, usersRemoveState });
    const location = useLocation<{ showProgramModal?: boolean } | undefined>();
    const history = useHistory();
    const pageRef = useRef(null);
    const { formatMessage } = useIntl();

    const back = () => {
        history.push(getRoute(RoutePathName.clientUsers), {
            isBack: true,
        });
    };

    // ---------------------------------------
    // On init, get user details

    const getUserDetails = useCallback(() => {
        if (match && match.params.userId) {
            getDetails({id: match.params.userId});
        }
    }, [getDetails, match]);

    useEffect(() => {
        resetDetails();
        getUserDetails();

        if (location.state?.showProgramModal) {
            setIsProgramModalVisible(true);
            setIsProgramModalAfterCreate(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Welcome email

    const welcomeEmail = () => {
        if (user) {
            sendWelcomeEmail({
                userIds: [user.id],
            });
        }
    };

    useEffect(() => {
        if (previous?.usersSendWelcomeState.loading && !usersSendWelcomeState.loading) {
            if (usersSendWelcomeState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ClientUsersMessages.welcomeEmailSuccess));
            }
        }
    }, [previous, usersSendWelcomeState.loading, usersSendWelcomeState.error, formatMessage]);

    // ---------------------------------------
    // Switch view / edit

    const edit = () => {
        setIsEdit(true);
        scrollToElement(pageRef.current);
    };

    const view = () => {
        setIsEdit(false);
        scrollToElement(pageRef.current);
    };

    const onUpdate = (values: User) => {
        update({
            ...values,
            id: user?.id,
        });
        view();
    };

    useEffect(() => {
        if (previous?.usersUpdateState.loading && !usersUpdateState.loading) {
            if (usersUpdateState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ClientUsersMessages.updateSuccess));
                getUserDetails();
            }
        }
    }, [previous, usersUpdateState.loading, usersUpdateState.error, formatMessage, getUserDetails]);

    // ---------------------------------------
    // Render

    const onRemove = () => {
        if (user) {
            remove({id: user.id});
        }
    };

    useEffect(() => {
        if (previous?.usersRemoveState.loading && !usersRemoveState.loading) {
            if (usersRemoveState.error) {
                message.error(formatMessage(genericMessages.defaultError));
            } else {
                message.success(formatMessage(ClientUsersMessages.deleteSuccess));
                history.push(getRoute(RoutePathName.clientUsers));
            }
        }
    }, [previous, usersRemoveState.loading, usersRemoveState.error, formatMessage, history]);

    // ---------------------------------------
    // Program modals

    const openProgramModal = () => {
        setIsProgramModalVisible(true);
    };

    const openExtendProgramModal = () => {
        setIsProgramExtend(true);
        setIsProgramModalVisible(true);
    };

    const closeProgramModal = () => {
        setIsProgramModalVisible(false);
        setIsProgramExtend(false);
        setIsProgramModalAfterCreate(false);
    };

    // ---------------------------------------
    // Render

    const userProgram = (user?.selectedProgram?.extensions && user?.selectedProgram?.extensions.length > 0)
                        ? user?.selectedProgram?.extensions[user?.selectedProgram?.extensions.length - 1]
                        : user?.selectedProgram;

    return user ? (
        <div id="client-user-profil" ref={pageRef}>
            <Button
                onClick={back}
                type="link"
                className="cta-back"
            >
                <IconArrowLeft /> <FormattedMessage {...ClientUsersMessages.backToUsers} />
            </Button>

            {isEdit ? (
                <ClientUserEditForm
                    user={user}
                    onSubmit={onUpdate}
                    onCancel={view}
                    onRemove={onRemove}
                />
            ) : (
                <>
                    <div className="description-header">
                        <div className="flex-between">
                            <div className="user-card flex-between">
                                <Avatar src={user?.avatarUrl} size={96}>
                                    {!user?.avatarUrl && <UserOutlined />}
                                </Avatar>
                                <div>
                                    {(user.role?.slug && !hasRole(user, [RoleSlug.user])) && (
                                        <Tag><FormattedMessage {...rolesMessages.get(user.role.slug)} /></Tag>
                                    )}
                                    <Typography.Title level={1}>
                                        {getFullName(user.firstName, user.lastName)}
                                    </Typography.Title>
                                    <div className="icon-row"><IconMail /> {formatTextData(user.email)}</div>
                                    <div className="icon-row"><IconPhone /> {formatTextData(user.phone)}</div>
                                </div>
                            </div>

                            <div className="actions">
                                <Button
                                    type="ghost"
                                    onClick={welcomeEmail}
                                >
                                    <FormattedMessage {...ClientUsersMessages.welcomeEmail} />
                                </Button>
                                {(user.selectedProgram && hasRole(user, [RoleSlug.user])) && (
                                    <ButtonLink
                                        to={getRoute(RoutePathName.clientUserProgramClosure, {userId: user.id})}
                                        ghost
                                    >
                                        <FormattedMessage {...ClientUsersMessages.closeProgram} />
                                    </ButtonLink>
                                )}
                                <Button
                                    type="ghost"
                                    onClick={edit}
                                >
                                    <FormattedMessage {...ClientUsersMessages.edit} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoTitle} />}>
                            <EnumMessage map={genderMessages} value={user?.gender} />
                        </Descriptions.Item>
                        <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoBirthday} />}>
                            <FormattedDate value={user?.birthday} />
                        </Descriptions.Item>
                        <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoUserGroups} />}>
                            {user?.groups?.length || user?.scope?.groups?.length ? (
                                <>
                                    {user?.groups?.map((group) => (
                                        <Tag key={group.id}>{group.name}</Tag>
                                    ))}
                                    {user?.scope?.groups?.map((group) => (
                                        <Tag key={group.id} className="tag-primary">{group.name}&nbsp;<SettingOutlined /></Tag>
                                    ))}
                                </>
                            ) : '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCompany} />}>
                            <FormattedMessage {...AccountMessages.infoCompanyGroup} tagName="label" /><br />
                            {formatTextData(user?.company?.group)}
                            <Divider />
                            <FormattedMessage {...AccountMessages.infoCompanyFirm} tagName="label" /><br />
                            {formatTextData(user?.company?.name)}
                        </Descriptions.Item>

                        {hasRole(user, [RoleSlug.user]) && (
                            <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoCurrentPost} />}>
                                <FormattedMessage {...AccountMessages.infoCurrentPostStatute} tagName="label" /><br />
                                    <EnumMessage map={jobStatusMessages} value={user?.job?.level} />
                                <Divider />
                                <FormattedMessage {...AccountMessages.infoCurrentPost} tagName="label" /><br />
                                {formatTextData(user?.job?.position)}
                                <Divider />
                                <FormattedMessage {...AccountMessages.infoCurrentPostSalary} tagName="label" /><br />
                                <FormattedNumber
                                    value={user?.job?.annualSalaryWithoutTax || 0}
                                    style="currency" // eslint-disable-line react/style-prop-object
                                    currency="EUR"
                                    currencyDisplay="symbol"
                                    minimumFractionDigits={0}
                                    maximumFractionDigits={0}
                                />
                                <Divider />
                                <FormattedMessage {...AccountMessages.infoCurrentPostManager} tagName="label" /><br />
                                {formatTextData(user?.job?.manager)}
                                <Divider />
                                <FormattedMessage {...AccountMessages.infoCurrentPostHrRep} tagName="label" /><br />
                                {formatTextData(user?.job?.hrRepresentative)}
                            </Descriptions.Item>
                        )}

                        <Descriptions.Item label={<FormattedMessage {...AccountMessages.infoAddress} />}>
                            {formatTextData(formatAddress(user?.address))}
                        </Descriptions.Item>

                    </Descriptions>
                </>
            )}

            <div id="user-program">
                <Typography.Title level={2}>
                    <FormattedMessage {...ClientUsersMessages.userProgram} />
                </Typography.Title>

                {!user.selectedProgram ? (
                    <Card className="no-program">
                        <Button
                            type="primary"
                            onClick={openProgramModal}
                        >
                            <FormattedMessage {...ClientUsersMessages.selectProgramNow} />
                        </Button>
                    </Card>
                ) : (
                    <Card className="program-card">
                        <div className="tags">
                            {(userProgram && userProgram.dates) && (
                                <Tag icon={<IconEvent />}>
                                    {userProgram.dates?.fromDate && (<FormattedDate value={new Date(userProgram.dates?.fromDate)}/>)}
                                    &nbsp;-&nbsp;
                                    {userProgram.dates?.toDate && (<FormattedDate value={new Date(userProgram.dates?.toDate)}/>)}
                                </Tag>
                            )}
                            <Tag className={'status-' + user.selectedProgram.status}>
                                <EnumMessage map={selectedProgramStatusMessages} value={user?.selectedProgram?.status} />
                            </Tag>
                        </div>
                        <Typography.Title level={2}>
                            {userProgram?.program?.name}
                        </Typography.Title>
                        <TruncatedText
                            maxHeight={175}
                            withFade
                        >
                            <p
                                className="program-card-description"
                                dangerouslySetInnerHTML={{ __html: userProgram?.program?.description || '' }}
                            />
                        </TruncatedText>
                        <Divider />

                        {!user.selectedProgram.closure ? (
                            <>
                                {(user.selectedProgram.extensions && user.selectedProgram.extensions.length > 0) ? (
                                    <div className="flex-between">
                                        <Space>
                                            <Button
                                                type="ghost"
                                                onClick={openExtendProgramModal}
                                            >
                                                <FormattedMessage {...ClientUsersMessages.extendAgain} />
                                            </Button>
                                        </Space>

                                        <Space>
                                            <ButtonLink
                                                to={getRoute(RoutePathName.clientUserProgramClosure, {userId: user.id})}
                                                ghost
                                            >
                                                <FormattedMessage {...ClientUsersMessages.closeProgram} />
                                            </ButtonLink>
                                        </Space>
                                    </div>
                                ) : (
                                    <div className="flex-between">
                                        <Space>
                                            <Button
                                                type="ghost"
                                                onClick={openProgramModal}
                                            >
                                                <FormattedMessage {...ClientUsersMessages.changeProgram} />
                                            </Button>
                                        </Space>

                                        <Space>
                                            <ButtonLink
                                                to={getRoute(RoutePathName.clientUserProgramClosure, {userId: user.id})}
                                                ghost
                                            >
                                                <FormattedMessage {...ClientUsersMessages.closeProgram} />
                                            </ButtonLink>

                                            <Button
                                                type="primary"
                                                onClick={openExtendProgramModal}
                                            >
                                                <FormattedMessage {...ClientUsersMessages.extendNow} />
                                            </Button>
                                        </Space>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="closure-infos">
                                <div className="flex-between">
                                    <Typography.Title level={3}>
                                        <FormattedMessage {...ClientUsersMessages.closureInformations} />
                                    </Typography.Title>

                                    <ButtonLink
                                        to={getRoute(RoutePathName.clientUserProgramClosure, {userId: user.id})}
                                        ghost
                                    >
                                        <FormattedMessage {...ClientUsersMessages.edit} />
                                    </ButtonLink>
                                </div>
                                <br/>
                                <Descriptions column={1} bordered>
                                    {(user.selectedProgram.closure.type) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.typeLabel} />}>
                                            <EnumMessage map={programClosureTypeMessages} value={user.selectedProgram.closure.type} />
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.leavingDate) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.leavingDateLabel} />}>
                                            <FormattedDate value={user.selectedProgram.closure.leavingDate}/>
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.job?.entryOnDutyDate) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.entryOnDutyDateLabel} />}>
                                            <FormattedDate value={user.selectedProgram.closure.job.entryOnDutyDate}/>
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.job?.jobTitle) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.jobLabel} />}>
                                            {user.selectedProgram.closure.job.jobTitle}
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.job?.annualSalaryWithoutTax) && (
                                        <Descriptions.Item label={<FormattedMessage {...CreateUserMessages.annualSalaryLabel} />}>
                                            {user.selectedProgram.closure.job.annualSalaryWithoutTax}
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.type === ProgramClosureType.businessCreation) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.partnerLabel} />}>
                                            <FormattedMessage
                                                {...(user.selectedProgram.closure.partnership) ? genericMessages.yes : genericMessages.no}
                                            />
                                        </Descriptions.Item>
                                    )}
                                    {(user.selectedProgram.closure.training?.dates) && (
                                        <>
                                            <Descriptions.Item
                                                label={<FormattedMessage {...ProgramClosureMessages.trainingFromDateLabel} />}
                                            >
                                                <FormattedDate value={user.selectedProgram.closure.training?.dates.fromDate}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label={<FormattedMessage {...ProgramClosureMessages.trainingFromDateLabel} />}
                                            >
                                                <FormattedDate value={user.selectedProgram.closure.training?.dates.toDate}/>
                                            </Descriptions.Item>
                                        </>
                                    )}
                                    {(user.selectedProgram.closure.comment) && (
                                        <Descriptions.Item label={<FormattedMessage {...ProgramClosureMessages.noteLabel} />}>
                                            {user.selectedProgram.closure.comment}
                                        </Descriptions.Item>
                                    )}
                                </Descriptions><br/>
                                <Space>
                                    <Button
                                        type="ghost"
                                        onClick={openProgramModal}
                                    >
                                        <FormattedMessage {...ClientUsersMessages.changeProgram} />
                                    </Button>
                                </Space>
                            </div>
                        )}
                    </Card>
                )}
            </div>

            <ClientUserFilesList user={user} />

            <ClientUserProgramModal
                user={user}
                isAfterCreate={isProgramModalAfterCreate}
                isVisible={isProgramModalVisible}
                isExtension={isProgramExtend}
                onClose={closeProgramModal}
                onSuccess={closeProgramModal}
            />

        </div>
    ) : <></>;
};

const mapStateToProps = (state: MainReducerState) => ({
    usersDetailsState: state.users.details,
    usersUpdateState: state.users.update,
    usersSendWelcomeState: state.users.sendWelcomeEmail,
    usersRemoveState: state.users.remove,
});

export default connect(
    mapStateToProps,
    {
        getDetails: usersActions.details.trigger,
        resetDetails: usersActions.details.reset,
        update: usersActions.update.trigger,
        sendWelcomeEmail: usersActions.sendWelcomeEmail.trigger,
        remove: usersActions.remove.trigger,
    },
)(ClientUser);
