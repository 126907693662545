import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4.442 12.457a2.53 2.53 0 011.27-.34c.68 0 1.32.265 1.801.746l.005.005.005.005a.667.667 0 00.475.197c.179 0 .347-.07.474-.197l.005-.005.005-.005a2.53 2.53 0 011.8-.746c.455 0 .891.12 1.274.342a4.48 4.48 0 10-7.114-.002zm11.548 1.07a1.609 1.609 0 00-2.275 0l-.005.004-.005.005a1.609 1.609 0 01-2.275 0l-.005-.005-.005-.005a1.609 1.609 0 00-2.275 0l-.005.005-.005.005a1.609 1.609 0 01-2.275 0l-.005-.005-.005-.005a1.609 1.609 0 00-2.275 0l-.005.005-.005.005a1.609 1.609 0 01-2.275 0l-.005-.005-.005-.005a1.609 1.609 0 00-2.275 0L0 13.531V16h16v-2.464l-.005-.005-.005-.005zM8 0c.259 0 .469.21.469.469v2.828a.469.469 0 01-.938 0V.47C7.531.209 7.741 0 8 0zM.773 2.508a.469.469 0 01.663 0l2.343 2.343a.469.469 0 01-.663.663L.773 3.17a.469.469 0 010-.663zm11.446 2.344l2.343-2.343a.469.469 0 11.663.663l-2.343 2.343a.469.469 0 11-.663-.663zm2.219 4.414H16v.937h-1.563a.469.469 0 110-.937zM0 9.266h1.563a.469.469 0 010 .937H0v-.937z" />
    </svg>
);

const IconRetirement: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-retirement${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconRetirement;
