import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48">
        <path d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0zm0 3.2C12.512 3.2 3.2 12.512 3.2 24c0 11.488 9.312 20.8 20.8 20.8 11.488 0 20.8-9.312 20.8-20.8 0-11.488-9.312-20.8-20.8-20.8zm9.99 14.05c.527.006.997.34 1.18.84.184.502.044 1.066-.35 1.42L22.245 31.167a1.26 1.26 0 0 1-1.73-.02l-5.865-5.622a1.303 1.303 0 0 1-.366-1.238c.105-.45.44-.808.875-.94.436-.132.91-.018 1.24.3l5 4.804 11.697-10.847c.24-.23.562-.358.893-.353z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

const IconCheckCircle: FC<IconComponentProps> = (props) => (
    // @ts-ignore
    <Icon
        component={svg}
        {...props}
        className={`icon-check-circle${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCheckCircle;
